import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Spinner, // Import Spinner for loading indicator
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import { showToast } from "../../Components/Common/Toast";
import {
  scanResumeRequest,
  scanResumeReset,
} from "../../redux/jobseeker/scan_resume/actions";
import { FiUpload } from "react-icons/fi";

const ResumeScan = () => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const isLoading = useSelector((state) => state.scanResumeReducer.isLoading); // Get loading state from Redux

  const getUploadParams = () => ({ url: "https://httpbin.org/post" });

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "headers_received") {
      setFileUploaded(true);
      setUploadedFile(file);
      setErrors((prevErrors) => ({ ...prevErrors, uploadedFile: "" }));
    } else if (status === "error_upload") {
      showToast("error", "Upload failed, please try again.");
    }
  };

  const validate = () => {
    const errors = {};
    let isValid = true;
    if (!uploadedFile) {
      errors.uploadedFile = "Please upload at least one file";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleScanNow = (e) => {
    e.preventDefault();
    if (validate()) {
      if (fileUploaded) {
        const formData = new FormData();
        formData.append("resume", uploadedFile);
        dispatch(scanResumeRequest(formData));
      } else {
        showToast("error", "Please upload a file before scanning");
      }
    }
  };

  // Listen to changes in scanResumeData after dispatch
  const scanResumeData = useSelector((state) => state.scanResumeReducer);

  // Redirect based on scanResumeData change

  useEffect(() => {
    if (scanResumeData.status == true) {
      // showToast("success", scanResumeData.message);
      // dispatch(scanResumeReset());
      navigate(`${process.env.PUBLIC_URL}/jobseeker/resume_scan_result`);
    } else if (scanResumeData.status == false && scanResumeData.message != "") {
      showToast("error", scanResumeData.message);
      dispatch(scanResumeReset());
    }
  }, [scanResumeData]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Resume Scan" title="ResumeScan" />
      <Container fluid={true}>
        <Card className="bg-white">
          <CardBody>
            <Form className="theme-form" style={{ padding: "2px !important" }}>
              <FormGroup>
                <Label>
                  Ai based Resume Scan
                  <span className="text-danger">*</span>
                </Label>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  maxFiles={1}
                  multiple={false}
                  canCancel={true}
                  accept=".pdf,.docx,.doc"
                  inputContent={
                    <span>
                      <FiUpload size={40} />
                      <h5 className="mt-2" style={{ cursor: "pointer" }}>
                        Drag and drop files for resume scan
                      </h5>
                    </span>
                  }
                  styles={{
                    dropzone: {
                      minHeight: 180,
                      borderColor: "#007bff",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      position: "relative",
                    },
                    inputLabel: { color: "#6c757d", cursor: "pointer" },
                    input: { display: "none" },
                    inputLabelWithFiles: {
                      marginTop: "3rem",
                      justifyContent: "center",
                    },
                    preview: {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "10px 0",
                    },
                  }}
                />
                {errors.uploadedFile && (
                  <small className="text-danger">{errors.uploadedFile}</small>
                )}
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleScanNow}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Fragment>
                      <Spinner
                        size="sm"
                        color="light"
                        style={{ marginRight: "8px" }}
                      />{" "}
                      {/* Adjusting right margin */}
                      Scanning...
                    </Fragment>
                  ) : (
                    "Scan now"
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ResumeScan;
