import { takeLatest, call, put } from "redux-saga/effects";
import { UNLOCK_TEMPLATE_REQUEST } from "../../actionTypes";

import { unlockTemplateSuccess, unlockTemplateFailure } from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* unlockTemplate(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/unlock_premium_resume", action.payload)
    );

    if (response.data.status == true) {
      yield put(unlockTemplateSuccess(response.data));
    } else {
      yield put(unlockTemplateFailure(response.data));
    }
  } catch (error) {
    yield put(unlockTemplateFailure({ status: false, message: error.message }));
  }
}

// Watcher saga
function* unlockTemplateSaga() {
  yield takeLatest(UNLOCK_TEMPLATE_REQUEST, unlockTemplate);
}
export default unlockTemplateSaga;
