import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Home } from "react-feather";
import { AuthContext } from "../../../Auth/AuthContext";
import { useContext } from "react";
const Breadcrumbs = (props) => {
  const { user } = useContext(AuthContext);
  const userType = user.type.toLowerCase() || "jobseeker";
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              <h3>{props.mainTitle}</h3>
            </Col>
            <Col xs="6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`${process.env.PUBLIC_URL}/${userType}/dashboard`}>
                    <Home />
                  </Link>
                </li>

                {props.parent ? (
                  <li className="breadcrumb-item">{props.parent}</li>
                ) : (
                  ""
                )}
                {props.subParent ? (
                  <li className="breadcrumb-item">{props.subParent}</li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active">{props.title}</li>
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
