import {
  FETCH_JOB_SOURCES_REQUEST,
  FETCH_JOB_SOURCES_SUCCESS,
  FETCH_JOB_SOURCES_FAILURE,
} from "../../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  message: "",
  status: false,
};

const jobSourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_SOURCES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOB_SOURCES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: action.payload.status,
        isLoading: false,
      };
    case FETCH_JOB_SOURCES_FAILURE:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default jobSourceReducer;
