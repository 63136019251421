// actions.js
import * as types from "../actionTypes";

export const fetchCompanyRequest = (keyword) => ({
  type: types.FETCH_COMPANY_REQUEST,
  payload: { keyword },
});

export const fetchCompanySuccess = (data) => ({
  type: types.FETCH_COMPANY_SUCCESS,
  payload: { data },
});

export const fetchCompanyFailure = (error) => ({
  type: types.FETCH_COMPANY_FAILURE,
  payload: { error },
});
