import React from "react";
import { useState } from "react";
import { showToast } from "./Toast";
import parse from "html-react-parser";
import { countryCodes } from "../../Constant";
import AxiosInstance from "../../services/AxiosInstance";
import { SYSTEM_PREFIX, COINS } from "../../Constant/index";
// Capitalize function

export const showStyledCoins = (postJobPoints) => {
  return (
    <strong className="text-success">
      {postJobPoints} {COINS}
    </strong>
  );
};

export const styleTotalCoins = (postJobPoints) => {
  return <strong className="text-primary">{postJobPoints}</strong>;
};

export const getFileExtension = (url) => {
  return url.split(".").pop().split(/\#|\?/)[0];
};

export const sanitizedFileName = (candidateName) => {
  return candidateName.replace(/\s+/g, "_");
};

export const getFileType = (fileExtension) => {
  const fileTypes = {
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  };
  return fileTypes[fileExtension.toLowerCase()] || "application/octet-stream";
};

export const downloadFileFromUrl = async (url, fileName) => {
  console.log("Fetching file from:", url, "with filename:", fileName);

  try {
    const response = await AxiosInstance().post(
      "/file/download",
      {
        fileUrl: url,
      },
      {
        responseType: "blob", // Ensure the response is handled as a blob
      }
    );

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = response.data;
    const fileExtension = getFileExtension(url);
    const fileType = getFileType(fileExtension);

    const fileBlob = new Blob([blob], { type: fileType });

    // Create a temporary URL for the Blob object
    const tempUrl = URL.createObjectURL(fileBlob);

    // Create a temporary anchor element
    const anchor = document.createElement("a");
    anchor.href = tempUrl;
    anchor.download = SYSTEM_PREFIX + "_" + fileName + "_Resume";

    // Trigger the download by simulating a click on the anchor element
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(tempUrl);
    document.body.removeChild(anchor);
  } catch (error) {
    console.error("Error fetching file:", error);
  }
};

export const capitalize = (str) => {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Date format function

export const formatDate = (inputDate) => {
  const inputDateObject = new Date(inputDate);
  const day = inputDateObject.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    inputDateObject
  );
  const year = inputDateObject.getFullYear();

  return `${day} ${month} ${year}`;
};

export const formatToInteger = (number) => {
  return Math.floor(number);
};

// Search by input on change function

export const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.list_name);

    console.log("Searching for:", searchTerm);
  };

  return (
    <div>
      <input value={searchTerm} onChange={handleInputChange} />
    </div>
  );
};

const CommonFunction = () => {
  return <div></div>;
};

export const handleCopyToClipboard = (value) => {
  return navigator.clipboard.writeText(value).then(
    () => {
      showToast("success", "Copied to clipboard");
    },
    (error) => {
      showToast("error", "Failed to copy");
      console.error("Failed to copy:", error);
    }
  );
};

export const handleSendEmail = (emailAddress) => {
  window.location.href = `mailto:${emailAddress}`;
};

export const handlePhoneCall = (phoneNumber) => {
  window.location.href = `tel:${phoneNumber}`;
};

// utils.js or within your component file
export const capitalizeEachWord = (text) => {
  if (!text) return "";

  return text
    .split(" ")
    .map((word) => word.toUpperCase())
    .join(" ");
};

export function unslugAndCapitalize(slug) {
  // Split the slug into an array of words
  const words = slug.split("_");

  // Capitalize the first letter of each word and keep the rest lowercase
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the capitalized words back into a string
  const capitalizedText = capitalizedWords.join(" ");

  return capitalizedText;
}

export function capitalizeFirstLetter(text) {
  // Split the string into words based on spaces
  const words = text.split(" ");

  // Capitalize the first letter of each word and keep the rest lowercase
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the capitalized words back into a string
  return capitalizedWords.join(" ");
}

// Time ago function
// export const timeAgo = (postDate) => {
//   const now = new Date();
//   const posted = new Date(postDate);

//   const seconds = Math.floor((now - posted) / 1000);
//   const minutes = Math.floor(seconds / 60);
//   const hours = Math.floor(minutes / 60);

//   let timeAgo;

//   if (seconds < 60) {
//     timeAgo = `${seconds} seconds ago`;
//   } else if (minutes < 60) {
//     timeAgo = `${minutes} minutes ago`;
//   } else if (hours < 24) {
//     timeAgo = `${hours} hours ago`;
//   } else {
//     const days = Math.floor(hours / 24);
//     const weeks = Math.floor(days / 7);
//     const months = Math.floor(days / 30);
//     const years = Math.floor(months / 12);

//     if (years > 0) {
//       timeAgo = `${years} years ago`;
//     } else if (months > 0) {
//       timeAgo = `${months} months ago`;
//     } else if (weeks > 0) {
//       timeAgo = `${weeks} weeks ago`;
//     } else {
//       timeAgo = `${days} days ago`;
//     }
//   }

//   return timeAgo;
// };

export const timeAgo = (postDate, useAgo = true) => {
  const now = new Date();
  const posted = new Date(postDate);

  const seconds = Math.floor((now - posted) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  let timeAgo;

  if (seconds < 60) {
    timeAgo = useAgo ? `${seconds} seconds ago` : `${seconds} seconds`;
  } else if (minutes < 60) {
    timeAgo = useAgo ? `${minutes} minutes ago` : `${minutes} minutes`;
  } else if (hours < 24) {
    timeAgo = useAgo ? `${hours} hours ago` : `${hours} hours`;
  } else {
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      timeAgo = useAgo ? `${years} years ago` : `${years} years`;
    } else if (months > 0) {
      timeAgo = useAgo ? `${months} months ago` : `${months} months`;
    } else if (weeks > 0) {
      timeAgo = useAgo ? `${weeks} weeks ago` : `${weeks} weeks`;
    } else {
      timeAgo = useAgo ? `${days} days ago` : `${days} days`;
    }
  }

  return timeAgo;
};

// Function to format experience based on min and max values
// export const formatExperience = (minExperience, maxExperience) => {
//   if (minExperience !== "" && maxExperience !== "") {
//     const experienceMin = parseFloat(minExperience);
//     const experienceMax = parseFloat(maxExperience);

//     if (experienceMin === experienceMax) {
//       if (experienceMax === 1) {
//         return "1 year";
//       } else if (experienceMax === 0) {
//         return "0 year";
//       } else {
//         return `${experienceMax} years`;
//       }
//     } else {
//       return `${experienceMin} - ${experienceMax} years`;
//     }
//   } else if (maxExperience === "" || maxExperience === undefined) {
//     console.log("formatExperience");
//     if (minExperience <= 1) {
//       return `${minExperience} year`;
//     } else {
//       return `${minExperience} years`;
//     }
//   } else {
//     return "-";
//   }
// };

// Function to format timestamp to display in AM/PM format
export const formatTimeAMPM = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero to minutes if needed
  const formattedTime = hours + ":" + minutes + " " + ampm;
  return formattedTime;
};

export const removeHTMLTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
};

// Function to convert HTML string to JSX
export function convertHtmlToJsx(htmlString) {
  // Parse the HTML string into JSX
  const jsx = parse(htmlString);

  return jsx;
}

// // Function to format individual salary number into "Lacs PA" format
// export function formatSalary(salary) {
//   if (salary < 100000) {
//     return (salary / 1000).toFixed(2).replace(/\.0+$/, "") + " K"; // Convert to fractional Lacs
//   } else if (salary < 10000000) {
//     return (salary / 100000).toFixed(2).replace(/\.0+$/, "") + " Lacs P.A."; // Convert to Lacs format with PA
//   } else {
//     return (salary / 10000000).toFixed(2).replace(/\.0+$/, "") + " Crores P.A."; // Convert to Crores format with PA
//   }
// }

// // Main function to format salary range output
// export const formatSalaryOutput = (salary_min, salary_max) => {
//   if (salary_min === undefined && salary_max === undefined) {
//     return "N/A"; // Return "N/A" if both minimum and maximum salaries are undefined
//   } else if (salary_min !== null && salary_max !== null) {
//     console.log("archana", formatSalary(salary_min));
//     const formatted_min = formatSalary(salary_min); // Format minimum salary
//     const formatted_max = formatSalary(salary_max); // Format maximum salary
//     return `${formatted_min.split(" ")[0]}-${
//       formatted_max.split(" ")[0]
//     } Lacs P.A.`; // Return formatted range string
//   } else if (salary_min !== null && salary_max === null) {
//     return `${formatSalary(salary_min)}`; // Return formatted minimum salary if no maximum
//   } else if (salary_min === null && salary_max !== null) {
//     return `${formatSalary(salary_max)}`; // Return formatted maximum salary if no minimum
//   } else {
//     return "N/A"; // Default case (should ideally not be reached)
//   }
// };

// Function to format individual salary number into "Lacs PA" format
export function formatSalary(salary) {
  if (salary < 100000) {
    return (salary / 1000).toFixed(2).replace(/\.0+$/, "") + " K"; // Convert to K
  } else if (salary < 10000000) {
    return (salary / 100000).toFixed(2).replace(/\.0+$/, "") + " Lacs P.A."; // Convert to Lacs format with PA
  } else {
    return (salary / 10000000).toFixed(2).replace(/\.0+$/, "") + " Crores P.A."; // Convert to Crores format with PA
  }
}

// Main function to format salary range output
export const formatSalaryOutput = (salary_min, salary_max) => {
  if (salary_min === undefined && salary_max === undefined) {
    return "N/A"; // Return "N/A" if both minimum and maximum salaries are undefined
  } else if (salary_min !== null && salary_max !== null) {
    const formatted_max = formatSalary(salary_max); // Format maximum salary
    const max_unit = formatted_max.split(" ")[1]; // Extract unit from maximum salary format

    // Function to format salary based on the unit of the maximum salary
    const formatSalaryWithUnit = (salary, unit) => {
      if (unit === "K") {
        return (salary / 1000).toFixed(2).replace(/\.0+$/, "") + " K";
      } else if (unit === "Lacs") {
        return (salary / 100000).toFixed(2).replace(/\.0+$/, "") + " Lacs P.A.";
      } else if (unit === "Crores") {
        return (
          (salary / 10000000).toFixed(2).replace(/\.0+$/, "") + " Crores P.A."
        );
      }
    };

    const formatted_min = formatSalaryWithUnit(salary_min, max_unit); // Format minimum salary using the same unit as maximum salary
    return `${formatted_min.split(" ")[0]}-${
      formatted_max.split(" ")[0]
    } ${max_unit}`; // Return formatted range string
  } else if (salary_min !== null && salary_max === null) {
    return formatSalary(salary_min); // Return formatted minimum salary if no maximum
  } else if (salary_min === null && salary_max !== null) {
    return formatSalary(salary_max); // Return formatted maximum salary if no minimum
  } else {
    return "N/A"; // Default case (should ideally not be reached)
  }
};

// // Function to format work experience output
// export const formatExperienceOutput = (minExp, maxExp) => {
//   if (minExp == "" && maxExp == "") {
//     return "N/A";
//   }else if (minExp == 0 && maxExp ==0) {
//     return 0 year;
//   } else if (minExp && maxExp) {
//     return minExp === maxExp
//       ? minExp <= 1
//         ? ${minExp} year
//         : ${minExp} years
//       : ${minExp}-${maxExp} years;
//   } else if (minExp && !maxExp) {
//     return minExp <= 1 ? ${minExp} year : ${minExp} years;
//   }
// };
export const formatExperienceOutput = (minExp, maxExp) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (!minExp && !maxExp) {
    return "";
  } else if (minExp && maxExp) {
    const output =
      minExp === maxExp
        ? minExp <= 1
          ? `${minExp} Year`
          : `${minExp} Years`
        : `${minExp}-${maxExp} Years`;
    return capitalizeFirstLetter(output);
  } else if (minExp && !maxExp) {
    const output = minExp <= 1 ? `${minExp} Year` : `${minExp} Years`;
    return capitalizeFirstLetter(output);
  } else if (!minExp && maxExp) {
    const output = maxExp <= 1 ? `${maxExp} Year` : `${maxExp} Years`;
    return capitalizeFirstLetter(output);
  }
};

export const formatExperienceYearAndMonth = (totalExpYears, totalExpMonths) => {
  // Handle the case when both years and months are 0
  if (totalExpYears == 0 && totalExpMonths == 0) {
    return "0 Year";
  }

  let formattedString = "";

  if (totalExpYears > 0) {
    formattedString += `${totalExpYears} Year${totalExpYears > 1 ? "s" : ""}`;
  }

  if (totalExpMonths > 0) {
    if (totalExpYears > 0) {
      formattedString += " ";
    }
    formattedString += `${totalExpMonths} Month${
      totalExpMonths > 1 ? "s" : ""
    }`;
  }

  return formattedString.trim();
};

export const convertMonthsToYears = (months) => {
  // Ensure the input is a number and is not negative
  if (months < 0) {
    return 0;
  }

  // Use integer division to convert months to years
  const years = Math.floor(months / 12);
  console.log("years", years);
  return years;
};

export const convertMonthsToYearsAndMonths = (months) => {
  // Ensure the input is a number and is not negative
  if (months < 0) {
    return "0 months";
  }

  // Calculate years and remaining months
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  // Construct the result string based on the values
  let result = "";
  if (years > 0) {
    result += `${years} year${years > 1 ? "s" : ""}`;
  }
  if (remainingMonths > 0) {
    result += ` ${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
  }

  return result.trim();
};

export const generateSlug = (...parts) => {
  return parts
    .filter((part) => part !== null && part !== undefined) // Filter out null or undefined parts
    .map(
      (part) =>
        part
          .toString() // Convert part to string
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
          .replace(/^-+|-+$/g, "") // Remove leading and trailing hyphens
    )
    .join("-");
};

export const extractAfterLastHyphen = (str, positionFromEnd = 1) => {
  if (typeof str !== "string") {
    return null;
  }

  const parts = str.split("-");
  if (positionFromEnd <= parts.length) {
    return parts[parts.length - positionFromEnd];
  }
  return null;
};

// localStorageUtils.js
export const loadFiltersFromLocalStorage = (key, defaultFilters) => {
  const storedFilters = localStorage.getItem(key);
  return storedFilters ? JSON.parse(storedFilters) : defaultFilters;
};

export const saveFiltersToLocalStorage = (key, filters) => {
  localStorage.setItem(key, JSON.stringify(filters));
};

export const removeFiltersFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

// CommonFunctions.js
export function idToBase64(id) {
  const idString = String(id); // Convert ID to a string explicitly
  return btoa(idString);
}

// CommonFunctions.js
export function base64ToId(base64String) {
  const decodedString = atob(base64String);
  return parseInt(decodedString, 10); // Convert decoded string to integer
}

// Utility function to validate number input and convert to words
export const convertNumberToWords = (price) => {
  const sglDigit = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const dblDigit = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tensPlace = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const handle_tens = (dgt, prevDgt) => {
    return dgt === 0
      ? ""
      : dgt === 1
      ? ` ${dblDigit[prevDgt]}`
      : ` ${tensPlace[dgt]}`;
  };

  const handle_utlc = (dgt, nxtDgt, denom) => {
    return (
      (dgt !== 0 && nxtDgt !== 1 ? ` ${sglDigit[dgt]}` : "") +
      (nxtDgt !== 0 || dgt > 0 ? ` ${denom}` : "")
    );
  };

  let str = "";
  let words = [];

  price = price.toString();

  if (isNaN(parseInt(price))) {
    return "";
  } else if (parseInt(price) === 0) {
    return sglDigit[0]; // Return "Zero" if price is 0
  } else if (parseInt(price) > 0 && price.length <= 10) {
    for (let digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) {
      let digit = price[digitIdx] - 0;
      let nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0;
      let place = price.length - digitIdx - 1;

      switch (place) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(
            digit !== 0
              ? ` ${sglDigit[digit]} Hundred${
                  price[digitIdx + 1] !== "0" && price[digitIdx + 2] !== "0"
                    ? " and"
                    : ""
                }`
              : ""
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(
            digit !== 0
              ? ` ${sglDigit[digit]} Hundred${
                  price[digitIdx + 1] !== "0" || price[digitIdx + 2] !== "0"
                    ? " and"
                    : " Crore"
                }`
              : ""
          );
          break;
        default:
          break;
      }
    }
    str = words.reverse().join("");
  } else {
    str = "";
  }

  return str.trim();
};

export const formatWebsite = (website) => {
  // Remove "https://" and "www."
  website = website.replace(/^https?:\/\//, "");

  // Remove leading "www." (if present)
  website = website.replace(/^www\./, "");

  // Ensure presence of "www."
  website = `www.${website}`;

  return website;
};

export default CommonFunction;
