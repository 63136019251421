// dashboardReducer.js
import {
  FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST,
  FETCH_JOBSEEKER_PUBLIC_PROFILE_SUCCESS,
  FETCH_JOBSEEKER_PUBLIC_PROFILE_FAILURE,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const jobSeekerPublicProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_JOBSEEKER_PUBLIC_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,

        error: null,
      };
    case FETCH_JOBSEEKER_PUBLIC_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default jobSeekerPublicProfileReducer;
