// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import { fetchEducationSuccess,fetchEducationFailure } from "./actions";

function* fetchEducationSaga(action) {
  try {
    console.log(action.payload);
    // Call your API function here with action.payload.keyword
    const response = yield call(() =>
      AxiosInstance().post("/fetch_education", action.payload)
    );
    //console.log("fetch_location", response);
    // Dispatch success action with the response data
    if(response.data.status){
      yield put(fetchEducationSuccess(response.data));
    }else{
      yield put(fetchEducationFailure(response.data));
    }
    
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchEducationSuccess({status:false,message:error.messsage}));
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* educationSaga() {
  yield takeLatest(types.FETCH_EDUCATION_REQUEST, fetchEducationSaga);
}

export default educationSaga;
