import React, { useState, useEffect, useCallback, useContext } from "react";
import Select from "react-select";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addCandidate,
  fetchCandidateRequest,
  resetAddCandidate,
} from "../../../redux/recruiter/add_candidate/actions";
import { fetchLocationsRequest } from "../../../redux/location/actions";
import { showToast } from "../../../Components/Common/Toast";
import { useParams, useNavigate } from "react-router-dom";
import {
  customOptionsStyle,
  experienceOptions,
  monthsOptions,
} from "../../../Constant";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import styles directly from react-phone-input-2
import validator from "validator";
import { AuthContext } from "../../../Auth/AuthContext";

const AddCandidate = () => {
  const dispatch = useDispatch();
  const { triggerTotalPoints } = useContext(AuthContext);
  const { job_id, cand_id } = useParams();
  const navigate = useNavigate();
  const [locationOptions, setLocationOptions] = useState([]);
  const [formData, setFormData] = useState({
    full_name: "",
    email1: "",
    email2: "",
    country_code1: "91",
    country_code2: "91",
    phone1: "",
    phone2: "",
    jobseeker_location: null,
    relevant_experience_years: null,
    relevant_experience_months: null,
  });

  const [errors, setErrors] = useState({});
  const addCandidateReducerData = useSelector(
    (state) => state.addCandidateReducer
  );

  // Fetching and updating location data
  let locationReducerData = useSelector((state) => state.locationReducer);
  let locationData = locationReducerData.data.data;

  useEffect(() => {
    locationData = locationData || [];
    setLocationOptions(locationData);
  }, [locationData]);

  const handleLocationFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleLocationInputChange = useCallback((inputValue) => {
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleLocationsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, jobseeker_location: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, jobseeker_location: selectedOptions });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedErrors = { ...errors, [name]: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name } = e.nativeEvent.submitter;

    if (validate()) {
      const payload = {
        jobseeker_full_name: formData.full_name,
        jobseeker_email1: formData.email1,
        jobseeker_email2: formData.email2,
        country_code1: formData.country_code1,
        country_code2: formData.country_code2,
        jobseeker_phone1: formData.phone1,
        jobseeker_phone2: formData.phone2,
        jobseeker_location: formData.jobseeker_location
          ? formData.jobseeker_location.value
          : null,
        month_exp: formData.relevant_experience_months
          ? formData.relevant_experience_months.value
          : null,
        year_exp: formData.relevant_experience_years
          ? formData.relevant_experience_years.value
          : null,
        job_id: extractAfterLastHyphen(job_id),
        //seeker_id: cand_id,
        type: name,
      };

      dispatch(addCandidate(payload));

      //navigate(`/recruiter/job_candidate_detail/${cand_id}`); // Redirect to the desired path
    } else {
      console.error("Form submission error:", errors);
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.full_name) {
      errors.full_name = "Full name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email1) {
      errors.email1 = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.email1)) {
      errors.email1 = "Please enter valid email";
      isValid = false;
    }

    if (formData.email2 && !emailRegex.test(formData.email2)) {
      errors.email2 = "Please enter valid email";
      isValid = false;
    }

    const trimmedPhoneNumber = formData.phone1.trim();
    if (!trimmedPhoneNumber) {
      errors.phone1 = "Phone number is required";
      isValid = false;
    } else if (
      formData.country_code1 === "91" &&
      trimmedPhoneNumber.length !== 10
    ) {
      errors.phone1 = "Phone number must be 10 digits for india";
      isValid = false;
    } else if (isNaN(trimmedPhoneNumber)) {
      errors.phone1 = "Phone number must be a valid number";
      isValid = false;
    } else if (
      !validator.isMobilePhone(trimmedPhoneNumber, "any", { strictMode: false })
    ) {
      errors.phone1 = "Invalid phone number format";
      isValid = false;
    }

    const trimmedPhoneNumber2 = formData.phone2.trim();
    if (trimmedPhoneNumber2) {
      // Check if phone2 is provided
      if (
        formData.country_code2 === "91" &&
        trimmedPhoneNumber2.length !== 10
      ) {
        errors.phone2 = "Phone number must be 10 digits for India";
        isValid = false;
      } else if (isNaN(trimmedPhoneNumber2)) {
        errors.phone2 = "Phone number must be a valid number";
        isValid = false;
      } else if (
        !validator.isMobilePhone(trimmedPhoneNumber2, "any", {
          strictMode: false,
        })
      ) {
        errors.phone2 = "Invalid phone number format";
        isValid = false;
      }
    }

    if (!formData.jobseeker_location) {
      errors.jobseeker_location = "Current location is required";
      isValid = false;
    }

    if (
      !formData.relevant_experience_years &&
      !formData.relevant_experience_months
    ) {
      errors.relevant_experience_years =
        "Relevant Experience (either in years or months) is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    console.log("addCandidateReducerData:", addCandidateReducerData);
    if (
      addCandidateReducerData.status === true &&
      addCandidateReducerData.message !== ""
    ) {
      showToast("success", addCandidateReducerData.message);
      triggerTotalPoints("add_candidate", true, false);
      const seeker_id = addCandidateReducerData.seeker_id;
      const job_cand_id = addCandidateReducerData.job_cand_id;

      if (seeker_id && job_cand_id) {
        navigate(
          `${process.env.PUBLIC_URL}/recruiter/job_candidate_detail/edit/${seeker_id}/${job_cand_id}`
        );
      } else {
        navigate(`${process.env.PUBLIC_URL}/recruiter/manage_candidate`);
      }
      dispatch(resetAddCandidate());
    } else if (
      addCandidateReducerData.status === false &&
      addCandidateReducerData.message !== ""
    ) {
      showToast("error", addCandidateReducerData.message);
      dispatch(resetAddCandidate());
    }
  }, [addCandidateReducerData, navigate, dispatch]);

  const handleCodeChange = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code1: country.dialCode,
    });
  };
  const handleCodeChange2 = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code2: country.dialCode,
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="theme-form">
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="full_name">
                  Full Name
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="full_name"
                  id="full_name"
                  placeholder="Enter full name"
                  value={formData.full_name}
                  onChange={handleChange}
                />
                {errors.full_name && (
                  <small className="text-danger">{errors.full_name}</small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>
                  Current Location
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  name="jobseeker_location"
                  options={locationOptions}
                  isLoading={locationReducerData.isLoading}
                  placeholder="Select locations..."
                  value={formData.jobseeker_location}
                  onChange={handleLocationsChange}
                  onInputChange={handleLocationInputChange}
                  styles={customOptionsStyle}
                  onFocus={handleLocationFocus}
                />
                {errors.jobseeker_location && (
                  <small className="text-danger">
                    {errors.jobseeker_location}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Primary Phone
                  <span className="text-danger">*</span>
                </Label>
                <div className="d-flex">
                  <PhoneInput
                    style={{
                      width: "10px",
                      marginRight: "10px",
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      disabled: true,
                      // placeholder: "Enter country code",
                    }}
                    country={"in"} // Default country
                    value={formData.country_code1}
                    onChange={handleCodeChange}
                    inputStyle={{
                      width: "100px", // Adjust width to fit country code
                      height: "38px",
                      //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                      paddingLeft: "3rem",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      color: "#495057",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }}
                    enableAreaCodes={false}
                  />
                  <Input
                    className="form-control form-control-sm"
                    placeholder="Enter primary phone number"
                    id="phone1"
                    name="phone1"
                    value={formData.phone1}
                    onChange={handleChange}
                    style={{ marginLeft: "95px" }} // Add margin to separate the fields
                    maxLength={15} // Adjust the maximum length as per your requirement
                  />
                </div>

                {errors.phone1 && (
                  <small className="text-danger">{errors.phone1}</small>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="email">
                  Primary Email<span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="email1"
                  id="email1"
                  placeholder="Enter primary email"
                  value={formData.email1}
                  onChange={handleChange}
                />
                {errors.email1 && (
                  <small className="text-danger">{errors.email1}</small>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Secondary Phone</Label>
                <div className="d-flex">
                  <PhoneInput
                    style={{
                      width: "10px",
                      marginRight: "10px",
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      disabled: true,
                      // placeholder: "Enter country code",
                    }}
                    country={"in"} // Default country
                    value={formData.country_code2}
                    onChange={handleCodeChange2}
                    inputStyle={{
                      width: "100px", // Adjust width to fit country code
                      height: "38px",
                      //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                      paddingLeft: "3rem",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      color: "#495057",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }}
                    enableAreaCodes={false}
                  />
                  <Input
                    className="form-control form-control-sm"
                    placeholder="Enter secondary phone number"
                    id="phone2"
                    name="phone2"
                    value={formData.phone2}
                    onChange={handleChange}
                    style={{ marginLeft: "95px" }} // Add margin to separate the fields
                    maxLength={15} // Adjust the maximum length as per your requirement
                  />
                </div>

                {errors.phone2 && (
                  <small className="text-danger">{errors.phone2}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="email2">Secondary Email</Label>
                <Input
                  className="form-control form-control-sm"
                  type="email"
                  name="email2"
                  id="email2"
                  placeholder="Enter secondary email"
                  value={formData.email2}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label>
              Relevant Experience <span className="font-danger">*</span>
            </Label>
            <Col md={6}>
              <FormGroup>
                <Select
                  options={experienceOptions}
                  value={formData.relevant_experience_years}
                  onChange={(selectedOption) => {
                    setFormData({
                      ...formData,
                      relevant_experience_years: selectedOption,
                    });
                    setErrors({ ...errors, relevant_experience_years: "" });
                  }}
                />
                {errors.relevant_experience_years && (
                  <small className="text-danger">
                    {errors.relevant_experience_years}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Select
                  options={monthsOptions}
                  value={formData.relevant_experience_months}
                  onChange={(selectedOption) => {
                    setFormData({
                      ...formData,
                      relevant_experience_months: selectedOption,
                    });
                    setErrors({ ...errors, relevant_experience_years: "" });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            color="secondary"
            type="submit"
            name="submit"
            className="me-1"
            disabled={addCandidateReducerData.isLoading}
          >
            Submit
          </Button>
          <Button
            color="primary"
            type="submit"
            name="update"
            disabled={addCandidateReducerData.isLoading}
          >
            Update More
          </Button>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default AddCandidate;
