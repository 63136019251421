import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_CANDIDATE_INFO_REQUEST } from "../../actionTypes";

import {
  updateCandidateInfoSuccess,
  updateCandidateInfoFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updateCandidateStatus(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post(
        "/recruiter/update_job_candidate_info",
        action.payload
      )
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(updateCandidateInfoSuccess(response.data));
    } else {
      yield put(updateCandidateInfoFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateCandidateInfoFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* updateCandidateInfoSaga() {
  yield takeLatest(UPDATE_CANDIDATE_INFO_REQUEST, updateCandidateStatus);
}
export default updateCandidateInfoSaga;
