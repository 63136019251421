import {
  FETCH_RECOMMENDED_JOBS_REQUEST,
  FETCH_RECOMMENDED_JOBS_SUCCESS,
  FETCH_RECOMMENDED_JOBS_FAILURE,
} from "../../actionTypes";

// jobActions.js

export const fetchJobsRequest = (page, perPage) => {
  return {
    type: FETCH_RECOMMENDED_JOBS_REQUEST,
    payload: { page, perPage },
  };
};

export const fetchJobsSuccess = (jobs) => {
  return {
    type: FETCH_RECOMMENDED_JOBS_SUCCESS,
    payload: jobs,
  };
};

export const fetchJobsFailure = (error) => {
  return {
    type: FETCH_RECOMMENDED_JOBS_FAILURE,
    payload: error,
  };
};
