import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Components/Common/Toast";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
  Card,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import {
  updateJobseekerPasswordRequest,
  resetJobseekerPasswordMessage,
} from "../../redux/jobseeker/change_password/actions";
const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const changeJobseekerPasswordReducerData = useSelector(
    (state) => state.changeJobseekerPasswordReducer
  );
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCpassword, setShowCpassword] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    cpassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error message when input changes
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(formData);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    dispatch(
      updateJobseekerPasswordRequest(
        formData.oldPassword,
        formData.password,
        formData.cpassword
      )
    );
  };

  const validate = (data) => {
    let errors = {};
    let isValid = true;

    if (!data.oldPassword) {
      errors.oldPassword = "Old password is required";
      isValid = false;
    }

    if (!data.password) {
      errors.password = "New password is required";
      isValid = false;
    } else if (data.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!/[A-Z]/.test(data.password)) {
      errors.password = "Password must contain at least one uppercase letter";
      isValid = false;
    } else if (!/[a-z]/.test(data.password)) {
      errors.password = "Password must contain at least one lowercase letter";
      isValid = false;
    } else if (!/[0-9]/.test(data.password)) {
      errors.password = "Password must contain at least one number";
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.password)) {
      errors.password = "Password must contain at least one special character";
      isValid = false;
    }

    if (!data.cpassword) {
      errors.cpassword = "Confirm password is required";
      isValid = false;
    } else if (data.password !== data.cpassword) {
      errors.cpassword = "Password doesn't match";
      isValid = false;
    }

    // You can add more validation rules here for each field

    return isValid ? null : errors;
  };

  useEffect(() => {
    if (
      changeJobseekerPasswordReducerData.status == true &&
      changeJobseekerPasswordReducerData.message != ""
    ) {
      showToast("success", changeJobseekerPasswordReducerData.message);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetJobseekerPasswordMessage());
      setFormData({
        ...formData,
        password: "",
        cpassword: "",
        oldPassword: "",
      });
    } else if (
      changeJobseekerPasswordReducerData.status == false &&
      changeJobseekerPasswordReducerData.message != ""
    ) {
      showToast("error", changeJobseekerPasswordReducerData.message);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetJobseekerPasswordMessage());
    }
  }, [changeJobseekerPasswordReducerData]);

  return (
    <Fragment>
      <Breadcrumbs title="Change Password" mainTitle="Change Password" />
      <Container fluid={true}>
        <Form className="theme-form" onSubmit={onSubmit}>
          <Card>
            <CardBody>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label className="form-label">
                      Old Password<span className="text-danger">*</span>
                    </Label>
                    <div className="position-relative">
                      <Input
                        className="form-control form-control-sm"
                        type={showOldPassword ? "text" : "password"}
                        placeholder="********"
                        name="oldPassword"
                        value={formData.oldPassword}
                        onChange={handleChange}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      >
                        <span className={showOldPassword ? "" : "show"}></span>
                      </div>
                    </div>
                    {errors.oldPassword && (
                      <small className="text-danger">
                        {errors.oldPassword}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="form-label">
                      New Password<span className="text-danger">*</span>
                    </Label>
                    <div className="position-relative">
                      <Input
                        className="form-control form-control-sm"
                        type={showPassword ? "text" : "password"}
                        placeholder="********"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <span className={showPassword ? "" : "show"}></span>
                      </div>
                    </div>
                    {errors.password && (
                      <small className="text-danger">{errors.password}</small>
                    )}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup>
                    <Label className="form-label">
                      Confirm Password<span className="text-danger">*</span>
                    </Label>
                    <div className="position-relative">
                      <Input
                        className="form-control form-control-sm"
                        type={showCpassword ? "text" : "password"}
                        placeholder="********"
                        name="cpassword"
                        value={formData.cpassword}
                        onChange={handleChange}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setShowCpassword(!showCpassword)}
                      >
                        <span className={showCpassword ? "" : "show"}></span>
                      </div>
                    </div>
                    {errors.cpassword && (
                      <small className="text-danger">{errors.cpassword}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Button
                color="primary"
                disabled={changeJobseekerPasswordReducerData.isLoading}
                type="submit"
              >
                Change Password
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </Fragment>
  );
};

export default ChangePasswordPage;
