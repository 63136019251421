import React from "react";
import { Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import whatsapp from "../../../assets/images/Icons/whatsapp.png";
import facebook from "../../../assets/images/Icons/facebook.png";
import twitter from "../../../assets/images/Icons/twitter.png";
import linkedin from "../../../assets/images/Icons/linkedin.png";
import { FiCopy } from "react-icons/fi";
import { handleCopyToClipboard } from "../../Common/CommonFunction";
import { MdContentCopy } from "react-icons/md";

const ShareModal = ({ isOpen, toggle, resumeShareLink }) => {
  const shareOnSocialMedia = (platform) => {
    let shareUrl = "";
    let linkText = "";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Referral | Naukrify – ${resumeShareLink}`
        )}`;
        linkText = "Share via WhatsApp";
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
          resumeShareLink
        )}`;
        linkText = "Share via Facebook";
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          `Referral | Naukrify – ${resumeShareLink}`
        )}`;
        linkText = "Share via Twitter";
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          resumeShareLink
        )}`;
        linkText = "Share via LinkedIn";
        break;
      default:
        break;
    }
    console.log("Clicked on", shareUrl);
    if (shareUrl !== "") {
      // Create a temporary anchor element
      const anchorElement = document.createElement("a");
      anchorElement.href = shareUrl;
      anchorElement.target = "_blank";
      anchorElement.innerText = linkText;

      // Append the anchor tag to a container or directly to the document body
      document.body.appendChild(anchorElement);

      // Open the anchor link
      anchorElement.click();

      // Clean up: remove the anchor element after click
      document.body.removeChild(anchorElement);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle} className="border-0">
        Congrats! Share your VisualCV.
      </ModalHeader>

      <ModalBody>
        <div className="referral-description  me-2 d-flex flex-column align-items-center">
          <div className="share-links ">
            <h6 className="share-link me-2">Click to copy link:</h6>
            <div className="d-flex align-items-center copy-link-wrapper border py-2 px-3 rounded mb-1">
              <span className="referral-link me-auto">
                {resumeShareLink.length > 45
                  ? `${resumeShareLink.substring(0, 45)}...`
                  : resumeShareLink}
              </span>
              <MdContentCopy
                className="copy-icon ms-3"
                size={30}
                onClick={() => handleCopyToClipboard(resumeShareLink)}
              />
            </div>

            <span className="referral-link mt-2 text-muted">
              <b>Note: </b> With this link, people will be able to see your
              updated data.
            </span>
            <br />
            <br />
            <span className="referral-link me-4  ">
              We automatically track views on this URL. Check your stats
              dashboard to see whan your VisualCV is Viewed!{" "}
            </span>
            <br />

            <div className="d-flex justify-content-center  mt-4">
              <img
                src={whatsapp}
                className="share-link share-referral me-2"
                onClick={() => shareOnSocialMedia("whatsapp")}
                aria-label="Whatsapp"
                alt="whatsapp"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                title="whatsapp"
              />
              <img
                src={facebook}
                className="share-link share-referral me-2"
                onClick={() => shareOnSocialMedia("facebook")}
                aria-label="Facebook"
                alt="facebook"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                title="facebook"
              />
              <img
                src={twitter}
                className="share-link share-referral me-2"
                onClick={() => shareOnSocialMedia("twitter")}
                aria-label="Twitter"
                alt="twitter"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                title="twitter"
              />
              <img
                src={linkedin}
                className="share-link share-referral me-2"
                onClick={() => shareOnSocialMedia("linkedin")}
                aria-label="Linkedin"
                alt="linkedin"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                title="linkedin"
              />
              {/* Icons end */}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;
