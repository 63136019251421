import {
  UNLOCK_TEMPLATE_REQUEST,
  UNLOCK_TEMPLATE_SUCCESS,
  UNLOCK_TEMPLATE_FAILURE,
  RESET_MESSAGE,
} from "../../actionTypes";

// Action creators
export const unlockTemplateRequest = (data) => ({
  type: UNLOCK_TEMPLATE_REQUEST,
  payload: data,
});

export const unlockTemplateSuccess = (message) => ({
  type: UNLOCK_TEMPLATE_SUCCESS,
  payload: message,
});

export const unlockTemplateFailure = (error) => ({
  type: UNLOCK_TEMPLATE_FAILURE,
  payload: error,
});

export const resetUnlockTemplateMessage = () => ({
  type: RESET_MESSAGE,
});
