// actions.js
import {
  ADD_CANDIDATE_REQUEST,
  ADD_CANDIDATE_SUCCESS,
  ADD_CANDIDATE_FAILURE,
  FETCH_ADD_CANDIDATE_REQUEST,
  FETCH_ADD_CANDIDATE_SUCCESS,
  FETCH_ADD_CANDIDATE_FAILURE,
  RESET_ADD_CANDIDATE,
} from "../../actionTypes";

export const addCandidate = (candidateData) => ({
  type: ADD_CANDIDATE_REQUEST,
  payload: candidateData,
});

export const addCandidateSuccess = (message) => ({
  type: ADD_CANDIDATE_SUCCESS,
  payload: message,
});

export const addCandidateFailure = (error) => ({
  type: ADD_CANDIDATE_FAILURE,
  payload: error,
});

export const fetchCandidateRequest = (data) => ({
  type: FETCH_ADD_CANDIDATE_REQUEST,
  payload: data,
});

export const fetchCandidateSuccess = (data) => ({
  type: FETCH_ADD_CANDIDATE_SUCCESS,
  payload: data,
});

export const fetchCandidateFailure = (error) => ({
  type: FETCH_ADD_CANDIDATE_FAILURE,
  payload: error,
});

export const resetAddCandidate = () => ({
  type: RESET_ADD_CANDIDATE,
});
