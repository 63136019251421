import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import SavedJobs from "../../Components/Jobseeker/Jobs/SavedJobs";
// import CardsClass from './Cards';
const SavedJobsPage = () => {
  return (
    <Fragment>
      <Breadcrumbs title="Saved Jobs" mainTitle="Saved Jobs" />
      <Container fluid={true}>
        <SavedJobs />
      </Container>
    </Fragment>
  );
};

export default SavedJobsPage;
