import { takeLatest, call, put } from "redux-saga/effects";
import { ADD_NOTE_REQUEST, DELETE_NOTE_REQUEST } from "../../actionTypes";

import {
  addNoteSuccess,
  addNoteFailure,
  deleteNoteSuccess,
  deleteNoteFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* addNote(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/addnote", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(addNoteSuccess(response.data));
    } else {
      yield put(addNoteFailure(response.data));
    }
  } catch (error) {
    yield put(addNoteFailure({ status: false, message: error.message }));
  }
}

function* deleteNote(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/delete_note", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(deleteNoteSuccess(response.data));
    } else {
      yield put(deleteNoteFailure(response.data));
    }
  } catch (error) {
    yield put(deleteNoteFailure({ status: false, message: error.message }));
  }
}

// Watcher saga
function* addNoteSaga() {
  yield takeLatest(ADD_NOTE_REQUEST, addNote);
  yield takeLatest(DELETE_NOTE_REQUEST, deleteNote);
}
export default addNoteSaga;
