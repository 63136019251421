// actions.js
import {
  CHECK_TEMPLATE_LOCK_REQUEST,
  CHECK_TEMPLATE_LOCK_SUCCESS,
  CHECK_TEMPLATE_LOCK_FAILURE,
} from "../../actionTypes";

export const checkTemplateLockRequest = (data) => ({
  type: CHECK_TEMPLATE_LOCK_REQUEST,
  payload: data,
});

export const checkTemplateLockSuccess = (request) => ({
  type: CHECK_TEMPLATE_LOCK_SUCCESS,
  payload: request,
});

export const checkTemplateLockFailure = (error) => ({
  type: CHECK_TEMPLATE_LOCK_FAILURE,
  payload: error,
});
