import React, { Fragment } from "react";

import Searchbar from "./Searchbar";
import Notificationbar from "./Notificationbar";
import JobseekerHeader from "./JobseekerHeader";
import RecruiterHeader from "./RecruiterHeader";
import { Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Auth/AuthContext";
import { useContext } from "react";
const RightHeader = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(`${process.env.PUBLIC_URL}/jobseeker/auth/login`); // Replace '/login' with the actual route for your login page
  };

  const handleRegisterClick = () => {
    navigate(`${process.env.PUBLIC_URL}/jobseeker/auth/register`); // Replace '/register' with the actual route for your register page
  };

  return (
    <Fragment>
      <Col
        xxl="12"
        xl="6"
        md="7"
        className="d-flex justify-content-between nav-right pull-right right-header col-8 p-0 ms-auto"
        // col="12"
        // className="nav-right pull-right right-header"
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        // }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link
            to={`${process.env.PUBLIC_URL}/jobseeker/auth/login`}
            style={{ width: "150px" }}
          >
            <img
              className=""
              src={require("../../../../assets/images/logo/logo.png")}
              alt=""
              width={"100%"}
            />
          </Link>
        </div>
        <ul className="simple-list nav-menus flex-row">
          {/* <li><Searchbar /></li>
          <li><Notificationbar /></li> */}
          {user.isLoggedin && user.type === "JOBSEEKER" ? (
            <JobseekerHeader />
          ) : user.isLoggedin && user.type === "RECRUITER" ? (
            <RecruiterHeader />
          ) : (
            <div className="d-flex">
              <Button
                color="primary"
                className="me-2"
                onClick={handleLoginClick}
              >
                Login
              </Button>

              <Button color="primary" outline onClick={handleRegisterClick}>
                Register
              </Button>
            </div>
          )}
        </ul>
      </Col>
    </Fragment>
  );
};

export default RightHeader;
