import React, { Fragment, useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Card,
  Badge,
} from "reactstrap";
import Spinner from "../../Common/Spinner";
import {
  fetchJobseekerDataRequest,
  resetData,
} from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  updateResumeAvatar,
  deleteResumeRequest,
  resetResumeData,
} from "../../../redux/jobseeker/update_resume/actions";
import { showSweetAlert } from "../../Common/SweetAlert";
import { deleteSocialMediaRequest } from "../../../redux/jobseeker/social_media/actions";
import { useSelector, useDispatch } from "react-redux";

import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { socialMediaOptions } from "../../../Constant";

import { FaFacebookSquare } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { FaStackOverflow } from "react-icons/fa";

import { showToast } from "../../Common/Toast";
import { MdOutlinePreview } from "react-icons/md";
import { useParams } from "react-router";
import { RiTwitterXFill } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";

import {
  downloadFileFromUrl,
  sanitizedFileName,
} from "../../Common/CommonFunction";

const EditMyProfile = ({ profileData }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  const [showSocialMedia, setShowSocialMedia] = useState(false);

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
            More Details
          </h4>
        </CardHeader>
        <CardBody className="">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 me-2">Resume Headline</h5>
          </div>
          <div className="mt-3 ps-2">
            <p>{profileData.resume_headline}</p>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default EditMyProfile;
