import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, reset } from "../../redux/auth/login/actions";
import { showToast } from "../../Components/Common/Toast";
import { AuthContext } from "../../Auth/AuthContext";
import { getTotalPointsRequest } from "../../redux/get_total_points/actions";
const LoginForm = () => {
  const { user, setUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [togglePassword, setTogglePassword] = useState(false);
  const { type } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [rememberPassword, setRememberPassword] = useState(true);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    type: type ? type.toUpperCase() : "",
  });

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      type: type ? type.toUpperCase() : "",
    }));
    setErrors({});
  }, [type]);

  useEffect(() => {
    if (user && user.isLoggedin === true) {
      navigate(`${process.env.PUBLIC_URL}/${type}`);
    }
  }, [user, type, navigate]);

  const handleCheckboxChange = (event) => {
    setRememberPassword(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formattedData = {
        email: formData.email,
        password: formData.password,
        type: formData.type,
      };
      dispatch(loginRequest(formattedData));
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        errors.email = "Please enter a valid email";
        isValid = false;
      }
    }

    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!/[a-z]/.test(formData.password)) {
      errors.password = "Password must contain at least one lowercase letter";
      isValid = false;
    } else if (!/[A-Z]/.test(formData.password)) {
      errors.password = "Password must contain at least one uppercase letter";
      isValid = false;
    } else if (!/\d/.test(formData.password)) {
      errors.password = "Password must contain at least one digit";
      isValid = false;
    } else if (!/[^a-zA-Z\d]/.test(formData.password)) {
      errors.password = "Password must contain at least one special character";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    setErrors({ ...errors, [name]: error });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const loginReducerData = useSelector((state) => state.loginReducer);
  const loginData = loginReducerData.data;

  useEffect(() => {
    if (loginReducerData.status && loginReducerData.message) {
      showToast("success", loginReducerData.message);
      dispatch(reset());

      const user_data = {
        name: loginData.name,
        email: loginData.email,
        type: loginData.type,
      };
      localStorage.setItem("_token", loginData.token);
      localStorage.setItem("user_data", JSON.stringify(user_data));

      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/${type}/dashboard`);
      }, 1000);
      dispatch(getTotalPointsRequest());
    } else if (!loginReducerData.status && loginReducerData.message) {
      showToast("error", loginReducerData.message);
      dispatch(reset());
    }
  }, [loginReducerData, dispatch, navigate, type, loginData]);

  return (
    <Fragment>
      <div className="login-card">
        <div>
          <div className="login-main">
            <Form className="theme-form login-form" onSubmit={handleSubmit}>
              <h4>Sign in as {type}</h4>
              <p>Enter your email & password to login</p>
              <FormGroup>
                <Label className="col-form-label m-0">
                  Email Address <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder="abc@gmail.com"
                  onChange={handleChange}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label m-0">
                  Password <span className="text-danger">*</span>
                </Label>
                <div className="position-relative">
                  <Input
                    className="form-control"
                    type={togglePassword ? "text" : "password"}
                    name="password"
                    placeholder="********"
                    onChange={handleChange}
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    <span className={togglePassword ? "" : "show"}></span>
                  </div>
                </div>
                {errors.password && (
                  <small className="text-danger">{errors.password}</small>
                )}
              </FormGroup>
              <FormGroup className="position-relative">
                <div className="checkbox">
                  <Link
                    to={`${
                      process.env.PUBLIC_URL
                    }/${type.toLowerCase()}/auth/forgot_password`}
                  >
                    Forgot password?
                  </Link>
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  className="d-block w-100 mt-2"
                  color="primary"
                  type="submit"
                  disabled={loginReducerData.isLoading}
                >
                  Sign in
                </Button>
              </FormGroup>
              <p className="text-start mb-0">
                Don't have an account?
                <Link
                  className="ms-2"
                  to={`${
                    process.env.PUBLIC_URL
                  }/${type.toLowerCase()}/auth/register`}
                >
                  Create Account
                </Link>
              </p>
              <p className="mb-0 text-start">
                Sign in as{" "}
                {type.toUpperCase() === "RECRUITER" ? "jobseeker" : "recruiter"}{" "}
                <Link
                  className="ms-2"
                  to={`${process.env.PUBLIC_URL}/${
                    type.toUpperCase() === "RECRUITER"
                      ? "jobseeker"
                      : "recruiter"
                  }/auth/login`}
                >
                  Sign In
                </Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
