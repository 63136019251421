import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Row,
  Badge,
} from "reactstrap";
import { Edit } from "react-feather";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { showToast } from "../../Components/Common/Toast";
import {
  updateCompanyProfile,
  fetchCompanyProfile,
  updateCompanyLogo,
  removeCompanyLogoRequest,
  resetMessage,
} from "../../redux/recruiter/company_profile/actions";
import { customOptionsStyle, benefitsOptions } from "../../Constant/index";
import Select from "react-select";
import { fetchIndustryRequest } from "../../redux/industryType/actions";
import { fetchSkillsRequest } from "../../redux/mandatorySkills/actions";

import { fetchFunctionalAreaRequest } from "../../redux/functionalArea/actions";
import { fetchCompanyRequest } from "../../redux/company/actions";
import CreatableSelect from "react-select/creatable";
import { FaPlus } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { readOnlyStyle } from "../../Constant/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import styles directly from react-phone-input-2
import validator from "validator";
import { fetchLocationsRequest } from "../../redux/location/actions";

const CompanyProfilePage = () => {
  const dispatch = useDispatch();
  const [industryTypeOptions, setIndustryTypeOptions] = useState([]);
  const [functionalAreaOptions, setFunctionalAreaOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mandatorySkillsOptions, setMandatorySkillsOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);

  const [formData, setFormData] = useState({
    company_logo: "",
    company_name: "",
    company_id: "",
    company_email: "",
    company_phone: "",
    country_code: "91",
    company_website: "",
    company_about: "",
    company_linkedin_url: "",
    company_facebook_url: "",
    company_twitter_url: "",
    no_of_employees: "",

    functional_area: [],
    company_technology: [],

    annual_revenue: "",
    company_address: "",
    company_city: null,
    company_state: "",
    company_country: "",
    company_zip_code: "",
    company_hq_location: null,
    industryType: null,
    benefits: [],
  });
  const [errors, setErrors] = useState({});
  //read location data from store
  let locationReducerData = useSelector((state) => state.locationReducer);
  let locationData = locationReducerData.data.data;

  useEffect(() => {
    locationData = locationData || [];
    setLocationOptions(locationData);
  }, [locationData]);

  const handleLocationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleLocationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Callback to handle location select change
  const handleHqLocationsChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, company_hq_location: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, company_hq_location: selectedOptions });
  };

  // Callback to handle location select change
  const handleCompanyCityChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, company_city: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, company_city: selectedOptions });
  };

  const companyProfileReducerData = useSelector(
    (state) => state.companyProfileReducer
  );

  useEffect(() => {
    // Fetch company profile on component mount
    dispatch(fetchCompanyProfile());
  }, []);

  const companyProfile = companyProfileReducerData.data;

  useEffect(() => {
    // Populate form data with fetched company profile data
    if (companyProfile && companyProfileReducerData.isLoading != true) {
      setFormData({
        company_logo: companyProfile.company_logo || "",
        company_name: companyProfile.company_name || "",
        company_email: companyProfile.company_email || "",
        company_phone: companyProfile.company_phone || "",
        country_code: companyProfile.country_code || "91",
        company_website: companyProfile.company_website || "",
        company_about: companyProfile.company_about || "",
        company_linkedin_url: companyProfile.company_linkedin_url || "",
        company_facebook_url: companyProfile.company_facebook_url || "",
        company_twitter_url: companyProfile.company_twitter_url || "",
        no_of_employees: companyProfile.no_of_employees || "",

        functional_area: companyProfile.functional_area_type || [],
        //  company_technology: companyProfile.company_technology || "",
        company_technology: companyProfile.company_technology || [],
        annual_revenue: companyProfile.annual_revenue || "",
        company_address: companyProfile.company_address || "",
        company_city: companyProfile.company_city || "",
        company_state: companyProfile.company_state || "",
        company_country: companyProfile.company_country || "",
        company_zip_code: companyProfile.company_zip_code || "",
        company_hq_location: companyProfile.company_hq_location || "",
        industryType: companyProfile.industry_area || "",

        benefits: companyProfile.company_benefits
          ? companyProfile.company_benefits
              .split(",")
              .map((benefit) => {
                return benefitsOptions.find(
                  (option) => option.value === benefit
                );
              })
              .filter((option) => option !== undefined)
          : [],
      });
    }
  }, [companyProfileReducerData]);
  console.log("fd", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear the error message when input changes
    const updatedErrors = { ...errors, [name]: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const updatedData = {
        industry_area: formData.industryType.value,
        is_consultancy: formData.industryType.value === "51" ? 1 : 0,
        company_id: formData.company_name.value || "",
        company_name: formData.company_name.label || "",
        company_email: formData.company_email,
        company_phone: formData.company_phone,
        country_code: formData.country_code,
        company_website: formData.company_website,
        company_about: formData.company_about,
        company_linkedin_url: formData.company_linkedin_url,
        company_facebook_url: formData.company_facebook_url,
        company_twitter_url: formData.company_twitter_url,
        no_of_employees: formData.no_of_employees,
        company_technology: formData.company_technology
          .map((skill) => skill.value)
          .join(","),
        functional_area: formData.functional_area
          .map((area) => area.value)
          .join(","),
        //  company_technology: formData.company_technology,
        annual_revenue: formData.annual_revenue,
        company_address: formData.company_address,
        company_city: formData.company_city.value,
        company_state: formData.company_state,
        company_country: formData.company_country,
        company_zip_code: formData.company_zip_code,
        company_hq_location: formData.company_hq_location.value,
        company_benefits: formData.benefits.map((loc) => loc.value).join(","),
      };
      dispatch(updateCompanyProfile(updatedData));
    }
  };
  console.log("formdata", formData);

  const validate = () => {
    let errors = {};
    let isValid = true;

    // Validate Company Name
    if (!formData.company_name) {
      errors.company_name = "Company name is required";
      isValid = false;
    }

    // Validate Company Email
    if (!formData.company_email.trim()) {
      errors.company_email = "Company email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.company_email)) {
      errors.company_email = "Invalid email format";
      isValid = false;
    }

    // Validate Company Phone Number
    const trimmedPhoneNumber = formData.company_phone.trim();

    if (!trimmedPhoneNumber) {
      errors.company_phone = "Phone number is required";
      isValid = false;
    } else if (
      formData.country_code === "91" &&
      trimmedPhoneNumber.length !== 10
    ) {
      errors.company_phone = "Phone number must be 10 digits for india";
      isValid = false;
    } else if (isNaN(trimmedPhoneNumber)) {
      errors.company_phone = "Phone number must be a valid number";
      isValid = false;
    } else if (
      !validator.isMobilePhone(trimmedPhoneNumber, "any", { strictMode: false })
    ) {
      errors.company_phone = "Invalid phone number format";
      isValid = false;
    }

    // Validate Company Website URL
    if (!formData.company_website.trim()) {
      errors.company_website = "Company website URL is required";
      isValid = false;
    } else if (
      !/^(ftp|http|https):\/\/[^ "]+$/.test(formData.company_website)
    ) {
      errors.company_website = "Invalid URL format";
      isValid = false;
    }

    // Validate About Company
    if (!formData.company_about) {
      errors.company_about = "About company is required";
      isValid = false;
    }

    // Validate LinkedIn URL
    if (!formData.company_linkedin_url.trim()) {
      errors.company_linkedin_url = "LinkedIn URL is required";
      isValid = false;
    } else if (
      !/^(https?:\/\/)?(www\.)?linkedin.com\/.*/i.test(
        formData.company_linkedin_url
      )
    ) {
      errors.company_linkedin_url = "Invalid LinkedIn URL format";
      isValid = false;
    }

    // Validate Facebook URL
    if (!formData.company_facebook_url.trim()) {
      errors.company_facebook_url = "Facebook URL is required";
      isValid = false;
    } else if (
      !/^(https?:\/\/)?(www\.)?facebook.com\/.*/i.test(
        formData.company_facebook_url
      )
    ) {
      errors.company_facebook_url = "Invalid Facebook URL format";
      isValid = false;
    }

    // Validate Twitter URL
    if (!formData.company_twitter_url.trim()) {
      errors.company_twitter_url = "Twitter URL is required";
      isValid = false;
    } else if (
      !/^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/.*/i.test(
        formData.company_twitter_url
      )
    ) {
      errors.company_twitter_url = "Invalid Twitter URL format";
      isValid = false;
    }

    // Validate Number of Employees
    if (!formData.no_of_employees.trim()) {
      errors.no_of_employees = "Number of employees is required";
      isValid = false;
    } // Add additional validation if needed

    // Validate Industry Type
    if (!formData.industryType) {
      errors.industryType = "Industry type is required";
      isValid = false;
    } // Add additional validation if needed

    // Validate Functional Area
    // if (!formData.functional_area) {
    //   errors.functional_area = "Functional area is required";
    //   isValid = false;
    // } // Add additional validation if needed

    //Validate Company Technology

    if (
      !formData.company_technology ||
      formData.company_technology.length === 0
    ) {
      errors.company_technology = "Company technology is required";
      isValid = false;
    }

    // Validate Annual Revenue
    if (!formData.annual_revenue.trim()) {
      errors.annual_revenue = "Annual revenue is required";
      isValid = false;
    } else if (isNaN(formData.annual_revenue.trim())) {
      errors.annual_revenue = "Annual revenue must be a number";
      isValid = false;
    }

    // Validate Company Address
    if (!formData.company_address) {
      errors.company_address = "Company address is required";
      isValid = false;
    }

    // Validate Company City
    if (!formData.company_city) {
      errors.company_city = "Company city is required";
      isValid = false;
    }

    // Validate Company State
    if (!formData.company_state) {
      errors.company_state = "Company state is required";
      isValid = false;
    }

    // Validate Company Country
    if (!formData.company_country) {
      errors.company_country = "Company country is required";
      isValid = false;
    }

    // Validate Company Zip Code
    if (!formData.company_zip_code.trim()) {
      errors.company_zip_code = "Company zip code is required";
      isValid = false;
    } else if (isNaN(formData.company_zip_code.trim())) {
      errors.company_zip_code = "Company zip code must be a number";
      isValid = false;
    }

    // Validate Company HQ Location
    if (!formData.company_hq_location) {
      errors.company_hq_location = "Company HQ location is required";
      isValid = false;
    }

    if (!formData.benefits || formData.benefits.length === 0) {
      errors.benefits = "Benefits is required";
      isValid = false;
    }

    // Set errors state and return validation result
    setErrors(errors);
    return isValid;
  };

  //read industry type data from store
  let mandatorySkillsReducerData = useSelector(
    (state) => state.mandatorySkillsReducer
  );
  // console.log(industryTypeReducerData.data);
  let mandatorySkillsData = mandatorySkillsReducerData.data.data;

  useEffect(() => {
    mandatorySkillsData = mandatorySkillsData || [];
    setMandatorySkillsOptions(mandatorySkillsData);
  }, [mandatorySkillsData]);

  const handlemandatorySkillsFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchSkillsRequest(keyword, "MANDATORY"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleMandatorySkillsInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchSkillsRequest(inputValue, "MANDATORY"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleMandatorySkillsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, company_technology: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      company_technology: selectedOptions,
    });
  };

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    // Dispatch action to update avatar immediately when file is selected
    var file = event.target.files[0];
    if (file) {
      const formData = new FormData();

      formData.append("company_logo", file);

      dispatch(updateCompanyLogo(formData));
    }
  };

  // Industry type dropdown related functions
  // ...
  //read industry type data from store
  let industryTypeReducerData = useSelector(
    (state) => state.industryTypeReducer
  );

  let industryTypeData = industryTypeReducerData.data.data;

  useEffect(() => {
    industryTypeData = industryTypeData || [];
    setIndustryTypeOptions(industryTypeData);
  }, [industryTypeData]);

  const handleIndustryFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchIndustryRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  const handleIndustryChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, industryType: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, industryType: selectedOptions });
  };

  // Callback to handle location input change
  const handleIndustryInputChange = useCallback((inputValue) => {
    // Update the location search value state
    // setLocationSearchValue(inputValue);

    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchIndustryRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Toast notification for profile update
  useEffect(() => {
    if (
      companyProfileReducerData.message != "" &&
      companyProfileReducerData.status == true
    ) {
      showToast("success", companyProfileReducerData.message);
      dispatch(fetchCompanyProfile());
      dispatch(resetMessage());
    } else if (
      companyProfileReducerData.message != "" &&
      companyProfileReducerData.status == false
    ) {
      showToast("error", companyProfileReducerData.message);
      dispatch(resetMessage());
    }
  }, [companyProfileReducerData]);

  const handleRemoveImage = () => {
    dispatch(removeCompanyLogoRequest());
    setSelectedFile(null);
  };

  //read industry type data from store
  let functionalAreaReducerData = useSelector(
    (state) => state.functionalAreaReducer
  );
  console.log(functionalAreaReducerData);
  let functionalAreaData = functionalAreaReducerData.data.data;

  useEffect(() => {
    functionalAreaData = functionalAreaData || [];
    setFunctionalAreaOptions(functionalAreaData);
  }, [functionalAreaData]);

  const handleFunctionalAreaFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchFunctionalAreaRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleFunctionalAreaInputChange = useCallback((inputValue) => {
    // Update the location search value state
    // setLocationSearchValue(inputValue);

    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchIndustryRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleFunctionalAreaChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, functional_area: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      functional_area: selectedOptions, // Assuming selectedOptions is the value you want to set
    });
  };

  let companyReducerData = useSelector((state) => state.companyReducer);
  let companyData = companyReducerData.data.data;

  useEffect(() => {
    // Check if companyData is valid and not empty
    if (companyData && companyData.length > 0) {
      setCompanyOptions(companyData);
    }
  }, [companyData]); // Update state when companyData changes

  const handleCompanyFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = "";
        dispatch(fetchCompanyRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCompanyInputChange = useCallback((inputValue) => {
    const fetchData = async () => {
      try {
        dispatch(fetchCompanyRequest(inputValue));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleCompanyChange = (selectedOption) => {
    const updatedErrors = { ...errors, company_name: "" };
    setErrors(updatedErrors);

    setFormData({ ...formData, company_name: selectedOption });
  };

  const handleBenefitsChange = (benefit) => {
    // Check if the option's value already exists in formData.benefits
    const existingOption = formData.benefits.find(
      (item) => item.value === benefit.value
    );

    // Toggle the selected state for the clicked benefit
    let updatedBenefits = [];
    if (existingOption) {
      updatedBenefits = formData.benefits.filter(
        (item) => item.value !== benefit.value
      );
    } else {
      updatedBenefits = [...formData.benefits, benefit];
    }

    // Update formData with the updated benefits array
    setFormData((prevFormData) => ({
      ...prevFormData,
      benefits: updatedBenefits,
    }));
  };

  const handleCodeChange = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code: country.dialCode,
    });
  };

  return (
    <Fragment>
      <Breadcrumbs title="Edit Company" mainTitle="Edit Company" />
      <Container fluid={true}>
        <Form onSubmit={handleSubmit} className="theme-form">
          <Card>
            <CardBody>
              <div className="profile_img_container d-flex justify-content-center position-relative">
                <div className="position-relative d-flex align-items-center">
                  {selectedFile ? (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Company Image"
                      className="rounded-circle profile_img"
                      width="100px"
                      height="100px"
                      style={{ objectFit: "contain" }}
                    />
                  ) : formData.company_logo !== "" ? (
                    <img
                      src={formData.company_logo}
                      alt="Company Image"
                      className="rounded-circle profile_img"
                      width="100px"
                      height="100px"
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <div
                      className="rounded-circle profile_img"
                      //  className="badge-image"
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#7366ff",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "40px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {formData.company_name
                        ? formData.company_name.label.charAt(0).toUpperCase()
                        : ""}
                    </div>
                  )}

                  <div className="icon_overlay text-center">
                    <Label htmlFor="fileInput">
                      <Edit className="edit-icon text-white" size={18} />
                    </Label>
                    <Input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  {(selectedFile || formData.company_logo !== "") && (
                    <Button
                      color="danger"
                      onClick={handleRemoveImage}
                      className="ms-3"
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "100%",
                        height: "30px",
                        padding: "0px 10px",
                        fontSize: "12px",
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </div>
              <div className="text-center mb-3 mt-2">
                <Label>Company Logo</Label>
              </div>
              <h5 className="mb-4">About the Company</h5>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="col-form-label m-0 pt-0">
                      Company Name<span className="text-danger">*</span>
                    </Label>
                    <CreatableSelect
                      isDisabled={true}
                      isClearable
                      className=""
                      options={companyOptions}
                      isLoading={companyReducerData.isLoading}
                      placeholder="Select Company..."
                      value={formData.company_name}
                      onChange={handleCompanyChange}
                      onInputChange={handleCompanyInputChange}
                      // styles={{
                      //   control: (provided, state) => ({
                      //     ...provided,
                      //     backgroundColor: "#f3f3ff",
                      //     border: "1px solid #ccc",
                      //     borderRadius: "4px",
                      //     height: "46px", // Adjust height as needed
                      //     padding: "0px", // Adjust padding as needed
                      //     boxShadow: state.isFocused
                      //       ? "0 0 0 2px rgba(0, 123, 255, 0.2)"
                      //       : "none",
                      //     "&:hover": {
                      //       borderColor: "#999",
                      //     },
                      //   }),
                      // }}
                      onFocus={handleCompanyFocus}
                    />
                    {errors.company_name && (
                      <small className="text-danger">
                        {errors.company_name}
                      </small>
                    )}
                  </FormGroup>
                  {/* <FormGroup>
                    <Label>
                      Company Name<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Company Name"
                      id="company_name"
                      name="company_name"
                      value={formData.company_name}
                      onChange={handleChange}
                    />
                    {errors.company_name && (
                      <small className="text-danger">
                        {errors.company_name}
                      </small>
                    )}
                  </FormGroup> */}
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company Email<span className="text-danger">*</span>
                    </Label>
                    <Input
                      readOnly={
                        companyProfile &&
                        companyProfileReducerData.isLoading != true &&
                        companyProfile.company_email
                          ? true
                          : false
                      }
                      className="form-control form-control-sm"
                      placeholder="Enter Company email"
                      id="company_email"
                      name="company_email"
                      value={formData.company_email}
                      onChange={handleChange}
                      style={
                        companyProfile &&
                        companyProfileReducerData.isLoading !== true &&
                        companyProfile.company_email
                          ? readOnlyStyle
                          : {}
                      }
                    />
                    {errors.company_email && (
                      <small className="text-danger">
                        {errors.company_email}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company Phone <span className="text-danger">*</span>
                    </Label>
                    <div className="d-flex">
                      <PhoneInput
                        style={{
                          width: "10px",
                          marginRight: "10px",
                        }}
                        inputProps={{
                          name: "phone",
                          autoFocus: true,
                          disabled: true,
                          // placeholder: "Enter country code",
                        }}
                        country={"in"} // Default country
                        value={formData.country_code}
                        onChange={handleCodeChange}
                        inputStyle={{
                          width: "100px", // Adjust width to fit country code
                          height: "38px",
                          //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                          paddingLeft: "3rem",
                          fontSize: "1rem",
                          lineHeight: "1.5",
                          color: "#495057",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          transition:
                            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                        }}
                        enableAreaCodes={false}
                      />
                      <Input
                        className="form-control form-control-sm"
                        placeholder="Enter phone number"
                        id="company_phone"
                        name="company_phone"
                        value={formData.company_phone}
                        onChange={handleChange}
                        style={{ marginLeft: "95px" }} // Add margin to separate the fields
                        maxLength={15} // Adjust the maximum length as per your requirement
                      />
                    </div>

                    {errors.company_phone && (
                      <small className="text-danger">
                        {errors.company_phone}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company Website URL<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter company Website URL"
                      id="company_website"
                      name="company_website"
                      value={formData.company_website}
                      onChange={handleChange}
                    />
                    {errors.company_website && (
                      <small className="text-danger">
                        {errors.company_website}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>
                      About Company<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      placeholder="Enter about the company"
                      id="company_about"
                      name="company_about"
                      rows={6}
                      value={formData.company_about}
                      onChange={handleChange}
                    />
                    {errors.company_about && (
                      <small className="text-danger">
                        {errors.company_about}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <h5 className="mb-4">Social Media</h5>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      LinkedIn URL<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter LinkedIn URL"
                      id="company_linkedin_url"
                      name="company_linkedin_url"
                      value={formData.company_linkedin_url}
                      onChange={handleChange}
                    />
                    {errors.company_linkedin_url && (
                      <small className="text-danger">
                        {errors.company_linkedin_url}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Facebook URL<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter Facebook URL"
                      id="company_facebook_url"
                      name="company_facebook_url"
                      value={formData.company_facebook_url}
                      onChange={handleChange}
                    />
                    {errors.company_facebook_url && (
                      <small className="text-danger">
                        {errors.company_facebook_url}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Twitter URL<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter Twitter URL"
                      id="company_twitter_url"
                      name="company_twitter_url"
                      value={formData.company_twitter_url}
                      onChange={handleChange}
                    />
                    {errors.company_twitter_url && (
                      <small className="text-danger">
                        {errors.company_twitter_url}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <h5 className="mb-4">Business Information</h5>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Number of Employees<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter Number of Employees"
                      id="no_of_employees"
                      name="no_of_employees"
                      value={formData.no_of_employees}
                      onChange={handleChange}
                    />
                    {errors.no_of_employees && (
                      <small className="text-danger">
                        {errors.no_of_employees}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>
                      Industry Type
                      <span className="text-danger">*</span>
                    </Label>

                    <Select
                      options={industryTypeOptions}
                      isLoading={industryTypeReducerData.isLoading}
                      placeholder="Select industry type..."
                      value={formData.industryType} // Use formData.industry directly
                      onChange={handleIndustryChange} // Handle change event
                      styles={customOptionsStyle}
                      onFocus={handleIndustryFocus} // Handle focus event
                      onInputChange={handleIndustryInputChange}
                    />
                    {errors.industryType && (
                      <small className="text-danger">
                        {errors.industryType}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Functional Area</Label>
                    <Select
                      isMulti
                      options={functionalAreaOptions}
                      isLoading={functionalAreaReducerData.isLoading}
                      placeholder="Select functional area..."
                      value={formData.functional_area}
                      onChange={handleFunctionalAreaChange}
                      styles={customOptionsStyle}
                      onFocus={handleFunctionalAreaFocus}
                      onInputChange={handleFunctionalAreaInputChange}
                    />
                    {/* {errors.functional_area && (
                      <small className="text-danger">
                        {errors.functional_area}
                      </small>
                    )} */}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <div className="mb-2">
                    <Label>
                      Company Technology
                      <span className="text-danger">*</span>
                    </Label>
                    <Select
                      isMulti
                      options={mandatorySkillsOptions}
                      isLoading={mandatorySkillsReducerData.mandatoryLoading}
                      value={formData.company_technology} // Use formData.skills directly
                      onChange={handleMandatorySkillsChange} // Handle change event
                      styles={customOptionsStyle}
                      placeholder={"Select Technology..."}
                      onFocus={handlemandatorySkillsFocus} // Handle focus event
                      onInputChange={handleMandatorySkillsInputChange}
                    />
                    <small>Note: You can select multiple technology</small>
                    <br />
                    {errors.company_technology && (
                      <small className="text-danger">
                        {errors.company_technology}
                      </small>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Annual Revenue<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter annual revenue"
                      id="annual_revenue"
                      name="annual_revenue"
                      value={formData.annual_revenue}
                      onChange={handleChange}
                    />
                    {errors.annual_revenue && (
                      <small className="text-danger">
                        {errors.annual_revenue}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <h5 className="mb-4">Address</h5>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company Address<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter company address"
                      id="company_address"
                      name="company_address"
                      value={formData.company_address}
                      onChange={handleChange}
                    />
                    {errors.company_address && (
                      <small className="text-danger">
                        {errors.company_address}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company City<span className="text-danger">*</span>
                    </Label>
                    {/* <Input
                      className="form-control form-control-sm"
                      placeholder="Company City"
                      id="company_city"
                      name="company_city"
                      value={formData.company_city}
                      onChange={handleChange}
                    /> */}
                    <Select
                      //  isMulti
                      options={locationOptions}
                      isLoading={locationReducerData.isLoading}
                      placeholder="Select locations..."
                      value={formData.company_city}
                      onChange={handleCompanyCityChange}
                      onInputChange={handleLocationInputChange}
                      styles={customOptionsStyle}
                      onFocus={handleLocationFocus}
                    />
                    {errors.company_city && (
                      <small className="text-danger">
                        {errors.company_city}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company State<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter company state"
                      id="company_state"
                      name="company_state"
                      value={formData.company_state}
                      onChange={handleChange}
                    />
                    {errors.company_state && (
                      <small className="text-danger">
                        {errors.company_state}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company Country<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter company country"
                      id="company_country"
                      name="company_country"
                      value={formData.company_country}
                      onChange={handleChange}
                    />
                    {errors.company_country && (
                      <small className="text-danger">
                        {errors.company_country}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company Zip Code<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter company zip code"
                      id="company_zip_code"
                      name="company_zip_code"
                      value={formData.company_zip_code}
                      onChange={handleChange}
                    />
                    {errors.company_zip_code && (
                      <small className="text-danger">
                        {errors.company_zip_code}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Company HQ Location<span className="text-danger">*</span>
                    </Label>
                    {/* <Input
                      className="form-control form-control-sm"
                      placeholder="Company HQ Location"
                      id="company_hq_location"
                      name="company_hq_location"
                      value={formData.company_hq_location}
                      onChange={handleChange}
                    /> */}
                    <Select
                      //  isMulti
                      options={locationOptions}
                      isLoading={locationReducerData.isLoading}
                      placeholder="Select locations..."
                      value={formData.company_hq_location}
                      onChange={handleHqLocationsChange}
                      onInputChange={handleLocationInputChange}
                      styles={customOptionsStyle}
                      onFocus={handleLocationFocus}
                    />

                    {errors.company_hq_location && (
                      <small className="text-danger">
                        {errors.company_hq_location}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <h5 className="mb-4">Benefits</h5>
              <Row>
                <Col xs="10" className="mb-3">
                  <FormGroup className="">
                    <Label className="mb-2">
                      Benefits <span className="text-danger">*</span>
                    </Label>
                    <div>
                      {benefitsOptions.map((benefit) => (
                        <Badge
                          key={benefit.value}
                          className={`me-2 mb-2 border rounded d-inline-flex align-items-center ${
                            formData.benefits.some(
                              (item) => item.value === benefit.value
                            )
                              ? "bg-primary text-white"
                              : "text-black"
                          }`}
                          style={{
                            cursor: "pointer",
                          }}
                          color="gray"
                          pill
                          onClick={() => handleBenefitsChange(benefit)}
                        >
                          {formData.benefits.some(
                            (item) => item.value === benefit.value
                          ) ? (
                            <FaCheck
                              style={{
                                marginRight: "5px",
                                height: "16px",
                                width: "16px",
                                paddingTop: "0px",
                              }}
                            />
                          ) : (
                            <FaPlus
                              style={{
                                marginRight: "5px",
                                height: "16px",
                                width: "16px",
                                paddingTop: "0px",
                              }}
                            />
                          )}
                          <span>{benefit.label}</span>
                        </Badge>
                      ))}
                    </div>
                    {/* <ReactQuill
                    className="quill-text"
                    theme="snow"
                    value={formData.benefits}
                    onChange={handleBenefitsChange}
                  /> */}
                  </FormGroup>
                  {errors.benefits && (
                    <small className="text-danger">{errors.benefits}</small>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Button
                color="primary"
                type="submit"
                disabled={companyProfileReducerData.isLoading}
              >
                Update
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </Fragment>
  );
};

export default CompanyProfilePage;
