// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchResumeTemplatesSuccess,
  fetchResumeTemplatesFailure,
} from "./actions";
import { FETCH_RESUME_TEMPLATES_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";
function* fetchgenerateResumesSaga(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("generate_resumes", action.payload)
    );
    console.log(response);
    yield put(fetchResumeTemplatesSuccess(response.data));
  } catch (error) {
    yield put(fetchResumeTemplatesFailure(error));
  }
}

function* generateResumesSaga() {
  yield takeLatest(FETCH_RESUME_TEMPLATES_REQUEST, fetchgenerateResumesSaga);
}

export default generateResumesSaga;
