import {
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  RESET_PASSWORD_MESSAGE,
} from "../../actionTypes";

const initialState = {
  status:false,
  isLoading: false,
  message: "",
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_PASSWORD_SUCCESS:
      console.log("us",action.payload);
      return {
        ...state,
        isLoading: false,
        status:action.payload.status,
        message: action.payload.message,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        status:action.payload.status,
        message: action.payload.message,
      };
    case RESET_PASSWORD_MESSAGE:
      return {
        ...state,
        status:false,
        isLoading:false,
        message: '',
      };
    default:
      return state;
  }
};

export default changePasswordReducer;
