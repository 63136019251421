import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Badge,
  Row,
  Col,
} from "reactstrap";

import Editpersonal from "./Editpersonal";
import Editsocialmedia from "./Editsocialmedia";
import AddskillModal from "./AddskillModal";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdCopy } from "react-icons/io";
import { RiMailSendLine } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import Desiredcareerprofile from "./Desiredcareerprofile";
import { MdOutlinePreview } from "react-icons/md";
import {
  handleCopyToClipboard,
  handleSendEmail,
  handlePhoneCall,
  capitalize,
  formatExperienceOutput,
  formatDate,
  formatExperienceYearAndMonth,
} from "../../Common/CommonFunction";
import {
  jobTypeOptions,
  employmentTypeOptions,
  shiftTypeOptions,
} from "../../../Constant";
import {
  fetchJobseekerDataRequest,
  resetData,
} from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  updateAvatar,
  resetAvatarData,
} from "../../../redux/jobseeker/update_avatar/actions";
import { showToast } from "../../Common/Toast";
import { useParams } from "react-router";
import { socialMediaOptions } from "../../../Constant";
import { BsAlignTop } from "react-icons/bs";
import { AVATAR } from "../../../Constant";
import { addContactedCountRequest } from "../../../redux/jobseeker/add_contacted_count/actions";
import { convertNumberToWords } from "../../Common/CommonFunction";

const MyProfileEdit = ({ profileData, validationMessages }) => {
  console.log("validationMessages33", validationMessages);

  const [isEditMode, setIsEditMode] = useState(false);
  const [showeditPersonal, setEditPersonal] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDesiredCareer, setShowDesiredCareer] = useState(false);
  const fileInputRef = useRef(null);
  const { cand_id, jobcad_id } = useParams();
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    // Dispatch action to update avatar immediately when file is selected
    updateAvatarAction(event.target.files[0]);
  };

  // Dispatch action to update avatar
  const updateAvatarAction = (file) => {
    console.log(file);
    if (file) {
      const formData = new FormData();
      formData.append("jobseeker_profile_image", file);
      console.log(formData);
      dispatch(updateAvatar(formData));
      // setTimeout(() => {
      //   dispatch(fetchJobseekerDataRequest());
      // }, 1100);
    }
  };

  const toggleEditMode = () => {
    setIsEditMode((prevEditMode) => !prevEditMode);
  };

  const toggleEditPersonal = () => {
    setEditPersonal(false);
  };

  const toggleSocialMedia = () => {
    setShowSocialMedia(!showSocialMedia);
  };

  const toggleAddSkillModal = () => {
    setShowSkillModal(false);
  };

  const [socialMedia, setsocialMedia] = useState({
    id: null,
    seeker_id: null,
    social_profile_name: "",
    url: "",
  });

  const emptyField = (str) => {
    if (str === null || "") {
      return "-";
    } else {
      return str;
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    // Add logic to handle save operation (e.g., send data to the server)
    // For now, just toggle back to view mode
    toggleEditMode();
  };

  const desiredCareerProfile = () => {
    setShowDesiredCareer(true);
  };

  const toggleDesiredCarrer = () => {
    setShowDesiredCareer(false);
  };

  const OpenEditPersonal = () => {
    setEditPersonal(true);
  };

  const avatarReducerData = useSelector((state) => state.avatarReducer);
  useEffect(() => {
    if (avatarReducerData.status && avatarReducerData.message != "") {
      showToast("success", avatarReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      // props.toggleModal();
      dispatch(resetAvatarData());
    } else if (
      avatarReducerData.status == false &&
      avatarReducerData.message != ""
    ) {
      showToast("error", avatarReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      // props.toggleModal();
      dispatch(resetAvatarData());
    }
  }, [avatarReducerData]);

  return (
    <>
      <Card style={{ fontSize: 15 }}>
        <CardHeader className="py-3">
          <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
            Basic Info
          </h4>
        </CardHeader>
        <CardBody className="">
          <div className="profile_img_container d-flex justify-content-between  position-relative ">
            <div className="d-flex align-items-center">
              {selectedFile ? (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Company logo"
                  className="rounded-circle profile_img "
                  width="80px"
                  height="80px"
                  style={{ objectFit: "cover" }}
                />
              ) : profileData.jobseeker_photo != "" ? (
                <img
                  src={profileData.jobseeker_photo}
                  alt="Company logo"
                  className="rounded-circle profile_img"
                  width="80px"
                  height="80px"
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <img
                  src={AVATAR}
                  alt="Company logo"
                  className="rounded-circle profile_img"
                  width="80px"
                  height="80px"
                  style={{ objectFit: "cover" }}
                />
              )}
              <div
                className="icon_overlay text-center"
                style={{ top: 65, left: 65 }}
              >
                <label htmlFor="fileInput">
                  <FiEdit className="edit-icon text-white" size={18} />
                </label>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              <div className="media-body" style={{ marginLeft: 14 }}>
                <a
                // to={`${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`}
                >
                  <h4 attrH5={{ className: "mb-1" }}>
                    {capitalize(emptyField(profileData.jobseeker_full_name))}
                  </h4>
                </a>
                {/* <p>DESIGNER</p> */}
                {/* Show validation message if there is any for the 'name' field */}
                {validationMessages &&
                  validationMessages.find((msg) => msg.field === "name") && (
                    <div
                      className="text-danger"
                      style={{ fontSize: "12px", marginTop: "5px" }}
                    >
                      {validationMessages.message}
                    </div>
                  )}
              </div>
            </div>

            <div className="ml-auto">
              <FiEdit
                className="edit-icon "
                size={22}
                onClick={OpenEditPersonal}
                title="Edit"
              />
            </div>
          </div>

          <div className="">
            <Row className="mt-3" style={{ lineHeight: "inherit" }}>
              <Col xs="5" className="fw-bold p-0 ps-3">
                Email
                <span style={{ float: "right" }}> : </span>
              </Col>

              <Col xs="7">
                {profileData.jobseeker_email1 ? (
                  <div>
                    <a
                      href={`mailto:${emptyField(
                        profileData.jobseeker_email1.trim()
                      )}`}
                      style={{ marginRight: 5 }}
                    >
                      {emptyField(profileData.jobseeker_email1.trim()).length >
                      17
                        ? emptyField(
                            profileData.jobseeker_email1.trim()
                          ).substring(0, 17) + "..."
                        : emptyField(profileData.jobseeker_email1.trim())}
                    </a>
                    <RiMailSendLine
                      className="email-icon "
                      title="Email"
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleSendEmail(profileData.jobseeker_email1)
                      }
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_email1)
                      }
                      title="Copy"
                    />{" "}
                  </div>
                ) : (
                  "N/A"
                )}
              </Col>
            </Row>

            <Row className="mt-2" style={{ lineHeight: "inherit" }}>
              <Col xs="5" className="fw-bold p-0 ps-3">
                Email2
                <span style={{ float: "right" }}> : </span>
              </Col>

              <Col xs="7">
                {profileData.jobseeker_email2 ? (
                  <div>
                    <a
                      href={`mailto:${emptyField(
                        profileData.jobseeker_email2.trim()
                      )}`}
                      style={{ marginRight: 5 }}
                    >
                      {/* {emptyField(email.trim())} */}
                      {emptyField(profileData.jobseeker_email2.trim()).length >
                      17
                        ? emptyField(
                            profileData.jobseeker_email2.trim()
                          ).substring(0, 17) + "..."
                        : emptyField(profileData.jobseeker_email2.trim())}
                    </a>
                    <RiMailSendLine
                      className="email-icon"
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleSendEmail(profileData.jobseeker_email2)
                      }
                      title="Mail"
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_email2)
                      }
                      title="Copy"
                    />
                    {/* Icon for copying email */}
                  </div>
                ) : (
                  "N/A"
                )}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold"
                style={{ lineHeight: "inherit" }}
              >
                Phone <span style={{ float: "right" }}> : </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_phone1 ? (
                  <div>
                    <span style={{ marginRight: 5 }}>
                      {emptyField(profileData.jobseeker_phone1.trim())}
                    </span>
                    <IoCallOutline
                      className="phone-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handlePhoneCall(profileData.jobseeker_phone1)
                      }
                      title="Call"
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_phone1)
                      }
                      title="Copy"
                    />
                  </div>
                ) : (
                  "N/A"
                )}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold"
                style={{ lineHeight: "inherit" }}
              >
                Phone2 <span style={{ float: "right" }}> : </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_phone2 ? (
                  <div>
                    <span style={{ marginRight: 5 }}>
                      {emptyField(profileData.jobseeker_phone2.trim())}
                    </span>
                    <IoCallOutline
                      className="phone-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handlePhoneCall(profileData.jobseeker_phone2)
                      }
                      title="Call"
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_phone2)
                      }
                      title="Copy"
                    />
                  </div>
                ) : (
                  "N/A"
                )}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold"
                style={{ lineHeight: "inherit" }}
              >
                DOB<span style={{ float: "right" }}> : </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_date_of_birth
                  ? formatDate(profileData.jobseeker_date_of_birth)
                  : "N/A"}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold"
                style={{ lineHeight: "inherit" }}
              >
                Gender<span style={{ float: "right" }}> : </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_gender
                  ? capitalize(profileData.jobseeker_gender)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Marital Status
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_marital_status
                  ? capitalize(profileData.jobseeker_marital_status)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Differently Abled
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_differently_abled
                  ? capitalize(profileData.jobseeker_differently_abled)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Permanent Address
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_permanent_address
                  ? capitalize(profileData.jobseeker_permanent_address)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Home Town
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_hometown
                  ? capitalize(profileData.jobseeker_hometown)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Pin Code
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_pincode
                  ? profileData.jobseeker_pincode
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Total Experience
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.total_exp_years >= 0 ||
                profileData.total_exp_months >= 0
                  ? formatExperienceYearAndMonth(
                      parseInt(profileData.total_exp_years),
                      parseInt(profileData.total_exp_months)
                    )
                  : "N/A"}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Current Location
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_location
                  ? capitalize(profileData.jobseeker_location.label)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className="p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Preferred Location
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_location &&
                profileData.jobseeker_preferred_location.length > 0
                  ? profileData.jobseeker_preferred_location
                      .map((location) => capitalize(location.label))
                      .join(", ")
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className="p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Notice period
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_notice_period
                  ? `${profileData.jobseeker_notice_period} Days`
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Foreign Work Permit
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_work_permit_for_other_countries &&
                profileData.jobseeker_work_permit_for_other_countries.length > 0
                  ? profileData.jobseeker_work_permit_for_other_countries
                      .map((country) => capitalize(country.label))
                      .join(", ")
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Current CTC
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_current_salary
                  ? `${
                      profileData.jobseeker_current_salary
                    } (${convertNumberToWords(
                      profileData.jobseeker_current_salary
                    )})`
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Expected CTC
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_salary
                  ? `${
                      profileData.jobseeker_preferred_salary
                    } (${convertNumberToWords(
                      profileData.jobseeker_preferred_salary
                    )})`
                  : "N/A"}
              </Col>
            </Row>
          </div>

          <hr />

          <div className="d-flex align-items-center justify-content-between mt-4">
            <h5 className="mb-0 me-2">Desired Career Profile</h5>

            <div className="ms-2">
              <div className="d-flex">
                <FiEdit
                  className="edit-icon "
                  size={22}
                  onClick={desiredCareerProfile}
                  title="Edit"
                />
              </div>
            </div>
          </div>
          <div className="mt-3 ps-2">
            <Row className="mt-4">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Job Title
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_job_title
                  ? capitalize(profileData.jobseeker_preferred_job_title)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Desired Industry
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_industry &&
                profileData.jobseeker_preferred_industry.label
                  ? capitalize(profileData.jobseeker_preferred_industry.label)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Functional Area
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_industry &&
                profileData.jobseeker_preferred_department.label
                  ? capitalize(profileData.jobseeker_preferred_department.label)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Job Preferences
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_job_type
                  ? jobTypeOptions.find(
                      (option) =>
                        option.value == profileData.jobseeker_preferred_job_type
                    )?.label
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className=" p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Employment Type
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_employment_type
                  ? employmentTypeOptions.find(
                      (option) =>
                        option.value ==
                        profileData.jobseeker_preferred_employment_type
                    )?.label
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs="5"
                className="p-0 ps-3 fw-bold d-flex align-items-start"
                style={{ lineHeight: "inherit" }}
              >
                Shift Preference
                <span className="ms-auto" style={{ alignSelf: "flex-start" }}>
                  {" "}
                  :{" "}
                </span>
              </Col>
              <Col xs="7">
                {profileData.jobseeker_preferred_shift
                  ? profileData.jobseeker_preferred_shift
                      .split(",")
                      .map(
                        (shift) =>
                          shiftTypeOptions.find(
                            (option) => option.value === shift
                          )?.label
                      )
                      .join(", ")
                  : "N/A"}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      {showeditPersonal && (
        <Editpersonal
          isOpen={showeditPersonal}
          toggleModal={toggleEditPersonal}
          data={profileData}
        />
      )}

      {showDesiredCareer && (
        <Desiredcareerprofile
          isOpen={showDesiredCareer}
          toggleModal={toggleDesiredCarrer}
          data={profileData}
        />
      )}
    </>
  );
};

export default MyProfileEdit;
