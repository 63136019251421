import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import SavedCandidates from "../../Components/Recruiter/SavedCandidates/SavedCandidates";
const fetchSavedCandidatesSaga = () => {
  return (
    <Fragment>
      <Breadcrumbs title="Saved Candidates" mainTitle="Saved Candidates" />
      <Container fluid={true}>
        <SavedCandidates />
      </Container>
    </Fragment>
  );
};

export default fetchSavedCandidatesSaga;
