import {
  INVITE_RECRUITER_REQUEST,
  INVITE_RECRUITER_SUCCESS,
  INVITE_RECRUITER_FAILURE,
  RESET_INVITE_RECRUITER,
} from "../../actionTypes";

export const inviteRecruiterRequest = (message) => ({
  type: INVITE_RECRUITER_REQUEST,
  payload: message,
});

export const inviteRecruiterSuccess = (data) => ({
  type: INVITE_RECRUITER_SUCCESS,
  payload: data,
});

export const inviteRecruiterFailure = (data) => ({
  type: INVITE_RECRUITER_FAILURE,
  payload: data,
});

export const resetInviteRecruiter = () => ({
  type: RESET_INVITE_RECRUITER,
});
