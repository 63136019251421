import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardHeader, CardBody, Button, Table, Card } from "reactstrap";
import { FiStar } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  extractAfterLastHyphen,
  convertMonthsToYearsAndMonths,
} from "../../Common/CommonFunction";

const Mainskills = ({ profileData }) => {
  const dispatch = useDispatch();

  const emptyField = (str) => {
    if (str === "" || str === null) {
      return "-";
    } else {
      return str;
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between ">
            <h4 className="mb-0" style={{ fontWeight: 550 }}>
              Main Skills
            </h4>
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ps-2 table-responsive">
            {profileData.it_skills && profileData.it_skills.length > 0 ? (
              <>
                <Table
                  borderless
                  className="table-responsive-sm table-responsive-md"
                >
                  {/* Table header */}
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Skills</th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Version
                      </th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Last Used
                      </th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Experience
                      </th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Rating
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody>
                    {profileData.it_skills.map((skill) => {
                      const isPrimary = profileData.primary_skill
                        .split(",")
                        .map((s) => s.trim())
                        .includes(skill.it_skill.label);

                      return (
                        <tr key={skill.id}>
                          <td>
                            {isPrimary ? (
                              <span className="primary-skill text-primary">
                                {skill.it_skill.label}{" "}
                                <FiStar
                                  size={10}
                                  style={{
                                    marginLeft: "3px",
                                    color: "blue", // Example: Change star color for primary skill
                                  }}
                                />
                              </span>
                            ) : (
                              skill.it_skill.label
                            )}
                          </td>
                          <td className="text-center">
                            {emptyField(skill.skill_version)}
                          </td>
                          <td className="text-center">
                            {emptyField(skill.skill_last_used)}
                          </td>
                          <td className="text-center">
                            {emptyField(
                              convertMonthsToYearsAndMonths(
                                skill.skill_experienced
                              )
                            )}
                          </td>
                          <td className="text-center">
                            {emptyField(skill.skill_rating)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className="text-center mt-3">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Mainskills;
