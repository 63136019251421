import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import {
  addMainSkillFailure,
  addMainSkillSuccess,
  deleteMainSkillFailure,
  deleteMainSkillSuccess,
} from "./actions";

function* addMainSkill(action) {
  console.log("ad", action);
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/add_it_skills", action.payload)
    );
    console.log("sr", response);
    if (response.data.status) {
      yield put(addMainSkillSuccess(response.data));
    } else {
      yield put(addMainSkillFailure(response.data));
    }
  } catch (error) {
    yield put(addMainSkillFailure({ status: false, message: error.message }));
  }
}

function* deleteMainSkill(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/delete_it_skills", action.payload)
    );
    if (response.data.status) {
      yield put(deleteMainSkillSuccess(response.data));
    } else {
      yield put(deleteMainSkillFailure(response.data));
    }
  } catch (error) {
    yield put(
      deleteMainSkillFailure({ status: false, message: error.message })
    );
  }
}

function* mainSkillSaga() {
  yield takeLatest(types.ADD_TECHNICAL_SKILL_REQUEST, addMainSkill);
  yield takeLatest(types.DELETE_TECHNICAL_SKILLS_REQUEST, deleteMainSkill);
}

export default mainSkillSaga;
