import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import ChatAppContext from "../../../Chat";
import SearchChatList from "./SearchChatList";
import CurrentUser from "./CurrentUser";
import avatar from "../../../../src/assets/images/avatar.png";
import { capitalize } from "../../Common/CommonFunction";
import Spinner from "../../Common/Spinner";
import { NotificationContext } from "../../../Notifications/NotificationContext";
const ChatStatus = () => {
  const {
    members = [],
    memberLoading,
    changeChat,
    searchKeyword = "",
  } = useContext(ChatAppContext) || {};

  const filteredMembers = members.filter((member) =>
    member.recruiter_full_name
      ? member.recruiter_full_name
          .toLowerCase()
          .includes(searchKeyword.toLowerCase())
      : false
  );

  const handleChatChange = (member) => {
    // Update unread message count to 0 when changing chats
    changeChat(member);
    member.unread_msg_count = 0; // Reset unread message count
  };

  return (
    <Fragment>
      <div className="chat-box">
        <div className="chat-left-aside">
          {/* <CurrentUser /> */}
          <div className="people-list" id="people-list">
            <SearchChatList />
            {memberLoading ? (
              <Spinner />
            ) : filteredMembers.length > 0 ? (
              <ul className="simple-list list custom-scrollbar">
                {filteredMembers.map((member) => {
                  const {
                    chat_id,
                    profile_image,
                    recruiter_full_name,
                    status,
                    online,
                    unread_msg_count,
                  } = member;
                  return (
                    <li
                      className={`clearfix border-0 ${
                        member?.chat_id === chat_id ? "active" : ""
                      }`}
                      key={chat_id}
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleChatChange(member); // Call the modified function
                        }}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={profile_image || avatar}
                            className="rounded-circle user-image"
                            alt="image"
                          />

                          <div className="about">
                            <div className="name">
                              {capitalize(recruiter_full_name)}
                            </div>
                            <div className="status">{status}</div>
                          </div>
                        </div>
                        {unread_msg_count !== 0 && (
                          <span className="badge rounded-pill badge-success me-3">
                            {unread_msg_count}
                          </span>
                        )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="no-results">
                <p>No results found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatStatus;
