// reducers.js
import {
  JOB_SHARING_REQUEST,
  JOB_SHARING_SUCCESS,
  JOB_SHARING_FAILURE,
  RESET_MESSAGE,
} from "../actionTypes";

const initialState = {
  data: null, // Initialize data to null
  message: "",
  isLoading: false,
  status: false,
};

const jobSharingReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_SHARING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case JOB_SHARING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case JOB_SHARING_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_MESSAGE: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };

    default:
      return state;
  }
};

export default jobSharingReducer;
