// sagas.jsx
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchJobseekerReferralSuccess,
  fetchJobseekerReferralFailure,
} from "./actions";
import { FETCH_JOBSEEKER_REFERRAL_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* fetchJobseekerReferralDataSaga(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/referral", action.payload)
    );

    const totalRefferPoint = response.data.total_reffer_point;

    yield put(fetchJobseekerReferralSuccess(response.data)); // Dispatch success action with response data
  } catch (error) {
    yield put(fetchJobseekerReferralFailure(error.message)); // Dispatch failure action with error message
  }
}

function* referralJobseekerSaga() {
  yield takeLatest(
    FETCH_JOBSEEKER_REFERRAL_REQUEST,
    fetchJobseekerReferralDataSaga
  ); 
}

export default referralJobseekerSaga;
