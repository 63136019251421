import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { publicApplyJobSuccess, publicApplyJobFailure } from "./actions";
import { PUBLIC_APPLY_JOB_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* publicApplyJob(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/public_apply_job", action.payload)
    );

    if (response.data.status) {
      yield put(publicApplyJobSuccess(response.data));
    } else {
      yield put(publicApplyJobFailure(response.data));
    }
  } catch (error) {
    yield put(publicApplyJobFailure({ status: false, message: error.message }));
  }
}

// Starts submitFeedbackSaga on each dispatched SUBMIT_FEEDBACK_REQUEST action
function* publicApplyJobSaga() {
  yield takeLatest(PUBLIC_APPLY_JOB_REQUEST, publicApplyJob);
}

export default publicApplyJobSaga;
