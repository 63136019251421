import React, { Fragment, useState, useContext, useEffect } from "react";
import { Card, Col, CardBody, Row, Badge } from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Briefcase, MapPin, DollarSign, Clock } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { MdCurrencyRupee } from "react-icons/md";
import { LuClock3 } from "react-icons/lu";
import { FaRegBuilding } from "react-icons/fa";
import { FiStar } from "react-icons/fi"; // Ensure FiStar is imported
import { PiSuitcaseSimple } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineAddLocation } from "react-icons/md";
import { GrLocation } from "react-icons/gr";

import {
  timeAgo,
  capitalize,
  formatExperienceOutput,
  generateSlug,
  formatSalaryOutput,
  removeHTMLTags,
} from "../../../Components/Common/CommonFunction";
import Spinner from "../../Common/Spinner";
import NoDataFound from "../../Common/NoDataFound";
import { AuthContext } from "../../../Auth/AuthContext";
import {
  saveFavoriteJobRequest,
  resetFavoriteMessage,
} from "../../../redux/jobseeker/favorite_job/actions";
import { showToast } from "../../Common/Toast";
import Pagination from "../../Common/Pagination";
import { JobSearchContext } from "../../../JobSearch/JobSearchContext";
const ListCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { filters, updatePagePerpage } = useContext(JobSearchContext);
  const [rating, setRating] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [savedJobs, setSavedJobs] = useState({});
  console.log("archana", user);
  const jobSearchReducerData = useSelector((state) => state.jobSearchReducer);
  // const isLoading = jobSearchReducerData.isLoading;
  // const jobSearchData = jobSearchReducerData.data?.data || [];

  let jobSearchData = [];
  if (!jobSearchReducerData.isLoading && jobSearchReducerData.data.data) {
    jobSearchData = jobSearchReducerData.data.data;
  }

  const totalPages = jobSearchReducerData
    ? Math.ceil(jobSearchReducerData.data.total / perPage)
    : 0;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    updatePagePerpage({ page: currentPage, per_page: perPage });
  }, [currentPage, perPage]);

  const handleFavouriteJobClick = (id) => {
    if (user.isLoggedin && user.type == "JOBSEEKER") {
      dispatch(saveFavoriteJobRequest({ job_id: id }));
      setSavedJobs((prev) => ({ ...prev, [id]: true }));
    } else {
      // navigate(`${process.env.PUBLIC_URL}/auth/login`);
      navigate("/auth/login");
    }
  };
  useEffect(() => {
    if (jobSearchData.length > 0) {
      const initialSavedJobs = jobSearchData.reduce((acc, job) => {
        acc[job.job_id] = job.is_saved;
        return acc;
      }, {});
      setSavedJobs(initialSavedJobs);
    }
  }, [jobSearchData]);

  const favoriteJobReducerData = useSelector(
    (state) => state.favoriteJobReducer
  );
  useEffect(() => {
    if (
      favoriteJobReducerData.status &&
      favoriteJobReducerData.message !== ""
    ) {
      showToast("success", favoriteJobReducerData.message);
      dispatch(resetFavoriteMessage());
      // navigate(`${process.env.PUBLIC_URL}/jobseeker/saved_jobs`);
      //navigate(`${process.env.PUBLIC_URL}/jobseeker/saved_jobs`);
    } else if (
      favoriteJobReducerData.status === false &&
      favoriteJobReducerData.message !== ""
    ) {
      showToast("error", favoriteJobReducerData.message);
      dispatch(resetFavoriteMessage());
    }
  }, [favoriteJobReducerData, navigate]);

  const handleCardClick = (event, job) => {
    event.preventDefault(); // Prevent the default behavior of the link

    // Custom logic using the job object
    const targetUrl =
      user.isLoggedin && user.type === "JOBSEEKER"
        ? `${process.env.PUBLIC_URL}/jobseeker/job_details/${generateSlug(
            job.job_name,
            job.job_location,
            formatExperienceOutput(
              job.work_experience_min,
              job.work_experience_max
            ).replace("-", "-to-"),
            job.job_id
          )}`
        : `${process.env.PUBLIC_URL}/public/job_details/${generateSlug(
            job.job_name,
            job.job_location,
            formatExperienceOutput(
              job.work_experience_min,
              job.work_experience_max
            ).replace("-", "-to-"),
            job.job_id
          )}`;

    // Perform the redirection
    navigate(targetUrl);
  };

  return (
    <Fragment>
      <Col xl="9" className="box-col-8 xl-70">
        {jobSearchReducerData.isLoading ? (
          <Spinner color="primary" />
        ) : !jobSearchReducerData.isLoading &&
          jobSearchData &&
          jobSearchData.length > 0 ? (
          <>
            <Row className="mb-4">
              {jobSearchData.map((job, index) => (
                <Col xl="12" className="xl-100 box-col-12" key={index}>
                  <Card style={{ minHeight: "285px" }}>
                    <div className="job-search">
                      <CardBody>
                        <div className="media">
                          <div className="media-body">
                            <h5 className="mb-0">
                              <Link onClick={(e) => handleCardClick(e, job)}>
                                {capitalize(job.job_name)}
                              </Link>
                            </h5>
                            <span
                              className="me-2 d-flex align-items-center  f-w-500 "
                              style={{ minHeight: "1em" }}
                            >
                              {/* <FaRegBuilding
                          size={16}
                          className="me-1"
                          style={{ color: "green" }}
                        /> */}
                              <span className="me-1">
                                {job.company_name &&
                                  capitalize(job.company_name)}
                              </span>

                              {job.average_rating && (
                                <div className="stars me-2 icon-container ">
                                  {[...Array(5)].map((_, i) => {
                                    const starValue = i + 1;
                                    const averageRating =
                                      job.average_rating ?? "";
                                    return (
                                      <span
                                        key={starValue}
                                        className=""
                                        style={{
                                          cursor: "pointer",
                                          color:
                                            starValue <= averageRating
                                              ? "orange"
                                              : "gray",
                                          fontSize: "calc(0.8em + 0.5vw)",
                                        }}
                                      >
                                        &#9733;
                                      </span>
                                    );
                                  })}
                                </div>
                              )}
                            </span>
                            <p
                              className=" d-flex align-items-center flex-wrap  f-w-500"
                              style={{ color: "#121224", minHeight: "3em" }} // Adjust minHeight as needed
                            >
                              <span className=" d-flex align-items-center">
                                <PiSuitcaseSimple
                                  size={19}
                                  className="me-1 suit-case-icon"
                                />
                                {job.work_experience_min ||
                                job.work_experience_max ? (
                                  <div className="mb-0 d-flex align-items-center">
                                    <span>
                                      {formatExperienceOutput(
                                        job.work_experience_min,
                                        job.work_experience_max
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  "N/A"
                                )}
                              </span>
                              <span className="mx-2">|</span>{" "}
                              {job.is_disclose_salary == 1 ? (
                                <span className="me-2 mb-0 d-flex align-items-center">
                                  <MdCurrencyRupee
                                    className="me-1 rupee-icon"
                                    size={18}
                                  />
                                  {job.salary_min || job.salary_max
                                    ? formatSalaryOutput(
                                        job.salary_min,
                                        job.salary_max
                                      )
                                    : "N/A"}
                                </span>
                              ) : (
                                // Salary is not disclosed
                                <span className="me-2 mb-0 d-flex align-items-center">
                                  <MdCurrencyRupee
                                    className="me-1 rupee-icon"
                                    size={18}
                                  />
                                  Not disclosed
                                </span>
                              )}
                              <span className="mx-2">|</span>{" "}
                              <span className="d-flex align-items-center">
                                <GrLocation
                                  size={19}
                                  className="me-1 location-icon"
                                />
                                {job.job_location
                                  ? capitalize(job.job_location)
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                          {job.company_logo ? (
                            <img
                              src={job.company_logo}
                              alt="Badge Placeholder"
                              className="badge-image"
                              width={60}
                              height={60}
                            />
                          ) : job.company_name ? (
                            <div
                              className="badge-image"
                              style={{
                                width: "100px",
                                height: "80px",
                                backgroundColor: "#7366ff",
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "40px",
                                fontWeight: "bold",
                                color: "white",
                              }}
                            >
                              {job.company_name
                                ? job.company_name.charAt(0).toUpperCase()
                                : ""}
                            </div>
                          ) : (
                            <div
                              className="badge-image"
                              style={{
                                width: "100px",
                                height: "80px",
                                backgroundColor: "#7366ff",
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "40px",
                                fontWeight: "bold",
                                color: "white",
                              }}
                            >
                              <FaRegBuilding />
                            </div>
                          )}
                        </div>
                        {job.job_description && (
                          <div
                            className="pt-2"
                            style={{
                              maxHeight: "54px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2, // Maximum number of lines to display
                              whiteSpace: "normal",
                              // fontSize: "14px", // Set the font size to 15px
                              // color: "#999999", // Adjust the color as needed
                            }}
                          >
                            {removeHTMLTags(job.job_description)}
                          </div>
                        )}
                        {job.mandatory_skills && (
                          <div className="mb-2 mt-2">
                            <div>
                              {job.mandatory_skills && (
                                <div className="mb-3">
                                  <div>
                                    {job.mandatory_skills
                                      .split(",")
                                      .map((skill) => skill.trim())
                                      .sort((a, b) =>
                                        a === job.primary_skill
                                          ? -1
                                          : b === job.primary_skill
                                          ? 1
                                          : 0
                                      ) // Sort to ensure primary skill is first
                                      .slice(0, 3)
                                      .map((skill, index) => {
                                        const isPrimary =
                                          skill === job.primary_skill; // Check if current skill is primary
                                        return (
                                          <Badge
                                            key={index}
                                            className={`me-2 mt-2 border rounded ${
                                              isPrimary
                                                ? "fw-bolder text-primary"
                                                : "text-black"
                                            }`}
                                            color="gray"
                                            pill
                                          >
                                            {skill}
                                            {isPrimary && (
                                              <FiStar
                                                style={{
                                                  marginLeft: "5px",
                                                  color: "blue", // Example: Change star color for primary skill
                                                }}
                                              />
                                            )}
                                          </Badge>
                                        );
                                      })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <p className="mt-2 d-flex justify-content-between">
                          {job.created_at && (
                            <span className="d-flex align-items-center text-muted ">
                              <LuClock3
                                size={16}
                                style={{
                                  marginRight: "5px",
                                }}
                              />{" "}
                              {timeAgo(job.created_at)}
                            </span>
                          )}

                          {user.isLoggedin && user.type == "JOBSEEKER" && (
                            <span
                              onClick={() => {
                                if (!savedJobs[job.job_id])
                                  handleFavouriteJobClick(job.job_id);
                              }}
                              className={`sidebar-link sidebar-title link-nav d-flex align-items-center  ${
                                savedJobs[job.job_id] ? "disabled" : ""
                              }`}
                              style={{ cursor: "pointer" }} // Inline style as fallback
                            >
                              <FaRegHeart className="me-2" size={16} />
                              {savedJobs[job.job_id] ? "Saved" : "Save"}
                            </span>
                          )}
                        </p>
                      </CardBody>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            {totalPages > 1 && (
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </>
        ) : (
          <Card className="pt-3 pb-2">
            <NoDataFound message={"No jobs found"} />
          </Card>
        )}
      </Col>
    </Fragment>
  );
};

export default ListCard;
