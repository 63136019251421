import {
  POST_JOB_REQUEST,
  POST_JOB_SUCCESS,
  POST_JOB_FAILURE,
  FETCH_JOB_REQUEST,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

export const postJobRequest = (jobData) => ({
  type: POST_JOB_REQUEST,
  payload: jobData,
});

export const postJobSuccess = (response) => ({
  type: POST_JOB_SUCCESS,
  payload: response,
});

export const postJobFailure = (error) => ({
  type: POST_JOB_FAILURE,
  payload: error,
});

export const fetchJobRequest = (jobId) => ({
  type: FETCH_JOB_REQUEST,
  payload: jobId,
});

export const fetchJobSuccess = (jobData) => ({
  type: FETCH_JOB_SUCCESS,
  payload: jobData,
});

export const fetchJobFailure = (error) => ({
  type: FETCH_JOB_FAILURE,
  payload: error,
});

export const resetJob = () => ({
  type: RESET_JOB,
});
