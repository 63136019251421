import React, { Fragment, useContext, useState, useEffect } from "react";
import { Plus } from "react-feather";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import NotificationsList from "../Components/Notifications/NotificationsList";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../Components/Common/Breadcrumb";
import { markAllAsReadRequest } from "../redux/notifications/actions";
import { useSelector } from "react-redux";
import { NotificationProvider } from "../Notifications/NotificationContext";
import { useNotification } from "../Notifications/NotificationContext";
import { NotificationContext } from "../Notifications/NotificationContext";
const NotificationsPage = () => {
  const dispatch = useDispatch();

  const { unreadNotificationsCount } = useContext(NotificationContext);
  const handleMarkAllAsRead = () => {
    dispatch(markAllAsReadRequest());
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Notifications"
        parent="Apps"
        title="Notifications"
      />
      <Container fluid={true} className="email-wrap bookmark-wrap todo-wrap">
        <Row>
          <Col xl="12" className="xl-100 box-col-12">
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between">
                <h5>Notifications</h5>
                {unreadNotificationsCount !== 0 && (
                  <div className="d-flex align-items-center gap-3">
                    <div className="todo">
                      <div className="todo-list-wrapper">
                        <div className="mark-all-tasks">
                          <div className="mark-all-tasks-container">
                            <span
                              className="mark-all-btn d-flex align-items-center gap-1"
                              id="mark-all-finished"
                              role="button"
                              onClick={handleMarkAllAsRead} // Handle click event
                            >
                              <span className="btn-label">
                                Mark all as read
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </CardHeader>
              <CardBody>
                <div className="todo">
                  <div className="todo-list-wrapper">
                    <div className="todo-list-container todo-list-footer">
                      <NotificationsList />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default NotificationsPage;
