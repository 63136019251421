import {
  CHECK_CHAT_EXIST_REQUEST,
  CHECK_CHAT_EXIST_SUCCESS,
  CHECK_CHAT_EXIST_FAILURE,
  FETCH_CHAT_MESSAGES_REQUEST,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES_FAILURE,
  SEND_CHAT_MESSAGE_REQUEST,
  SEND_CHAT_MESSAGE_SUCCESS,
  SEND_CHAT_MESSAGE_FAILURE,
  RESET_CHAT_STATE,
  FETCH_CHAT_MEMBERS_REQUEST,
  FETCH_CHAT_MEMBERS_SUCCESS,
  FETCH_CHAT_MEMBERS_FAILURE,
  SEND_CHAT_INVITE_SUCCESS,
} from "../../../actionTypes";

const initialState = {
  checkChatExist: {
    isLoading: false,
    error: null,
    chatId: null,
    status: false,
  },
  fetchChatMessages: {
    isLoading: false,
    status: false,
    error: null,
    data: {}, // Initialize as an empty object
    profileDetails: null,
  },
  sendChatMessage: {
    isLoading: false,
    chatId: null,
    error: null,
    message: null,
    status: false,
    is_replying_to_recruiter: 0,
  },
  fetchChatMembers: {
    isLoading: false,
    error: null,
    totalUnread: null,
    data: [],
  },
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_CHAT_EXIST_REQUEST:
      return {
        ...state,
        checkChatExist: {
          ...state.checkChatExist,
          isLoading: true,
          error: null,
        },
      };
    case CHECK_CHAT_EXIST_SUCCESS:
      return {
        ...state,
        checkChatExist: {
          ...state.checkChatExist,
          isLoading: false,
          chatId: action.payload.chat_id,
          status: action.payload.status,
        },
      };
    case CHECK_CHAT_EXIST_FAILURE:
      return {
        ...state,
        checkChatExist: {
          ...state.checkChatExist,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_CHAT_MESSAGES_REQUEST:
      return {
        ...state,
        fetchChatMessages: {
          ...state.fetchChatMessages,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        fetchChatMessages: {
          ...state.fetchChatMessages,
          isLoading: false,
          data: action.payload.chat_messages,
          profileDetails: action.payload.profile_details,
          status: action.payload.status,
        },
      };
    case FETCH_CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        fetchChatMessages: {
          ...state.fetchChatMessages,
          isLoading: false,
          error: action.payload,
          status: action.payload.status,
        },
      };
    case SEND_CHAT_MESSAGE_REQUEST:
      return {
        ...state,
        sendChatMessage: {
          ...state.sendChatMessage,
          isLoading: true,
          error: null,
        },
      };
    case SEND_CHAT_MESSAGE_SUCCESS:
      const newMessage = action.payload.data;
      const date = new Date(newMessage.created_at).toISOString().split("T")[0];

      return {
        ...state,
        sendChatMessage: {
          ...state.sendChatMessage,
          isLoading: false,
          message: action.payload.message,
          status: action.payload.status,
          is_replying_to_recruiter: action.payload.is_replying_to_recruiter,
          //  chatId: action.payload.chat_id,
        },

        fetchChatMessages: {
          ...state.fetchChatMessages,
          data: {
            ...state.fetchChatMessages.data,
            [date]: [...(state.fetchChatMessages.data[date] || []), newMessage],
          },
        },
      };
    case SEND_CHAT_INVITE_SUCCESS:
      return {
        ...state,
        sendChatMessage: {
          ...state.sendChatMessage,
          isLoading: false,
          message: action.payload.message,
          status: action.payload.status,
          chatId: action.payload.chat_id,
        },
      };
    case SEND_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
        sendChatMessage: {
          ...state.sendChatMessage,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_CHAT_MEMBERS_REQUEST:
      return {
        ...state,
        fetchChatMembers: {
          ...state.fetchChatMembers,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_CHAT_MEMBERS_SUCCESS:
      console.log("FETCH_CHAT_MEMBERS_SUCCESS", action.payload);
      return {
        ...state,
        fetchChatMembers: {
          ...state.fetchChatMembers,
          isLoading: false,
          data: action.payload.data,
          totalUnread: action.payload.total_unread,
          error: null,
        },
      };
    case FETCH_CHAT_MEMBERS_FAILURE:
      return {
        ...state,
        fetchChatMembers: {
          ...state.fetchChatMembers,
          isLoading: false,
          error: action.payload,
        },
      };
    case RESET_CHAT_STATE:
      const { arrayName } = action;
      return {
        ...state,
        [arrayName]: [], // Reset the specified array to an empty array
      };
    default:
      return state;
  }
};

export default chatReducer;
