import {
  PUBLIC_APPLY_JOB_REQUEST,
  PUBLIC_APPLY_JOB_SUCCESS,
  PUBLIC_APPLY_JOB_FAILURE,
  PUBLIC_APPLY_JOB_RESET,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,

  message: "",
};

const publicApplyJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUBLIC_APPLY_JOB_REQUEST:
      console.log("feedback reducer line no. 17", action.payload);
      return {
        ...state,
        isLoading: true,
      };
    case PUBLIC_APPLY_JOB_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };
    case PUBLIC_APPLY_JOB_FAILURE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: action.payload.message,
      };
    case PUBLIC_APPLY_JOB_RESET: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };
    default:
      return state;
  }
};

export default publicApplyJobReducer;
