import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import RecommendedJobs from "../../Components/Jobseeker/Jobs/RecommendedJobs";
// import CardsClass from './Cards';
const RecommendedJobsPage = () => {
  return (
    <Fragment>
      <Breadcrumbs title="Recommended Jobs" mainTitle="Recommended Jobs" />
      <Container fluid={true}>
        <RecommendedJobs />
      </Container>
    </Fragment>
  );
};

export default RecommendedJobsPage;
