import React from "react";
import { Card, CardBody, Col, Row, Progress } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import YourBalanceCard from "../../Common/CommonWidgets/YourBalanceCard";
import { Link } from "react-router-dom";
import { AVATAR } from "../../../Constant";
import { capitalize } from "../../Common/CommonFunction";
import { useNavigate } from "react-router-dom";
const GreetingCard = () => {
  // Retrieve data from the Redux store using useSelector hook
  const navigate = useNavigate();
  const dashboardReducerdData = useSelector(
    (state) => state.jobseekerDashboardReducer
  );

  let countData = [];
  let profileCompletionPercentage;
  let visitorCount;
  let contactCount;
  // Check if dashboardReducerdData is not loading and contains the necessary data
  if (
    !dashboardReducerdData.isLoading &&
    dashboardReducerdData.data &&
    dashboardReducerdData.data.data &&
    dashboardReducerdData.data.data.jobseeker_details
  ) {
    countData = dashboardReducerdData.data.data.jobseeker_details;
    profileCompletionPercentage =
      dashboardReducerdData.data.data.profile_completion_percentage;
    visitorCount = dashboardReducerdData.data.data.visitors_count;
    contactCount = dashboardReducerdData.data.data.contact_count;
  }

  // Destructuring data from dashboardData
  const { jobseeker_full_name, jobseeker_location, jobseeker_photo } =
    countData;

  const handleEditClick = () => {
    navigate(`${process.env.PUBLIC_URL}/jobseeker/update_profile`);
  };

  return (
    <Card className="balance-box">
      <CardBody>
        <div className="balance-profile d-flex align-items-center">
          <div className="d-flex flex-column align-items-center me-3">
            <div className="balance-img mb-2">
              {jobseeker_photo ? (
                <img
                  src={jobseeker_photo}
                  alt="user vector"
                  width="64"
                  height="64"
                  className="rounded-circle"
                />
              ) : (
                <img
                  src={AVATAR}
                  alt="default user"
                  width="64"
                  height="64"
                  className="rounded-circle"
                />
              )}
            </div>
            <button
              className="btn btn-primary  "
              style={{ paddingTop: 2, paddingBottom: 2 }}
              onClick={handleEditClick}
            >
              Edit
            </button>
          </div>
          <div>
            <h5 class="mb-0 text-start ">{capitalize(jobseeker_full_name)}</h5>
            <div className="progress-showcase row mt-3 ">
              <div className="col-12 text-right mb-2">
                <Progress
                  value={profileCompletionPercentage}
                  color="info"
                  className="sm-progress-bar"
                />
              </div>
              <div className="col-12 d-flex justify-content-between">
                <div className="text-start">
                  {profileCompletionPercentage}% profile completed
                </div>
                <Link
                  className="edit-icon text-end"
                  to={`${process.env.PUBLIC_URL}/jobseeker/update_profile`}
                >
                  Complete Profile
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="radial-progress-card ">
          <Col
            xl="12"
            sm="6"
            className="d-flex justify-content-around align-items-center "
          >
            {/* <div className="text-center">
                    <h4>{visitorCount ? visitorCount : 0}</h4>
                    <span className="f-light">Visitor</span>
                  </div>
                  <div className="text-center">
                    <h4>{contactCount ? contactCount : 0}</h4>
                    <span className="f-light">Contact</span>
                  </div>
                  <div className="text-center">
                    <h4>{visitorCount ? visitorCount : 0}</h4>
                    <span className="f-light">Follow</span>
                  </div> */}
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

export default GreetingCard;
