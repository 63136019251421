import {
  UPDATE_CONTACTIBILITY_REQUEST,
  UPDATE_CONTACTIBILITY_SUCCESS,
  UPDATE_CONTACTIBILITY_FAILURE,
  RESET_CONTACTIBILITY_MESSAGE,
} from "../../actionTypes";

// action creators
export const updateContactibilityRequest = (data) => ({
  type: UPDATE_CONTACTIBILITY_REQUEST,
  payload: data,
});

export const updateContactibilitySuccess = (message) => ({
  type: UPDATE_CONTACTIBILITY_SUCCESS,
  payload: message,
});

export const updateContactibilityFailure = (error) => ({
  type: UPDATE_CONTACTIBILITY_FAILURE,
  payload: error,
});

export const resetContactibilityMessage = () => ({
  type: RESET_CONTACTIBILITY_MESSAGE,
});
