import { React, Fragment, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Badge,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useCallback } from "react";
import { fetchSkillsRequest } from "../../../redux/mandatorySkills/actions";
import { customOptionsStyle } from "../../../Constant";
import {
  addSkillsRequest,
  resetData,
} from "../../../redux/jobseeker/skills/actions";
import { showToast } from "../../Common/Toast";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { useParams } from "react-router";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";

const AddskillModal = (props) => {
  const dispatch = useDispatch();
  const { cand_id, jobcad_id } = useParams();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    seeker_id: props.data ? props.data.jobseeker_id : null,
    id: props.data ? props.data.id : null,
    skills: props.data ? props.data.skill : null,
  });
  console.log("wwww", props);
  const handleSkillsChange = (selectedOptions) => {
    setFormData({ ...formData, skills: selectedOptions });
  };

  const handleSkillInputChange = useCallback(
    (inputValue) => {
      // Fetch data based on the inputValue (keyword)
      const fetchData = async () => {
        try {
          dispatch(fetchSkillsRequest(inputValue));
        } catch (error) {
          // Handle any errors if necessary
        }
      };

      // Call the fetchData function when the location input changes
      fetchData();
    },
    [dispatch]
  );

  const handleSkillFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchSkillsRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  let skillReducerData = useSelector((state) => state.mandatorySkillsReducer);
  console.log("srd", skillReducerData);
  let skillData = skillReducerData.data.data;
  // let skillData = [];

  const handleSubmit = (e) => {
    // e.preventDefault();
    // const data = {
    //   skill_id: formData.skills.map((skill) => skill.value).join(","),
    // };
    // dispatch(addSkillsRequest(data));

    e.preventDefault();

    // Check if any skills are selected
    if (!formData.skills || formData.skills.length === 0) {
      setErrors({ skills: "Please select at least one skill." });
      return; // Exit early if no skills are selected
    }

    // Reset errors if there are any
    setErrors({});

    // Prepare data for submission
    const data = {
      seeker_id: formData.seeker_id,
      skill_id: formData.skills.map((skill) => skill.value).join(","),
    };

    // Dispatch action to add skills
    dispatch(addSkillsRequest(data));
  };

  const addSkillReducerData = useSelector((state) => state.skillsReducer);
  useEffect(() => {
    console.log("srd", addSkillReducerData);
    if (addSkillReducerData.status && addSkillReducerData.message != "") {
      showToast("success", addSkillReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      props.toggleModal();
      dispatch(resetData());
    } else if (
      addSkillReducerData.status == false &&
      addSkillReducerData.message != ""
    ) {
      showToast("error", addSkillReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      props.toggleModal();
      dispatch(resetData());
    }
  }, [addSkillReducerData]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4>Add Skills</h4>
        </ModalHeader>
        <ModalBody className="pt-0 ">
          <FormGroup>
            <Label>
              <b>Skill</b>
              <span className="text-danger">*</span>
            </Label>
            <Select
              placeholder="Select skills"
              isLoading={skillReducerData.isLoading}
              options={skillData}
              value={formData.skills}
              onChange={handleSkillsChange}
              styles={customOptionsStyle}
              onFocus={handleSkillFocus}
              onInputChange={handleSkillInputChange}
              isMulti={true}
            />
            {errors.skills && (
              <small className="text-danger">{errors.skills}</small>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            disabled={addSkillReducerData.isLoading}
            color="primary"
            type="submit"
          >
            Add
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddskillModal;
