import { call, put, takeEvery } from "redux-saga/effects";
import {
  updateCompanyProfileSuccess,
  updateCompanyProfileFailure,
  fetchCompanyProfileSuccess,
  fetchCompanyProfileFailure,
  updateCompanyLogoSuccess,
  updateCompanyLogoFailure,
  removeCompanyLogoSuccess,
  removeCompanyLogoFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {
  UPDATE_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_REQUEST,
  UPDATE_COMPANY_LOGO_REQUEST,
  REMOVE_COMPANY_LOGO_REQUEST,
} from "../../actionTypes";

function* updateCompanyProfileSaga(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/add_company_details", action.payload)
    );

    if (response.data.status) {
      yield put(updateCompanyProfileSuccess(response.data));
    } else {
      yield put(updateCompanyProfileFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateCompanyProfileFailure({ status: false, message: error.message })
    );
  }
}

function* fetchCompanyProfileSaga() {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/fetch_company_profile_details")
    );
    if (response.data.status) {
      yield put(fetchCompanyProfileSuccess(response.data));
    } else {
      yield put(fetchCompanyProfileFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchCompanyProfileFailure({ status: false, message: error.message })
    );
  }
}

function* updateCompanyLogo(action) {
  console.log("avator", action.payload);

  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/company_logo", action.payload)
    );
    if (response.data.status) {
      yield put(updateCompanyLogoSuccess(response.data));
    } else {
      yield put(updateCompanyLogoFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateCompanyLogoFailure({ status: false, message: error.message })
    );
  }
}

function* removeCompanyLogo(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/remove_company_logo")
    );

    if (response.data.status) {
      yield put(removeCompanyLogoSuccess(response.data));
    } else {
      yield put(removeCompanyLogoFailure(response.data));
    }
  } catch (error) {
    yield put(
      removeCompanyLogoFailure({ status: false, message: error.message })
    );
  }
}

function* companyProfileSaga() {
  yield takeEvery(UPDATE_COMPANY_PROFILE_REQUEST, updateCompanyProfileSaga);
  yield takeEvery(FETCH_COMPANY_PROFILE_REQUEST, fetchCompanyProfileSaga);
  yield takeEvery(UPDATE_COMPANY_LOGO_REQUEST, updateCompanyLogo);
  yield takeEvery(REMOVE_COMPANY_LOGO_REQUEST, removeCompanyLogo);
}

export default companyProfileSaga;
