// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import { fetchCompanySuccess, fetchCompanyFailure } from "./actions";

function* fetchCompany(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(() =>
      AxiosInstance().post("/fetch_all_companies", action.payload)
    );
    if (response.data.status) {
      yield put(fetchCompanySuccess(response.data));
    } else {
      yield put(fetchCompanyFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchCompanyFailure({ status: false, message: error.message }));
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* companySaga() {
  yield takeLatest(types.FETCH_COMPANY_REQUEST, fetchCompany);
}

export default companySaga;
