import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
  Card,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import AddCandidate from "../../Components/Recruiter/AddCandidate/AddCandidate";

const AddCandidatePage = () => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // Handle form submission
    console.log(data);
  };

  const copyToClipboard = (element) => {
    // Implement the copyToClipboard function here
  };

  return (
    <Fragment>
      <Breadcrumbs title="Add Candidate" mainTitle="Add Candidate" />
      <Container fluid={true}>
        {/* Your provided HTML code */}
        <AddCandidate />
      </Container>
    </Fragment>
  );
};

export default AddCandidatePage;
