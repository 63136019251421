import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppliedJobsRequest } from "../../../redux/jobseeker/applied_jobs/actions";
import { showToast } from "../../Common/Toast";
import {
  capitalize,
  formatDate,
  formatExperienceOutput,
  generateSlug,
  unslugAndCapitalize,
} from "../../Common/CommonFunction";
import { Badge, Col, Card, CardHeader, CardBody } from "reactstrap";
import { Edit2, Trash2, Eye, Copy } from "react-feather";
import { Link } from "react-router-dom";
import NoDataFound from "../../Common/NoDataFound";
import Spinner from "../../Common/Spinner";
import { customStyles } from "../../../Constant";

const CustomHeader = ({ cell }) => (
  <th style={{ fontWeight: "bold" }}>{cell.label}</th>
);

const AppliedJobDataTable = () => {
  const dispatch = useDispatch();

  const getStatusColor = (status) => {
    // Implement your logic to determine the badge color based on status
    // For example:
    switch (status) {
      case "HIRED":
        return "badge-light-success";
      case "SHORTLISTED":
        return "badge-light-success";
      case "INTERVIEWED":
        return "badge-light-primary";
      case "PENDING":
        return "badge-light-info";
      case "VIEWED":
        return "badge-light-warning";

      case "REJECTED":
        return "badge-light-danger";
      default:
        return "badge-light-primary";
    }
  };

  const tableColumns = [
    {
      name: "#",
      short_column: "job_id",
      cell: (row, index) => index + 1,
      width: "5%",
      sortable: true,
      center: false,
    },
    {
      name: "Job Title",
      short_column: "job_name",
      cell: (row) =>
        row.job_name ? (
          <Link
            to={`${process.env.PUBLIC_URL}/jobseeker/job_details/${generateSlug(
              row.job_name,
              row.job_location,
              formatExperienceOutput(
                row.work_experience_min,
                row.work_experience_max
              ).replace("-", "-to-"),
              row.job_id
            )}`}
            title={"View Job"}
          >
            {capitalize(row.job_name)}
          </Link>
        ) : (
          "-"
        ),
      sortable: true,
      center: false,
    },
    {
      short_column: "company_name",
      name: "Company Name",
      cell: (row) => (row.company_name ? capitalize(row.company_name) : "_"),
      sortable: true,
      center: false,
    },

    {
      name: "Experience",

      cell: (row) => {
        const experienceOutput = formatExperienceOutput(
          row.work_experience_min,
          row.work_experience_max
        );
        return experienceOutput !== "N/A" ? experienceOutput : "-";
      },

      sortable: false,
      center: true,
    },
    {
      name: "Location",
      short_column: "job_location",
      cell: (row) => (row.job_location ? capitalize(row.job_location) : "_"),
      sortable: true,
      center: false,
    },
    {
      name: "Applied On",
      short_column: "created_at",
      cell: (row) => (row.created_at ? formatDate(row.created_at) : "_"),
      sortable: true,
      center: false,
    },
    // {
    //   name: "Status",

    //   sortable: false,
    //   center: false,
    //   cell: (row) => (
    //     <span
    //       className={`badge ${getCandidateStatusColor(row.candidate_status)}`}
    //     >
    //       {unslugAndCapitalize(row.candidate_status)}
    //     </span>
    //   ),
    // },
    {
      name: "Actions",

      cell: (row) => (
        <div>
          <span>
            <Link
              to={`${
                process.env.PUBLIC_URL
              }/jobseeker/job_details/${generateSlug(
                row.job_name,
                row.job_location,
                formatExperienceOutput(
                  row.work_experience_min,
                  row.work_experience_max
                ).replace("-", "-to-"),
                row.job_id
              )}`}
              title="View Job"
            >
              <Eye
                size={18}
                className="eye-icon"
                style={{ marginRight: "10px" }}
              />
            </Link>
            {/* <Edit2 size={18} color="blue" style={{ marginRight: "10px" }} />
            <Copy size={18} color="orange" /> */}
          </span>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    const data = {
      status: "ALL",
      type: "ALL",

      startDate: null,
      endDate: null,
      keyword: "",
    };
    dispatch(fetchAppliedJobsRequest(1, 10, data));
  }, [dispatch]);

  const appliedJobsReducerData = useSelector(
    (state) => state.appliedJobsReducer
  );
  const appliedJobsData = appliedJobsReducerData.data.data;

  if (appliedJobsReducerData.error) {
    showToast("error", appliedJobsReducerData.error);
  }

  return (
    <Fragment>
      <Card>
        <CardHeader className="card-no-border ">
          <div className="header-top">
            <h5>Applied Jobs</h5>
            {appliedJobsData && (
              <div className="card-header-right-icon">
                <Link to={`${process.env.PUBLIC_URL}/jobseeker/applied_Jobs`}>
                  View All
                </Link>
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody className="px-0 py-3 pt-0">
          <DataTable
            customStyles={customStyles}
            data={appliedJobsData}
            columns={tableColumns}
            keyField="id"
            striped={true}
            center={true}
            headerComponent={CustomHeader}
            progressPending={appliedJobsReducerData.isLoading}
            progressComponent={<Spinner />}
            noDataComponent={<NoDataFound message={"No Applied Job Found"} />}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AppliedJobDataTable;
