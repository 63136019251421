import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_JOBSEEKER_PASSWORD_REQUEST } from "../../actionTypes";

import {
  updateJobseekerPasswordSuccess,
  updateJobseekerPasswordFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updateJobseekerPassword(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/change_password", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(updateJobseekerPasswordSuccess(response.data));
    } else {
      yield put(updateJobseekerPasswordFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateJobseekerPasswordFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* changeJobseekerPasswordSaga() {
  yield takeLatest(UPDATE_JOBSEEKER_PASSWORD_REQUEST, updateJobseekerPassword);
}
export default changeJobseekerPasswordSaga;
