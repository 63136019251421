// actions.js
import {
  FETCH_REFERRAL_REQUEST,
  FETCH_REFERRAL_SUCCESS,
  FETCH_REFERRAL_FAILURE,
} from "../../actionTypes";

export const fetchReferralRequest = () => ({
  type: FETCH_REFERRAL_REQUEST,
});

export const fetchReferralSuccess = (request) => ({
  type: FETCH_REFERRAL_SUCCESS,
  payload: request,
});

export const fetchReferralFailure = (error) => ({
  type: FETCH_REFERRAL_FAILURE,
  payload: error,
});
