import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { submitFeedbackSuccess, submitFeedbackFailure } from "./actions";
import { SUBMIT_FEEDBACK_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* submitFeedbackSaga(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/add_job_feedback", action.payload)
    );

    if (response.data.status) {
      yield put(submitFeedbackSuccess(response.data));
    } else {
      yield put(submitFeedbackFailure(response.data));
    }
  } catch (error) {
    yield put(submitFeedbackFailure({ status: false, message: error.message }));
  }
}

// Starts submitFeedbackSaga on each dispatched SUBMIT_FEEDBACK_REQUEST action
function* jobFeedbackSaga() {
  yield takeLatest(SUBMIT_FEEDBACK_REQUEST, submitFeedbackSaga);
}

export default jobFeedbackSaga;
