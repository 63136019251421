import * as types from "../../actionTypes";

const initialState = {
  data: {},
  isLoading: false,
  message: "",
  status: "",
};

const addEducationReducer = (state = initialState, action) => {
  console.log("prd", action);
  switch (action.type) {
    case types.ADD_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case types.ADD_EDUCATION_BACKGROUND_FAILURE:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        data: {},
        status: action.payload.status,
        message: action.payload.message,
      };
    case types.DELETE_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case types.DELETE_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case types.RESET_DATA:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: false,
        message: "",
      };

    default:
      return state;
  }
};

export default addEducationReducer;
