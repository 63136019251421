// actions.js
import {
  CANDIDATE_SAVE_FOR_LATER_REQUEST,
  CANDIDATE_SAVE_FOR_LATER_SUCCESS,
  CANDIDATE_SAVE_FOR_LATER_FAILURE,
  CANDIDATE_SAVE_FOR_LATER_RESET,
} from "../../actionTypes";

export const candidateSaveForLaterRequest  = (candidateData) => ({
  type: CANDIDATE_SAVE_FOR_LATER_REQUEST,
  payload: candidateData,
});

export const candidateSaveForLaterSuccess  = (message) => ({
  type: CANDIDATE_SAVE_FOR_LATER_SUCCESS,
  payload: message,
});

export const candidateSaveForLaterFailure  = (error) => ({
  type: CANDIDATE_SAVE_FOR_LATER_FAILURE,
  payload: error,
});



export const candidateSaveForLaterReset  = () => ({
  type: CANDIDATE_SAVE_FOR_LATER_RESET,
});
