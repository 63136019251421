import React, { Fragment, useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import EditMyProfile from "./EditmyProfile";
import MyProfileEdit from "./MyProfileEdit";
// import UserTable from './UserTable';
import Workexperience from "./Workexperience";
import Mainskills from "./Mainskills";
import Worksample from "./Worksample";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobseekerPublicProfileRequest } from "../../../redux/public/jobseeker_public_profile/actions";
import { showToast } from "../../Common/Toast";
import Spinner from "../../Common/Spinner";
import { LOADER_TIMEOUT } from "../../../Constant";
import { PreloaderContext } from "../../../_helper/Preloader/PreloaderContext";
import { useParams } from "react-router";
import Educationbackground from "./Educationbackground";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";

const PublicProfile = () => {
  const { cand_id, jobcad_id } = useParams();

  const dispatch = useDispatch();
  const { showPreloader } = useContext(PreloaderContext);
  console.log("extractAfterLastHyphen", extractAfterLastHyphen(cand_id));
  useEffect(() => {
    const data = {
      seeker_id: extractAfterLastHyphen(cand_id),
      //job_cand_id: jobcad_id,
      type: "PUBLIC",
    };
    dispatch(fetchJobseekerPublicProfileRequest(data));
  }, []);

  const jobSeekerPublicProfileReducerData = useSelector(
    (state) => state.jobSeekerPublicProfileReducer
  );

  let profileData = [];
  if (
    !jobSeekerPublicProfileReducerData.isLoading &&
    jobSeekerPublicProfileReducerData.data &&
    jobSeekerPublicProfileReducerData.data.data
  ) {
    profileData = jobSeekerPublicProfileReducerData.data.data;
  }

  return (
    <Fragment>
      {jobSeekerPublicProfileReducerData.isLoading ? (
        <Spinner /> // Show loader when isLoading is true
      ) : (
        <Row>
          <Col xl="2"></Col>
          <Col xl="3">
            <MyProfileEdit profileData={profileData} />
          </Col>

          <Col xl="5">
            <EditMyProfile profileData={profileData} />

            <Mainskills profileData={profileData} />

            <Educationbackground profileData={profileData} />

            <Workexperience profileData={profileData} />

            <Worksample profileData={profileData} />
          </Col>

          <Col xl="2"></Col>
        </Row>
      )}
    </Fragment>
  );
};
export default PublicProfile;
