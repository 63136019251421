import {
  SAVE_FAVORITE_JOB_REQUEST,
  SAVE_FAVORITE_JOB_SUCCESS,
  SAVE_FAVORITE_JOB_FAILURE,
  FETCH_FAVORITE_JOBS_REQUEST,
  FETCH_FAVORITE_JOBS_SUCCESS,
  FETCH_FAVORITE_JOBS_FAILURE,
  DELETE_FAVORITE_JOB_REQUEST,
  DELETE_FAVORITE_JOB_SUCCESS,
  DELETE_FAVORITE_JOB_FAILURE,
  RESET_FAVORITE_MESSAGE,
} from "../../actionTypes";

export const saveFavoriteJobRequest = (data) => ({
  type: SAVE_FAVORITE_JOB_REQUEST,
  payload: data,
});

export const saveFavoriteJobSuccess = (message) => ({
  type: SAVE_FAVORITE_JOB_SUCCESS,
  payload: message,
});

export const saveFavoriteJobFailure = (error) => ({
  type: SAVE_FAVORITE_JOB_FAILURE,
  payload: error,
});
export const fetchFavoriteJobsRequest = (
  page,
  per_page,

  data,

  column,
  direction
) => ({
  type: FETCH_FAVORITE_JOBS_REQUEST,
  payload: { page, per_page, data, column, direction },
});

export const fetchFavoriteJobsSuccess = (data) => ({
  type: FETCH_FAVORITE_JOBS_SUCCESS,
  payload: data,
});

export const fetchFavoriteJobsFailure = (error) => ({
  type: FETCH_FAVORITE_JOBS_FAILURE,
  payload: error,
});

export const deleteFavoriteJobRequest = (data) => ({
  type: DELETE_FAVORITE_JOB_REQUEST,
  payload: data,
});

export const deleteFavoriteJobSuccess = (message) => ({
  type: DELETE_FAVORITE_JOB_SUCCESS,
  payload: message,
});

export const deleteFavoriteJobFailure = (error) => ({
  type: DELETE_FAVORITE_JOB_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetFavoriteMessage = () => ({
  type: RESET_FAVORITE_MESSAGE,
});
