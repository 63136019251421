import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Spinner from "../../Common/Spinner";

const PreviewResumeModal = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [resumeContent, setResumeContent] = useState("");

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setResumeContent(props.data.jobseeker_resume);
      setLoading(false);
    }, 2000);
  }, [props.data.jobseeker_resume]);

  const getViewerUrl = (url) => {
    return `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
      url
    )}`;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <ModalHeader toggle={props.toggleModal}>
        {props.data.resume_name}
      </ModalHeader>
      <ModalBody className="text-center" style={{ height: "600px" }}>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Spinner />
          </div>
        ) : (
          <object
            data={getViewerUrl(resumeContent)}
            type="application/pdf"
            width="100%"
            height="600px"
          ></object>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PreviewResumeModal;
