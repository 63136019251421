import React, { useState } from "react";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Searchbar = () => {
  const [searchresponsive, setSearchresponsive] = useState(false);
  const navigate = useNavigate(); // Get navigate function from useNavigate

  const handleSearchClick = () => {
    // Redirect to another page
    navigate(`${process.env.PUBLIC_URL}/recruiter/candidate_search`); // Use navigate function to navigate
  };

  return (
    <li>
      <span className="header-search">
        <Search onClick={handleSearchClick} />
      </span>
    </li>
  );
};

export default Searchbar;
