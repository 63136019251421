import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom"; // Import useParams
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import PostJobForm from "../../Components/Recruiter/PostJob/PostJobForm";

const PostJobPage = () => {
  const { id, duplicate, type } = useParams(); // Use useParams to get the parameters
  // Decide the breadcrumb title based on the route
  const breadcrumbTitle =
    type === "internship" ? "Post Internship" : "Post Job";
  return (
    <Fragment>
      <Breadcrumbs title={breadcrumbTitle} mainTitle={breadcrumbTitle} />
      <Container fluid={true}>
        <PostJobForm type={type} id={id} isDuplicate={duplicate === "true"} />
      </Container>
    </Fragment>
  );
};

export default PostJobPage;
