// actions.js
import * as types from '../actionTypes';

export const fetchSkillsRequest = (keyword,skillType) => ({
    type: types.FETCH_SKILLS_REQUEST,
    payload: { keyword,skillType },
  });
  
  export const fetchSkillsSuccess = (data) => ({
    type: types.FETCH_SKILLS_SUCCESS,
    payload: { data },
  });
  
  export const fetchSkillsFailure = (error) => ({
    type: types.FETCH_SKILLS_FAILURE,
    payload: { error },
  });
  
  

























  