import Sidebar from "./Sidebar";
import ListCard from "./ListCard";

import { Container, Row } from "reactstrap";
import YourCandidateSearchProvider from "../../../YourCandidateSearch/YourCandidateSearchContext";
const YourCandidateSearch = () => {
  return (
    <Container fluid={true}>
      <Row className="mb-5">
        <YourCandidateSearchProvider>
          <Sidebar />
          <ListCard />
        </YourCandidateSearchProvider>
      </Row>
    </Container>
  );
};

export default YourCandidateSearch;
