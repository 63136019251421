import { takeLatest, put, call } from "redux-saga/effects";
import {
  DELETE_SAVED_CANDIDATE_REQUEST,
  FETCH_SAVED_CANDIDATES_REQUEST,
} from "../../actionTypes";
import {
  fetchSavedCandidatesSuccess,
  fetchSavedCandidatesFailure,
  deleteSavedCandidateSuccess,
  deleteSavedCandidateFailure,
} from "./actions";

import AxiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

function* fetchSavedCandidates(action) {
  console.log("action.payload.status", action.payload.status);

  let url = `/recruiter/fetch_saved_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}`;

  if (action.payload.column) {
    url += `&sortBy=${action.payload.column}`;
  }

  if (action.payload.direction) {
    url += `&direction=${action.payload.direction}`;
  }

  try {
    const response = yield AxiosInstance().post(url, action.payload.data);

    if (response.data.status) {
      yield put(fetchSavedCandidatesSuccess(response.data));
    } else {
      yield put(fetchSavedCandidatesFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchSavedCandidatesFailure({ status: false, message: error.message })
    );
  }
}

function* deleteSavedCandidate(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post(`/recruiter/delete_saved_candidate`, action.payload)
    );
    console.log("delres", response);

    yield put(deleteSavedCandidateSuccess(response.data));
  } catch (error) {
    yield put(
      deleteSavedCandidateFailure({ status: false, message: error.message })
    );
  }
}

export default function* fetchSavedCandidatesSaga() {
  yield takeLatest(FETCH_SAVED_CANDIDATES_REQUEST, fetchSavedCandidates);

  yield takeLatest(DELETE_SAVED_CANDIDATE_REQUEST, deleteSavedCandidate);
}
