// reducer.js
import {
  ADD_CANDIDATE_REQUEST,
  ADD_CANDIDATE_SUCCESS,
  ADD_CANDIDATE_FAILURE,
  FETCH_ADD_CANDIDATE_REQUEST,
  FETCH_ADD_CANDIDATE_SUCCESS,
  FETCH_ADD_CANDIDATE_FAILURE,
  RESET_ADD_CANDIDATE,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  status: false,
  message: "",
};

const addCandidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CANDIDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
        seeker_id: action.payload.seeker_id,
        job_cand_id: action.payload.job_cand_id,
      };
    case ADD_CANDIDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case FETCH_ADD_CANDIDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ADD_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status,
      };
    case FETCH_ADD_CANDIDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_ADD_CANDIDATE:
      return {
        ...state,
        isLoading: false,
        status: false,
        message: "",
      };
    default:
      return state;
  }
};

export default addCandidateReducer;
