import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import BannerCard from "../../Components/Jobseeker/LinkedinBanner/BannerCard";
import { getLinkedInBannerRequest } from "../../redux/get_linkedin_banner/actions";
import Spinner from "../../Components/Common/Spinner";
import NoDataFound from "../../Components/Common/NoDataFound";

const AiLinkedinBannerJobseeker = () => {
  const dispatch = useDispatch();
  const getLinkedinBannerReducerData = useSelector(
    (state) => state.getLinkedinBannerReducer
  );
  const { data: bannerData, isLoading } = getLinkedinBannerReducerData;

  const [activeTab, setActiveTab] = useState("ALL");

  useEffect(() => {
    dispatch(getLinkedInBannerRequest({ type: activeTab }));
  }, [dispatch, activeTab]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const filteredBannerData = bannerData?.filter(
    (template) =>
      (activeTab === "ALL" &&
        (template.type === "FREE" || template.type === "PREMIUM")) ||
      activeTab === template.category
  );

  return (
    <Fragment>
      <Breadcrumbs title="AI Linkedin Banner" mainTitle="AI Linkedin Banner" />
      <Container fluid>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <Nav className="border-tab nav-primary nav nav-tabs" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "ALL" ? "active" : ""}
                    onClick={() => toggleTab("ALL")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="icofont icofont-file"></i>
                    All Banners
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "ATTITUDE" ? "active" : ""}
                    onClick={() => toggleTab("ATTITUDE")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="icofont icofont-file"></i>
                    Attitude
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "MOTIVATION" ? "active" : ""}
                    onClick={() => toggleTab("MOTIVATION")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="icofont icofont-file"></i>
                    Motivation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "HIRING" ? "active" : ""}
                    onClick={() => toggleTab("HIRING")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="icofont icofont-file"></i>
                    Hiring
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "CUSTOMIZABLE" ? "active" : ""}
                    onClick={() => toggleTab("CUSTOMIZABLE")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="icofont icofont-file"></i>
                    Customizable
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            {isLoading ? (
              <Spinner color="primary" />
            ) : filteredBannerData && filteredBannerData.length > 0 ? (
              <TabContent activeTab={activeTab}>
                <TabPane tabId={activeTab}>
                  <Row>
                    {filteredBannerData.map((template, index) => (
                      <BannerCard
                        key={template.banner_id}
                        index={index}
                        template={template}
                      />
                    ))}
                  </Row>
                </TabPane>
              </TabContent>
            ) : (
              <NoDataFound message="No banners available." />
            )}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AiLinkedinBannerJobseeker;
