import React, { Fragment, useEffect, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  markAllAsReadRequest,
  resetMarkAllAsReadMessage,
} from "../../redux/notifications/actions";
import { showToast } from "../Common/Toast";
import { formatDate } from "../Common/CommonFunction";
import NoDataFound from "../Common/NoDataFound";
import { Card } from "reactstrap";

import Pagination from "../Common/Pagination";
import Spinner from "../Common/Spinner";
import { FiCornerDownRight } from "react-icons/fi";
import { useContext } from "react";
import { NotificationContext } from "../../Notifications/NotificationContext";
const NotificationsList = () => {
  const {
    allNotifications,
    allNotificationsCount,
    fetchUnreadNotificationsRequest,
    fetchAllNotificationsRequest,
  } = useContext(NotificationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const totalPages = allNotificationsCount
    ? Math.ceil(allNotificationsCount / perPage)
    : 0;

  // Use useCallback to prevent unnecessary re-renders
  const fetchNotifications = useCallback(() => {
    fetchAllNotificationsRequest(currentPage, perPage);
  }, [currentPage, perPage]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const handleNotificationClick = (notification) => {
    if (notification.isread == 1) {
      navigate(`${process.env.PUBLIC_URL}/${notification.link}`);
    } else {
      dispatch(markAllAsReadRequest({ id: notification.id }));

      setTimeout(() => {
        fetchUnreadNotificationsRequest();
        navigate(`${process.env.PUBLIC_URL}/${notification.link}`);
      }, 2000); // Delay of 500ms before redirecting
    }
  };
  const markAllAsReadNotificationsState = useSelector(
    (state) => state.notificationsReducer.markAllAsRead
  );

  const allNotificationsState = useSelector(
    (state) => state.notificationsReducer.all
  );
  const isLoading = allNotificationsState.isLoading;

  useEffect(() => {
    const { status, message } = markAllAsReadNotificationsState;
    if (status && message) {
      showToast("success", message);
      fetchAllNotificationsRequest(currentPage, perPage);
      fetchUnreadNotificationsRequest();
      dispatch(resetMarkAllAsReadMessage());
    } else if (!status && message) {
      showToast("error", message);
      fetchAllNotificationsRequest(currentPage, perPage);
      fetchUnreadNotificationsRequest();
      dispatch(resetMarkAllAsReadMessage());
    }
  }, [markAllAsReadNotificationsState, dispatch]);

  return (
    <Fragment>
      {isLoading ? (
        <Spinner color="primary" /> // Render spinner while loading
      ) : (
        <div
          className="todo-list-body "
          //style={{ maxHeight: "auto", overflow: "none" }}
        >
          <ul className="simple-list mb-5" id="todo-list">
            {allNotifications && allNotifications.length > 0 ? (
              allNotifications.map((notification) => (
                <li
                  className={`task border-0 ${
                    notification.isread == 1 ? "pending" : "completed "
                  }`}
                  key={notification.id}
                >
                  <div className="task-container">
                    <NavLink
                      // to={notification.link}
                      className={`sidebar-link sidebar-title link-nav`}
                    >
                      <h4
                        className={`task-label ${
                          notification.isread == 1 ? "" : "text-decoration-none"
                        }`}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          handleNotificationClick(notification);
                        }}
                      >
                        <FiCornerDownRight className="me-2" />

                        {notification.description}
                      </h4>
                    </NavLink>

                    <div className="d-flex align-items-center gap-4">
                      <h5 className="assign-name m-0">
                        {formatDate(notification.date)}
                      </h5>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <Card className="pb-3 pt-3">
                <NoDataFound message={"No notifications found"} />
              </Card>
            )}
          </ul>
          {totalPages > 1 && (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default NotificationsList;
