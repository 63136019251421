import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  ListGroup,
  ListGroupItem,
  Card,
  Badge,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { showSweetAlert } from "../../Common/SweetAlert";

import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { socialMediaOptions } from "../../../Constant";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { showToast } from "../../Common/Toast";
import { educationLevelsOptions } from "../../../Constant";
import { formatDate } from "../../Common/CommonFunction";
import { useParams } from "react-router";
import {
  deleteWorkExpRequest,
  resetData,
} from "../../../redux/jobseeker/work_experience/actions";
import { capitalize } from "../../Common/CommonFunction";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";
const Workexperience = ({ profileData }) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
              Work Experience
            </h4>{" "}
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ps-2">
            {profileData.work_experience &&
            profileData.work_experience.length > 0 ? (
              // Render work experience data if available
              profileData.work_experience.map((work) => (
                <div
                  className="d-flex justify-content-between setup mb-3"
                  key={work.id}
                >
                  <div>
                    <h5>
                      {capitalize(work.designation)}&nbsp;at&nbsp;
                      {work.organization.label}
                    </h5>
                    <p>
                      <span>
                        {formatDate(work.started_working_from)} -{" "}
                        {formatDate(work.worked_till)}
                      </span>
                      <br />
                      <span>{capitalize(work.job_description)}</span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              // Render "No data found" message if no work experience data
              <div className="text-center">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default Workexperience;
