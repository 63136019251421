import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Label,
  Button,
  Input,
  Container,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { useDispatch } from "react-redux";
import {
  submitReportRequest,
  resetReportJobData,
} from "../../redux/jobseeker/report_job/actions";
import { useParams } from "react-router-dom";
import { showToast } from "../../Components/Common/Toast";
import { reportReason } from "../../Constant/index";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { extractAfterLastHyphen } from "../../Components/Common/CommonFunction";

const ReportJobPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    reason: null, // Initialize as null for react-select
    job_id: extractAfterLastHyphen(jobId),
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSelectChange = (selectedOption) => {
    handleInputChange("reason", selectedOption);
  };

  const formattedData = {
    job_id: extractAfterLastHyphen(jobId),
    name: formData.name,
    email: formData.email,
    reason: formData.reason ? formData.reason.value : "", // Store the value from the selected option
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      dispatch(submitReportRequest(formattedData));
      // try {
      //   dispatch(submitReportRequest(formattedData));
      //   showToast("success", "Report submitted successfully");
      //   setFormData({ name: "", email: "", reason: null }); // Reset the select to null
      //   setSuccessMessage("");
      // } catch (error) {
      //   showToast("error", "Failed to submit report. Please try again.");
      // }
    }
  };
  const reportJobReducerData = useSelector((state) => state.reportJobReducer);

  useEffect(() => {
    if (
      reportJobReducerData.status == true &&
      reportJobReducerData.message != ""
    ) {
      showToast("success", reportJobReducerData.message);
      // Dispatch action to reset the message after showing the toast

      navigate(`${process.env.PUBLIC_URL}/jobseeker/job_details/${jobId}`);
      setTimeout(() => {
        dispatch(resetReportJobData());
      }, 5000);
    } else if (
      reportJobReducerData.status == false &&
      reportJobReducerData.message != ""
    ) {
      showToast("error", reportJobReducerData.message);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetReportJobData());
    }
  }, [reportJobReducerData]);

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
      isValid = false;
    }

    if (!formData.reason) {
      errors.reason = "Reason for reporting is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    // Check if user data exists in local storage
    const userDataString = localStorage.getItem("user_data");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        console.log("Retrieved User Data:", userData);
        const { name, email } = userData;
        setFormData((prevFormData) => ({
          ...prevFormData,
          name,
          email,
        }));
      } catch (error) {
        console.error("Error parsing user data from local storage:", error);
      }
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumbs title="Report Job" mainTitle="Report Job" />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <FormGroup>
                <h5>
                  <Label>Report Job</Label>
                </h5>
                <br />
                <Label>
                  Enter Your Name <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control-sm form-control"
                  name="name"
                  value={formData.name}
                  placeholder="Your Name"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  // style={{ outline: 'none !important', boxShadow: 'none !important'}}
                />
                {errors.name && (
                  <small
                    className="tex
                t-danger"
                  >
                    {errors.name}
                  </small>
                )}
                <br />

                <Label>
                  Enter Email <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control-sm form-control"
                  type="email"
                  name="email"
                  value={formData.email}
                  placeholder="Enter Email"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                />
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
                <br />

                <Label for="reason">
                  Reason for Reporting <span className="text-danger">*</span>
                </Label>
                <Select
                  id="reason"
                  name="reason"
                  value={formData.reason}
                  onChange={handleSelectChange}
                  options={reportReason}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder="Select a reason"
                />
                {errors.reason && (
                  <small className="text-danger">{errors.reason}</small>
                )}
                <br />

                <Button
                  color="primary"
                  style={{ float: "right" }}
                  type="submit"
                >
                  Submit
                </Button>
                {successMessage && (
                  <small className="text-success">{successMessage}</small>
                )}
              </FormGroup>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </Fragment>
  );
};

export default ReportJobPage;
