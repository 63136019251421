import React, { Fragment } from "react";
import { Col, Row, Button, Table } from "reactstrap";
import { downloadPipelineCsvRequest } from "../../../redux/recruiter/job_pipeline/actions";
import { useDispatch } from "react-redux";

const JobMetricsBreakDownDatatable = () => {
  const dispatch = useDispatch();

  const handleExport = () => {
    // Handle export logic here
    // const obj = {};
    // dispatch(downloadPipelineCsvRequest(obj));
  };

  return (
    <Fragment>
      <Row className="d-flex justify-content-between mb-3">
        <Col xs="auto">
          <h5>Job Metrics Breakdown</h5>
        </Col>
        {/* <Col xs="auto">
          <Button color="primary" onClick={handleExport}>
            Export
          </Button>
        </Col> */}
      </Row>
      <Row>
        <Col xs="12" className="box-col-12 xl-100">
          <Table striped responsive>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Description</th>
                <th>Parameter</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Application Completion Rate</td>
                <td>
                  This column represents the percentage of completed job
                  applications out of the total number of applications received.
                </td>
                <td>
                  Low: Less than 50% completion rate
                  <br />
                  Moderate: 50% to 75% completion rate
                  <br />
                  High: More than 75% completion rate
                </td>
              </tr>
              <tr>
                <td>Candidates per Hire</td>
                <td>
                  This column indicates the average number of candidates
                  considered before making a single hire.
                </td>
                <td>
                  Low: Less than 10 candidates per hire
                  <br />
                  Moderate: 10 to 20 candidates per hire
                  <br />
                  High: More than 20 candidates per hire
                </td>
              </tr>
              <tr>
                <td>Application to Interview</td>
                <td>
                  This column represents the percentage of applicants who are
                  invited for an interview out of the total number of completed
                  applications.
                </td>
                <td>
                  Low: Less than 10% interview rate
                  <br />
                  Moderate: 10% to 30% interview rate
                  <br />
                  High: More than 30% interview rate
                </td>
              </tr>
              <tr>
                <td>Applicants per Open Requisition</td>
                <td>
                  This column indicates the average number of applicants for
                  each open job requisition.
                </td>
                <td>
                  Low: Less than 10 applicants per requisition
                  <br />
                  Moderate: 10 to 20 applicants per requisition
                  <br />
                  High: More than 20 applicants per requisition
                </td>
              </tr>
              <tr>
                <td>Interviews to Hires</td>
                <td>
                  This column indicates the percentage of job interviews that
                  lead to actual hires.
                </td>
                <td>
                  Low: Less than 50% hire rate
                  <br />
                  Moderate: 50% to 75% hire rate
                  <br />
                  High: More than 75% hire rate
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Fragment>
  );
};

export default JobMetricsBreakDownDatatable;
