import * as types from "../../actionTypes";

export const addMainSkillRequest = (data) => ({
  type: types.ADD_TECHNICAL_SKILL_REQUEST,
  payload: data,
});

export const addMainSkillSuccess = (data) => ({
  type: types.ADD_TECHNICAL_SKILL_SUCCESS,
  payload: data,
});

export const addMainSkillFailure = (error) => ({
  type: types.ADD_TECHNICAL_SKILL_FAILURE,
  payload: error,
});

export const deleteMainSkillRequest = (data) => ({
  type: types.DELETE_TECHNICAL_SKILLS_REQUEST,
  payload: data,
});

export const deleteMainSkillSuccess = (data) => ({
  type: types.DELETE_TECHNICAL_SKILLS_SUCCESS,
  payload: data,
});

export const deleteMainSkillFailure = (error) => ({
  type: types.DELETE_TECHNICAL_SKILLS_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA,
});
