import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_CONTACTIBILITY_REQUEST } from "../../actionTypes";

import {
  updateContactibilitySuccess,
  updateContactibilityFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updateContactibility(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/contactibility", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(updateContactibilitySuccess(response.data));
    } else {
      yield put(updateContactibilityFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateContactibilityFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* updateContactibilitySaga() {
  yield takeLatest(UPDATE_CONTACTIBILITY_REQUEST, updateContactibility);
}
export default updateContactibilitySaga;
