import {
  FETCH_MANAGE_JOBS_REQUEST,
  FETCH_MANAGE_JOBS_SUCCESS,
  FETCH_MANAGE_JOBS_FAILURE,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

export const fetchManageJobsRequest = (
  page,
  per_page,
  data,
  column,
  direction
) => {
  return {
    type: FETCH_MANAGE_JOBS_REQUEST,
    payload: { page, per_page, data, column, direction },
  };
};
export const fetchManageJobsSuccess = (jobs) => {
  return {
    type: FETCH_MANAGE_JOBS_SUCCESS,
    payload: jobs,
  };
};

export const fetchManageJobsFailure = (error) => {
  return {
    type: FETCH_MANAGE_JOBS_FAILURE,
    payload: error,
  };
};

// delete job actions

export const deleteJobRequest = (jobId) => ({
  type: DELETE_JOB_REQUEST,
  payload: jobId,
});

export const deleteJobSuccess = (success) => ({
  type: DELETE_JOB_SUCCESS,
  payload: success,
});

export const deleteJobFailure = (error) => ({
  type: DELETE_JOB_FAILURE,
  payload: error,
});
export const resetJob = () => ({
  type: RESET_JOB,
});
