import { React, Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  updateResumeHeadlineRequest,
  resetData,
} from "../../../redux/jobseeker/resume_headline/actions";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";
import { showToast } from "../../Common/Toast";
import { useParams } from "react-router";
const Resumeheadline = (props) => {
  const dispatch = useDispatch();
  const { cand_id, jobcad_id } = useParams();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    resume_headline: props.data.resume_headline,
    jobseeker_id: props.data.jobseeker_id,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        resume_headline: formData.resume_headline,
        seeker_id: formData.jobseeker_id,
      };
      dispatch(updateResumeHeadlineRequest(data));
    }
  };

  // validate form data

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.resume_headline) {
      errors.resume_headline = "Resume headline is required";
      isValid = false;
    } else if (formData.resume_headline.length > 250) {
      errors.resume_headline = "Resume headline cannot exceed 250 characters";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const updateResumeReducerData = useSelector(
    (state) => state.updateResumeReducer
  );
  useEffect(() => {
    console.log("srd", updateResumeReducerData);
    if (
      updateResumeReducerData.status &&
      updateResumeReducerData.message != ""
    ) {
      showToast("success", updateResumeReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      props.toggleModal();
      dispatch(resetData());
    } else if (
      updateResumeReducerData.status == false &&
      updateResumeReducerData.message != ""
    ) {
      showToast("error", updateResumeReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      props.toggleModal();
      dispatch(resetData());
    }
  }, [dispatch, updateResumeReducerData]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4> Edit Resume Headline</h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <FormGroup>
            <Label>
              Resume Headline
              <span className="text-danger">*</span>
            </Label>
            <Input
              type="textarea"
              className="form-control"
              rows={8}
              name="resume_headline"
              placeholder="Enter resume headline..."
              onChange={handleInputChange}
              value={formData.resume_headline}
            ></Input>
            {errors.resume_headline && (
              <small className="text-danger">{errors.resume_headline}</small>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            disabled={updateResumeReducerData.isLoading}
            color="primary"
            type="submit"
          >
            Update
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default Resumeheadline;
