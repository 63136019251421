import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST } from "../../actionTypes";

import {
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* fetchJobseekerDashboardData(action) {
  console.log("fetchDashboardData");
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/dashboard", action.payload)
    );
    if (response.data.status) {
      yield put(fetchDashboardDataSuccess(response.data));
    } else {
      yield put(fetchDashboardDataFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchDashboardDataFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* jobseekerDashboardSaga() {
  yield takeLatest(
    FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST,
    fetchJobseekerDashboardData
  );
}
export default jobseekerDashboardSaga;
