import React, { Fragment, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Collapse,
  Input,
} from "reactstrap";
import { JobSearchContext } from "../../../../JobSearch/JobSearchContext";
import { jobTypeOptions } from "../../../../Constant";
const JobType = () => {
  const { filters, updateFilters } = useContext(JobSearchContext);
  const [isFilter, setIsFilter] = useState(true);

  // const handleCheckboxChange = (option) => {
  //   // Toggle the selected state for the clicked job type
  //   const updatedJobTypes = filters.job_type.includes(option.value)
  //     ? filters.job_type.filter((type) => type !== option.value)
  //     : [...filters.job_type, option.value];
  //   console.log("updatedJobTypes", updatedJobTypes);
  //   // Update the job types filter in the context
  //   updateFilters({ job_type: updatedJobTypes });
  // };

  const handleCheckboxChange = (option) => {
    // Check if the option's value already exists in job_type array
    const existingOption = filters.job_type.find(
      (item) => item.value === option.value
    );

    // Toggle the selected state for the clicked job type
    let updatedJobTypes = [];
    if (existingOption) {
      updatedJobTypes = filters.job_type.filter(
        (item) => item.value !== option.value
      );
    } else {
      updatedJobTypes = [...filters.job_type, option];
    }

    // Update the job types filter in the context with updated array
    updateFilters({ job_type: updatedJobTypes });
  };

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Job Type</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="filter-cards-view animate-chk border-0">
              {/* <div className="checkbox-animated"> */}
              {jobTypeOptions.map((option, index) => (
                <label
                  key={index}
                  className="d-flex"
                  htmlFor={`chk-ani-${index}`}
                >
                  <Input
                    className="checkbox_animated"
                    id={`chk-ani-${index}`}
                    type="checkbox"
                    checked={filters.job_type.some(
                      (item) => item.value === option.value
                    )}
                    onChange={() => handleCheckboxChange(option)}
                  />{" "}
                  {option.label}
                </label>
              ))}
              {/* </div> */}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default JobType;
