import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Card,
  Badge,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { showSweetAlert } from "../../Common/SweetAlert";
import EducationModal from "./EducationModal";
import { useParams } from "react-router";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { socialMediaOptions } from "../../../Constant";

import { showToast } from "../../Common/Toast";
import {
  performanceScaleOptions,
  educationLevelsOptions,
  schoolMediumsOptions,
} from "../../../Constant";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  deleteEducationBackgroundRequest,
  resetData,
} from "../../../redux/jobseeker/add_education/actions";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";
const Educationbackground = ({ profileData }) => {
  const dispatch = useDispatch();

  const [showEducationModal, setEducationModal] = useState(false);
  const { type, cand_id, jobcad_id } = useParams();
  const [existingEducationLevelOptions, setExistingEducationLevelOptions] =
    useState(null);
  useEffect(() => {
    const matchedOptions = educationLevelsOptions.filter(
      (option) =>
        profileData.education &&
        profileData.education.some(
          (education) =>
            education.education_level === option.value &&
            (education.education_level == "SECONDARY" ||
              education.education_level == "SENIOR_SECONDARY")
        )
    );
    // console.log("mat",matchedOptions);
    setExistingEducationLevelOptions(matchedOptions);
  }, []);

  const [updateEducation, setUpdateEducation] = useState({
    id: null,
    seeker_id: null,
    jobseeker_college_name: "",
    jobseeker_degree: "",
    education_level: "",
    jobseeker_education_type: "",
    jobseeker_passing_year: "",
    jobseeker_specialization: "",
    performance: "",
    performance_scale: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onEditSubmit = (data) => {
    alert(data);
  };

  const toggleEducationModal = () => {
    setEducationModal(false);
  };

  const addEducation = () => {
    setEducationModal(true);
    setUpdateEducation({
      id: null,
      seeker_id: extractAfterLastHyphen(cand_id),
      jobseeker_college_name: "",
      jobseeker_degree: "",
      education_level: existingEducationLevelOptions,
      jobseeker_education_type: "",
      passing_year: "",
      jobseeker_specialization: "",
      performance: "",
      performance_scale: "",
    });
  };

  const editEducation = (data) => {
    setEducationModal(true);
    setUpdateEducation(data);
  };

  const handleEducationBackgroundDeleteClick = (education) => {
    console.log("ji", education);
    showSweetAlert("confirm", {
      message: "You want to delete the job",
      fun: handleEducationBackground,
      id: education,
    });
  };
  const handleEducationBackground = (education) => {
    console.log("deleteEducationBackgroundRequest", education);
    // Dispatch the delete action when delete button is clicked
    dispatch(
      deleteEducationBackgroundRequest({
        seeker_id: education.seeker_id,
        id: education.id,
        type: education.type,
      })
    );
  };

  const addEducationReducerData = useSelector(
    (state) => state.addEducationReducer
  );
  useEffect(() => {
    console.log("srd", addEducationReducerData);
    if (
      addEducationReducerData.status &&
      addEducationReducerData.message != ""
    ) {
      showToast("success", addEducationReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      dispatch(resetData());
    } else if (
      addEducationReducerData.status == false &&
      addEducationReducerData.message != ""
    ) {
      showToast("error", addEducationReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      dispatch(resetData());
    }
  }, [dispatch, addEducationReducerData]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between ">
            <h4 className="mb-0 " style={{ fontWeight: 550 }}>
              Education Background
            </h4>
            {type == "view" ? null : (
              <FiPlusSquare
                className="add-icon "
                size={22}
                onClick={addEducation}
                title="Add"
              />
            )}
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ps-2">
            {profileData.education && profileData.education.length > 0 ? (
              // Render education data if available
              profileData.education.map((education) => (
                <div
                  className="d-flex justify-content-between setup mb-3"
                  key={education.id}
                >
                  {education.education_level != "SECONDARY" &&
                  education.education_level != "SENIOR_SECONDARY" ? (
                    <div>
                      <h5>
                        {education.jobseeker_degree &&
                          education.jobseeker_degree.label}{" "}
                        - {education.jobseeker_specialization},{" "}
                        {education.jobseeker_passing_year}
                      </h5>
                      <p className="text-capitalize">
                        {education.jobseeker_college_name}
                        <br />
                        <span style={{ fontWeight: "lighter" }}>
                          {
                            performanceScaleOptions.find(
                              (option) =>
                                option.value == education.performance_scale
                            )?.label
                          }{" "}
                          : {education.performance}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h5>
                        {
                          educationLevelsOptions.find(
                            (option) =>
                              option.value == education.education_level
                          )?.label
                        }{" "}
                        - {education.passing_year}{" "}
                      </h5>
                      <p className="text-capitalize">
                        {education.board} -{" "}
                        {
                          schoolMediumsOptions.find(
                            (option) => option.value == education.school_medium
                          )?.label
                        }
                        <br />
                        <span style={{ fontWeight: "lighter" }}>
                          {
                            performanceScaleOptions.find(
                              (option) =>
                                option.value == education.performance_scale
                            )?.label
                          }{" "}
                          : {education.performance}
                        </span>
                      </p>
                    </div>
                  )}

                  <div className="ms-2">
                    {/* <FiEdit
                      size={15}
                      className="text-primary profile_icons"
                      onClick={() => {
                        editEducation(education);
                      }}
                    /> */}
                    {type == "view" ? null : (
                      <div className="d-flex">
                        <FiEdit
                          className="edit-icon me-2"
                          size={22}
                          onClick={() => {
                            editEducation(education);
                          }}
                          title="Edit"
                        />
                        <RiDeleteBinLine
                          className="delete-icon "
                          size={22}
                          onClick={() =>
                            handleEducationBackgroundDeleteClick(education)
                          }
                          title="Delete"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              // Render "No data found" message if no education data
              <div className="text-center">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>

      {showEducationModal && (
        <EducationModal
          isOpen={showEducationModal}
          toggleModal={toggleEducationModal}
          data={updateEducation}
        />
      )}
    </Fragment>
  );
};
export default Educationbackground;
