import { React, Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addSocialMediaRequest,
  resetData,
} from "../../../redux/jobseeker/social_media/actions";
import Select from "react-select";
import { customOptionsStyle, socialMediaOptions } from "../../../Constant";
import { showToast } from "../../Common/Toast";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import validator from "validator"; // Import the validator library
import { useParams } from "react-router";
const Editsocialmedia = (props) => {
  const dispatch = useDispatch();
  const { cand_id, jobcad_id } = useParams();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    jobseeker_id: props.data.seeker_id,
    id: props.data.id,
    social_profile_name:
      socialMediaOptions.find((option) => {
        if (option.value == props.data.social_profile_name) {
          return option;
        }
      }) || null,
    url: (props.data.url && props.data.url) || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    setErrors({ ...errors, [name]: error });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("formData", formData);

    if (validate()) {
      const data = {
        seeker_id: formData.jobseeker_id,
        id: formData.id,
        social_profile_name:
          formData.social_profile_name.value || formData.social_profile_name,
        url: formData.url,
      };

      // Dispatch your action to update personal info
      dispatch(addSocialMediaRequest(data));
    }
    // No need to handle else here, because setErrors will be called in the validate function
  };
  // console.log("formData.social_profile_name", formData.social_profile_name);
  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.social_profile_name) {
      errors.social_profile_name = "Social profile Name is required";
      isValid = false;
    }

    if (!formData.url) {
      errors.url = "URL is required";
      isValid = false;
    } else {
      // Validate URL based on social media platform
      switch (formData.social_profile_name.value) {
        case "FACEBOOK":
          if (
            !validator.isURL(formData.url) ||
            !formData.url.includes("facebook.com")
          ) {
            errors.url = "Invalid Facebook URL";
            isValid = false;
          }
          break;
        case "GITHUB":
          if (
            !validator.isURL(formData.url) ||
            !formData.url.includes("github.com")
          ) {
            errors.url = "Invalid GitHub URL";
            isValid = false;
          }
          break;
        case "LINKEDIN":
          if (
            !validator.isURL(formData.url) ||
            !formData.url.includes("linkedin.com")
          ) {
            errors.url = "Invalid LinkedIn URL";
            isValid = false;
          }
          break;
        case "TWITTER":
          if (
            !validator.isURL(formData.url) ||
            (!formData.url.includes("twitter.com") &&
              !formData.url.includes("x.com"))
          ) {
            errors.url = "Invalid Twitter URL";
            isValid = false;
          }
          break;
        case "STACK_OVERFLOW":
          if (
            !validator.isURL(formData.url) ||
            !formData.url.includes("stackoverflow.com")
          ) {
            errors.url = "Invalid Stackoverflow URL";
            isValid = false;
          }
          break;
        // Add validation for other social media platforms here
        default:
          // Default case or other social media platforms
          if (!validator.isURL(formData.url)) {
            errors.url = "Invalid URL format";
            isValid = false;
          }
      }
    }

    console.log("is", isValid);
    console.log("errs", errors);

    setErrors(errors);

    return isValid;
  };

  // Callback to handle social media select change
  const handleSocialMediaChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, social_profile_name: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, social_profile_name: selectedOptions });
  };

  const socialReducerData = useSelector((state) => state.socialMediaReducer);

  useEffect(() => {
    console.log("srd", socialReducerData);
    if (socialReducerData.status && socialReducerData.message != "") {
      props.toggleModal();
      //  dispatch(resetData());
    } else if (
      socialReducerData.status == false &&
      socialReducerData.message != ""
    ) {
      // showToast("error", socialReducerData.message);
      props.toggleModal();
      //dispatch(resetData());
    }
  }, [dispatch, socialReducerData]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4> {props.data.id ? "Edit Social Media" : "Add Social Media"}</h4>
        </ModalHeader>
        <ModalBody className="pt-0 mt3">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Social Profile Name
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select profile name"
                  isDisabled={props.data.id ? true : false}
                  name="social_profile_name"
                  styles={customOptionsStyle}
                  options={props.data.options}
                  onChange={handleSocialMediaChange}
                  value={formData.social_profile_name}
                />

                {errors.social_profile_name && (
                  <small className="text-danger">
                    {errors.social_profile_name}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  <b>URL</b>
                  <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="url"
                  className="form-control form-control-sm"
                  placeholder="Enter URL"
                  value={formData.url}
                  onChange={handleChange}
                />
                {errors.url && (
                  <small className="text-danger">{errors.url}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            disabled={socialReducerData.isLoading}
            color="primary"
            type="submit"
          >
            {props.data.id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default Editsocialmedia;
