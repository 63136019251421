// reducers.js
import {
  FETCH_UNREAD_NOTIFICATIONS_REQUEST,
  FETCH_ALL_NOTIFICATIONS_REQUEST,
  FETCH_UNREAD_NOTIFICATIONS_SUCCESS,
  FETCH_ALL_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  MARK_ALL_AS_READ_REQUEST,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILURE,
  RESET_MARK_ALL_AS_READ_MESSAGE,
} from "../actionTypes";

const initialState = {
  unread: {
    data: [],
    count: 0,
    unreadChatMembers: 0,
    isLoading: false,
  },
  all: {
    data: [],
    count: 0,
    isLoading: false,
  },
  markAllAsRead: {
    data: null, // Initialize data to null
    message: "",
    isLoading: false,
    status: false,
  },

  error: null,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNREAD_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        unread: {
          ...state.unread,
          isLoading: true,
        },
      };
    case FETCH_ALL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        all: {
          ...state.all,
          isLoading: true,
        },
      };
    case FETCH_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unread: {
          ...state.unread,
          isLoading: false,
          data: action.payload.data,
          count: action.payload.total,
          unreadChatMembers: action.payload.total_unread_chat_member,
        },
      };
    case FETCH_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          isLoading: false,
          data: action.payload.data,
          count: action.payload.total,
        },
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        unread: {
          ...state.unread,
          isLoading: false,
        },
        all: {
          ...state.all,
          isLoading: false,
        },
        error: action.error,
      };
    case MARK_ALL_AS_READ_REQUEST:
      return {
        ...state,
        markAllAsRead: {
          ...state.markAllAsRead, // Preserve other properties
          isLoading: true,
        },
      };
    case MARK_ALL_AS_READ_SUCCESS:
      return {
        ...state,
        markAllAsRead: {
          ...state.markAllAsRead, // Preserve other properties
          isLoading: false,
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case MARK_ALL_AS_READ_FAILURE:
      return {
        ...state,
        markAllAsRead: {
          ...state.markAllAsRead, // Preserve other properties
          isLoading: false,
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case RESET_MARK_ALL_AS_READ_MESSAGE: // Reset message action
      return {
        ...state,
        markAllAsRead: {
          ...state.markAllAsRead, // Preserve other properties
          message: "",
          status: false,
        },
      };
    default:
      return state;
  }
};

export default notificationsReducer;
