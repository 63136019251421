import Sidebar from "./Sidebar";
import ListCard from "./ListCard";

import { Container, Row } from "reactstrap";
import JobSearchProvider from "../../../JobSearch/JobSearchContext";
const JobSearch = ({ marginTop = null }) => {
  return (
    <Container fluid={true}>
      <Row className="mb-5">
        <JobSearchProvider>
          <Sidebar marginTop={marginTop} />
          <ListCard />
        </JobSearchProvider>
      </Row>
    </Container>
  );
};

export default JobSearch;
