import {
  FETCH_RECOMMENDED_JOBS_REQUEST,
  FETCH_RECOMMENDED_JOBS_SUCCESS,
  FETCH_RECOMMENDED_JOBS_FAILURE,
} from "../../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const recommendedJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECOMMENDED_JOBS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RECOMMENDED_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status, // Set status from payload if needed
        message: action.payload.message, // Set message from payload if needed
      };
    case FETCH_RECOMMENDED_JOBS_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: action.payload.status,
        message: action.payload.message,
      };
    default:
      return state;
  }
};
export default recommendedJobsReducer;
