// reducer.js
import * as types from "../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  status: false,
  message: "",
};

const functionalAreaReducer = (state = initialState, action) => {
  // console.log(action.payload)
  switch (action.type) {
    case types.FETCH_FUNCTIONAL_AREA_REQUEST:
      return { ...state, isLoading: true };

    case types.FETCH_FUNCTIONAL_AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        status: action.payload.status,
      };

    case types.FETCH_FUNCTIONAL_AREA_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
        data: [],
      };

    default:
      return state;
  }
};

export default functionalAreaReducer;
