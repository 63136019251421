import { call, put, takeLatest } from "redux-saga/effects";
import { VERIFY_RECRUITER_INVITE_TOKEN_REQUEST } from "../../actionTypes";
// import { postJobApi } from "./api"; // Replace with your actual API file
import AxiosInstance from "../../../services/AxiosInstance";

import {
  verifyRecruiterInviteTokenSuccess,
  verifyRecruiterInviteTokenFailure,
} from "./actions";

function* verifyRecruiterInviteToken(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/verify_invitation_token", action.payload)
    );

    if (response.data.status) {
      yield put(verifyRecruiterInviteTokenSuccess(response.data));
    } else {
      yield put(verifyRecruiterInviteTokenFailure(response.data));
    }
  } catch (error) {
    yield put(
      verifyRecruiterInviteTokenFailure({
        status: false,
        message: error.message,
      })
    );
  }
}

// Watch for POST_JOB_REQUEST action
function* verifyRecruiterInviteTokenSaga() {
  yield takeLatest(
    VERIFY_RECRUITER_INVITE_TOKEN_REQUEST,
    verifyRecruiterInviteToken
  );
}

export default verifyRecruiterInviteTokenSaga;
