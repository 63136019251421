import {
  UNLOCK_TEMPLATE_REQUEST,
  UNLOCK_TEMPLATE_SUCCESS,
  UNLOCK_TEMPLATE_FAILURE,
  RESET_MESSAGE,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
};

const unlockTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNLOCK_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UNLOCK_TEMPLATE_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case UNLOCK_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default unlockTemplateReducer;
