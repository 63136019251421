import axios from "axios";

const baseURL = "https://api-test.naukrify.com/";

const AxiosInstance = () => {
  let token = localStorage.getItem("_token");

  return axios.create({
    baseURL,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    withCredentials: true, // Add this line to ensure credentials are included
  });
};

// let token = localStorage.getItem("_token");

// const AxiosInstance = axios.create({
//   baseURL,
//   headers: {
//     Authorization: token ? `Bearer ${token}` : undefined
//   }
// });

export default AxiosInstance;
