import React from "react";
import "./Template1.css"; // Assuming you have a CSS file for styling
import { Table, Progress } from "reactstrap";
import { getIcon } from "../../../Common/CommonFunction";
import github from "../../../../assets/images/resumeIcons/github.png";
import linkedin from "../../../../assets/images/resumeIcons/linkedin.png";
import facebook from "../../../../assets/images/resumeIcons/facebook.png";
import twitter from "../../../../assets/images/resumeIcons/twitter.png";
import stackoverflow from "../../../../assets/images/resumeIcons/stackoverflow.png";
import call from "../../../../assets/images/resumeIcons/call.png";
import email from "../../../../assets/images/resumeIcons/email.png";
import gender from "../../../../assets/images/resumeIcons/gender.png";
import couple from "../../../../assets/images/resumeIcons/couple.png";
import calendar from "../../../../assets/images/resumeIcons/calendar.png";
import location from "../../../../assets/images/resumeIcons/location.png";
import { formatDate, capitalize } from "../../../Common/CommonFunction";
import { educationLevelsOptions } from "../../../../Constant";
import { FaStar } from "react-icons/fa";

const Template1 = ({ jobseekerData = {} }) => {
  console.log("jobseekerData443", jobseekerData);
  const emptyField = (str) => {
    return str === "" || str === null ? "-" : str;
  };
  const calculateProgressValue = (rating) => {
    // Convert rating out of 10 to a percentage
    return (rating / 10) * 100;
  };
  const {
    jobseeker_full_name = "",
    jobseeker_date_of_birth = "",
    jobseeker_gender = "",
    jobseeker_marital_status = "",
    jobseeker_preferred_job_title = "",
    jobseeker_email1 = "",
    jobseeker_phone1 = "",
    jobseeker_email2 = "",
    jobseeker_phone2 = "",
    jobseeker_location = {},
    jobseeker_photo = "",
    resume_headline = "",
    work_experience = [],
    work_sample = [],
    education = [],
    it_skills = [],
    skill = [],
    online_detail_result = [],
  } = jobseekerData;

  const renderSocialIcon = (socialProfileName) => {
    switch (socialProfileName.toUpperCase()) {
      case "LINKEDIN":
        return <img src={linkedin} alt="LinkedIn Icon" />;
      case "GITHUB":
        return <img src={github} alt="GitHub Icon" />;
      default:
        return null;
    }
  };

  return (
    <>
      <main className="resume1" id="resume1">
        <div className="resume_container">
          {/* sidebar start */}
          <div className="sidebar">
            {jobseeker_photo && (
              <div className="profile_img_container">
                <img src={jobseeker_photo} alt="Profile image" />
              </div>
            )}

            <div className="sidebar-info mt-35">
              <div className="contact mb-20">
                <div className="section-title">Personal Info</div>
                <div className="contact-container mt-15">
                  <table>
                    <tbody>
                      {jobseeker_date_of_birth && (
                        <tr className="email">
                          <td>
                            <img src={calendar} alt="calendar icon" />
                          </td>
                          <td>{formatDate(jobseeker_date_of_birth)}</td>
                        </tr>
                      )}

                      {jobseeker_gender && (
                        <tr className="mobile">
                          <td>
                            <img src={gender} alt="gender icon" />
                          </td>
                          <td>{capitalize(jobseeker_gender)}</td>
                        </tr>
                      )}

                      {jobseeker_marital_status && (
                        <tr className="address">
                          <td>
                            <img src={couple} alt="couple icon" />
                          </td>
                          <td>{capitalize(jobseeker_marital_status)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="contact mb-20">
                <div className="section-title">Contact</div>
                <div className="contact-container mt-15">
                  <table>
                    <tbody>
                      {jobseeker_email1 && (
                        <tr className="email">
                          <td>
                            <img src={email} alt="email icon" />
                          </td>
                          <td>
                            <a href={`mailto:${jobseeker_email1}`}>
                              {jobseeker_email1}
                            </a>
                          </td>
                        </tr>
                      )}

                      {jobseeker_phone1 && (
                        <tr className="mobile">
                          <td>
                            <img src={call} alt="call icon" />
                          </td>
                          <td>
                            <a href={`tel:+${jobseeker_phone1}`}>
                              {jobseeker_phone1}
                            </a>
                          </td>
                        </tr>
                      )}

                      {jobseeker_location.label && (
                        <tr className="address">
                          <td>
                            <img src={location} alt="location icon" />
                          </td>
                          <td>{jobseeker_location.label}</td>
                        </tr>
                      )}
                      {online_detail_result.length > 0 &&
                        online_detail_result.map(
                          (profile, index) =>
                            (profile.social_profile_name.toUpperCase() ===
                              "LINKEDIN" ||
                              profile.social_profile_name.toUpperCase() ===
                                "GITHUB") && (
                              <tr
                                key={index}
                                className={profile.social_profile_name.toLowerCase()}
                              >
                                <td>
                                  {renderSocialIcon(
                                    profile.social_profile_name
                                  )}
                                </td>
                                <td>
                                  <a href={profile.url}>{profile.url}</a>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
              {skill.length > 0 && (
                <div className="skills mb-20">
                  <div className="section-title">Skills</div>
                  <div className="list-container mt-15">
                    <ul className="list">
                      {skill.map((item, index) => (
                        <li key={index} className="list-item">
                          {item.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className="language mb-20">
                <div className="section-title">Languages</div>
                <div className="list-container mt-15">
                  <ul className="list">
                    <li className="list-item">Hindi</li>
                    <li className="list-item">English</li>
                    <li className="list-item">Tamil</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* sidebar end */}
          {/* right box start */}
          <div className="main-content">
            <div className="section">
              <div className="name-container">
                <div>
                  <span className="fname">
                    {capitalize(jobseeker_full_name.split(" ")[0] || "")}
                  </span>
                  <br />
                  <span className="lname">
                    {capitalize(jobseeker_full_name.split(" ")[1] || "")}
                  </span>
                </div>
                <div className="resume-title">
                  {capitalize(jobseeker_preferred_job_title)}
                </div>
              </div>
            </div>
            {resume_headline && (
              <div className="section">
                <div className="section-title mb-10">Objective</div>
                <div className="section-content">
                  <p>{resume_headline}</p>
                </div>
              </div>
            )}
            {education.length > 0 && (
              <div className="section">
                <div className="section-title mb-10">Education</div>
                {education.map((edu, index) => (
                  <div key={index} className="section-content">
                    <h4>
                      {
                        educationLevelsOptions.find(
                          (option) => option.value === edu.education_level
                        )?.label
                      }{" "}
                      - {edu.passing_year}
                    </h4>
                    <p>{edu.board}</p>
                    <p>Percentage: {edu.performance}%</p>
                  </div>
                ))}
              </div>
            )}
            {work_experience.length > 0 && (
              <div className="section">
                <div className="section-title mb-10">Work Experience</div>
                {work_experience.map((work, index) => (
                  <div key={index} className="section-content">
                    <h4>
                      {capitalize(work.designation)}@{work.organization.label}
                    </h4>
                    <p>
                      {formatDate(work.started_working_from)} -{" "}
                      {formatDate(work.worked_till)}
                    </p>
                    <p>{capitalize(work.job_description)}</p>
                  </div>
                ))}
              </div>
            )}
            {it_skills.length > 0 && (
              <div className="section mb-4">
                <div className="section-title mb-10">Main Skills</div>
                <div className="row">
                  {it_skills
                    .filter((skill) => skill.skill_rating > 0)
                    .map((skill, index) => (
                      <div key={skill.id} className="col-md-6 mb-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="font-weight-bold w-50">
                            {skill.it_skill.label}
                          </span>

                          <div className="d-flex flex-column w-30">
                            <div className="d-flex">
                              {[...Array(5)].map((star, i) => {
                                const ratingValue = Math.ceil(
                                  skill.skill_rating / 2
                                ); // Convert rating to 5-star scale and round up
                                return (
                                  <FaStar
                                    key={i}
                                    color={
                                      i < ratingValue ? "#ffc107" : "#e4e5e9"
                                    }
                                    size={12}
                                    style={{ marginRight: "2px" }}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {work_sample.length > 0 && (
              <div className="section">
                <div className="section-title mb-10">Work Sample</div>
                {work_sample.map((work, index) => (
                  <div key={index} className="section-content">
                    <h4>
                      {capitalize(work.work_title)} (
                      {formatDate(work.work_start)} to{" "}
                      {formatDate(work.work_end)})
                    </h4>
                    <p className="mb-1">
                      <a href={work.work_url}>{work.work_url}</a>
                    </p>
                    <p className="mb-1">{capitalize(work.work_description)}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* right box end */}
        </div>
        <footer>
          <a href="www.naukrify.com" target="_blank" rel="noopener noreferrer">
            <img
              src="http://localhost/HTML-PDF-Converter/naukrify2.png"
              alt="Naukrify Logo"
            />
          </a>
        </footer>
      </main>
    </>
  );
};

export default Template1;
