import {
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  RESET_FEEDBACK_MESSAGE,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  is_job_feedback: 0,
  message: "",
};

const jobFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_FEEDBACK_REQUEST:
      console.log("feedback reducer line no. 17", action.payload);
      return {
        ...state,
        isLoading: true,
      };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        is_job_feedback: action.payload.is_job_feedback,
        isLoading: false,
        message: action.payload.message,
      };
    case SUBMIT_FEEDBACK_FAILURE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: action.payload.message,
      };
    case RESET_FEEDBACK_MESSAGE: // Reset message action
      return {
        ...state,
        is_job_feedback: 0,
        message: "",
        isLoading: false,
        status: false,
      };
    default:
      return state;
  }
};

export default jobFeedbackReducer;
