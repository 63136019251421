// reducer.js
import {
  GET_LINKEDIN_BANNER_REQUEST,
  GET_LINKEDIN_BANNER_SUCCESS,
  GET_LINKEDIN_BANNER_FAILURE,
} from "../actionTypes";

const initialState = {
  isLoading: false,
  error: null,
  data: [],
};

const getLinkedinBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LINKEDIN_BANNER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_LINKEDIN_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case GET_LINKEDIN_BANNER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default getLinkedinBannerReducer;
