// actions.js
import {
  FETCH_REWARDS_POINTS_REQUEST,
  FETCH_REWARDS_POINTS_SUCCESS,
  FETCH_REWARDS_POINTS_FAILURE,
  RESET_REWARDS_POINTS,
} from "../actionTypes";

export const fetchRewardsPointsRequest = () => ({
  type: FETCH_REWARDS_POINTS_REQUEST,
  //payload: { keyword },
});

export const fetchRewardsPointsSuccess = (data) => ({
  type: FETCH_REWARDS_POINTS_SUCCESS,
  payload: data,
});

export const fetchRewardsPointsFailure = (error) => ({
  type: FETCH_REWARDS_POINTS_FAILURE,
  payload: error,
});

export const resetRewardsPoints = () => ({
  type: RESET_REWARDS_POINTS,
});
