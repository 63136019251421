import { takeLatest, call, put } from "redux-saga/effects";
import { ADD_CONTACTED_COUNT_REQUEST } from "../../actionTypes";

import { addContactedCountSuccess, addContactedCountFailure } from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* addContactedCount(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/contact_count", action.payload)
    );

    if (response.data.status == true) {
      yield put(addContactedCountSuccess(response.data));
    } else {
      yield put(addContactedCountFailure(response.data));
    }
  } catch (error) {
    yield put(
      addContactedCountFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* addContactedCountSaga() {
  yield takeLatest(ADD_CONTACTED_COUNT_REQUEST, addContactedCount);
}
export default addContactedCountSaga;
