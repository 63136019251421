// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  candidateBulkExcelUploadSuccess,
  candidateBulkExcelUploadFailure,
} from "./actions";
import { CANDIDATE_BULK_EXCEL_UPLOAD_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";
function* handleCandidateBulkExcelUpload(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/bulk_upload_csv", action.payload)
    );
    console.log(response);
    if (response.data.status) {
      yield put(candidateBulkExcelUploadSuccess(response.data));
    } else {
      yield put(candidateBulkExcelUploadFailure(response.data));
    }
  } catch (error) {
    yield put(
      candidateBulkExcelUploadFailure({ status: false, message: error.message })
    );
  }
}

function* candidateBulkExcelUploadSaga() {
  yield takeLatest(
    CANDIDATE_BULK_EXCEL_UPLOAD_REQUEST,
    handleCandidateBulkExcelUpload
  );
}

export default candidateBulkExcelUploadSaga;
