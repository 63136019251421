import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import Select from "react-select";
import { showToast } from "../../Common/Toast";
import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  communicationsOptions,
  contactibilityOptions,
  technicalSkillsOptions,
  softSkillsOptions,
  experienceFitOptions,
  statusOptions,
} from "../../../Constant";
import AddNoteModal from "./AddNoteModal";
import { useParams } from "react-router-dom";
import {
  updateCandidateInfoRequest,
  resetCandidateInfoData,
} from "../../../redux/recruiter/update_candidate_info/actions";

import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  deleteNoteRequest,
  resetNoteMessage,
} from "../../../redux/recruiter/add_note/actions";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";
import { AuthContext } from "../../../Auth/AuthContext";
const CandidateStatus = ({ profileData }) => {
  const dispatch = useDispatch();
  const { cand_id, jobcad_id, type } = useParams();
  const [showAddNote, setAddNote] = useState(false);
  const { triggerTotalPoints } = useContext(AuthContext);
  const toggleAddNote = () => {
    setAddNote(!showAddNote); // Toggle the state to show/hide the modal
  };

  const addNote = () => {
    setAddNote(true); // Show the modal when "Add" button is clicked
  };

  const deleteNote = () => {
    if (jobcad_id) {
      dispatch(deleteNoteRequest({ id: extractAfterLastHyphen(jobcad_id) }));
    }
  };

  const [formData, setFormData] = useState({
    status:
      (profileData.job_candidate_details &&
        profileData.job_candidate_details.candidate_status &&
        statusOptions.find(
          (option) =>
            option.value === profileData.job_candidate_details.candidate_status
        )) ||
      null,
    contactibility:
      (profileData.job_candidate_details &&
        profileData.job_candidate_details.contactibility &&
        contactibilityOptions.find(
          (option) =>
            option.value === profileData.job_candidate_details.contactibility
        )) ||
      null,
    communications:
      (profileData.job_candidate_details &&
        profileData.job_candidate_details.communication &&
        communicationsOptions.find(
          (option) =>
            option.value === profileData.job_candidate_details.communication
        )) ||
      null,
    experience_fit:
      (profileData.job_candidate_details &&
        profileData.job_candidate_details.experience_fit &&
        experienceFitOptions.find(
          (option) =>
            option.value === profileData.job_candidate_details.experience_fit
        )) ||
      null,
    soft_skills:
      profileData.job_candidate_details &&
      profileData.job_candidate_details.soft_skills &&
      profileData.job_candidate_details.soft_skills.length > 0
        ? profileData.job_candidate_details.soft_skills
        : null,

    technical_skills:
      profileData.job_candidate_details &&
      profileData.job_candidate_details.technical_skills &&
      profileData.job_candidate_details.technical_skills.length > 0
        ? profileData.job_candidate_details.technical_skills
        : null,
    note:
      (profileData.job_candidate_details &&
        profileData.job_candidate_details.candidate_note) ||
      "",
    rating:
      (profileData.job_candidate_details &&
        profileData.job_candidate_details.candidate_rating) ||
      0,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};
    if (!formData.status) formErrors.status = "Status is required";
    if (!formData.contactibility)
      formErrors.contactibility = "Contactability is required";
    if (!formData.communications)
      formErrors.communications = "Communications is required";
    if (!formData.technical_skills)
      formErrors.technical_skills = "Technical skills are required";
    if (!formData.soft_skills)
      formErrors.soft_skills = "Soft skills are required";
    if (!formData.experience_fit)
      formErrors.experience_fit = "Experience fit is required";

    if (!formData.note.trim()) {
      formErrors.note = "Note is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  console.log("errors", errors);
  const handleSubmit = () => {
    if (!validateForm()) return;

    if (jobcad_id) {
      const data = {
        id: extractAfterLastHyphen(jobcad_id),
        candidate_status: formData.status.value,
        contactibility_tag: formData.contactibility.value,
        communication_tag: formData.communications.value,
        exp_fit: formData.experience_fit.value,
        technical_skills: formData.technical_skills
          ? formData.technical_skills.map((skill) => skill.value).join(",")
          : [],

        soft_skills: formData.soft_skills
          ? formData.soft_skills.map((skill) => skill.value).join(",")
          : [],
        rating: formData.rating,
        note: formData.note || "",
      };
      dispatch(updateCandidateInfoRequest(data));
    }
  };

  // Function to dispatch action on dropdown change
  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption });
    setErrors({ ...errors, status: "" }); // Reset status error
  };

  const handleContactibilityChange = (selectedOption) => {
    setFormData({ ...formData, contactibility: selectedOption });
    setErrors({ ...errors, contactibility: "" }); // Reset contactibility error
  };

  const handleCommunicationsChange = (selectedOption) => {
    setFormData({ ...formData, communications: selectedOption });
    setErrors({ ...errors, communications: "" }); // Reset communications error
  };

  const handleTechnicalSkillsChange = (selectedOption) => {
    setFormData({ ...formData, technical_skills: selectedOption });
    setErrors({ ...errors, technical_skills: "" }); // Reset technical skills error
  };

  const handleSoftSkillsChange = (selectedOption) => {
    setFormData({ ...formData, soft_skills: selectedOption });
    setErrors({ ...errors, soft_skills: "" }); // Reset soft skills error
  };

  const handleExperienceFitChange = (selectedOption) => {
    setFormData({ ...formData, experience_fit: selectedOption });
    setErrors({ ...errors, experience_fit: "" }); // Reset experience fit error
  };

  const handleRatingChange = (newRating) => {
    setFormData({
      ...formData,
      rating: newRating,
    });
    setErrors({ ...errors, rating: "" }); // Reset rating error
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Clear the error message when input changes
    let error = "";

    setErrors({ ...errors, note: error });

    // Check if the input length exceeds 150 characters
    if (value.length <= 150) {
      setFormData({ ...formData, note: value });
    } else {
      // Truncate the input value to 150 characters
      setFormData({ ...formData, note: value.slice(0, 150) });
    }
  };

  let updateCandidateInfoReducerData = useSelector(
    (state) => state.updateCandidateInfoReducer
  );

  useEffect(() => {
    if (
      updateCandidateInfoReducerData.status &&
      updateCandidateInfoReducerData.message !== ""
    ) {
      showToast("success", updateCandidateInfoReducerData.message);

      if (updateCandidateInfoReducerData.is_candidate_feedback) {
        triggerTotalPoints("candidate_feedback", true, false);
      }

      const data = {
        seeker_id: extractAfterLastHyphen(cand_id),
        job_cand_id: extractAfterLastHyphen(jobcad_id),
      };
      dispatch(fetchJobseekerDataRequest(data));

      dispatch(resetCandidateInfoData());
    } else if (
      updateCandidateInfoReducerData.status === false &&
      updateCandidateInfoReducerData.message !== ""
    ) {
      showToast("error", updateCandidateInfoReducerData.message);

      dispatch(resetCandidateInfoData());
    }
  }, [updateCandidateInfoReducerData]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
            Job Candidate Feedback
          </h4>
        </CardHeader>
        <CardBody className="">
          <Row>
            <Col xl="6">
              <FormGroup>
                <Label for="status">
                  Status<span className="text-danger">*</span>
                </Label>
                <Select
                  options={statusOptions}
                  placeholder="Select status"
                  value={formData.status}
                  onChange={handleStatusChange} // Call the appropriate handler
                  // isDisabled={type === "view"}
                />
                {errors.status && (
                  <p className="text-danger">{errors.status}</p>
                )}
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup>
                <Label for="contactibility">
                  Contactability<span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select contactibility"
                  options={contactibilityOptions}
                  value={formData.contactibility}
                  onChange={handleContactibilityChange} // Call the appropriate handler
                  // isDisabled={type === "view"}
                />
                {errors.contactibility && (
                  <p className="text-danger">{errors.contactibility}</p>
                )}
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup>
                <Label for="communications">
                  Communications<span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select communication"
                  options={communicationsOptions}
                  value={formData.communications}
                  onChange={handleCommunicationsChange} // Call the appropriate handler
                  //  isDisabled={type === "view"}
                />
                {errors.communications && (
                  <p className="text-danger">{errors.communications}</p>
                )}
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup>
                <Label for="technical_skills">
                  Technical Skills<span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select technical skills"
                  isMulti
                  options={technicalSkillsOptions}
                  value={formData.technical_skills}
                  onChange={handleTechnicalSkillsChange} // Call the appropriate handler
                  // isDisabled={type === "view"}
                />
                {errors.technical_skills && (
                  <p className="text-danger">{errors.technical_skills}</p>
                )}
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup>
                <Label for="soft_skills">
                  Soft Skills<span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select soft skills"
                  isMulti
                  options={softSkillsOptions}
                  value={formData.soft_skills}
                  onChange={handleSoftSkillsChange} // Call the appropriate handler
                  //isDisabled={type === "view"}
                />
                {errors.soft_skills && (
                  <p className="text-danger">{errors.soft_skills}</p>
                )}
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup>
                <Label for="experience_fit">
                  Experience Fit<span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select experience"
                  options={experienceFitOptions}
                  value={formData.experience_fit}
                  onChange={handleExperienceFitChange} // Call the appropriate handler
                  //isDisabled={type === "view"}
                />
                {errors.experience_fit && (
                  <p className="text-danger">{errors.experience_fit}</p>
                )}
              </FormGroup>
            </Col>

            <Col xl="12">
              <FormGroup>
                <Label>
                  Note
                  <span className="text-danger">*</span>
                </Label>

                <textarea
                  //  disabled={type === "view"}
                  value={formData.note}
                  name="note"
                  placeholder="Enter note"
                  onChange={handleChange}
                  className="form-control "
                  rows={3} // Set the number of rows here
                />
                {errors.note && <p className="text-danger">{errors.note}</p>}
              </FormGroup>
            </Col>
            <Col xl="12">
              <FormGroup>
                <Label>Rating</Label>
                <div className="stars">
                  {[...Array(5)].map((_, index) => {
                    const starValue = index + 1;
                    return (
                      <span
                        key={starValue}
                        onClick={() => handleRatingChange(starValue)}
                        style={{
                          cursor: "pointer",
                          color:
                            starValue <= formData.rating ? "orange" : "gray",
                          fontSize: "30px",
                        }}
                      >
                        &#9733;
                      </span>
                    );
                  })}
                </div>
              </FormGroup>
            </Col>
            <Col xl="12">
              <FormGroup>
                <div className="mt-4">
                  <Button
                    color="primary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                    onClick={handleSubmit}
                    disabled={updateCandidateInfoReducerData.isLoading}
                  >
                    Update
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CandidateStatus;
