import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  CardBody,
  CardTitle,
  Row,
  Button,
  Badge,
  Label,
  CardText,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveFavoriteJobRequest,
  resetFavoriteMessage,
} from "../../../redux/jobseeker/favorite_job/actions";
import {
  fetchJobDetails,
  applyJobRequest,
  resetMessage,
} from "../../../redux/jobdetails/actions";
import { useNavigate } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";
import { RiMenu2Line } from "react-icons/ri";
import { IoSchoolOutline } from "react-icons/io5";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import { MdCurrencyRupee } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { BsSuitcaseLg } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsFillFlagFill, BsBookmarkFill, BsChatFill } from "react-icons/bs";
import { PreloaderContext } from "../../../_helper/Preloader/PreloaderContext";
import { showToast } from "../../Common/Toast";
import { LOADER_TIMEOUT, benefitsOptions } from "../../../Constant";
import Spinner from "../../Common/Spinner";
import {
  submitReportRequest,
  resetReportJobData,
} from "../../../redux/jobseeker/report_job/actions";
import {
  formatDate,
  convertMonthsToYears,
  generateSlug,
} from "../../Common/CommonFunction";
import { AuthContext } from "../../../Auth/AuthContext";
import { removeHTMLTags } from "../../Common/CommonFunction";
import {
  formatExperienceOutput,
  capitalize,
  extractAfterLastHyphen,
} from "../../Common/CommonFunction";
import { formatSalaryOutput } from "../../Common/CommonFunction";
import { FaCheckCircle, FaLock } from "react-icons/fa";
import { FiStar } from "react-icons/fi";
import { IoIosStar } from "react-icons/io";
import whatsapp from "..//../../assets/images/Icons/whatsapp.png";
import facebook from "../../../assets/images/Icons/facebook.png";
import twitter from "../../../assets/images/Icons/twitter.png";
import linkedin from "../../../assets/images/Icons/linkedin.png";
import NoDataFound from "../../Common/NoDataFound";
import instagram from "../../../assets/images/Icons/instagram.png";
import { SYSTEM_NAME } from "../../../Constant";
import {
  jobSharingRequest,
  resetJobSharingMessage,
} from "../../../redux/job_sharing/actions";
import { jobTypeOptions } from "../../../Constant";
import { employmentTypeOptions } from "../../../Constant";
import { GrLocation } from "react-icons/gr";
import { PiSuitcaseSimple } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import {
  joinGroupRequest,
  joinGroupReset,
} from "../../../redux/join_group/actions";
const JobDetails = () => {
  const { user, triggerTotalPoints } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { showPreloader } = useContext(PreloaderContext);
  const jobReducerData = useSelector((state) => state.jobDetailsReducer);
  console.log("rd", jobReducerData);
  const jobData = jobReducerData.data;
  const [rating, setRating] = useState("");
  const referralLink = `${window.location.origin}${process.env.PUBLIC_URL}/public/job_details/${id}`;
  // const referralLink = `https://www.google.com`;
  const shareOnSocialMedia = (platform) => {
    // Logic to share referral link on selected social media platform
    let shareUrl = "";
    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Naukrify | Job apply link – ${referralLink}`
        )}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
          referralLink
        )}`;
        break;
      case "twitter":
        shareUrl = `http://twitter.com/share?url=${encodeURIComponent(
          referralLink
        )}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          referralLink
        )}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/`; // Instagram sharing URL (placeholder)
        alert(
          "Instagram sharing requires the mobile app or deeper integration. Redirecting to Instagram."
        );
        break;
      default:
        break;
    }
    dispatch(jobSharingRequest()); // Dispatch the log action
    setTimeout(() => {
      window.open(shareUrl, "_blank"); // Open the share URL in a new tab
    }, 2000); // Delay of 2 seconds (2000 milliseconds)
  };

  useEffect(() => {
    dispatch(fetchJobDetails({ job_id: extractAfterLastHyphen(id) }));
  }, [id]);

  useEffect(() => {
    if (jobReducerData.status == true && jobReducerData.message != "") {
      showToast("success", jobReducerData.message);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetMessage());

      // Reset form data
    } else if (jobReducerData.status == false && jobReducerData.message != "") {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", jobReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", jobReducerData.message);
      }

      // Dispatch action to reset the message after showing the toast
      dispatch(resetMessage());
    }
  }, [jobReducerData]);

  // const handleApplyNow = () => {
  //   // Dispatch action to apply job
  //   if (jobData.posting_type == "EXTERNAL") {
  //     window.open(jobData.job_external_url, "_blank");
  //   } else {
  //     dispatch(
  //       applyJobRequest(extractAfterLastHyphen(id), jobData.recruiter_id)
  //     );
  //   }
  // };

  const handleReportJobClick = () => {
    const formattedData = {
      job_id: extractAfterLastHyphen(id),
    };

    navigate(`${process.env.PUBLIC_URL}/public/report_job/${id}`);
  };

  const handleEditJobClick = (row) => {
    const url = `${process.env.PUBLIC_URL}/recruiter/post_job/${
      row.employment_type === "INTERNSHIP" ? "internship" : "job"
    }/${generateSlug(
      row.job_name,
      row.job_location,
      formatExperienceOutput(
        row.work_experience_min,
        row.work_experience_max
      ).replace("-", "-to-"),
      row.job_id
    )}`;
    navigate(url);
  };

  const reportJobReducerData = useSelector((state) => state.reportJobReducer);
  useEffect(() => {
    if (reportJobReducerData.status && reportJobReducerData.message != "") {
      showToast("success", reportJobReducerData.message);

      dispatch(resetReportJobData());
    } else if (
      reportJobReducerData.status == false &&
      reportJobReducerData.message != ""
    ) {
      showToast("error", reportJobReducerData.message);

      dispatch(resetReportJobData());
    }
  }, [reportJobReducerData]);

  // const handleFavouriteJobClick = () => {
  //   if (user.isLoggedin) {
  //     dispatch(saveFavoriteJobRequest({ job_id: extractAfterLastHyphen(id) }));
  //   } else {
  //     // User is not logged in, navigate to the login page
  //     navigate(`${process.env.PUBLIC_URL}/auth/login`); // Use navigate for navigation
  //   }
  // };

  // const handleFeedbackJobClick = () => {
  //   if (user.isLoggedin) {
  //     navigate(`${process.env.PUBLIC_URL}/jobseeker/job_feedback/${id}`); // Use navigate for navigation
  //   } else {
  //     // User is not logged in, navigate to the login page
  //     navigate(`${process.env.PUBLIC_URL}/auth/login`); // Use navigate for navigation
  //   }
  // };

  const favoriteJobReducerData = useSelector(
    (state) => state.favoriteJobReducer
  );
  useEffect(() => {
    if (favoriteJobReducerData.status && favoriteJobReducerData.message != "") {
      showToast("success", favoriteJobReducerData.message);

      dispatch(resetFavoriteMessage());

      navigate(`${process.env.PUBLIC_URL}/jobseeker/saved_jobs`);
    } else if (
      favoriteJobReducerData.status == false &&
      favoriteJobReducerData.message != ""
    ) {
      showToast("error", favoriteJobReducerData.message);

      dispatch(resetFavoriteMessage());
    }
  }, [favoriteJobReducerData]);

  const jobSharingReducerData = useSelector((state) => state.jobSharingReducer);

  useEffect(() => {
    if (jobSharingReducerData.status && jobSharingReducerData.message != "") {
      //  showToast("success", jobSharingReducerData.message);
      triggerTotalPoints("share_jobs", true);
      dispatch(resetJobSharingMessage());
    } else if (
      jobSharingReducerData.status == false &&
      jobSharingReducerData.message != ""
    ) {
      showToast("error", jobSharingReducerData.message);

      dispatch(resetJobSharingMessage());
    }
  }, [jobSharingReducerData]);

  const joinInGroup = (groupType, url) => {
    const formattedData = {
      url: url,
      group_type: groupType,
    };

    dispatch(joinGroupRequest(formattedData));
  };

  const joinGroupReducerData = useSelector((state) => state.joinGroupReducer);
  useEffect(() => {
    if (joinGroupReducerData.status && joinGroupReducerData.message != "") {
      //  showToast("success", jobSharingReducerData.message);
      if (jobSharingReducerData.type) {
        triggerTotalPoints(jobSharingReducerData.type, true);
      }
      dispatch(joinGroupReset());
    } else if (
      joinGroupReducerData.status == false &&
      joinGroupReducerData.message != ""
    ) {
      showToast("error", joinGroupReducerData.message);

      dispatch(joinGroupReset());
    }
  }, [joinGroupReducerData]);

  return (
    <Fragment>
      <Container fluid={true}>
        {!jobReducerData.isLoading && jobData ? (
          <Row className="mb-5">
            <Col md={7}>
              <Card>
                <CardBody>
                  <div className="media">
                    <div className="media-body">
                      <h4 className="f-w-500 text-capitalize me-3 mb-0">
                        <a
                          href="#javascript"
                          style={{
                            display: "block",
                            overflowWrap: "break-word",
                          }}
                        >
                          {capitalize(jobData.job_name)}
                        </a>
                      </h4>

                      <span
                        className="me-2 d-flex align-items-center  f-w-500 "
                        style={{ minHeight: "1em" }}
                      >
                        {/* <FaRegBuilding
                              size={16}
                              className="me-1"
                              style={{ color: "green" }}
                            /> */}
                        <span className="me-1">
                          {jobData.company_name &&
                            capitalize(jobData.company_name)}
                        </span>
                        {jobData.average_rating && (
                          <div className="stars me-2 icon-container ">
                            {[...Array(5)].map((_, i) => {
                              const starValue = i + 1;
                              const averageRating =
                                jobData.average_rating ?? "";
                              return (
                                <span
                                  key={starValue}
                                  className=""
                                  style={{
                                    cursor: "pointer",
                                    color:
                                      starValue <= averageRating
                                        ? "orange"
                                        : "gray",
                                    fontSize: "calc(0.8em + 0.5vw)",
                                  }}
                                >
                                  &#9733;
                                </span>
                              );
                            })}
                          </div>
                        )}
                      </span>
                      <p
                        className=" d-flex align-items-center flex-wrap  f-w-500"
                        style={{ color: "#121224", minHeight: "3em" }} // Adjust minHeight as needed
                      >
                        <span className=" d-flex align-items-center">
                          <PiSuitcaseSimple
                            size={19}
                            className="me-1 suit-case-icon"
                          />
                          {jobData.work_experience_min ||
                          jobData.work_experience_max ? (
                            <div className="mb-0 d-flex align-items-center">
                              <span>
                                {formatExperienceOutput(
                                  jobData.work_experience_min,
                                  jobData.work_experience_max
                                )}
                              </span>
                            </div>
                          ) : (
                            "N/A"
                          )}
                        </span>
                        <span className="mx-2">|</span>{" "}
                        {jobData.is_disclose_salary == 1 ? (
                          // Salary is disclosed
                          <span className="me-2 mb-0 d-flex align-items-center">
                            <MdCurrencyRupee
                              className="me-1 rupee-icon"
                              size={18}
                            />
                            {jobData.salary_min || jobData.salary_max
                              ? formatSalaryOutput(
                                  jobData.salary_min,
                                  jobData.salary_max
                                )
                              : "N/A"}
                          </span>
                        ) : (
                          // Salary is not disclosed
                          <span className="me-2 mb-0 d-flex align-items-center">
                            <MdCurrencyRupee
                              className="me-1 rupee-icon"
                              size={18}
                            />
                            Not disclosed
                          </span>
                        )}
                        <span className="mx-2">|</span>{" "}
                        <span className="d-flex align-items-center">
                          <GrLocation
                            size={19}
                            className="me-1 location-icon"
                          />
                          {jobData.job_location
                            ? capitalize(jobData.job_location)
                            : "N/A"}
                        </span>
                      </p>
                    </div>
                    {jobData.job_status !== "CLOSED" && (
                      <Button
                        color="primary"
                        className="mt-1 d-flex align-items-center ps-3 pe-3"
                        disabled={jobData.job_applied_status}
                        onClick={() => handleEditJobClick(jobData)}
                      >
                        <FiEdit size="18px" className="me-1" />
                        Edit
                      </Button>
                    )}
                  </div>

                  <div className="mt-4">
                    <h5 style={{ display: "flex", alignItems: "center" }}>
                      <RiMenu2Line style={{ marginRight: "5px" }} /> Job Details
                    </h5>
                    <div className="mt-3">
                      {jobData.no_of_positions && (
                        <CardText>
                          <strong>Vacancy: </strong> {jobData.no_of_positions}
                        </CardText>
                      )}
                      {jobData.industry_area && (
                        <CardText>
                          <strong>Industry Type:</strong>{" "}
                          {jobData.industry_area}
                        </CardText>
                      )}

                      {jobData.functional_area && (
                        <CardText>
                          <strong>Functional Area:</strong>{" "}
                          {jobData.functional_area}
                        </CardText>
                      )}

                      {jobData.job_type && (
                        <CardText>
                          <strong>Job Type:</strong>{" "}
                          {(() => {
                            const jobType = jobTypeOptions.find(
                              (option) => option.value === jobData.job_type
                            );
                            return jobType ? jobType.label : "Unknown"; // Assuming jobTypeOptions have a 'label' property
                          })()}
                        </CardText>
                      )}

                      {jobData.employment_type && (
                        <CardText>
                          <strong>Employment Type:</strong>{" "}
                          {(() => {
                            const employmentType = employmentTypeOptions.find(
                              (option) =>
                                option.value === jobData.employment_type
                            );
                            return employmentType
                              ? employmentType.label
                              : "Unknown"; // Assuming employmentTypeOptions have a 'label' property
                          })()}
                        </CardText>
                      )}
                      {jobData.created_at && (
                        <CardText>
                          <strong>Published on:</strong>{" "}
                          {formatDate(jobData.created_at)}
                        </CardText>
                      )}

                      {jobData.job_last_date && (
                        <CardText>
                          <strong>Application Deadline:</strong>
                          {""} {formatDate(jobData.job_last_date)}
                        </CardText>
                      )}

                      {jobData.hit && (
                        <CardText>
                          <strong>Job Views:</strong> {jobData.hit}
                        </CardText>
                      )}
                    </div>
                  </div>

                  {jobData.job_description && (
                    <div className="mt-4">
                      <h5 style={{ display: "flex", alignItems: "center" }}>
                        <IoDocumentTextOutline style={{ marginRight: "5px" }} />{" "}
                        Job Description
                      </h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: jobData.job_description,
                        }}
                      >
                        {/* {removeHTMLTags(jobData.job_description)} */}
                        {/* {jobData.job_description} */}
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <br />
                    <div className="d-flex align-items-center">
                      <span>Share: &nbsp;&nbsp;</span>

                      <img
                        src={whatsapp}
                        className="share-link share_refferal me-2"
                        onClick={() => shareOnSocialMedia("whatsapp")}
                        aria-label="Whatsapp"
                        alt="whatsapp"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                        title="Share on Whatsapp"
                      />
                      <img
                        src={facebook}
                        className="share-link share_refferal me-2"
                        onClick={() => shareOnSocialMedia("facebook")}
                        aria-label="Facebook"
                        alt="facebook"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                        title="Share on Facebook"
                      />
                      <img
                        src={twitter}
                        className="share-link share_refferal me-2"
                        onClick={() => shareOnSocialMedia("twitter")}
                        aria-label="Twitter"
                        alt="twitter"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                        title="Share on Twitter"
                      />
                      <img
                        src={linkedin}
                        className="share-link share_refferal me-2"
                        onClick={() => shareOnSocialMedia("linkedin")}
                        aria-label="Linkedin"
                        alt="linkedin"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                        title="Share on Linkedin"
                      />
                      <img
                        src={instagram}
                        className="share-link share_refferal "
                        onClick={() => shareOnSocialMedia("instagram")}
                        aria-label="Instagram"
                        alt="instagram"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                        title="Share on Instagram"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              {jobData.qualifications && (
                <Card>
                  <CardBody>
                    <h5 style={{ display: "flex", alignItems: "center" }}>
                      <IoSchoolOutline style={{ marginRight: "5px" }} />{" "}
                      Education (Minimum Qualification)
                    </h5>
                    <ul
                      className="mt-3"
                      style={{ listStyleType: "disc", paddingLeft: "20px" }}
                    >
                      {jobData.qualifications
                        .split(",")
                        .map((qualification, index) => (
                          <li key={index}>{qualification.trim()}</li>
                        ))}
                    </ul>
                  </CardBody>
                </Card>
              )}

              {jobData.mandatory_skills && (
                <Card>
                  <CardBody>
                    <h5 style={{ display: "flex", alignItems: "center" }}>
                      <IoExtensionPuzzleOutline
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Skills Required
                    </h5>
                    <div className="mt-4">
                      {jobData.mandatory_skills && (
                        <div className="mt-2">
                          <strong className="mb-2">Mandatory Skills</strong>

                          {jobData.mandatory_skills && (
                            <div>
                              {jobData.mandatory_skills
                                .split(",")
                                .map((skill) => skill.trim())
                                .sort((a, b) =>
                                  a === jobData.primary_skill
                                    ? -1
                                    : b === jobData.primary_skill
                                    ? 1
                                    : 0
                                ) // Sort to ensure primary skill is first
                                .slice(0, 3)
                                .map((skill, index) => {
                                  const isPrimary =
                                    skill === jobData.primary_skill; // Check if current skill is primary
                                  return (
                                    <Badge
                                      key={index}
                                      className={`me-2 mb-2 border rounded  ${
                                        isPrimary
                                          ? "fw-bolder text-primary"
                                          : "text-black"
                                      }`}
                                      color="gray"
                                      pill
                                    >
                                      {skill}
                                      {isPrimary && (
                                        <FiStar
                                          style={{
                                            marginLeft: "5px",
                                            color: "blue", // Example: Change star color for primary skill
                                          }}
                                        />
                                      )}
                                    </Badge>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}

                      {jobData.good_to_have_skills && (
                        <div className="mt-2">
                          <strong className="mb-2">Good to Have Skills</strong>
                          <div>
                            {jobData.good_to_have_skills
                              .split(",")
                              .map((skill, index) => (
                                <Badge
                                  className="me-2 mb-2 border rounded text-black"
                                  color="gray"
                                  pill
                                >
                                  {skill.trim()}
                                </Badge>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}

              {jobData.job_benefits && (
                <Card>
                  <CardBody>
                    <h5 style={{ display: "flex", alignItems: "center" }}>
                      <IoDocumentTextOutline style={{ marginRight: "5px" }} />{" "}
                      Benefits
                    </h5>

                    <ul className="mt-3">
                      {jobData.job_benefits
                        .split(",")
                        .map((identifier, index) => {
                          const benefit = benefitsOptions.find(
                            (option) => option.value === identifier
                          );
                          return benefit ? (
                            <li key={index}>{benefit.label}</li>
                          ) : null;
                        })
                        .filter((item) => item !== null)}
                    </ul>
                  </CardBody>
                </Card>
              )}
              <div
                //className="alert alert-warning mt-3"
                style={{
                  marginTop: "20px",
                  padding: "10px",
                  backgroundColor: "#fff3cd",
                  border: "1px solid #ffeeba",
                  borderRadius: "14px",
                  color: "#856404",
                }}
                role="alert"
              >
                If an employer asks you to pay any kind of fee, please notify us
                immediately. {SYSTEM_NAME} does not charge any fee from the
                applicants and we do not allow other companies also to do so.
              </div>
            </Col>
            <Col md={5}>
              <Card>
                <CardBody>
                  <h5 style={{ display: "flex", alignItems: "center" }}>
                    <FaRegBuilding style={{ marginRight: "5px" }} /> Company
                    Overview
                  </h5>
                  <Row className="mt-4">
                    <Col xs="auto">
                      {jobData.company_logo ? (
                        <img
                          src={jobData.company_logo}
                          alt="Company Logo"
                          style={{ width: "100px", objectFit: "contain" }}
                          className="img-responsive rounded"
                        />
                      ) : (
                        <div className="company_name_bg">
                          {jobData.company_name
                            ? jobData.company_name.charAt(0).toUpperCase()
                            : ""}
                        </div>
                      )}
                    </Col>
                    <Col>
                      <div className="company-info text-capitalize">
                        <h6>{capitalize(jobData.company_name)}</h6>
                        <p className="mb-0">{jobData.address}</p>
                        {jobData.company_website && (
                          <p className="mb-0">
                            <a
                              href={"http://" + jobData.company_website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visit website
                            </a>
                          </p>
                        )}
                        <p>{jobData.jobs_count} Current Jobs Openings</p>

                        <div className="d-flex align-items-center mt-2">
                          {/* <span>Share: &nbsp;&nbsp;</span> */}
                          {jobData.company_facebook_url && (
                            <Link
                              to={jobData.company_facebook_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={facebook}
                                className="share-link share_refferal me-2"
                                aria-label="Facebook"
                                alt="facebook"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                title="Share on Facebook"
                              />
                            </Link>
                          )}
                          {jobData.company_twitter_url && (
                            <Link
                              to={jobData.company_twitter_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={twitter}
                                className="share-link share_refferal me-2"
                                aria-label="Twitter"
                                alt="twitter"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                title="Share on Twitter"
                              />
                            </Link>
                          )}
                          {jobData.company_linkedin_url && (
                            <Link
                              to={jobData.company_linkedin_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={linkedin}
                                className="share-link share_refferal me-2"
                                aria-label="Linkedin"
                                alt="linkedin"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                title="Share on Linkedin"
                              />
                            </Link>
                          )}
                          {jobData.company_instagram_url && (
                            <Link
                              to={jobData.company_instagram_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={instagram}
                                className="share-link share_refferal "
                                aria-label="Instagram"
                                alt="instagram"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                title="Share on Instagram"
                              />{" "}
                            </Link>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {jobData.company_about && (
                    <div>
                      <hr />
                      <p className="company-about text-justify mb-1">
                        {jobData.company_about}
                      </p>
                      {jobData.company_website && (
                        <a
                          target="_blank"
                          href={"http://" + jobData.company_website}
                        >
                          Read More
                        </a>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h5
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#25D366",
                    }}
                  >
                    <img
                      src={require("../../../assets/images/Icons/whatsapp.png")}
                      width="30px"
                      className="me-2"
                      alt="WhatsApp Icon"
                    />
                    <strong>Join Our WhatsApp Group</strong>
                  </h5>
                  <p>
                    Don't miss out on the latest job updates! Connect with us on
                    WhatsApp and stay ahead in your career.
                  </p>

                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup(
                            "WHATSAPP",
                            "https://chat.whatsapp.com/EMgfWNER5ThF2iM8kvrEDi"
                          )
                        }
                        href="https://chat.whatsapp.com/EMgfWNER5ThF2iM8kvrEDi"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Naukrify Jobs
                      </a>
                    </li>
                  </ul>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#0088cc", // Telegram's brand color
                    }}
                  >
                    <img
                      src={require("../../../assets/images/Icons/telegram.png")} // Assuming you have a Telegram icon
                      width="30px"
                      className="me-2"
                      alt="Telegram Icon"
                    />
                    <strong>Join Our Telegram Group</strong>
                  </h5>
                  <p>
                    Don't miss out on the latest job updates! Connect with us on
                    Telegram and stay ahead in your career.
                  </p>

                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup("TELEGRAM", "https://t.me/India_IT_JOBS")
                        }
                        href="https://t.me/India_IT_JOBS" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        India IT Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup(
                            "TELEGRAM",
                            "https://t.me/Bangalore_Job_Seekers"
                          )
                        }
                        href="https://t.me/Bangalore_Job_Seekers" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Bangalore Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup(
                            "TELEGRAM",
                            "https://t.me/India_NON_IT_JOBS"
                          )
                        }
                        href="https://t.me/India_NON_IT_JOBS" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        India Non IT Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup(
                            "TELEGRAM",
                            "https://t.me/Hyderabad_Job_Seekers"
                          )
                        }
                        href="https://t.me/Hyderabad_Job_Seekers" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Hyderabad Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup(
                            "TELEGRAM",
                            "https://t.me/Freshers_Jobs_India"
                          )
                        }
                        href="https://t.me/Freshers_Jobs_India" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Freshers Jobs India
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup("TELEGRAM", "https://t.me/All_India_Jobs")
                        }
                        href="https://t.me/All_India_Jobs" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        All India Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup(
                            "TELEGRAM",
                            "https://t.me/NoidaJobSeekers"
                          )
                        }
                        href="https://t.me/NoidaJobSeekers" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Noida Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          joinInGroup(
                            "TELEGRAM",
                            "https://t.me/Naukri_Jobs_India"
                          )
                        }
                        href="https://t.me/Naukri_Jobs_India" // Replace with your Telegram group link
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Naukri Jobs India
                      </a>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : jobReducerData.isLoading ? (
          <Spinner />
        ) : (
          <NoDataFound message={"This job is no longer available"} />
        )}
      </Container>
    </Fragment>
  );
};

export default JobDetails;
