import React, { Fragment } from "react";
import Chat from "./Chat";
import Searchbar from "./Searchbar";
import Notificationbar from "./Notificationbar";
import UserHeader from "./UserHeader";
import { Col } from "reactstrap";
import NotificationProvider from "../../../../Notifications/NotificationContext";
import ChatProvider from "../../../../Chat/ChatProvider";
import TotalPoints from "./TotalPoints";
const RightHeader = () => {
  return (
    <Fragment>
      <Col
        xxl="7"
        xl="6"
        md="7"
        className="nav-right pull-right right-header col-8 p-0 ms-auto"
      >
        {/* <Col md="8"> */}
        <ul className="simple-list nav-menus flex-row">
          {/* <TotalPoints /> */}
          <Searchbar />

          <NotificationProvider>
            <Chat />
            <Notificationbar />
          </NotificationProvider>

          <UserHeader />
        </ul>
        {/* </Col> */}
      </Col>
    </Fragment>
  );
};

export default RightHeader;
