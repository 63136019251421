import React, { useContext } from "react";
import { Card, CardBody, Col, Media } from "reactstrap";
import CarToon from "../../../assets/images/dashboard/cartoon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import trophy from "../../../assets/images/trophy.png";
import { showToast } from "../../Common/Toast";
import { resetDailyVisit } from "../../../redux/recruiter/dashboard/actions";
import { AuthContext } from "../../../Auth/AuthContext";
import { COINS } from "../../../Constant";
import CountUp from "react-countup";

const GreetingCard = () => {
  const dispatch = useDispatch();
  const WelcomeToNaukrify = "Welcome to Naukrify";
  const WelcomeMessage = "Here whats happing in your account today  ";
  const WhatsNew = "What's new";
  const navigate = useNavigate();
  const { totalPoints, triggerTotalPoints } = useContext(AuthContext);
  // Retrieve data from the Redux store using useSelector hook
  const dashboardReducerdData = useSelector((state) => state.dashboardReducer);

  if (
    !dashboardReducerdData.isLoading &&
    dashboardReducerdData.data &&
    dashboardReducerdData.is_daily_visit
  ) {
    // console.log("dashboardReducerData", dashboardReducerdData);

    if (dashboardReducerdData.is_daily_visit) {
      triggerTotalPoints("daily_visit", true);
      dispatch(resetDailyVisit());
    }
  }

  const handlePassbookClick = () => {
    navigate("/recruiter/go_to_passbook");
  };

  const handleReferClick = () => {
    navigate(`${process.env.PUBLIC_URL}/recruiter/referral`);
  };

  return (
    <Card className="profile-box">
      <CardBody>
        <Media>
          <Media body>
            <div className="greeting-user">
              <h4 className="f-w-600">
                {totalPoints ? "Congratulations" : "Refer & Earn Rewards"}
              </h4>
              <p className="text-mute">
                {totalPoints
                  ? `You have earned rewards`
                  : "Refer your friends and earn rewards"}
              </p>
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <h3
                  attrH4={{ className: "mb-3 mt-3 f-w-500 mb-0 f-22" }}
                  style={{ margin: 0, display: "inline" }}
                >
                  <CountUp
                    duration={5}
                    start={0}
                    separator=","
                    end={totalPoints ? totalPoints : "0"}
                  />
                </h3>
                <span
                  className="f-light f-14 f-w-400 ms-1 text-capitalize"
                  style={{
                    color: "white",
                    textTransform: "capitalize",
                    marginLeft: "4px",
                  }}
                >
                  {COINS}
                </span>
              </div>

              <div className="whatsnew-btn" style={{ marginTop: "1.5em" }}>
                <button
                  onClick={handleReferClick}
                  className="btn btn-outline-white"
                  style={{ color: "", outline: "true" }}
                >
                  Refer & Earn
                </button>
              </div>
            </div>
          </Media>
          {/* <div>
              <div className="clockbox">
                <svg
                  id="clock"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 600 600"
                >
                  <g id="face">
                    <circle className="circle" cx={300} cy={300} r="253.9" />
                    <path
                      className="hour-marks"
                      d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"
                    />
                    <circle className="mid-circle" cx={300} cy={300} r="16.2" />
                  </g>
                  <g id="hour">
                    <path className="hour-hand" d="M300.5 298V142" />
                    <circle
                      className="sizing-box"
                      cx={300}
                      cy={300}
                      r="253.9"
                    />
                  </g>
                  <g id="minute">
                    <path className="minute-hand" d="M300.5 298V67" />
                    <circle
                      className="sizing-box"
                      cx={300}
                      cy={300}
                      r="253.9"
                    />
                  </g>
                  <g id="second">
                    <path className="second-hand" d="M300.5 350V55" />
                    <circle
                      className="sizing-box"
                      cx={300}
                      cy={300}
                      r="253.9"
                    ></circle>
                  </g>
                </svg>
              </div>
              <div className="badge f-10 p-0" id="txt"></div>
            </div> */}
        </Media>
        <div className="cartoon">
          <img
            src={trophy}
            alt="vector women with leptop"
            style={{ width: "60%" }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default GreetingCard;
