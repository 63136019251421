import {
  JOIN_GROUP_REQUEST,
  JOIN_GROUP_SUCCESS,
  JOIN_GROUP_FAILURE,
  JOIN_GROUP_RESET,
} from "../actionTypes";

export const joinGroupRequest = (data) => ({
  type: JOIN_GROUP_REQUEST,
  payload: data,
});

export const joinGroupSuccess = (message) => ({
  type: JOIN_GROUP_SUCCESS,
  payload: message,
});

export const joinGroupFailure = (error) => ({
  type: JOIN_GROUP_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const joinGroupReset = () => ({
  type: JOIN_GROUP_RESET,
});
