import {
  DOWNLOAD_CANDIDATES,
  FETCH_MANAGE_CANDIDATES_REQUEST,
  FETCH_MANAGE_CANDIDATES_SUCCESS,
  FETCH_MANAGE_CANDIDATES_FAILURE,
  DELETE_CANDIDATE_REQUEST,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAILURE,
  RESET_DATA,
} from "../../actionTypes";

export const fetchManageCandidates = (
  page,
  per_page,
  data,
  column,
  direction
) => {
  return {
    type: FETCH_MANAGE_CANDIDATES_REQUEST,
    payload: { page, per_page, data, column, direction },
  };
};
export const fetchManageCandidatesSuccess = (jobs) => {
  return {
    type: FETCH_MANAGE_CANDIDATES_SUCCESS,
    payload: jobs,
  };
};

export const fetchManageCandidatesFailure = (error) => {
  return {
    type: FETCH_MANAGE_CANDIDATES_FAILURE,
    payload: error,
  };
};

export const downloadCandidates = (data) => {
  return {
    type: DOWNLOAD_CANDIDATES,
    payload: data,
  };
};

export const deleteCandidateRequest = (data) => ({
  type: DELETE_CANDIDATE_REQUEST,
  payload: data,
});

export const deleteCandidateSuccess = (success) => ({
  type: DELETE_CANDIDATE_SUCCESS,
  payload: success,
});

export const deleteCandidateFailure = (error) => ({
  type: DELETE_CANDIDATE_FAILURE,
  payload: error,
});
export const resetCandidate = () => ({
  type: RESET_DATA,
});
