import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { USER_TYPES } from "../../Constant";
import {
  forgetPasswordRequest,
  resetForgetPassword,
} from "../../redux/auth/forgot_password/actions";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Components/Common/Toast";

const ForgetPwd = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const isValidLoginType = USER_TYPES.find(
    (loginType) => loginType === type.toUpperCase()
  );

  useEffect(() => {
    if (!isValidLoginType) {
      navigate("/404");
    }
  }, []);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear email error when input changes
  };
  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setEmailError("Email address is required");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
      return false;
    }

    return true;
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();

    if (validateEmail()) {
      const formattedData = {
        email: email || "",
        type: isValidLoginType || "",
      };

      dispatch(forgetPasswordRequest(formattedData));
    }
  };

  let forgotPasswordReducerData = useSelector(
    (state) => state.forgotPasswordReducer
  );

  useEffect(() => {
    if (
      forgotPasswordReducerData.status &&
      forgotPasswordReducerData.message !== ""
    ) {
      showToast("success", forgotPasswordReducerData.message);
      dispatch(resetForgetPassword());
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/${type}/auth/login/`);
      }, 1000); // Adjust the delay time as needed
    } else if (
      !forgotPasswordReducerData.status &&
      forgotPasswordReducerData.message !== ""
    ) {
      showToast("error", forgotPasswordReducerData.message);
      dispatch(resetForgetPassword());
    }
  }, [forgotPasswordReducerData, dispatch, navigate, type]);

  return (
    <Fragment>
      <Container fluid={true} className="p-0">
        <div className="login-card">
          <div>
            <div className="login-main">
              <Form
                className="theme-form login-form"
                onSubmit={handleSubmitClick}
              >
                <h4>Reset Your Password</h4>
                <FormGroup>
                  <Label className="col-form-label m-0">
                    Email<span className="text-danger">*</span>
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={email}
                    onChange={handleInputChange}
                    placeholder="Test@gmail.com"
                  />
                  {emailError && (
                    <small className="text-danger">{emailError}</small>
                  )}
                </FormGroup>
                <FormGroup className="text-end">
                  <Button
                    className="btn-block "
                    color="primary"
                    type="submit"
                    disabled={forgotPasswordReducerData.isLoading}
                  >
                    Submit
                  </Button>
                </FormGroup>

                <p className="text-start">
                  Already have an password?
                  <Link
                    className="ms-2"
                    to={`${process.env.PUBLIC_URL}/auth/login`}
                  >
                    Sign in
                  </Link>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </Container>{" "}
      <ToastContainer />
    </Fragment>
  );
};

export default ForgetPwd;
