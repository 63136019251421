import * as types from "../../actionTypes";

export const addEducationRequest = (data) => ({
  type: types.ADD_EDUCATION_BACKGROUND_REQUEST,
  payload: data,
});

export const addEducationSuccess = (data) => ({
  type: types.ADD_EDUCATION_BACKGROUND_SUCCESS,
  payload: data,
});

export const addEducationFailure = (error) => ({
  type: types.ADD_EDUCATION_BACKGROUND_FAILURE,
  payload: error,
});

export const deleteEducationBackgroundRequest = (data) => ({
  type: types.DELETE_EDUCATION_BACKGROUND_REQUEST,
  payload: data,
});

export const deleteEducationBackgroundSuccess = (data) => ({
  type: types.DELETE_EDUCATION_BACKGROUND_SUCCESS,
  payload: data,
});

export const deleteEducationBackgroundFailure = (error) => ({
  type: types.DELETE_EDUCATION_BACKGROUND_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA,
});
