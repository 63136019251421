import {
  CHECK_CHAT_EXIST_REQUEST,
  CHECK_CHAT_EXIST_SUCCESS,
  CHECK_CHAT_EXIST_FAILURE,
  FETCH_CHAT_MESSAGES_REQUEST,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES_FAILURE,
  SEND_CHAT_MESSAGE_REQUEST,
  SEND_CHAT_MESSAGE_SUCCESS,
  SEND_CHAT_INVITE_SUCCESS,
  SEND_CHAT_MESSAGE_FAILURE,
  FETCH_CHAT_MEMBERS_REQUEST,
  FETCH_CHAT_MEMBERS_SUCCESS,
  FETCH_CHAT_MEMBERS_FAILURE,
  RECEIVE_CHAT_MESSAGE,
  RESET_CHAT_STATE,
} from "../../../actionTypes";

export const checkChatExistRequest = (data) => ({
  type: CHECK_CHAT_EXIST_REQUEST,
  payload: data,
});

export const checkChatExistSuccess = (data) => ({
  type: CHECK_CHAT_EXIST_SUCCESS,
  payload: data,
});

export const checkChatExistFailure = (error) => ({
  type: CHECK_CHAT_EXIST_FAILURE,
  payload: error,
});

export const fetchChatMessagesRequest = (data) => ({
  type: FETCH_CHAT_MESSAGES_REQUEST,
  payload: data,
});

export const fetchChatMessagesSuccess = (messages) => ({
  type: FETCH_CHAT_MESSAGES_SUCCESS,
  payload: messages,
});

export const fetchChatMessagesFailure = (error) => ({
  type: FETCH_CHAT_MESSAGES_FAILURE,
  payload: error,
});

export const sendChatMessageRequest = (messageData) => ({
  type: SEND_CHAT_MESSAGE_REQUEST,
  payload: messageData,
});

export const sendChatMessageSuccess = (message) => ({
  type: SEND_CHAT_MESSAGE_SUCCESS,
  payload: message,
});

export const sendChatInviteSuccess = (message) => ({
  type: SEND_CHAT_INVITE_SUCCESS,
  payload: message,
});

export const sendChatMessageFailure = (error) => ({
  type: SEND_CHAT_MESSAGE_FAILURE,
  payload: error,
});

export const fetchChatMembersRequest = () => ({
  type: FETCH_CHAT_MEMBERS_REQUEST,
});

export const fetchChatMembersSuccess = (members) => ({
  type: FETCH_CHAT_MEMBERS_SUCCESS,
  payload: members,
});

export const fetchChatMembersFailure = (error) => ({
  type: FETCH_CHAT_MEMBERS_FAILURE,
  payload: error,
});

export const resetChatState = (arrayName) => ({
  type: RESET_CHAT_STATE,
  arrayName,
});
