import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import { addSkillsSuccess, addSkillsFailure } from "./actions";

function* addSkills(action) {
  console.log("ad", action);
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/add_jobseeker_skills", action.payload)
    );
    console.log("sr", response);
    if (response.data.status) {
      yield put(addSkillsSuccess(response.data));
    } else {
      yield put(addSkillsFailure(response.data));
    }
  } catch (error) {
    yield put(addSkillsFailure({ status: false, message: error.message }));
  }
}

function* skillsSaga() {
  yield takeLatest(types.ADD_SKILLS_REQUEST, addSkills);
}

export default skillsSaga;
