import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Table, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { AVATAR } from "../../../Constant";
import {
  capitalize,
  formatDate,
  generateSlug,
} from "../../Common/CommonFunction";
const NewApplicants = () => {
  // Retrieve data from the Redux store using useSelector hook
  const dashboardReducerdData = useSelector((state) => state.dashboardReducer);

  let newApplicantsData = [];
  // Check if dashboard data is available and not loading
  if (
    dashboardReducerdData &&
    !dashboardReducerdData.isLoading &&
    dashboardReducerdData.data &&
    dashboardReducerdData.data.data &&
    dashboardReducerdData.data.data.new_applicants
  ) {
    newApplicantsData = dashboardReducerdData.data.data.new_applicants.slice(
      0,
      5
    ); // Only first 5 records
  }

  return (
    <div className="appointment">
      <Card>
        <CardHeader className="card-no-border">
          <div className="header-top">
            <h5 attrH5={{ className: "m-0" }}>New Applicants</h5>
            {newApplicantsData.length >= 1 && (
              <div className="card-header-right-icon">
                <Link
                  to={`${process.env.PUBLIC_URL}/recruiter/manage_candidate`}
                >
                  View All
                </Link>
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody className="pt-0">
          <div className="appointment-table">
            {newApplicantsData.length > 0 ? (
              <Table borderless responsive>
                <tbody>
                  {newApplicantsData.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item.jobseeker_photo ? (
                          <img
                            className="img-fluid img-40 rounded-circle"
                            src={item.jobseeker_photo}
                            alt="user"
                          />
                        ) : (
                          <img
                            className="img-fluid img-40 rounded-circle"
                            src={AVATAR} // Path to your default image
                            alt="user"
                          />
                        )}
                      </td>
                      <td
                        className="img-content-box"
                        style={{ textAlign: "start" }}
                      >
                        <Link
                          className="d-block f-w-400"
                          to={`${
                            process.env.PUBLIC_URL
                          }/recruiter/job_candidate_detail/view/${generateSlug(
                            item.jobseeker_full_name,
                            item.jobseeker_location,
                            item.seeker_id
                          )}/${generateSlug(
                            item.jobseeker_full_name,
                            item.job_name,
                            item.id
                          )}`}
                          title={"View Candidate"}
                        >
                          {item.jobseeker_full_name
                            ? capitalize(item.jobseeker_full_name)
                            : " "}{" "}
                          {/* Use the jobseeker_full_name from data */}
                        </Link>
                        <span className="f-light">
                          {item.jobseeker_email1
                            ? item.jobseeker_email1.length > 18
                              ? item.jobseeker_email1.substring(0, 18) + "..."
                              : item.jobseeker_email1
                            : ""}
                        </span>{" "}
                        {/* Use the jobseeker_email from data */}
                      </td>
                      <td className="text-end">
                        {item.created_at
                          ? formatDate(item.created_at)
                          : "1 days ago"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default NewApplicants;
