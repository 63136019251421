import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Row,
} from "reactstrap";
import { Edit } from "react-feather";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { showToast } from "../../Components/Common/Toast";
import {
  updateRecruiterProfile,
  fetchRecruiterProfile,
  updateRecruiterAvatar,
  removeRecruiterAvatar,
  resetMessage,
} from "../../redux/recruiter/recruiter_profile/actions";
import { customOptionsStyle, roleOptions } from "../../Constant/index";
import Select from "react-select";
import { fetchIndustryRequest } from "../../redux/industryType/actions";
import { AVATAR } from "../../Constant/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import styles directly from react-phone-input-2
import validator from "validator";
import { readOnlyStyle } from "../../Constant/index";
const RecruiterProfilePage = () => {
  const dispatch = useDispatch();
  const [industryTypeOptions, setIndustryTypeOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [formData, setFormData] = useState({
    profile_image: "",
    email: "",
    role: null,
    full_name: "",
    country_code: "91",
    mobile_number: "",
    recruiter_linkedin_url: "",
  });
  const [errors, setErrors] = useState({});

  const recruiterProfileReducerData = useSelector(
    (state) => state.recruiterProfileReducer
  );

  useEffect(() => {
    // Fetch company profile on component mount
    dispatch(fetchRecruiterProfile());
  }, []);

  const recruiterProfile = recruiterProfileReducerData.data;

  useEffect(() => {
    // Populate form data with fetched company profile data
    if (recruiterProfile && recruiterProfileReducerData.isLoading != true) {
      setFormData({
        profile_image: recruiterProfile.profile_image || "",

        full_name: recruiterProfile.recruiter_full_name || "",
        country_code: recruiterProfile.country_code || "91",
        mobile_number: recruiterProfile.recruiter_mobile_number || "",
        email: recruiterProfile.recruiter_email || "",
        role:
          roleOptions.find((option) => option.value == recruiterProfile.role) ||
          null,

        recruiter_linkedin_url: recruiterProfile.social_link || "",
      });
    }
  }, [recruiterProfileReducerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear the error message when input changes
    const updatedErrors = { ...errors, [name]: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handler function to update the selected role
  const handleRoleChange = (selectedOption) => {
    setFormData({
      ...formData,
      role: selectedOption,
    });
    setErrors({ ...errors, role: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const updatedData = {
        recruiter_full_name: formData.full_name,
        country_code: formData.country_code,
        mobile_number: formData.mobile_number,
        email: formData.email,
        role: formData.role.value,
        social_link: formData.recruiter_linkedin_url,
      };
      dispatch(updateRecruiterProfile(updatedData));
      // dispatch(fetchCompanyProfile());
    }
  };
  console.log("errors", errors);
  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.full_name) {
      errors.full_name = "Full name is required";
      isValid = false;
    }

    const trimmedPhoneNumber = formData.mobile_number.trim();
    if (!trimmedPhoneNumber) {
      errors.mobile_number = "Phone number is required";
      isValid = false;
    } else if (
      formData.mobile_number === "91" &&
      trimmedPhoneNumber.length !== 10
    ) {
      errors.mobile_number = "Phone number must be 10 digits for india";
      isValid = false;
    } else if (isNaN(trimmedPhoneNumber)) {
      errors.mobile_number = "Phone number must be a valid number";
      isValid = false;
    } else if (
      !validator.isMobilePhone(trimmedPhoneNumber, "any", { strictMode: false })
    ) {
      errors.mobile_number = "Invalid phone number format";
      isValid = false;
    }

    // Validate Company Email
    if (!formData.email.trim()) {
      errors.email = "Recruiter email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    // Validate Functional Area
    if (!formData.role) {
      errors.role = "Role is required";
      isValid = false;
    } // Add additional validation if needed

    // Validate LinkedIn URL
    if (!formData.recruiter_linkedin_url.trim()) {
      errors.recruiter_linkedin_url = "LinkedIn URL is required";
      isValid = false;
    } else if (
      !/^(https?:\/\/)?(www\.)?linkedin.com\/.*/i.test(
        formData.recruiter_linkedin_url
      )
    ) {
      errors.recruiter_linkedin_url = "Invalid LinkedIn URL format";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    // Dispatch action to update avatar immediately when file is selected
    var file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_image", file);

      dispatch(updateRecruiterAvatar(formData));
    }
  };

  /**
   * industry type dropdown code start
   */

  //read industry type data from store
  let industryTypeReducerData = useSelector(
    (state) => state.industryTypeReducer
  );

  let industryTypeData = industryTypeReducerData.data.data;

  useEffect(() => {
    industryTypeData = industryTypeData || [];
    setIndustryTypeOptions(industryTypeData);
  }, [industryTypeData]);

  /**
   * industy type dropdown code end
   */

  useEffect(() => {
    // Check if company profile data status is received
    if (
      recruiterProfileReducerData.message != "" &&
      recruiterProfileReducerData.status == true
    ) {
      // Show success toast message if status is true
      showToast("success", recruiterProfileReducerData.message);
      dispatch(fetchRecruiterProfile());
      // Reset message after displaying it
      dispatch(resetMessage());
      // Fetch company profile again to get updated data
      // dispatch(fetchCompanyProfile());
    } else if (
      recruiterProfileReducerData.message != "" &&
      recruiterProfileReducerData.status == false
    ) {
      showToast("error", recruiterProfileReducerData.message);
      // Reset message after displaying it
      dispatch(resetMessage());
      // Fetch company profile again to get updated data
      // dispatch(fetchCompanyProfile());
    }
  }, [recruiterProfileReducerData]);

  const handleRemoveImage = () => {
    dispatch(removeRecruiterAvatar());
    setSelectedFile(null);
  };

  const handleCodeChange = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code: country.dialCode,
    });
  };

  return (
    <Fragment>
      <Breadcrumbs title="Edit Profile" mainTitle="Edit Profile" />
      <Container fluid={true}>
        <Form onSubmit={handleSubmit} className="theme-form">
          <Card>
            <CardBody>
              <div className="profile_img_container d-flex justify-content-center position-relative">
                <div className="position-relative d-flex align-items-center">
                  {selectedFile ? (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Company Image"
                      className="rounded-circle profile_img"
                      width="100px"
                      height="100px"
                      style={{ objectFit: "contain" }}
                    />
                  ) : formData.profile_image != "" ? (
                    <img
                      src={formData.profile_image}
                      alt="Company Image"
                      className="rounded-circle profile_img"
                      width="100px"
                      height="100px"
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <img
                      src={AVATAR}
                      alt="Company Image"
                      className="rounded-circle profile_img"
                      width="100px"
                      height="100px"
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <div className="icon_overlay text-center">
                    <Label htmlFor="fileInput">
                      <Edit className="edit-icon text-white" size={18} />
                    </Label>
                    <Input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  {(selectedFile || formData.profile_image !== "") && (
                    <Button
                      color="danger"
                      onClick={handleRemoveImage}
                      className="ms-3"
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "calc(100% + 20px)",
                        height: "30px",
                        padding: "0px 10px",
                        fontSize: "12px",
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </div>
              <div className="text-center mb-3 mt-2">
                <Label>Profile Image</Label>
              </div>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Your Name<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter your full name"
                      id="full_name"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleChange}
                    />
                    {errors.full_name && (
                      <small className="text-danger">{errors.full_name}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Your Role
                      <span className="danger" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Select
                      options={roleOptions}
                      value={formData.role}
                      onChange={handleRoleChange}
                      styles={customOptionsStyle}
                      placeholder="Select your role..."
                    />
                    {errors.role && (
                      <small className="text-danger">{errors.role}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Your Email<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="Enter your email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      readOnly // Set the readOnly attribute
                      style={readOnlyStyle}
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Your Phone Number <span className="text-danger">*</span>
                    </Label>
                    <div className="d-flex">
                      <PhoneInput
                        style={{
                          width: "10px",
                          marginRight: "10px",
                        }}
                        inputProps={{
                          name: "phone",
                          autoFocus: true,
                          disabled: true,
                          // placeholder: "Enter country code",
                        }}
                        country={"in"} // Default country
                        value={formData.country_code}
                        onChange={handleCodeChange}
                        inputStyle={{
                          width: "100px", // Adjust width to fit country code
                          height: "38px",
                          //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                          paddingLeft: "3rem",
                          fontSize: "1rem",
                          lineHeight: "1.5",
                          color: "#495057",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          transition:
                            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                        }}
                        enableAreaCodes={false}
                      />
                      <Input
                        className="form-control form-control-sm"
                        placeholder="Enter phone number"
                        id="mobile_number"
                        name="mobile_number"
                        value={formData.mobile_number}
                        onChange={handleChange}
                        style={{ marginLeft: "95px" }} // Add margin to separate the fields
                        maxLength={15} // Adjust the maximum length as per your requirement
                      />
                    </div>

                    {errors.mobile_number && (
                      <small className="text-danger">
                        {errors.mobile_number}
                      </small>
                    )}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>
                      LinkedIn URL<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control form-control-sm"
                      placeholder="LinkedIn URL"
                      id="recruiter_linkedin_url"
                      name="recruiter_linkedin_url"
                      value={formData.recruiter_linkedin_url}
                      onChange={handleChange}
                    />
                    {errors.recruiter_linkedin_url && (
                      <small className="text-danger">
                        {errors.recruiter_linkedin_url}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Button
                color="primary"
                type="submit"
                disabled={recruiterProfileReducerData.isLoading}
              >
                Update
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </Fragment>
  );
};

export default RecruiterProfilePage;
