// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { joinGroupSuccess, joinGroupFailure } from "./actions";
import { JOIN_GROUP_REQUEST } from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";

function* joinGroup(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/join_group", action.payload)
    );
    if (response.data.status == true) {
      yield put(joinGroupSuccess(response.data));
    } else {
      yield put(joinGroupFailure(response.data));
    }
  } catch (error) {
    yield put(joinGroupFailure({ status: false, message: error.message }));
  }
}

function* joinGroupSaga() {
  yield takeLatest(JOIN_GROUP_REQUEST, joinGroup);
}

export default joinGroupSaga;
