import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchManageJobsSuccess,
  fetchManageJobsFailure,
  deleteJobSuccess,
  deleteJobFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {
  FETCH_MANAGE_JOBS_REQUEST,
  DELETE_JOB_REQUEST,
} from "../../actionTypes";

function* fetchManageJobsSaga(action) {
  console.log("demo", action.payload);
  let url = `/recruiter/manage_job?page=${action.payload.page}&per_page=${action.payload.per_page}`;

  if (action.payload.column) {
    url += `&sortBy=${action.payload.column}`;
  }

  if (action.payload.direction) {
    url += `&direction=${action.payload.direction}`;
  }

  try {
    const response = yield call(() =>
      AxiosInstance().post(url, action.payload.data)
    );
    if (response.data.status) {
      yield put(fetchManageJobsSuccess(response.data));
    } else {
      yield put(fetchManageJobsFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchManageJobsFailure({ status: false, message: error.message })
    );
  }
}

// delete job saga function

function* deleteJob(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post(`/recruiter/delete_job`, action.payload)
    );
    console.log("delres", response);

    yield put(deleteJobSuccess(response.data));
  } catch (error) {
    yield put(deleteJobFailure({ status: false, message: error.message }));
  }
}

function* manageJobsSaga() {
  yield takeEvery(FETCH_MANAGE_JOBS_REQUEST, fetchManageJobsSaga);
  yield takeLatest(DELETE_JOB_REQUEST, deleteJob);
}

export default manageJobsSaga;
