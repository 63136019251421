import React from "react";
import "./Template3.css"; // Assuming you have a CSS file for styling
import github from "../../../../assets/images/resumeIcons/githubwhite.png";
import linkedin from "../../../../assets/images/resumeIcons/linkedinwhite.png";
import emailwhite from "../../../../assets/images/resumeIcons/emailwhite.png";
import callwhite from "../../../../assets/images/resumeIcons/callwhite.png";
import linkedinwhite from "../../../../assets/images/resumeIcons/linkedinwhite.png";
import { educationLevelsOptions } from "../../../../Constant";
import { FaStar } from "react-icons/fa";
import {
  capitalize,
  capitalizeFirstLetter,
  formatDate,
} from "../../../Common/CommonFunction";

const Template3 = ({ jobseekerData = {} }) => {
  const {
    jobseeker_full_name = "",
    jobseeker_date_of_birth = "",
    jobseeker_gender = "",
    jobseeker_marital_status = "",
    jobseeker_preferred_job_title = "",
    jobseeker_email1 = "",
    jobseeker_phone1 = "",
    jobseeker_email2 = "",
    jobseeker_phone2 = "",
    jobseeker_location = {},
    jobseeker_photo = "",
    resume_headline = "",
    work_experience = [],
    work_sample = [],
    education = [],
    it_skills = [],
    skill = [],
    online_detail_result = [],
  } = jobseekerData;

  const renderSocialIcon = (socialProfileName) => {
    switch (socialProfileName.toUpperCase()) {
      case "LINKEDIN":
        return <img src={linkedin} alt="LinkedIn Icon" />;
      case "GITHUB":
        return <img src={github} alt="GitHub Icon" />;
      default:
        return null;
    }
  };

  const extractDisplayableValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return value.label || value.value || "";
    }
    return value || "";
  };

  return (
    <main className="resume3" id="resume3">
      <div className="container">
        <div className="sidebar">
          <div className="profile_img_container">
            <img src={jobseeker_photo} alt="Profile" />
          </div>
          <div className="sidebar-info mt-35">
            <div className="contact mb-20">
              <div className="section-title"></div>
              <div className="contact-container mt-15">
                <table>
                  <tbody>
                    <tr className="email">
                      <td>
                        <img src={emailwhite} alt="Email" />
                      </td>
                      <td>
                        <a
                          href={`mailto:${extractDisplayableValue(
                            jobseeker_email1
                          )}`}
                        >
                          {extractDisplayableValue(jobseeker_email1)}
                        </a>
                      </td>
                    </tr>
                    <tr className="mobile">
                      <td>
                        <img src={callwhite} alt="Phone" />
                      </td>
                      <td>
                        <a
                          href={`tel:${extractDisplayableValue(
                            jobseeker_phone1
                          )}`}
                        >
                          {extractDisplayableValue(jobseeker_phone1)}
                        </a>
                      </td>
                    </tr>
                    <tr className="address">
                      <td>
                        <img src={linkedinwhite} alt="Address" />
                      </td>
                      <td>{extractDisplayableValue(jobseeker_location)}</td>
                    </tr>

                    {online_detail_result.length > 0 &&
                      online_detail_result.map(
                        (profile, index) =>
                          (profile.social_profile_name.toUpperCase() ===
                            "LINKEDIN" ||
                            profile.social_profile_name.toUpperCase() ===
                              "GITHUB") && (
                            <tr
                              key={index}
                              className={profile.social_profile_name.toLowerCase()}
                            >
                              <td>
                                {renderSocialIcon(profile.social_profile_name)}
                              </td>
                              <td>
                                <a href={profile.url}>{profile.url}</a>
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="education mb-20">
              <div className="section-title">EDUCATION</div>
              <div className="list-container mt-15">
                {education.map((edu, index) => (
                  <p key={index} className="education-item">
                    <strong>
                      {
                        educationLevelsOptions.find(
                          (option) => option.value === edu.education_level
                        )?.label
                      }{" "}
                      - {edu.passing_year}
                    </strong>
                    <br />
                    <span className="year">{edu.board}</span>
                    <br />
                    <span className="year">Percentage: {edu.performance}%</span>
                  </p>
                ))}
              </div>
            </div>

            <div className="expertise mb-20">
              <div className="section-title"> Skills</div>
              <div className="list-container mt-15">
                <ul className="list">
                  {skill.map((skillItem, index) => (
                    <li key={index} className="list-item">
                      {extractDisplayableValue(skillItem.label)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="language mb-20">
              <div className="section-title">LANGUAGES</div>
              <div className="list-container mt-15">
                <ul className="list">
                  <li className="list-item">Hindi</li>
                  <li className="list-item">English</li>
                  <li className="list-item">Tamil</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          <div className="section">
            <div className="name-container">
              <div className="name-prf">
                <div className="full-name">
                  <span className="fname">
                    {extractDisplayableValue(
                      capitalize(jobseeker_full_name.toUpperCase())
                    )}
                  </span>
                </div>
                <div className="resume-title">
                  {extractDisplayableValue(
                    capitalizeFirstLetter(jobseeker_preferred_job_title)
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="section-title mb-10">Experience</div>
            <div className="experience-container">
              <ul className="experience-list">
                {work_experience.map((work, index) => (
                  <li key={index}>
                    <div className="section-content experience-item">
                      <h4>
                        {capitalize(work.designation)}@{work.organization.label}
                      </h4>
                      <p>
                        {formatDate(work.started_working_from)} -{" "}
                        {formatDate(work.worked_till)}
                      </p>
                      <p>{capitalize(work.job_description)}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="section">
            <div className="section-title mb-10">Work Sample</div>
            <div className="reference-container">
              {work_sample.map((work, index) => (
                <div key={index} className="section-content">
                  <h4>
                    {capitalize(work.work_title)} ({formatDate(work.work_start)}{" "}
                    to {formatDate(work.work_end)})
                  </h4>
                  <p className="mb-1">
                    <a href={work.work_url}>{work.work_url}</a>
                  </p>
                  <p className="mb-1">{capitalize(work.work_description)}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="section">
            <div className="section-title mb-10">Main Skills</div>
            <div className="main-skill-container">
              <div className="section-content">
                {it_skills
                  .filter((skill) => skill.skill_rating > 0)
                  .map((skill, index) => (
                    <div key={skill.id} className="col-md-6 mb-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="font-weight-bold w-50">
                          {skill.it_skill.label}
                        </span>

                        <div className="d-flex flex-column w-30">
                          <div className="d-flex">
                            {[...Array(5)].map((star, i) => {
                              const ratingValue = Math.ceil(
                                skill.skill_rating / 2
                              ); // Convert rating to 5-star scale and round up
                              return (
                                <FaStar
                                  key={i}
                                  color={
                                    i < ratingValue ? "#ffc107" : "#e4e5e9"
                                  }
                                  size={12}
                                  style={{ marginRight: "2px" }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Template3;
