import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_CANDIDATE_TECHNICAL_SKILLS_REQUEST } from "../../actionTypes";

import {
  updateCandidateTechnicalSkillsSuccess,
  updateCandidateTechnicalSkillsError,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updateCandidateTechnicalSkills(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/technical_skills", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(updateCandidateTechnicalSkillsSuccess(response.data));
    } else {
      yield put(updateCandidateTechnicalSkillsError(response.data));
    }
  } catch (error) {
    yield put(
      updateCandidateTechnicalSkillsError({
        status: false,
        message: error.message,
      })
    );
  }
}

// Watcher saga
function* updateCandidateTechnicalSkillsSaga() {
  yield takeLatest(
    UPDATE_CANDIDATE_TECHNICAL_SKILLS_REQUEST,
    updateCandidateTechnicalSkills
  );
}
export default updateCandidateTechnicalSkillsSaga;
