import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Form,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { submitFeedbackRequest } from "../../redux/jobseeker/job_feedback/actions";
import { showToast } from "../../Components/Common/Toast";
import { useParams } from "react-router-dom";
import { resetFeedbackMessage } from "../../redux/jobseeker/job_feedback/actions";
import { useNavigate } from "react-router-dom";
import { extractAfterLastHyphen } from "../../Components/Common/CommonFunction";
import { AuthContext } from "../../Auth/AuthContext";
function JobFeedbackPage() {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [errors, setErrors] = useState({});
  const { triggerTotalPoints } = useContext(AuthContext);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (feedback.trim() === "") {
      newErrors.feedback = "Please enter some feedback.";
      valid = false;
    }

    if (rating === 0) {
      newErrors.rating = "Please select a rating.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    dispatch(
      submitFeedbackRequest({
        job_id: extractAfterLastHyphen(jobId),
        feedback,
        rating,
      })
    );
    // showToast("success", "Submitting your feedback...");

    //  setFeedback("");
    // setRating(0);
  };

  const jobFeedbackReducerData = useSelector(
    (state) => state.jobFeedbackReducer
  );

  useEffect(() => {
    if (
      jobFeedbackReducerData.status == true &&
      jobFeedbackReducerData.message != ""
    ) {
      showToast("success", jobFeedbackReducerData.message);
      // Dispatch action to reset the message after showing the toast
      if (jobFeedbackReducerData.is_job_feedback) {
        triggerTotalPoints("job_feedback", true, false);
      }
      navigate(`${process.env.PUBLIC_URL}/jobseeker/job_details/${jobId}`);

      setTimeout(() => {
        dispatch(resetFeedbackMessage());
      }, 5000);
    } else if (
      jobFeedbackReducerData.status == false &&
      jobFeedbackReducerData.message != ""
    ) {
      showToast("error", jobFeedbackReducerData.message);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetFeedbackMessage());
    }
  }, [jobFeedbackReducerData]);

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, feedback: null }));
  };

  const handleRating = (rate) => {
    setRating(rate);
    setErrors((prevErrors) => ({ ...prevErrors, rating: null }));
  };

  return (
    <Fragment>
      <Breadcrumbs title="Job Feedback" mainTitle="Job Feedback" />

      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} className="theme-form">
                  <FormGroup>
                    <h5>Job Feedback</h5>
                    <br />
                    <Label for="feedbackText">Feedback</Label>
                    <textarea
                      className="form-control mt-2"
                      id="feedbackText"
                      placeholder="Write your feedback..."
                      value={feedback}
                      onChange={handleFeedbackChange}
                      rows={5}
                      style={{ outline: "none" }}
                    />
                    {errors.feedback && (
                      <small className="text-danger">{errors.feedback}</small>
                    )}

                    <div style={{ marginTop: "20px" }}>
                      <Label>Rating</Label>
                      <div className="stars">
                        {[...Array(5)].map((_, index) => {
                          const starValue = index + 1;
                          return (
                            <span
                              key={starValue}
                              onClick={() => handleRating(starValue)}
                              style={{
                                cursor: "pointer",
                                color: starValue <= rating ? "orange" : "gray",
                                fontSize: "30px",
                              }}
                            >
                              &#9733;
                            </span>
                          );
                        })}
                      </div>
                      {errors.rating && (
                        <small className="text-danger">{errors.rating}</small>
                      )}
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <Button
                        color="primary"
                        type="submit"
                        style={{ float: "right" }}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default JobFeedbackPage;
