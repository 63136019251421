// actions.js
import * as types from '../actionTypes';


export const fetchFunctionalAreaRequest = (keyword) => ({
    type: types.FETCH_FUNCTIONAL_AREA_REQUEST,
    payload: { keyword },
  });
  
  export const fetchFunctionalAreaSuccess = (data) => ({
    type: types.FETCH_FUNCTIONAL_AREA_SUCCESS,
    payload: { data },
  });
  
  export const fetchFunctionalAreaFailure = (error) => ({
    type: types.FETCH_FUNCTIONAL_AREA_FAILURE,
    payload: { error },
  });