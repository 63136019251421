import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import BannerCard from "../../Components/Recruiter/LinkedinBanner/BannerCard";
import { getLinkedInBannerRequest } from "../../redux/get_linkedin_banner/actions";
import Spinner from "../../Components/Common/Spinner";
import NoDataFound from "../../Components/Common/NoDataFound";

const AiLinkedinBannerRecruiter = () => {
  const dispatch = useDispatch();
  const getLinkedinBannerReducerData = useSelector(
    (state) => state.getLinkedinBannerReducer
  );
  const { data: bannerData, isLoading } = getLinkedinBannerReducerData;

  const [activeTab, setActiveTab] = useState("ALL");

  useEffect(() => {
    dispatch(getLinkedInBannerRequest({ type: activeTab }));
  }, [dispatch, activeTab]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const filteredBanners =
    bannerData?.filter((template) => {
      switch (activeTab) {
        case "ALL":
          return template.type === "FREE" || template.type === "PREMIUM";
        case "ATTITUDE":
          return template.category === "ATTITUDE";
        case "MOTIVATION":
          return template.category === "MOTIVATION";
        case "HIRING":
          return template.category === "HIRING";
        case "CUSTOMIZABLE":
          return template.category === "CUSTOMIZABLE";
        default:
          return false;
      }
    }) || [];

  return (
    <Fragment>
      <Breadcrumbs title="AI Linkedin Banner" mainTitle="AI Linkedin Banner" />
      <Container fluid>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <Nav className="border-tab nav-primary nav nav-tabs" tabs>
                {[
                  "ALL",
                  "ATTITUDE",
                  "MOTIVATION",
                  "HIRING",
                  "CUSTOMIZABLE",
                ].map((tab) => (
                  <NavItem key={tab}>
                    <NavLink
                      className={activeTab === tab ? "active" : ""}
                      onClick={() => toggleTab(tab)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icofont icofont-file"></i>
                      {tab.charAt(0) + tab.slice(1).toLowerCase()}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>
            {isLoading ? (
              <Spinner color="primary" />
            ) : bannerData && bannerData.length > 0 ? (
              <TabContent activeTab={activeTab}>
                <TabPane tabId={activeTab}>
                  <Row>
                    {filteredBanners.map((template, index) => (
                      <BannerCard
                        key={template.banner_id}
                        index={index}
                        template={template}
                      />
                    ))}
                  </Row>
                </TabPane>
              </TabContent>
            ) : (
              <NoDataFound />
            )}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AiLinkedinBannerRecruiter;
