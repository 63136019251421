import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import JobDetails from "../../Components/Jobseeker/JobDetail/JobDetails";

const JobDetailPage = () => {
  return (
    <Fragment>
      <Breadcrumbs title="Job Details" mainTitle="Job Details" />
      <Container fluid={true}>
        <JobDetails />
      </Container>
    </Fragment>
  );
};

export default JobDetailPage;
