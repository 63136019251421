import * as types from "../../actionTypes";

const initialState = {
  data: {},
  isLoading: false,
  message: "",
  status: "",
};

const jobseekerProfileReducer = (state = initialState, action) => {
  console.log("prd", action);
  switch (action.type) {
    case types.UPDATE_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.UPDATE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case types.UPDATE_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    case types.RESET_DATA:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: false,
        message: "",
      };
    default:
      return state;
  }
};

export default jobseekerProfileReducer;
