import {
  CANDIDATE_BULK_UPLOAD_REQUEST,
  CANDIDATE_BULK_UPLOAD_SUCCESS,
  CANDIDATE_BULK_UPLOAD_FAILURE,
  CANDIDATE_BULK_UPLOAD_RESET,
} from "../../actionTypes";

export const bulkUploadRequest = (type) => ({
  type: CANDIDATE_BULK_UPLOAD_REQUEST,
  payload: type,
});

export const bulkUploadSuccess = (data) => ({
  type: CANDIDATE_BULK_UPLOAD_SUCCESS,
  payload: data,
});

export const bulkUploadFailure = (error) => ({
  type: CANDIDATE_BULK_UPLOAD_FAILURE,
  payload: error,
});

export const bulkUploadReset = () => ({
  type: CANDIDATE_BULK_UPLOAD_RESET,
});
