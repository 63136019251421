import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Badge,
  Row,
  Col,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdCopy } from "react-icons/io";
import { RiMailSendLine } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";

import { MdOutlinePreview } from "react-icons/md";
import {
  handleCopyToClipboard,
  handleSendEmail,
  handlePhoneCall,
  capitalize,
  formatExperienceOutput,
  formatDate,
  formatExperienceYearAndMonth,
} from "../../Common/CommonFunction";
import {
  jobTypeOptions,
  employmentTypeOptions,
  shiftTypeOptions,
} from "../../../Constant";

import { AVATAR } from "../../../Constant";

const MyProfileEdit = ({ profileData }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const emptyField = (str) => {
    if (str === null || "") {
      return "-";
    } else {
      return str;
    }
  };

  return (
    <>
      <Card style={{ fontSize: 15 }}>
        <CardHeader className="py-3">
          <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
            Basic Info
          </h4>
        </CardHeader>
        <CardBody className="">
          <div className="profile_img_container d-flex justify-content-between  position-relative ">
            <div className="d-flex align-items-center">
              {selectedFile ? (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Company logo"
                  className="rounded-circle profile_img "
                  width="80px"
                  height="80px"
                  style={{ objectFit: "cover" }}
                />
              ) : profileData.jobseeker_photo != "" ? (
                <img
                  src={profileData.jobseeker_photo}
                  alt="Company logo"
                  className="rounded-circle profile_img"
                  width="80px"
                  height="80px"
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <img
                  src={AVATAR}
                  alt="Company logo"
                  className="rounded-circle profile_img"
                  width="80px"
                  height="80px"
                  style={{ objectFit: "cover" }}
                />
              )}

              <div className="media-body" style={{ marginLeft: 14 }}>
                <a
                // to={`${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`}
                >
                  <h4 attrH5={{ className: "mb-1" }}>
                    {capitalize(emptyField(profileData.jobseeker_full_name))}
                  </h4>
                </a>
                {/* <p>DESIGNER</p> */}
              </div>
            </div>
          </div>

          <div className="">
            {profileData.jobseeker_email1 && (
              <Row className="mt-3" style={{ lineHeight: "inherit" }}>
                <Col xs="5" className="fw-bold p-0 ps-3">
                  Email
                  <span style={{ float: "right" }}> : </span>
                </Col>

                <Col xs="7">
                  <div>
                    <a
                      href={`mailto:${emptyField(
                        profileData.jobseeker_email1.trim()
                      )}`}
                      style={{ marginRight: 5 }}
                    >
                      {emptyField(profileData.jobseeker_email1.trim()).length >
                      17
                        ? emptyField(
                            profileData.jobseeker_email1.trim()
                          ).substring(0, 17) + "..."
                        : emptyField(profileData.jobseeker_email1.trim())}
                    </a>
                    <RiMailSendLine
                      className="email-icon "
                      title="Email"
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleSendEmail(profileData.jobseeker_email1)
                      }
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_email1)
                      }
                      title="Copy"
                    />{" "}
                  </div>
                </Col>
              </Row>
            )}

            {profileData.jobseeker_email2 && (
              <Row className="mt-2" style={{ lineHeight: "inherit" }}>
                <Col xs="5" className="fw-bold p-0 ps-3">
                  Email2
                  <span style={{ float: "right" }}> : </span>
                </Col>

                <Col xs="7">
                  <div>
                    <a
                      href={`mailto:${emptyField(
                        profileData.jobseeker_email2.trim()
                      )}`}
                      style={{ marginRight: 5 }}
                    >
                      {/* {emptyField(email.trim())} */}
                      {emptyField(profileData.jobseeker_email2.trim()).length >
                      17
                        ? emptyField(
                            profileData.jobseeker_email2.trim()
                          ).substring(0, 17) + "..."
                        : emptyField(profileData.jobseeker_email2.trim())}
                    </a>
                    <RiMailSendLine
                      className="email-icon"
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleSendEmail(profileData.jobseeker_email2)
                      }
                      title="Mail"
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_email2)
                      }
                      title="Copy"
                    />
                    {/* Icon for copying email */}
                  </div>
                </Col>
              </Row>
            )}
            {profileData.jobseeker_phone1 && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Phone <span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  <div>
                    <span style={{ marginRight: 5 }}>
                      {emptyField(profileData.jobseeker_phone1.trim())}
                    </span>
                    <IoCallOutline
                      className="phone-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handlePhoneCall(profileData.jobseeker_phone1)
                      }
                      title="Call"
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_phone1)
                      }
                      title="Copy"
                    />
                  </div>
                </Col>
              </Row>
            )}
            {profileData.jobseeker_phone2 && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Phone2 <span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  <div>
                    <span style={{ marginRight: 5 }}>
                      {emptyField(profileData.jobseeker_phone2.trim())}
                    </span>
                    <IoCallOutline
                      className="phone-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handlePhoneCall(profileData.jobseeker_phone2)
                      }
                      title="Call"
                    />{" "}
                    <IoMdCopy
                      className="copy-icon "
                      size={22}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handleCopyToClipboard(profileData.jobseeker_phone2)
                      }
                      title="Copy"
                    />
                  </div>
                </Col>
              </Row>
            )}
            {profileData.jobseeker_date_of_birth && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  DOB<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {formatDate(profileData.jobseeker_date_of_birth)}
                </Col>
              </Row>
            )}
            {profileData.jobseeker_gender && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Gender<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">{capitalize(profileData.jobseeker_gender)}</Col>
              </Row>
            )}
            {profileData.jobseeker_marital_status && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Marital Status<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {capitalize(profileData.jobseeker_marital_status)}
                </Col>
              </Row>
            )}
            {profileData.jobseeker_differently_abled && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Differently Abled<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {capitalize(profileData.jobseeker_differently_abled)}
                </Col>
              </Row>
            )}

            {profileData.jobseeker_permanent_address && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Permanent Address<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {capitalize(profileData.jobseeker_permanent_address)}
                </Col>
              </Row>
            )}
            {profileData.jobseeker_hometown && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Home Town<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">{capitalize(profileData.jobseeker_hometown)}</Col>
              </Row>
            )}
            {profileData.jobseeker_pincode && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Pin Code<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">{profileData.jobseeker_pincode}</Col>
              </Row>
            )}
            {profileData.total_exp_years >= 0 ||
              (profileData.total_exp_months >= 0 && (
                <Row className="mt-2">
                  <Col
                    xs="5"
                    className=" p-0 ps-3 fw-bold"
                    style={{ lineHeight: "inherit" }}
                  >
                    Total Experience<span style={{ float: "right" }}> : </span>
                  </Col>
                  <Col xs="7">
                    {formatExperienceYearAndMonth(
                      parseInt(profileData.total_exp_years),
                      parseInt(profileData.total_exp_months)
                    )}
                  </Col>
                </Row>
              ))}
            {profileData.jobseeker_location && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Current Location<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {capitalize(profileData.jobseeker_location.label)}
                </Col>
              </Row>
            )}
            {profileData.jobseeker_preferred_location &&
              profileData.jobseeker_preferred_location.length > 0 && (
                <Row className="mt-2">
                  <Col
                    xs="5"
                    className="p-0 ps-3 fw-bold"
                    style={{ lineHeight: "inherit" }}
                  >
                    Preferred Location
                    <span style={{ float: "right" }}> : </span>
                  </Col>
                  <Col xs="7">
                    {profileData.jobseeker_preferred_location
                      .map((location) => capitalize(location.label))
                      .join(", ")}
                  </Col>
                </Row>
              )}

            {profileData.jobseeker_notice_period && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className="p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Notice period<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {`${profileData.jobseeker_notice_period} Days`}
                </Col>
              </Row>
            )}
            {profileData.jobseeker_work_permit_for_other_countries &&
              profileData.jobseeker_work_permit_for_other_countries.length >
                0 && (
                <Row className="mt-2">
                  <Col
                    xs="5"
                    className=" p-0 ps-3 fw-bold"
                    style={{ lineHeight: "inherit" }}
                  >
                    Foreign Work Permit
                    <span style={{ float: "right" }}> : </span>
                  </Col>
                  <Col xs="7">
                    {profileData.jobseeker_work_permit_for_other_countries
                      .map((country) => capitalize(country.label))
                      .join(", ")}
                  </Col>
                </Row>
              )}
          </div>

          <hr />

          <div className="d-flex align-items-center justify-content-between mt-4">
            <h5 className="mb-0 me-2">Desired Career Profile</h5>
          </div>
          <div className="mt-3 ps-2">
            {profileData.jobseeker_preferred_job_title && (
              <Row className="mt-4">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Job Title<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {capitalize(profileData.jobseeker_preferred_job_title)}
                </Col>
              </Row>
            )}
            {profileData.jobseeker_preferred_industry &&
              profileData.jobseeker_preferred_industry.label && (
                <Row className="mt-2">
                  <Col
                    xs="5"
                    className=" p-0 ps-3 fw-bold"
                    style={{ lineHeight: "inherit" }}
                  >
                    Desired Industry<span style={{ float: "right" }}> : </span>
                  </Col>
                  <Col xs="7">
                    {capitalize(profileData.jobseeker_preferred_industry.label)}
                  </Col>
                </Row>
              )}
            {profileData.jobseeker_preferred_industry &&
              profileData.jobseeker_preferred_department.label && (
                <Row className="mt-2">
                  <Col
                    xs="5"
                    className=" p-0 ps-3 fw-bold"
                    style={{ lineHeight: "inherit" }}
                  >
                    Functional Area<span style={{ float: "right" }}> : </span>
                  </Col>
                  <Col xs="7">
                    {capitalize(
                      profileData.jobseeker_preferred_department.label
                    )}
                  </Col>
                </Row>
              )}
            {profileData.jobseeker_preferred_job_type && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Job Preferences<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {
                    jobTypeOptions.find(
                      (option) =>
                        option.value == profileData.jobseeker_preferred_job_type
                    )?.label
                  }
                </Col>
              </Row>
            )}
            {profileData.jobseeker_preferred_employment_type && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className=" p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Employment Type<span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {
                    employmentTypeOptions.find(
                      (option) =>
                        option.value ==
                        profileData.jobseeker_preferred_employment_type
                    )?.label
                  }
                </Col>
              </Row>
            )}

            {profileData.jobseeker_preferred_shift && (
              <Row className="mt-2">
                <Col
                  xs="5"
                  className="p-0 ps-3 fw-bold"
                  style={{ lineHeight: "inherit" }}
                >
                  Shift Preference
                  <span style={{ float: "right" }}> : </span>
                </Col>
                <Col xs="7">
                  {profileData.jobseeker_preferred_shift
                    .split(",")
                    .map(
                      (shift) =>
                        shiftTypeOptions.find(
                          (option) => option.value === shift
                        )?.label
                    )
                    .join(", ")}
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default MyProfileEdit;
