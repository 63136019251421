import React, { Fragment, useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Card,
  Badge,
} from "reactstrap";
import Spinner from "../../Common/Spinner";
import {
  fetchJobseekerDataRequest,
  resetData,
} from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  updateResumeAvatar,
  deleteResumeRequest,
  resetResumeData,
} from "../../../redux/jobseeker/update_resume/actions";
import { showSweetAlert } from "../../Common/SweetAlert";
import { deleteSocialMediaRequest } from "../../../redux/jobseeker/social_media/actions";
import { useSelector, useDispatch } from "react-redux";
import Worksamplemodal from "./Worksamplemodal";
import Desiredcareerprofile from "./Desiredcareerprofile";
import Resumeheadline from "./Resumeheadline";
import Workexperiencemodal from "./Workexperiencemodal";
import EducationModal from "./EducationModal";
import TechnicalskillsModal from "./TechnicalskillsModal";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { socialMediaOptions } from "../../../Constant";
import Editsocialmedia from "./Editsocialmedia";
import { FaFacebookSquare } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { FaStackOverflow } from "react-icons/fa";
import AddskillModal from "./AddskillModal";
import { showToast } from "../../Common/Toast";
import { MdOutlinePreview } from "react-icons/md";
import { useParams } from "react-router";
import { RiTwitterXFill } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";
import PreviewResumeModal from "./PreviewResumeModal";
import {
  downloadFileFromUrl,
  sanitizedFileName,
} from "../../Common/CommonFunction";

const EditMyProfile = ({ profileData, validationMessages }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const { cand_id, jobcad_id } = useParams();

  const [showWorkSample, setWorkSample] = useState(false);
  const [showDesiredCareer, setShowDesiredCareer] = useState(false);
  const [showEditResumeHeadline, setResumeHeadline] = useState(false);
  const [showWorkExperience, setWorkExperience] = useState(false);
  const [showPreviewResume, setShowPreviewResume] = useState(false);

  const [showTechnicalSkills, setTechnicalSkills] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [updateWorkSample, setUpdateWorkSample] = useState({
    id: null,
    seeker_id: null,
    work_title: "",
    work_start: "",
    work_end: "",
    work_url: "",
    work_description: "",
  });
  const [updateWorkExperience, setUpdateWorkExperience] = useState({
    id: null,
    seeker_id: null,
    designation: "",
    started_working_from: "",
    worked_till: "",
    job_description: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onEditSubmit = (data) => {
    alert(data);
  };

  const toggleWorkSampleModal = () => {
    setWorkSample(false);
  };

  const toggleDesiredCarrer = () => {
    setShowDesiredCareer(false);
  };

  const toggleResumeHeadline = () => {
    setResumeHeadline(false);
  };

  const toggleTechnicalSkillsModal = () => {
    setTechnicalSkills(false);
  };

  const toggleWorkExperienceModal = () => {
    setWorkExperience(false);
  };

  const editResumeHeadline = () => {
    setResumeHeadline(true);
  };

  const [editTechnicalSkill, setEditTechnicalSkill] = useState({
    id: null,
    seeker_id: null,
    it_skill: "",
    skill_version: "",
    skill_last_used: "",
    skill_experienced: "",
    skill_rating: "",
  });

  const [socialMedia, setsocialMedia] = useState({
    id: null,
    seeker_id: null,
    social_profile_name: "",
    url: "",
  });

  const toggleSocialMedia = () => {
    setShowSocialMedia(!showSocialMedia);
  };

  const addSocialMedia = () => {
    if (profileData && profileData.online_detail_result) {
      const filteredOptions = socialMediaOptions.filter((option) => {
        return !profileData.online_detail_result.some(
          (result) => result.social_profile_name === option.value
        );
      });
      console.log("fo", filteredOptions);
      setShowSocialMedia(true);
      setsocialMedia({
        id: null,
        seeker_id: null,
        social_profile_name: "",
        url: "",
        options: filteredOptions,
      });
    }
  };

  const editSocialMedia = (data) => {
    console.log(data);
    setShowSocialMedia(true);
    setsocialMedia({
      id: data.id,
      seeker_id: data.seeker_id,
      social_profile_name: data.social_profile_name,
      url: data.url,
      options: null,
    });
  };

  const addSkillModal = (data) => {
    setShowSkillModal(true);
  };

  const toggleAddSkillModal = () => {
    setShowSkillModal(false);
  };
  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      // message.textContent = 'Error: File size exceeds 2MB limit.';
      showToast("error", "Error: File size exceeds 2MB limit !");
      return;
    } else {
      setIsUploading(true); // Start uploading state
      setSelectedFile(file);

      setTimeout(() => {
        // Simulate successful upload
        updateResumeAction(file);
        setIsUploading(false);
        // Update profileData.is_uploaded_resume or any necessary state after upload
      }, 2000); // Simulating 2 seconds delay for upload
    }
  };

  // Dispatch action to update Resume
  const updateResumeAction = (file) => {
    console.log(file);
    if (file) {
      const formData = new FormData();
      formData.append("jobseeker_resume_file", file);
      console.log(formData);
      dispatch(updateResumeAvatar(formData));
      // dispatch(resetData());
      // setTimeout(() => {
      //   dispatch(fetchJobseekerDataRequest());
      // }, 1100);
    }
  };
  const handleResumeDeleteClick = (job_id) => {
    console.log("ji", job_id);
    showSweetAlert("confirm", {
      message: "You want to delete the job",
      fun: deleteResume,
      id: job_id,
    });
  };

  const deleteResume = () => {
    dispatch(deleteResumeRequest({ id: profileData.id })); // Replace 'profileData.resumeId' with the actual ID of the resume
  };

  const handleSocialMediaDeleteClick = (job_id) => {
    console.log("ji", job_id);
    showSweetAlert("confirm", {
      message: "You want to delete the job",
      fun: handleSocialMedia,
      id: job_id,
    });
  };

  const handleSocialMedia = (did) => {
    // Dispatch the delete action when delete button is clicked
    dispatch(deleteSocialMediaRequest(did)); // Adjust the payload as needed
  };

  const socialReducerData = useSelector((state) => state.socialMediaReducer);

  useEffect(() => {
    console.log("srd", socialReducerData);
    if (socialReducerData.status && socialReducerData.message != "") {
      showToast("success", socialReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      //props.toggleModal();
      dispatch(resetData());
    } else if (
      socialReducerData.status == false &&
      socialReducerData.message != ""
    ) {
      showToast("error", socialReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      // props.toggleModal();
      dispatch(resetData());
    }
  }, [socialReducerData]);

  const updateReducerData = useSelector((state) => state.updateReducer);

  useEffect(() => {
    console.log("srd", updateReducerData);
    if (updateReducerData.status && updateReducerData.message != "") {
      showToast("success", updateReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      //props.toggleModal();
      dispatch(resetResumeData());
    } else if (
      updateReducerData.status == false &&
      updateReducerData.message != ""
    ) {
      showToast("error", updateReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      // props.toggleModal();
      dispatch(resetResumeData());
    }
  }, [updateReducerData]);

  const togglePreviewResumeModal = () => {
    setShowPreviewResume(false);
  };

  const handlePreviewClick = () => {
    if (profileData.is_uploaded_resume) {
      setShowPreviewResume(true);
    }
  };

  const handleResumeDownloadClick = () => {
    const url = profileData.jobseeker_resume;
    const candidateName = profileData.jobseeker_full_name;

    const fileName = sanitizedFileName(candidateName);

    downloadFileFromUrl(url, fileName);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
            More Details
          </h4>
        </CardHeader>
        <CardBody className="">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 me-2">Resume Headline</h5>
            {/* <FiEdit
              size={15}
              className="text-primary profile_icons"
              onClick={editResumeHeadline}
            /> */}
            <div className="d-flex">
              {profileData.resume_headline ? (
                <FiEdit
                  className="edit-icon"
                  size={22}
                  onClick={editResumeHeadline}
                  title="Edit"
                />
              ) : (
                <FiPlusSquare
                  className="add-icon "
                  size={22}
                  onClick={editResumeHeadline}
                  title="Edit"
                />
              )}
            </div>
          </div>
          <div className="mt-3 ps-2">
            <p>{profileData.resume_headline}</p>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 me-2">Social Media</h5>

            <FiPlusSquare
              className="add-icon "
              size={22}
              onClick={addSocialMedia}
              title="Add"
            />
          </div>
          <div className="mt-3 ps-2">
            {profileData.online_detail_result &&
              profileData.online_detail_result.map((item) => {
                if (item.social_profile_name === "LINKEDIN") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <div className="d-flex">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            // color: "inherit",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaLinkedin className="me-2" size={20} />
                          <p>
                            {
                              socialMediaOptions.find(
                                (option) =>
                                  option.value === item.social_profile_name
                              )?.label
                            }
                          </p>
                        </a>
                      </div>
                      {/* <FiEdit
                        size={15}
                        className="text-primary profile_icons"
                        onClick={() => {
                          editSocialMedia({
                            id: item.id,
                            seeker_id: item.seeker_id,
                            social_profile_name: item.social_profile_name,
                            url: item.url,
                          });
                        }}
                      /> */}

                      <div className="d-flex mt-2">
                        <FiEdit
                          className="edit-icon  me-2"
                          size={22}
                          onClick={() => {
                            editSocialMedia({
                              id: item.id,
                              seeker_id: item.seeker_id,
                              social_profile_name: item.social_profile_name,
                              url: item.url,
                            });
                          }}
                          title="Edit"
                        />
                        <RiDeleteBinLine
                          className="delete-icon"
                          size={22}
                          onClick={() => {
                            handleSocialMediaDeleteClick({
                              id: item.id,
                              // seeker_id: item.seeker_id,
                            });
                          }}
                          title="Delete"
                        />
                      </div>
                    </div>
                  );
                }
                if (item.social_profile_name === "FACEBOOK") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <div className="d-flex">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            // color: "inherit",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaFacebookSquare className="me-2" size={20} />

                          <p>
                            {
                              socialMediaOptions.find(
                                (option) =>
                                  option.value === item.social_profile_name
                              )?.label
                            }
                          </p>
                        </a>
                      </div>

                      <div className="d-flex mt-2">
                        <FiEdit
                          className="edit-icon  me-2"
                          size={22}
                          onClick={() => {
                            editSocialMedia({
                              id: item.id,
                              seeker_id: item.seeker_id,
                              social_profile_name: item.social_profile_name,
                              url: item.url,
                            });
                          }}
                          title="Edit"
                        />
                        <RiDeleteBinLine
                          className="delete-icon "
                          size={22}
                          onClick={() => {
                            handleSocialMediaDeleteClick({
                              id: item.id,
                              seeker_id: item.seeker_id,
                            });
                          }}
                          title="Delete"
                        />
                      </div>
                    </div>
                  );
                }
                if (item.social_profile_name === "GITHUB") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <div className="d-flex">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            // color: "inherit",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaGithub className="me-2" size={20} />
                          <p>
                            {
                              socialMediaOptions.find(
                                (option) =>
                                  option.value === item.social_profile_name
                              )?.label
                            }
                          </p>
                        </a>
                      </div>
                      {/* <FiEdit
                        size={15}
                        className="text-primary profile_icons"
                        onClick={() => {
                          editSocialMedia({
                            id: item.id,
                            seeker_id: item.seeker_id,
                            social_profile_name: item.social_profile_name,
                            url: item.url,
                          });
                        }}
                      /> */}

                      <div className="d-flex mt-2">
                        <FiEdit
                          className="edit-icon text-primary me-2"
                          size={22}
                          onClick={() => {
                            editSocialMedia({
                              id: item.id,
                              seeker_id: item.seeker_id,
                              social_profile_name: item.social_profile_name,
                              url: item.url,
                            });
                          }}
                          title="Edit"
                        />
                        <RiDeleteBinLine
                          className="delete-icon "
                          size={22}
                          onClick={() => {
                            handleSocialMediaDeleteClick({
                              id: item.id,
                              seeker_id: item.seeker_id,
                            });
                          }}
                          title="Delete"
                        />
                      </div>
                    </div>
                  );
                }
                if (item.social_profile_name === "TWITTER") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <div className="d-flex">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <RiTwitterXFill className="me-2" size={20} />
                          <p>
                            {
                              socialMediaOptions.find(
                                (option) =>
                                  option.value === item.social_profile_name
                              )?.label
                            }
                          </p>
                        </a>
                      </div>

                      <div className="d-flex mt-2">
                        <FiEdit
                          className="edit-icon  me-2"
                          size={22}
                          onClick={() => {
                            editSocialMedia({
                              id: item.id,
                              seeker_id: item.seeker_id,
                              social_profile_name: item.social_profile_name,
                              url: item.url,
                            });
                          }}
                          title="Edit"
                        />
                        <RiDeleteBinLine
                          className="delete-icon "
                          size={22}
                          onClick={() => {
                            handleSocialMediaDeleteClick({
                              id: item.id,
                              seeker_id: item.seeker_id,
                            });
                          }}
                          title="Delete"
                        />
                      </div>
                    </div>
                  );
                }
                if (item.social_profile_name === "STACK_OVERFLOW") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <div className="d-flex">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* Assuming you have imported the Stack Overflow icon */}
                          <FaStackOverflow className="me-2" size={20} />
                          <p>
                            {
                              socialMediaOptions.find(
                                (option) =>
                                  option.value === item.social_profile_name
                              )?.label
                            }
                          </p>
                        </a>
                      </div>

                      <div className="d-flex mt-2">
                        <FiEdit
                          className="edit-icon  me-2"
                          size={22}
                          onClick={() => {
                            editSocialMedia({
                              id: item.id,
                              seeker_id: item.seeker_id,
                              social_profile_name: item.social_profile_name,
                              url: item.url,
                            });
                          }}
                          title="Edit"
                        />
                        <RiDeleteBinLine
                          className="delete-icon "
                          size={22}
                          onClick={() => {
                            handleSocialMediaDeleteClick({
                              id: item.id,
                              seeker_id: item.seeker_id,
                            });
                          }}
                          title="Delete"
                        />
                      </div>
                    </div>
                  );
                }

                return null;
              })}
          </div>

          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 me-2">Resume</h5>
            {profileData.is_uploaded_resume ? (
              <div className="d-flex ">
                <FiEdit
                  className="edit-icon "
                  size={22}
                  onClick={() => fileInputRef.current.click()}
                  title="Edit"
                />
              </div>
            ) : (
              <FiPlusSquare
                className="add-icon"
                size={22}
                onClick={() => fileInputRef.current.click()}
                title="Add"
              />
            )}
          </div>

          <div className="mt-3 ps-2">
            <input
              ref={fileInputRef} // Assign ref to file input
              type="file"
              name=""
              id="resume_change"
              onChange={handleResumeChange}
              hidden
              accept=".doc,.docx,.rtf,.pdf"
            />
            {isUploading && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "30px" }}
              >
                <Spinner />
              </div>
            )}
            {profileData.is_uploaded_resume && (
              <div className="d-flex ">
                <div className="vh-30 me-2">
                  <Button
                    color="primary"
                    className="d-flex align-items-center pe-3 ps-3"
                    onClick={handlePreviewClick} // Open resume in new tab/window
                  >
                    <MdOutlinePreview size={18} style={{ marginRight: 2 }} />{" "}
                    Preview
                  </Button>{" "}
                </div>
                <div className="vh-30">
                  <Button
                    color="primary"
                    className="d-flex align-items-center pe-3 ps-3"
                    onClick={handleResumeDownloadClick}
                  >
                    <FiDownload size={18} style={{ marginRight: 2 }} /> Download
                  </Button>
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>

      {showEditResumeHeadline && (
        <Resumeheadline
          isOpen={showEditResumeHeadline}
          toggleModal={toggleResumeHeadline}
          data={profileData}
        />
      )}
      {showSocialMedia && (
        <Editsocialmedia
          isOpen={showSocialMedia}
          toggleModal={toggleSocialMedia}
          data={socialMedia}
        />
      )}
      {showSkillModal && (
        <AddskillModal
          isOpen={showSkillModal}
          toggleModal={toggleAddSkillModal}
          data={profileData.skill}
        />
      )}
      {showPreviewResume && (
        <PreviewResumeModal
          isOpen={showPreviewResume}
          toggleModal={togglePreviewResumeModal}
          data={profileData}
        />
      )}
    </Fragment>
  );
};
export default EditMyProfile;
