// actions.js
import * as types from "../actionTypes";

export const fetchLocationsRequest = (keyword) => ({
  type: types.FETCH_LOCATIONS_REQUEST,
  payload: { keyword },
});

export const fetchLocationsSuccess = (data) => ({
  type: types.FETCH_LOCATIONS_SUCCESS,
  payload: { data },
});

export const fetchLocationsFailure = (error) => ({
  type: types.FETCH_LOCATIONS_FAILURE,
  payload: { error },
});
