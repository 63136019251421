import {
  JOB_SHARING_REQUEST,
  JOB_SHARING_SUCCESS,
  JOB_SHARING_FAILURE,
  RESET_MESSAGE,
} from "../actionTypes";

export const jobSharingRequest = () => ({
  type: JOB_SHARING_REQUEST,
});

export const jobSharingSuccess = (message) => ({
  type: JOB_SHARING_SUCCESS,
  payload: message,
});

export const jobSharingFailure = (error) => ({
  type: JOB_SHARING_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetJobSharingMessage = () => ({
  type: RESET_MESSAGE,
});
