import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST } from "../../actionTypes";

import {
  updateCandidatePrimarySkillsSuccess,
  updateCandidatePrimarySkillsFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updateCandidatePrimarySkills(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post(
        "/jobseeker/add_update_primary_skill",
        action.payload
      )
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(updateCandidatePrimarySkillsSuccess(response.data));
    } else {
      yield put(updateCandidatePrimarySkillsFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateCandidatePrimarySkillsFailure({
        status: false,
        message: error.message,
      })
    );
  }
}

// Watcher saga
function* updateCandidatePrimarySkillSaga() {
  yield takeLatest(
    UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST,
    updateCandidatePrimarySkills
  );
}
export default updateCandidatePrimarySkillSaga;
