import React, { Fragment, useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { Col, Row, Card, CardBody, Input, Button, Form } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import Filter from "./Filter";
import Location from "./Location";
import JobTitleClass from "./JobTitle";
import Industry from "./Industry";
import SkillClass from "./Skill";
import FunctionalArea from "./FunctionalArea";
import JobType from "./JobType";
import EmploymentType from "./EmploymentType";
import Freshness from "./Freshness";
import { experienceOptions } from "../../../../Constant";
import { fetchCandidateRequest } from "../../../../redux/recruiter/candidate_search/actions";
import { YourCandidateSearchContext } from "../../../../YourCandidateSearch/YourCandidateSearchContext";
import { fetchLocationsRequest } from "../../../../redux/location/actions";
import { customOptionsStyle } from "../../../../Constant";
import Ctc from "./Ctc";
import WorkExperience from "./WorkExperience";
import { useLocation } from "react-router-dom";
import Gender from "./Gender";

const Sidebar = () => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [isOpen, setIsOpen] = useState(false);
  const { filters, clearFilters, updateFilters, pagePerpage } = useContext(
    YourCandidateSearchContext
  );
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    keyword: "",
    experience: null,
    locations: [],
  });
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  console.log("lastSegment", lastSegment);

  let searchType;

  switch (lastSegment) {
    case "candidate_search":
      searchType = "CANDIDATE";
      break;
    case "your_candidate_search":
      searchType = "JOBCANDIDATE";
      break;
    default:
      searchType = "";
      break;
  }

  // Synchronize formData with filters context
  useEffect(() => {
    setFormData({
      keyword: filters.keyword || "",
      experience: filters.experience || null,
      locations: filters.locations || [],
    });
  }, [filters]);

  /**
   * location dropdown code start
   */
  //read location data from store
  const locationsReducerData = useSelector((state) => state.locationReducer);
  let locationData = [];
  if (!locationsReducerData.isLoading && locationsReducerData.data.data) {
    locationData = locationsReducerData.data.data;
  }

  const handleLocationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleLocationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Callback to handle location select change
  const handleLocationsChange = (selectedOptions) => {
    // Update the form state with selected values
    setErrors((prevErrors) => ({ ...prevErrors, locations: "" }));
    setFormData({ ...formData, locations: selectedOptions });
  };

  /**
   * location dropdown code end
   */

  useEffect(() => {
    // formData.locations.map((loc) => loc.value).join(",")

    const page = pagePerpage.page;
    const per_page = pagePerpage.per_page;
    const reqData = {
      keyword: filters.keyword,
      gender: filters.gender,
      freshness: filters.freshness, // Set the default freshness to 30 days
      locations: filters.locations.map((loc) => loc.value).join(","),

      industry_type: filters.industry_type.map((loc) => loc.value).join(","),

      functional_area: filters.functional_area
        .map((loc) => loc.value)
        .join(","),
      skills: filters.skills.map((loc) => loc.value).join(","),
      minctc: filters.minctc,
      maxctc: filters.maxctc,
      // min_experience: filters.min_experience,
      // max_experience: filters.max_experience,
      experience: (filters.experience && filters.experience.value) || "",
      type: searchType,
    };

    dispatch(fetchCandidateRequest(page, per_page, reqData));
    console.log("reqdata", reqData);
  }, [filters, pagePerpage]);

  // console.log("loc", filters.locations);
  //  console.log("fil", filters);

  const handleExperienceChange = (selectedOption) => {
    // console.log("exp", selectedOption);
    setErrors((prevErrors) => ({ ...prevErrors, experience: "" }));
    setFormData({ ...formData, experience: selectedOption });
  };

  const handleKeywordChange = (e) => {
    setErrors((prevErrors) => ({ ...prevErrors, keyword: "" }));
    setFormData({ ...formData, keyword: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updateFilters(formData);
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // if (formData.keyword.trim() === "") {
    //   errors.keyword = "Keyword is required";
    //   isValid = false;
    // }
    // if (!formData.experience) {
    //   errors.experience = "Experience is required";
    //   isValid = false;
    // }

    // if (formData.locations.length === 0) {
    //   errors.locations = "At least one location is required";
    //   isValid = false;
    // }
    setErrors(errors);
    return isValid;
  };

  const handleClearFilters = () => {
    setFormData({ ...formData, keyword: "", experience: null, locations: [] });
    clearFilters();
  };
  console.log("errors", errors);

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardBody className="pb-3">
            <Form className="theme-form" onSubmit={handleSubmit}>
              <div className="row  justify-content-between">
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                  <Input
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Search keywords..."
                    value={formData.keyword}
                    onChange={handleKeywordChange}
                  />
                  {errors.keyword && (
                    <small className="text-danger">{errors.keyword}</small>
                  )}
                </div>

                <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
                  <Select
                    isMulti
                    options={locationData}
                    isLoading={locationsReducerData.isLoading}
                    placeholder="Select locations..."
                    value={formData.locations}
                    onChange={handleLocationsChange}
                    onInputChange={handleLocationInputChange}
                    styles={customOptionsStyle}
                    onFocus={handleLocationFocus}
                  />
                  {errors.locations && (
                    <small className="text-danger">{errors.locations}</small>
                  )}
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
                  <Select
                    options={experienceOptions}
                    value={formData.experience}
                    onChange={handleExperienceChange}
                    isSearchable
                    placeholder="Experience"
                  />
                  {errors.experience && (
                    <small className="text-danger">{errors.experience}</small>
                  )}
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 mb-3">
                  <Button
                    color="primary"
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>

      <Col xl="3" className="box-col-4 xl-30">
        <div className="md-sidebar">
          <Button
            onClick={(e) => setIsOpen(!isOpen)}
            color="primary"
            className="md-sidebar-toggle"
          >
            Job filter
          </Button>
          <div
            className={`md-sidebar-aside job-left-aside custom-scrollbar ${
              isOpen && "open"
            } `}
          >
            <div className="" id="accordionoc">
              <Row>
                <Filter
                  onClearAll={handleClearFilters} // Pass a function to handle clearing filters
                />
                <Freshness />
                <Location />
                <FunctionalArea />
                <SkillClass />
                <Industry />
                <Ctc />
                <Gender />
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default Sidebar;
