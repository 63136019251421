// reducer.js
import {
  CANDIDATE_BULK_UPLOAD_REQUEST,
  CANDIDATE_BULK_UPLOAD_SUCCESS,
  CANDIDATE_BULK_UPLOAD_FAILURE,
  CANDIDATE_BULK_UPLOAD_RESET,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  status: false,

  message: "",
};

const candidateBulkUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANDIDATE_BULK_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CANDIDATE_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case CANDIDATE_BULK_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case CANDIDATE_BULK_UPLOAD_RESET:
      return {
        ...state,
        isLoading: false,
        status: false,
        message: "",
      };
    default:
      return state;
  }
};

export default candidateBulkUploadReducer;
