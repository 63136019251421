import {
  UPDATE_CANDIDATE_INFO_REQUEST,
  UPDATE_CANDIDATE_INFO_SUCCESS,
  UPDATE_CANDIDATE_INFO_FAILURE,
  RESET_CANDIDATE_INFO_MESSAGE,
} from "../../actionTypes";

// action creators
export const updateCandidateInfoRequest = (data) => ({
  type: UPDATE_CANDIDATE_INFO_REQUEST,
  payload: data,
});

export const updateCandidateInfoSuccess = (message) => ({
  type: UPDATE_CANDIDATE_INFO_SUCCESS,
  payload: message,
});

export const updateCandidateInfoFailure = (error) => ({
  type: UPDATE_CANDIDATE_INFO_FAILURE,
  payload: error,
});

export const resetCandidateInfoData = () => ({
  type: RESET_CANDIDATE_INFO_MESSAGE,
});
