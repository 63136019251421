import {
  UPDATE_CANDIDATE_INFO_REQUEST,
  UPDATE_CANDIDATE_INFO_SUCCESS,
  UPDATE_CANDIDATE_INFO_FAILURE,
  RESET_CANDIDATE_INFO_MESSAGE,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
  is_candidate_feedback: 0,
};

const updateCandidateInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CANDIDATE_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CANDIDATE_INFO_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
        is_candidate_feedback: action.payload.is_candidate_feedback,
      };
    case UPDATE_CANDIDATE_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_CANDIDATE_INFO_MESSAGE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
        is_candidate_feedback: 0,
      };
    default:
      return state;
  }
};

export default updateCandidateInfoReducer;
