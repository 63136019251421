// sagas.js

import { call, put, takeEvery } from "redux-saga/effects";
import { fetchCandidateSuccess, fetchCandidateFailure } from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { FETCH_CANDIDATE_REQUEST } from "../../actionTypes";

function* fetchCandidateSaga(action) {
  //console.log(action.payload);
  console.log("pagePerpage2", action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post(
        `/recruiter/search_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}`,
        action.payload.data
      )
    );
    if (response.data.status) {
      yield put(fetchCandidateSuccess(response.data));
    } else {
      yield put(fetchCandidateFailure(response.data));
    }
  } catch (error) {
    yield put(fetchCandidateFailure({ status: false, message: error.message }));
  }
}

function* candidateSearchSaga() {
  yield takeEvery(FETCH_CANDIDATE_REQUEST, fetchCandidateSaga);
}

export default candidateSearchSaga;
