import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { Card, Col, CardBody, Row, Button, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { Briefcase, MapPin, DollarSign, Clock } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import {
  timeAgo,
  capitalize,
  generateSlug,
  formatSalaryOutput,
  formatExperienceYearAndMonth,
  formatSalary,
} from "../../../Components/Common/CommonFunction";
import Spinner from "../../Common/Spinner";
import { AVATAR } from "../../../Constant";
import { LuClock3 } from "react-icons/lu";
import { MdCurrencyRupee } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import NoDataFound from "../../Common/NoDataFound";
import {
  checkChatExistRequest,
  resetChatState,
} from "../../../redux/recruiter/chat_message/chat/actions";
import ChatModal from "../Chat/ChatModal";
import { useNavigate } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import { PiSuitcaseSimple } from "react-icons/pi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { FiStar } from "react-icons/fi";
import { YourCandidateSearchContext } from "../../../YourCandidateSearch/YourCandidateSearchContext";
import Pagination from "../../Common/Pagination";
import {
  candidateSaveForLaterRequest,
  candidateSaveForLaterReset,
} from "../../../redux/recruiter/candidate_save_for_later/actions";
import { showToast } from "../../Common/Toast";
import { FaRegHeart } from "react-icons/fa";
import { LuMailPlus } from "react-icons/lu";
import ChatAppContext from "../../../Chat";
const ListCard = () => {
  const { filters, updatePagePerpage } = useContext(YourCandidateSearchContext);
  const { changeChat } = useContext(ChatAppContext) || {};
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Changed to useNavigate
  const [showChatModal, setShowChatModal] = useState(false);
  const [selectedSeekerId, setSelectedSeekerId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const handleMessageClick = (candidate) => {
    changeChat(candidate);
    setSelectedSeekerId(candidate.seeker_id); // Set the selected seeker ID
    setShowChatModal(true);
  };

  const candidateSearchReducerData = useSelector(
    (state) => state.candidateSearchReducer
  );

  const totalPages = candidateSearchReducerData
    ? Math.ceil(candidateSearchReducerData.data.total / perPage)
    : 0;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    updatePagePerpage({ page: currentPage, per_page: perPage });
  }, [currentPage, perPage]);

  let candidateSearchData = [];
  if (
    !candidateSearchReducerData.isLoading &&
    candidateSearchReducerData.data.data
  ) {
    candidateSearchData = candidateSearchReducerData.data.data;
  }
  const [showAllSkills, setShowAllSkills] = useState(false);

  // Function to toggle showAllSkills for a specific candidate
  const toggleShowAllSkills = (candidateId) => {
    setShowAllSkills((prevState) => ({
      ...prevState,
      [candidateId]: !prevState[candidateId],
    }));
  };

  const handleSaveForLaterClick = (seekerId) => {
    dispatch(candidateSaveForLaterRequest({ seeker_id: seekerId }));
    setSavedCandidates((prev) => ({ ...prev, [seekerId]: true }));
  };
  const candidateSaveForLaterReducerData = useSelector(
    (state) => state.candidateSaveForLaterReducer
  );
  useEffect(() => {
    console.log(
      "candidateSaveForLaterReducerData:",
      candidateSaveForLaterReducerData
    );
    if (
      candidateSaveForLaterReducerData.status === true &&
      candidateSaveForLaterReducerData.message !== ""
    ) {
      showToast("success", candidateSaveForLaterReducerData.message);

      //   navigate(`${process.env.PUBLIC_URL}/recruiter/saved_candidates`);

      dispatch(candidateSaveForLaterReset());
    } else if (
      candidateSaveForLaterReducerData.status === false &&
      candidateSaveForLaterReducerData.message !== ""
    ) {
      showToast("error", candidateSaveForLaterReducerData.message);
      dispatch(candidateSaveForLaterReset());
    }
  }, [candidateSaveForLaterReducerData]);

  const skillContainerRefs = useRef({});
  const [firstRowCount, setFirstRowCount] = useState({});
  const [showMoreButtons, setShowMoreButtons] = useState({});

  useEffect(() => {
    candidateSearchData.forEach((candidate) => {
      if (skillContainerRefs.current[candidate.seeker_id]) {
        const container = skillContainerRefs.current[candidate.seeker_id];
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;
        const badges = container.querySelectorAll(".resume-skill-badge");
        const showMoreButtonWidth = 100; // Adjust this value according to the actual width of the "Show more" button
        const badgeHeight = badges[0]?.offsetHeight || 0; // Assuming all badges have the same height
        const availableHeight = badgeHeight * 1; // Height for 3 rows
        const availableWidth = containerWidth - showMoreButtonWidth;

        let totalBadgesWidth = 0;
        let totalBadgesHeight = 0;
        let visibleBadgesCount = 0;

        badges.forEach((badge, index) => {
          const badgeWidth = badge.offsetWidth;
          totalBadgesWidth += badgeWidth;

          if (totalBadgesWidth > availableWidth) {
            totalBadgesWidth = badgeWidth; // Reset width for the new row
            totalBadgesHeight += badgeHeight; // Increment height for the new row
          }

          if (totalBadgesHeight + badgeHeight <= availableHeight) {
            visibleBadgesCount += 1;
          }
        });

        setFirstRowCount((prev) => ({
          ...prev,
          [candidate.seeker_id]: visibleBadgesCount,
        }));

        if (badges.length > visibleBadgesCount) {
          setShowMoreButtons((prev) => ({
            ...prev,
            [candidate.seeker_id]: true,
          }));
        }
      }
    });
  }, [candidateSearchData]);

  const toggleShowAllMainSkills = (candidateId) => {
    setShowAllMainSkills((prevState) => ({
      ...prevState,
      [candidateId]: !prevState[candidateId],
    }));
  };

  const mainSkillContainerRefs = useRef({});
  const [mainSkillCount, setMainSkillCount] = useState({});
  const [mainSkillShowMoreButtons, setMainSkillShowMoreButtons] = useState({});
  const [showAllMainSkills, setShowAllMainSkills] = useState({});

  useEffect(() => {
    candidateSearchData.forEach((candidate) => {
      if (mainSkillContainerRefs.current[candidate.seeker_id]) {
        const container = mainSkillContainerRefs.current[candidate.seeker_id];
        const containerWidth = container.offsetWidth;
        const badges = container.querySelectorAll(".main-skill-badge");
        const showMoreButtonWidth = 100; // Adjust this value according to the actual width of the "Show more" button
        const badgeHeight = badges[0]?.offsetHeight || 0; // Assuming all badges have the same height
        const availableHeight = badgeHeight * 3; // Height for 3 rows
        const availableWidth = containerWidth - showMoreButtonWidth;

        let totalBadgesWidth = 0;
        let totalBadgesHeight = 0;
        let visibleBadgesCount = 0;

        badges.forEach((badge, index) => {
          const badgeWidth = badge.offsetWidth;
          totalBadgesWidth += badgeWidth;

          if (totalBadgesWidth > availableWidth) {
            totalBadgesWidth = badgeWidth; // Reset width for the new row
            totalBadgesHeight += badgeHeight; // Increment height for the new row
          }

          if (totalBadgesHeight + badgeHeight <= availableHeight) {
            visibleBadgesCount += 1;
          }
        });

        setMainSkillCount((prev) => ({
          ...prev,
          [candidate.seeker_id]: visibleBadgesCount,
        }));

        if (badges.length > visibleBadgesCount) {
          setMainSkillShowMoreButtons((prev) => ({
            ...prev,
            [candidate.seeker_id]: true,
          }));
        } else {
          setMainSkillShowMoreButtons((prev) => ({
            ...prev,
            [candidate.seeker_id]: false,
          }));
        }
      }
    });
  }, [candidateSearchData]);

  const [savedCandidates, setSavedCandidates] = useState({});
  useEffect(() => {
    if (candidateSearchData.length > 0) {
      const initialSavedJobs = candidateSearchData.reduce((acc, candidate) => {
        acc[candidate.seeker_id] = candidate.is_saved;
        return acc;
      }, {});
      setSavedCandidates(initialSavedJobs);
    }
  }, [candidateSearchData]);

  return (
    <Fragment>
      <Col xl="9" className="box-col-8 xl-70">
        {candidateSearchReducerData.isLoading ? (
          <Spinner color="primary" />
        ) : !candidateSearchReducerData.isLoading &&
          candidateSearchData &&
          candidateSearchData.length > 0 ? (
          <>
            <Row className="mb-5">
              {candidateSearchData.map((candidate, index) => (
                <Col xl="12" className="xl-100 box-col-12" key={index}>
                  <Card style={{ height: "auto" }}>
                    <CardBody>
                      <div className="media">
                        <div className="media-body">
                          <h5 className="mb-0">
                            <Link
                              to={`${window.location.origin}${
                                process.env.PUBLIC_URL
                              }/recruiter/job_candidate_detail/view/${generateSlug(
                                candidate.jobseeker_full_name,
                                candidate.jobseeker_location,
                                candidate.seeker_id
                              )}`}
                            >
                              {capitalize(candidate.jobseeker_full_name)}
                            </Link>
                          </h5>
                          <span
                            className="me-2 d-flex align-items-center f-w-500"
                            style={{ minHeight: "1em" }}
                          >
                            {candidate.organization &&
                              candidate.designation &&
                              candidate.organization.label && (
                                <span className="me-1">
                                  {capitalize(candidate.designation)}
                                  &nbsp;at&nbsp;
                                  {candidate.organization.label}
                                </span>
                              )}
                            {candidate.average_rating && (
                              <div className="stars me-2 icon-container">
                                {[...Array(5)].map((_, i) => {
                                  const starValue = i + 1;
                                  const averageRating =
                                    candidate.average_rating ?? "";
                                  return (
                                    <span
                                      key={starValue}
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          starValue <= averageRating
                                            ? "orange"
                                            : "gray",
                                        fontSize: "calc(0.8em + 0.5vw)",
                                      }}
                                    >
                                      &#9733;
                                    </span>
                                  );
                                })}
                              </div>
                            )}
                          </span>
                          <p
                            className="d-flex align-items-center flex-wrap f-w-500"
                            style={{ color: "#121224", minHeight: "3em" }}
                          >
                            <span className="d-flex align-items-center">
                              <PiSuitcaseSimple
                                size={19}
                                className="me-1 suit-case-icon"
                              />
                              {candidate.total_exp_years !== null &&
                              candidate.total_exp_years !== undefined &&
                              candidate.total_exp_months !== null &&
                              candidate.total_exp_months !== undefined ? (
                                <div className="mb-0 d-flex align-items-center">
                                  <span>
                                    {formatExperienceYearAndMonth(
                                      candidate.total_exp_years,
                                      candidate.total_exp_months
                                    )}
                                  </span>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </span>
                            <span className="mx-2">|</span>
                            <span className="d-flex align-items-center">
                              <MdCurrencyRupee
                                size={18}
                                className="me-1 rupee-icon"
                              />
                              {candidate.jobseeker_preferred_salary
                                ? formatSalary(
                                    candidate.jobseeker_preferred_salary
                                  )
                                : "Not disclosed"}
                            </span>
                            <span className="mx-2">|</span>
                            <span className="d-flex align-items-center">
                              <GrLocation
                                size={19}
                                className="me-1 location-icon"
                              />
                              {candidate.jobseeker_preferred_location
                                ? candidate.jobseeker_preferred_location
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                        <img
                          src={candidate.jobseeker_photo || AVATAR}
                          alt="Badge Placeholder"
                          className="badge-image"
                          width={60}
                          height={60}
                        />
                      </div>

                      {candidate.resume_headline && (
                        <p
                          style={{
                            maxHeight: "54px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2, // Maximum number of lines to display
                            whiteSpace: "normal",
                            fontSize: "14px", // Set the font size to 15px
                            // color: "#999999", // Adjust the color as needed
                          }}
                          className="resume-headline mt-2 mb-0"
                        >
                          {candidate.resume_headline}
                        </p>
                      )}
                      {candidate.qualification &&
                        candidate.qualification.degree &&
                        candidate.qualification.college_or_school_name &&
                        candidate.qualification.passing_year && (
                          <div className="row mt-2">
                            <div
                              className="col-12 col-md-auto mb-2 mb-md-0 pe-0"
                              style={{ minWidth: 125 }}
                            >
                              <b className="f-w-500">Education</b>
                              <b style={{ float: "right" }}>:</b>
                            </div>
                            <div className="col-12 col-md">
                              <span>
                                {candidate.qualification.degree},{" "}
                                {candidate.qualification.college_or_school_name}
                                , {candidate.qualification.passing_year}
                              </span>
                            </div>
                          </div>
                        )}

                      {candidate.main_skills && candidate.primary_skill && (
                        <div className="row mt-2">
                          <div
                            className="col-12 col-md-auto mb-2 mb-md-0 pe-0"
                            style={{ minWidth: 125 }}
                          >
                            <b className="f-w-500">Main skills</b>
                            <b style={{ float: "right" }}>:</b>
                          </div>
                          <div className="col-12 col-md">
                            <div
                              className="d-flex flex-wrap"
                              ref={(el) =>
                                (mainSkillContainerRefs.current[
                                  candidate.seeker_id
                                ] = el)
                              }
                            >
                              {(showAllMainSkills[candidate.seeker_id]
                                ? [
                                    ...candidate.primary_skill
                                      .split(",")
                                      .map((skill) => skill.trim())
                                      .filter((skill) =>
                                        candidate.main_skills.includes(
                                          skill.trim()
                                        )
                                      ),
                                    ...candidate.main_skills
                                      .split(",")
                                      .map((skill) => skill.trim())
                                      .filter(
                                        (skill) =>
                                          !candidate.primary_skill.includes(
                                            skill.trim()
                                          )
                                      ),
                                  ]
                                : [
                                    ...candidate.primary_skill
                                      .split(",")
                                      .map((skill) => skill.trim())
                                      .filter((skill) =>
                                        candidate.main_skills.includes(
                                          skill.trim()
                                        )
                                      ),
                                    ...candidate.main_skills
                                      .split(",")
                                      .map((skill) => skill.trim())
                                      .filter(
                                        (skill) =>
                                          !candidate.primary_skill.includes(
                                            skill.trim()
                                          )
                                      ),
                                  ].slice(
                                    0,
                                    mainSkillCount[candidate.seeker_id]
                                  )
                              ).map((skill, index) => {
                                const isPrimary =
                                  candidate.primary_skill.includes(skill);
                                return (
                                  <Badge
                                    key={index}
                                    className={`me-2 mb-2 border rounded main-skill-badge ${
                                      isPrimary
                                        ? "fw-bolder text-primary"
                                        : "text-black"
                                    }`}
                                    color="gray"
                                    pill
                                  >
                                    {skill}
                                    {isPrimary && (
                                      <FiStar
                                        style={{
                                          marginLeft: "5px",
                                          color: "blue",
                                        }}
                                      />
                                    )}
                                  </Badge>
                                );
                              })}
                              {candidate.main_skills.split(",").length >
                                mainSkillCount[candidate.seeker_id] && (
                                <div className="d-flex align-items-center">
                                  <a
                                    className="show-more-button"
                                    onClick={() =>
                                      toggleShowAllMainSkills(
                                        candidate.seeker_id
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color: "blue",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {showAllMainSkills[candidate.seeker_id]
                                      ? "Show less"
                                      : "...Show more"}
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {candidate.resume_skill && (
                        <div className="row mt-2">
                          <div
                            className="col-12 col-md-auto mb-2 mb-md-0 pe-0"
                            style={{ minWidth: 125 }}
                          >
                            <b className="f-w-500">May also know</b>{" "}
                            <b style={{ float: "right" }}>:</b>
                          </div>
                          <div className="col-12 col-md">
                            <div
                              className="d-flex flex-wrap"
                              ref={(el) =>
                                (skillContainerRefs.current[
                                  candidate.seeker_id
                                ] = el)
                              }
                            >
                              {(showAllSkills[candidate.seeker_id]
                                ? candidate.resume_skill.split(",")
                                : candidate.resume_skill
                                    .split(",")
                                    .slice(
                                      0,
                                      firstRowCount[candidate.seeker_id]
                                    )
                              ).map((skill, index) => (
                                <Badge
                                  key={index}
                                  className="me-2 mb-2 border rounded resume-skill-badge text-black"
                                  color="gray"
                                  pill
                                >
                                  {skill}
                                </Badge>
                              ))}
                              {candidate.resume_skill.split(",").length >
                                firstRowCount[candidate.seeker_id] && (
                                <div className="d-flex align-items-center">
                                  <a
                                    className="show-more-button"
                                    onClick={() =>
                                      toggleShowAllSkills(candidate.seeker_id)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color: "blue",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {showAllSkills[candidate.seeker_id]
                                      ? "Show less"
                                      : "...Show more"}
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <p className="d-flex justify-content-between mt-2 ">
                        {candidate.updated_at && (
                          <span className="d-flex align-items-center text-muted me-4">
                            <Clock
                              className="clock-icon"
                              size={16}
                              style={{ marginRight: "5px" }}
                            />
                            {timeAgo(candidate.updated_at)}
                          </span>
                        )}
                        <div className="d-flex justify-content-between flex-wrap">
                          <span
                            onClick={() => handleMessageClick(candidate)}
                            className={`me-2 link-nav d-flex align-items-center `}
                            style={{ cursor: "pointer" }} // Inline style as fallback
                          >
                            <LuMailPlus className="me-2" size={16} />
                            {"Invite"}
                          </span>
                          <span
                            onClick={() => {
                              if (!savedCandidates[candidate.seeker_id])
                                handleSaveForLaterClick(candidate.seeker_id);
                            }}
                            className={`link-nav d-flex align-items-center ${
                              savedCandidates[candidate.seeker_id]
                                ? "disabled"
                                : ""
                            }`}
                            style={{ cursor: "pointer" }} // Inline style as fallback
                          >
                            <FaRegHeart className="me-2" size={16} />
                            {savedCandidates[candidate.seeker_id]
                              ? "Saved"
                              : "Save"}
                          </span>
                        </div>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </>
        ) : (
          <NoDataFound />
        )}
      </Col>
      {showChatModal && (
        <ChatModal
          isOpen={showChatModal}
          toggleModal={() => setShowChatModal(false)}
          seekerId={selectedSeekerId} // Pass selected seeker ID to ChatModal
        />
      )}
    </Fragment>
  );
};

export default ListCard;
