import React, { Fragment, useState, useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Label,
  Button,
  Form,
} from "reactstrap";
import { JobSearchContext } from "../../../../JobSearch/JobSearchContext";
import { employmentTypeOptions } from "../../../../Constant";
const EmploymentType = () => {
  const [isFilter, setIsFilter] = useState(true);
  const { filters, updateFilters } = useContext(JobSearchContext);
  const [searchKeyword, setSearchKeyword] = useState("");

  // Handle search input change
  const handleSearchChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
  };

  // Handle checkbox change

  const handleCheckboxChange = (option) => {
    // Check if the option's value already exists in job_type array
    const existingOption = filters.employment_type.find(
      (item) => item.value === option.value
    );

    // Toggle the selected state for the clicked job type
    let updatedEmploymentType = [];
    if (existingOption) {
      updatedEmploymentType = filters.employment_type.filter(
        (item) => item.value !== option.value
      );
    } else {
      updatedEmploymentType = [...filters.employment_type, option];
    }

    // Update the job types filter in the context with updated array
    updateFilters({ employment_type: updatedEmploymentType });
  };

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Employment Type</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="filter-cards-view animate-chk border-0">
              {/* <Form className="theme-form">

              <div className="job-filter mb-3">
                <div className="faq-form">
                  <Input
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Search employment type..."
                    onChange={handleSearchChange}
                  />
                  
                </div>
              </div>
              </Form> */}
              {/* <div className="checkbox-animated"> */}
              {employmentTypeOptions.map((option, index) => (
                <label
                  key={index}
                  className="d-flex"
                  htmlFor={`chk-emp-${index}`}
                >
                  <Input
                    className="checkbox_animated mr-2"
                    id={`chk-emp-${index}`}
                    type="checkbox"
                    checked={filters.employment_type.some(
                      (item) => item.value === option.value
                    )}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  {option.label}
                </label>
              ))}
              {/* </div> */}
              {/* <Button className="btn text-center" color="primary" type="button">
                Find jobs
              </Button> */}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default EmploymentType;
