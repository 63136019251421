import { call, put, takeLatest } from "redux-saga/effects";
import { REGISTER_REQUEST } from "../../actionTypes";
// import { postJobApi } from "./api"; // Replace with your actual API file
import AxiosInstance from "../../../services/AxiosInstance";

import { registerFailure, registerSuccess } from "./actions";

function* register(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/auth/register", action.payload)
    );
    // console.log('saga res',response);
    if (response.data.status) {
      yield put(registerSuccess(response.data));
    } else {
      yield put(registerFailure(response.data));
    }
  } catch (error) {
    yield put(registerFailure({ status: false, message: error.message }));
  }
}

// Watch for POST_JOB_REQUEST action
function* registerSaga() {
  yield takeLatest(REGISTER_REQUEST, register);
}

export default registerSaga;
