import { takeLatest, call, put } from "redux-saga/effects";
import { CHANGE_JOB_STATUS_REQUEST } from "../../actionTypes";

import { changeJobStatusSuccess, changeJobStatusFailure } from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* changeJobStatusHandel(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/change_job_status", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(changeJobStatusSuccess(response.data));
    } else {
      yield put(changeJobStatusFailure(response.data));
    }
  } catch (error) {
    yield put(
      changeJobStatusFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* changeJobStatusSaga() {
  yield takeLatest(CHANGE_JOB_STATUS_REQUEST, changeJobStatusHandel);
}
export default changeJobStatusSaga;
