import * as types from "../../actionTypes";

export const addWorkSampleRequest = (data) => ({
  type: types.ADD_WORK_SAMPLE_REQUEST,
  payload: data,
});

export const addWorkSampleSuccess = (data) => ({
  type: types.ADD_WORK_SAMPLE_SUCCESS,
  payload: data,
});

export const addWorkSampleFailure = (error) => ({
  type: types.ADD_WORK_SAMPLE_FAILURE,
  payload: error,
});

export const deleteWorkSampleRequest = (data) => ({
  type: types.DELETE_WORK_SAMPLE_REQUEST,
  payload: data,
});

export const deleteWorkSampleSuccess = (data) => ({
  type: types.DELETE_WORK_SAMPLE_SUCCESS,
  payload: data,
});

export const deleteWorkSampleFailure = (error) => ({
  type: types.DELETE_WORK_SAMPLE_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA,
});
