/* == Common Constants == */
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const RESET_DATA = "RESET_DATA";

// Auth actions types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const RESET_FORGET_PASSWORD_FAILURE = "RESET_FORGET_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_ACCOUNT_REQUEST = "VERIFY_ACCOUNT_REQUEST";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAILURE = "VERIFY_ACCOUNT_FAILURE";

// update jobseeker profile

export const FETCH_JOBSEEKER_DATA_REQUEST = "FETCH_JOBSEEKER_DATA_REQUEST";
export const FETCH_JOBSEEKER_DATA_SUCCESS = "FETCH_JOBSEEKER_DATA_SUCCESS";
export const FETCH_JOBSEEKER_DATA_FAILURE = "FETCH_JOBSEEKER_DATA_FAILURE";

export const FETCH_APPLIED_JOBS_REQUEST = "FETCH_APPLIED_JOBS_REQUEST";
export const FETCH_APPLIED_JOBS_SUCCESS = "FETCH_APPLIED_JOBS_SUCCESS";
export const FETCH_APPLIED_JOBS_FAILURE = "FETCH_APPLIED_JOBS_FAILURE";

export const FETCH_RECOMMENDED_JOBS_REQUEST = "FETCH_RECOMMENDED_JOBS_REQUEST";
export const FETCH_RECOMMENDED_JOBS_SUCCESS = "FETCH_RECOMMENDED_JOBS_SUCCESS";
export const FETCH_RECOMMENDED_JOBS_FAILURE = "FETCH_RECOMMENDED_JOBS_FAILURE";

export const FETCH_MANAGE_JOBS_REQUEST = "FETCH_MANAGE_JOBS_REQUEST";
export const FETCH_MANAGE_JOBS_SUCCESS = "FETCH_MANAGE_JOBS_SUCCESS";
export const FETCH_MANAGE_JOBS_FAILURE = "FETCH_MANAGE_JOBS_FAILURE";

export const DELETE_JOB_REQUEST = "DELETE_JOB_REQUEST";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";

export const UPDATE_COMPANY_PROFILE_REQUEST = "UPDATE_COMPANY_PROFILE_REQUEST";
export const UPDATE_COMPANY_PROFILE_SUCCESS = "UPDATE_COMPANY_PROFILE_SUCCESS";
export const UPDATE_COMPANY_PROFILE_FAILURE = "UPDATE_COMPANY_PROFILE_FAILURE";
export const RESET_MESSAGE = "RESET_MESSAGE";

export const FETCH_COMPANY_PROFILE_REQUEST = "FETCH_COMPANY_PROFILE_REQUEST";
export const FETCH_COMPANY_PROFILE_SUCCESS = "FETCH_COMPANY_PROFILE_SUCCESS";
export const FETCH_COMPANY_PROFILE_FAILURE = "FETCH_COMPANY_PROFILE_FAILURE";

export const UPDATE_RECRUITER_PROFILE_REQUEST =
  "UPDATE_RECRUITER_PROFILE_REQUEST";
export const UPDATE_RECRUITER_PROFILE_SUCCESS =
  "UPDATE_RECRUITER_PROFILE_SUCCESS";
export const UPDATE_RECRUITER_PROFILE_FAILURE =
  "UPDATE_RECRUITER_PROFILE_FAILURE";

export const FETCH_RECRUITER_PROFILE_REQUEST =
  "FETCH_RECRUITER_PROFILE_REQUEST";
export const FETCH_RECRUITER_PROFILE_SUCCESS =
  "FETCH_RECRUITER_PROFILE_SUCCESS";
export const FETCH_RECRUITER_PROFILE_FAILURE =
  "FETCH_RECRUITER_PROFILE_FAILURE";

export const UPDATE_RECRUITER_AVATAR_REQUEST =
  "UPDATE_RECRUITER_AVATAR_REQUEST";
export const UPDATE_RECRUITER_AVATAR_SUCCESS =
  "UPDATE_RECRUITER_AVATAR_SUCCESS";
export const UPDATE_RECRUITER_AVATAR_FAILURE =
  "UPDATE_RECRUITER_AVATAR_FAILURE";
export const REMOVE_RECRUITER_AVATAR_REQUEST =
  "REMOVE_RECRUITER_AVATAR_REQUEST";
export const REMOVE_RECRUITER_AVATAR_SUCCESS =
  "REMOVE_RECRUITER_AVATAR_SUCCESS";
export const REMOVE_RECRUITER_AVATAR_FAILURE =
  "REMOVE_RECRUITER_AVATAR_FAILURE";

export const UPDATE_COMPANY_LOGO_REQUEST = "UPDATE_COMPANY_LOGO_REQUEST";
export const UPDATE_COMPANY_LOGO_SUCCESS = "UPDATE_COMPANY_LOGO_SUCCESS";
export const UPDATE_COMPANY_LOGO_FAILURE = "UPDATE_COMPANY_LOGO_FAILURE";
export const REMOVE_COMPANY_LOGO_REQUEST = "REMOVE_COMPANY_LOGO_REQUEST";
export const REMOVE_COMPANY_LOGO_SUCCESS = "REMOVE_COMPANY_LOGO_SUCCESS";
export const REMOVE_COMPANY_LOGO_FAILURE = "REMOVE_COMPANY_LOGO_FAILURE";

export const FETCH_JOB_DETAILS_REQUEST = "FETCH_JOB_DETAILS_REQUEST";
export const FETCH_JOB_DETAILS_SUCCESS = "FETCH_JOB_DETAILS_SUCCESS";
export const FETCH_JOB_DETAILS_FAILURE = "FETCH_JOB_DETAILS_FAILURE";

export const JOB_SHARING_REQUEST = "JOB_SHARING_REQUEST";
export const JOB_SHARING_SUCCESS = "JOB_SHARING_SUCCESS";
export const JOB_SHARING_FAILURE = "JOB_SHARING_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
export const RESET_PASSWORD_MESSAGE = "RESET_PASSWORD_MESSAGE";

export const UPDATE_JOBSEEKER_PASSWORD_REQUEST =
  "UPDATE_JOBSEEKER_PASSWORD_REQUEST";
export const UPDATE_JOBSEEKER_PASSWORD_SUCCESS =
  "UPDATE_JOBSEEKER_PASSWORD_SUCCESS";
export const UPDATE_JOBSEEKER_PASSWORD_FAILURE =
  "UPDATE_JOBSEEKER_PASSWORD_FAILURE";
export const RESET_JOBSEEKER_PASSWORD_MESSAGE =
  "RESET_JOBSEEKER_PASSWORD_MESSAGE";

export const FETCH_JOB_APPLICATIONS_REQUEST = "FETCH_JOB_APPLICATIONS_REQUEST";
export const FETCH_JOB_APPLICATIONS_SUCCESS = "FETCH_JOB_APPLICATIONS_SUCCESS";
export const FETCH_JOB_APPLICATIONS_FAILURE = "FETCH_JOB_APPLICATIONS_FAILURE";

export const ADD_CANDIDATE_REQUEST = "ADD_CANDIDATE_REQUEST";
export const ADD_CANDIDATE_SUCCESS = "ADD_CANDIDATE_SUCCESS";
export const ADD_CANDIDATE_FAILURE = "ADD_CANDIDATE_FAILURE";
export const FETCH_ADD_CANDIDATE_REQUEST = "FETCH_ADD_CANDIDATE_REQUEST"; // Updated action type for adding candidate request
export const FETCH_ADD_CANDIDATE_SUCCESS = "FETCH_ADD_CANDIDATE_SUCCESS"; // Updated action type for adding candidate success
export const FETCH_ADD_CANDIDATE_FAILURE = "FETCH_ADD_CANDIDATE_FAILURE"; // Updated action type for adding candidate failure
export const RESET_ADD_CANDIDATE = "RESET_ADD_CANDIDATE";

export const FETCH_LOCATIONS_REQUEST = "FETCH_LOCATIONS_REQUEST";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";

export const FETCH_EDUCATION_REQUEST = "FETCH_EDUCATION_REQUEST";
export const FETCH_EDUCATION_SUCCESS = "FETCH_EDUCATION_SUCCESS";
export const FETCH_EDUCATION_FAILURE = "FETCH_EDUCATION_FAILURE";

export const FETCH_JOB_REQUEST = "FETCH_JOB_REQUEST";
export const FETCH_JOB_SUCCESS = "FETCH_JOB_SUCCESS";
export const FETCH_JOB_FAILURE = "FETCH_JOB_FAILURE";
export const RESET_JOB = "RESET_JOB";

export const POST_JOB_REQUEST = "POST_JOB_REQUEST";
export const POST_JOB_SUCCESS = "POST_JOB_SUCCESS";
export const POST_JOB_FAILURE = "POST_JOB_FAILURE";

export const FETCH_FUNCTIONAL_AREA_REQUEST = "FETCH_FUNCTIONAL_AREA_REQUEST";
export const FETCH_FUNCTIONAL_AREA_SUCCESS = "FETCH_FUNCTIONAL_AREA_SUCCESS";
export const FETCH_FUNCTIONAL_AREA_FAILURE = "FETCH_FUNCTIONAL_AREA_FAILURE";

export const FETCH_INDUSTRY_REQUEST = "FETCH_INDUSTRY_REQUEST";
export const FETCH_INDUSTRY_SUCCESS = "FETCH_INDUSTRY_SUCCESS";
export const FETCH_INDUSTRY_FAILURE = "FETCH_INDUSTRY_FAILURE";

export const FETCH_SKILLS_REQUEST = "FETCH_SKILLS_REQUEST";
export const FETCH_SKILLS_SUCCESS = "FETCH_SKILLS_SUCCESS";
export const FETCH_SKILLS_FAILURE = "FETCH_SKILLS_FAILURE";

export const FETCH_COUNTRY_REQUEST = "FETCH_COUNTRY_REQUEST";
export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_FAILURE = "FETCH_COUNTRY_FAILURE";

export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

//update profile

export const ADD_SOCIAL_MEDIA_REQUEST = "ADD_SOCIAL_MEDIA_REQUEST";
export const ADD_SOCIAL_MEDIA_SUCCESS = "ADD_SOCIAL_MEDIA_SUCCESS";
export const ADD_SOCIAL_MEDIA_FAILURE = "ADD_SOCIAL_MEDIA_FAILURE";
export const DELETE_SOCIAL_MEDIA_REQUEST = "DELETE_SOCIAL_MEDIA_REQUEST";
export const DELETE_SOCIAL_MEDIA_SUCCESS = "DELETE_SOCIAL_MEDIA_SUCCESS";
export const DELETE_SOCIAL_MEDIA_FAILURE = "DELETE_SOCIAL_MEDIA_FAILURE";

export const ADD_SKILLS_REQUEST = "ADD_SKILLS_REQUEST";
export const ADD_SKILLS_SUCCESS = "ADD_SKILLS_SUCCESS";
export const ADD_SKILLS_FAILURE = "ADD_SKILLS_FAILURE";

export const UPDATE_RESUME_HEADLINE_REQUEST = "UPDATE_RESUME_HEADLINE_REQUEST";
export const UPDATE_RESUME_HEADLINE_SUCCESS = "UPDATE_RESUME_HEADLINE_SUCCESS";
export const UPDATE_RESUME_HEADLINE_FAILURE = "UPDATE_RESUME_HEADLINE_FAILURE";

export const SCAN_RESUME_REQUEST = "SCAN_RESUME_REQUEST";
export const SCAN_RESUME_SUCCESS = "SCAN_RESUME_SUCCESS";
export const SCAN_RESUME_FAILURE = "SCAN_RESUME_FAILURE";
export const SCAN_RESUME_RESET = "SCAN_RESUME_RESET";

export const ADD_EDUCATION_BACKGROUND_REQUEST =
  "ADD_EDUCATION_BACKGROUND_REQUEST";
export const ADD_EDUCATION_BACKGROUND_SUCCESS =
  "ADD_EDUCATION_BACKGROUND_SUCCESS";
export const ADD_EDUCATION_BACKGROUND_FAILURE =
  "ADD_EDUCATION_BACKGROUND_FAILURE";
export const DELETE_EDUCATION_BACKGROUND_REQUEST =
  "DELETE_EDUCATION_BACKGROUND_REQUEST";
export const DELETE_EDUCATION_BACKGROUND_SUCCESS =
  "DELETE_EDUCATION_BACKGROUND_SUCCESS";
export const DELETE_EDUCATION_BACKGROUND_FAILURE =
  "DELETE_EDUCATION_BACKGROUND_FAILURE";

export const ADD_WORK_EXPERIENCE_REQUEST = "ADD_WORK_EXPERIENCE_REQUEST";
export const ADD_WORK_EXPERIENCE_SUCCESS = "ADD_WORK_EXPERIENCE_SUCCESS";
export const ADD_WORK_EXPERIENCE_FAILURE = "ADD_WORK_EXPERIENCE_FAILURE";
export const DELETE_WORK_EXPERIENCE_REQUEST = "DELETE_WORK_EXPERIENCE_REQUEST";
export const DELETE_WORK_EXPERIENCE_SUCCESS = "DELETE_WORK_EXPERIENCE_SUCCESS";
export const DELETE_WORK_EXPERIENCE_FAILURE = "DELETE_WORK_EXPERIENCE_FAILURE";

export const ADD_TECHNICAL_SKILL_REQUEST = "ADD_TECHNICAL_SKILL_REQUEST";
export const ADD_TECHNICAL_SKILL_SUCCESS = "ADD_TECHNICAL_SKILL_SUCCESS";
export const ADD_TECHNICAL_SKILL_FAILURE = "ADD_TECHNICAL_SKILL_FAILURE";
export const DELETE_TECHNICAL_SKILLS_REQUEST =
  "DELETE_TECHNICAL_SKILLS_REQUEST";
export const DELETE_TECHNICAL_SKILLS_SUCCESS =
  "DELETE_TECHNICAL_SKILLS_SUCCESS";
export const DELETE_TECHNICAL_SKILLS_FAILURE =
  "DELETE_TECHNICAL_SKILLS_FAILURE";

export const UPDATE_PERSONAL_INFO_REQUEST = "UPDATE_PERSONAL_INFO_REQUEST";
export const UPDATE_PERSONAL_INFO_SUCCESS = "UPDATE_PERSONAL_INFO_SUCCESS";
export const UPDATE_PERSONAL_INFO_FAILURE = "UPDATE_PERSONAL_INFO_FAILURE";

export const ADD_WORK_SAMPLE_REQUEST = "ADD_WORK_SAMPLE_REQUEST";
export const ADD_WORK_SAMPLE_SUCCESS = "ADD_WORK_SAMPLE_SUCCESS";
export const ADD_WORK_SAMPLE_FAILURE = "ADD_WORK_SAMPLE_FAILURE";
export const DELETE_WORK_SAMPLE_REQUEST = "DELETE_WORK_SAMPLE_REQUEST";
export const DELETE_WORK_SAMPLE_SUCCESS = "DELETE_WORK_SAMPLE_SUCCESS";
export const DELETE_WORK_SAMPLE_FAILURE = "DELETE_WORK_SAMPLE_FAILURE";

export const UPDATE_DESIRED_CAREER_PROFILE_REQUEST =
  "UPDATE_DESIRED_CAREER_PROFILE_REQUEST";
export const UPDATE_DESIRED_CAREER_PROFILE_SUCCESS =
  "UPDATE_DESIRED_CAREER_PROFILE_SUCCESS";
export const UPDATE_DESIRED_CAREER_PROFILE_FAILURE =
  "UPDATE_DESIRED_CAREER_PROFILE_FAILURE";

// sandeep work

// recruiter - manage candidates

export const FETCH_MANAGE_CANDIDATES_REQUEST =
  "FETCH_MANAGE_CANDIDATES_REQUEST";
export const FETCH_MANAGE_CANDIDATES_SUCCESS =
  "FETCH_MANAGE_CANDIDATES_SUCCESS";
export const FETCH_MANAGE_CANDIDATES_FAILURE =
  "FETCH_MANAGE_CANDIDATES_FAILURE";
export const DOWNLOAD_CANDIDATES = "DOWNLOAD_CANDIDATES";

// update jobseeker profile

export const UPDATE_JOBSEEKER_AVATAR = "UPDATE_JOBSEEKER_AVATAR";
export const UPDATE_JOBSEEKER_AVATAR_SUCCESS =
  "UPDATE_JOBSEEKER_AVATAR_SUCCESS";
export const UPDATE_JOBSEEKER_AVATAR_FAILURE =
  "UPDATE_JOBSEEKER_AVATAR_FAILURE";
export const RESET_JOOBSEEKER_AVATAR_DATA = "RESET_JOOBSEEKER_AVATAR_DATA";

export const FETCH_RESUME_TEMPLATES_REQUEST = "FETCH_RESUME_TEMPLATES_REQUEST";
export const FETCH_RESUME_TEMPLATES_SUCCESS = "FETCH_RESUME_TEMPLATES_SUCCESS";
export const FETCH_RESUME_TEMPLATES_FAILURE = "FETCH_RESUME_TEMPLATES_FAILURE";
export const UPDATE_RESUME = "UPDATE_RESUME";
export const UPDATE_RESUME_SUCCESS = "UPDATE_RESUME_SUCCESS";
export const UPDATE_RESUME_FAILURE = "UPDATE_RESUME_FAILURE";
export const DELETE_RESUME_REQUEST = "DELETE_RESUME_REQUEST";
export const DELETE_RESUME_SUCCESS = "DELETE_RESUME_SUCCESS";
export const DELETE_RESUME_FAILURE = "DELETE_RESUME_FAILURE";
export const FETCH_TEMPLATES_REQUEST = "FETCH_TEMPLATES_REQUEST";
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATES_FAILURE = "FETCH_TEMPLATES_FAILURE";
export const UNLOCK_TEMPLATE_REQUEST = "UNLOCK_TEMPLATE_REQUEST";
export const UNLOCK_TEMPLATE_SUCCESS = "UNLOCK_TEMPLATE_SUCCESS";
export const UNLOCK_TEMPLATE_FAILURE = "UNLOCK_TEMPLATE_FAILURE";

export const SHARE_RESUME_TEMPLATE_REQUEST = "SHARE_RESUME_TEMPLATE_REQUEST";
export const SHARE_RESUME_TEMPLATE_SUCCESS = "SHARE_RESUME_TEMPLATE_SUCCESS";
export const SHARE_RESUME_TEMPLATE_FAILURE = "SHARE_RESUME_TEMPLATE_FAILURE";
export const CHECK_TEMPLATE_LOCK_REQUEST = "CHECK_TEMPLATE_LOCK_REQUEST";
export const CHECK_TEMPLATE_LOCK_SUCCESS = "CHECK_TEMPLATE_LOCK_SUCCESS";
export const CHECK_TEMPLATE_LOCK_FAILURE = "CHECK_TEMPLATE_LOCK_FAILURE";
export const SHARE_SEEKER_DATA_REQUEST = "SHARE_SEEKER_DATA_REQUEST";
export const SHARE_SEEKER_DATA_SUCCESS = "SHARE_SEEKER_DATA_SUCCESS";
export const SHARE_SEEKER_DATA_FAILURE = "SHARE_SEEKER_DATA_FAILURE";

// actionTypes.js  for  referral  for recruiter
export const FETCH_REFERRAL_REQUEST = "FETCH_REFERRAL_REQUEST";
export const FETCH_REFERRAL_SUCCESS = "FETCH_REFERRAL_SUCCESS";
export const FETCH_REFERRAL_FAILURE = "FETCH_REFERRAL_FAILURE";

// actionTypes.js  for  jobseeker  for recruiter
export const FETCH_JOBSEEKER_REFERRAL_REQUEST =
  "FETCH_JOBSEEKER_REFERRAL_REQUEST";
export const FETCH_JOBSEEKER_REFERRAL_SUCCESS =
  "FETCH_JOBSEEKER_REFERRAL_SUCCESS";
export const FETCH_JOBSEEKER_REFERRAL_FAILURE =
  "FETCH_JOBSEEKER_REFERRAL_FAILURE";

// Action Types  for jobseeker raj report job
export const SUBMIT_REPORT_REQUEST = "SUBMIT_REPORT_REQUEST";
export const SUBMIT_REPORT_SUCCESS = "SUBMIT_REPORT_SUCCESS";
export const SUBMIT_REPORT_FAILURE = "SUBMIT_REPORT_FAILURE";

export const FETCH_REPORT_JOB_REQUEST = "FETCH_REPORT_JOB_REQUEST";

//archana
export const APPLY_JOB_REQUEST = "APPLY_JOB_REQUEST";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAILURE = "APPLY_JOB_FAILURE";

export const FETCH_DASHBOARD_DATA_REQUEST = "FETCH_DASHBOARD_DATA_REQUEST";
export const FETCH_DASHBOARD_DATA_SUCCESS = "FETCH_DASHBOARD_DATA_SUCCESS";
export const FETCH_DASHBOARD_DATA_FAILURE = "FETCH_DASHBOARD_DATA_FAILURE";
export const RESET_DAILY_VISIT = "RESET_DAILY_VISIT";

export const DELETE_CANDIDATE_REQUEST = "DELETE_CANDIDATE_REQUEST";
export const DELETE_CANDIDATE_SUCCESS = "DELETE_CANDIDATE_SUCCESS";
export const DELETE_CANDIDATE_FAILURE = "DELETE_CANDIDATE_FAILURE";

export const FETCH_CANDIDATE_REQUEST = "FETCH_CANDIDATE_REQUEST";
export const FETCH_CANDIDATE_SUCCESS = "FETCH_CANDIDATE_SUCCESS";
export const FETCH_CANDIDATE_FAILURE = "FETCH_CANDIDATE_FAILURE";

export const FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST =
  "FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST";
export const FETCH_JOBSEEKER_DASHBOARD_DATA_SUCCESS =
  "FETCH_JOBSEEKER_DASHBOARD_DATA_SUCCESS";
export const FETCH_JOBSEEKER_DASHBOARD_DATA_FAILURE =
  "FETCH_JOBSEEKER_DASHBOARD_DATA_FAILURE";
export const RESET_DAILY = "RESET_DAILY";

export const UPDATE_CANDIDATE_INFO_REQUEST = "UPDATE_CANDIDATE_INFO_REQUEST";
export const UPDATE_CANDIDATE_INFO_SUCCESS = "UPDATE_CANDIDATE_INFO_SUCCESS";
export const UPDATE_CANDIDATE_INFO_FAILURE = "UPDATE_CANDIDATE_INFO_FAILURE";
export const RESET_CANDIDATE_INFO_MESSAGE = "RESET_CANDIDATE_INFO_MESSAGE";

export const UPDATE_CONTACTIBILITY_REQUEST = "UPDATE_CONTACTIBILITY_REQUEST";
export const UPDATE_CONTACTIBILITY_SUCCESS = "UPDATE_CONTACTIBILITY_SUCCESS";
export const UPDATE_CONTACTIBILITY_FAILURE = "UPDATE_CONTACTIBILITY_FAILURE";
export const RESET_CONTACTIBILITY_MESSAGE = "RESET_CONTACTIBILITY_MESSAGE";

export const UPDATE_COMMUNICATION_REQUEST = "UPDATE_COMMUNICATION_REQUEST";
export const UPDATE_COMMUNICATION_SUCCESS = "UPDATE_COMMUNICATION_SUCCESS";
export const UPDATE_COMMUNICATION_FAILURE = "UPDATE_COMMUNICATION_FAILURE";
export const RESET_COMMUNICATION_MESSAGE = "RESET_COMMUNICATION_MESSAGE";

export const UPDATE_CANDIDATE_EXPERIENCE_REQUEST =
  "UPDATE_CANDIDATE_EXPERIENCE_REQUEST";
export const UPDATE_CANDIDATE_EXPERIENCE_SUCCESS =
  "UPDATE_CANDIDATE_EXPERIENCE_SUCCESS";
export const UPDATE_CANDIDATE_EXPERIENCE_ERROR =
  "UPDATE_CANDIDATE_EXPERIENCE_ERROR";
export const RESET_CANDIDATE_EXPERIENCE_MESSAGE =
  "RESET_CANDIDATE_EXPERIENCE_MESSAGE";

// CandidateActionTypes.js

export const UPDATE_CANDIDATE_TECHNICAL_SKILLS_REQUEST =
  "UPDATE_CANDIDATE_TECHNICAL_SKILLS_REQUEST";
export const UPDATE_CANDIDATE_TECHNICAL_SKILLS_SUCCESS =
  "UPDATE_CANDIDATE_TECHNICAL_SKILLS_SUCCESS";
export const UPDATE_CANDIDATE_TECHNICAL_SKILLS_ERROR =
  "UPDATE_CANDIDATE_TECHNICAL_SKILLS_ERROR";
export const RESET_CANDIDATE_TECHNICAL_SKILLS_MESSAGE =
  "RESET_CANDIDATE_TECHNICAL_SKILLS_MESSAGE";

export const UPDATE_CANDIDATE_SOFT_SKILLS_REQUEST =
  "UPDATE_CANDIDATE_SOFT_SKILLS_REQUEST";
export const UPDATE_CANDIDATE_SOFT_SKILLS_SUCCESS =
  "UPDATE_CANDIDATE_SOFT_SKILLS_SUCCESS";
export const UPDATE_CANDIDATE_SOFT_SKILLS_ERROR =
  "UPDATE_CANDIDATE_SOFT_SKILLS_ERROR";
export const RESET_CANDIDATE_SOFT_SKILLS_MESSAGE =
  "RESET_CANDIDATE_SOFT_SKILLS_MESSAGE";

export const UPDATE_CANDIDATE_RATING_REQUEST =
  "UPDATE_CANDIDATE_RATING_REQUEST";
export const UPDATE_CANDIDATE_RATING_SUCCESS =
  "UPDATE_CANDIDATE_RATING_SUCCESS";
export const UPDATE_CANDIDATE_RATING_FAILURE =
  "UPDATE_CANDIDATE_RATING_FAILURE";
export const RESET_CANDIDATE_RATING_MESSAGE = "RESET_CANDIDATE_RATING_MESSAGE";

export const GO_TO_PASSBOOK_REQUEST = "GO_TO_PASSBOOK_REQUEST";
export const GO_TO_PASSBOOK_SUCCESS = "GO_TO_PASSBOOK_SUCCESS";
export const GO_TO_PASSBOOK_FAILURE = "GO_TO_PASSBOOK_FAILURE";

export const ADD_NOTE_REQUEST = "ADD_NOTE_REQUEST";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAILURE = "ADD_NOTE_FAILURE";
export const DELETE_NOTE_REQUEST = "DELETE_NOTE_REQUEST";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAILURE = "DELETE_NOTE_FAILURE";
export const RESET_NOTE_MESSAGE = "RESET_NOTE_MESSAGE";

export const SAVE_FAVORITE_JOB_REQUEST = "SAVE_FAVORITE_JOB_REQUEST";
export const SAVE_FAVORITE_JOB_SUCCESS = "SAVE_FAVORITE_JOB_SUCCESS";
export const SAVE_FAVORITE_JOB_FAILURE = "SAVE_FAVORITE_JOB_FAILURE";
export const RESET_FAVORITE_MESSAGE = "RESET_FAVORITE_MESSAGE";

export const FETCH_FAVORITE_JOBS_REQUEST = "FETCH_FAVORITE_JOBS_REQUEST";
export const FETCH_FAVORITE_JOBS_SUCCESS = "FETCH_FAVORITE_JOBS_SUCCESS";
export const FETCH_FAVORITE_JOBS_FAILURE = "FETCH_FAVORITE_JOBS_FAILURE";

export const DELETE_FAVORITE_JOB_REQUEST = "DELETE_FAVORITE_JOB_REQUEST";
export const DELETE_FAVORITE_JOB_SUCCESS = "DELETE_FAVORITE_JOB_SUCCESS";
export const DELETE_FAVORITE_JOB_FAILURE = "DELETE_FAVORITE_JOB_FAILURE";

export const FETCH_JOB_SOURCES_REQUEST = "FETCH_JOB_SOURCES_REQUEST";
export const FETCH_JOB_SOURCES_SUCCESS = "FETCH_JOB_SOURCES_SUCCESS";
export const FETCH_JOB_SOURCES_FAILURE = "FETCH_JOB_SOURCES_FAILURE";

export const FETCH_JOB_CONVERSION_REQUEST = "FETCH_JOB_CONVERSION_REQUEST";
export const FETCH_JOB_CONVERSION_SUCCESS = "FETCH_JOB_CONVERSION_SUCCESS";
export const FETCH_JOB_CONVERSION_FAILURE = "FETCH_JOB_CONVERSION_FAILURE";

export const FETCH_JOB_PIPELINE_REQUEST = "FETCH_JOB_PIPELINE_REQUEST";
export const FETCH_JOB_PIPELINE_SUCCESS = "FETCH_JOB_PIPELINE_SUCCESS";
export const FETCH_JOB_PIPELINE_FAILURE = "FETCH_JOB_PIPELINE_FAILURE";

export const FETCH_WEEKLY_STATUS_REQUEST = "FETCH_WEEKLY_STATUS_REQUEST";
export const FETCH_WEEKLY_STATUS_SUCCESS = "FETCH_WEEKLY_STATUS_SUCCESS";
export const FETCH_WEEKLY_STATUS_FAILURE = "FETCH_WEEKLY_STATUS_FAILURE";

export const FETCH_DETAIL_REPORT_REQUEST = "FETCH_DETAIL_REPORT_REQUEST";
export const FETCH_DETAIL_REPORT_SUCCESS = "FETCH_DETAIL_REPORT_SUCCESS";
export const FETCH_DETAIL_REPORT_FAILURE = "FETCH_DETAIL_REPORT_FAILURE";

export const FETCH_JOB_NAMES_REQUEST = "FETCH_JOB_NAMES_REQUEST";
export const FETCH_JOB_NAMES_SUCCESS = "FETCH_JOB_NAMES_SUCCESS";
export const FETCH_JOB_NAMES_FAILURE = "FETCH_JOB_NAMES_FAILURE";

export const FETCH_UNREAD_NOTIFICATIONS_REQUEST =
  "FETCH_UNREAD_NOTIFICATIONS_REQUEST";
export const FETCH_ALL_NOTIFICATIONS_REQUEST =
  "FETCH_ALL_NOTIFICATIONS_REQUEST";
export const FETCH_UNREAD_NOTIFICATIONS_SUCCESS =
  "FETCH_UNREAD_NOTIFICATIONS_SUCCESS";
export const FETCH_ALL_NOTIFICATIONS_SUCCESS =
  "FETCH_ALL_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const MARK_ALL_AS_READ_REQUEST = "MARK_ALL_AS_READ_REQUEST";
export const MARK_ALL_AS_READ_SUCCESS = "MARK_ALL_AS_READ_SUCCESS";
export const MARK_ALL_AS_READ_FAILURE = "MARK_ALL_AS_READ_FAILURE";
export const RESET_MARK_ALL_AS_READ_MESSAGE = "RESET_MARK_ALL_AS_READ_MESSAGE";

export const SUBMIT_FEEDBACK_REQUEST = "SUBMIT_FEEDBACK_REQUEST";
export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS";
export const SUBMIT_FEEDBACK_FAILURE = "SUBMIT_FEEDBACK_FAILURE";
export const RESET_FEEDBACK_MESSAGE = "RESET_FEEDBACK_MESSAGE";

export const DOWNLOAD_PIPELINE_CSV_REQUEST = "DOWNLOAD_PIPELINE_CSV_REQUEST";
export const DOWNLOAD_PIPELINE_CSV_SUCCESS = "DOWNLOAD_PIPELINE_CSV_SUCCESS";
export const DOWNLOAD_PIPELINE_CSV_FAILURE = "DOWNLOAD_PIPELINE_CSV_FAILURE";

export const DOWNLOAD_JOB_SOURCE_CSV_REQUEST =
  "DOWNLOAD_JOB_SOURCE_CSV_REQUEST";
export const DOWNLOAD_JOB_SOURCE_CSV_SUCCESS =
  "DOWNLOAD_JOB_SOURCE_CSV_SUCCESS";
export const DOWNLOAD_JOB_SOURCE_CSV_FAILURE =
  "DOWNLOAD_JOB_SOURCE_CSV_FAILURE";

export const DOWNLOAD_JOB_CONVERSION_CSV_REQUEST =
  "DOWNLOAD_JOB_CONVERSION_CSV_REQUEST";
export const DOWNLOAD_JOB_CONVERSION_CSV_SUCCESS =
  "DOWNLOAD_JOB_CONVERSION_CSV_SUCCESS";
export const DOWNLOAD_JOB_CONVERSION_CSV_FAILURE =
  "DOWNLOAD_JOB_CONVERSION_CSV_FAILURE";

export const DOWNLOAD_RECRUITER_DETAILS_CSV_REQUEST =
  "DOWNLOAD_RECRUITER_DETAILS_CSV_REQUEST";
export const DOWNLOAD_RECRUITER_DETAILS_CSV_SUCCESS =
  "DOWNLOAD_RECRUITER_DETAILS_CSV_SUCCESS";
export const DOWNLOAD_RECRUITER_DETAILS_CSV_FAILURE =
  "DOWNLOAD_RECRUITER_DETAILS_CSV_FAILURE";

export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";

export const ADD_CONTACTED_COUNT_REQUEST = "ADD_CONTACTED_COUNT_REQUEST";
export const ADD_CONTACTED_COUNT_SUCCESS = "ADD_CONTACTED_COUNT_SUCCESS";
export const ADD_CONTACTED_COUNT_FAILURE = "ADD_CONTACTED_COUNT_FAILURE";

// actionTypes.js
export const CHECK_CHAT_EXIST_REQUEST = "CHECK_CHAT_EXIST_REQUEST";
export const CHECK_CHAT_EXIST_SUCCESS = "CHECK_CHAT_EXIST_SUCCESS";
export const CHECK_CHAT_EXIST_FAILURE = "CHECK_CHAT_EXIST_FAILURE";
export const FETCH_CHAT_MESSAGES_REQUEST = "FETCH_CHAT_MESSAGES_REQUEST";
export const FETCH_CHAT_MESSAGES_SUCCESS = "FETCH_CHAT_MESSAGES_SUCCESS";
export const FETCH_CHAT_MESSAGES_FAILURE = "FETCH_CHAT_MESSAGES_FAILURE";
export const SEND_CHAT_MESSAGE_REQUEST = "SEND_CHAT_MESSAGE_REQUEST";
export const SEND_CHAT_MESSAGE_SUCCESS = "SEND_CHAT_MESSAGE_SUCCESS";
export const SEND_CHAT_MESSAGE_FAILURE = "SEND_CHAT_MESSAGE_FAILURE";
export const RECEIVE_CHAT_MESSAGE = "RECEIVE_CHAT_MESSAGE";
export const RESET_CHAT_STATE = "RESET_CHAT_STATE";
export const FETCH_CHAT_MEMBERS_REQUEST = "FETCH_CHAT_MEMBERS_REQUEST";
export const FETCH_CHAT_MEMBERS_SUCCESS = "FETCH_CHAT_MEMBERS_SUCCESS";
export const FETCH_CHAT_MEMBERS_FAILURE = "FETCH_CHAT_MEMBERS_FAILURE";
export const SEND_CHAT_INVITE_SUCCESS = "SEND_CHAT_INVITE_SUCCESS";

export const PUBLIC_APPLY_JOB_REQUEST = "PUBLIC_APPLY_JOB_REQUEST";
export const PUBLIC_APPLY_JOB_SUCCESS = "PUBLIC_APPLY_JOB_SUCCESS";
export const PUBLIC_APPLY_JOB_FAILURE = "PUBLIC_APPLY_JOB_FAILURE";
export const PUBLIC_APPLY_JOB_RESET = "PUBLIC_APPLY_JOB_RESET";

// src/redux/bulkUpload/bulkUploadActionTypes.js

export const CANDIDATE_BULK_UPLOAD_REQUEST = "CANDIDATE_BULK_UPLOAD_REQUEST";
export const CANDIDATE_BULK_UPLOAD_SUCCESS = "CANDIDATE_BULK_UPLOAD_SUCCESS";
export const CANDIDATE_BULK_UPLOAD_FAILURE = "CANDIDATE_BULK_UPLOAD_FAILURE";
export const CANDIDATE_BULK_UPLOAD_RESET = "CANDIDATE_BULK_UPLOAD_RESET";

// actionTypes.js
export const CANDIDATE_BULK_EXCEL_UPLOAD_REQUEST =
  "CANDIDATE_BULK_EXCEL_UPLOAD_REQUEST";
export const CANDIDATE_BULK_EXCEL_UPLOAD_SUCCESS =
  "CANDIDATE_BULK_EXCEL_UPLOAD_SUCCESS";
export const CANDIDATE_BULK_EXCEL_UPLOAD_FAILURE =
  "CANDIDATE_BULK_EXCEL_UPLOAD_FAILURE";
export const CANDIDATE_BULK_EXCEL_UPLOAD_RESET =
  "CANDIDATE_BULK_EXCEL_UPLOAD_RESET";

export const CHANGE_JOB_STATUS_REQUEST = "CHANGE_JOB_STATUS_REQUEST";
export const CHANGE_JOB_STATUS_SUCCESS = "CHANGE_JOB_STATUS_SUCCESS";
export const CHANGE_JOB_STATUS_FAILURE = "CHANGE_JOB_STATUS_FAILURE";
export const RESET_CHANGE_JOB_STATUS = "RESET_CHANGE_JOB_STATUS";

export const UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST =
  "UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST";
export const UPDATE_CANDIDATE_PRIMARY_SKILLS_SUCCESS =
  "UPDATE_CANDIDATE_PRIMARY_SKILLS_SUCCESS";
export const UPDATE_CANDIDATE_PRIMARY_SKILLS_FAILURE =
  "UPDATE_CANDIDATE_PRIMARY_SKILLS_FAILURE";
export const RESET_CANDIDATE_PRIMARY_SKILLS_STATE =
  "RESET_CANDIDATE_PRIMARY_SKILLS_STATE"; // New action type

export const EXTEND_JOB_EXPIRE_DATE_REQUEST = "EXTEND_JOB_EXPIRE_DATE_REQUEST";
export const EXTEND_JOB_EXPIRE_DATE_SUCCESS = "EXTEND_JOB_EXPIRE_DATE_SUCCESS";
export const EXTEND_JOB_EXPIRE_DATE_FAILURE = "EXTEND_JOB_EXPIRE_DATE_FAILURE";
export const EXTEND_JOB_EXPIRE_DATE_RESET = "EXTEND_JOB_EXPIRE_DATE_RESET";

export const INVITE_RECRUITER_REQUEST = "INVITE_RECRUITER_REQUEST";
export const INVITE_RECRUITER_SUCCESS = "INVITE_RECRUITER_SUCCESS";
export const INVITE_RECRUITER_FAILURE = "INVITE_RECRUITER_FAILURE";
export const RESET_INVITE_RECRUITER = "RESET_INVITE_RECRUITER";

export const FETCH_RECRUITER_INVITES_REQUEST =
  "FETCH_RECRUITER_INVITES_REQUEST";
export const FETCH_RECRUITER_INVITES_SUCCESS =
  "FETCH_RECRUITER_INVITES_SUCCESS";
export const FETCH_RECRUITER_INVITES_FAILURE =
  "FETCH_RECRUITER_INVITES_FAILURE";
export const RESET_FETCH_RECRUITER_INVITES = "RESET_FETCH_RECRUITER_INVITES";

export const VERIFY_RECRUITER_INVITE_TOKEN_REQUEST =
  "VERIFY_RECRUITER_INVITE_TOKEN_REQUEST";
export const VERIFY_RECRUITER_INVITE_TOKEN_SUCCESS =
  "VERIFY_RECRUITER_INVITE_TOKEN_SUCCESS";
export const VERIFY_RECRUITER_INVITE_TOKEN_FAILURE =
  "VERIFY_RECRUITER_INVITE_TOKEN_FAILURE";
export const RESET_VERIFY_RECRUITER_INVITE_TOKEN =
  "RESET_VERIFY_RECRUITER_INVITE_TOKEN";

export const ACCEPT_OR_REJECT_INVITE_REQUEST =
  "ACCEPT_OR_REJECT_INVITE_REQUEST";
export const ACCEPT_OR_REJECT_INVITE_SUCCESS =
  "ACCEPT_OR_REJECT_INVITE_SUCCESS";
export const ACCEPT_OR_REJECT_INVITE_FAILURE =
  "ACCEPT_OR_REJECT_INVITE_FAILURE";
export const RESET_ACCEPT_OR_REJECT_INVITE = "RESET_ACCEPT_OR_REJECT_INVITE";

// totalPointsActionTypes.js

export const GET_TOTAL_POINTS_REQUEST = "GET_TOTAL_POINTS_REQUEST";
export const GET_TOTAL_POINTS_SUCCESS = "GET_TOTAL_POINTS_SUCCESS";
export const GET_TOTAL_POINTS_FAILURE = "GET_TOTAL_POINTS_FAILURE";
export const RESET_TOTAL_POINTS = "RESET_TOTAL_POINTS";

// actionTypes.js
export const FETCH_REWARDS_POINTS_REQUEST = "FETCH_REWARDS_POINTS_REQUEST";
export const FETCH_REWARDS_POINTS_SUCCESS = "FETCH_REWARDS_POINTS_SUCCESS";
export const FETCH_REWARDS_POINTS_FAILURE = "FETCH_REWARDS_POINTS_FAILURE";
export const RESET_REWARDS_POINTS = "RESET_REWARDS_POINTS";

export const GENERATE_LINKEDIN_BANNER_REQUEST =
  "GENERATE_LINKEDIN_BANNER_REQUEST";
export const GENERATE_LINKEDIN_BANNER_SUCCESS =
  "GENERATE_LINKEDIN_BANNER_SUCCESS";
export const GENERATE_LINKEDIN_BANNER_FAILURE =
  "GENERATE_LINKEDIN_BANNER_FAILURE";

export const GET_LINKEDIN_BANNER_REQUEST = "GET_LINKEDIN_BANNER_REQUEST";
export const GET_LINKEDIN_BANNER_SUCCESS = "GET_LINKEDIN_BANNER_SUCCESS";
export const GET_LINKEDIN_BANNER_FAILURE = "GET_LINKEDIN_BANNER_FAILURE";

export const UPDATE_LINKEDIN_BANNER_REQUEST = "UPDATE_LINKEDIN_BANNER_REQUEST";
export const UPDATE_LINKEDIN_BANNER_SUCCESS = "UPDATE_LINKEDIN_BANNER_SUCCESS";
export const UPDATE_LINKEDIN_BANNER_FAILURE = "UPDATE_LINKEDIN_BANNER_FAILURE";
export const RESET_LINKEDIN_BANNER_STATE = "RESET_LINKEDIN_BANNER_STATE";

export const FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST =
  "FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST";
export const FETCH_JOBSEEKER_PUBLIC_PROFILE_SUCCESS =
  "FETCH_JOBSEEKER_PUBLIC_PROFILE_SUCCESS";
export const FETCH_JOBSEEKER_PUBLIC_PROFILE_FAILURE =
  "FETCH_JOBSEEKER_PUBLIC_PROFILE_FAILURE";

export const CANDIDATE_SAVE_FOR_LATER_REQUEST =
  "CANDIDATE_SAVE_FOR_LATER_REQUEST";
export const CANDIDATE_SAVE_FOR_LATER_SUCCESS =
  "CANDIDATE_SAVE_FOR_LATER_SUCCESS";
export const CANDIDATE_SAVE_FOR_LATER_FAILURE =
  "CANDIDATE_SAVE_FOR_LATER_FAILURE";
export const CANDIDATE_SAVE_FOR_LATER_RESET = "CANDIDATE_SAVE_FOR_LATER_RESET";

export const FETCH_SAVED_CANDIDATES_REQUEST = "FETCH_SAVED_CANDIDATES_REQUEST";
export const FETCH_SAVED_CANDIDATES_SUCCESS = "FETCH_SAVED_CANDIDATES_SUCCESS";
export const FETCH_SAVED_CANDIDATES_FAILURE = "FETCH_SAVED_CANDIDATES_FAILURE";
export const RESET_SAVED_CANDIDATES = "RESET_SAVED_CANDIDATES";
export const DELETE_SAVED_CANDIDATE_REQUEST = "DELETE_SAVED_CANDIDATE_REQUEST";
export const DELETE_SAVED_CANDIDATE_SUCCESS = "DELETE_SAVED_CANDIDATE_SUCCESS";
export const DELETE_SAVED_CANDIDATE_FAILURE = "DELETE_SAVED_CANDIDATE_FAILURE";

// src/redux/joinGroup/joinGroupActionTypes.js

export const JOIN_GROUP_REQUEST = "JOIN_GROUP_REQUEST";
export const JOIN_GROUP_SUCCESS = "JOIN_GROUP_SUCCESS";
export const JOIN_GROUP_FAILURE = "JOIN_GROUP_FAILURE";
export const JOIN_GROUP_RESET = "JOIN_GROUP_RESET";
