import {
  UPDATE_COMPANY_PROFILE_REQUEST,
  UPDATE_COMPANY_PROFILE_SUCCESS,
  UPDATE_COMPANY_PROFILE_FAILURE,
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAILURE,
  UPDATE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_LOGO_FAILURE,
  REMOVE_COMPANY_LOGO_REQUEST,
  REMOVE_COMPANY_LOGO_SUCCESS,
  REMOVE_COMPANY_LOGO_FAILURE,
  RESET_MESSAGE,
} from "../../actionTypes";

export const updateCompanyProfile = (data) => ({
  type: UPDATE_COMPANY_PROFILE_REQUEST,
  payload: data,
});

export const updateCompanyProfileSuccess = (profileData) => ({
  type: UPDATE_COMPANY_PROFILE_SUCCESS,
  payload: profileData,
});

export const updateCompanyProfileFailure = (error) => ({
  type: UPDATE_COMPANY_PROFILE_FAILURE,
  payload: error,
});

export const fetchCompanyProfile = () => ({
  type: FETCH_COMPANY_PROFILE_REQUEST,
});

export const fetchCompanyProfileSuccess = (profileData) => ({
  type: FETCH_COMPANY_PROFILE_SUCCESS,
  payload: profileData,
});

export const fetchCompanyProfileFailure = (error) => ({
  type: FETCH_COMPANY_PROFILE_FAILURE,
  payload: error,
});

export const updateCompanyLogo = (avatarData) => ({
  type: UPDATE_COMPANY_LOGO_REQUEST,
  payload: avatarData,
});

export const updateCompanyLogoSuccess = (avatar) => ({
  type: UPDATE_COMPANY_LOGO_SUCCESS,
  payload: avatar,
});

export const updateCompanyLogoFailure = (error) => ({
  type: UPDATE_COMPANY_LOGO_FAILURE,
  payload: error,
});

export const removeCompanyLogoRequest = () => ({
  type: REMOVE_COMPANY_LOGO_REQUEST,
});

export const removeCompanyLogoSuccess = (message) => ({
  type: REMOVE_COMPANY_LOGO_SUCCESS,
  payload: message,
});

export const removeCompanyLogoFailure = (error) => ({
  type: REMOVE_COMPANY_LOGO_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetMessage = () => ({
  type: RESET_MESSAGE,
});
