// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";

import { GENERATE_LINKEDIN_BANNER_REQUEST } from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import {
  generateLinkedInBannerSuccess,
  generateLinkedInBannerFailure,
} from "./actions";

function* generateLinkedInBanner(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(
      () => AxiosInstance().post(`/fetch_linkedin_banner`, action.payload)

      //recruiter/manage_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}
    );
    if (response.data.status) {
      yield put(generateLinkedInBannerSuccess(response.data));
    } else {
      yield put(generateLinkedInBannerFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(
      generateLinkedInBannerFailure({ status: false, message: error.message })
    );
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* generateLinkedinBannerSaga() {
  yield takeLatest(GENERATE_LINKEDIN_BANNER_REQUEST, generateLinkedInBanner);
}

export default generateLinkedinBannerSaga;
