// dashboardReducer.js
import {
  FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST,
  FETCH_JOBSEEKER_DASHBOARD_DATA_SUCCESS,
  FETCH_JOBSEEKER_DASHBOARD_DATA_FAILURE,
  RESET_DAILY,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
  is_daily_visit: 0,
};

const jobseekerDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_JOBSEEKER_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        is_daily_visit: action.payload.data.is_daily_visit,
        error: null,
      };
    case FETCH_JOBSEEKER_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case RESET_DAILY:
      return {
        ...state,
        is_daily_visit: 0,
      };

    default:
      return state;
  }
};

export default jobseekerDashboardReducer;
