import { Container } from "reactstrap";

import PublicProfile from "../../Components/Public/PublicProfile";
import { Fragment } from "react";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { useParams } from "react-router";

const PublicProfilePage = () => {
  const { cand_id, jobcad_id } = useParams();
  return (
    <Fragment>
      <Breadcrumbs title={"Profile"} mainTitle={"Profile"} />
      <Container fluid={true}>
        <PublicProfile />
      </Container>
    </Fragment>
  );
};

export default PublicProfilePage;
