import {
  FETCH_JOB_SOURCES_REQUEST,
  FETCH_JOB_SOURCES_SUCCESS,
  FETCH_JOB_SOURCES_FAILURE,
  DOWNLOAD_JOB_SOURCE_CSV_REQUEST,
  DOWNLOAD_JOB_SOURCE_CSV_SUCCESS,
  DOWNLOAD_JOB_SOURCE_CSV_FAILURE,
} from "../../actionTypes";

export const fetchJobSourcesRequest = (data) => {
  return {
    type: FETCH_JOB_SOURCES_REQUEST,
    payload: data,
  };
};
export const fetchJobSourcesSuccess = (jobSources) => {
  return {
    type: FETCH_JOB_SOURCES_SUCCESS,
    payload: jobSources,
  };
};

export const fetchJobSourcesFailure = (error) => {
  return {
    type: FETCH_JOB_SOURCES_FAILURE,
    payload: error,
  };
};

export const downloadJobSourceCsvRequest = (data) => {
  return {
    type: DOWNLOAD_JOB_SOURCE_CSV_REQUEST,
    payload: data,
  };
};
export const downloadJobSourceCsvSuccess = (jobSources) => {
  return {
    type: DOWNLOAD_JOB_SOURCE_CSV_SUCCESS,
    payload: jobSources,
  };
};

export const downloadJobSourceCsvFailure = (error) => {
  return {
    type: DOWNLOAD_JOB_SOURCE_CSV_FAILURE,
    payload: error,
  };
};
