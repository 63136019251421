import { call, put, takeLatest } from "redux-saga/effects";
import AxiosInstance from "../../../services/AxiosInstance"; // Import your Axios instance
import { FETCH_JOB_APPLICATIONS_REQUEST } from "../../actionTypes"; // Import your action types
import {
  fetchJobApplicationsSuccess,
  fetchJobApplicationsFailure,
} from "./actions";
function* fetchJobApplicationsSaga(action) {
  let url = `/recruiter/job_applications?page=${action.payload.page}&per_page=${action.payload.per_page}`;

  if (action.payload.column) {
    url += `&sortBy=${action.payload.column}`;
  }

  if (action.payload.direction) {
    url += `&direction=${action.payload.direction}`;
  }

  try {
    // Make the API request to fetch job applications
    const response = yield call(() =>
      AxiosInstance().post(url, action.payload.data)
    );
    if (response.data.status) {
      // Dispatch success action with the response data
      yield put(fetchJobApplicationsSuccess(response.data));
    } else {
      yield put(fetchJobApplicationsFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(
      fetchJobApplicationsFailure({ status: false, message: error.message })
    );
  }
}

// Watch for the FETCH_JOB_APPLICATIONS_REQUEST action and call the saga function
function* jobApplicationsSaga() {
  yield takeLatest(FETCH_JOB_APPLICATIONS_REQUEST, fetchJobApplicationsSaga);
}

export default jobApplicationsSaga;
