// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import {fetchIndustrySuccess,fetchIndustryFailure} from "./actions"

function* fetchIndustrySaga(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(() =>
      AxiosInstance().post("/fetch_industry_area", action.payload)
    );
    // Dispatch success action with the response data
    if(response.data.status){
      yield put(fetchIndustrySuccess(response.data));
    }else{
      yield put(fetchIndustryFailure(response.data));
    }
    
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchIndustryFailure({status:false,message:error.message}));
  }
}

// Watch for the latest FETCH_INDUSTRY_REQUEST action and call the saga
function* industryTypeSaga() {
  yield takeLatest(types.FETCH_INDUSTRY_REQUEST, fetchIndustrySaga);
}

export default industryTypeSaga;
