import { React, Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/update_profile/actions";

import {
  addWorkSampleRequest,
  resetData,
} from "../../../redux/jobseeker/work_sample/actions";

import DatePicker from "react-datepicker";
import { showToast } from "../../Common/Toast";

const Worksamplemodal = (props) => {
  console.log("props", props);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  /**
   * set default form field values
   */
  const [formData, setFormData] = useState({
    work_sample_id: props.data.id || "",
    work_title: props.data.work_title || "",
    work_url: props.data.work_url || "",
    work_start: props.data.work_start ? new Date(props.data.work_start) : null,
    work_end: props.data.work_end ? new Date(props.data.work_end) : null,
    work_description: props.data.work_description || "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);

    if (validate()) {
      // Format the data before logging or dispatching

      const formattedData = {
        work_sample_id: formData.work_sample_id || "",
        work_title: formData.work_title || "",
        work_url: formData.work_url || "",
        work_start: formData.work_start || "",
        work_end: formData.work_end || "",
        work_description: formData.work_description || "",
      };

      dispatch(addWorkSampleRequest(formattedData));
    }
  };

  // validate form data

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.work_title) {
      errors.work_title = "Work title is required";
      isValid = false;
    }

    if (!formData.work_start) {
      errors.work_start = "Start date is required";
      isValid = false;
    }
    if (!formData.work_end) {
      errors.work_end = "End date is required";
      isValid = false;
    }

    if (!formData.work_description) {
      errors.work_description = "Work description is required";
      isValid = false;
    }
    // Regular expression for URL validation
    const urlRegex = new RegExp(
      "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
    );
    if (formData.work_url && !urlRegex.test(formData.work_url)) {
      errors.work_url = "Please enter a valid URL";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  //handle onchange on form fields to remove errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    // Clear the error message when input changes
    let error = "";
    setErrors({ ...errors, [name]: error });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addWorkSampleReducerData = useSelector(
    (state) => state.workSampleReducer
  );
  useEffect(() => {
    console.log("srd", addWorkSampleReducerData);
    if (
      addWorkSampleReducerData.status &&
      addWorkSampleReducerData.message != ""
    ) {
      props.toggleModal();
    } else if (
      addWorkSampleReducerData.status == false &&
      addWorkSampleReducerData.message != ""
    ) {
      props.toggleModal();
    }
  }, [addWorkSampleReducerData]);

  // handle date chage
  const handleEndDateChange = (date) => {
    const updatedErrors = { ...errors, work_end: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, work_end: date });
  };

  const handleStartDateChange = (date) => {
    const updatedErrors = { ...errors, work_start: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, work_start: date });
  };

  console.log(props.data);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader toggle={props.toggleModal} className="border-0">
          <h4> {props.data.id ? "Edit Work Sample" : "Add Work Sample"}</h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Work Title<span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="work_title"
                  className="form-control form-control-sm"
                  placeholder="Enter work title"
                  onChange={handleChange}
                  value={formData.work_title}
                />
                {errors.work_title && (
                  <small className="text-danger">{errors.work_title}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Start Date<span className="text-danger">*</span>
                </Label>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  className="form-control form-control-sm mb-0"
                  selected={formData.work_start}
                  onChange={handleStartDateChange}
                  // dateFormat="yyyy-MM-dd"
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Enter start date"
                  dropdownMode="select"
                />
                {errors.work_start && (
                  <small className="text-danger">{errors.work_start}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  End Date<span className="text-danger">*</span>
                </Label>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  className="form-control form-control-sm mb-0"
                  selected={formData.work_end}
                  onChange={handleEndDateChange}
                  // dateFormat="yyyy-MM-dd"
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Enter end date"
                  dropdownMode="select"
                />
                {errors.work_end && (
                  <small className="text-danger">{errors.work_end}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Work URL</Label>
                <Input
                  type="text"
                  name="work_url"
                  className="form-control form-control-sm"
                  placeholder="Enter work URL"
                  onChange={handleChange}
                  defaultValue={formData.work_url}
                />
                {errors.work_url && (
                  <small className="text-danger">{errors.work_url}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>
                  Description<span className="text-danger">*</span>
                </Label>
                <Input
                  type="textarea"
                  name="work_description"
                  rows="7"
                  placeholder="Enter work description"
                  onChange={handleChange}
                  defaultValue={formData.work_description}
                />
                {errors.work_description && (
                  <small className="text-danger">
                    {errors.work_description}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            disabled={addWorkSampleReducerData.isLoading}
            color="primary"
            type="submit"
          >
            {props.data.id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default Worksamplemodal;
