import { takeLatest, call, put } from "redux-saga/effects";
import { EXTEND_JOB_EXPIRE_DATE_REQUEST } from "../../actionTypes";

import {
  extendJobExpireDateSuccess,
  extendJobExpireDateFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* extendJobExpireDate(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/extend_job", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(extendJobExpireDateSuccess(response.data));
    } else {
      yield put(extendJobExpireDateFailure(response.data));
    }
  } catch (error) {
    yield put(
      extendJobExpireDateFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* extendJobExpireDateSaga() {
  yield takeLatest(EXTEND_JOB_EXPIRE_DATE_REQUEST, extendJobExpireDate);
}
export default extendJobExpireDateSaga;
