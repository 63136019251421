import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { capitalize, formatDate } from "../../Components/Common/CommonFunction";
import { Trash2 } from "react-feather";
import { showSweetAlert } from "../../Components/Common/SweetAlert";
import { showToast } from "../../Components/Common/Toast";
import DataTable from "react-data-table-component";
import { unslugAndCapitalize } from "../../Components/Common/CommonFunction";
import Spinner from "../../Components/Common/Spinner";
import SavedJobs from "../../Components/Jobseeker/Jobs/SavedJobs";
import { goToPassbookRequest } from "../../redux/goToPassbook/actions";
import NoDataFound from "../../Components/Common/NoDataFound";
import { customStyles, getPassbookStatusColor } from "../../Constant";
// import CardsClass from './Cards';
import { useRef } from "react";
const CustomHeader = ({ cell }) => (
  <th style={{ fontWeight: "bold" }}>{cell.label}</th>
);
const GoToPassbook = () => {
  const dispatch = useDispatch();
  const { type } = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = async (column, direction) => {
    try {
      const page = currentPage;
      const per_page = perPage;
      const short_by = column.short_column;
      const column_direction = direction;
      const data = {
        user_type: "JOBSEEKER",
      };
      dispatch(
        goToPassbookRequest(page, per_page, data, short_by, column_direction)
      );
    } catch (error) {
      console.error("Error fetching sorted data:", error);
    }
  };

  const tableColumns = [
    {
      name: "#",
      short_column: "id",
      cell: (row, index) => index + 1,
      width: "60px",
      sortable: true,
      center: false,
    },

    {
      name: "Type",
      cell: (row) => (row.type ? unslugAndCapitalize(row.type) : "-"),
      sortable: false,
      center: true,
    },

    {
      name: "Points",
      cell: (row) => (row.points ? capitalize(row.points) : "-"),
      sortable: false,
      center: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span className={`badge ${getPassbookStatusColor(row.status)}`}>
          {unslugAndCapitalize(row.status)}
        </span>
      ),
      sortable: false,
      center: true,
    },
    {
      name: "Created At",
      short_column: "created_at",
      cell: (row) => formatDate(row.created_at),
      //  selector: (row) => formatDate(row.created_at),
      sortable: true,
      center: false,
    },
  ];

  useEffect(() => {
    const data = {
      user_type: "JOBSEEKER",
    };
    dispatch(goToPassbookRequest(currentPage, perPage, data));
  }, [currentPage, perPage]);

  const goToPassbookReducerData = useSelector(
    (state) => state.goToPassbookReducer
  );
  const goToPassbookData = goToPassbookReducerData.data.data || [];

  return (
    <Fragment>
      <Breadcrumbs title="Passbook" mainTitle="Passbook" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="px-0 py-3 ">
                <DataTable
                  customStyles={customStyles}
                  data={goToPassbookData}
                  columns={tableColumns}
                  keyField="id"
                  striped={true}
                  center={true}
                  pagination
                  paginationServer
                  paginationTotalRows={goToPassbookReducerData.data.total}
                  paginationPerPage={perPage}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  headerComponent={CustomHeader}
                  progressPending={goToPassbookReducerData.isLoading}
                  progressComponent={<Spinner />}
                  noDataComponent={<NoDataFound message={"No Data Found"} />}
                  onSort={(column, direction) => handleSort(column, direction)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GoToPassbook;
