import React, { Fragment, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Button, Badge } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { ArrowRight } from "react-feather";
import whatsapp from "../../assets/images/Icons/whatsapp.png";
import facebook from "../../assets/images/Icons/facebook.png";
import twitter from "../../assets/images/Icons/twitter.png";
import linkedin from "../../assets/images/Icons/linkedin.png";
import { Link } from "react-router-dom";
import { fetchReferralRequest } from "../../redux/recruiter/referral/actions";
import {
  handleCopyToClipboard,
  showStyledCoins,
  styleTotalCoins,
} from "../../Components/Common/CommonFunction";
import ReferralCoin from "../../assets/images/refferral-coin.svg";
import Ref from "../../assets/images/ref.png";
import { fetchRewardsPointsRequest } from "../../redux/fetch_rewards_points/actions";
import { AuthContext } from "../../Auth/AuthContext";
import { COINS } from "../../Constant";
const RecruiterReferralPage = () => {
  const dispatch = useDispatch();
  const { totalPoints } = useContext(AuthContext);
  useEffect(() => {
    dispatch(fetchReferralRequest()); // Fetch total referral coins when component mounts
  }, []);

  const recruiterRefferalData = useSelector(
    (state) => state.referralRecruiterReducer
  );

  const recruiter_id = recruiterRefferalData.data.referal_code;

  const referralLink = `${window.location.origin}${process.env.PUBLIC_URL}/recruiter/auth/register/${recruiter_id}`;
  console.log("dynamic refeerral link", referralLink);

  const shareOnSocialMedia = (platform) => {
    // Logic to share referral link on selected social media platform
    let shareUrl = "";
    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=Referral | Naukrify –${referralLink}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer.php?u=${referralLink}`;
        break;
      case "twitter":
        shareUrl = `http://twitter.com/share?text=${referralLink}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${referralLink}`;
        break;
      default:
        break;
    }
    window.open(shareUrl, "_blank");
  };
  const fetchRewardsPointsReducerData = useSelector(
    (state) => state.fetchRewardsPointsReducer
  );

  useEffect(() => {
    if (
      !fetchRewardsPointsReducerData ||
      !fetchRewardsPointsReducerData.data ||
      !Array.isArray(fetchRewardsPointsReducerData.data) ||
      fetchRewardsPointsReducerData.data.length <= 0
    ) {
      dispatch(fetchRewardsPointsRequest());
    }
  }, []);

  const recruiterReferralPoints =
    fetchRewardsPointsReducerData?.data?.find(
      (item) => item.type === "recruiter_referral"
    )?.points ?? 0;

  const verifyEmailPoints =
    fetchRewardsPointsReducerData?.data?.find(
      (item) => item.type === "verify_email"
    )?.points ?? 0;

  const shareJobsPoints =
    fetchRewardsPointsReducerData?.data?.find(
      (item) => item.type === "share_jobs"
    )?.points ?? 0;

  const postJobPoints =
    fetchRewardsPointsReducerData?.data?.find(
      (item) => item.type === "post_jobs"
    )?.points ?? 0;

  return (
    <Fragment>
      <Breadcrumbs title="Referral" mainTitle="Referral" />
      <Container fluid={true}>
        <Row className="justify-content-center">
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={5} xs={12}>
                    <div>
                      <img
                        src={Ref}
                        className="img-fluid referral-program"
                        alt="Referral"
                        style={{ float: "left" }}
                      />
                    </div>
                  </Col>
                  <Col md={7} xs={12}>
                    <h4 className="mt-3 mb-4">
                      Invite Your Friends &amp; Earn upto{" "}
                      {showStyledCoins(recruiterReferralPoints)} for each
                      referral
                    </h4>
                    <p className="res-width">
                      Invite your friends to unlock exciting rewards. Unlock
                      additional rewards when you share a job with your social
                      network.
                    </p>
                    <p className="mt-4">Share your referral link</p>
                    <div className="body">
                      <h6>
                        <span
                          className="border border-secondary  p-1 "
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "50px",
                          }}
                        >
                          <a
                            href={referralLink}
                            className="ref-link"
                            id="p1"
                            style={{
                              flex: "1",
                              display: "block",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              paddingLeft: "7px",
                            }}
                          >
                            {referralLink}
                          </a>
                          <Button
                            onClick={() => handleCopyToClipboard(referralLink)}
                            className="buttonss ml-2"
                            style={{ flexShrink: "1", borderRadius: "50px" }}
                          >
                            COPY
                          </Button>
                        </span>
                      </h6>
                    </div>
                    <div className="refferal-description mt-4 me-2 d-flex flex-column align-items-center">
                      <div className="share-links text-center ">
                        <span className="share-link me-2 ">
                          {" "}
                          <strong style={{ margin: "0 5px" }}>OR</strong>
                        </span>
                        <br />
                        <br />

                        {/*  icons starting */}
                        <img
                          src={whatsapp}
                          className="share-link share_refferal me-2"
                          onClick={() => shareOnSocialMedia("whatsapp")}
                          aria-label="Whatsapp"
                          alt="whatsapp"
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }} // Adjust the size as needed
                        />

                        <img
                          src={facebook}
                          className="share-link share_refferal me-2"
                          onClick={() => shareOnSocialMedia("facebook")}
                          aria-label="Facebook"
                          alt="facebook"
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }} // Adjust the size as needed
                        />

                        <img
                          src={twitter}
                          className="share-link share_refferal me-2"
                          onClick={() => shareOnSocialMedia("twitter")}
                          aria-label="Twitter"
                          alt="twitter"
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }} // Adjust the size as needed
                        />

                        <img
                          src={linkedin}
                          className="share-link share_refferal me-2"
                          onClick={() => shareOnSocialMedia("linkedin")}
                          aria-label="Linkedin"
                          alt="linkedin"
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }} // Adjust the size as needed
                        />

                        {/*  icons ends */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                      <Badge color="light" pill className="hover-badge">
                        <div className="badge-content d-flex align-items-center justify-content-between flex-column flex-sm-row">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <img
                              src={ReferralCoin}
                              className="refferral-coin mb-2 mb-sm-0 me-sm-3"
                              alt="Referral Coins"
                              width={20}
                            />
                            <div className="text-center text-sm-start d-flex justify-content-between align-items-center">
                              <span
                                className="text-uppercase"
                                style={{ fontWeight: "600", color: "#212529" }}
                              >
                                TOTAL REFERRAL {COINS}
                              </span>
                              &nbsp;
                              <span
                                style={{
                                  color: "#007BFF",
                                  fontSize: "15px",
                                  display: "block",
                                }}
                              >
                                {styleTotalCoins(totalPoints)}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ marginRight: "10px" }}>
                              {" "}
                              {/* Adjust the space as needed */}
                              &nbsp; &nbsp; {/* Insert space */}
                            </div>
                            <Link
                              to={`${process.env.PUBLIC_URL}/recruiter/go_to_passbook`}
                            >
                              <Badge
                                color="white"
                                className="text-dark rounded-pill d-flex align-items-center"
                                style={{ width: "120px", fontSize: "12px" }}
                              >
                                Go to Passbook{" "}
                                <ArrowRight
                                  color="black "
                                  size={30}
                                  className="ms-1"
                                />
                              </Badge>
                            </Link>
                          </div>
                        </div>
                      </Badge>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="refferal-description">
                    <h4 className="mt-4" style={{ fontWeight: "600" }}>
                      How it works?
                    </h4>
                    <p style={{ fontWeight: "500" }}>
                      Do you want free referral coins!
                    </p>

                    <p>Yeah I do!</p>
                    <p>Here's how:</p>
                    <ul>
                      <li>
                        Share a referral URL and after successful registration &
                        email verification of your friend, you will gain{" "}
                        {showStyledCoins(recruiterReferralPoints)}.
                      </li>
                      <li>
                        When you verify your email you will gain{" "}
                        {showStyledCoins(verifyEmailPoints)}.
                      </li>
                      <li>
                        Share a job by the social button given in the job detail
                        page to gain {showStyledCoins(shareJobsPoints)}.
                      </li>
                    </ul>
                    <p>Where to use it:</p>
                    <ul>
                      <li>
                        Use the coins to post a job for just{" "}
                        {showStyledCoins(postJobPoints)}.
                      </li>
                    </ul>
                    <i>
                      *Limit 3 for each platform ( Twitter / Facebook / WhatsApp
                      / Linkedin ) for earning points, no limits on sharing.
                    </i>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RecruiterReferralPage;
