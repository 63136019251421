import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  Home,
  Edit,
  CheckSquare,
  File,
  Users,
  Columns,
  LogOut,
} from "react-feather";
import { Button } from "reactstrap";
import { AuthContext } from "../../../Auth/AuthContext";
import { GoCrossReference } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { FaRegFileLines } from "react-icons/fa6";
import { FaBookmark } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { FiExternalLink } from "react-icons/fi";
import { FiFileText } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { FiMaximize } from "react-icons/fi";

const SidebarMenuItems = () => {
  const { logout } = useContext(AuthContext);
  return (
    <>
      <Fragment>
        <li className="sidebar-list">
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/dashboard`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <Home />
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/update_profile`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiUsers />
            <span>Update Profile</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/applied_Jobs`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <CheckSquare />
            <span>Applied Jobs</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/saved_jobs`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiSave />
            <span>Saved Jobs</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/recommended_jobs`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <Columns />
            <span>Recommended Jobs</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/job_search`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiSearch />
            <span>Search Job</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/referral`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiExternalLink />
            <span>Referral</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/generate_resume`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiFileText />
            <span>Generate Resumes</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/ai_linkedin_banner`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiFileText />
            <span>AI Linkedin Banner</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/jobseeker/resume_scan`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiMaximize />
            <span>Resume Scan</span>
          </NavLink>
        </li>
        <li className="sidebar-main-title p-0"></li>
        <li className="sidebar-list">
          <a
            href="#"
            className={`sidebar-link sidebar-title link-nav`}
            onClick={logout}
          >
            <LogOut />
            <span>Logout</span>
          </a>
        </li>
      </Fragment>
    </>
  );
};

export default SidebarMenuItems;
