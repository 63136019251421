import React, { Fragment, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import RegisterFrom from "./RegisterFrom";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { USER_TYPES } from "../../Constant";
import Cookies from "universal-cookie"; // Import Cookies as default
const Register = () => {
  const { type, id, token } = useParams();

  const navigate = useNavigate();
  const cookies = new Cookies();

  const isValidLoginType = USER_TYPES.find(
    (loginType) => loginType === type.toUpperCase()
  );

  const getDomain = () => {
    const hostname = window.location.hostname;
    if (hostname === "localhost") {
      return "localhost"; // for local development
    } else if (hostname.endsWith("naukrify.com")) {
      return ".naukrify.com"; // for naukrify.com subdomains
    }
    return hostname;
  };

  useEffect(() => {
    if (id) {
      if (!isValidLoginType) {
        navigate("/404");
      } else {
        cookies.set("ref_code", id, {
          path: "/",
          domain: getDomain(),
          sameSite: "None",
          secure: true,
        });
        cookies.set("ref_type", type, {
          path: "/",
          domain: getDomain(),
          sameSite: "None",
          secure: true,
        });
      }
    }
  }, [id, type, isValidLoginType, navigate, cookies]);

  return (
    <Fragment>
      <section>
        <Container fluid={true} className="p-0">
          <Row className="m-0">
            <Col
              xl="5"
              className="b-center bg-size"
              style={{
                backgroundImage: `url(${require("../../assets/images/login/office.jpg")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "block",
              }}
            >
              {/* <img className='bg-img-cover bg-center d-none' src={require('../../assets/images/login/office.jpg')} alt='looginpage' /> */}
            </Col>
            <Col xl="7 p-0">
              <RegisterFrom logoClassMain="text-start" />
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </Fragment>
  );
};

export default Register;
