import { React, Fragment, useEffect, useCallback, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocationsRequest } from "../../../redux/location/actions";
import { fetchCountryRequest } from "../../../redux/country/actions";
import { showToast } from "../../Common/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { customOptionsStyle, yearsOptions } from "../../../Constant";
import { updatePersonalInfo } from "../../../redux/jobseeker/update_profile/actions";
import {
  fetchJobseekerDataRequest,
  resetData,
} from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  yesnoOptions,
  genderOptions,
  maritalStatusOptions,
  categoryOptions,
  readOnlyStyle,
  jobTypeOptions,
  jobShiftOptions,
  monthsOptions,
  experienceOptions,
} from "../../../Constant";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import styles directly from react-phone-input-2
import validator from "validator";
import { convertNumberToWords } from "../../Common/CommonFunction";
const Editpersonal = (props) => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  /**
   * set default form field values
   */

  const [locationOptions, setLocationOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const profileReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer
  );
  let profileData = [];
  if (!profileReducerData.isLoading && profileReducerData.data.data) {
    profileData = profileReducerData.data.data;
  }

  const [formData, setFormData] = useState({
    name:
      (profileData.jobseeker_full_name && profileData.jobseeker_full_name) ||
      "",
    country_code1:
      (profileData.country_code1 && profileData.country_code1) || "91",
    country_code2:
      (profileData.country_code2 && profileData.country_code2) || "",
    jobseeker_phone1:
      (profileData.jobseeker_phone1 && profileData.jobseeker_phone1) || "",
    jobseeker_email1:
      (profileData.jobseeker_email1 && profileData.jobseeker_email1) || "",
    jobseeker_email2:
      (profileData.jobseeker_email2 && profileData.jobseeker_email2) || "",
    jobseeker_phone2:
      (profileData.jobseeker_phone2 && profileData.jobseeker_phone2) || "",
    location:
      (profileData.jobseeker_location && profileData.jobseeker_location) || "",

    date_of_birth: profileData.jobseeker_date_of_birth
      ? new Date(profileData.jobseeker_date_of_birth)
      : null,

    paddress:
      (profileData.jobseeker_permanent_address &&
        profileData.jobseeker_permanent_address) ||
      "",
    jobseeker_gender:
      (profileData.jobseeker_gender &&
        genderOptions.find(
          (option) => option.value == profileData.jobseeker_gender
        )) ||
      null,

    pincode:
      (profileData.jobseeker_pincode && profileData.jobseeker_pincode) || "",
    marital_status:
      maritalStatusOptions.find((option) => {
        if (option.value == profileData.jobseeker_marital_status) {
          return option;
        }
      }) || "",
    home_town:
      (profileData.jobseeker_hometown && profileData.jobseeker_hometown) || "",

    jobseeker_work_permit_for_other_countries:
      (profileData.jobseeker_work_permit_for_other_countries &&
        profileData.jobseeker_work_permit_for_other_countries) ||
      "",
    differently_abled:
      yesnoOptions.find((option) => {
        if (option.value == profileData.jobseeker_differently_abled) {
          return option;
        }
      }) || "",
    total_exp_years:
      experienceOptions.find((option) => {
        if (option.value == profileData.total_exp_years) {
          return option;
        }
      }) || "",
    total_exp_months:
      monthsOptions.find((option) => {
        if (option.value == profileData.total_exp_months) {
          return option;
        }
      }) || "",

    jobseeker_notice_period: profileData.jobseeker_notice_period,
    jobseeker_preferred_location:
      (profileData.jobseeker_preferred_location &&
        profileData.jobseeker_preferred_location) ||
      "",
    jobseeker_preferred_salary: profileData.jobseeker_preferred_salary || "",
    jobseeker_current_salary: profileData.jobseeker_current_salary || "",
    jobseeker_preferred_job_type:
      jobTypeOptions.find(
        (option) => option.value == profileData.jobseeker_preferred_job_type
      ) || null,
    jobseeker_preferred_shift:
      jobShiftOptions.find(
        (option) => option.value == profileData.jobseeker_preferred_shift
      ) || null,
    relevant_experience: profileData.relevant_experience || "",
  });

  // handle date chage
  const handleDateChange = (date) => {
    const updatedErrors = { ...errors, date_of_birth: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, date_of_birth: date });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const data = {
        jobseeker_full_name: formData.name || "",
        country_code1: formData.country_code1 || "",
        country_code2: formData.country_code2 || "",
        jobseeker_phone1: formData.jobseeker_phone1 || "",
        jobseeker_phone2: formData.jobseeker_phone2 || "",
        jobseeker_email1: formData.jobseeker_email1 || "",
        jobseeker_email2: formData.jobseeker_email2 || "",
        jobseeker_location: formData.location ? formData.location.value : "",
        jobseeker_preferred_location: formData.jobseeker_preferred_location
          ? formData.jobseeker_preferred_location
              .map((loc) => loc.value)
              .join(",")
          : "",
        total_exp_years: formData.total_exp_years
          ? formData.total_exp_years.value
          : "",
        total_exp_months: formData.total_exp_months
          ? formData.total_exp_months.value
          : "",
        jobseeker_pincode: formData.pincode || "",
        jobseeker_notice_period: formData.jobseeker_notice_period || "",
        jobseeker_preferred_salary: formData.jobseeker_preferred_salary || "",
        jobseeker_current_salary: formData.jobseeker_current_salary || "",
        jobseeker_date_of_birth: formData.date_of_birth || "",
        jobseeker_permanent_address: formData.paddress || "",
        jobseeker_gender: formData.jobseeker_gender
          ? formData.jobseeker_gender.value
          : "",
        jobseeker_jobseeker_pincode: formData.pincode || "",
        jobseeker_marital_status: formData.marital_status
          ? formData.marital_status.value
          : "",
        jobseeker_hometown: formData.home_town || "",
        jobseeker_work_permit_for_other_countries:
          formData.jobseeker_work_permit_for_other_countries
            ? formData.jobseeker_work_permit_for_other_countries
                .map((permit) => permit.value)
                .join(",")
            : "",
        jobseeker_differently_abled: formData.differently_abled
          ? formData.differently_abled.value
          : "",
      };

      dispatch(updatePersonalInfo(data));
    }
  };

  // validate form data

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    const trimmedPhoneNumber = formData.jobseeker_phone1.trim();
    if (!trimmedPhoneNumber) {
      errors.jobseeker_phone1 = "Phone number is required";
      isValid = false;
    } else if (
      formData.country_code1 === "91" &&
      trimmedPhoneNumber.length !== 10
    ) {
      errors.jobseeker_phone1 = "Phone number must be 10 digits for india";
      isValid = false;
    } else if (isNaN(trimmedPhoneNumber)) {
      errors.jobseeker_phone1 = "Phone number must be a valid number";
      isValid = false;
    } else if (
      !validator.isMobilePhone(trimmedPhoneNumber, "any", { strictMode: false })
    ) {
      errors.jobseeker_phone1 = "Invalid phone number format";
      isValid = false;
    }

    const trimmedPhoneNumber2 = formData.jobseeker_phone2.trim();
    if (trimmedPhoneNumber2) {
      if (
        formData.country_code2 === "91" &&
        trimmedPhoneNumber2.length !== 10
      ) {
        errors.jobseeker_phone2 = "Phone number must be 10 digits for india";
        isValid = false;
      } else if (isNaN(trimmedPhoneNumber2)) {
        errors.jobseeker_phone2 = "Phone number must be a valid number";
        isValid = false;
      } else if (
        !validator.isMobilePhone(trimmedPhoneNumber2, "any", {
          strictMode: false,
        })
      ) {
        errors.jobseeker_phone2 = "Invalid phone number format";
        isValid = false;
      }
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      formData.jobseeker_email2 &&
      !emailRegex.test(formData.jobseeker_email2)
    ) {
      errors.jobseeker_email2 = "Please enter valid email";
      isValid = false;
    }

    if (!formData.location) {
      errors.location = "Current location is required";
      isValid = false;
    }

    if (!formData.jobseeker_preferred_location) {
      errors.location = "Preferred location is required";
      isValid = false;
    }

    if (!formData.date_of_birth) {
      errors.date_of_birth = "Date of birth is required";
      isValid = false;
    }

    if (!formData.paddress) {
      errors.paddress = "Permanent address is required";
      isValid = false;
    }

    if (!formData.jobseeker_gender) {
      errors.jobseeker_gender = "Gender is required";
      isValid = false;
    }

    // Validate pincode
    const pincode = formData.pincode.trim();
    if (!pincode) {
      errors.pincode = "Pincode is required";
      isValid = false;
    } else if (isNaN(pincode)) {
      errors.pincode = "Pincode must be a valid number";
      isValid = false;
    } else if (pincode.length !== 6) {
      // assuming a 6-digit pincode, adjust as necessary
      errors.pincode = "Pincode must be 6 digits long";
      isValid = false;
    }

    if (!formData.marital_status) {
      errors.marital_status = "Marital status is required";
      isValid = false;
    }

    if (!formData.home_town) {
      errors.home_town = "Home town is required";
      isValid = false;
    }

    if (!formData.differently_abled) {
      errors.differently_abled = "Differently abled is required";
      isValid = false;
    }

    if (!formData.total_exp_years && !formData.total_exp_months) {
      errors.total_exp_years =
        "Total experience (either in years or months) is required";
      isValid = false;
    }

    if (!formData.jobseeker_notice_period) {
      errors.jobseeker_notice_period = "Notice period is required";
      isValid = false;
    } else if (isNaN(formData.jobseeker_notice_period)) {
      errors.jobseeker_notice_period = "Notice period must be a number";
      isValid = false;
    }

    if (!formData.jobseeker_preferred_salary) {
      errors.jobseeker_preferred_salary = "Preferred CTC is required";
      isValid = false;
    } else if (isNaN(formData.jobseeker_preferred_salary)) {
      errors.jobseeker_preferred_salary = "Preferred CTC must be a number";
      isValid = false;
    }

    if (!formData.jobseeker_current_salary) {
      errors.jobseeker_current_salary = "Current CTC is required";
      isValid = false;
    } else if (isNaN(formData.jobseeker_current_salary)) {
      errors.jobseeker_current_salary = "Current CTC must be a number";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    setErrors({ ...errors, [name]: error });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGenderChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_gender: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, jobseeker_gender: selectedOption });
  };

  const handleDifferentlyAbledChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, differently_abled: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, differently_abled: selectedOption });
  };

  const handleMaritalStatusChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, marital_status: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, marital_status: selectedOption });
  };

  /**
   * location dropdown code start
   */
  //read location data from store
  let locationReducerData = useSelector((state) => state.locationReducer);
  let locationData = locationReducerData.data.data;

  useEffect(() => {
    locationData = locationData || [];
    setLocationOptions(locationData);
  }, [locationData]);

  const handleLocationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleLocationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Callback to handle location select change
  const handleLocationsChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, location: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, location: selectedOptions });
  };

  const totalYearExperienceChange = (selectedOptions) => {
    const updatedErrors = { ...errors, total_exp_years: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, total_exp_years: selectedOptions });
  };

  const totalMonthExperienceChange = (selectedOptions) => {
    const updatedErrors = { ...errors, total_exp_years: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, total_exp_months: selectedOptions });
  };

  /**
   * location dropdown code end
   */

  /**
   * country dropdown code start
   */
  //read country data from store
  let countryReducerData = useSelector((state) => state.countryReducer);
  let countryData = countryReducerData.data.data;

  useEffect(() => {
    countryData = countryData || [];
    setCountryOptions(countryData);
  }, [countryData]);

  const handleCountryFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here

    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchCountryRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleCountryInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchCountryRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Callback to handle location select change
  const handleCountryChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, work_permit: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({
      ...formData,
      jobseeker_work_permit_for_other_countries: selectedOptions,
    });
  };

  /**
   * country dropdown code end
   */
  const jobseekerProfileReducerData = useSelector(
    (state) => state.jobseekerProfileReducer
  );
  useEffect(() => {
    if (
      jobseekerProfileReducerData.status &&
      jobseekerProfileReducerData.message != ""
    ) {
      showToast("success", jobseekerProfileReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      props.toggleModal();
      dispatch(resetData());
    } else if (
      jobseekerProfileReducerData.status == false &&
      jobseekerProfileReducerData.message != ""
    ) {
      showToast("error", jobseekerProfileReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      props.toggleModal();
      dispatch(resetData());
    }
  }, [jobseekerProfileReducerData]);

  const handleCodeChange = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code1: country.dialCode,
    });
  };

  const handleCodeChange2 = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code2: country.dialCode,
    });
  };

  // Callback to handle location select change
  const handlePreferedLocationsChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_location: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, jobseeker_preferred_location: selectedOptions });
  };

  const handleJobTypeChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_job_type: "" };
    setErrors(updatedErrors);

    // Update formData directly
    setFormData({
      ...formData,
      jobseeker_preferred_job_type: selectedOption, // Set the selected value
    });
  };

  const handleJobShiftChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_shift: "" };
    setErrors(updatedErrors);
    console.log(selectedOption);

    // Update formData directly
    setFormData({
      ...formData,
      jobseeker_preferred_shift: selectedOption,
    });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4>Edit Personal Info</h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <h5 className="mb-3 text-purple">Personal Info</h5>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Name
                  <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  className="form-control form-control-sm"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <small className="text-danger">{errors.name}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="date_of_birth">
                  Date of Birth
                  <span className="font-danger">*</span>
                </Label>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  className="form-control form-control-sm mb-0"
                  selected={formData.date_of_birth}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Enter your date of birth"
                  dropdownMode="select"
                />
                {errors.date_of_birth && (
                  <small className="text-danger">{errors.date_of_birth}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Primary Email <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_email1"
                  className="form-control form-control-sm "
                  placeholder="Enter your email "
                  value={formData.jobseeker_email1}
                  readOnly // Set the readOnly attribute
                  style={readOnlyStyle}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Secondary Email </Label>
                <Input
                  type="text"
                  name="jobseeker_email2"
                  className="form-control form-control-sm"
                  placeholder="Enter second email"
                  value={formData.jobseeker_email2}
                  onChange={handleChange}
                />
                {errors.jobseeker_email2 && (
                  <small className="text-danger">
                    {errors.jobseeker_email2}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Primary Mobile <span className="text-danger">*</span>
                </Label>
                <div className="d-flex">
                  <PhoneInput
                    style={{
                      width: "10px",
                      marginRight: "10px",
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      disabled: true,
                      // placeholder: "Enter country code",
                    }}
                    country={"in"} // Default country
                    value={formData.country_code1}
                    onChange={handleCodeChange}
                    inputStyle={{
                      width: "100px", // Adjust width to fit country code
                      height: "38px",
                      //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                      paddingLeft: "3rem",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      color: "#495057",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }}
                    enableAreaCodes={false}
                  />
                  <Input
                    className="form-control form-control-sm"
                    placeholder="Enter your phone number"
                    id="jobseeker_phone1"
                    name="jobseeker_phone1"
                    value={formData.jobseeker_phone1}
                    onChange={handleChange}
                    style={{ marginLeft: "95px" }} // Add margin to separate the fields
                    maxLength={15} // Adjust the maximum length as per your requirement
                  />
                </div>

                {errors.jobseeker_phone1 && (
                  <small className="text-danger">
                    {errors.jobseeker_phone1}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Secondary Mobile</Label>
                <div className="d-flex">
                  <PhoneInput
                    style={{
                      width: "10px",
                      marginRight: "10px",
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      disabled: true,
                      // placeholder: "Enter country code",
                    }}
                    country={"in"} // Default country
                    value={formData.country_code2}
                    onChange={handleCodeChange2}
                    inputStyle={{
                      width: "100px", // Adjust width to fit country code
                      height: "38px",
                      //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                      paddingLeft: "3rem",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      color: "#495057",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }}
                    enableAreaCodes={false}
                  />
                  <Input
                    className="form-control form-control-sm"
                    placeholder="Enter second phone number"
                    id="jobseeker_phone2"
                    name="jobseeker_phone2"
                    value={formData.jobseeker_phone2}
                    onChange={handleChange}
                    style={{ marginLeft: "95px" }} // Add margin to separate the fields
                    maxLength={15} // Adjust the maximum length as per your requirement
                  />
                </div>

                {errors.jobseeker_phone2 && (
                  <small className="text-danger">
                    {errors.jobseeker_phone2}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Gender
                  <span className="font-danger">*</span>
                </Label>
                <Select
                  placeholder="Select gender"
                  options={genderOptions}
                  name="jobseeker_gender"
                  value={formData.jobseeker_gender}
                  onChange={handleGenderChange}
                />
                {errors.jobseeker_gender && (
                  <small className="text-danger">
                    {errors.jobseeker_gender}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Marital Status
                  <span className="font-danger">*</span>
                </Label>
                <Select
                  placeholder="Select marital status"
                  options={maritalStatusOptions}
                  name="marital_status"
                  value={formData.marital_status}
                  onChange={handleMaritalStatusChange}
                />
                {errors.marital_status && (
                  <small className="text-danger">{errors.marital_status}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="differently_abled">
                  Differently Abled
                  <span className="font-danger">*</span>
                </Label>
                <Select
                  placeholder="Select differently abled"
                  options={yesnoOptions}
                  name="differently_abled"
                  value={formData.differently_abled}
                  onChange={handleDifferentlyAbledChange}
                />
                {errors.differently_abled && (
                  <small className="text-danger">
                    {errors.differently_abled}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="address">
                  Permanent Address
                  <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="paddress"
                  className="form-control form-control-sm"
                  placeholder="Enter permanent address"
                  value={formData.paddress}
                  onChange={handleChange}
                />
                {errors.paddress && (
                  <small className="text-danger">{errors.paddress}</small>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="hometown">
                  Home Town
                  <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="home_town"
                  className="form-control form-control-sm"
                  placeholder="Enter home town"
                  value={formData.home_town}
                  onChange={handleChange}
                />
                {errors.home_town && (
                  <small className="text-danger">{errors.home_town}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Pincode
                  <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="pincode"
                  className="form-control form-control-sm"
                  placeholder="Enter pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
                {errors.pincode && (
                  <small className="text-danger">{errors.pincode}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <h5 className="mb-3 mt-2 text-purple">Professional Info</h5>
          <Row>
            <Col md={12}>
              <Label>
                Total Experience <span className="font-danger">*</span>
              </Label>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Select
                  options={experienceOptions}
                  value={formData.total_exp_years}
                  onChange={totalYearExperienceChange}
                  styles={customOptionsStyle}
                  placeholder="Select years"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Select
                  placeholder="Select month"
                  options={monthsOptions}
                  value={formData.total_exp_months}
                  onChange={totalMonthExperienceChange}
                />
              </FormGroup>
            </Col>
            {errors.total_exp_years && (
              <small className="text-danger">{errors.total_exp_years}</small>
            )}
            {/* <Col md="6">
              <FormGroup>
                <Label for="relevant_experience">
                  Relevant Experience (Months){" "}
                </Label>
                <Input
                  type="number"
                  name="relevant_experience"
                  className="form-control form-control-sm"
                  placeholder="Relevant experience"
                  onChange={handleChange}
                  value={formData.relevant_experience}
                />
                {errors.relevant_experience && (
                  <small className="text-danger">
                    {errors.relevant_experience}
                  </small>
                )}
              </FormGroup>
            </Col> */}

            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_salary">
                  Notice period <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_notice_period"
                  className="form-control form-control-sm"
                  placeholder="Enter notice period"
                  onChange={handleChange}
                  value={formData.jobseeker_notice_period}
                />
                {errors.jobseeker_notice_period && (
                  <small className="text-danger">
                    {errors.jobseeker_notice_period}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="work_permit_location">Foreign Work Permit</Label>
                <Select
                  name="jobseeker_work_permit_for_other_countries"
                  isMulti
                  options={countryOptions}
                  isLoading={countryReducerData.isLoading}
                  placeholder="Select country"
                  value={formData.jobseeker_work_permit_for_other_countries}
                  onChange={handleCountryChange}
                  onInputChange={handleCountryInputChange}
                  styles={customOptionsStyle}
                  onFocus={handleCountryFocus}
                />
              </FormGroup>
            </Col>
          </Row>
          <h5 className="mb-3 mt-2 text-purple">Location</h5>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Current Location <span className="font-danger">*</span>
                </Label>
                <Select
                  options={locationOptions}
                  isLoading={locationReducerData.isLoading}
                  placeholder="Select current locations"
                  value={formData.location}
                  onChange={handleLocationsChange}
                  onInputChange={handleLocationInputChange}
                  styles={customOptionsStyle}
                  onFocus={handleLocationFocus}
                />{" "}
                {errors.location && (
                  <small className="text-danger">{errors.location}</small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_location">
                  Preferred Location <span className="font-danger">*</span>
                </Label>
                <Select
                  isMulti
                  options={locationOptions}
                  isLoading={locationReducerData.isLoading}
                  placeholder="Select preferred locations"
                  value={formData.jobseeker_preferred_location}
                  onChange={handlePreferedLocationsChange}
                  onInputChange={handleLocationInputChange}
                  styles={customOptionsStyle}
                  onFocus={handleLocationFocus}
                />
                {errors.jobseeker_preferred_location && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_location}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <h5 className="mb-3 mt-2 text-purple">Salary</h5>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_current_salary">
                  Current CTC <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_current_salary"
                  className="form-control form-control-sm"
                  placeholder="Enter current ctc"
                  onChange={handleChange}
                  value={formData.jobseeker_current_salary}
                />
                {formData.jobseeker_current_salary &&
                  !isNaN(formData.jobseeker_current_salary) && (
                    <small className="text-muted text-capitalize">
                      {convertNumberToWords(
                        parseFloat(formData.jobseeker_current_salary)
                      )}
                    </small>
                  )}
                {errors.jobseeker_current_salary && (
                  <small className="text-danger">
                    {errors.jobseeker_current_salary}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_salary">
                  Expected CTC <span className="font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_preferred_salary"
                  className="form-control form-control-sm"
                  placeholder="Enter expected ctc"
                  onChange={handleChange}
                  value={formData.jobseeker_preferred_salary}
                />
                {formData.jobseeker_preferred_salary &&
                  !isNaN(formData.jobseeker_preferred_salary) && (
                    <small className="text-muted text-capitalize">
                      {convertNumberToWords(
                        parseFloat(formData.jobseeker_preferred_salary)
                      )}
                    </small>
                  )}
                {errors.jobseeker_preferred_salary && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_salary}
                  </small>
                )}
              </FormGroup>
            </Col>
            {/* <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_job_type">
                  Job Preferences
                </Label>
                <Select
                  options={jobTypeOptions}
                  value={formData.jobseeker_preferred_job_type}
                  placeholder="Select job type..."
                  onChange={handleJobTypeChange}
                  styles={customOptionsStyle}
                />
                {errors.jobseeker_preferred_job_type && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_job_type}
                  </small>
                )}
              </FormGroup>
            </Col> */}
            {/* <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_shift">Shift Preference</Label>
                <Select
                  options={jobShiftOptions}
                  value={formData.jobseeker_preferred_shift}
                  placeholder="Select job shift..."
                  onChange={handleJobShiftChange}
                  styles={customOptionsStyle}
                />
                {errors.jobseeker_preferred_shift && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_shift}
                  </small>
                )}
              </FormGroup>
            </Col> */}
          </Row>

          <Row>
            {/*
          
            <Col md={6}>
              <FormGroup>
                <Label for="differently_abled">
                  Differently Abled
                  <span className="font-danger">*</span>
                </Label>
                <Select
                  options={yesnoOptions}
                  name="differently_abled"
                  value={formData.differently_abled}
                  onChange={handleDifferentlyAbledChange}
                />
                {errors.differently_abled && (
                  <small className="text-danger">
                    {errors.differently_abled}
                  </small>
                )}
              </FormGroup>
          
              */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={props.toggleModal}
            disabled={jobseekerProfileReducerData.isLoading}
          >
            Close
          </Button>
          <Button color="primary" type="submit">
            Update
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default Editpersonal;
