import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Collapse,
  Input,
  Form,
} from "reactstrap";

// Import your action creator
import { fetchFunctionalAreaRequest } from "../../../../redux/functionalArea/actions";
import { JobSearchContext } from "../../../../JobSearch/JobSearchContext";
const FunctionalArea = () => {
  const dispatch = useDispatch();
  const functionalAreasReducerData =
    useSelector((state) => state.functionalAreaReducer) || [];

  let functionalAreasData = [];
  if (
    !functionalAreasReducerData.isLoading &&
    functionalAreasReducerData.data.data
  ) {
    functionalAreasData = functionalAreasReducerData.data.data;
  }

  const [isFilter, setIsFilter] = useState(true);
  const { filters, updateFilters } = useContext(JobSearchContext);
  const [searchKeyword, setSearchKeyword] = useState("");

  // console.log(checkedAreas);
  useEffect(() => {
    // Dispatch fetchFunctionalAreas when component mounts
    dispatch(fetchFunctionalAreaRequest());
  }, [dispatch]);

  // Handle input change for search
  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);

    // Dispatch fetchFunctionalAreas with the search keyword
    dispatch(fetchFunctionalAreaRequest(keyword));
  };

  // Handle checkbox change

  const handleCheckboxChange = (area) => {
    // Check if the option's value already exists in job_type array
    const existingOption = filters.functional_area.find(
      (item) => item.value === area.value
    );

    // Toggle the selected state for the clicked job type
    let updatedFunctionalArea = [];
    if (existingOption) {
      updatedFunctionalArea = filters.functional_area.filter(
        (item) => item.value !== area.value
      );
    } else {
      updatedFunctionalArea = [...filters.functional_area, area];
    }

    // Update the job types filter in the context with updated array
    updateFilters({ functional_area: updatedFunctionalArea });
  };

  // Merge and sort the functional areas to show selected ones first
  const mergedFunctionalAreasData = [...functionalAreasData];
  filters.functional_area.forEach((filterArea) => {
    if (
      !mergedFunctionalAreasData.some((area) => area.value === filterArea.value)
    ) {
      mergedFunctionalAreasData.push(filterArea);
    }
  });

  const sortedFunctionalAreasData = mergedFunctionalAreasData.sort((a, b) => {
    const isSelectedA = filters.functional_area.some(
      (item) => item.value === a.value
    );
    const isSelectedB = filters.functional_area.some(
      (item) => item.value === b.value
    );
    return isSelectedA === isSelectedB ? 0 : isSelectedA ? -1 : 1;
  });

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Functional Area</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="animate-chk border-0 ">
              <Form className="theme-form">
                <div className="job-filter mb-3">
                  <div className="faq-form">
                    <Input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Search functional area..."
                      value={searchKeyword}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Form>
              {functionalAreasReducerData.isLoading ? (
                <div>Loading...</div>
              ) : (
                <div
                  className="functional-area-checkbox custom-scrollbar"
                  style={{
                    maxHeight: "200px", // Adjust the maximum height as needed
                    overflowY: "auto",
                  }}
                >
                  {sortedFunctionalAreasData.length > 0 ? (
                    sortedFunctionalAreasData.map((area, index) => (
                      <label
                        key={index}
                        className="d-flex"
                        // htmlFor={`chk-ani${index}`}
                      >
                        <Input
                          className="checkbox_animated mr-2"
                          id={`chk-ani${index}`}
                          type="checkbox"
                          checked={filters.functional_area.some(
                            (item) => item.value === area.value
                          )}
                          onChange={() => handleCheckboxChange(area)}
                        />{" "}
                        {area.label}
                      </label>
                    ))
                  ) : (
                    <p>No data found</p>
                  )}
                </div>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FunctionalArea;
