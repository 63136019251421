// reducers.js
import {
  FETCH_RECRUITER_INVITES_REQUEST,
  FETCH_RECRUITER_INVITES_SUCCESS,
  FETCH_RECRUITER_INVITES_FAILURE,
  RESET_FETCH_RECRUITER_INVITES,
} from "../../actionTypes";

const initialState = {
  data: [], // Initialize data to null
  message: "",
  isLoading: false,
  status: false,
};

const fetchRecruiterInviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECRUITER_INVITES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RECRUITER_INVITES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status, // Set status from payload if needed
      };
    case FETCH_RECRUITER_INVITES_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        status: action.payload.status,
        message: action.payload.message,
      };

    case RESET_FETCH_RECRUITER_INVITES: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };
    default:
      return state;
  }
};

export default fetchRecruiterInviteReducer;
