import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
// import HeaderCard from '../../Common/Component/HeaderCard';
import DataTableComponent from "../../Components/Jobseeker/Jobs/DataTableComponent";

const AppliedJobsPage = () => {
  return (
    <Fragment>
      <Breadcrumbs title="Applied Jobs" mainTitle="Applied Jobs" />
      <Container fluid={true}>
        <DataTableComponent />
      </Container>
    </Fragment>
  );
};

export default AppliedJobsPage;
