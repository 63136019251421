import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
  Card,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { handleCopyToClipboard } from "../../Components/Common/CommonFunction";
import { fetchCompanyProfile } from "../../redux/recruiter/company_profile/actions";
import { LiaHandPointRight } from "react-icons/lia";

const CareerPage = () => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    // Handle form submission
    console.log(data);
  };

  const companyProfileReducerData = useSelector(
    (state) => state.companyProfileReducer
  );

  useEffect(() => {
    // Fetch company profile if not already available in the store
    if (!companyProfileReducerData.data) {
      dispatch(fetchCompanyProfile());
    }
  }, [dispatch, companyProfileReducerData.data]);

  let companyId;

  if (
    companyProfileReducerData.data &&
    companyProfileReducerData.isLoading != true
  ) {
    companyId = companyProfileReducerData.data.company_id;
  }

  return (
    <Fragment>
      <Breadcrumbs
        title="Career Page Integration"
        mainTitle="Career Page Integration"
      />
      <Container fluid={true}>
        {/* Your provided HTML code */}
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div style={{ display: "flex" }}>
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "32px", height: "32px" }}
                    width="512.000000pt"
                    height="512.000000pt"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      {/* SVG content */}
                    </g>
                  </svg>
                  <h3
                    style={{
                      marginTop: "2px",
                      marginLeft: "5px",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Revolutionize Your Company Website with Our Hassle-Free
                    Career Board Integration
                  </h3>
                </div>
                <div style={{ marginLeft: "38px", marginTop: "15px" }}>
                  <b>Benefits:</b>
                  <ul>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <LiaHandPointRight
                        style={{
                          marginRight: "5px",
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      <span>Seamless Integration:</span> Say goodbye to manual
                      job updates...
                    </li>
                    {/* ... other list items */}
                  </ul>
                </div>
                <div style={{ marginLeft: "38px" }}>
                  <b>Enhanced Visibility:</b>
                  <ul>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <LiaHandPointRight
                        style={{
                          marginRight: "5px",
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      Boost your online presence and attract top talent
                      effortlessly. Simplify your hiring process and save
                      valuable time.
                    </li>
                  </ul>
                </div>
                <div style={{ marginLeft: "38px" }}>
                  <b>User-Friendly:</b>
                  <ul>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <LiaHandPointRight
                        style={{
                          marginRight: "5px",
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      No coding expertise required. It's as easy as copy and
                      paste!
                    </li>
                  </ul>
                </div>
                <div style={{ marginLeft: "38px" }}>
                  <b>Motivated Candidates:</b>
                  <ul>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <LiaHandPointRight
                        style={{
                          marginRight: "5px",
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      Engage job seekers with an up-to-date career board.
                    </li>
                  </ul>
                </div>
                <div style={{ marginLeft: "38px" }}>
                  <b>Sample Link &amp; Screenshot:</b>
                  <ul>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      See it in action! Visit www.yourcompany.com/careers to
                      witness the seamless integration.
                    </li>
                  </ul>
                </div>
                <div style={{ marginLeft: "38px" }}>
                  <b>Get Started in 3 Easy Steps:</b>
                  <ul>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <LiaHandPointRight
                        style={{
                          marginRight: "5px",
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      <b>Copy:</b> Copy the provided iframe code.
                    </li>
                  </ul>
                  <br />
                  <Row>
                    <Col xs="12" xl="8">
                      <div className="embed-box">
                        {/* <code className="txt">
                          &lt;iframe src=`${process.env.PUBLIC_URL}{" "}
                          /public/career` frameborder="0" style="height:600px;
                          width:1200px;"&gt;&lt;/iframe&gt;
                          <br />
                        </code> */}
                        <code className="txt">
                          &lt;iframe src=
                          {`${window.origin}${process.env.PUBLIC_URL}/public/career/${companyId}`}{" "}
                          frameborder="0" className="iframeStyles"
                          style="height:600px; width:1200px "
                          &gt;&lt;/iframe&gt;
                          <br />
                        </code>

                        <Button
                          className="mt-3 mb-3"
                          onClick={() =>
                            handleCopyToClipboard(
                              document.querySelector(".txt").innerText
                            )
                          }
                          color="primary"
                          style={{
                            display: "flex",
                            gap: "10px",
                            borderRadius: "25px",
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              overflow: "hidden",
                              verticalAlign: "middle",
                            }}
                          >
                            <g id="copy">
                              <path
                                id="Vector"
                                d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                id="Vector_2"
                                d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </g>
                          </svg>
                          COPY CODE
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <ul>
                    <li style={{ alignItems: "center" }}>
                      <LiaHandPointRight
                        style={{
                          marginRight: "5px",
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      <b>Paste:</b> Paste it into your company website's career
                      page.
                      <br />
                      <br />
                      <img
                        src={require("../../assets/images/paste.png")}
                        style={{ height: "30%", width: "70%" }}
                        alt="Paste it"
                      />
                      <br />
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li style={{ alignItems: "center" }}>
                      <LiaHandPointRight
                        style={{
                          marginRight: "5px",
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      <span style={{ marginBottom: "40px" }}>
                        <b>Automate:</b> Watch your job listings automatically
                        update without any manual effort!
                      </span>
                      <br />
                      <br />
                      <img
                        src={require("../../assets/images/gt.png")}
                        style={{ height: "30%", width: "70%" }}
                        alt="Automate"
                      />
                    </li>
                  </ul>
                </div>
                <div style={{ marginLeft: "38px", marginTop: "25px" }}>
                  <i>
                    If you need technical assistance, please reach out to our
                    dedicated team at xxxx@xxx or call us at [Insert Contact
                    Number]. Elevate your hiring game today!
                  </i>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default CareerPage;
