import React, { Fragment, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import LoginForm from "./LoginForm";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { USER_TYPES } from "../../Constant";

const Login = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const isValidLoginType = USER_TYPES.find(
    (loginType) => loginType === type.toUpperCase()
  );

  useEffect(() => {
    if (!isValidLoginType) {
      navigate("/404");
    }
  }, []);

  return (
    <Fragment>
      <section>
        <Container fluid={true}>
          <Row>
            <Col
              xl="5"
              className="b-center bg-size"
              style={{
                backgroundImage: `url(${require("../../assets/images/login/office.jpg")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "block",
              }}
            >
              {/* <img className='bg-img-cover bg-center d-none' src={require('../../assets/images/login/office.jpg')} alt='looginpage' /> */}
            </Col>
            <Col xl="7 p-0">
              <LoginForm logoClassMain="text-start" />
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </Fragment>
  );
};

export default Login;
