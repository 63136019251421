import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Card,
  CardBody,
  Input,
  Col,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { customOptionsStyle } from "../../Constant";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import JobConversionDatatable from "../../Components/Recruiter/jobConversion/JobConversionDatatable";
import JobMetricsBreakDownDatatable from "../../Components/Recruiter/jobConversion/JobMetricsBreakDownDatatable";
import OverallJobMetricsDatatable from "../../Components/Recruiter/jobConversion/OverallJobMetricsDatatable";
import { fetchJobNamesRequest } from "../../redux/recruiter/job_name/actions";
import { fetchJobConversionRequest } from "../../redux/recruiter/job_conversion/actions";
import { FaCalendarAlt } from "react-icons/fa"; // FontAwesome calendar icon
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const JobConversionPage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    jobId: null,
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
    },
  });
  const [errors, setErrors] = useState({});
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    const formateData = {
      job_id: formData.jobId,
      startDate: formData.dateRange.startDate.toISOString(),
      endDate: formData.dateRange.endDate.toISOString(),
    };
    dispatch(fetchJobConversionRequest(formateData));
  }, []);

  const jobConversionReducerData = useSelector(
    (state) => state.jobConversionReducer
  );
  const jobConversionData = jobConversionReducerData.data.data;

  const handleDateRangeChange = (ranges) => {
    if (ranges.range1 && ranges.range1.startDate && ranges.range1.endDate) {
      setFormData({
        ...formData,
        dateRange: {
          startDate: ranges.range1.startDate,
          endDate: ranges.range1.endDate,
        },
      });
      setErrors({
        ...errors,
        dateRange: "",
      });
      setDatePickerOpen(false);
    } else {
      console.error("Invalid date range selection:", ranges);
    }
  };

  const [jobNameOptions, setJobNameOptions] = useState([]);
  const jobNameReducerData = useSelector((state) => state.jobNameReducer);
  const jobNameData = jobNameReducerData.data.data;

  useEffect(() => {
    if (jobNameData && jobNameData.length > 0) {
      setJobNameOptions(jobNameData);
    } else {
      setJobNameOptions([]);
    }
  }, [jobNameData]);

  const handleJobNameFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = "";
        dispatch(fetchJobNamesRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleJobNameInputChange = useCallback(
    (inputValue) => {
      const fetchData = async () => {
        try {
          dispatch(fetchJobNamesRequest(inputValue));
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    },
    [dispatch]
  );

  const handleJobNameChange = (selectedOptions) => {
    const updatedErrors = { ...errors, jobId: "" };
    setErrors(updatedErrors);
    setFormData({
      ...formData,
      jobId: selectedOptions ? selectedOptions.value : null,
    });
  };

  const formatDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.dateRange.startDate || !formData.dateRange.endDate) {
      newErrors.dateRange = "Please select a date range";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formateData = {
        job_id: formData.jobId,
        startDate: formData.dateRange.startDate.toISOString(),
        endDate: formData.dateRange.endDate.toISOString(),
      };
      dispatch(fetchJobConversionRequest(formateData));
    } else {
      console.error("Form validation failed");
    }
  };

  const locale = {
    localize: {
      month: (monthIndex) => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return months[monthIndex];
      },
      day: (dayIndex) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[dayIndex];
      },
    },
    formatLong: {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  };

  return (
    <Fragment>
      <Breadcrumbs
        title="Job Conversion Insights"
        mainTitle="Job Conversion Insights"
      />
      <Container fluid>
        <Form className="theme-form" onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <div className="row gx-3">
                <div className="col-12 col-md-6 col-lg-7 mb-3">
                  <FormGroup className="mb-0">
                    <Label>Job Name</Label>
                    <Select
                      isLoading={jobNameReducerData.isLoading}
                      options={jobNameOptions}
                      value={jobNameOptions.find(
                        (opt) => opt.id === formData.jobId
                      )}
                      onChange={handleJobNameChange}
                      styles={customOptionsStyle}
                      onFocus={handleJobNameFocus}
                      onInputChange={handleJobNameInputChange}
                      placeholder="Select job name..."
                    />
                  </FormGroup>
                </div>

                <div className="col-12 col-lg-5 d-flex align-items-center mb-3">
                  <div className="flex-grow-1 d-flex align-items-center me-2">
                    <FormGroup className="mb-0 flex-grow-1">
                      <Label>
                        Date
                        <span className="text-danger">*</span>
                      </Label>
                      <Button
                        color="primary"
                        id="shownCalendar"
                        onClick={() => setDatePickerOpen(!datePickerOpen)}
                        className="d-flex align-items-center px-3 flex-grow-1"
                        style={{ height: "38px" }}
                      >
                        <FaCalendarAlt />
                        <span className="ms-2">
                          {`${formatDate(
                            formData.dateRange.startDate
                          )} - ${formatDate(formData.dateRange.endDate)}`}
                        </span>
                      </Button>
                    </FormGroup>
                  </div>
                  <Button
                    color="primary"
                    type="submit"
                    className="flex-grow-1"
                    style={{ height: "38px" }}
                    disabled={jobConversionReducerData.isLoading}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              {datePickerOpen && (
                <div className="mt-3">
                  <DateRangePicker
                    editableDateInputs
                    onChange={handleDateRangeChange}
                    ranges={[formData.dateRange]}
                    locale={locale}
                    months={2}
                    direction="horizontal"
                    open={datePickerOpen}
                    onToggle={(open) => setDatePickerOpen(open)}
                  />
                </div>
              )}
              <JobConversionDatatable
                jobConversionData={jobConversionData}
                formData={formData}
              />{" "}
            </CardBody>
          </Card>
        </Form>
      </Container>
    </Fragment>
  );
};

export default JobConversionPage;
