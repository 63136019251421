import React from "react";
import { Col, Row } from "reactstrap";
import Widgets1 from "../../Common/CommonWidgets/Widgets1";
import { useSelector } from "react-redux";
import {
  FaEye,
  FaPhoneAlt,
  FaBriefcase,
  FaTimesCircle,
  FaCalendarWeek,
  FaCheckCircle,
  FaPhone,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const WidgetsWrapper = () => {
  // Retrieve data from the Redux store using useSelector hook
  const dashboardReducerdData = useSelector(
    (state) => state.jobseekerDashboardReducer
  );

  let countData = [];
  // Check if dashboardReducerdData is not loading and contains the necessary data
  if (
    !dashboardReducerdData.isLoading &&
    dashboardReducerdData.data &&
    dashboardReducerdData.data.data
  ) {
    countData = dashboardReducerdData.data.data;
  }

  // Destructuring data from countData
  const {
    pending_count,
    shortlisted_count,
    rejected_count,
    total_applied_count,
    total_view_count,
    hired_count,
    interviewed_count,
    contact_count,
    resume_count,
  } = countData;

  // Configuration array for widgets
  const widgetsConfig = [
    {
      title: "Profile Views",
      count: total_view_count,
      color: "secondary",
      icon: <FaEye className="svg-fill" />,
      link: null,
    },
    {
      title: "Rejected Jobs",
      count: rejected_count,
      color: "primary",
      icon: <FaTimesCircle className="svg-fill" />,
      link: "/jobseeker/applied_Jobs/rejected",
    },
    {
      title: "Contacted",
      count: contact_count,
      color: "success",
      icon: <FaPhone className="svg-fill" />,
      link: null,
    },
    {
      title: "Hired",
      count: hired_count,
      color: "warning",
      icon: <FaPhoneAlt className="svg-fill" />,
      link: "/jobseeker/applied_Jobs/hired",
    },
    {
      title: "Interviewed",
      count: interviewed_count,
      color: "primary",
      icon: <FaCalendarWeek className="svg-fill" />,
      link: "/jobseeker/applied_Jobs/interviewed",
    },
    {
      title: "Shortlisted Jobs",
      count: shortlisted_count,
      color: "success",
      icon: <FaCheckCircle className="svg-fill" />,
      link: "/jobseeker/applied_Jobs/shortlisted",
    },
    {
      title: "Applied Jobs",
      count: total_applied_count,
      color: "success",
      icon: <FaBriefcase className="svg-fill" />,
      link: "/jobseeker/applied_Jobs",
    },

    {
      title: "Resume Viewed",
      count: resume_count,
      color: "success",
      icon: <FaEye className="svg-fill" />,
      link: null,
    },
  ];

  return (
    <>
      <Row>
        {widgetsConfig.map((widget, index) => (
          <Col xl="4" lg="6" md="6" sm="12" className="mb-2" key={index}>
            {widget.link ? (
              <Link to={`${process.env.PUBLIC_URL}${widget.link}`}>
                <Widgets1
                  data={{
                    title: widget.title,
                    gros: widget.count,
                    total: widget.count,
                    color: widget.color,
                    icon: widget.icon,
                  }}
                />
              </Link>
            ) : (
              <Widgets1
                data={{
                  title: widget.title,
                  gros: widget.count,
                  total: widget.count,
                  color: widget.color,
                  icon: widget.icon,
                }}
              />
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default WidgetsWrapper;
