import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Card, CardBody, CardHeader, Button, Row } from "reactstrap";
import Spinner from "../../Common/Spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Mail, Copy } from "react-feather";
import {
  fetchRecruiterInvitesRequest,
  resetFetchRecruiterInvites,
} from "../../../redux/recruiter/fetch_recruiter_invite/actions";
import { PreloaderContext } from "../../../_helper/Preloader/PreloaderContext";
import { showToast } from "../../Common/Toast";
import { showSweetAlert } from "../../Common/SweetAlert";
import { Link } from "react-router-dom";
import {
  customStyles,
  emptyField,
  LOADER_TIMEOUT,
  getCandidateStatusColor,
} from "../../../Constant";
import {
  formatDate,
  handleSendEmail,
  handleCopyToClipboard,
  unslugAndCapitalize,
} from "../../../Components/Common/CommonFunction";
import NoDataFound from "../../Common/NoDataFound";
import InviteModal from "./InviteModal";
import { FaClockRotateLeft } from "react-icons/fa6";
import {
  inviteRecruiterRequest,
  resetInviteRecruiter,
} from "../../../redux/recruiter/invite_recruiter/actions";
import { FcAcceptDatabase } from "react-icons/fc";
const InviteRecruiter = () => {
  const navigate = useNavigate();
  const { candidateStatus } = useParams();

  const [selectedIds, setSelectedIds] = useState([]);
  const dispatch = useDispatch();
  const { showPreloader } = useContext(PreloaderContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showInvite, setInvite] = useState(false);

  const fetchRecruiterInviteReducerData = useSelector(
    (state) => state.fetchRecruiterInviteReducer
  );

  const handlePageChange = (page) => setCurrentPage(page);
  const handlePerRowsChange = (newPerPage) => setPerPage(newPerPage);

  // const handleDelete = (seeker_id) => {
  //   showSweetAlert("confirm", {
  //     message: "You want to delete the candidate",
  //     fun: deleteJob,
  //     id: seeker_id,
  //   });
  // };

  // const deleteJob = (seeker_id) => {
  //   // dispatch(deleteCandidateRequest({ seeker_id }));
  // };

  const handleRowSelected = (selectedRows) => {
    const selectedIds = selectedRows.selectedRows.map((row) => row.seeker_id);
    setSelectedIds(selectedIds);
  };

  const handelReInviteClick = (row) => {
    dispatch(inviteRecruiterRequest({ invitation_id: row.invitation_id }));
  };

  const handelApprovedClick = (row) => {
    navigate(`/job/invite/${row.invitation_token}`);
  };

  const inviteRecruiterReducerData = useSelector(
    (state) => state.inviteRecruiterReducer
  );

  useEffect(() => {
    if (inviteRecruiterReducerData.message) {
      const messageType = inviteRecruiterReducerData.status
        ? "success"
        : "error";
      showToast(messageType, inviteRecruiterReducerData.message);
      const data = { page: "", per_page: "" };
      dispatch(fetchRecruiterInvitesRequest(data));
      dispatch(resetInviteRecruiter());
    }
  }, [inviteRecruiterReducerData, dispatch]);

  const tableColumns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
      sortable: true,
    },
    {
      name: "Email",
      sortable: true,
      //   width: "180px",
      cell: (row) => (
        <>
          {row.invite_email ? (
            <span style={{ cursor: "pointer" }}>
              {row.invite_email && (
                <div className="mb-2">
                  {emptyField(row.invite_email.trim()).length > 15
                    ? `${emptyField(row.invite_email.trim()).substring(
                        0,
                        15
                      )}...`
                    : emptyField(row.invite_email.trim())}
                  <Mail
                    size={18}
                    className="email-icon"
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                      verticalAlign: "middle",
                    }}
                    onClick={() => handleSendEmail(row.invite_email)}
                  />
                  <Copy
                    size={18}
                    className="copy-icon"
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      verticalAlign: "middle",
                    }}
                    onClick={() => handleCopyToClipboard(row.invite_email)}
                  />
                </div>
              )}
            </span>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (
        <span className={`badge ${getCandidateStatusColor(row.status)}`}>
          {unslugAndCapitalize(row.status)}
        </span>
      ),
    },
    {
      name: "Sent At",
      center: true,
      //  width: "120px",
      cell: (row) => formatDate(row.sent_at) || "-",
    },
    {
      name: "Action",
      center: true,
      //  width: "120px",
      cell: (row) => (
        <div>
          <span>
            {row.status === "PENDING" && (
              <Link to={`/job/invite/${row.invitation_token}`}>
                <span>
                  <FcAcceptDatabase
                    size={25}
                    className="resend-icon me-2"
                    title="Accept"
                  />
                </span>{" "}
              </Link>
            )}
            <span>
              <FaClockRotateLeft
                size={18}
                onClick={() => {
                  handelReInviteClick(row);
                }}
                className="resend-icon"
                title="Re Sent"
              />
            </span>
          </span>
        </div>
      ),
    },
  ];

  const toggleInviteModal = () => setInvite(false);
  const handelInviteClick = () => setInvite(true);

  useEffect(() => {
    const data = { page: currentPage, per_page: perPage };
    dispatch(fetchRecruiterInvitesRequest(data));
  }, [perPage, currentPage, dispatch]);

  const handleSort = (column, direction) => {
    const data = { page: currentPage, per_page: perPage };
    dispatch(fetchRecruiterInvitesRequest(data));
  };

  return (
    <Fragment>
      <Row>
        <Col xs="12" className="box-col-12 xl-100">
          <Card>
            <CardHeader className="py-3">
              <Button
                color="primary"
                className="float-end"
                onClick={handelInviteClick}
              >
                Add
              </Button>
            </CardHeader>
            <CardBody className="px-0 py-3">
              <DataTable
                customStyles={customStyles}
                data={fetchRecruiterInviteReducerData.data.data}
                columns={tableColumns}
                striped={true}
                center={true}
                // selectableRows
                // onSelectedRowsChange={handleRowSelected}
                pagination
                paginationServer
                paginationTotalRows={fetchRecruiterInviteReducerData.data.total}
                paginationPerPage={perPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={fetchRecruiterInviteReducerData.isLoading}
                progressComponent={<Spinner />}
                noDataComponent={<NoDataFound message={"No invites found"} />}
                onSort={handleSort}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showInvite && (
        <InviteModal isOpen={showInvite} toggleModal={toggleInviteModal} />
      )}
    </Fragment>
  );
};

export default InviteRecruiter;
