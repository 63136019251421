import {
  EXTEND_JOB_EXPIRE_DATE_REQUEST,
  EXTEND_JOB_EXPIRE_DATE_SUCCESS,
  EXTEND_JOB_EXPIRE_DATE_FAILURE,
  EXTEND_JOB_EXPIRE_DATE_RESET,
} from "../../actionTypes";

export const extendJobExpireDateRequest = (message) => ({
  type: EXTEND_JOB_EXPIRE_DATE_REQUEST,
  payload: message,
});

export const extendJobExpireDateSuccess = (data) => ({
  type: EXTEND_JOB_EXPIRE_DATE_SUCCESS,
  payload: data,
});

export const extendJobExpireDateFailure = (data) => ({
  type: EXTEND_JOB_EXPIRE_DATE_FAILURE,
  payload: data,
});

export const extendJobExpireDateReset = () => ({
  type: EXTEND_JOB_EXPIRE_DATE_RESET,
});
