import { React, Fragment, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Badge,
} from "reactstrap";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  addDesireCareerRequest,
  resetData,
} from "../../../redux/jobseeker/desired_career/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocationsRequest } from "../../../redux/location/actions";
import { fetchIndustryRequest } from "../../../redux/industryType/actions";
import { fetchFunctionalAreaRequest } from "../../../redux/functionalArea/actions";
import { useState } from "react";
import { useCallback } from "react";
import Select from "react-select";
import {
  customOptionsStyle,
  jobTypeOptions,
  employmentTypeOptions,
  shiftTypeOptions,
} from "../../../Constant";
import { showToast } from "../../Common/Toast";
import { convertNumberToWords } from "../../Common/CommonFunction";
import { FaCheck } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";

const Desiredcareerprofile = (props) => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [functionalAreaOptions, setFunctionalAreaOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState([]);

  /**
   * set default form field values
   */
  const [formData, setFormData] = useState({
    jobseeker_preferred_job_title: props.data.jobseeker_preferred_job_title,
    jobseeker_preferred_department: props.data.jobseeker_preferred_department,
    jobseeker_preferred_job_type: jobTypeOptions.find(
      (option) => option.value == props.data.jobseeker_preferred_job_type
    ),

    jobseeker_preferred_employment_type: employmentTypeOptions.find(
      (option) => option.value == props.data.jobseeker_preferred_employment_type
    ),

    jobseeker_preferred_shift: props.data.jobseeker_preferred_shift
      ? props.data.jobseeker_preferred_shift

          .split(",")
          .map((type) => {
            return shiftTypeOptions.find((option) => option.value === type);
          })
          .filter((option) => option !== undefined)
      : [],
    jobseeker_preferred_location: props.data.jobseeker_preferred_location,
    jobseeker_preferred_salary: props.data.jobseeker_preferred_salary,
    jobseeker_preferred_industry: props.data.jobseeker_preferred_industry,
  });

  /**
   * industry type dropdown code start
   */

  //read industry type data from store
  let industryTypeReducerData = useSelector(
    (state) => state.industryTypeReducer
  );

  let industryTypeData = industryTypeReducerData.data.data;

  useEffect(() => {
    industryTypeData = industryTypeData || [];
    setIndustryTypeOptions(industryTypeData);
  }, [industryTypeData]);

  const handleIndustryFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchIndustryRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleIndustryInputChange = useCallback((inputValue) => {
    // Update the location search value state
    // setLocationSearchValue(inputValue);

    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchIndustryRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleIndustryChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_industry: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, jobseeker_preferred_industry: selectedOptions });
  };

  /**
   * industy type dropdown code end
   */

  /**
   * functional area dropdown code start
   */

  //read industry type data from store
  let functionalAreaReducerData = useSelector(
    (state) => state.functionalAreaReducer
  );
  console.log(functionalAreaReducerData);
  let functionalAreaData = functionalAreaReducerData.data.data;

  useEffect(() => {
    functionalAreaData = functionalAreaData || [];
    setFunctionalAreaOptions(functionalAreaData);
  }, [functionalAreaData]);

  const handleFunctionalAreaFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchFunctionalAreaRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleFunctionalAreaInputChange = useCallback((inputValue) => {
    // Update the location search value state
    // setLocationSearchValue(inputValue);

    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchIndustryRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleFunctionalAreaChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_department: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      jobseeker_preferred_department: selectedOptions, // Assuming selectedOptions is the value you want to set
    });
  };

  /**
   * functional area dropdown code end
   */

  /**
   * location dropdown code start
   */
  //read location data from store
  let locationReducerData = useSelector((state) => state.locationReducer);
  let locationData = locationReducerData.data.data;

  useEffect(() => {
    locationData = locationData || [];
    setLocationOptions(locationData);
  }, [locationData]);

  const handleLocationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleLocationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Callback to handle location select change
  const handleLocationsChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_location: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, jobseeker_preferred_location: selectedOptions });
  };

  /**
   * location dropdown code end
   */

  /**
   * job type code start
   */
  const handleJobTypeChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_job_type: "" };
    setErrors(updatedErrors);

    // Update formData directly
    setFormData({
      ...formData,
      jobseeker_preferred_job_type: selectedOption, // Set the selected value
    });
  };
  /**
   * job type code end
   */

  /**
   * employement type code start
   */

  const handleEmploymentTypeChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = {
      ...errors,
      jobseeker_preferred_employment_type: "",
    };
    setErrors(updatedErrors);
    console.log("handleEmploymentTypeChange", selectedOption);

    // Update formData directly
    setFormData({
      ...formData,
      jobseeker_preferred_employment_type: selectedOption,
    });
  };

  const handleJobShiftChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobseeker_preferred_shift: "" };
    setErrors(updatedErrors);
    console.log(selectedOption);

    // Update formData directly
    setFormData({
      ...formData,
      jobseeker_preferred_shift: selectedOption,
    });
  };

  /**
   * employement type code end
   */

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);

    if (validate()) {
      // Format the data before logging or dispatching

      const formattedData = {
        jobseeker_preferred_job_title: formData.jobseeker_preferred_job_title,
        jobseeker_preferred_department:
          formData.jobseeker_preferred_department.value,
        jobseeker_preferred_job_type:
          formData.jobseeker_preferred_job_type.value,
        jobseeker_preferred_employment_type:
          formData.jobseeker_preferred_employment_type.value,
        jobseeker_preferred_shift: formData.jobseeker_preferred_shift
          .map((loc) => loc.value)
          .join(","),

        jobseeker_preferred_industry:
          formData.jobseeker_preferred_industry.value,

        // jobseeker_preferred_salary: formData.jobseeker_preferred_salary,
        // jobseeker_preferred_location: formData.jobseeker_preferred_location
        //   .map((loc) => loc.value)
        //   .join(","),
      };

      console.log("formd", formattedData);

      dispatch(addDesireCareerRequest(formattedData));
    }
  };

  // validate form data

  const validate = () => {
    let errors = {};
    let isValid = true;

    // if (!formData.jobseeker_preferred_job_title) {
    //   errors.jobseeker_preferred_job_title = "Job title is required";
    //   isValid = false;
    // }

    if (!formData.jobseeker_preferred_industry) {
      errors.jobseeker_preferred_industry = "Industry type is required";
      isValid = false;
    }

    if (!formData.jobseeker_preferred_department) {
      errors.jobseeker_preferred_department = "Functional area is required";
      isValid = false;
    }

    if (!formData.jobseeker_preferred_job_type) {
      errors.jobseeker_preferred_job_type = "Job type is required";
      isValid = false;
    }
    if (!formData.jobseeker_preferred_employment_type) {
      errors.jobseeker_preferred_employment_type =
        "Employment type is required";
      isValid = false;
    }
    if (!formData.jobseeker_preferred_shift) {
      errors.jobseeker_preferred_shift = "Job shift is required";
      isValid = false;
    }
    // if (!formData.jobseeker_preferred_location) {
    //   errors.jobseeker_preferred_location = "Location is required";
    //   isValid = false;
    // }
    // // Validate Jobseeker Preferred Salary
    // if (!formData.jobseeker_preferred_salary) {
    //   errors.jobseeker_preferred_salary = "Salary is required";
    //   isValid = false;
    // } else if (isNaN(formData.jobseeker_preferred_salary)) {
    //   errors.jobseeker_preferred_salary = "Salary must be a number";
    //   isValid = false;
    // }

    setErrors(errors);
    return isValid;
  };

  //handle onchange on form fields to remove errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    // Clear the error message when input changes
    let error = "";
    setErrors({ ...errors, [name]: error });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleShiftTypeChange = (type) => {
    const updatedErrors = { ...errors, jobseeker_preferred_shift: "" };
    setErrors(updatedErrors);

    // Check if the option's value already exists in formData.benefits
    const existingOption = formData.jobseeker_preferred_shift.find(
      (item) => item.value === type.value
    );

    // Toggle the selected state for the clicked benefit
    let updatedShiftType = [];
    if (existingOption) {
      updatedShiftType = formData.jobseeker_preferred_shift.filter(
        (item) => item.value !== type.value
      );
    } else {
      updatedShiftType = [...formData.jobseeker_preferred_shift, type];
    }

    // Update formData with the updated benefits array
    setFormData((prevFormData) => ({
      ...prevFormData,
      jobseeker_preferred_shift: updatedShiftType,
    }));
  };

  const desireCareerReducerData = useSelector(
    (state) => state.desireCareerReducer
  );
  useEffect(() => {
    console.log("srd", desireCareerReducerData);
    if (
      desireCareerReducerData.status &&
      desireCareerReducerData.message != ""
    ) {
      showToast("success", desireCareerReducerData.message);
      dispatch(fetchJobseekerDataRequest());
      props.toggleModal();
      dispatch(resetData());
    } else if (
      desireCareerReducerData.status == false &&
      desireCareerReducerData.message != ""
    ) {
      showToast("error", desireCareerReducerData.message);
      props.toggleModal();
      dispatch(resetData());
    }
  }, [dispatch, desireCareerReducerData]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4> Job Preferences</h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_job_title">Job Title</Label>
                <Input
                  type="text"
                  name="jobseeker_preferred_job_title"
                  className="form-control form-control-sm"
                  placeholder="Enter job title"
                  onChange={handleChange}
                  value={formData.jobseeker_preferred_job_title}
                />
                {errors.jobseeker_preferred_job_title && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_job_title}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_industry">
                  Industry Type
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={industryTypeOptions}
                  isLoading={industryTypeReducerData.isLoading}
                  placeholder="Select industry type"
                  value={formData.jobseeker_preferred_industry} // Use formData.industry directly
                  onChange={handleIndustryChange} // Handle change event
                  styles={customOptionsStyle}
                  onFocus={handleIndustryFocus} // Handle focus event
                  onInputChange={handleIndustryInputChange}
                />
                {errors.jobseeker_preferred_industry && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_industry}
                  </small>
                )}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_department">
                  Functional Area
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={functionalAreaOptions}
                  isLoading={functionalAreaReducerData.isLoading}
                  placeholder="Select functional area"
                  value={formData.jobseeker_preferred_department}
                  onChange={handleFunctionalAreaChange}
                  styles={customOptionsStyle}
                  onFocus={handleFunctionalAreaFocus}
                  onInputChange={handleFunctionalAreaInputChange}
                />
                {errors.jobseeker_preferred_department && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_department}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_job_type">
                  Job Preferences<span className="text-danger">*</span>
                </Label>
                <Select
                  options={jobTypeOptions}
                  value={formData.jobseeker_preferred_job_type}
                  placeholder="Select job preferences"
                  onChange={handleJobTypeChange}
                  styles={customOptionsStyle}
                />
                {errors.jobseeker_preferred_job_type && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_job_type}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_employment_type">
                  Employment Type
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={employmentTypeOptions}
                  value={formData.jobseeker_preferred_employment_type}
                  placeholder="Select employement type"
                  onChange={handleEmploymentTypeChange}
                  styles={customOptionsStyle}
                />
                {errors.jobseeker_preferred_employment_type && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_employment_type}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label for="jobseeker_preferred_shift">
                  Shift Preference <span className="text-danger">*</span>
                </Label>
                {/* <Select
                  options={jobShiftOptions}
                  value={formData.jobseeker_preferred_shift}
                  placeholder="Select job shift..."
                  onChange={handleJobShiftChange}
                  styles={customOptionsStyle}
                />
                {errors.jobseeker_preferred_shift && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_shift}
                  </small>
                )} */}
                <div className="mt-2">
                  {shiftTypeOptions.map((type) => (
                    <Badge
                      key={type.value}
                      className={`me-2 mb-2 border rounded d-inline-flex align-items-center ${
                        formData.jobseeker_preferred_shift.some(
                          (item) => item.value === type.value
                        )
                          ? "bg-primary text-white"
                          : "text-black"
                      }`}
                      style={{
                        cursor: "pointer",
                      }}
                      color="gray"
                      pill
                      onClick={() => handleShiftTypeChange(type)}
                    >
                      {formData.jobseeker_preferred_shift.some(
                        (item) => item.value === type.value
                      ) ? (
                        <FaCheck
                          style={{
                            marginRight: "5px",
                            height: "16px",
                            width: "16px",
                            paddingTop: "0px",
                          }}
                        />
                      ) : (
                        <FaPlus
                          style={{
                            marginRight: "5px",
                            height: "16px",
                            width: "16px",
                            paddingTop: "0px",
                          }}
                        />
                      )}
                      <span>{type.label}</span>
                    </Badge>
                  ))}
                </div>
              </FormGroup>{" "}
              {errors.jobseeker_preferred_shift && (
                <small className="text-danger">
                  {errors.jobseeker_preferred_shift}
                </small>
              )}
            </Col>
            {/* <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_shift">
                  Desired Shift
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={jobShiftOptions}
                  value={formData.jobseeker_preferred_shift}
                  placeholder="Select job shift..."
                  onChange={handleJobShiftChange}
                  styles={customOptionsStyle}
                />
                {errors.jobseeker_preferred_shift && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_shift}
                  </small>
                )}
              </FormGroup>
            </Col> */}
            {/* <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_salary">
                  Expected CTC{" "}
                  {formData.jobseeker_preferred_salary &&
                    !isNaN(formData.jobseeker_preferred_salary) && (
                      <small className="text-muted text-capitalize">
                        (
                        {convertNumberToWords(
                          parseFloat(formData.jobseeker_preferred_salary)
                        )}
                        )
                      </small>
                    )}{" "}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_preferred_salary"
                  className="form-control form-control-sm"
                  placeholder="Expected Salary"
                  onChange={handleChange}
                  value={formData.jobseeker_preferred_salary}
                />
                {errors.jobseeker_preferred_salary && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_salary}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_location">
                  Desired Location
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  isMulti
                  options={locationOptions}
                  isLoading={locationReducerData.isLoading}
                  placeholder="Select locations..."
                  value={formData.jobseeker_preferred_location}
                  onChange={handleLocationsChange}
                  onInputChange={handleLocationInputChange}
                  styles={customOptionsStyle}
                  onFocus={handleLocationFocus}
                />
                {errors.jobseeker_preferred_location && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_location}
                  </small>
                )}
              </FormGroup>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={desireCareerReducerData.isLoading}
          >
            Update
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default Desiredcareerprofile;
