import { call, put, takeEvery } from "redux-saga/effects";
import {
  updateRecruiterProfileSuccess,
  updateRecruiterProfileFailure,
  fetchRecruiterProfileSuccess,
  fetchRecruiterProfileFailure,
  updateRecruiterAvatarSuccess,
  updateRecruiterAvatarFailure,
  removeRecruiterAvatarSuccess,
  removeRecruiterAvatarFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {
  UPDATE_RECRUITER_PROFILE_REQUEST,
  FETCH_RECRUITER_PROFILE_REQUEST,
  UPDATE_RECRUITER_AVATAR_REQUEST,
  REMOVE_RECRUITER_AVATAR_REQUEST,
} from "../../actionTypes";

function* updateCompanyProfileSaga(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/add_recruiter_profile", action.payload)
    );

    if (response.data.status) {
      yield put(updateRecruiterProfileSuccess(response.data));
    } else {
      yield put(updateRecruiterProfileFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateRecruiterProfileFailure({ status: false, message: error.message })
    );
  }
}

function* fetchCompanyProfileSaga() {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/fetch_recruiter_profile_details")
    );
    if (response.data.status) {
      yield put(fetchRecruiterProfileSuccess(response.data));
    } else {
      yield put(fetchRecruiterProfileFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchRecruiterProfileFailure({ status: false, message: error.message })
    );
  }
}

function* updateAvatar(action) {
  //console.log(action.payload);

  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/profile_image", action.payload)
    );
    if (response.data.status) {
      yield put(updateRecruiterAvatarSuccess(response.data));
    } else {
      yield put(updateRecruiterAvatarFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateRecruiterAvatarFailure({ status: false, message: error.message })
    );
  }
}

function* removeAvatar() {
  //console.log(action.payload);

  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/remove_profile_image")
    );
    if (response.data.status) {
      yield put(removeRecruiterAvatarSuccess(response.data));
    } else {
      yield put(removeRecruiterAvatarFailure(response.data));
    }
  } catch (error) {
    yield put(
      removeRecruiterAvatarFailure({ status: false, message: error.message })
    );
  }
}

function* recruiterProfileSaga() {
  yield takeEvery(UPDATE_RECRUITER_PROFILE_REQUEST, updateCompanyProfileSaga);
  yield takeEvery(FETCH_RECRUITER_PROFILE_REQUEST, fetchCompanyProfileSaga);
  yield takeEvery(UPDATE_RECRUITER_AVATAR_REQUEST, updateAvatar);
  yield takeEvery(REMOVE_RECRUITER_AVATAR_REQUEST, removeAvatar);
}

export default recruiterProfileSaga;
