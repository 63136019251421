import React, { Fragment, useEffect, useContext } from 'react';
import { LOADER_TIMEOUT } from '../../Constant';
import {PreloaderContext} from '../../_helper/Preloader/PreloaderContext';
const Loader = () => {
  
  const { showPreloader,setShowPreloader } = useContext(PreloaderContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPreloader(false)
    }, LOADER_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [showPreloader]);

  return (
    <Fragment>
      <div className={`loader-wrapper ${showPreloader ? '' : 'loderhide'}`}>
        <div className='loader-index'>
          <span></span>
        </div>
        <svg>
          <defs></defs>
          <filter id='goo'>
            <fegaussianblur in='SourceGraphic' stdDeviation='11' result='blur'></fegaussianblur>
            <fecolormatrix in='blur' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9' result='goo'></fecolormatrix>
          </filter>
        </svg>
      </div>
    </Fragment>
  );
};

export default Loader;
