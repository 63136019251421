import { Link } from "react-router-dom";
import nfimg from "../../../assets/images/logo/logo-icon.png";

const SidebarIcon = () => {
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/recruiter/dashboard`}>
        <img className="img-fluid" src={nfimg} alt="" />
      </Link>
    </div>
  );
};

export default SidebarIcon;
