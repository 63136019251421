import React, { Fragment, useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import EditMyProfile from "./EditmyProfile";
import MyProfileEdit from "./MyProfileEdit";
// import UserTable from './UserTable';
import Workexperience from "./Workexperience";
import Mainskills from "./Mainskills";
import Worksample from "./Worksample";
import ResumeSkills from "./ResumeSkills";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { showToast } from "../../Common/Toast";
import Spinner from "../../Common/Spinner";
import { LOADER_TIMEOUT } from "../../../Constant";
import { PreloaderContext } from "../../../_helper/Preloader/PreloaderContext";
import { useParams } from "react-router";
import Educationbackground from "./Educationbackground";
import CandidateStatus from "./CandidateStatus";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";
const ProfileEditContain = () => {
  const { cand_id, jobcad_id } = useParams();

  const dispatch = useDispatch();
  const { showPreloader } = useContext(PreloaderContext);

  useEffect(() => {
    dispatch(
      fetchJobseekerDataRequest({
        seeker_id: extractAfterLastHyphen(cand_id),
        job_cand_id: extractAfterLastHyphen(jobcad_id),
      })
    );
  }, []);

  const fetchJobseekerDataReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer
  );
  let profileData = [];
  if (
    !fetchJobseekerDataReducerData.isLoading &&
    fetchJobseekerDataReducerData.data.data
  ) {
    profileData = fetchJobseekerDataReducerData.data.data;
  }
  console.log("rd", fetchJobseekerDataReducerData);
  console.log("pd", profileData);

  useEffect(() => {
    if (
      fetchJobseekerDataReducerData.status &&
      fetchJobseekerDataReducerData.message != ""
    ) {
      showToast("success", fetchJobseekerDataReducerData.message);
      // dispatch(resetJob());
    } else if (
      fetchJobseekerDataReducerData.status == false &&
      fetchJobseekerDataReducerData.message != ""
    ) {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", fetchJobseekerDataReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", fetchJobseekerDataReducerData.message);
      }
      // dispatch(resetJob());
    }
  }, [fetchJobseekerDataReducerData]);

  return (
    <div>
      {fetchJobseekerDataReducerData.isLoading ? (
        <Spinner /> // Show loader when isLoading is true
      ) : (
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="5">
            <MyProfileEdit profileData={profileData} />
            <ResumeSkills profileData={profileData} />
            <Educationbackground profileData={profileData} />
          </Col>
          <Col xl="7">
            {jobcad_id && <CandidateStatus profileData={profileData} />}
            <EditMyProfile profileData={profileData} />
            <Mainskills profileData={profileData} />
          </Col>

          <Col xl="12">
            <Workexperience profileData={profileData} />
          </Col>

          <Col xl="12">
            <Worksample profileData={profileData} />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ProfileEditContain;
