import React from "react";
import { Card, CardHeader, CardBody, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import { capitalize, formatDate } from "../../Common/CommonFunction";
import Spinner from "../../Common/Spinner";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector hooks
import {
  formatExperienceOutput,
  generateSlug,
  unslugAndCapitalize,
} from "../../Common/CommonFunction";
import NoDataFound from "../../Common/NoDataFound";
import { Edit2, Trash2, Eye, Copy, Users } from "react-feather";
import { customStyles } from "../../../Constant";
const JobDataTable = () => {
  // Retrieve data from the Redux store using useSelector hook
  const dashboardReducerdData = useSelector((state) => state.dashboardReducer);
  const getStatusColor = (status) => {
    // Implement your logic to determine the badge color based on status
    // For example:
    switch (status) {
      case "ACTIVE":
        return "badge-light-success";
      case "ON_HOLD":
        return "badge-light-primary";
      case "UNDER_REVIEW":
        return "badge-light-info";
      case "DRAFT":
        return "badge-light-warning";
      case "CLOSED":
        return "badge-light-danger";
      default:
        return "badge-light-primary";
    }
  };
  let activejobsData = [];

  // Check if dashboard data is available and not loading
  if (
    dashboardReducerdData &&
    !dashboardReducerdData.isLoading &&
    dashboardReducerdData.data &&
    dashboardReducerdData.data.data &&
    dashboardReducerdData.data.data.active_jobs
  ) {
    activejobsData = dashboardReducerdData.data.data.active_jobs;
  }

  const tableColumns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
      sortable: true,
      center: false,
    },
    {
      name: "Job Title",
      sortable: true,
      center: false,
      width: "15%",
      cell: (row) =>
        row.job_name ? (
          <Link
            to={`${process.env.PUBLIC_URL}/recruiter/job_details/${generateSlug(
              row.job_name,
              row.job_location,
              formatExperienceOutput(
                row.work_experience_min,
                row.work_experience_max
              ).replace("-", "-to-"),
              row.job_id
            )}`}
            title={"View Job"}
          >
            {capitalize(row.job_name)}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Location",
      width: "15%",
      cell: (row) => capitalize(row.job_location) || "-",
      sortable: true,
      center: false,
    },
    {
      name: "Experience",
      width: "15%",
      cell: (row) => {
        const experienceOutput = formatExperienceOutput(
          row.work_experience_min,
          row.work_experience_max
        );
        return experienceOutput !== "N/A" ? experienceOutput : "-";
      },
      sortable: false,
      center: true,
    },
    {
      name: "Created At",
      cell: (row) => formatDate(row.created_at) || "-",
      sortable: true,
      center: false,
      width: "18%",
    },
    {
      name: "Applications",
      width: "17%",
      sortable: false,
      center: true,
      cell: (row) =>
        row.applied_candidate_count ? (
          <Link
            to={`${
              process.env.PUBLIC_URL
            }/recruiter/job_applications/${generateSlug(
              row.job_name,
              row.job_location,
              formatExperienceOutput(
                row.work_experience_min,
                row.work_experience_max
              ).replace("-", "-to-"),
              row.job_id
            )}`}
          >
            {row.applied_candidate_count}
          </Link>
        ) : (
          0
        ),
    },
    {
      name: "Status",
      width: "11%",
      sortable: false,
      center: true,
      cell: (row) => (
        <span className={`badge ${getStatusColor(row.job_status)}`}>
          {unslugAndCapitalize(row.job_status)}
        </span>
      ),
    },
  ];

  return (
    <Card>
      <CardHeader className="card-no-border ">
        <div className="header-top">
          <h5>Active Jobs</h5>
          {activejobsData.length >= 1 && (
            <div className="card-header-right-icon">
              <Link to={`${process.env.PUBLIC_URL}/recruiter/manage_jobs`}>
                View All
              </Link>
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody className="px-0 py-3 pt-0">
        <DataTable
          customStyles={customStyles}
          striped={true}
          columns={tableColumns}
          data={activejobsData}
          //  pagination
          //   paginationServer
          //   paginationPerPage={10}
          selectableRowsHighlight
          selectableRowsOnClick
          progressPending={dashboardReducerdData.isLoading}
          progressComponent={<Spinner />}
          noDataComponent={<NoDataFound message={"No active jobs found"} />}
        />
      </CardBody>
    </Card>
  );
};

export default JobDataTable;
