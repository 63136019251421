import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnreadNotificationsRequest,
  fetchAllNotificationsRequest,
} from "../redux/notifications/actions";

// Create a context for managing notifications
export const NotificationContext = createContext();

// Custom hook for consuming the NotificationContext

// Notification provider component
const NotificationProvider = ({ children }) => {
  const dispatch = useDispatch();

  // State for unread notifications
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  // State for all notifications
  const [allNotifications, setAllNotifications] = useState([]);
  const [allNotificationsCount, setAllNotificationsCount] = useState(0);
  const [totalUnreadChatMembers, setTotalUnreadChatMembers] = useState(0);

  // Selector for notifications reducer data
  const unreadNotificationsState = useSelector(
    (state) => state.notificationsReducer.unread
  );
  const allNotificationsState = useSelector(
    (state) => state.notificationsReducer.all
  );

  const {
    data: unreadData,
    count: unreadCount,
    unreadChatMembers: unreadChatMemberCount,
    isLoading: unreadLoading,
  } = unreadNotificationsState;
  const {
    data: allData,
    count: allCount,
    isLoading: allLoading,
  } = allNotificationsState;

  useEffect(() => {
    fetchUnreadNotifications();

    //Set interval to refresh unread notifications every 20 minutes (1200000 ms)
    const intervalId = setInterval(() => {
      fetchUnreadNotifications();
    }, 20000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const fetchUnreadNotifications = () => {
    dispatch(fetchUnreadNotificationsRequest({ is_read: 0 }, 1, 4));
  };

  const fetchAllNotifications = (page, perPage) => {
    dispatch(fetchAllNotificationsRequest({ is_read: "" }, page, perPage));
  };

  // Update unread notifications state
  useEffect(() => {
    if (!unreadLoading && unreadData) {
      setUnreadNotifications(unreadData);
      setUnreadNotificationsCount(unreadCount);
      setTotalUnreadChatMembers(unreadChatMemberCount || 0);
    } else {
      setUnreadNotifications([]);
      setUnreadNotificationsCount(0);
      setTotalUnreadChatMembers(0);
    }
  }, [unreadData, unreadCount, unreadChatMemberCount]);

  // Update all notifications state
  useEffect(() => {
    if (!allLoading && allData) {
      setAllNotifications(allData);
      setAllNotificationsCount(allCount);
    } else {
      setAllNotifications([]);
      setAllNotificationsCount(0);
    }
  }, [allData, allLoading, allCount]);

  // console.log("totalUnreadChatMembers", totalUnreadChatMembers);
  return (
    <NotificationContext.Provider
      value={{
        unreadNotifications,
        unreadNotificationsCount,
        allNotifications,
        allNotificationsCount,
        totalUnreadChatMembers,

        fetchAllNotificationsRequest: fetchAllNotifications, // Assign function
        fetchUnreadNotificationsRequest: fetchUnreadNotifications, // Assign function
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationProvider;
