import {
  ACCEPT_OR_REJECT_INVITE_REQUEST,
  ACCEPT_OR_REJECT_INVITE_SUCCESS,
  ACCEPT_OR_REJECT_INVITE_FAILURE,
  RESET_ACCEPT_OR_REJECT_INVITE,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
  invite_status: false,
};

const acceptOrRejectInviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_OR_REJECT_INVITE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACCEPT_OR_REJECT_INVITE_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
        invite_status: action.payload.invite_status,
      };
    case ACCEPT_OR_REJECT_INVITE_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_ACCEPT_OR_REJECT_INVITE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
        invite_status: false,
      };
    default:
      return state;
  }
};

export default acceptOrRejectInviteReducer;
