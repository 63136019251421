import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import ResumeCard from "../../Components/Jobseeker/Resume/ResumeCard";
import { fetchResumeTemplates } from "../../redux/jobseeker/generate_resumes/actions";
import Spinner from "../../Components/Common/Spinner";
import NoDataFound from "../../Components/Common/NoDataFound";

const GenerateResumesPage = () => {
  const resumeTemplatesReducerData = useSelector(
    (state) => state.generateResumesReducer
  );
  const resumeTemplatesData = resumeTemplatesReducerData.data;

  const [activeTab, setActiveTab] = useState("ALL"); // Changed state variable name

  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch action when component mounts for the first time
    dispatch(fetchResumeTemplates({ type: activeTab })); // Changed to use activeTab state
  }, [activeTab]); // Added activeTab to dependency array

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs title="Generate Resumes" mainTitle="Generate Resumes" />
      <Container fluid>
        <Card>
          <CardBody>
            <Nav className="border-tab nav-primary nav nav-tabs" tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "ALL" ? "active" : ""}
                  onClick={() => toggleTab("ALL")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="icofont icofont-file"></i>
                  All Resumes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "PREMIUM" ? "active" : ""}
                  onClick={() => toggleTab("PREMIUM")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="icofont icofont-crown"></i>
                  Premium Resumes
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="ALL">
                <Row>
                  {resumeTemplatesData &&
                    resumeTemplatesData
                      .filter(
                        (template) =>
                          template.resume_category === "FREE" ||
                          template.resume_category === "PREMIUM"
                      )
                      .map((template) => (
                        <ResumeCard key={template.id} template={template} />
                      ))}
                </Row>
              </TabPane>
              <TabPane tabId="PREMIUM">
                <Row>
                  {resumeTemplatesData &&
                    resumeTemplatesData.filter(
                      (template) => template.resume_category === "PREMIUM"
                    ).length > 0 &&
                    resumeTemplatesData
                      .filter(
                        (template) => template.resume_category === "PREMIUM"
                      )
                      .map((template) => (
                        <ResumeCard key={template.id} template={template} />
                      ))}
                </Row>
              </TabPane>
            </TabContent>
            {resumeTemplatesReducerData.isLoading && (
              <Spinner color="primary" />
            )}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default GenerateResumesPage;
