import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Button,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import logoWhite from "../../assets/images/logo/logo.png";
import { USER_TYPES } from "../../Constant";
import { showToast } from "../../Components/Common/Toast";
import {
  verifyAccountRequest,
  reset,
} from "../../redux/auth/verify_account/actions";
import { ToastContainer } from "react-toastify";
const VerifyAccount = () => {
  const dispatch = useDispatch();
  const { type, token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // Check if login type is valid
  const isValidLoginType = USER_TYPES.includes(type.toUpperCase());

  useEffect(() => {
    if (!isValidLoginType) {
      navigate("/404");
      return;
    }

    if (token) {
      setIsLoading(true);
      dispatch(verifyAccountRequest({ type: type.toUpperCase(), token }));
    }
  }, [dispatch, type, token, isValidLoginType, navigate]);

  const verifyAccountReducerData = useSelector(
    (state) => state.verifyAccountReducer
  );

  useEffect(() => {
    if (verifyAccountReducerData.message) {
      const { status, message } = verifyAccountReducerData;

      if (status) {
        showToast("success", message);
        setTimeout(() => {
          setIsLoading(false);
          if (verifyAccountReducerData.is_redirect) {
            navigate(`${process.env.PUBLIC_URL}/${type}/auth/login/`);
          }
          dispatch(reset());
        }, 2000);
      } else {
        setIsLoading(false);
        setErrorMessage(message);
        showToast("error", message);
        dispatch(reset());
      }
    }
  }, [verifyAccountReducerData, dispatch, navigate, type]);

  return (
    <Fragment>
      <Container fluid className="p-0 login-page">
        <Row className="m-0">
          <Col xl="12 p-0">
            <div className="login-card">
              <Form className="theme-form login-form">
                <div className="text-center mb-4">
                  <img
                    src={logoWhite}
                    alt="Logo"
                    className="img-fluid"
                    width="100"
                  />
                </div>
                <h4 className="text-center mb-4">Verify Your Account</h4>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                    <p>Verifying...</p>
                  </div>
                ) : errorMessage ? (
                  <div className="text-center text-danger">
                    <p>{errorMessage}</p>
                  </div>
                ) : (
                  <FormGroup className="d-flex justify-content-center">
                    <Button color="primary" className="btn-block" disabled>
                      Verify Account
                    </Button>
                  </FormGroup>
                )}
              </Form>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </Fragment>
  );
};

export default VerifyAccount;
