import { ToastContainer, toast } from "react-toastify";

const Toast = (props) => {
  // const showToast = (name) => {
  //   switch (name) {
  //     case "success":
  //       toast.success(message);
  //       break;
  //     case "info":
  //       toast.info(message);
  //       break;
  //     case "warning":
  //       toast.warn(message);
  //       break;
  //     case "danger":
  //       toast.error(message);
  //       break;
  //     default:
  //       toast(message);
  //   }
  // };

  // showToast(name);

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export const showToast = (name, message) => {
  console.log("success", "showToast");
  switch (name) {
    case "success":
      toast.success(message);
      break;
    case "info":
      toast.info(message);
      break;
    case "warning":
      toast.warn(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
  }
};

// showToast(name);

export default Toast;
