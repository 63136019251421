import {
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_FAILURE,
  RESET_DATA,
} from "../../actionTypes";

export const verifyAccountRequest = (data) => ({
  type: VERIFY_ACCOUNT_REQUEST,
  payload: data,
});

export const verifyAccountSuccess = (response) => ({
  type: VERIFY_ACCOUNT_SUCCESS,
  payload: response,
});

export const verifyAccountFailure = (error) => ({
  type: VERIFY_ACCOUNT_FAILURE,
  payload: error,
});

export const reset = () => ({
  type: RESET_DATA,
});
