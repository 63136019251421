import * as types from '../../actionTypes';

const initialState = {
  data: {},
  isLoading: false,
  message: "",
  status:""
};


const desireCareerReducer = (state = initialState, action) => {
  console.log('prd',action);
  switch (action.type) {
    case types.UPDATE_DESIRED_CAREER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.UPDATE_DESIRED_CAREER_PROFILE_SUCCESS:
        
      return {
        ...state,
        isLoading: false,
        status:action.payload.status,
        message:action.payload.message
      };
    case types.UPDATE_DESIRED_CAREER_PROFILE_FAILURE:
        console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        data:{},
        status:action.payload.status,
        message:action.payload.message
      };
      case types.RESET_DATA:
        return {
          ...state,
          isLoading: false,
          data:{},
          status:false,
          message:""
        };

    default:
      return state;
  }
};

export default desireCareerReducer;
