// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import { fetchLocationsFailure, fetchLocationsSuccess } from "./actions";

function* fetchLocationsSaga(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(
      () => AxiosInstance().post(`/fetch_location`, action.payload)

      //recruiter/manage_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}
    );
    if (response.data.status) {
      yield put(fetchLocationsSuccess(response.data));
    } else {
      yield put(fetchLocationsFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchLocationsFailure({ status: false, message: error.message }));
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* locationSaga() {
  yield takeLatest(types.FETCH_LOCATIONS_REQUEST, fetchLocationsSaga);
}

export default locationSaga;
