import React, { useState, useContext } from "react";
import { FiMessageSquare } from "react-icons/fi"; // Import chat icon
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ChatAppContext from "../../../../Chat";
import { NotificationContext } from "../../../../Notifications/NotificationContext";
const Chat = () => {
  const navigate = useNavigate(); // Get navigate function from useNavigate

  const { totalUnreadChatMembers } = useContext(NotificationContext);

  const handleSearchClick = () => {
    // Redirect to another page
    navigate(`${process.env.PUBLIC_URL}/recruiter/chat`); // Use navigate function to navigate
  };

  return (
    <li className="onhover-dropdown">
      <div
        className={`notification-box ${
          totalUnreadChatMembers > 0 ? "animated" : ""
        }`}
        onClick={handleSearchClick} // Handle click to navigate
      >
        <FiMessageSquare size={24} /> {/* Replace Bell with chat icon */}
        <span className="badge rounded-pill badge-success">
          {totalUnreadChatMembers}
        </span>
      </div>
    </li>
  );
};

export default Chat;
