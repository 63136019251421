import React, { useState } from "react";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";

const Searchbar = () => {
  const [searchresponsive, setSearchresponsive] = useState(false);
  const navigate = useNavigate(); // Use useNavigate hook

  const handleSearchClick = () => {
    // Redirect to next page
    navigate(`${process.env.PUBLIC_URL}/jobseeker/job_search`); // Update "/next-page" with your actual route
  };

  return (
    <li>
      <span className="header-search">
        <Search onClick={handleSearchClick} />
      </span>
    </li>
  );
};

export default Searchbar;
