import {
  FETCH_MANAGE_JOBS_REQUEST,
  FETCH_MANAGE_JOBS_SUCCESS,
  FETCH_MANAGE_JOBS_FAILURE,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  status: false,
  message: "",
};

const manageJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MANAGE_JOBS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_MANAGE_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status,
        message: "",
      };
    case FETCH_MANAGE_JOBS_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        status: action.payload.status,
        message: action.payload.message,
      };

    case DELETE_JOB_REQUEST:
      return { ...state, isLoading: true };
    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case DELETE_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
      case RESET_JOB:
      return {
        ...state,
        isLoading: false,
        status: false,
        message: '',
      };
    default:
      return state;
  }
};

export default manageJobsReducer;
