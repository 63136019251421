import React, { Fragment, useContext } from "react";
import CustomContext from "../../../_helper/Customizer";
import SidebarIcon from "./SidebarIcon";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";

const Sidebar = () => {
  const { toggleIcon } = useContext(CustomContext);


  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-links").classList.remove("active");
  };

  return (
    <Fragment>
      <div
        className="bg-overlay1"
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div
        className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`}
        sidebar-layout="stroke-svg"
      >
        <SidebarIcon />
        <SidebarLogo />

        <SidebarMenu />
      </div>
    </Fragment>
  );
};

export default Sidebar;
