import { call, put, takeLatest } from "redux-saga/effects";
import { LOGIN_REQUEST } from "../../actionTypes";
// import { postJobApi } from "./api"; // Replace with your actual API file
import AxiosInstance from "../../../services/AxiosInstance";

import { loginSuccess, loginFailure } from "./actions";

function* login(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/auth/login", action.payload)
    );

    console.log("res", response);
    if (response.data.status) {
      yield put(loginSuccess(response.data));
    } else {
      yield put(loginFailure(response.data));
    }
  } catch (error) {
    yield put(loginFailure({ status: false, message: error.message }));
  }
}

// Watch for POST_JOB_REQUEST action
function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, login);
}

export default loginSaga;
