import { takeLatest, call, put } from "redux-saga/effects";
import { ACCEPT_OR_REJECT_INVITE_REQUEST } from "../../actionTypes";

import {
  acceptOrRejectInviteFailure,
  acceptOrRejectInviteSuccess,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* acceptOrRejectInvite(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/invitation_response", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(acceptOrRejectInviteSuccess(response.data));
    } else {
      yield put(acceptOrRejectInviteFailure(response.data));
    }
  } catch (error) {
    yield put(
      acceptOrRejectInviteFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* acceptOrRejectInviteSaga() {
  yield takeLatest(ACCEPT_OR_REJECT_INVITE_REQUEST, acceptOrRejectInvite);
}
export default acceptOrRejectInviteSaga;
