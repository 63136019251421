import { call, put, takeLatest } from "redux-saga/effects";
import {
  POST_JOB_REQUEST,
  FETCH_JOB_REQUEST,
  POST_JOB_SUCCESS,
  POST_JOB_FAILURE,
} from "../../actionTypes";
// import { postJobApi } from "./api"; // Replace with your actual API file
import AxiosInstance from "../../../services/AxiosInstance";
import {
  postJobSuccess,
  postJobFailure,
  fetchJobFailure,
  fetchJobSuccess,
} from "./actions";

function* postJob(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/post_job", action.payload)
    );
    
    if(response.data.status){

      yield put(postJobSuccess(response.data));
    }else{
      yield put(postJobFailure(response.data))
    }
    
  } catch (error) {
    yield put(postJobFailure(error));
  }
}
function* fetchJobData(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/edit_or_duplicate_job", action.payload)
    );
    // console.log('saga res',response);
    if(response.data.status){

      yield put(fetchJobSuccess(response.data));
    }else{
      yield put(fetchJobFailure(response.data));
    }
  } catch (error) {
    yield put(fetchJobFailure(error));
  }
}

// Watch for POST_JOB_REQUEST action
function* postJobSaga() {
  yield takeLatest(POST_JOB_REQUEST, postJob);
  yield takeLatest(FETCH_JOB_REQUEST, fetchJobData);
}

export default postJobSaga;
