import React from "react";
import { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Fragment>
      <footer className="footer" style={{marginLeft:0}}>
        <Container fluid={true}>
          <Row>
            <Col col="12" className="footer-copyright text-center">
              <p className= "mb-0"
              >{`© ${currentYear} Naukrify. All rights reserved.`}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
