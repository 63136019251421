// sagas.js

import { call, put, takeEvery } from "redux-saga/effects";
import { fetchJobsSuccess, fetchJobsFailure } from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { FETCH_RECOMMENDED_JOBS_REQUEST } from "../../actionTypes";

function* fetchJobs(action) {
  const { page, perPage } = action.payload;
  try {
    const response = yield call(() =>
      AxiosInstance().post(
        `/jobseeker/recommended_jobs?page=${page}&per_page=${perPage}`
      )
    );
    if (response.data.status) {
      yield put(fetchJobsSuccess(response.data));
    } else {
      yield put(fetchJobsFailure(response.data));
    }
  } catch (error) {
    yield put(fetchJobsFailure({ status: false, message: error.message }));
  }
}

function* recommendedJobsSaga() {
  yield takeEvery(FETCH_RECOMMENDED_JOBS_REQUEST, fetchJobs);
}

export default recommendedJobsSaga;
