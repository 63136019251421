import React, { Fragment, useContext } from "react";
import ChatAppContext from "../../../Chat";
import { useLocation } from "react-router-dom";
import avatar from "../../../../src/assets/images/avatar.png";
import { capitalize } from "../../Common/CommonFunction";

const ChatHeader = () => {
  const { selectedUser, handalProfileToggle } =
    useContext(ChatAppContext) || {};
  const location = useLocation();

  return (
    <Fragment>
      <div className="chat-header clearfix" style={{ display: "block" }}>
        <img
          className="rounded-circle"
          src={selectedUser?.profile_image || avatar}
          alt="User Avatar"
          onClick={handalProfileToggle}
          style={{ cursor: "pointer" }} // Add a pointer cursor for better UX
        />

        <div className="media-body">
          <div className="about">
            <div className="name">
              {selectedUser
                ? capitalize(selectedUser.recruiter_full_name)
                : "Vincent Porter"}
              {location.state3 ? (
                <span className="font-primary f-12"> Typing...</span>
              ) : (
                ""
              )}
            </div>
            <div className="status digits">
              {selectedUser ? selectedUser.lastSeenDate : "5 May, 5:30 PM"}
            </div>
          </div>
        </div>
        {/* Uncomment the below code to show the chat menu icons if needed */}
        {/* <ul className="simple-list list-inline float-start float-sm-end chat-menu-icons d-flex flex-row">
          <li className="list-inline-item border-0">
            <a href="#javascript">
              <Search />
            </a>
          </li>
          <li className="list-inline-item border-0">
            <a href="#javascript">
              <Paperclip />
            </a>
          </li>
          <li className="list-inline-item border-0">
            <a href="#javascript">
              <Headphones />
            </a>
          </li>
          <li className="list-inline-item border-0">
            <a href="#javascript">
              <Video />
            </a>
          </li>
          <li className="list-inline-item border-0">
            <a href="#javascript">
              <AlignJustify onClick={chatMenuToggle} />
            </a>
          </li>
        </ul> */}
      </div>
    </Fragment>
  );
};

export default ChatHeader;
