// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";

import { fetchSkillsSuccess,fetchSkillsFailure } from "./actions";

function* fetchSkillsSaga(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(() =>
      AxiosInstance().post("/fetch_skill", {keyword:action.payload.keyword})
    );
    
    // Dispatch success action with the response data
    if(response.data.status){

      yield put(fetchSkillsSuccess(response.data));
    }else{
      yield put(fetchSkillsFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchSkillsFailure({status:false,message:error.message}));
  }
}

// Watch for the latest FETCH_INDUSTRY_REQUEST action and call the saga
function* mandatorySkillsSaga() {
  yield takeLatest(types.FETCH_SKILLS_REQUEST, fetchSkillsSaga);
}

export default mandatorySkillsSaga;
