import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_PASSWORD_REQUEST } from "../../actionTypes";

import { updatePasswordSuccess, updatePasswordFailure } from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updatePasswordSaga(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/change_password", action.payload)
    );
    console.log('res',response.data);
    if(response.data.status == true){
      yield put(updatePasswordSuccess(response.data));
    }else{
      yield put(updatePasswordFailure(response.data));
    }
    
  } catch (error) {
    yield put(updatePasswordFailure({status:false,message:error.message}));
  }
}

// Watcher saga
function* changePasswordSaga() {
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePasswordSaga);
}
export default changePasswordSaga;
