import React from 'react';
import DataTable from 'react-data-table-component';


const Test2 = () => {
  // Sample job data
  const jobApplications = [
    {
      jobTitle: 'React And Php',
      companyName: 'NXT',
      experience: '1 Year - 1 Year',
      location: 'Adilabad',
      appliedOn: '23 Dec, 2023',
      status: 'Viewed',
    },
    // Add more job items as needed
  ];

  // Define table columns
  const tableColumns = [
    {
      name: 'Job Title',
      selector: 'jobTitle',
      sortable: true,
    },
    {
      name: 'Company Name',
      selector: 'companyName',
      sortable: true,
      center: true,
    },
    {
      name: 'Experience',
      selector: 'experience',
      sortable: true,
      center: true,
    },
    {
      name: 'Location',
      selector: 'location',
      sortable: true,
      center: true,
    },
    {
      name: 'Applied On',
      selector: 'appliedOn',
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (row) => (
        <button
          type="button"
          className="btn"
          style={{
            fontSize: '13px',
            cursor: 'default',
            borderRadius: '20px',
            background: row.status === 'Viewed' ? '#FFDEEA' : '#FFE7B9',
            color: 'black',
          }}
        >
          {row.status}
        </button>
      ),
    },
  ];

  return (
    <div className="manage-job-container">
      <DataTable
        columns={tableColumns}
        data={jobApplications}
        pagination
        striped
        highlightOnHover
      />
    </div>
  );
};

export default Test2;
