import { React, Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/update_profile/actions";
import {
  addEducationRequest,
  resetData,
} from "../../../redux/jobseeker/add_education/actions";
import { fetchEducationRequest } from "../../../redux/education/actions";
import { useCallback } from "react";
import {
  customOptionsStyle,
  educationLevelsOptions,
  educationBoardsOptions,
  courseTypeOptions,
  schoolMediumsOptions,
  passingyearsOptions,
  performanceScaleOptions,
} from "../../../Constant";
import { showToast } from "../../Common/Toast";

const EducationModal = (props) => {
  console.log("pro", props);

  const dispatch = useDispatch();
  const [educationOptions, setEducationOptions] = useState([]);
  const [newEduLevelOptions, setNewEduLevelOptions] = useState([]);

  useEffect(() => {
    // Extract the values from props array
    if (props.data.id == "" || props.data.id == null) {
      const valuesToFilter = props.data.education_level.map(
        (item) => item.value
      );
      console.log("val", valuesToFilter);

      // Filter out options that don't match with the values from props array
      const filteredOptions = educationLevelsOptions.filter(
        (option) => !valuesToFilter.includes(option.value)
      );
      if (
        valuesToFilter.includes("SECONDARY") &&
        valuesToFilter.includes("SENIOR_SECONDARY")
      ) {
        setIsSchool(false);
      }

      setFormData({ ...formData, education_level: filteredOptions[0] });
      setNewEduLevelOptions(filteredOptions);
    }
  }, []);

  console.log("newedu", newEduLevelOptions);

  const [errors, setErrors] = useState({});
  const [isSchool, setIsSchool] = useState(true);

  useEffect(() => {
    if (
      props.data.id &&
      props.data.education_level != "SECONDARY" &&
      props.data.education_level != "SENIOR_SECONDARY"
    ) {
      setIsSchool(false);
    }
  }, []);
  /**
   * set default form field values
   *
   */
  const [formData, setFormData] = useState({
    seeker_id: props.data.seeker_id,
    id: props.data.id,
    board:
      (props.data.board &&
        educationBoardsOptions.find(
          (option) => option.value == props.data.board
        )) ||
      null,
    school_medium:
      (props.data.school_medium &&
        schoolMediumsOptions.find(
          (option) => option.value == props.data.school_medium
        )) ||
      null,
    education_level:
      props.data.id != null
        ? educationLevelsOptions.find(
            (option) => option.value == props.data.education_level
          )
        : null,
    jobseeker_degree:
      (props.data.jobseeker_degree && props.data.jobseeker_degree) || null,
    jobseeker_specialization:
      (props.data.jobseeker_specialization &&
        props.data.jobseeker_specialization) ||
      "",
    jobseeker_college_name:
      (props.data.jobseeker_college_name &&
        props.data.jobseeker_college_name) ||
      "",
    jobseeker_education_type:
      (props.data.jobseeker_education_type &&
        courseTypeOptions.find(
          (option) => option.value == props.data.jobseeker_education_type
        )) ||
      null,
    passing_year:
      (props.data.passing_year &&
        passingyearsOptions.find(
          (option) => option.value == props.data.passing_year
        )) ||
      null,
    performance_scale:
      (props.data.performance_scale &&
        performanceScaleOptions.find(
          (option) => option.value == props.data.performance_scale
        )) ||
      null,
    performance: (props.data.performance && props.data.performance) || "",
  });

  console.log("fd", formData);

  /**
   * education dropdown code start
   */
  //read education data from store
  let educationReducerData = useSelector((state) => state.educationReducer);
  let educationData = educationReducerData.data.data;

  useEffect(() => {
    educationData = educationData || [];
    setEducationOptions(educationData);
  }, [educationData]);

  const handleEducationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchEducationRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleEducationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchEducationRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleEducationChange = (selectedOptions) => {
    const updatedErrors = { ...errors, jobseeker_degree: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, jobseeker_degree: selectedOptions });
  };

  /**
   * education dropdown code end
   */

  /**
   * handle form submit when all data is valid
   */

  const handleSubmit = (e, status) => {
    e.preventDefault();

    console.log(formData);

    if (validate()) {
      // Format the data before logging or dispatching
      // let job_id = id;
      // if (duplicate) {
      //   job_id = "";
      // }
      const formattedData = {
        // job_id: job_id || "", // Set job_id to id if exists, otherwise to ""
        seeker_id: formData.seeker_id,
        id: formData.id,
        board: (formData.board && formData.board.value) || "",
        school_medium:
          (formData.school_medium && formData.school_medium.value) || "",
        education_level:
          (formData.education_level && formData.education_level.value) || "",
        jobseeker_degree:
          (formData.jobseeker_degree && formData.jobseeker_degree.value) || "",
        jobseeker_specialization: formData.jobseeker_specialization,
        jobseeker_college_name: formData.jobseeker_college_name,
        jobseeker_education_type:
          (formData.jobseeker_education_type &&
            formData.jobseeker_education_type.value) ||
          "",
        passing_year: formData.passing_year.value,
        performance_scale: formData.performance_scale.value,
        performance: formData.performance,
      };

      console.log("formatted_data", formattedData);

      dispatch(addEducationRequest(formattedData));
    }
  };

  // validate form data

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.education_level) {
      errors.education_level = "Education level is required";
      isValid = false;
    }

    if (isSchool) {
      if (!formData.board) {
        errors.board = "Board is required";
        isValid = false;
      }
      if (!formData.school_medium) {
        errors.school_medium = "School medium is required";
        isValid = false;
      }
    } else {
      if (!formData.jobseeker_degree) {
        errors.jobseeker_degree = "Course is required";
        isValid = false;
      }
      if (!formData.jobseeker_college_name) {
        errors.jobseeker_college_name = "College is required";
        isValid = false;
      }
      if (!formData.jobseeker_specialization) {
        errors.jobseeker_specialization = "Specialization is required";
        isValid = false;
      }
      if (!formData.jobseeker_education_type) {
        errors.jobseeker_education_type = "Course type is required";
        isValid = false;
      }
    }

    if (!formData.performance || !formData.performance.trim()) {
      errors.performance = "Performance is required";
      isValid = false;
    } else if (isNaN(formData.performance.trim())) {
      errors.performance = "Performance must be a number";
      isValid = false;
    }

    if (!formData.performance_scale) {
      errors.performance_scale = "Performance scale is required";
      isValid = false;
    }
    if (!formData.passing_year) {
      errors.passing_year = "Passing year is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const addEducationReducerData = useSelector(
    (state) => state.addEducationReducer
  );
  useEffect(() => {
    console.log("srd", addEducationReducerData);
    if (
      addEducationReducerData.status &&
      addEducationReducerData.message != ""
    ) {
      props.toggleModal();
    } else if (
      addEducationReducerData.status == false &&
      addEducationReducerData.message != ""
    ) {
      props.toggleModal();
    }
  }, [addEducationReducerData]);

  const handleEducationLevelChange = (selectedOption) => {
    const updatedErrors = { ...errors, education_level: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, education_level: selectedOption });
    if (
      selectedOption.value == "SECONDARY" ||
      selectedOption.value == "SENIOR_SECONDARY"
    ) {
      setIsSchool(true);
    } else {
      setIsSchool(false);
    }
  };
  const handleBoardChange = (selectedOption) => {
    const updatedErrors = { ...errors, board: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, board: selectedOption });
  };
  const handleSchoolMediumChange = (selectedOption) => {
    const updatedErrors = { ...errors, school_medium: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, school_medium: selectedOption });
  };
  const handleEducationTypeChange = (selectedOption) => {
    const updatedErrors = { ...errors, jobseeker_education_type: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, jobseeker_education_type: selectedOption });
  };
  const handlesPassingYearChange = (selectedOption) => {
    const updatedErrors = { ...errors, passing_year: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, passing_year: selectedOption });
  };
  const handlePerformanceScaleChange = (selectedOption) => {
    const updatedErrors = { ...errors, performance_scale: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, performance_scale: selectedOption });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    setErrors({ ...errors, [name]: error });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4>{props.data.id ? "Update Education" : "Add Education"}</h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="jobseeker_college_name">
                  Education Level
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={newEduLevelOptions}
                  placeholder="Select education level"
                  value={formData.education_level}
                  onChange={handleEducationLevelChange}
                  styles={customOptionsStyle}
                  isDisabled={props.data.id}
                />
                {errors.education_level && (
                  <small className="text-danger">
                    {errors.education_level}
                  </small>
                )}
              </FormGroup>
            </Col>

            <Col md={6} className={isSchool ? "" : "d-none"}>
              <FormGroup>
                <Label for="jobseeker_degree">
                  Board
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={educationBoardsOptions}
                  placeholder="Select board"
                  value={formData.board}
                  onChange={handleBoardChange}
                  styles={customOptionsStyle}
                />
                {errors.board && (
                  <small className="text-danger">{errors.board}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6} className={isSchool ? "" : "d-none"}>
              <FormGroup>
                <Label for="jobseeker_degree">
                  School Medium
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={schoolMediumsOptions}
                  placeholder="Select school medium"
                  value={formData.school_medium}
                  onChange={handleSchoolMediumChange}
                  styles={customOptionsStyle}
                />
                {errors.school_medium && (
                  <small className="text-danger">{errors.school_medium}</small>
                )}
              </FormGroup>
            </Col>

            <Col md={6} className={!isSchool ? "" : "d-none"}>
              <FormGroup>
                <Label for="jobseeker_college_name">
                  College Name
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="jobseeker_college_name"
                  placeholder="Enter college name"
                  value={formData.jobseeker_college_name}
                  onChange={handleChange}
                />
                {errors.jobseeker_college_name && (
                  <small className="text-danger">
                    {errors.jobseeker_college_name}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6} className={!isSchool ? "" : "d-none"}>
              <FormGroup>
                <Label for="jobseeker_education">
                  Course
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={educationOptions}
                  isLoading={educationReducerData.isLoading}
                  placeholder="Select course"
                  value={formData.jobseeker_degree}
                  onChange={handleEducationChange}
                  styles={customOptionsStyle}
                  onInputChange={handleEducationInputChange}
                  onFocus={handleEducationFocus}
                />
                {errors.jobseeker_degree && (
                  <small className="text-danger">
                    {errors.jobseeker_degree}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6} className={!isSchool ? "" : "d-none"}>
              <FormGroup>
                <Label for="jobseeker_education_type">
                  Course Type
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={courseTypeOptions}
                  placeholder="Select course type"
                  value={formData.jobseeker_education_type}
                  onChange={handleEducationTypeChange}
                  styles={customOptionsStyle}
                />
                {errors.jobseeker_education_type && (
                  <small className="text-danger">
                    {errors.jobseeker_education_type}
                  </small>
                )}
              </FormGroup>
            </Col>

            <Col md={6} className={!isSchool ? "" : "d-none"}>
              <FormGroup>
                <Label>
                  Specialization
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="jobseeker_specialization"
                  placeholder="Enter specialization"
                  value={formData.jobseeker_specialization}
                  onChange={handleChange}
                />
                {errors.jobseeker_specialization && (
                  <small className="text-danger">
                    {errors.jobseeker_specialization}
                  </small>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="jobseeker_passing_year">
                  Passing Year
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={passingyearsOptions}
                  placeholder="Select passing year"
                  value={formData.passing_year}
                  onChange={handlesPassingYearChange}
                  styles={customOptionsStyle}
                />
                {errors.passing_year && (
                  <small className="text-danger">{errors.passing_year}</small>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="jobseeker_passing_year">
                  Performance Scale
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={performanceScaleOptions}
                  placeholder="Select course type"
                  value={formData.performance_scale}
                  onChange={handlePerformanceScaleChange}
                  styles={customOptionsStyle}
                />
                {errors.performance_scale && (
                  <small className="text-danger">
                    {errors.performance_scale}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Performance
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="performance"
                  placeholder="Enter your performance"
                  value={formData.performance}
                  onChange={handleChange}
                />
                {errors.performance && (
                  <small className="text-danger">{errors.performance}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            disabled={addEducationReducerData.isLoading}
            color="primary"
            type="submit"
          >
            {props.data.id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EducationModal;
