import {
  UPDATE_CANDIDATE_EXPERIENCE_REQUEST,
  UPDATE_CANDIDATE_EXPERIENCE_SUCCESS,
  UPDATE_CANDIDATE_EXPERIENCE_ERROR,
  RESET_CANDIDATE_EXPERIENCE_MESSAGE,
} from "../../actionTypes";

// action creators
export const updateCandidateExperienceRequest = (data) => ({
  type: UPDATE_CANDIDATE_EXPERIENCE_REQUEST,
  payload: data,
});

export const updateCandidateExperienceSuccess = (message) => ({
  type: UPDATE_CANDIDATE_EXPERIENCE_SUCCESS,
  payload: message,
});

export const updateCandidateExperienceError = (error) => ({
  type: UPDATE_CANDIDATE_EXPERIENCE_ERROR,
  payload: error,
});

export const resetCandidateExperienceMessage = () => ({
  type: RESET_CANDIDATE_EXPERIENCE_MESSAGE,
});
