import {
  FETCH_CANDIDATE_REQUEST,
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATE_FAILURE,
} from "../../actionTypes";

export const fetchCandidateRequest = (page, per_page, data) => ({
  type: FETCH_CANDIDATE_REQUEST,
  payload: { page, per_page, data },
});

export const fetchCandidateSuccess = (data) => ({
  type: FETCH_CANDIDATE_SUCCESS,
  payload: data,
});

export const fetchCandidateFailure = (error) => ({
  type: FETCH_CANDIDATE_FAILURE,
  payload: error,
});
