// sagas.jsx
import { call, put, takeLatest } from "redux-saga/effects";
import { shareSeekerDataSuccess, shareSeekerDataFailure } from "./actions";
import { SHARE_SEEKER_DATA_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* shareSeekerData(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/share_seeker_data", action.payload)
    );

    if (response.data.status) {
      yield put(shareSeekerDataSuccess(response.data));
    } else {
      yield put(shareSeekerDataFailure(response.message));
    }
  } catch (error) {
    yield put(
      shareSeekerDataFailure({ status: false, message: error.message })
    ); // Dispatch failure action with error message
  }
}

function* shareSeekerDataSaga() {
  yield takeLatest(SHARE_SEEKER_DATA_REQUEST, shareSeekerData);
}

export default shareSeekerDataSaga;
