// reducers.js
import {
  SAVE_FAVORITE_JOB_REQUEST,
  SAVE_FAVORITE_JOB_SUCCESS,
  SAVE_FAVORITE_JOB_FAILURE,
  FETCH_FAVORITE_JOBS_REQUEST,
  FETCH_FAVORITE_JOBS_SUCCESS,
  FETCH_FAVORITE_JOBS_FAILURE,
  DELETE_FAVORITE_JOB_REQUEST,
  DELETE_FAVORITE_JOB_SUCCESS,
  DELETE_FAVORITE_JOB_FAILURE,
  RESET_FAVORITE_MESSAGE,
} from "../../actionTypes";

const initialState = {
  data: [], // Initialize data to null
  message: "",
  isLoading: false,
  status: false,
};

const favoriteJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FAVORITE_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_FAVORITE_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case SAVE_FAVORITE_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case FETCH_FAVORITE_JOBS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FAVORITE_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        //status: action.payload.status, // Set status from payload if needed
      };
    case FETCH_FAVORITE_JOBS_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        //  status: action.payload.status,
        message: action.payload.message,
      };
    case DELETE_FAVORITE_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_FAVORITE_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case DELETE_FAVORITE_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_FAVORITE_MESSAGE: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };
    default:
      return state;
  }
};

export default favoriteJobReducer;
