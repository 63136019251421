import {
  ADD_CONTACTED_COUNT_REQUEST,
  ADD_CONTACTED_COUNT_SUCCESS,
  ADD_CONTACTED_COUNT_FAILURE,
  RESET_MESSAGE,
} from "../../actionTypes";

// Action creators
export const addContactedCountRequest = (data) => ({
  type: ADD_CONTACTED_COUNT_REQUEST,
  payload: data,
});

export const addContactedCountSuccess = (message) => ({
  type: ADD_CONTACTED_COUNT_SUCCESS,
  payload: message,
});

export const addContactedCountFailure = (error) => ({
  type: ADD_CONTACTED_COUNT_FAILURE,
  payload: error,
});

export const resetContactedCountMessage = () => ({
  type: RESET_MESSAGE,
});
