import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST } from "../../actionTypes";

import {
  fetchJobseekerPublicProfileSuccess,
  fetchJobseekerPublicProfileFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* fetchJobseekerPublicProfile(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/public_jobseeker_data", action.payload)
    );

    if (response.data.status) {
      yield put(fetchJobseekerPublicProfileSuccess(response.data));
    } else {
      yield put(fetchJobseekerPublicProfileFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchJobseekerPublicProfileFailure({
        status: false,
        message: error.message,
      })
    );
  }
}

// Watcher saga
function* jobseekerPublicProfileSaga() {
  yield takeLatest(
    FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST,
    fetchJobseekerPublicProfile
  );
}
export default jobseekerPublicProfileSaga;
