import SweetAlert from "sweetalert2";

// const SweetAlerts = (props) => {
  export const showSweetAlert = (name,props) => {
    switch (name) {
      case "confirm":
        SweetAlert.fire({
          title: "Are you sure?",
          text: props.message,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ok",
          cancelButtonText: "cancel",
          reverseButtons: true,
        }).then((result) => {
          console.log(result);
          if (result.value) {
            props.fun(props.id)
          }
        });
        break;
      case "alertSuccess":
        SweetAlert.fire({
          title: "Success",
          text: props.message,
          icon: "success",
        });
        break;
      case "alertDanger":
        SweetAlert.fire({
          title: props.title,
          text: props.message,
          icon: "error",
        });
        break;
      case "alertInfo":
        SweetAlert.fire({
          title: props.title,
          text: props.message,
          icon: "info",
        });
        break;
      case "alertWarning":
        SweetAlert.fire({
          title: props.title,
          text: props.message,
          icon: "warning",
        });
        break;
      default:
        break;
    }
  };

  // showAlert(props.name);

// };

// export default SweetAlerts;
