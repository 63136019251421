import {
  UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST,
  UPDATE_CANDIDATE_PRIMARY_SKILLS_SUCCESS,
  UPDATE_CANDIDATE_PRIMARY_SKILLS_FAILURE,
  RESET_CANDIDATE_PRIMARY_SKILLS_STATE,
} from "../../actionTypes";

export const updateCandidatePrimarySkillsRequest = (message) => ({
  type: UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST,
  payload: message,
});

export const updateCandidatePrimarySkillsSuccess = (error) => ({
  type: UPDATE_CANDIDATE_PRIMARY_SKILLS_SUCCESS,
  payload: error,
});

export const updateCandidatePrimarySkillsFailure = () => ({
  type: UPDATE_CANDIDATE_PRIMARY_SKILLS_FAILURE,
});

export const resetCandidateUpdateState = () => ({
  type: RESET_CANDIDATE_PRIMARY_SKILLS_STATE,
});
