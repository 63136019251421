import {
  VERIFY_RECRUITER_INVITE_TOKEN_REQUEST,
  VERIFY_RECRUITER_INVITE_TOKEN_SUCCESS,
  VERIFY_RECRUITER_INVITE_TOKEN_FAILURE,
  RESET_VERIFY_RECRUITER_INVITE_TOKEN,
} from "../../actionTypes";

export const verifyRecruiterInviteTokenRequest = (data) => ({
  type: VERIFY_RECRUITER_INVITE_TOKEN_REQUEST,
  payload: data,
});

export const verifyRecruiterInviteTokenSuccess = (response) => ({
  type: VERIFY_RECRUITER_INVITE_TOKEN_SUCCESS,
  payload: response,
});

export const verifyRecruiterInviteTokenFailure = (error) => ({
  type: VERIFY_RECRUITER_INVITE_TOKEN_FAILURE,
  payload: error,
});

export const resetVerifyRecruiterInviteToken = () => ({
  type: RESET_VERIFY_RECRUITER_INVITE_TOKEN,
});
