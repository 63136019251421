import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { YourCandidateSearchContext } from "../../../../YourCandidateSearch/YourCandidateSearchContext";
import {
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Collapse,
  Input,
  Form,
} from "reactstrap";

// Import your action creator
import { fetchSkillsRequest } from "../../../../redux/mandatorySkills/actions";

const Skills = () => {
  const dispatch = useDispatch();
  const mandatorySkillsReducerData =
    useSelector((state) => state.mandatorySkillsReducer) || [];
  let mandatorySkillsData = [];
  if (
    !mandatorySkillsReducerData.isLoading &&
    mandatorySkillsReducerData.data.data
  ) {
    mandatorySkillsData = mandatorySkillsReducerData.data.data;
  }

  const [isFilter, setIsFilter] = useState(true);
  const { filters, updateFilters } = useContext(YourCandidateSearchContext);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    // Dispatch fetchSkills when component mounts
    dispatch(fetchSkillsRequest());
  }, [dispatch]);

  // Handle input change for search
  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);

    // Dispatch fetchSkills with the search keyword
    dispatch(fetchSkillsRequest(keyword));
  };

  // Handle checkbox change

  const handleCheckboxChange = (skill) => {
    // Check if the option's value already exists in job_type array
    const existingOption = filters.skills.find(
      (item) => item.value === skill.value
    );

    // Toggle the selected state for the clicked job type
    let updatedSkills = [];
    if (existingOption) {
      updatedSkills = filters.skills.filter(
        (item) => item.value !== skill.value
      );
    } else {
      updatedSkills = [...filters.skills, skill];
    }

    // Update the job types filter in the context with updated array
    updateFilters({ skills: updatedSkills });
  };

  // Merge and sort the skills to show selected ones first
  const mergedSkillsData = [...mandatorySkillsData];
  filters.skills.forEach((filterSkill) => {
    if (!mergedSkillsData.some((skill) => skill.value === filterSkill.value)) {
      mergedSkillsData.push(filterSkill);
    }
  });

  const sortedSkillsData = mergedSkillsData.sort((a, b) => {
    const isSelectedA = filters.skills.some((item) => item.value === a.value);
    const isSelectedB = filters.skills.some((item) => item.value === b.value);
    return isSelectedA === isSelectedB ? 0 : isSelectedA ? -1 : 1;
  });

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Skills</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="animate-chk border-0 ">
              <Form className="theme-form">
                <div className="job-filter mb-3">
                  <div className="faq-form">
                    <Input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Search skills.."
                      value={searchKeyword}
                      onChange={handleInputChange}
                    />
                    <i className="search-icon" data-feather="search"></i>
                  </div>
                </div>
              </Form>
              {mandatorySkillsReducerData.isLoading ? (
                <div>Loading...</div>
              ) : (
                <div
                  className="functional-area-checkbox custom-scrollbar"
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {sortedSkillsData.length > 0 ? (
                    sortedSkillsData.map((skill, index) => (
                      <label
                        key={index}
                        className="d-flex"
                        // htmlFor={`chk-ani${index}`}
                      >
                        <Input
                          className="checkbox_animated mr-2"
                          id={`chk-ani${index}`}
                          type="checkbox"
                          checked={filters.skills.some(
                            (item) => item.value === skill.value
                          )}
                          onChange={() => handleCheckboxChange(skill)}
                        />
                        {skill.label}
                      </label>
                    ))
                  ) : (
                    <p>No data found</p>
                  )}
                </div>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Skills;
