import React, { Fragment } from "react";
import Breadcrumbs from "../../Components/Common/Breadcrumb";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import ChatStatus from "../../Components/Recruiter/Chat/ChatStatus";
import Chatting from "../../Components/Recruiter/Chat/Chatting";

const ChatPage = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Chat App" parent="Chat" title="Chat App" />
      <Container fluid={true}>
        <Row>
          <Col className="call-chat-sidebar">
            <Card>
              <CardBody className="chat-body " style={{ padding: "15px" }}>
                <ChatStatus />
              </CardBody>
            </Card>
          </Col>
          <Col className="call-chat-body">
            <Card>
              <CardBody className="p-0">
                <Chatting />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ChatPage;
