import {
  VERIFY_RECRUITER_INVITE_TOKEN_REQUEST,
  VERIFY_RECRUITER_INVITE_TOKEN_SUCCESS,
  VERIFY_RECRUITER_INVITE_TOKEN_FAILURE,
  RESET_VERIFY_RECRUITER_INVITE_TOKEN,
} from "../../actionTypes";

const initialState = {
  status: false,
  message: "",
  error: null,
  isLoading: false, // Add isLoading state to manage loading status
};

const verifyRecruiterInviteTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_RECRUITER_INVITE_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_RECRUITER_INVITE_TOKEN_SUCCESS:
      return {
        ...state,
        status: true,
        isLoading: false,
        message: action.payload.message,
        error: null,
      };
    case VERIFY_RECRUITER_INVITE_TOKEN_FAILURE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: action.payload.message,
        error: action.payload,
      };
    case RESET_VERIFY_RECRUITER_INVITE_TOKEN:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default verifyRecruiterInviteTokenReducer;
