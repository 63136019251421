export const LOADER_TIMEOUT = 3000;
export const SYSTEM_NAME = "Naukrify";
export const SYSTEM_PREFIX = "NF";
export const COMPANY_LOGO = require("../assets/images/logo/logo.png");
export const WEBSITE_NAME = "Naukarify";
export const AVATAR = require("../assets/images/avatar.png");
export const COINS = "coins";

export const customOptionsStyle = {
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#f1f0ff", // Change background color
    color: "black", // Change text color
  }),
};

// inputStyles.js
export const readOnlyStyle = {
  backgroundColor: "#f5f5f5",
  color: "hsl(0, 0%, 20%)",
  // Add other default styles if needed
};

export const getCandidateStatusColor = (status) => {
  switch (status) {
    case "HIRED":
      return "badge-light-success";
    case "SHORTLISTED":
      return "badge-light-success";
    case "INTERVIEWED":
      return "badge-light-primary";
    case "PENDING":
      return "badge-light-info";
    case "VIEWED":
      return "badge-light-warning";
    case "REJECTED":
      return "badge-light-danger";
    case "ACCEPTED":
      return "badge-light-success";
    default:
      return "badge-light-primary";
  }
};

export const getPassbookStatusColor = (status) => {
  // Implement your logic to determine the badge color based on status
  // For example:
  switch (status) {
    case "CREDIT":
      return "badge-light-success";
    case "DEBIT":
      return "badge-light-danger";

    default:
      return "badge-light-primary";
  }
};

export const yearsOptions = Array.from({ length: 10 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1} year${i !== 0 ? "s" : ""}`,
}));

export const monthsOptions = Array.from({ length: 12 }, (_, i) => ({
  value: i,
  label: `${i} month${i === 1 ? "" : "s"}`,
})).map((option) => {
  if (option.value === 0) {
    option.label = "0 month";
  }
  return option;
});

export const ratingOptions = Array.from({ length: 10 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1}`,
}));

const currentYear = new Date().getFullYear();
export const passingyearsOptions = [];

for (let year = currentYear; year >= 1940; year--) {
  passingyearsOptions.push({ label: year.toString(), value: year.toString() });
}

export const performanceScaleOptions = [
  { label: "Percentage", value: 100 },
  { label: "CGPA (Scale of 10)", value: 10 },
  { label: "CGPA (Scale of 7)", value: 7 },
  { label: "CGPA (Scale of 5)", value: 5 },
  { label: "CGPA (Scale of 4)", value: 4 },
];

export const jobTypeOptions = [
  { value: "FULL_TIME", label: "Full Time" },
  { value: "PART_TIME", label: "Part Time" },
  { value: "CONTRACT", label: "Contract" },
  { value: "CONTRACT_ON_HIRE", label: "Contract on Hire" },
];

export const employmentTypeOptions = [
  { value: "PERMANENT", label: "Permanent" },
  { value: "TEMPORARY/CONTRACTUAL", label: "Temporary/Contractual Job" },
  { value: "FREELANCE/HOME_BASED", label: "Freelance/Home Based" },
  { value: "INTERNSHIP", label: "Apprenticeship/Internship" },
];

export const jobShiftOptions = [
  { value: "DAY", label: "Day" },
  { value: "NIGHT", label: "Night" },
  { value: "FLEXIBLE", label: "Flexible" },
];

export const experienceOptions = [
  { value: "0", label: "0 year" },
  { value: "1", label: "1 year" },
  { value: "2", label: "2 years" },
  { value: "3", label: "3 years" },
  { value: "4", label: "4 years" },
  { value: "5", label: "5 years" },
  { value: "6", label: "6 years" },
  { value: "7", label: "7 years" },
  { value: "8", label: "8 years" },
  { value: "9", label: "9 years" },
  { value: "10", label: "10 years" },
  { value: "11", label: "11 years" },
  { value: "12", label: "12 years" },
  { value: "13", label: "13 years" },
  { value: "14", label: "14 years" },
  { value: "15", label: "15 years" },
  { value: "16", label: "16 years" },
  { value: "17", label: "17 years" },
  { value: "18", label: "18 years" },
  { value: "19", label: "19 years" },
  { value: "20", label: "20 years" },
  { value: "21", label: "21 years" },
  { value: "22", label: "22 years" },
  { value: "23", label: "23 years" },
  { value: "24", label: "24 years" },
  { value: "25", label: "25 years" },
  { value: "26", label: "26 years" },
  { value: "27", label: "27 years" },
  { value: "28", label: "28 years" },
  { value: "29", label: "29 years" },
  { value: "30", label: "30 years" },
];

export const salaryRangeOptions = [
  { value: "20000", label: "20,000" },
  { value: "50000", label: "50,000" },
  { value: "60000", label: "60,000" },
  { value: "70000", label: "70,000" },
  { value: "80000", label: "80,000" },
  { value: "90000", label: "90,000" },
  { value: "100000", label: "1,00,000" },
  { value: "125000", label: "1,25,000" },
  { value: "150000", label: "1,50,000" },
  { value: "175000", label: "1,75,000" },
  { value: "200000", label: "2,00,000" },
  { value: "225000", label: "2,25,000" },
  { value: "250000", label: "2,50,000" },
  { value: "275000", label: "2,75,000" },
  { value: "300000", label: "3,00,000" },
  { value: "325000", label: "3,25,000" },
  { value: "350000", label: "3,50,000" },
  { value: "375000", label: "3,75,000" },
  { value: "400000", label: "4,00,000" },
  { value: "425000", label: "4,25,000" },
  { value: "450000", label: "4,50,000" },
  { value: "475000", label: "4,75,000" },
  { value: "500000", label: "5,00,000" },
  { value: "550000", label: "5,50,000" },
  { value: "600000", label: "6,00,000" },
  { value: "650000", label: "6,50,000" },
  { value: "700000", label: "7,00,000" },
  { value: "750000", label: "7,50,000" },
  { value: "800000", label: "8,00,000" },
  { value: "850000", label: "8,50,000" },
  { value: "900000", label: "9,00,000" },
  { value: "950000", label: "9,50,000" },
  { value: "1000000", label: "10,00,000" },
  { value: "1100000", label: "11,00,000" },
  { value: "1200000", label: "12,00,000" },
  { value: "1300000", label: "13,00,000" },
  { value: "1400000", label: "14,00,000" },
  { value: "1500000", label: "15,00,000" },
  { value: "1600000", label: "16,00,000" },
  { value: "1700000", label: "17,00,000" },
  { value: "1800000", label: "18,00,000" },
  { value: "1900000", label: "19,00,000" },
  { value: "2000000", label: "20,00,000" },
  { value: "2250000", label: "22,50,000" },
  { value: "2500000", label: "25,00,000" },
  { value: "2750000", label: "27,50,000" },
  { value: "3000000", label: "30,00,000" },
  { value: "3250000", label: "32,50,000" },
  { value: "3500000", label: "35,00,000" },
  { value: "3750000", label: "37,50,000" },
  { value: "4000000", label: "40,00,000" },
  { value: "4500000", label: "45,00,000" },
  { value: "5000000", label: "50,00,000" },
  { value: "10000000", label: "1,00,00,000 & above" },
];

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const freshnessOptions = [
  { value: 1, label: "Today" },
  { value: 3, label: "Last 3 days" },
  { value: 7, label: "Last 7 days" },

  { value: 30, label: "Last 30 days" },
  { value: 180, label: "Last 6 month" }, // Add this option
];

export const maritalStatusOptions = [
  { label: "Married", value: "married" },
  { label: "Unmarried", value: "unmarried" },
];

export const categoryOptions = [{ label: "General", value: "general" }];

export const yesnoOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const socialMediaOptions = [
  { value: "LINKEDIN", label: "Linked In" },
  { value: "FACEBOOK", label: "Facebook" },
  { value: "GITHUB", label: "GitHub" },
  { value: "TWITTER", label: "Twitter" },
  { value: "STACK_OVERFLOW", label: "Stack Overflow" },
];

export const educationBoardsOptions = [
  { label: "Central Board of Secondary Education (CBSE)", value: "CBSE" },
  {
    label:
      "Council for the Indian School Certificate Examinations (CISCE) (ICSE/ISC)",
    value: "CISCE",
  },
  { label: "Diploma", value: "DIPLOMA" },
  { label: "National Open School", value: "NATIONAL OPEN SCHOOL" },
  { label: "International Baccalaureate (IB)", value: "IB" },
  {
    label: "Andhra Pradesh Board of Intermediate Education (BIEAP)",
    value: "BIEAP",
  },
  {
    label: "Telangana State Board of Intermediate Education (TSBIE)",
    value: "TSBIE",
  },
  { label: "Assam Higher Secondary Education Council (AHSEC)", value: "AHSEC" },
  { label: "Bihar School Examination Board (BSEB)", value: "BSEB" },
  {
    label: "Chhattisgarh Board of Secondary Education (CGBSE)",
    value: "CGBSE",
  },
  {
    label: "Goa Board of Secondary & Higher Secondary Education (GBSHSE)",
    value: "GBSHSE",
  },
  {
    label: "Gujarat Secondary and Higher Secondary Education Board (GSEB)",
    value: "GSEB",
  },
  { label: "Haryana Board of School Education (HBSE)", value: "HBSE" },
  {
    label: "Himachal Pradesh Board of School Education (HPBOSE)",
    value: "HPBOSE",
  },
  {
    label: "Jammu and Kashmir State Board of School Education (JKBOSE)",
    value: "JKBOSE",
  },
  { label: "Jharkhand Academic Council (JAC)", value: "JAC" },
  {
    label: "Karnataka Secondary Education Examination Board (KSEEB)",
    value: "KSEEB",
  },
  {
    label: "Kerala Higher Secondary Examination Board (DHSE Kerala)",
    value: "DHSE KERALA",
  },
  {
    label: "Madhya Pradesh Board of Secondary Education (MPBSE)",
    value: "MPBSE",
  },
  {
    label:
      "Maharashtra State Board of Secondary and Higher Secondary Education (MSBSHSE)",
    value: "MSBSHSE",
  },
  {
    label: "Manipur Council of Higher Secondary Education (CHSEM)",
    value: "CHSEM",
  },
  { label: "Meghalaya Board of School Education (MBOSE)", value: "MBOSE" },
  { label: "Mizoram Board of School Education (MBSE)", value: "MBSE" },
  { label: "Nagaland Board of School Education (NBSE)", value: "NBSE" },
  {
    label: "Odisha Board of Secondary Education (BSE Odisha)",
    value: "BSE ODISHA",
  },
  { label: "Punjab School Education Board (PSEB)", value: "PSEB" },
  { label: "Rajasthan Board of Secondary Education (RBSE)", value: "RBSE" },
  { label: "Tamil Nadu Board of Secondary Education (TNBSE)", value: "TNBSE" },
  { label: "Tripura Board of Secondary Education (TBSE)", value: "TBSE" },
  { label: "Uttar Pradesh Madhyamik Shiksha Parishad (UPMSP)", value: "UPMSP" },
  { label: "Uttarakhand Board of School Education (UBSE)", value: "UBSE" },
  {
    label: "West Bengal Council of Higher Secondary Education (WBCHSE)",
    value: "WBCHSE",
  },
];

export const schoolMediumsOptions = [
  { label: "Assamese", value: "ASSAMESE" },
  { label: "Bengali", value: "BENGALI" },
  { label: "Gujarati", value: "GUJARATI" },
  { label: "Hindi", value: "HINDI" },
  { label: "Kannada", value: "KANNADA" },
  { label: "Malayalam", value: "MALAYALAM" },
  { label: "Marathi", value: "MARATHI" },
  { label: "Odia", value: "ODIA" },
  { label: "Punjabi", value: "PUNJABI" },
  { label: "Tamil", value: "TAMIL" },
  { label: "Telugu", value: "TELUGU" },
  { label: "Urdu", value: "URDU" },
  { label: "English", value: "ENGLISH" },
  { label: "Regional Language", value: "REGIONAL_LANGUAGE" },
  { label: "Bilingual", value: "BILINGUAL" },
  { label: "International", value: "INTERNATIONAL" },
];

export const courseTypeOptions = [
  { label: "Full-time", value: "FULL_TIME" },
  { label: "Part-time", value: "PART_TIME" },
  { label: "Correspondence", value: "CORRESPONDENCE" },
];

export const educationLevelsOptions = [
  { label: "X (Secondary)", value: "SECONDARY" },
  { label: "XII (Senior Secondary)", value: "SENIOR_SECONDARY" },
  { label: "Graduation", value: "GRADUATION" },
  { label: "Diploma", value: "DIPLOMA" },
  { label: "Post Graduation", value: "POST_GRADUATION" },
  { label: "PhD", value: "PHD" },
];

export const countryCodes = [
  { label: "USA (+1)", value: "+1" },
  { label: "India (+91)", value: "+91" },
  { label: "UK (+44)", value: "+44" },
  { label: "Australia (+61)", value: "+61" },
  { label: "Japan (+81)", value: "+81" },
  // Add more country objects as needed
];

export const statusOptions = [
  { value: "INTERVIEWED", label: "Interviewed" },
  { value: "SHORTLISTED", label: "Shortlisted" },
  { value: "ON_HOLD", label: "On Hold" },
  { value: "HIRED", label: "Hired" },
  { value: "REJECTED", label: "Rejected" },
];

export const contactibilityOptions = [
  { value: "NOT_REACHABLE", label: "Not Reachable" },
  { value: "CALL_LATER", label: "Call Later" },
  { value: "CALLED", label: "Called" },
  { value: "MAIL_SENT", label: "Mail Sent" },
  { value: "NOT_PICKING", label: "Not Picking Up" },
  { value: "WRONG_NUMBER", label: "Wrong Number" },
];

export const communicationsOptions = [
  { value: "CLEAR_CONCISE", label: "Clear and concise communication" },
  { value: "ACTIVE_LISTENING", label: "Active listening" },
  { value: "PROFESSIONAL_TONE", label: "Professional tone" },
  { value: "WRITTEN_SKILLS", label: "Written communication skills" },
];

export const technicalSkillsOptions = [
  {
    value: "SOFTWARE_PROFICIENCY",
    label: "Proficiency in specific software/tools",
  },
  { value: "CODING_LANGUAGES", label: "Coding languages" },
  { value: "INDUSTRY_KNOWLEDGE", label: "Industry-specific knowledge" },
  { value: "DATA_ANALYSIS", label: "Data analysis skills" },
];

export const softSkillsOptions = [
  { value: "TEAMWORK_COLLABORATION", label: "Teamwork/collaboration" },
  { value: "PROBLEM_SOLVING", label: "Problem-solving abilities" },
  { value: "ADAPTABILITY", label: "Adaptability" },
  { value: "LEADERSHIP_POTENTIAL", label: "Leadership potential" },
];

export const experienceFitOptions = [
  { value: "WORK_EXPERIENCE", label: "Relevant work experience" },
  { value: "INDUSTRY_KNOWLEDGE", label: "Industry knowledge" },
  { value: "PREVIOUS_PROJECTS", label: "Previous projects or achievements" },
];

export const jobsourceOptions = [
  { value: "EMPLOYEE REFERRAL", label: "Employee Referral" },

  { value: "CANDIDATE APPLYING DIRECT", label: "Candidate Applying Direct" },
  { value: "OTHER", label: "Other" },
];

export const jobStatusOptions = [
  { value: "ACTIVE", label: "Active" },
  { value: "ON_HOLD", label: "On Hold" },
  { value: "CLOSED", label: "Closed" },
  { value: "DRAFT", label: "Draft" },
  // Add more status options as needed
];

export const shiftTypeOptions = [
  { value: "EARLY_MORNING_SHIFT", label: "Early Morning Shift" },
  { value: "NORMAL_SHIFT", label: "Normal Shift" },
  { value: "NIGHT_SHIFT", label: "Night Shift" },
  { value: "ROTATIONAL_SHIFT", label: "Rotational Shift" },
  { value: "US_SHIFT", label: "US Shift" },
  { value: "UK_SHIFT", label: "UK Shift" },
];

export const publicJobsourceOptions = [
  { value: "EMPLOYEE REFERRAL", label: "Employee Referral" },

  { value: "CANDIDATE APPLYING DIRECT", label: "Candidate Applying Direct" },
];

export const jobPostingTypesOptions = [
  {
    label: "Internal",
    value: "INTERNAL",
  },
  {
    label: "External",
    value: "EXTERNAL",
  },
];

export const jobPipelineOptions = [
  { value: "NEW", label: "New" },
  { value: "PHONESCREEN", label: "Phone Screen" },
  { value: "INTERVIEW", label: "Interview" },
  { value: "OFFER", label: "Offer" },
  { value: "HIRED", label: "Hired" },
  { value: "REJECTED", label: "Rejected" },
];

export const weeklyStatusOptions = [
  { value: "WEEK1", label: "April 1, 2024 - April 7, 2024" },
  { value: "WEEK2", label: "April 8, 2024 - April 14, 2024" },
  { value: "WEEK3", label: "April 15, 2024 - April 21, 2024" },
  // Add more weeks as needed
];

export const reportReason = [
  { value: "FRAUDULENT JOB POSTINGS", label: "Fraudulent Job Postings" },
  {
    value: "REQUESTING PAYMENT FOR EMPLOYMENT",
    label: "Requesting Payment for Employment",
  },
  { value: "IDENTIFY THEFT", label: "Identity Theft" },
  {
    value: "UNVERIFIES EMPLOYER OR COMPANIES",
    label: "Unverified Employers or Companies",
  },
  { value: "PROTECTING OTHER", label: "Protecting Other" },
];

export const roleOptions = [
  { value: "ACCOUNT_MANAGER", label: "Account manager" },
  { value: "LEAD_RECRUITER", label: "Lead Recruiter" },
  { value: "RECRUITER", label: "Recruiter" },
  // Add more roles as needed
];

export const customStyles = {
  headCells: {
    style: {
      fontSize: "14px",
      // justifyContent: "center !important", // Align header content to center
      whiteSpace: "normal",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      // justifyContent: "center !important", // Align header content to center
    },
  },
};

export const emptyField = (str) => {
  if (str === null || "") {
    return "-";
  } else {
    return str;
  }
};

export const discloseCompanyOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const benefitsOptions = [
  {
    value: "health_insurance",
    label: "Health Insurance",
  },
  {
    value: "provident_fund",
    label: "Provident Fund",
  },
  {
    value: "cell_phone_reimbursement", // Corrected to use snake_case consistently
    label: "Cell Phone Reimbursement",
  },
  {
    value: "paid_sick_time",
    label: "Paid Sick Time",
  },
  {
    value: "work_from_home",
    label: "Work From Home",
  },
  {
    value: "paid_time_off",
    label: "Paid Time Off (PTO)",
  },
  {
    value: "food_provided",
    label: "Food Provided",
  },
  {
    value: "life_insurance",
    label: "Life Insurance",
  },
  {
    value: "internet_reimbursement",
    label: "Internet Reimbursement",
  },
  {
    value: "commuter_assistance",
    label: "Commuter Assistance",
  },
  {
    value: "leave_encashment",
    label: "Leave Encashment",
  },
  {
    value: "flexible_schedule",
    label: "Flexible Schedule",
  },
  {
    value: "other",
    label: "Other",
  },
  // Add any other benefits here
];

// user types
export const USER_TYPES = ["ADMIN", "JOBSEEKER", "RECRUITER"];

// dropdownOptions.js
export const recruiterBannerOptions = [
  { value: "ALL", label: "All" },
  { value: "ATTITUDE", label: "Attitude" },
  { value: "MOTIVATION", label: "Motivation" },
  { value: "HIRING", label: "Hiring" },
  { value: "CUSTOMIZABLE", label: "Customizable" },
];

export const jobseekerBannerOptions = [
  { value: "ALL", label: "All" },
  { value: "ATTITUDE", label: "Attitude" },
  { value: "MOTIVATION", label: "Motivation" },
  { value: "CUSTOMIZABLE", label: "Customizable" },
  { value: "QUOTES", label: "Quotes" },
];
