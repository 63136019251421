// reducer.js
import {
  CHECK_TEMPLATE_LOCK_REQUEST,
  CHECK_TEMPLATE_LOCK_SUCCESS,
  CHECK_TEMPLATE_LOCK_FAILURE,
} from "../../actionTypes";

const initialState = {
  data: {},
  status: false,
  isLoading: false,
  message: "",
};

const checkTemplateLockedReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_TEMPLATE_LOCK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CHECK_TEMPLATE_LOCK_SUCCESS:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,

        isPurchased: action.payload.is_purchased,
        price: action.payload.price,
        label: action.payload.label,
      };
    case CHECK_TEMPLATE_LOCK_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default checkTemplateLockedReducer;
