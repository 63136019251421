import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import { addDesireCareerFailure, addDesireCareerSuccess } from "./actions";

function* addDesireCareer(action) {
  console.log('ad',action);
  try {
    
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/add_desired_details", action.payload)
    );
    console.log('sr',response);
    if (response.data.status) {
      yield put(addDesireCareerSuccess(response.data));
    } else {
      yield put(addDesireCareerSuccess(response.data));
    }
  } catch (error) {
    yield put(addDesireCareerFailure({ status: false, message: error.message }));
  }
}

function* desireCareerSaga() {
  yield takeLatest(types.UPDATE_DESIRED_CAREER_PROFILE_REQUEST, addDesireCareer);
}

export default desireCareerSaga;
