// Pagination.js

import React, { Fragment } from "react";
import { Col } from "reactstrap";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  console.log(totalPages, currentPage, onPageChange);

  const handlePageClick = (page, event) => {
    event.preventDefault(); // Prevent default anchor tag behavior
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""} border-0`}
        >
          <a
            href="#javascript"
            className="page-link"
            onClick={(e) => handlePageClick(i, e)} // Pass event to the handler
          >
            {i}
          </a>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <Fragment>
      <Col xs="12" className="mb-2">
        <nav aria-label="...">
          <ul className="pagination pagination-primary justify-content-end simple-list d-flex flex-row">
            {/* Previous button */}
            <li
              className={`page-item ${
                currentPage === 1 ? "disabled" : ""
              } border-0`}
            >
              <a
                className="page-link"
                href="#javascript"
                tabIndex="-1"
                onClick={(e) => handlePageClick(currentPage - 1, e)} // Pass event to the handler
              >
                Previous
              </a>
            </li>
            {/* Page numbers */}
            {renderPageNumbers()}
            {/* Next button */}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              } border-0`}
            >
              <a
                className="page-link"
                href="#javascript"
                onClick={(e) => handlePageClick(currentPage + 1, e)} // Pass event to the handler
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      </Col>
    </Fragment>
  );
};

export default Pagination;
