import { call, put, takeLatest } from "redux-saga/effects";
import { RESET_PASSWORD_REQUEST } from "../../actionTypes";
// import { postJobApi } from "./api"; // Replace with your actual API file
import AxiosInstance from "../../../services/AxiosInstance";

import { resetPasswordSuccess, resetPasswordFailure } from "./actions";

function* resetPassword(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/auth/reset_password", action.payload)
    );

    if (response.data.status) {
      yield put(resetPasswordSuccess(response.data));
    } else {
      yield put(resetPasswordFailure(response.data));
    }
  } catch (error) {
    yield put(resetPasswordFailure({ status: false, message: error.message }));
  }
}

// Watch for POST_JOB_REQUEST action
function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}

export default resetPasswordSaga;
