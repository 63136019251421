import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Label,
  Button,
} from "reactstrap";

const Filter = ({ onClearAll }) => {
  const [isFilter, setIsFilter] = useState(true);
  const handleClearAll = () => {
    // Call the onClearAll function passed from the parent component
    if (onClearAll) {
      onClearAll();
    }
  };
  return (
    <Fragment>
      <Col xl="12">
      <Card>
        <CardBody className="p-3 d-flex justify-content-between align-items-center">
          <h5 className="mb-0 p-0">Filters</h5>
          <Button
            className="btn text-center"
            color="primary"
            onClick={handleClearAll}
            type="button"
          >
            Clear All
          </Button>
        </CardBody>
      </Card>
    </Col>
    </Fragment>
  );
};

export default Filter;
