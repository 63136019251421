import {
  FETCH_RESUME_TEMPLATES_REQUEST,
  FETCH_RESUME_TEMPLATES_SUCCESS,
  FETCH_RESUME_TEMPLATES_FAILURE,
} from "../../actionTypes";

export const fetchResumeTemplates = (type) => ({
  type: FETCH_RESUME_TEMPLATES_REQUEST,
  payload: type,
});

export const fetchResumeTemplatesSuccess = (data) => ({
  type: FETCH_RESUME_TEMPLATES_SUCCESS,
  payload: data,
});

export const fetchResumeTemplatesFailure = (error) => ({
  type: FETCH_RESUME_TEMPLATES_FAILURE,
  payload: error,
});
