import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Card,
  Badge,
} from "reactstrap";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Worksamplemodal from "./Worksamplemodal";
import { showSweetAlert } from "../../Common/SweetAlert";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { formatDate } from "../../Common/CommonFunction";
import {
  deleteWorkSampleRequest,
  resetData,
} from "../../../redux/jobseeker/work_sample/actions";
import { showToast } from "../../Common/Toast";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";
import { capitalize } from "../../Common/CommonFunction";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";

const Worksample = ({ profileData }) => {
  const dispatch = useDispatch();
  const { type, cand_id, jobcad_id } = useParams();
  const [showWorkSample, setWorkSample] = useState(false);

  const [updateWorkSample, setUpdateWorkSample] = useState({
    id: null,
    seeker_id: null,
    work_title: "",
    work_start: "",
    work_end: "",
    work_url: "",
    work_description: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onEditSubmit = (data) => {
    alert(data);
  };

  const editworkSampleModal = (data) => {
    setWorkSample(true);
    setUpdateWorkSample({
      id: data.id,
      seeker_id: data.seeker_id,
      work_title: data.work_title,
      work_start: data.work_start,
      work_end: data.work_end,
      work_url: data.work_url,
      work_description: data.work_description,
    });
  };

  const addWorkSample = () => {
    setWorkSample(true);
    setUpdateWorkSample({
      id: null,
      seeker_id: extractAfterLastHyphen(cand_id),
      work_title: "",
      work_start: "",
      work_end: "",
      work_url: "",
      work_description: "",
    });
  };

  const toggleWorkSampleModal = () => {
    setWorkSample(false);
  };

  const handleDeleteWorkSampleClick = (work) => {
    showSweetAlert("confirm", {
      message: "You want to delete the job",
      fun: handleDeleteWorkSample,
      id: work,
    });
  };

  const handleDeleteWorkSample = (work) => {
    // Dispatch the delete work sample action
    dispatch(
      deleteWorkSampleRequest({ id: work.id, seeker_id: work.seeker_id })
    );
  };

  const addWorkSampleReducerData = useSelector(
    (state) => state.workSampleReducer
  );
  useEffect(() => {
    console.log("srd", addWorkSampleReducerData);
    if (
      addWorkSampleReducerData.status &&
      addWorkSampleReducerData.message != ""
    ) {
      showToast("success", addWorkSampleReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );

      dispatch(resetData());
    } else if (
      addWorkSampleReducerData.status == false &&
      addWorkSampleReducerData.message != ""
    ) {
      showToast("error", addWorkSampleReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      dispatch(resetData());
    }
  }, [addWorkSampleReducerData]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between ">
            <h4 className="mb-0" style={{ fontWeight: 550 }}>
              Work Sample
            </h4>
            {type == "view" ? null : (
              <FiPlusSquare
                className="add-icon "
                size={22}
                onClick={addWorkSample}
                title="Add"
              />
            )}
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ps-2">
            {profileData.work_sample && profileData.work_sample.length > 0 ? (
              // Render work sample data if available
              profileData.work_sample.map((work) => (
                <div
                  className="d-flex justify-content-between setup mb-3"
                  key={work.id}
                >
                  <div>
                    <h5>
                      {capitalize(work.work_title)} (
                      {formatDate(work.work_start)} to{" "}
                      {formatDate(work.work_end)})
                    </h5>

                    <p className="mb-1">
                      <a href={work.work_url}>{work.work_url}</a>
                    </p>

                    <p className="mb-1">{capitalize(work.work_description)}</p>
                  </div>
                  <div className="ms-2">
                    {type == "view" ? null : (
                      <div className="ms-2">
                        <div className="d-flex">
                          <FiEdit
                            className="edit-icon  me-2"
                            size={22}
                            onClick={() => {
                              editworkSampleModal({
                                id: work.id,
                                seeker_id: work.seeker_id,
                                work_title: work.work_title,
                                work_start: work.work_start,
                                work_end: work.work_end,
                                work_url: work.work_url,
                                work_description: work.work_description,
                              });
                            }}
                            title="Edit"
                          />

                          <RiDeleteBinLine
                            className="delete-icon "
                            size={22}
                            onClick={() => handleDeleteWorkSampleClick(work)}
                            title="Delete"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              // Render "No data found" message if no work sample data
              <div className="text-center">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>

      {showWorkSample && (
        <Worksamplemodal
          isOpen={showWorkSample}
          toggleModal={toggleWorkSampleModal}
          data={updateWorkSample}
        />
      )}
    </Fragment>
  );
};
export default Worksample;
