import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_LINKEDIN_BANNER_REQUEST } from "../actionTypes";

import {
  updateLinkedInBannerSuccess,
  updateLinkedInBannerFailure,
} from "./actions";
import AxiosInstance from "../../services/AxiosInstance";
// Worker saga
function* updateLinkedInBanner(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/update_banner", action.payload)
    );

    if (response.data.status == true) {
      yield put(updateLinkedInBannerSuccess(response.data));
    } else {
      yield put(updateLinkedInBannerFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateLinkedInBannerFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* updateLinkedinBannerSaga() {
  yield takeLatest(UPDATE_LINKEDIN_BANNER_REQUEST, updateLinkedInBanner);
}
export default updateLinkedinBannerSaga;
