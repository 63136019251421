import {
  UPDATE_JOBSEEKER_PASSWORD_REQUEST,
  UPDATE_JOBSEEKER_PASSWORD_SUCCESS,
  UPDATE_JOBSEEKER_PASSWORD_FAILURE,
  RESET_JOBSEEKER_PASSWORD_MESSAGE,
} from "../../actionTypes";

// Action creators
export const updateJobseekerPasswordRequest = (
  oldPassword,
  newPassword,
  confirmPassword
) => ({
  type: UPDATE_JOBSEEKER_PASSWORD_REQUEST,
  payload: {
    current_pass: oldPassword,
    new_pass: newPassword,
    confirm_pass: confirmPassword,
  },
});

export const updateJobseekerPasswordSuccess = (message) => ({
  type: UPDATE_JOBSEEKER_PASSWORD_SUCCESS,
  payload: message,
});

export const updateJobseekerPasswordFailure = (error) => ({
  type: UPDATE_JOBSEEKER_PASSWORD_FAILURE,
  payload: error,
});

export const resetJobseekerPasswordMessage = () => ({
  type: RESET_JOBSEEKER_PASSWORD_MESSAGE,
});
