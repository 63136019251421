import * as types from '../../actionTypes';

export const updateResumeHeadlineRequest = (data) => ({
  type: types.UPDATE_RESUME_HEADLINE_REQUEST,
  payload:data
});

export const updateResumeSuccess = (data) => ({
  type: types.UPDATE_RESUME_HEADLINE_SUCCESS,
  payload: data,
});

export const updateResumeFailure = (error) => ({
  type: types.UPDATE_RESUME_HEADLINE_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA
});








