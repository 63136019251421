import React from "react";
import ReactApexChart from "react-apexcharts";

const WidgetsRadialChart = {
  series: [],
  options: {
    chart: {
      height: 150,
      type: "radialBar",
      dropShadow: {
        enabled: true,
        top: 3,
        left: 0,
        blur: 10,
        color: "",
        opacity: 0.35,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
        track: {
          strokeWidth: "45%",
          opacity: 1,
          margin: 5,
        },
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--chart-text-color)",
            fontSize: "18px",
            show: true,
            offsetY: -8,
          },
        },
      },
    },
    colors: [],
    stroke: {
      lineCap: "round",
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
  },
};

const RadialProgressChart = ({ chartOption, chartHeight }) => {
  const updatedOption = {
    ...WidgetsRadialChart,
    series: chartOption.chart.series,
    options: {
      ...WidgetsRadialChart.options,
      chart: {
        ...WidgetsRadialChart.options.chart,
        dropShadow: {
          ...WidgetsRadialChart.options.chart.dropShadow,
          color: chartOption.chart.color,
        },
      },
      colors: [chartOption.chart.color],
    },
  };
  return (
    <ReactApexChart
      type="radialBar"
      height={chartHeight}
      series={updatedOption.series}
      options={updatedOption.options}
    />
  );
};

export default RadialProgressChart;
