import {
  FETCH_RECRUITER_INVITES_REQUEST,
  FETCH_RECRUITER_INVITES_SUCCESS,
  FETCH_RECRUITER_INVITES_FAILURE,
  RESET_FETCH_RECRUITER_INVITES,
} from "../../actionTypes";

export const fetchRecruiterInvitesRequest = (data) => ({
  type: FETCH_RECRUITER_INVITES_REQUEST,
  payload: data,
});

export const fetchRecruiterInvitesSuccess = (data) => ({
  type: FETCH_RECRUITER_INVITES_SUCCESS,
  payload: data,
});

export const fetchRecruiterInvitesFailure = (error) => ({
  type: FETCH_RECRUITER_INVITES_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetFetchRecruiterInvites = () => ({
  type: RESET_FETCH_RECRUITER_INVITES,
});
