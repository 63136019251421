import * as types from "../../actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const appliedJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_APPLIED_JOBS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.FETCH_APPLIED_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case types.FETCH_APPLIED_JOBS_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default appliedJobsReducer;
