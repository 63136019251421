// reducers.js
import {
  JOIN_GROUP_REQUEST,
  JOIN_GROUP_SUCCESS,
  JOIN_GROUP_FAILURE,
  JOIN_GROUP_RESET,
} from "../actionTypes";

const initialState = {
  data: null, // Initialize data to null
  message: "",
  isLoading: false,
  status: false,
};

const joinGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOIN_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case JOIN_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
        type: action.payload.type,
      };
    case JOIN_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case JOIN_GROUP_RESET: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };

    default:
      return state;
  }
};

export default joinGroupReducer;
