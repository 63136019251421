import {
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  RESET_PASSWORD_MESSAGE,
} from "../../actionTypes";

// Action creators
export const updatePasswordRequest = (
  oldPassword,
  newPassword,
  confirmPassword
) => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload: {
    current_pass: oldPassword,
    new_pass: newPassword,
    confirm_pass: confirmPassword,
  },
});

export const updatePasswordSuccess = (message) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: message,
});

export const updatePasswordFailure = (error) => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload: error,
});

export const resetPasswordMessage = () => ({
  type: RESET_PASSWORD_MESSAGE,
});
