import * as types from "../../actionTypes";

export const updateAvatar = (data) => {
  console.log(data);
  return { type: types.UPDATE_JOBSEEKER_AVATAR, payload: data };
};

export const resetAvatarData = () => ({
  type: types.RESET_JOOBSEEKER_AVATAR_DATA,
});
