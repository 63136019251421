// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchRecruiterInvitesSuccess,
  fetchRecruiterInvitesFailure,
} from "./actions";
import { FETCH_RECRUITER_INVITES_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* fetchRecruiterInvites(action) {
  const { page, per_page } = action.payload;
  try {
    const response = yield call(() =>
      AxiosInstance().post(
        `/recruiter/fetch_invitation?page=${page}&per_page=${per_page}`
      )
    );
    if (response.data.status == true) {
      yield put(fetchRecruiterInvitesSuccess(response.data));
    } else {
      yield put(fetchRecruiterInvitesFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchRecruiterInvitesFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* fetchRecruiterInviteSaga() {
  yield takeLatest(FETCH_RECRUITER_INVITES_REQUEST, fetchRecruiterInvites);
}
export default fetchRecruiterInviteSaga;
