import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useDispatch } from 'react-redux';
// import { addCompany } from '../redux/actions';

const FormComponent = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    company_name: '',
    full_name: '',
    mobile_code: '',
    mobilenumber: '',
    address: '',
    website: '',
    about: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear the error message when input changes
    const updatedErrors = { ...errors, [name]: '' };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // dispatch(addCompany(formData));
      // Reset form data
      setFormData({
        company_name: '',
        full_name: '',
        mobile_code: '',
        mobilenumber: '',
        address: '',
        website: '',
        about: '',
      });
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.company_name) {
      errors.company_name = 'Company name is required';
      isValid = false;
    }

    // Add other validation rules for other fields...

    setErrors(errors);
    return isValid;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="company_name">Company Name</Label>
        <Input
          type="text"
          name="company_name"
          id="company_name"
          placeholder="Enter company name"
          value={formData.company_name}
          onChange={handleChange}
          invalid={errors.company_name ? true : false}
        />
        <FormFeedback>{errors.company_name}</FormFeedback>
      </FormGroup>
      {/* Add other form fields similarly */}
      <Button type="submit" color="primary">Submit</Button>
    </Form>
  );
};

export default FormComponent;
