// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import { fetchTemplatesSuccess, fetchTemplatesFailure } from "./actions";

function* fetchTemplates(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(() =>
      AxiosInstance().post("/fetch_resumes", action.payload)
    );
    if (response.data.status) {
      yield put(fetchTemplatesSuccess(response.data));
    } else {
      yield put(fetchTemplatesFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchTemplatesFailure({ status: false, message: error.message }));
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* resumeTemplateSaga() {
  yield takeLatest(types.FETCH_TEMPLATES_REQUEST, fetchTemplates);
}

export default resumeTemplateSaga;
