import { useContext } from "react";

import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

function ProtectedRoute({ userType, children }) {
  const { user } = useContext(AuthContext);
  console.log(user);

  const token = localStorage.getItem("_token");
  if (token) {
    const userData = JSON.parse(localStorage.getItem("user_data"));

    if (userData.type !== userType) {
      return (
        <Navigate
          to={`${
            process.env.PUBLIC_URL
          }/${userData.type.toLowerCase()}/auth/login`}
          replace
        />
      );
    }
  } else {
    return (
      <Navigate
        to={`${process.env.PUBLIC_URL}/${userType.toLowerCase()}/auth/login`}
        replace
      />
    );
  }

  return children;
}

export default ProtectedRoute;
