import { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  loadFiltersFromLocalStorage,
  saveFiltersToLocalStorage,
  removeFiltersFromLocalStorage,
} from "../Components/Common/CommonFunction";
import {
  experienceOptions,
  jobTypeOptions,
  employmentTypeOptions,
} from "../Constant";

export const JobSearchContext = createContext();

const defaultFilters = {
  keyword: "",
  experience: "",
  freshness: "",
  locations: [],
  job_type: [],
  industry_type: [],
  employment_type: [],
  functional_area: [],
  skills: [],
};

export default function JobSearchProvider({ children }) {
  const fetchJobseekerDataReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer
  );

  const [profileData, setProfileData] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [pagePerpage, setPagePerpage] = useState({ page: 1, per_page: 6 });
  // Update profile data from Redux state
  useEffect(() => {
    if (
      !fetchJobseekerDataReducerData.isLoading &&
      fetchJobseekerDataReducerData.data &&
      fetchJobseekerDataReducerData.data.data
    ) {
      setProfileData(fetchJobseekerDataReducerData.data.data);
    }
  }, [fetchJobseekerDataReducerData]);

  // Load filters from localStorage or profileData
  useEffect(() => {
    const localFilters = loadFiltersFromLocalStorage("jobSearchFilters", null);
    if (localFilters) {
      setFilters(localFilters);
    } else if (profileData) {
      const newFilters = {
        keyword: profileData.jobseeker_preferred_job_title || "",
        experience:
          experienceOptions.find(
            (option) => option.value === profileData.total_exp
          ) || "",
        freshness: "",
        locations: profileData.jobseeker_preferred_location || [],
        job_type: profileData.jobseeker_preferred_job_type
          ? [
              jobTypeOptions.find(
                (option) =>
                  option.value === profileData.jobseeker_preferred_job_type
              ),
            ]
          : [],
        industry_type: profileData.jobseeker_preferred_industry
          ? [profileData.jobseeker_preferred_industry]
          : [],
        employment_type: profileData.jobseeker_preferred_employment_type
          ? [
              employmentTypeOptions.find(
                (option) =>
                  option.value ===
                  profileData.jobseeker_preferred_employment_type
              ),
            ]
          : [],
        functional_area: profileData.jobseeker_preferred_department
          ? [profileData.jobseeker_preferred_department]
          : [],
        skills: profileData.skill || [],
      };
      setFilters(newFilters);
      saveFiltersToLocalStorage("jobSearchFilters", newFilters);
    }
  }, [profileData]);

  // Save filters to localStorage on change
  useEffect(() => {
    if (filters !== defaultFilters) {
      saveFiltersToLocalStorage("jobSearchFilters", filters);
    }
  }, [filters]);

  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const updatePagePerpage = (newFilters) => {
    setPagePerpage((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
    removeFiltersFromLocalStorage("jobSearchFilters");
  };

  return (
    <JobSearchContext.Provider
      value={{
        filters,
        updateFilters,
        clearFilters,
        updatePagePerpage,
        pagePerpage,
      }}
    >
      {children}
    </JobSearchContext.Provider>
  );
}
