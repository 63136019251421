import {
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  RESET_FEEDBACK_MESSAGE,
} from "../../actionTypes";

export const submitFeedbackRequest = (data) => ({
  type: SUBMIT_FEEDBACK_REQUEST,
  payload: data,
});

export const submitFeedbackSuccess = (message) => ({
  type: SUBMIT_FEEDBACK_SUCCESS,
  payload: message,
});

export const submitFeedbackFailure = (error) => ({
  type: SUBMIT_FEEDBACK_FAILURE,
  payload: error,
});

export const resetFeedbackMessage = () => ({
  type: RESET_FEEDBACK_MESSAGE,
});
