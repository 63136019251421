import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

export const resetPasswordRequest = (jobData) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: jobData,
});

export const resetPasswordSuccess = (response) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: response,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

export const reset = () => ({
  type: RESET_JOB,
});
