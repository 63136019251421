import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
  data: {},
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST: // Handle fetching job request
      return {
        ...state,
        isLoading: true,
      };
    case RESET_PASSWORD_SUCCESS: // Handle fetching job success
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        message: action.payload.message,
        data: action.payload.data, // Store fetched job data
      };
    case RESET_PASSWORD_FAILURE: // Handle fetching job failure
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };
    case RESET_JOB: // Reset job data
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
