import {
  UPDATE_RECRUITER_PROFILE_REQUEST,
  UPDATE_RECRUITER_PROFILE_SUCCESS,
  UPDATE_RECRUITER_PROFILE_FAILURE,
  FETCH_RECRUITER_PROFILE_REQUEST,
  FETCH_RECRUITER_PROFILE_SUCCESS,
  FETCH_RECRUITER_PROFILE_FAILURE,
  UPDATE_RECRUITER_AVATAR_REQUEST,
  UPDATE_RECRUITER_AVATAR_SUCCESS,
  UPDATE_RECRUITER_AVATAR_FAILURE,
  REMOVE_RECRUITER_AVATAR_REQUEST,
  REMOVE_RECRUITER_AVATAR_SUCCESS,
  REMOVE_RECRUITER_AVATAR_FAILURE,
  RESET_MESSAGE,
} from "../../actionTypes";

export const updateRecruiterProfile = (data) => ({
  type: UPDATE_RECRUITER_PROFILE_REQUEST,
  payload: data,
});

export const updateRecruiterProfileSuccess = (profileData) => ({
  type: UPDATE_RECRUITER_PROFILE_SUCCESS,
  payload: profileData,
});

export const updateRecruiterProfileFailure = (error) => ({
  type: UPDATE_RECRUITER_PROFILE_FAILURE,
  payload: error,
});

export const fetchRecruiterProfile = () => ({
  type: FETCH_RECRUITER_PROFILE_REQUEST,
});

export const fetchRecruiterProfileSuccess = (profileData) => ({
  type: FETCH_RECRUITER_PROFILE_SUCCESS,
  payload: profileData,
});

export const fetchRecruiterProfileFailure = (error) => ({
  type: FETCH_RECRUITER_PROFILE_FAILURE,
  payload: error,
});

export const updateRecruiterAvatar = (avatarData) => ({
  type: UPDATE_RECRUITER_AVATAR_REQUEST,
  payload: avatarData,
});

export const updateRecruiterAvatarSuccess = (avatar) => ({
  type: UPDATE_RECRUITER_AVATAR_SUCCESS,
  payload: avatar,
});

export const updateRecruiterAvatarFailure = (error) => ({
  type: UPDATE_RECRUITER_AVATAR_FAILURE,
  payload: error,
});

export const removeRecruiterAvatar = () => ({
  type: REMOVE_RECRUITER_AVATAR_REQUEST,
});

export const removeRecruiterAvatarSuccess = (message) => ({
  type: REMOVE_RECRUITER_AVATAR_SUCCESS,
  payload: message,
});

export const removeRecruiterAvatarFailure = (error) => ({
  type: REMOVE_RECRUITER_AVATAR_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetMessage = () => ({
  type: RESET_MESSAGE,
});
