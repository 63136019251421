import React, { useState, useCallback, useContext, Fragment } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { showToast } from "../../../Components/Common/Toast";
import { LOADER_TIMEOUT, shiftTypeOptions } from "../../../Constant";
import {
  extractAfterLastHyphen,
  convertNumberToWords,
} from "../../Common/CommonFunction";
import { fetchCompanyRequest } from "../../../redux/company/actions";
import { FaPlus } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  CardFooter,
  Badge,
} from "reactstrap";
import Select from "react-select";
import {
  experienceOptions,
  jobTypeOptions,
  employmentTypeOptions,
  customOptionsStyle,
  benefitsOptions,
  jobPostingTypesOptions,
} from "../../../Constant";
import { fetchLocationsRequest } from "../../../redux/location/actions";
import { fetchEducationRequest } from "../../../redux/education/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchIndustryRequest } from "../../../redux/industryType/actions";
import { fetchFunctionalAreaRequest } from "../../../redux/functionalArea/actions";
import { fetchSkillsRequest } from "../../../redux/mandatorySkills/actions";
import CreatableSelect from "react-select/creatable";
import {
  extendJobExpireDateRequest,
  extendJobExpireDateReset,
} from "../../../redux/recruiter/extend_job_expire_date/actions";

import { FaCheck } from "react-icons/fa6";

import {
  postJobRequest,
  fetchJobRequest,
  resetJob,
} from "../../../redux/recruiter/post_job/actions";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import {
  updateCompanyProfile,
  fetchCompanyProfile,
} from "../../../redux/recruiter/company_profile/actions";
import DatePicker from "react-datepicker";
import { PreloaderContext } from "../../../_helper/Preloader/PreloaderContext";
import { discloseCompanyOptions } from "../../../Constant";
import formatDate from "../../Common/CommonFunction";
import { showStyledCoins } from "../../Common/CommonFunction";
import { fetchRewardsPointsRequest } from "../../../redux/fetch_rewards_points/actions";
import { AuthContext } from "../../../Auth/AuthContext";

const PostJobForm = ({ type }) => {
  const dispatch = useDispatch();
  const { triggerTotalPoints } = useContext(AuthContext);
  const { id, duplicate } = useParams();
  const { showPreloader } = useContext(PreloaderContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [companyOptions, setCompanyOptions] = useState([]);

  const initialJobType =
    type === "internship"
      ? { label: "Part Time", value: "PART_TIME" }
      : { label: "Full Time", value: "FULL_TIME" };
  const initialExperience =
    type === "internship" ? { label: "0 years", value: "0" } : null;

  /**
   * set default form field values
   */

  const [formData, setFormData] = useState({
    company: "",
    jobPostingType: "",
    jobTitle: "",
    education: [],
    jobDescription: "",
    benefits: [],
    shift_type: [],
    minSalary: null,
    maxSalary: null,
    locations: [],
    industryType: null,
    numVacancies: 1,
    employmentType:
      type === "internship"
        ? { label: "Apprenticeship/Internship", value: "INTERNSHIP" }
        : {
            label: "Permanent",
            value: "PERMANENT",
          }, // Default to Apprentice/internship if route is internship
    jobType: initialJobType,
    minExperience: initialExperience,
    maxExperience: initialExperience,
    mandatorySkills: [],
    primarySkill: null,
    functionalArea: null,
    goodToHaveSkills: [],
    last_apply_date: new Date(new Date().setDate(new Date().getDate() + 30)),
    is_disclose_salary: 1, // Initial state: assume checkbox is checked by default
    isDiscloseCompany: { value: 0, label: "No" },
  });

  /**
   * initialize state for dropdowns
   */
  const [functionalAreaOptions, setFunctionalAreaOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [mandatorySkillsOptions, setMandatorySkillsOptions] = useState([]);
  const [goodToHaveSkillsOptions, setGoodToHaveSkillsOptions] = useState([]);
  const [primarySkillOptions, setPrimarySkillOptions] = useState([]);
  const [salaryLabel, setSalaryLabel] = useState("Salary");
  const [buttonLabel, setButtonLabel] = useState("Post Job");
  const [isConsultancy, setIsConsultancy] = useState();
  /**
   * read job data from store in edit case
   */

  let companyReducerData = useSelector((state) => state.companyReducer);
  let companyData = companyReducerData.data.data;

  useEffect(() => {
    // Check if companyData is valid and not empty
    if (companyData && companyData.length > 0) {
      setCompanyOptions(companyData);
    }
  }, [companyData]); // Update state when companyData changes

  const handleCompanyFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = "";
        dispatch(fetchCompanyRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCompanyInputChange = useCallback((inputValue) => {
    const fetchData = async () => {
      try {
        dispatch(fetchCompanyRequest(inputValue));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleCompanyChange = (selectedOption) => {
    const updatedErrors = { ...errors, company: "" };
    setErrors(updatedErrors);

    setFormData({ ...formData, company: selectedOption });
  };

  const postJobReducerData = useSelector((state) => state.postJobReducer);
  useEffect(() => {
    // Fetch company profile on component mount
    dispatch(fetchCompanyProfile());
  }, []);
  const companyProfileReducerData = useSelector(
    (state) => state.companyProfileReducer
  );

  useEffect(() => {
    if (companyProfileReducerData?.data) {
      setIsConsultancy(companyProfileReducerData.data.is_consultancy);
    }
  }, [companyProfileReducerData]);

  let jobData = null;
  let companyProfile = null;
  if (id) {
    jobData = postJobReducerData.data;
  } else {
    companyProfile = companyProfileReducerData.data;
  }
  // console.log("red", postJobReducerData);

  useEffect(() => {
    if (id) {
      dispatch(fetchJobRequest({ job_id: extractAfterLastHyphen(id) }));
      setButtonLabel(
        duplicate && duplicate.toLowerCase() === "duplicate"
          ? "Post Job"
          : "Update Job"
      );
    }
  }, []);

  useEffect(() => {
    if (type === "internship") {
      setFormData((prevState) => ({
        ...prevState,
        employmentType: {
          label: "Apprenticeship/Internship",
          value: "INTERNSHIP",
        },
      }));
    }
  }, [type]);

  useEffect(() => {
    if (type === "internship") {
      setFormData((prevState) => ({
        ...prevState,
        employmentType: {
          label: "Apprenticeship/Internship",
          value: "INTERNSHIP",
        },
        jobType: initialJobType,
        minExperience: initialExperience,
        maxExperience: initialExperience,
      }));
    }
  }, [type]);

  // Update formData when jobData changes
  useEffect(() => {
    if (jobData) {
      //set mandatory skills as primary skills options in edit case
      setPrimarySkillOptions(
        (jobData.mandatory_skills && jobData.mandatory_skills) || []
      );

      let text = benefitsOptions.find(
        (option) => option.value == jobData.job_benefits
      );

      console.log("text", jobData);

      setFormData({
        is_disclose_salary: jobData.is_disclose_salary || "",
        isDiscloseCompany:
          discloseCompanyOptions.find((option) => {
            if (option.value == jobData.is_disclose_company) {
              return option;
            }
          }) || null,

        company: jobData.company_name || "",
        jobPostingType:
          jobPostingTypesOptions.find((option) => {
            if (option.value == jobData.job_posting_type) {
              return option;
            }
          }) || "",

        jobTitle: jobData.job_name || "",
        education: jobData.qualifications || [],
        jobDescription: jobData.job_description || "",
        benefits: jobData.job_benefits
          ? jobData.job_benefits
              .split(",")
              .map((benefit) => {
                return benefitsOptions.find(
                  (option) => option.value === benefit
                );
              })
              .filter((option) => option !== undefined)
          : [],

        minExperience:
          experienceOptions.find((option) => {
            if (option.value == jobData.work_experience_min) {
              return option;
            }
          }) || null,
        maxExperience:
          experienceOptions.find((option) => {
            if (option.value == jobData.work_experience_max) {
              return option;
            }
          }) || null,
        minSalary: jobData.salary_min || "",
        maxSalary: jobData.salary_max || "",
        locations: jobData.job_locations || [],
        industryType: jobData.industry_area || null,
        numVacancies: jobData.no_of_positions || "",
        employmentType:
          employmentTypeOptions.find((option) => {
            if (option.value == jobData.employment_type) {
              return option;
            }
          }) || "",
        jobType:
          jobTypeOptions.find((option) => {
            if (option.value == jobData.job_type) {
              return option;
            }
          }) || "",
        shift_type: jobData.job_shift
          ? jobData.job_shift
              .split(",")
              .map((type) => {
                return shiftTypeOptions.find((option) => option.value === type);
              })
              .filter((option) => option !== undefined)
          : [],
        mandatorySkills: jobData.mandatory_skills || "",
        primarySkill: jobData.primary_skill || null,
        functionalArea: jobData.functional_area_type || "",
        goodToHaveSkills: jobData.good_to_have_skills || "",
        last_apply_date: jobData.job_last_date
          ? new Date(jobData.job_last_date)
          : null,
      });
    } else if (companyProfile) {
      console.log("companyprofile", companyProfile);
      setFormData({
        company: companyProfile.company_name || "",
        jobTitle: "",
        education: [],
        jobDescription: "",
        benefits: companyProfile.company_benefits
          ? companyProfile.company_benefits
              .split(",")
              .map((benefit) => {
                return benefitsOptions.find(
                  (option) => option.value === benefit
                );
              })
              .filter((option) => option !== undefined)
          : [],

        minSalary: null,
        maxSalary: null,
        locations: [],
        industryType: companyProfile.industry_area || "",
        numVacancies: 1,
        employmentType:
          type === "internship"
            ? { label: "Apprenticeship/Internship", value: "INTERNSHIP" }
            : {
                label: "Permanent",
                value: "PERMANENT",
              }, // Default to Apprentice/internship if route is internship
        jobType: initialJobType,
        shift_type: [],
        minExperience: initialExperience,
        maxExperience: initialExperience,
        mandatorySkills: [],
        primarySkill: null,
        functionalArea: companyProfile.functional_area,
        goodToHaveSkills: [],
        last_apply_date: new Date(
          new Date().setDate(new Date().getDate() + 30)
        ),
        is_disclose_salary: companyProfile.is_consultancy, // Initial state: assume checkbox is checked by default
        isDiscloseCompany: { value: 0, label: "No" },
      });
      //  setIsConsultancy(companyProfile.is_consultancy);
    }
  }, [jobData, companyProfile]);

  //handle onchange on form fields to remove errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear the error message when input changes
    let error = "";
    if (name == "maxSalary") {
      if (parseFloat(formData.minSalary) > parseFloat(value)) {
        error = "Max salary can't be less than min salary";
      }
    }

    setErrors({ ...errors, [name]: error });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    const value = checked ? 0 : 1; // Convert true/false to 1/0

    setFormData({ ...formData, is_disclose_salary: value });
  };

  // handle date chage
  // const handleDateChange = (date) => {
  //   const updatedErrors = { ...errors, last_apply_date: "" };
  //   setErrors(updatedErrors);
  //   setFormData({ ...formData, last_apply_date: date });
  // };

  /**
   * handle form submit when all data is valid
   */

  const handleSubmit = (e, status) => {
    e.preventDefault();

    console.log("submit", formData);

    if (validate()) {
      // Format the data before logging or dispatching
      let job_id = extractAfterLastHyphen(id);
      if (duplicate) {
        job_id = "";
      }
      const formattedData = {
        job_id: job_id || "", // Set job_id to id if exists, otherwise to ""
        company_name: formData.company.label || "",
        job_posting_type: formData.jobPostingType.value,
        company_id: formData.company.value || "",
        job_name: formData.jobTitle,
        job_description: formData.jobDescription,
        job_benefits: formData.benefits.map((loc) => loc.value).join(","),
        qualification: formData.education.map((edu) => edu.value).join(","),
        work_experience_min: formData.minExperience.value,
        work_experience_max: formData.maxExperience.value,
        salary_min: formData.minSalary,
        salary_max: formData.maxSalary,
        vacno: formData.numVacancies,
        job_location: formData.locations.map((loc) => loc.value).join(","),
        industry_type: formData.industryType.value,
        functional_area_type: formData.functionalArea.value,
        employment_type: formData.employmentType.value,
        job_type: formData.jobType.value,
        job_shift: formData.shift_type.map((loc) => loc.value).join(","),
        job_status: status,
        mandatory_skills: formData.mandatorySkills
          .map((skill) => skill.value)
          .join(","),
        good_to_have_skills: formData.goodToHaveSkills
          ? formData.goodToHaveSkills.map((skill) => skill.value).join(",")
          : [],
        primary_skill: formData.primarySkill.value,

        is_disclose_salary: formData.is_disclose_salary,
        is_disclose_company: formData.isDiscloseCompany.value,
      };

      dispatch(postJobRequest(formattedData));
    }
  };

  // validate form data

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (isConsultancy == 1) {
      if (!formData.company) {
        errors.company = "Company name is required";
        isValid = false;
      }
    }

    if (!formData.jobTitle) {
      errors.jobTitle = "Job title is required";
      isValid = false;
    }

    if (!formData.education || formData.education.length === 0) {
      errors.education = "Education is required";
      isValid = false;
    }

    // Regular expressions for link and mobile number
    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/i;
    const mobilePattern = /\b\d{10}\b/;

    // Job Description validation
    if (!formData.jobDescription) {
      errors.jobDescription = "Job description is required";
      isValid = false;
    } else if (urlPattern.test(formData.jobDescription)) {
      errors.jobDescription = "Job description should not contain links";
      isValid = false;
    } else if (mobilePattern.test(formData.jobDescription)) {
      errors.jobDescription =
        "Job description should not contain mobile numbers";
      isValid = false;
    }

    if (!formData.minExperience) {
      errors.minExperience = "Min experience is required";
      isValid = false;
    }
    if (!formData.maxExperience) {
      errors.maxExperience = "Max experience is required";
      isValid = false;
    }

    if (formData.minExperience && formData.maxExperience) {
      if (
        parseFloat(formData.minExperience.value) >
        parseFloat(formData.maxExperience.value)
      ) {
        errors.maxExperience =
          "Max experience can't be less than min experience";
        isValid = false;
      }
    }

    // Check if minSalary and maxSalary are provided and are numbers
    if (formData.minSalary) {
      if (isNaN(formData.minSalary)) {
        errors.minSalary = "Min salary must be a number";
        isValid = false;
      }
    }

    if (formData.maxSalary) {
      if (isNaN(formData.maxSalary)) {
        errors.maxSalary = "Max salary must be a number";
        isValid = false;
      }
    }

    if (formData.maxSalary && formData.minSalary) {
      if (parseFloat(formData.maxSalary) < parseFloat(formData.minSalary)) {
        errors.maxSalary = "Max salary can't be less than min salary";
        isValid = false;
      }
    }

    if (!formData.locations || formData.locations.length === 0) {
      errors.locations = "Location is required";
      isValid = false;
    }

    if (!formData.industryType) {
      errors.industryType = "Industry type is required";
      isValid = false;
    }

    if (!formData.numVacancies) {
      errors.numVacancies = "Number of vacancies is required";
      isValid = false;
    }

    if (!formData.employmentType) {
      errors.employmentType = "Employment type is required";
      isValid = false;
    }

    if (!formData.jobType) {
      errors.jobType = "Job type is required";
      isValid = false;
    }

    if (!formData.jobPostingType) {
      errors.jobPostingType = "Job posting type is required";
      isValid = false;
    }

    if (!formData.mandatorySkills || formData.mandatorySkills.length === 0) {
      errors.mandatorySkills = "Mandatory skills is required";
      isValid = false;
    }

    if (!formData.primarySkill) {
      errors.primarySkill = "Primary skill is required";
      isValid = false;
    }

    if (!formData.functionalArea) {
      errors.functionalArea = "Functional area is required";
      isValid = false;
    }

    if (!formData.goodToHaveSkills || formData.goodToHaveSkills.length === 0) {
      errors.goodToHaveSkills = "Good to have skills is required";
      isValid = false;
    }

    if (!formData.benefits || formData.benefits.length === 0) {
      errors.benefits = "Benefits is required";
      isValid = false;
    }

    if (!formData.shift_type || formData.shift_type.length === 0) {
      errors.shift_type = "Shift type is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  /**
   * education dropdown code start
   */
  //read education data from store
  let educationReducerData = useSelector((state) => state.educationReducer);
  let educationData = educationReducerData.data.data;

  useEffect(() => {
    educationData = educationData || [];
    setEducationOptions(educationData);
  }, [educationData]);

  const handleEducationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchEducationRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleEducationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchEducationRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleEducationChange = (selectedOptions) => {
    const updatedErrors = { ...errors, education: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, education: selectedOptions });
  };

  const handleShiftTypeChange = (type) => {
    const updatedErrors = { ...errors, shift_type: "" };
    setErrors(updatedErrors);

    // Check if the option's value already exists in formData.benefits
    const existingOption = formData.shift_type.find(
      (item) => item.value === type.value
    );

    // Toggle the selected state for the clicked benefit
    let updatedShiftType = [];
    if (existingOption) {
      updatedShiftType = formData.shift_type.filter(
        (item) => item.value !== type.value
      );
    } else {
      updatedShiftType = [...formData.shift_type, type];
    }

    // Update formData with the updated benefits array
    setFormData((prevFormData) => ({
      ...prevFormData,
      shift_type: updatedShiftType,
    }));
  };

  /**
   * education dropdown code end
   */

  /**
   * location dropdown code start
   */
  //read location data from store
  let locationReducerData = useSelector((state) => state.locationReducer);
  let locationData = locationReducerData.data.data;

  useEffect(() => {
    locationData = locationData || [];
    setLocationOptions(locationData);
  }, [locationData]);

  const handleLocationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleLocationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Callback to handle location select change
  const handleLocationsChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, locations: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, locations: selectedOptions });
  };

  /**
   * location dropdown code end
   */

  /**
   * industry type dropdown code start
   */

  //read industry type data from store
  let industryTypeReducerData = useSelector(
    (state) => state.industryTypeReducer
  );

  let industryTypeData = industryTypeReducerData.data.data;

  useEffect(() => {
    industryTypeData = industryTypeData || [];
    setIndustryTypeOptions(industryTypeData);
  }, [industryTypeData]);

  const handleIndustryFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchIndustryRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleIndustryInputChange = useCallback((inputValue) => {
    // Update the location search value state
    // setLocationSearchValue(inputValue);

    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchIndustryRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleIndustryChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, industryType: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, industryType: selectedOptions });
  };

  /**
   * industy type dropdown code end
   */

  /**
   * mandatory skills dropdown code start
   */

  console.log("fd", formData);

  //read industry type data from store
  let mandatorySkillsReducerData = useSelector(
    (state) => state.mandatorySkillsReducer
  );
  // console.log(industryTypeReducerData.data);
  let mandatorySkillsData = mandatorySkillsReducerData.data.data;

  useEffect(() => {
    mandatorySkillsData = mandatorySkillsData || [];
    setMandatorySkillsOptions(mandatorySkillsData);
  }, [mandatorySkillsData]);

  const handlemandatorySkillsFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchSkillsRequest(keyword, "MANDATORY"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleMandatorySkillsInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchSkillsRequest(inputValue, "MANDATORY"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleMandatorySkillsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, mandatorySkills: "" };
    setErrors(updatedErrors);

    setPrimarySkillOptions(selectedOptions.length > 0 ? selectedOptions : []);
    var primarySkillValue = "";

    if (formData.primarySkill) {
      var found = false;
      selectedOptions.find((option) => {
        if (option.value === formData.primarySkill.value) {
          found = true;
          primarySkillValue = formData.primarySkill;
        }
      });
      if (!found || primarySkillOptions.length == 0) {
        primarySkillValue = null;
      }
    }

    setFormData({
      ...formData,
      mandatorySkills: selectedOptions,
      primarySkill: primarySkillValue,
    });
  };

  /**
   * mandatory skills dropdown code end
   */

  /**
   * good to have skills dropdown code start
   */
  // console.log('ps',formData);

  //read industry type data from store
  let goodToHaveSkillsReducerData = useSelector(
    (state) => state.mandatorySkillsReducer
  );
  // console.log(goodToHaveSkillsReducerData.data);
  let goodToHaveSkillsData = goodToHaveSkillsReducerData.data.data;

  useEffect(() => {
    goodToHaveSkillsData = goodToHaveSkillsData || [];
    setGoodToHaveSkillsOptions(goodToHaveSkillsData);
  }, [goodToHaveSkillsData]);

  const handleGoodToHaveSkillsFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchSkillsRequest(keyword, "GOOD_TO_HAVE"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleGoodToHaveSkillsInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchSkillsRequest(inputValue, "GOOD_TO_HAVE"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleGoodToHaveSkillsChange = (selectedOption) => {
    const updatedErrors = { ...errors, goodToHaveSkills: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, goodToHaveSkills: selectedOption });
  };

  /**
   * good to have skills dropdown code end
   */

  /**
   * primary skill dropdown code start
   */

  const handlePrimarySkillsChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, primarySkill: "" };
    setErrors(updatedErrors);

    // Update formData directly
    setFormData({
      ...formData,
      primarySkill: selectedOption, // Set the selected value
    });
  };

  /**
   * primary skill dropdown code end
   */

  /**
   * functional area dropdown code start
   */

  //read industry type data from store
  let functionalAreaReducerData = useSelector(
    (state) => state.functionalAreaReducer
  );
  console.log(functionalAreaReducerData);
  let functionalAreaData = functionalAreaReducerData.data.data;

  useEffect(() => {
    functionalAreaData = functionalAreaData || [];
    setFunctionalAreaOptions(functionalAreaData);
  }, [functionalAreaData]);

  const handleFunctionalAreaFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchFunctionalAreaRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleFunctionalAreaInputChange = useCallback((inputValue) => {
    // Update the location search value state
    // setLocationSearchValue(inputValue);

    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchIndustryRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleFunctionalAreaChange = (selectedOptions) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, functionalArea: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      functionalArea: selectedOptions, // Assuming selectedOptions is the value you want to set
    });
  };

  /**
   * functional area dropdown code end
   */

  /**
   * job description code start
   */

  // Callback function to handle changes in job description
  const handleJobDescriptionChange = (newContent) => {
    // Clear the error message for job description
    console.log("JD ", newContent);
    const updatedErrors = { ...errors, jobDescription: "" };
    setErrors(updatedErrors);

    if (newContent !== formData.jobDescription) {
      setFormData((prev) => ({ ...prev, jobDescription: newContent }));
    }
  };

  const handleBenefitsChange = (benefit) => {
    // Check if the option's value already exists in formData.benefits
    const existingOption = formData.benefits.find(
      (item) => item.value === benefit.value
    );

    // Toggle the selected state for the clicked benefit
    let updatedBenefits = [];
    if (existingOption) {
      updatedBenefits = formData.benefits.filter(
        (item) => item.value !== benefit.value
      );
    } else {
      updatedBenefits = [...formData.benefits, benefit];
    }

    // Update formData with the updated benefits array
    setFormData((prevFormData) => ({
      ...prevFormData,
      benefits: updatedBenefits,
    }));
  };

  const handleExperienceMinChange = (selectedOption) => {
    const updatedErrors = { ...errors, minExperience: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      minExperience: selectedOption,
    });

    // Validate if needed
    if (
      formData.maxExperience &&
      parseFloat(selectedOption.value) >
        parseFloat(formData.maxExperience.value)
    ) {
      setErrors({
        ...errors,
        maxExperience: "Max experience can't be less than min experience",
      });
    }
  };
  /**
   * min experience code end
   */

  /**
   * max experience code start
   */

  const handleExperienceMaxChange = (selectedOption) => {
    const updatedErrors = { ...errors, maxExperience: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      maxExperience: selectedOption,
    });

    // Validate if needed
    if (
      formData.minExperience &&
      parseFloat(selectedOption.value) <
        parseFloat(formData.minExperience.value)
    ) {
      setErrors({
        ...errors,
        maxExperience: "Max experience can't be less than min experience",
      });
    }
  };

  /**
   * max salary code end
   */

  /**
   * employement type code start
   */

  const handleEmploymentTypeChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, employmentType: "" };
    setErrors(updatedErrors);
    console.log(selectedOption);
    let job_type = "";
    let work_exp_min = "";
    let work_exp_max = "";
    if (selectedOption.value == "INTERNSHIP") {
      job_type =
        jobTypeOptions.find((option) => {
          if (option.value == "PART_TIME") {
            return option;
          }
        }) || "";
      work_exp_min =
        experienceOptions.find((option) => {
          if (option.value == "0") {
            return option;
          }
        }) || "";
      work_exp_max =
        experienceOptions.find((option) => {
          if (option.value == "0") {
            return option;
          }
        }) || "";
      setSalaryLabel("Stipend");
    } else if (selectedOption.value == "FREELANCE/HOME_BASED") {
      job_type =
        jobTypeOptions.find((option) => {
          if (option.value == "CONTRACT") {
            return option;
          }
        }) || "";
      setSalaryLabel("Salary");
    } else {
      job_type =
        jobTypeOptions.find((option) => {
          if (option.value == "FULL_TIME") {
            return option;
          }
        }) || "";
      setSalaryLabel("Salary");
    }

    // Update formData directly
    setFormData({
      ...formData,
      employmentType: selectedOption,
      jobType: job_type,
      minExperience: work_exp_min,
      maxExperience: work_exp_max,
    });
  };

  /**
   * employement type code end
   */

  /**
   * job type code start
   */
  const handleJobTypeChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobType: "" };
    setErrors(updatedErrors);

    // Update formData directly
    setFormData({
      ...formData,
      jobType: selectedOption, // Set the selected value
    });
  };

  const handleJobPostingTypeChange = (selectedOption) => {
    // Clear the error message when an option is selected
    const updatedErrors = { ...errors, jobPostingType: "" };
    setErrors(updatedErrors);

    // Update formData directly
    setFormData({
      ...formData,
      jobPostingType: selectedOption, // Set the selected value
    });
  };

  const handleCompanyDiscloseChange = (selectedOption) => {
    // Clear the error message when an option is selected
    // const updatedErrors = { ...errors, is_disclose_company: "" };
    //  setErrors(updatedErrors);
    console.log("selectedOption2222", selectedOption);
    // Update formData directly
    setFormData({
      ...formData,
      isDiscloseCompany: selectedOption, // Set the selected value
    });
  };

  /**
   * employement type code end
   */

  useEffect(() => {
    if (postJobReducerData.status && postJobReducerData.message != "") {
      showToast("success", postJobReducerData.message);
      console.log("archana", formData.jobPostingType);
      if (formData.jobPostingType.value == "EXTERNAL") {
        triggerTotalPoints("post_jobs", false, false);
      }

      dispatch(resetJob());
      navigate(`${process.env.PUBLIC_URL}/recruiter/manage_jobs/all`);
    } else if (
      postJobReducerData.status == false &&
      postJobReducerData.message != ""
    ) {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", postJobReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", postJobReducerData.message);
      }
      dispatch(resetJob());
    }
  }, [postJobReducerData]);

  const [expiryMessage, setExpiryMessage] = useState("");
  const [showExtendButton, setShowExtendButton] = useState(false);

  useEffect(() => {
    if (formData.last_apply_date) {
      const lastApplyDate = formData.last_apply_date;

      const today = new Date();
      const daysDifference = Math.ceil(
        (lastApplyDate - today) / (1000 * 60 * 60 * 24)
      );
      // console.log("lastApplyDate", daysDifference);
      //const daysDifference = 4;
      const changeDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      if (daysDifference <= 30 && daysDifference > 0) {
        setExpiryMessage(`Expiring in  ${daysDifference} day(s)`);
      } else if (daysDifference <= 0) {
        setExpiryMessage("Job has expired");
      } else {
        setExpiryMessage(`Expires on ${changeDate(lastApplyDate)}`);
      }

      if (daysDifference <= 7 && daysDifference > 0) {
        setShowExtendButton(true);
      } else {
        setShowExtendButton(false);
      }
    }
  }, [formData.last_apply_date]);

  const handleExtendClick = () => {
    dispatch(
      extendJobExpireDateRequest({ job_id: extractAfterLastHyphen(id) })
    );
  };

  const extendJobExpireDateReducerData = useSelector(
    (state) => state.extendJobExpireDateReducer
  );
  useEffect(() => {
    if (
      extendJobExpireDateReducerData.status &&
      extendJobExpireDateReducerData.message != ""
    ) {
      showToast("success", extendJobExpireDateReducerData.message);
      navigate(`${process.env.PUBLIC_URL}/recruiter/manage_jobs/all`);
      dispatch(extendJobExpireDateReset());
    } else if (
      extendJobExpireDateReducerData.status == false &&
      extendJobExpireDateReducerData.message != ""
    ) {
      showToast("error", extendJobExpireDateReducerData.message);
      dispatch(extendJobExpireDateReset());
    }
  }, [extendJobExpireDateReducerData]);

  const fetchRewardsPointsReducerData = useSelector(
    (state) => state.fetchRewardsPointsReducer
  );

  useEffect(() => {
    if (
      !fetchRewardsPointsReducerData ||
      !fetchRewardsPointsReducerData.data ||
      !Array.isArray(fetchRewardsPointsReducerData.data) ||
      fetchRewardsPointsReducerData.data.length <= 0
    ) {
      dispatch(fetchRewardsPointsRequest());
    }
  }, []);

  const filtered = fetchRewardsPointsReducerData?.data?.find(
    (item) => item.type === "post_jobs"
  );

  const jobPostingPoints = filtered ? filtered.points : 0;

  return (
    <Fragment>
      <Form onSubmit={handleSubmit} className="theme-form">
        {!id ? (
          <div
            className="d-flex "
            style={{
              backgroundColor: "#fff3cd",
              border: "1px solid #ffeeba",
              borderRadius: "14px",
              padding: "15px",
              color: "#856404",
              //textAlign: "center",
              marginBottom: "20px",
            }}
          >
            <span style={{ marginBottom: "10px" }}>
              When you post a external job,&nbsp;
              {showStyledCoins(jobPostingPoints)}
              &nbsp;will be deducted from your account. Earn &nbsp;
              {showStyledCoins(jobPostingPoints)}&nbsp;by providing feedback to
              candidates. Thank you for actively participating in our job
              portal!{" "}
            </span>
          </div>
        ) : (
          <>
            {showExtendButton && (
              <div
                className="d-flex"
                style={{
                  backgroundColor: "#fff3cd",
                  border: "1px solid #ffeeba",
                  borderRadius: "14px",
                  padding: "15px",
                  color: "#856404",
                  // textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                <span style={{ marginBottom: "10px" }}>
                  Your job is {expiryMessage},&nbsp;&nbsp;
                  <span
                    onClick={handleExtendClick}
                    className="fw-bold text-danger"
                    style={{
                      cursor: "pointer",
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                  >
                    Click Here
                  </span>
                </span>
                &nbsp;to extend now. By using&nbsp;
                {showStyledCoins(jobPostingPoints)}&nbsp;you can extend your job
                to 30 days.{" "}
              </div>
            )}
          </>
        )}
        <Card>
          <CardBody>
            {isConsultancy == 1 && (
              <>
                <h5 className="mb-4 text-purple">Company Details</h5>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Company Name<span className="text-danger">*</span>
                      </Label>
                      <CreatableSelect
                        isClearable
                        className=""
                        options={companyOptions}
                        isLoading={companyReducerData.isLoading}
                        placeholder="Select Company..."
                        value={formData.company}
                        onChange={handleCompanyChange}
                        onInputChange={handleCompanyInputChange}
                        onFocus={handleCompanyFocus}
                      />
                      {errors.company && (
                        <small className="text-danger">{errors.company}</small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Do You Want To Disclose Company?</Label>
                      <Select
                        options={discloseCompanyOptions}
                        value={formData.isDiscloseCompany}
                        placeholder="Select an option..."
                        onChange={handleCompanyDiscloseChange}
                        styles={customOptionsStyle}
                      />
                      {errors.isDiscloseCompany && (
                        <small className="text-danger">
                          {errors.isDiscloseCompany}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            <h5 className="mb-4 text-purple">Job Details</h5>

            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Job Title
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control-sm form-control"
                    name="jobTitle"
                    value={formData.jobTitle}
                    placeholder="Enter job title"
                    onChange={handleChange}
                    styles={customOptionsStyle}
                  />

                  {errors.jobTitle && (
                    <small className="text-danger">{errors.jobTitle}</small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Employment Type
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    options={employmentTypeOptions}
                    value={formData.employmentType}
                    placeholder="Select employement type..."
                    onChange={handleEmploymentTypeChange}
                    styles={customOptionsStyle}
                  />
                  {errors.employmentType && (
                    <small className="text-danger">
                      {errors.employmentType}
                    </small>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" className="mb-3">
                <FormGroup className="mb-5">
                  <Label>
                    Job Description
                    <span className="text-danger">*</span>
                  </Label>

                  <ReactQuill
                    placeholder="Enter job description"
                    className="quill-text"
                    theme="snow"
                    value={formData.jobDescription}
                    onChange={handleJobDescriptionChange}
                  />
                </FormGroup>
                {errors.jobDescription && (
                  <small className="text-danger ">
                    {errors.jobDescription}
                  </small>
                )}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Job Posting Type
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    options={jobPostingTypesOptions}
                    value={formData.jobPostingType}
                    placeholder="Select job posting type..."
                    onChange={handleJobPostingTypeChange}
                    styles={customOptionsStyle}
                  />
                  {errors.jobPostingType && (
                    <small className="text-danger">
                      {errors.jobPostingType}
                    </small>
                  )}
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>
                    Industry Type
                    <span className="text-danger">*</span>
                  </Label>

                  <Select
                    options={industryTypeOptions}
                    isLoading={industryTypeReducerData.isLoading}
                    placeholder="Select industry type..."
                    value={formData.industryType} // Use formData.industry directly
                    onChange={handleIndustryChange} // Handle change event
                    styles={customOptionsStyle}
                    onFocus={handleIndustryFocus} // Handle focus event
                    onInputChange={handleIndustryInputChange}
                  />
                  {errors.industryType && (
                    <small className="text-danger">{errors.industryType}</small>
                  )}
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>
                    Number of Vacancies
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    value={formData.numVacancies}
                    name="numVacancies" // Make sure the name attribute is correctly set
                    placeholder="Enter number of vacancies"
                    onChange={handleChange}
                    className="form-control form-control-sm"
                  />
                  {errors.numVacancies && (
                    <small className="text-danger">{errors.numVacancies}</small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Functional Area
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    options={functionalAreaOptions}
                    isLoading={functionalAreaReducerData.isLoading}
                    placeholder="Select functional area..."
                    value={formData.functionalArea}
                    onChange={handleFunctionalAreaChange}
                    styles={customOptionsStyle}
                    onFocus={handleFunctionalAreaFocus}
                    onInputChange={handleFunctionalAreaInputChange}
                  />
                  {errors.functionalArea && (
                    <small className="text-danger">
                      {errors.functionalArea}
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Job Location(s)
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    isMulti
                    options={locationOptions}
                    isLoading={locationReducerData.isLoading}
                    placeholder="Select locations..."
                    value={formData.locations}
                    onChange={handleLocationsChange}
                    onInputChange={handleLocationInputChange}
                    styles={customOptionsStyle}
                    onFocus={handleLocationFocus}
                  />
                  {errors.locations && (
                    <small className="text-danger">{errors.locations}</small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                {/* <FormGroup className="mb-3">
                  <Label>Job Expired</Label>
                  <DatePicker
                    className="form-control form-control-sm mb-0"
                    selected={formData.last_apply_date}
                    // onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Enter last apply date"
                    // style={{
                    //   backgroundColor: "#f1f0ff ! important", // Background color
                    //   color: "hsl(0, 0%, 20%)", // Text color
                    //   cursor: "not-allowed", // Cursor style
                    // }}
                    disabled
                  />
                </FormGroup> */}
                <Label className="form-label">Job Expire</Label>
                <div className="row align-items-center">
                  <div className="col">
                    <Input
                      type="text"
                      className="form-control form-control-sm font-secondary"
                      value={expiryMessage}
                      placeholder="Enter last apply date"
                      disabled
                    />
                  </div>
                  {showExtendButton && (
                    <div className="col-auto">
                      <Button
                        color="secondary"
                        onClick={handleExtendClick}
                        className="ms-3"
                      >
                        Extend Now
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <h5 className="mb-4 text-purple">Required Qualifications</h5>

            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Work Experience Min
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    options={experienceOptions}
                    value={formData.minExperience}
                    onChange={handleExperienceMinChange}
                    styles={customOptionsStyle}
                    placeholder="Select min experience..."
                  />
                  {errors.minExperience && (
                    <small className="text-danger">
                      {errors.minExperience}
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Work Experience Max
                    <span className="danger" style={{ color: "red" }}>
                      *
                    </span>
                  </Label>
                  <Select
                    options={experienceOptions}
                    value={formData.maxExperience}
                    onChange={handleExperienceMaxChange}
                    styles={customOptionsStyle}
                    placeholder="Select max experience..."
                  />
                  {errors.maxExperience && (
                    <small className="text-danger">
                      {errors.maxExperience}
                    </small>
                  )}
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>
                    Education (Minimun Qualifications)
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    isMulti
                    options={educationOptions}
                    isLoading={educationReducerData.isLoading}
                    placeholder="Select education..."
                    value={formData.education}
                    onChange={handleEducationChange}
                    styles={customOptionsStyle}
                    onInputChange={handleEducationInputChange}
                    onFocus={handleEducationFocus}
                  />

                  {errors.education && (
                    <small className="text-danger">{errors.education}</small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <div className="mb-2">
                  <Label>
                    Mandatory Skills
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    isMulti
                    options={mandatorySkillsOptions}
                    isLoading={mandatorySkillsReducerData.mandatoryLoading}
                    value={formData.mandatorySkills} // Use formData.skills directly
                    onChange={handleMandatorySkillsChange} // Handle change event
                    styles={customOptionsStyle}
                    placeholder={"Select mandatory skills..."}
                    onFocus={handlemandatorySkillsFocus} // Handle focus event
                    onInputChange={handleMandatorySkillsInputChange}
                  />
                  <small>Note: You can select multiple madatory skills</small>
                  <br />
                  {errors.mandatorySkills && (
                    <small className="text-danger">
                      {errors.mandatorySkills}
                    </small>
                  )}
                </div>
              </Col>

              <Col md="6">
                <FormGroup className="mb-2">
                  <Label>Good To Have Skills </Label>
                  <Select
                    isMulti
                    options={goodToHaveSkillsOptions}
                    value={formData.goodToHaveSkills}
                    isLoading={goodToHaveSkillsReducerData.goodToHaveLoading}
                    onChange={handleGoodToHaveSkillsChange}
                    styles={customOptionsStyle}
                    placeholder={"Select good to have skills..."}
                    onFocus={handleGoodToHaveSkillsFocus}
                    onInputChange={handleGoodToHaveSkillsInputChange}
                  />
                  <small>
                    Note: You can select multiple good to have skills
                  </small>
                  <br />
                  {errors.goodToHaveSkills && (
                    <small className="text-danger">
                      {errors.goodToHaveSkills}
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label>
                    Core Skill
                    <span className="text-danger">*</span>
                  </Label>
                  <Select
                    options={primarySkillOptions}
                    // value={
                    //   primarySkillOptions ?
                    //   primarySkillOptions.find(
                    //     (option) => option.value === formData.primarySkill
                    //   ):""
                    // }

                    value={formData.primarySkill}
                    onChange={handlePrimarySkillsChange}
                    placeholder={"Select primary skill..."}
                    styles={customOptionsStyle}
                  />
                  <small>
                    Note: Primary skill can be one of Mandatory Skills
                  </small>
                  <br />
                  {errors.primarySkill && (
                    <small className="text-danger">{errors.primarySkill}</small>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <h5 className="mb-4 text-purple">Salary and Benefits</h5>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Min CTC</Label>

                  <Input
                    type="text"
                    value={formData.minSalary}
                    name="minSalary" // Make sure the name attribute is correctly set
                    placeholder="Enter Min CTC"
                    onChange={handleChange}
                    className="form-control form-control-sm"
                  />
                  {formData.minSalary && !isNaN(formData.minSalary) && (
                    <small className="text-muted text-capitalize">
                      {convertNumberToWords(parseFloat(formData.minSalary))}
                    </small>
                  )}
                  {errors.minSalary && (
                    <small className="text-danger">{errors.minSalary}</small>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    {" "}
                    Max CTC
                    {/* <span className="danger" style={{ color: "red" }}>
                    *
                  </span> */}
                  </Label>
                  {/* <Select
                  options={salaryRangeOptions}
                  value={formData.maxSalary}
                  placeholder="Select max salary..."
                  onChange={handleSalaryMaxChange}
                  styles={customOptionsStyle}
                /> */}

                  <Input
                    type="text"
                    value={formData.maxSalary}
                    name="maxSalary" // Make sure the name attribute is correctly set
                    placeholder="Enter Max CTC"
                    onChange={handleChange}
                    className="form-control form-control-sm"
                  />
                  {formData.maxSalary && !isNaN(formData.maxSalary) && (
                    <div>
                      <small className="text-muted text-capitalize">
                        {convertNumberToWords(formData.maxSalary)}
                      </small>
                    </div>
                  )}
                  {errors.maxSalary && (
                    <small className="text-danger">{errors.maxSalary}</small>
                  )}
                </FormGroup>
              </Col>
              <Col xs="10" className="mb-2">
                <FormGroup className="">
                  <Label>
                    Shift Type <span className="text-danger ">*</span>
                  </Label>
                  <div className="mt-2">
                    {shiftTypeOptions.map((type) => (
                      <Badge
                        key={type.value}
                        className={`me-2 mb-2 border rounded d-inline-flex align-items-center ${
                          formData.shift_type.some(
                            (item) => item.value === type.value
                          )
                            ? "bg-primary text-white"
                            : "text-black"
                        }`}
                        style={{
                          cursor: "pointer",
                        }}
                        color="gray"
                        pill
                        onClick={() => handleShiftTypeChange(type)}
                      >
                        {formData.shift_type.some(
                          (item) => item.value === type.value
                        ) ? (
                          <FaCheck
                            style={{
                              marginRight: "5px",
                              height: "16px",
                              width: "16px",
                              paddingTop: "0px",
                            }}
                          />
                        ) : (
                          <FaPlus
                            size={16}
                            style={{
                              marginRight: "5px",
                              height: "16px",
                              width: "16px",
                              paddingTop: "0px",
                            }}
                          />
                        )}
                        <span>{type.label}</span>
                      </Badge>
                    ))}
                  </div>
                  {/* <ReactQuill
                    className="quill-text"
                    theme="snow"
                    value={formData.benefits}
                    onChange={handleBenefitsChange}
                  /> */}
                </FormGroup>
                {errors.shift_type && (
                  <small className="text-danger">{errors.shift_type}</small>
                )}
              </Col>
              <Col xs="10" className="mb-2">
                <FormGroup className="">
                  <Label>
                    Benefits <span className="text-danger ">*</span>
                  </Label>
                  <div className="mt-2">
                    {benefitsOptions.map((benefit) => (
                      <Badge
                        key={benefit.value}
                        className={`me-2 mb-2 border rounded d-inline-flex align-items-center ${
                          formData.benefits.some(
                            (item) => item.value === benefit.value
                          )
                            ? "bg-primary text-white"
                            : "text-black"
                        }`}
                        style={{
                          cursor: "pointer",
                        }}
                        color="gray"
                        pill
                        onClick={() => handleBenefitsChange(benefit)}
                      >
                        {formData.benefits.some(
                          (item) => item.value === benefit.value
                        ) ? (
                          <FaCheck
                            style={{
                              marginRight: "5px",
                              height: "16px",
                              width: "16px",
                              paddingTop: "0px",
                            }}
                          />
                        ) : (
                          <FaPlus
                            style={{
                              marginRight: "5px",
                              height: "16px",
                              width: "16px",
                              paddingTop: "0px",
                            }}
                          />
                        )}
                        <span>{benefit.label}</span>
                      </Badge>
                    ))}
                  </div>
                </FormGroup>
                {errors.benefits && (
                  <small className="text-danger">{errors.benefits}</small>
                )}
              </Col>
            </Row>

            <div>
              <Label className="d-block" htmlFor="chk-ani">
                <Input
                  className="checkbox_animated"
                  id="chk-ani"
                  type="checkbox"
                  checked={formData.is_disclose_salary == 0}
                  onChange={handleCheckboxChange} // Call the function when checkbox changes
                />
                Do not disclose the salary to candidates
                {/* Display the corresponding value based on checked state */}
              </Label>
            </div>
          </CardBody>
          <CardFooter className="text-end">
            <Button
              color="secondary"
              type="submit"
              className="me-1"
              onClick={(e) => handleSubmit(e, "DRAFT")}
              disabled={postJobReducerData.isLoading}
            >
              Save as Draft
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={(e) => handleSubmit(e, "UNDER_REVIEW")}
              disabled={postJobReducerData.isLoading}
            >
              {buttonLabel}
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Fragment>
  );
};

export default PostJobForm;
