import React from "react";
import { Container, Row, Col } from "reactstrap";
import { ReactComponent as NoDataFoundImage } from "../../assets/images/nodatafound.svg";

const NoDataFound = ({ message = "No Data Found" }) => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center "
    >
      <Row className="text-center">
        <Col>
          <NoDataFoundImage
            className="img-fluid mb-3"
            style={{ maxWidth: "400px", height: "260px" }}
          />
          <h4 className="text-muted">{message}</h4>
        </Col>
      </Row>
    </Container>
  );
};

export default NoDataFound;
