import {
  UPDATE_CANDIDATE_SOFT_SKILLS_REQUEST,
  UPDATE_CANDIDATE_SOFT_SKILLS_SUCCESS,
  UPDATE_CANDIDATE_SOFT_SKILLS_ERROR,
  RESET_CANDIDATE_SOFT_SKILLS_MESSAGE,
} from "../../actionTypes";

// action creators
export const updateCandidateSoftSkillsRequest = (data) => ({
  type: UPDATE_CANDIDATE_SOFT_SKILLS_REQUEST,
  payload: data,
});

export const updateCandidateSoftSkillsSuccess = (message) => ({
  type: UPDATE_CANDIDATE_SOFT_SKILLS_SUCCESS,
  payload: message,
});

export const updateCandidateSoftSkillsError = (error) => ({
  type: UPDATE_CANDIDATE_SOFT_SKILLS_ERROR,
  payload: error,
});

export const resetCandidateSoftSkillsData = () => ({
  type: RESET_CANDIDATE_SOFT_SKILLS_MESSAGE,
});
