// reducer.js
import * as types from "../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  message: "",
  status:false
};

const educationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_EDUCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_EDUCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        status: action.payload.status,
      };
    case types.FETCH_EDUCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        status: action.payload.status,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default educationReducer;
