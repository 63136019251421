// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { getLinkedInBannerSuccess, getLinkedInBannerFailure } from "./actions";
import { GET_LINKEDIN_BANNER_REQUEST } from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
function* getLinkedInBanner(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/fetch_all_banners ", action.payload)
    );
    console.log(response);
    yield put(getLinkedInBannerSuccess(response.data));
  } catch (error) {
    yield put(getLinkedInBannerFailure(error));
  }
}

function* getLinkedinBannerSaga() {
  yield takeLatest(GET_LINKEDIN_BANNER_REQUEST, getLinkedInBanner);
}

export default getLinkedinBannerSaga;
