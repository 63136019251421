import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  fetchManageJobsRequest,
  deleteJobRequest,
  resetJob,
} from "../../../redux/recruiter/manage_jobs/actions";
import {
  changeJobStatusRequest,
  resetJobStatusMessage,
} from "../../../redux/recruiter/change_job_status/actions";
import { Edit2, Trash2, Eye, Copy, Users } from "react-feather";
import { Link } from "react-router-dom";
import Spinner from "../../Common/Spinner";
import makeAnimated from "react-select/animated";
import {
  capitalize,
  formatDate,
  unslugAndCapitalize,
  formatExperienceOutput,
  generateSlug,
} from "../../../Components/Common/CommonFunction";
import {
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  CardHeader,
  Badge,
  Button,
  Form,
  Input,
} from "reactstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { PreloaderContext } from "../../../_helper/Preloader/PreloaderContext";
import { showToast } from "../../Common/Toast";
import { LOADER_TIMEOUT } from "../../../Constant";
import { showSweetAlert } from "../../Common/SweetAlert";
import { useParams } from "react-router-dom";
import NoDataFound from "../../Common/NoDataFound";
import { customStyles, jobStatusOptions } from "../../../Constant";
import Select from "react-select";
import { FaCalendarAlt } from "react-icons/fa";
import {
  DefinedRange,
  DateRangePicker,
  defaultStaticRanges,
} from "react-date-range";
import {
  addYears,
  startOfYear,
  endOfYear,
  endOfDay,
  isSameDay,
  endOfToday,
} from "date-fns";
import { IoMdCopy } from "react-icons/io";
import { addDays } from "date-fns";

const ManageJobs = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const { showPreloader } = useContext(PreloaderContext);
  const manageJobsReducerData = useSelector((state) => state.manageJobsReducer);
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(() => {
    return tab && tab.toLowerCase() === "all" ? "ALL" : "ACTIVE";
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [datePickerOpen, setDatePickerOpen] = useState(false); // State to manage date picker visibility

  const locale = {
    localize: {
      month: (monthIndex) => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return months[monthIndex];
      },
      day: (dayIndex) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[dayIndex];
      },
    },
    formatLong: {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  };
  const [formData, setFormData] = useState({
    dateRange: {
      key: "selection",
      startDate: startOfYear(new Date()), // Start of current year
      endDate: endOfToday(), // Today
      type: "ALL",
    },
  });

  const customStaticRanges = [
    {
      label: "All",
      range: () => ({
        startDate: startOfYear(new Date()), // Start of current year
        endDate: endOfToday(), // Today
        type: "ALL",
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Internship",
      range: () => ({
        startDate: startOfYear(new Date()), // Start of current year
        endDate: endOfToday(), // Today
        type: "INTERNSHIP",
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },

    ...defaultStaticRanges.map((range) => ({
      ...range,
      range: () => ({
        ...range.range(),
        type: range.label.toUpperCase().replace(/ /g, "_"), // Generate a type from the label
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    })),
  ];

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    let selectedType = "CUSTOM"; // Default to CUSTOM

    // Check if the selected range matches any predefined ranges
    for (let range of customStaticRanges) {
      const definedRange = range.range();
      if (
        selection.startDate.getTime() === definedRange.startDate.getTime() &&
        selection.endDate.getTime() === definedRange.endDate.getTime() &&
        selection.type === definedRange.type
      ) {
        selectedType = definedRange.type;
        break;
      }
    }

    setFormData({
      ...formData,
      dateRange: {
        ...selection,
        type: selectedType, // Set the type based on the selected range
      },
    });
    setDatePickerOpen(false); // Close the date picker after selecting a valid date range
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      status: activeTab,
      type: formData.dateRange.type,
      keyword: searchQuery,
      startDate: formData.dateRange.startDate
        ? formData.dateRange.startDate.toISOString()
        : null, // Convert Date objects to strings
      endDate: formData.dateRange.endDate
        ? formData.dateRange.endDate.toISOString()
        : null,
    };
    dispatch(fetchManageJobsRequest(currentPage, perPage, data));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleDelete = (job_id) => {
    showSweetAlert("confirm", {
      message: "You want to delete the job",
      fun: deleteJob,
      id: job_id,
    });
  };

  const deleteJob = (job_id) => {
    dispatch(deleteJobRequest({ job_id }));
  };

  const tabOptions = [
    {
      value: "ALL",
      label: `ALL (${manageJobsReducerData.data.all_jobs || 0})`,
    },
    {
      value: "UNDER_REVIEW",
      label: `UNDER REVIEW (${
        manageJobsReducerData.data.under_review_jobs || 0
      })`,
    },
    {
      value: "ON_HOLD",
      label: `ON HOLD (${manageJobsReducerData.data.onhold_jobs || 0})`,
    },
    {
      value: "ACTIVE",
      label: `ACTIVE (${manageJobsReducerData.data.active_jobs || 0})`,
    },
    {
      value: "DRAFT",
      label: `DRAFT (${manageJobsReducerData.data.draft_jobs || 0})`,
    },
    {
      value: "CLOSED",
      label: `CLOSED (${manageJobsReducerData.data.closed_jobs || 0})`,
    },
  ];

  const animatedComponents = makeAnimated();
  const colors = {
    primary: "#007bff",
    primary25: "#cce5ff",
    danger: "#dc3545",
    dangerLight: "#f8d7da",
    approved: "green",
    rejected: "red",
    hold: "orange",
    lightGray: "#f8f9fa",
    darkGray: "#343a40",
  };

  const getColor = (value) => {
    switch (value) {
      case "ACTIVE":
        return colors.approved;
      case "ON_HOLD":
        return colors.hold;
      case "CLOSED":
        return colors.rejected;
      case "UNDER_REVIEW":
        return colors.rejected;
      default:
        return colors.darkGray;
    }
  };

  const customStylesSelect = {
    control: (provided, state) => ({
      ...provided,
      width: "160px",
      borderColor: state.isFocused ? colors.primary : provided.borderColor,
      boxShadow: state.isFocused
        ? `0 0 0 1px ${colors.primary}`
        : provided.boxShadow,
      "&:hover": {
        borderColor: colors.primary,
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      color: getColor(state.data.value),
      "&::before": {
        content: '"•"',
        color: getColor(state.data.value),
        marginRight: "8px",
        fontSize: "40px",
        lineHeight: "20px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      backgroundColor: state.isSelected
        ? getColor(state.data.value)
        : state.isFocused
        ? colors.primary25
        : colors.lightGray,
      color: state.isSelected || state.isFocused ? "#fff" : colors.darkGray,
      "&:hover": {
        backgroundColor: colors.primary25,
        color: colors.darkGray,
      },
      "&::before": {
        content: '"•"',
        color: getColor(state.data.value),
        marginRight: "8px",
        fontSize: "40px",
        lineHeight: "20px",
      },
    }),
  };
  const handleStatusChange = (jobId, newStatus) => {
    const data = {
      job_id: jobId,
      status: newStatus,
    };

    dispatch(changeJobStatusRequest(data));
  };

  const tableColumns = [
    {
      name: "#",
      short_column: "job_id",
      cell: (row, index) => index + 1,
      width: "5%",
      sortable: true,
      center: false,
    },
    {
      name: "Job Title",
      short_column: "job_name",
      sortable: true,
      center: false,
      // width: "16%",
      cell: (row) =>
        row.job_name ? (
          <Link
            to={`${process.env.PUBLIC_URL}/recruiter/job_details/${generateSlug(
              row.job_name,
              row.job_location,
              formatExperienceOutput(
                row.work_experience_min,
                row.work_experience_max
              ).replace("-", "-to-"),
              row.job_id
            )}`}
            title={"View Job"}
          >
            {capitalize(row.job_name)}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Location",
      short_column: "job_location",
      cell: (row) => capitalize(row.job_location) || "-",
      sortable: true,
      center: false,
      //width: "14%",
    },
    {
      name: "Experience",
      width: "12%",
      cell: (row) => {
        const experienceOutput = formatExperienceOutput(
          row.work_experience_min,
          row.work_experience_max
        );
        return experienceOutput !== "N/A" ? experienceOutput : "-";
      },
      sortable: false,
      center: true,
    },
    {
      name: "Created At",
      short_column: "created_at",
      cell: (row) => formatDate(row.created_at) || "-",
      sortable: true,
      center: false,
      width: "13%",
    },
    {
      name: "Applications",
      width: "12%",
      sortable: false,
      center: true,
      cell: (row) =>
        row.applied_candidate_count ? (
          <Link
            to={`${
              process.env.PUBLIC_URL
            }/recruiter/job_applications/${generateSlug(
              row.job_name,
              row.job_location,
              formatExperienceOutput(
                row.work_experience_min,
                row.work_experience_max
              ).replace("-", "-to-"),
              row.job_id
            )}`}
          >
            {row.applied_candidate_count}
          </Link>
        ) : (
          0
        ),
    },
    // {
    //   name: "Status",
    //   width: "115px",
    //   sortable: false,
    //   center: true,
    //   cell: (row) => (
    //     <span className={`badge ${getStatusColor(row.job_status)}`}>
    //       {unslugAndCapitalize(row.job_status)}
    //     </span>
    //   ),
    // },
    {
      name: "Status", // New column
      width: "17%",
      sortable: false,
      center: true,
      cell: (row) =>
        row.job_status == "UNDER_REVIEW" ? (
          <Select
            components={animatedComponents}
            className="basic-single"
            classNamePrefix="select"
            value={{ value: "UNDER_REVIEW", label: "Under Review" }}
            onChange={(selectedOption) =>
              handleStatusChange(row.job_id, selectedOption.value)
            }
            options={[{ value: "UNDER_REVIEW", label: "Under Review" }].map(
              (option) => ({
                value: option.value,
                label: option.label,
              })
            )}
            styles={customStylesSelect}
            isDisabled={["CLOSED", "UNDER_REVIEW"].includes(row.job_status)} // Disable the entire Select component if status is CLOSED or UNDER_REVIEW
          />
        ) : (
          <Select
            components={animatedComponents}
            className="basic-single"
            classNamePrefix="select"
            value={jobStatusOptions.find(
              (option) => option.value === row.job_status
            )}
            onChange={(selectedOption) =>
              handleStatusChange(row.job_id, selectedOption.value)
            }
            options={jobStatusOptions.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            styles={customStylesSelect}
            isDisabled={["CLOSED", "UNDER_REVIEW"].includes(row.job_status)} // Disable the entire Select component if status is CLOSED or UNDER_REVIEW
          />
        ),
    },

    {
      name: "Actions",
      cell: (row) => (
        <div>
          <span>
            <Link
              to={`${
                process.env.PUBLIC_URL
              }/recruiter/job_details/${generateSlug(
                row.job_name,
                row.job_location,
                formatExperienceOutput(
                  row.work_experience_min,
                  row.work_experience_max
                ).replace("-", "-to-"),
                row.job_id
              )}`}
              title={"View Job"}
            >
              <Eye
                size={18}
                className="eye-icon"
                style={{ marginRight: "7px" }}
              />
            </Link>
            {row.job_status !== "CLOSED" &&
              row.job_status !== "UNDER_REVIEW" && (
                <Link
                  to={`${process.env.PUBLIC_URL}/recruiter/post_job/${
                    row.employment_type === "INTERNSHIP" ? "internship" : "job"
                  }/${generateSlug(
                    row.job_name,
                    row.job_location,
                    formatExperienceOutput(
                      row.work_experience_min,
                      row.work_experience_max
                    ).replace("-", "-to-"),
                    row.job_id
                  )}`}
                  title={"Edit Job"}
                >
                  <Edit2
                    size={18}
                    className="edit-icon"
                    style={{ marginRight: "7px" }}
                  />
                </Link>
              )}
            <Link
              to={`${process.env.PUBLIC_URL}/recruiter/post_job/${
                row.employment_type === "INTERNSHIP" ? "internship" : "job"
              }/${generateSlug(
                row.job_name,
                row.job_location,
                formatExperienceOutput(
                  row.work_experience_min,
                  row.work_experience_max
                ).replace("-", "-to-"),
                row.job_id
              )}/duplicate`}
              title={"Duplicate Job"}
            >
              <IoMdCopy
                size={22}
                className="copy-icon"
                style={{ marginRight: "7px" }}
              />
            </Link>
            {row.job_status !== "CLOSED" &&
              row.job_status !== "UNDER_REVIEW" && (
                <Link
                  to={`${
                    process.env.PUBLIC_URL
                  }/recruiter/job_applications/${generateSlug(
                    row.job_name,
                    row.job_location,
                    formatExperienceOutput(
                      row.work_experience_min,
                      row.work_experience_max
                    ).replace("-", "-to-"),
                    row.job_id
                  )}`}
                  title={"View Job Candidate"}
                >
                  <Users size={18} className="user-icon" />
                </Link>
              )}
            {/* <span title="Delete Job">
              <Trash2
                size={18}
                onClick={() => handleDelete(row.job_id)}
                color="red"
                style={{ marginRight: "10px", cursor: "pointer" }}
              />
            </span> */}
          </span>
        </div>
      ),
      sortable: false,
      center: true,
      width: "12%",
    },
  ];

  useEffect(() => {
    const page = currentPage;
    const per_page = perPage;
    const data = {
      type: formData.dateRange.type,
      status: activeTab,
      keyword: searchQuery,
      startDate: formData.dateRange.startDate
        ? formData.dateRange.startDate.toISOString()
        : null, // Convert Date objects to strings
      endDate: formData.dateRange.endDate
        ? formData.dateRange.endDate.toISOString()
        : null,
    };
    dispatch(fetchManageJobsRequest(page, per_page, data));
  }, [activeTab, perPage, currentPage, formData]);

  // handle server side sorting
  const handleSort = async (column, direction) => {
    console.log("column", direction);
    try {
      const page = currentPage;
      const per_page = perPage;
      const short_by = column.short_column;
      const column_direction = direction;
      const data = {
        type: formData.dateRange.type,
        status: activeTab,
        keyword: searchQuery,
        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : null, // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : null,
      };
      dispatch(
        fetchManageJobsRequest(page, per_page, data, short_by, column_direction)
      );
    } catch (error) {
      console.error("Error fetching sorted data:", error);
    }
  };

  const manageJobsData = manageJobsReducerData.data.data;

  // code to show toast message
  useEffect(() => {
    if (manageJobsReducerData.status && manageJobsReducerData.message != "") {
      showToast("success", manageJobsReducerData.message);
      const page = currentPage;
      const per_page = perPage;
      const data = {
        status: activeTab,
        type: formData.dateRange.type,
        keyword: searchQuery,
        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : null, // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : null,
      };
      dispatch(fetchManageJobsRequest(page, per_page, data));
      dispatch(resetJob());
    } else if (
      manageJobsReducerData.status == false &&
      manageJobsReducerData.message != ""
    ) {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", manageJobsReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", manageJobsReducerData.message);
      }
      dispatch(resetJob());
    }
  }, [manageJobsReducerData]);

  const changeJobStatusReducerData = useSelector(
    (state) => state.changeJobStatusReducer
  );
  useEffect(() => {
    if (
      changeJobStatusReducerData.status &&
      changeJobStatusReducerData.message != ""
    ) {
      showToast("success", changeJobStatusReducerData.message);
      const page = currentPage;
      const per_page = perPage;
      const data = {
        status: activeTab,
        type: formData.dateRange.type,
        keyword: searchQuery,
        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : null, // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : null,
      };
      dispatch(fetchManageJobsRequest(page, per_page, data));
      dispatch(resetJobStatusMessage());
    } else if (
      changeJobStatusReducerData.status == false &&
      changeJobStatusReducerData.message != ""
    ) {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", changeJobStatusReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", changeJobStatusReducerData.message);
      }
      dispatch(resetJobStatusMessage());
    }
  }, [changeJobStatusReducerData]);

  return (
    <Fragment>
      <Row>
        <Col xs="12" className="box-col-12 xl-100">
          <Card>
            <CardHeader className="py-3">
              <Nav
                className="nav-pills nav-primary justify-content-between mb-3"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {tabOptions.map((item, i) => (
                  <NavItem key={i}>
                    <NavLink
                      className={`font-weight-bold ${
                        activeTab === item.value ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(item.value)}
                      style={{ cursor: "pointer" }}
                    >
                      {item.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <div className="d-flex flex-wrap justify-content-between align-items-end">
                {/* Search Bar */}

                <Form
                  className="theme-form me-2 flex-grow-1"
                  onSubmit={handleSubmit}
                >
                  <div
                    className="input-group flex-nowrap "
                    style={{ height: 35, maxWidth: "350px" }}
                  >
                    <Input
                      className="form-control"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="search"
                      placeholder="Search.."
                    />
                    <Button
                      color="primary"
                      type="submit"
                      className="btn-primary input-group-text pe-3 ps-3"
                    >
                      Search
                    </Button>
                  </div>
                </Form>

                {/* Date Picker Button */}
                <div className="d-flex align-items-center ms-auto mt-3 mt-md-0">
                  <Button
                    color="primary"
                    id="shownCalendar"
                    onClick={() => setDatePickerOpen(!datePickerOpen)}
                    className="btn btn-primary d-flex align-items-center ps-3 pe-3"
                  >
                    <FaCalendarAlt className="" />
                    <span className="ms-2">
                      {formData &&
                        (formData.dateRange.type === "CUSTOM"
                          ? `${formatDate(
                              formData.dateRange.startDate,
                              "dd-MM-yyyy"
                            )} - ${formatDate(
                              formData.dateRange.endDate,
                              "dd-MM-yyyy"
                            )}`
                          : unslugAndCapitalize(formData.dateRange.type))}
                    </span>
                  </Button>
                  {datePickerOpen && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50px", // Adjust as needed based on your layout
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      {/* <DefinedRange
                          onChange={handleDateRangeChange}
                          ranges={[formData.dateRange]}
                          open={datePickerOpen}
                        /> */}
                      <DateRangePicker
                        editableDateInputs
                        onChange={handleDateRangeChange}
                        ranges={[formData.dateRange]}
                        locale={locale}
                        // months={2}
                        direction="horizontal"
                        open={datePickerOpen}
                        onToggle={(open) => setDatePickerOpen(open)}
                        staticRanges={customStaticRanges}
                      />
                    </div>
                  )}
                </div>
              </div>
            </CardHeader>

            <CardBody className="px-0 py-3">
              <div className="tabbed-card">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={activeTab}>
                    <DataTable
                      customStyles={customStyles}
                      data={manageJobsData}
                      columns={tableColumns}
                      striped={true}
                      center={true}
                      pagination
                      paginationServer
                      paginationTotalRows={manageJobsReducerData.data.total}
                      paginationPerPage={perPage}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      progressPending={manageJobsReducerData.isLoading}
                      progressComponent={<Spinner />}
                      noDataComponent={
                        <NoDataFound message={"No jobs found"} />
                      }
                      onSort={(column, direction) =>
                        handleSort(column, direction)
                      }
                    />
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ManageJobs;
