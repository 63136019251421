import { takeLatest, call, put } from "redux-saga/effects";
import AxiosInstance from "../../../services/AxiosInstance";
import * as types from "../../actionTypes";
import { fetchAppliedJobsFailure, fetchAppliedJobsSuccess } from "./actions";

function* fetchAppliedJobsSaga(action) {
  console.log("action123", action.payload);

  let url = `/jobseeker/applied_jobs?page=${action.payload.page}&per_page=${action.payload.per_page}`;

  if (action.payload.column) {
    url += `&sortBy=${action.payload.column}`;
  }

  if (action.payload.direction) {
    url += `&direction=${action.payload.direction}`;
  }
  try {
    const response = yield call(() =>
      AxiosInstance().post(url, action.payload.data)
    );
    if (response.data.status == true) {
      yield put(fetchAppliedJobsSuccess(response.data));
    } else {
      yield put(fetchAppliedJobsFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchAppliedJobsFailure({ status: false, message: error.message })
    );
  }
}

function* appliedJobsSaga() {
  yield takeLatest(types.FETCH_APPLIED_JOBS_REQUEST, fetchAppliedJobsSaga);
}

export default appliedJobsSaga;
