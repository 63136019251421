import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  inviteRecruiterRequest,
  resetInviteRecruiter,
} from "../../../redux/recruiter/invite_recruiter/actions";
import { showToast } from "../../Common/Toast";
import {
  fetchRecruiterInvitesRequest,
  resetFetchRecruiterInvites,
} from "../../../redux/recruiter/fetch_recruiter_invite/actions";
import { SYSTEM_NAME } from "../../../Constant";
import { fetchCompanyProfile } from "../../../redux/recruiter/company_profile/actions";
const InviteModal = (props) => {
  const dispatch = useDispatch();
  const { cand_id, jobcad_id } = useParams();
  const [errors, setErrors] = useState({});
  const [emailList, setEmailList] = useState([]);
  const emailInputRef = useRef(null);
  const [formData, setFormData] = useState({
    recruiter_email: "",
  });

  const handleChange = (e) => {
    setFormData({ recruiter_email: e.target.value });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addEmail(formData.recruiter_email.trim());
    }
  };

  const addEmail = (email) => {
    if (!email) return;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors({ recruiter_email: "Please enter valid email addresses" });
      return;
    }

    if (!emailList.includes(email)) {
      setEmailList((prevEmails) => [...prevEmails, email]);
      setFormData({ recruiter_email: "" });
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmailList((prevEmails) =>
      prevEmails.filter((email) => email !== emailToRemove)
    );
  };

  const validate = () => {
    const errors = {};
    let isValid = true;
    if (emailList.length === 0) {
      errors.recruiter_email = "At least one email is required";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        inviteRecruiterRequest({ recruiter_email: emailList.join(",") })
      );
    }
  };

  const inviteRecruiterReducerData = useSelector(
    (state) => state.inviteRecruiterReducer
  );

  useEffect(() => {
    if (inviteRecruiterReducerData.message) {
      if (inviteRecruiterReducerData.status) {
        props.toggleModal();
      }
    }
  }, [inviteRecruiterReducerData, dispatch, props]);

  const companyProfileReducerData = useSelector(
    (state) => state.companyProfileReducer
  );
  const [companyName, setCompanyName] = useState(null);

  useEffect(() => {
    // Fetch company profile on component mount if data doesn't exist
    if (!companyProfileReducerData || !companyProfileReducerData.data) {
      dispatch(fetchCompanyProfile());
    }
  }, []);

  useEffect(() => {
    if (
      companyProfileReducerData &&
      !companyProfileReducerData.isLoading &&
      companyProfileReducerData.data &&
      companyProfileReducerData.data.company_name
    ) {
      setCompanyName(companyProfileReducerData.data.company_name.label);
    }
  }, [companyProfileReducerData]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4 className="m-0">Add User</h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <FormGroup className="mb-4">
            <Label className="mb-0">
              Email Address <span className="text-danger">*</span>
            </Label>
            <small className="text-muted d-block mt-1">
              You can add multiple email
            </small>
            <div
              style={{
                border: "1px solid #ced4da",
                borderRadius: "4px",
                padding: "5px",
                minHeight: "38px",
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "#f8f9fa",
                cursor: "text",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
              onClick={() => emailInputRef.current.focus()}
            >
              {emailList.map((email, index) => (
                <span
                  key={index}
                  style={{
                    backgroundColor: "#e9ecef",
                    borderRadius: "3px",
                    padding: "5px 8px",
                    margin: "2px",
                    fontSize: "14px",
                    color: "#495057",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveEmail(email)}
                >
                  {email} &times;
                </span>
              ))}
              <input
                ref={emailInputRef}
                type="text"
                value={formData.recruiter_email}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  minWidth: "100px",
                  flex: "1",
                }}
                placeholder="Enter email address"
              />
            </div>
            {errors.recruiter_email && (
              <small className="text-danger d-block mt-1">
                {errors.recruiter_email}
              </small>
            )}
          </FormGroup>

          <div style={{ margin: "20px 0" }}>
            <strong>Note:</strong>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                marginTop: "10px",
              }}
            >
              <li style={{ marginBottom: "5px" }}>
                By clicking "Save and notify new user", I agree {SYSTEM_NAME}{" "}
                may share access to {companyName} account with this user,
              </li>
              <li style={{ marginBottom: "5px" }}>
                that I am authorized to grant such access,
              </li>
              <li style={{ marginBottom: "5px" }}>
                and for my email address to be shared with the user in this
                email notification.
              </li>
              <li>
                This user's access will match the role(s) I have selected for
                them.
              </li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={inviteRecruiterReducerData.isLoading}
          >
            Save and notify new user
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default InviteModal;
