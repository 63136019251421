import React, { Fragment, useContext } from "react";
import { Col, Row } from "reactstrap";
import ChatMessage from "./ChatMessage";
import ChatMenu from "./ChatMenu";
import ChatHeader from "./ChatHeader";

import SendChat from "./SendChat";
import ChatAppContext from "../../../Chat";

const Chatting = () => {
  const { profileToggle, selectedUser } = useContext(ChatAppContext) || {};

  return (
    <Fragment>
      <Row className="chat-box ">
        {selectedUser ? (
          <>
            <Col className="pe-0 chat-right-aside">
              <div className="chat">
                <ChatHeader />
                <ChatMessage />
                <SendChat />
              </div>
            </Col>
            {profileToggle && (
              <Col className="ps-0 chat-menu show">
                <ChatMenu />
              </Col>
            )}
          </>
        ) : (
          <Col className="d-flex justify-content-center align-items-center">
            <div className="align-items text-center">
              <h3>Start a Conversation</h3>
              <p>Select a chat to start messaging.</p>
            </div>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};
export default Chatting;
