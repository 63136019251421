import { call, put, takeLatest } from "redux-saga/effects";
import { FORGET_PASSWORD_REQUEST } from "../../actionTypes";
// import { postJobApi } from "./api"; // Replace with your actual API file
import AxiosInstance from "../../../services/AxiosInstance";

import { forgetPasswordSuccess, forgetPasswordFailure } from "./actions";

function* forgetPassword(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/auth/forgot_password", action.payload)
    );

    if (response.data.status) {
      yield put(forgetPasswordSuccess(response.data));
    } else {
      yield put(forgetPasswordFailure(response.data));
    }
  } catch (error) {
    yield put(forgetPasswordFailure({ status: false, message: error.message }));
  }
}

// Watch for POST_JOB_REQUEST action
function* forgotPasswordSaga() {
  yield takeLatest(FORGET_PASSWORD_REQUEST, forgetPassword);
}

export default forgotPasswordSaga;
