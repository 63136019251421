import { call, put, takeLatest } from "redux-saga/effects";
import { VERIFY_ACCOUNT_REQUEST } from "../../actionTypes";
// import { postJobApi } from "./api"; // Replace with your actual API file
import AxiosInstance from "../../../services/AxiosInstance";

import { verifyAccountSuccess, verifyAccountFailure } from "./actions";

function* verifyAccount(action) {
  //console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/auth/verify_account", action.payload)
    );

    if (response.data.status) {
      yield put(verifyAccountSuccess(response.data));
    } else {
      yield put(verifyAccountFailure(response.data));
    }
  } catch (error) {
    yield put(verifyAccountFailure({ status: false, message: error.message }));
  }
}

// Watch for POST_JOB_REQUEST action
function* verifyAccountSaga() {
  yield takeLatest(VERIFY_ACCOUNT_REQUEST, verifyAccount);
}

export default verifyAccountSaga;
