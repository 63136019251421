import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import JobApplications from "../../Components/Recruiter/JobApplications/JobApplications";

const JobApplicationsPage = () => {
  
  return (
    <Fragment>
      <Breadcrumbs title="Job Applications" mainTitle="Job Applications" />
      <Container fluid={true}>
        <JobApplications/>
      </Container>
    </Fragment>
  );
};

export default JobApplicationsPage;
