// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import { fetchFunctionalAreaFailure,fetchFunctionalAreaSuccess } from "./actions";

function* fetchFunctionalAreaSaga(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(() =>
      AxiosInstance().post("/fetch_functional_area", action.payload)
    );
    if(response.data.status){
      yield put(fetchFunctionalAreaSuccess(response.data));
    }else{
      yield put(fetchFunctionalAreaFailure(response.data));
    }
    
    
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchFunctionalAreaFailure({status:false,message:error.message}));
  }
}

// Watch for the latest FETCH_FUNCTIONAL_AREA_REQUEST action and call the saga
function* functionalAreaSaga() {
  yield takeLatest(
    types.FETCH_FUNCTIONAL_AREA_REQUEST,
    fetchFunctionalAreaSaga
  );
}

export default functionalAreaSaga;
