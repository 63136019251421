// actions.js
import {
  GENERATE_LINKEDIN_BANNER_REQUEST,
  GENERATE_LINKEDIN_BANNER_SUCCESS,
  GENERATE_LINKEDIN_BANNER_FAILURE,
} from "../actionTypes";

export const generateLinkedInBannerRequest = (data) => ({
  type: GENERATE_LINKEDIN_BANNER_REQUEST,
  payload: data,
});

export const generateLinkedInBannerSuccess = (data) => ({
  type: GENERATE_LINKEDIN_BANNER_SUCCESS,
  payload: data,
});

export const generateLinkedInBannerFailure = (error) => ({
  type: GENERATE_LINKEDIN_BANNER_FAILURE,
  payload: error,
});

// export const resetTotalPoints = () => ({
//   type: RESET_TOTAL_POINTS,
// });
