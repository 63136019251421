import {
  FETCH_SAVED_CANDIDATES_REQUEST,
  FETCH_SAVED_CANDIDATES_SUCCESS,
  FETCH_SAVED_CANDIDATES_FAILURE,
  RESET_SAVED_CANDIDATES,
  DELETE_SAVED_CANDIDATE_REQUEST,
  DELETE_SAVED_CANDIDATE_SUCCESS,
  DELETE_SAVED_CANDIDATE_FAILURE,
} from "../../actionTypes";

export const fetchSavedCandidatesRequest = (
  page,
  per_page,
  data,
  column,
  direction
) => {
  return {
    type: FETCH_SAVED_CANDIDATES_REQUEST,
    payload: { page, per_page, data, column, direction },
  };
};
export const fetchSavedCandidatesSuccess = (jobs) => {
  return {
    type: FETCH_SAVED_CANDIDATES_SUCCESS,
    payload: jobs,
  };
};

export const fetchSavedCandidatesFailure = (error) => {
  return {
    type: FETCH_SAVED_CANDIDATES_FAILURE,
    payload: error,
  };
};

export const deleteSavedCandidateRequest = (data) => ({
  type: DELETE_SAVED_CANDIDATE_REQUEST,
  payload: data,
});

export const deleteSavedCandidateSuccess = (success) => ({
  type: DELETE_SAVED_CANDIDATE_SUCCESS,
  payload: success,
});

export const deleteSavedCandidateFailure = (error) => ({
  type: DELETE_SAVED_CANDIDATE_FAILURE,
  payload: error,
});

export const resetSavedCandidates = () => ({
  type: RESET_SAVED_CANDIDATES,
});
