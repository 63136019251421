//saga.jsx
import { call, put, takeLatest } from "redux-saga/effects";
import { submitReportSuccess, submitReportFailure } from "./actions";
import {
  SUBMIT_REPORT_REQUEST,
  SUBMIT_REPORT_SUCCESS,
  SUBMIT_REPORT_FAILURE,
} from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* submitReport(action) {
  console.log("report job page line 18", action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/report_job", action.payload)
    );

    if (response.data.status) {
      yield put(submitReportSuccess(response.data));
    } else {
      yield put(submitReportFailure(response.data));
    }
  } catch (error) {
    yield put(submitReportFailure(error.message));
  }
}

function* reportJobSaga() {
  yield takeLatest(SUBMIT_REPORT_REQUEST, submitReport);
}

export default reportJobSaga;
