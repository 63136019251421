import { combineReducers } from "redux";
import loginReducer from "./auth/login/loginReducer";
import registerReducer from "./auth/register/registerReducer";
import forgotPasswordReducer from "./auth/forgot_password/forgotPasswordReducer";
import resetPasswordReducer from "./auth/reset_password/resetPasswordReducer";
import verifyAccountReducer from "./auth/verify_account/verifyAccountReducer";

import jobseekerProfileReducer from "./jobseeker/update_profile/reducer";
import appliedJobsReducer from "./jobseeker/applied_jobs/appliedJobsReducer";
import recommendedJobsReducer from "./jobseeker/recommended_jobs/recommendedJobsReducer";
import manageJobsReducer from "./recruiter/manage_jobs/manageJobsReducer";
import recruiterProfileReducer from "./recruiter/recruiter_profile/recruiterProfileReducer";
import companyProfileReducer from "./recruiter/company_profile/companyProfileReducer";
import jobDetailsReducer from "./jobdetails/jobDetailsReducer";
import changePasswordReducer from "./recruiter/change_password/changePasswordReducer";
import jobApplicationsReducer from "./recruiter/job_applications/jobApplicationsReducer";
import addCandidateReducer from "./recruiter/add_candidate/addCandidateReducer";
import scanResumeReducer from "./jobseeker/scan_resume/scanResumeReducer";
import generateResumesReducer from "./jobseeker/generate_resumes/generateResumesReducer";
import locationReducer from "./location/locationReducer";
import educationReducer from "./education/educationReducer";
import countryReducer from "./country/countryReducer";
import functionalAreaReducer from "./functionalArea/functionalAreaReducer";
import industryTypeReducer from "./industryType/industryTypeReducer";

import mandatorySkillsReducer from "./mandatorySkills/mandatorySkillsReducer";
import postJobReducer from "./recruiter/post_job/postJobReducer";
import jobSearchReducer from "./jobseeker/job_search/jobSearchReducer";

import socialMediaReducer from "./jobseeker/social_media/socialMediaReducer";
import skillsReducer from "./jobseeker/skills/skillsReducer";
import updateResumeReducer from "./jobseeker/resume_headline/updateResumeReducer";
import addEducationReducer from "./jobseeker/add_education/addEducationReducer";
// sandeep work
import manageCandidateReducer from "./recruiter/manage_candidates/manageCandidateReducer";
import addWorkExpReducer from "./jobseeker/work_experience/addWorkExpReducer";
import mainSkillReducer from "./jobseeker/main_skill/mainSkillReducer";
import workSampleReducer from "./jobseeker/work_sample/workSampleReducer";
import desireCareerReducer from "./jobseeker/desired_career/desireCareerReducer";

import dashboardReducer from "./recruiter/dashboard/dashboardReducer";
import candidateSearchReducer from "./recruiter/candidate_search/candidateSearchReducer";
import jobseekerDashboardReducer from "./jobseeker/dashboard/jobseekerDashboardReducer";
import avatarReducer from "./jobseeker/update_avatar/avatarReducer";
// raj
import referralRecruiterReducer from "./recruiter/referral/referralRecruiterReducer";
import referralJobseekerReducer from "./jobseeker/referral/referralJobseekerReducer";
import reportJobReducer from "../redux/jobseeker/report_job/reportJobReducer";
import updateReducer from "./jobseeker/update_resume/updateReducer";
import fetchJobseekerDataReducer from "./jobseeker/fetch_Jobseeker_data/fetchJobseekerDataReducer";
import updateCandidateInfoReducer from "./recruiter/update_candidate_info/updateCandidateInfoReducer";
import updateContactibilityReducer from "./recruiter/update_contactibility/updateContactibilityReducer";
import updateCommunicationReducer from "./recruiter/update_communication/updateCommunicationReducer";
import updateCandidateExperienceReducer from "./recruiter/update_candidate_experience/updateCandidateExperienceReducer";
import updateCandidateRatingReducer from "./recruiter/update_candidate_rating/updateCandidateRatingReducer";

import addNoteReducer from "./recruiter/add_note/addNoteReducer";

import jobPipelineReducer from "./recruiter/job_pipeline/jobPipelineReducer";
import weeklyStatusReducer from "./recruiter/weekly_status/weeklyStatusReducer";
import detailReportReducer from "./recruiter/detail_report/detailReportReducer";
import jobNameReducer from "./recruiter/job_name/jobNameReducer";
import favoriteJobReducer from "./jobseeker/favorite_job/favoriteJobReducer";
import jobSourceReducer from "./recruiter/job_source/jobSourceReducer";
import notificationsReducer from "./notifications/notificationsReducer";
import jobFeedbackReducer from "./jobseeker/job_feedback/jobFeedbackReducer";
import jobSharingReducer from "./job_sharing/jobSharingReducer";
import companyReducer from "./company/companyReducer";
import changeJobseekerPasswordReducer from "./jobseeker/change_password/changeJobseekerPasswordReducer";
import updateCandidateTechnicalSkillsReducer from "./recruiter/update_candidate_technical_skills/updateCandidateTechnicalSkillsReducer";
import updateCandidateSoftSkillsReducer from "./recruiter/update_candidate_soft_skills/updateCandidateSoftSkillsReducer";
import goToPassbookReducer from "./goToPassbook/goToPassbookReducer";
import resumeTemplateReducer from "./resumeTemplates/resumeTemplateReducer";
import checkTemplateLockedReducer from "./jobseeker/check_template_locked/checkTemplateLockedReducer";
import shareResumeTemplateReducer from "./jobseeker/share_resume_template/shareResumeTemplateReducer";
import shareSeekerDataReducer from "./jobseeker/share_seeker_data/shareSeekerDataReducer";
import unlockTemplateReducer from "./jobseeker/unlock_template/unlockTemplateReducer";
import addContactedCountReducer from "./jobseeker/add_contacted_count/addContactedCountReducer";
import jobConversionReducer from "./recruiter/job_conversion/jobConversionReducer";
import chatReducer from "./recruiter/chat_message/chat/chatReducer";
import publicApplyJobReducer from "./public/public_apply_job/publicApplyJobReducer";
import candidateBulkUploadReducer from "./recruiter/candidate_bulk_upload/candidateBulkUploadReducer";
import candidateBulkExcelUploadReducer from "./recruiter/candidate_bulk_excel_upload/candidateBulkExcelUploadReducer";
import changeJobStatusReducer from "./recruiter/change_job_status/changeJobStatusReducer";
import updateCandidatePrimarySkillReducer from "./recruiter/update_candidate_primary_skill/updateCandidatePrimarySkillReducer";
import extendJobExpireDateReducer from "./recruiter/extend_job_expire_date/extendJobExpireDateReducer";
import inviteRecruiterReducer from "./recruiter/invite_recruiter/inviteRecruiterReducer";
import fetchRecruiterInviteReducer from "./recruiter/fetch_recruiter_invite/fetchRecruiterInviteReducer";
import verifyRecruiterInviteTokenReducer from "./public/verify_recruiter_invite_token/verifyRecruiterInviteTokenReducer";
import acceptOrRejectInviteReducer from "./public/accept_or_reject_invite/acceptOrRejectInviteReducer";
import getTotalPointsReducer from "./get_total_points/getTotalPointsReducer";
import fetchRewardsPointsReducer from "./fetch_rewards_points/fetchRewardsPointsReducer";
import generateLinkedinBannerReducer from "./generate_linkedin_banner/generateLinkedinBannerReducer";
import getLinkedinBannerReducer from "./get_linkedin_banner/getLinkedinBannerReducer";
import updateLinkedinBannerReducer from "./update_linkedin_banner/updateLinkedinBannerReducer";
import jobSeekerPublicProfileReducer from "./public/jobseeker_public_profile/jobSeekerPublicProfileReducer";
import candidateSaveForLaterReducer from "./recruiter/candidate_save_for_later/candidateSaveForLaterReducer";
import fetchSavedCandidatesReducer from "./recruiter/fetch_saved_candidates/fetchSavedCandidatesReducer";
import joinGroupReducer from "./join_group/joinGroupReducer";

const rootReducer = combineReducers({
  loginReducer,
  registerReducer,
  verifyAccountReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  jobseekerProfileReducer,
  appliedJobsReducer,
  recommendedJobsReducer,
  manageJobsReducer,
  recruiterProfileReducer,
  companyProfileReducer,
  jobDetailsReducer,
  changePasswordReducer,
  changeJobseekerPasswordReducer,
  jobApplicationsReducer,
  addCandidateReducer,
  generateResumesReducer,
  scanResumeReducer,
  locationReducer,
  countryReducer,
  educationReducer,
  mandatorySkillsReducer,
  functionalAreaReducer,
  industryTypeReducer,
  postJobReducer,
  jobSharingReducer,
  manageCandidateReducer,
  jobSearchReducer,
  socialMediaReducer,
  skillsReducer,
  updateResumeReducer,
  addEducationReducer,
  addWorkExpReducer,
  mainSkillReducer,
  workSampleReducer,
  desireCareerReducer,
  referralRecruiterReducer,
  referralJobseekerReducer,
  reportJobReducer,
  dashboardReducer,
  candidateSearchReducer,

  jobseekerDashboardReducer,
  updateReducer,
  avatarReducer,
  fetchJobseekerDataReducer,
  updateCandidateInfoReducer,
  updateContactibilityReducer,
  updateCommunicationReducer,
  updateCandidateExperienceReducer,
  updateCandidateTechnicalSkillsReducer,
  updateCandidateSoftSkillsReducer,
  updateCandidateRatingReducer,
  addNoteReducer,
  jobConversionReducer,
  jobSourceReducer,
  jobPipelineReducer,
  weeklyStatusReducer,
  detailReportReducer,
  jobNameReducer,
  favoriteJobReducer,
  notificationsReducer,
  jobFeedbackReducer,
  companyReducer,
  goToPassbookReducer,
  resumeTemplateReducer,
  checkTemplateLockedReducer,
  shareResumeTemplateReducer,
  shareSeekerDataReducer,
  unlockTemplateReducer,
  addContactedCountReducer,
  chatReducer,
  publicApplyJobReducer,
  candidateBulkUploadReducer,
  candidateBulkExcelUploadReducer,
  changeJobStatusReducer,
  updateCandidatePrimarySkillReducer,
  extendJobExpireDateReducer,
  inviteRecruiterReducer,
  fetchRecruiterInviteReducer,
  verifyRecruiterInviteTokenReducer,
  acceptOrRejectInviteReducer,
  getTotalPointsReducer,
  fetchRewardsPointsReducer,
  generateLinkedinBannerReducer,
  getLinkedinBannerReducer,
  updateLinkedinBannerReducer,
  jobSeekerPublicProfileReducer,
  candidateSaveForLaterReducer,
  fetchSavedCandidatesReducer,
  joinGroupReducer,
});

export default rootReducer;
