import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { fetchJobNamesSuccess, fetchJobNamesFailure } from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { FETCH_JOB_NAMES_REQUEST } from "../../actionTypes";

function* fetchJobNames(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post(`/recruiter/recruiter_jobs_name`, action.payload)
    );
    if (response.data.status) {
      yield put(fetchJobNamesSuccess(response.data));
    } else {
      yield put(fetchJobNamesFailure(response.data));
    }
  } catch (error) {
    yield put(fetchJobNamesFailure({ status: false, message: error.message }));
  }
}

function* jobNameSaga() {
  yield takeEvery(FETCH_JOB_NAMES_REQUEST, fetchJobNames);
}

export default jobNameSaga;
