import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import {
  addEducationFailure,
  addEducationSuccess,
  deleteEducationBackgroundFailure,
  deleteEducationBackgroundSuccess,
} from "./actions";

function* addEducation(action) {
  console.log("ad", action);
  try {
    let url = "jobseeker/add_school_education_profile";
    if (
      action.payload.education_level != "SECONDARY" &&
      action.payload.education_level != "SENIOR_SECONDARY"
    ) {
      url = "jobseeker/add_education_profile";
    }
    const response = yield call(() =>
      AxiosInstance().post(url, action.payload)
    );
    console.log("sr", response);
    if (response.data.status) {
      yield put(addEducationSuccess(response.data));
    } else {
      yield put(addEducationFailure(response.data));
    }
  } catch (error) {
    yield put(addEducationFailure({ status: false, message: error.message }));
  }
}

function* deleteEducationBackground(action) {
  try {
    let response;
    if (action.payload.type === "school") {
      response = yield call(() =>
        AxiosInstance().post(
          "jobseeker/delete_school_education",
          // {
          // id: action.payload.id,
          // }
          action.payload
        )
      );
    } else if (action.payload.type === "college") {
      response = yield call(() =>
        AxiosInstance().post(
          "jobseeker/delete_education",
          // {
          // id: action.payload.id,
          // }
          action.payload
        )
      );
    }

    if (response.data.status) {
      yield put(deleteEducationBackgroundSuccess(response.data));
    } else {
      yield put(deleteEducationBackgroundFailure(response.data));
    }
  } catch (error) {
    yield put(
      deleteEducationBackgroundFailure({
        status: false,
        message: error.message,
      })
    );
  }
}

function* addEducationSaga() {
  yield takeLatest(types.ADD_EDUCATION_BACKGROUND_REQUEST, addEducation);
  yield takeLatest(
    types.DELETE_EDUCATION_BACKGROUND_REQUEST,
    deleteEducationBackground
  );
}

export default addEducationSaga;
