import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
  data: {},
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      // console.log(action.payload);
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };

    case RESET_JOB: // Reset job data
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default loginReducer;
