import * as types from "../../actionTypes";

export const fetchJobseekerDataRequest = (data) => ({
  type: types.FETCH_JOBSEEKER_DATA_REQUEST,
  payload: data,
});

export const fetchJobseekerDataSuccess = (data) => ({
  type: types.FETCH_JOBSEEKER_DATA_SUCCESS,
  payload: data,
});

export const fetchJobseekerDataFailure = (error) => ({
  type: types.FETCH_JOBSEEKER_DATA_FAILURE,
  payload: error,
});

export const updatePersonalInfo = (data) => {
  return { type: types.UPDATE_PERSONAL_INFO_REQUEST, payload: data };
};

export const resetData = () => ({
  type: types.RESET_DATA,
});
