// sagas.jsx
import { call, put, takeLatest } from "redux-saga/effects";
import { checkTemplateLockSuccess, checkTemplateLockFailure } from "./actions";
import { CHECK_TEMPLATE_LOCK_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* checkTemplateLock(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/check_template_locked", action.payload)
    );

    if (response.data.status) {
      yield put(checkTemplateLockSuccess(response.data));
    } else {
      yield put(checkTemplateLockFailure(response.message));
    }
  } catch (error) {
    yield put(checkTemplateLockFailure(error.message)); // Dispatch failure action with error message
  }
}

function* checkTemplateLockedSaga() {
  yield takeLatest(CHECK_TEMPLATE_LOCK_REQUEST, checkTemplateLock);
}

export default checkTemplateLockedSaga;
