import {
  FETCH_JOB_DETAILS_REQUEST,
  FETCH_JOB_DETAILS_SUCCESS,
  FETCH_JOB_DETAILS_FAILURE,
  APPLY_JOB_REQUEST,
  APPLY_JOB_SUCCESS,
  APPLY_JOB_FAILURE,
  RESET_MESSAGE,
} from "../actionTypes";

export const fetchJobDetails = (data) => ({
  type: FETCH_JOB_DETAILS_REQUEST,
  payload: data,
});

export const fetchJobDetailsSuccess = (jobDetails) => ({
  type: FETCH_JOB_DETAILS_SUCCESS,
  payload: jobDetails,
});

export const fetchJobDetailsFailure = (error) => ({
  type: FETCH_JOB_DETAILS_FAILURE,
  payload: error,
});

export const applyJobRequest = (data) => ({
  type: APPLY_JOB_REQUEST,
  payload: data,
});

export const applyJobSuccess = (message) => ({
  type: APPLY_JOB_SUCCESS,
  payload: message,
});

export const applyJobFailure = (error) => ({
  type: APPLY_JOB_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetMessage = () => ({
  type: RESET_MESSAGE,
});
