import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_DASHBOARD_DATA_REQUEST,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_FAILURE,
} from "../../actionTypes";

import {
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* fetchDashboardData(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/dashboard", action.payload)
    );
    if (response.data.status) {
      yield put(fetchDashboardDataSuccess(response.data));
    } else {
      fetchDashboardDataFailure(response.data);
    }
  } catch (error) {
    yield put(
      fetchDashboardDataFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* dashboardSaga() {
  yield takeLatest(FETCH_DASHBOARD_DATA_REQUEST, fetchDashboardData);
}
export default dashboardSaga;
