import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  Col,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { jobsourceOptions, customOptionsStyle } from "../../Constant";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import JobSourceDatatable from "../../Components/Recruiter/JobSource/JobSourceDatatable";
import { fetchJobSourcesRequest } from "../../redux/recruiter/job_source/actions";
import { fetchJobNamesRequest } from "../../redux/recruiter/job_name/actions";
import { FaCalendarAlt } from "react-icons/fa"; // FontAwesome calendar icon
const JobSourcePage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    jobId: null,
    jobSource: null,
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
    },
  });
  const [errors, setErrors] = useState({});
  const [datePickerOpen, setDatePickerOpen] = useState(false); // State to manage date picker visibility

  useEffect(() => {
    const formateData = {
      job_id: formData.jobId,
      startDate: formData.dateRange.startDate.toISOString(), // Convert Date objects to strings
      endDate: formData.dateRange.endDate.toISOString(),
      source: formData.jobSource,
    };
    // Dispatch action to fetch job pipeline data
    dispatch(fetchJobSourcesRequest(formateData)); // Pass form data as payload
  }, []);

  const jobSourceReducerData = useSelector((state) => state.jobSourceReducer);
  const jobSourceData = jobSourceReducerData.data; // Assuming your data structure
  const handleJobSourceChange = (selectedOption) => {
    setFormData({
      ...formData,
      jobSource: selectedOption.value, // Store only the value
    });
    setErrors({
      ...errors,
      jobSource: "",
    });
  };

  const handleDateRangeChange = (ranges) => {
    if (ranges.range1 && ranges.range1.startDate && ranges.range1.endDate) {
      setFormData({
        ...formData,
        dateRange: {
          startDate: ranges.range1.startDate,
          endDate: ranges.range1.endDate,
        },
      });
      setErrors({
        ...errors,
        dateRange: "",
      });
      setDatePickerOpen(false); // Close the date picker after selecting a valid date range
    } else {
      console.error("Invalid date range selection:", ranges);
    }
  };

  const [jobNameOptions, setJobNameOptions] = useState([]);

  //read industry type data from store
  let jobNameReducerData = useSelector((state) => state.jobNameReducer);

  let jobNameData = jobNameReducerData.data.data;

  useEffect(() => {
    if (jobNameData && jobNameData.length > 0) {
      setJobNameOptions(jobNameData);
    } else {
      setJobNameOptions([]); // Reset options if data is empty
    }
  }, [jobNameData]);

  const handleJobNameFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchJobNamesRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleJobNameInputChange = useCallback((inputValue) => {
    // Update the location search value state
    // setLocationSearchValue(inputValue);

    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchJobNamesRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleJobNameChange = (selectedOptions) => {
    // Clear errors and set job ID
    const updatedErrors = { ...errors, jobId: "" };
    setErrors(updatedErrors);

    setFormData({
      ...formData,
      jobId: selectedOptions ? selectedOptions.value : null,
    });
  };

  const formatDate = (date) => {
    // Format date as DD/MM/YYYY
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.dateRange.startDate || !formData.dateRange.endDate) {
      newErrors.dateRange = "Please select a date range";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formateData = {
        job_id: formData.jobId,
        startDate: formData.dateRange.startDate.toISOString(), // Convert Date objects to strings
        endDate: formData.dateRange.endDate.toISOString(),
        source: formData.jobSource,
      };
      // Dispatch action to fetch job pipeline data
      dispatch(fetchJobSourcesRequest(formateData)); // Pass form data as payload
    } else {
      console.error("Form validation failed");
    }
  };

  const locale = {
    localize: {
      month: (monthIndex) => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return months[monthIndex];
      },
      day: (dayIndex) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[dayIndex];
      },
    },
    formatLong: {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  };

  return (
    <Fragment>
      <Breadcrumbs title="Job Source" mainTitle="Job Source" />
      <Container fluid>
        <Form className="theme-form" onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <div className="row gx-3">
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <FormGroup className="mb-0">
                    <Label>Job Name</Label>
                    <Select
                      isLoading={jobNameReducerData.isLoading}
                      options={jobNameOptions}
                      value={jobNameOptions.find(
                        (opt) => opt.id === formData.jobId
                      )}
                      onChange={handleJobNameChange}
                      styles={customOptionsStyle}
                      onFocus={handleJobNameFocus}
                      onInputChange={handleJobNameInputChange}
                      placeholder="Select job name..."
                    />
                  </FormGroup>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                  <FormGroup className="mb-0">
                    <Label>Job Source</Label>
                    <Select
                      options={jobsourceOptions}
                      value={jobsourceOptions.find(
                        (opt) => opt.id === formData.jobSource
                      )}
                      onChange={handleJobSourceChange}
                      placeholder="Select job source..."
                    />
                  </FormGroup>
                </div>
                <div className="col-12 col-lg-5 d-flex align-items-center mb-3">
                  <div className="flex-grow-1 d-flex align-items-center me-2">
                    <FormGroup className="mb-0 flex-grow-1">
                      <Label>
                        Date
                        <span className="text-danger">*</span>
                      </Label>
                      <Button
                        color="primary"
                        id="shownCalendar"
                        onClick={() => setDatePickerOpen(!datePickerOpen)}
                        className="d-flex align-items-center px-3 flex-grow-1"
                        style={{ height: "38px" }}
                      >
                        <FaCalendarAlt />
                        <span className="ms-2">
                          {`${formatDate(
                            formData.dateRange.startDate
                          )} - ${formatDate(formData.dateRange.endDate)}`}
                        </span>
                      </Button>
                    </FormGroup>
                  </div>
                  <Button
                    color="primary"
                    type="submit"
                    className="flex-grow-1"
                    style={{ height: "38px" }}
                    disabled={jobSourceReducerData.isLoading}
                  >
                    Apply
                  </Button>
                </div>
              </div>

              {datePickerOpen && (
                <div className="mt-3">
                  <DateRangePicker
                    editableDateInputs
                    onChange={handleDateRangeChange}
                    ranges={[formData.dateRange]}
                    locale={locale}
                    months={2}
                    direction="horizontal"
                    open={datePickerOpen}
                    onToggle={(open) => setDatePickerOpen(open)}
                  />
                </div>
              )}
              <JobSourceDatatable
                jobSourceData={jobSourceData}
                formData={formData}
              />
            </CardBody>
          </Card>
        </Form>
      </Container>
    </Fragment>
  );
};

export default JobSourcePage;
