import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import {
  addSocialMediaSuccess,
  addSocialMediaFailure,
  deleteSocialMediaSuccess,
  deleteSocialMediaFailure,
} from "./actions";

function* addSocialMedia(action) {
  console.log("ad", action);
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/add_online_profile", action.payload)
    );
    console.log("sr", response);
    if (response.data.status) {
      yield put(addSocialMediaSuccess(response.data));
    } else {
      yield put(addSocialMediaFailure(response.data));
    }
  } catch (error) {
    yield put(addSocialMediaFailure({ status: false, message: error.message }));
  }
}

function* deleteSocialMedia(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/delete_online_profile", action.payload)
    );
    if (response.data.status) {
      yield put(deleteSocialMediaSuccess(response.data));
    } else {
      yield put(deleteSocialMediaFailure(response.data));
    }
  } catch (error) {
    yield put(
      deleteSocialMediaFailure({ status: false, message: error.message })
    );
  }
}

function* socialMediaSaga() {
  yield takeLatest(types.ADD_SOCIAL_MEDIA_REQUEST, addSocialMedia);
  yield takeLatest(types.DELETE_SOCIAL_MEDIA_REQUEST, deleteSocialMedia);
}

export default socialMediaSaga;
