import {
  POST_JOB_REQUEST,
  POST_JOB_SUCCESS,
  POST_JOB_FAILURE,
  FETCH_JOB_REQUEST,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

const initialState = {
  status:false,
  isLoading: false,
  message: "",
  data: null,
};

const postJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_JOB_REQUEST:
      // console.log(action.payload);
      return {
        ...state,
        isLoading: true,
      };
    case POST_JOB_SUCCESS:
      return {
        ...state,
        status:action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };
    case POST_JOB_FAILURE:
      return {
        ...state,
        status:action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };
    case FETCH_JOB_REQUEST: // Handle fetching job request
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOB_SUCCESS: // Handle fetching job success
      return {
        ...state,
        status:action.payload.status,
        isLoading: false,
        message:"",
        data: action.payload.data, // Store fetched job data
      };
    case FETCH_JOB_FAILURE: // Handle fetching job failure
      return {
        ...state,
        status:action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };
    case RESET_JOB: // Reset job data
      return {
        ...state,
        status:false,
        isLoading:false,
        message:"",
        data: null,
      };
    default:
      return state;
  }
};

export default postJobReducer;
