import React, { Fragment, useEffect } from "react";
import { Col, Row, Button, Table } from "reactstrap";
import { downloadPipelineCsvRequest } from "../../../redux/recruiter/job_pipeline/actions";
import { useDispatch } from "react-redux";
import { capitalize } from "../../Common/CommonFunction";
import NoDataFound from "../../Common/NoDataFound";

const JobPipelineDatatable = ({ jobPipelineData, formData }) => {
  console.log("hhhh", formData);
  // Extract job data from jobPipelineData if it exists
  const jobData = jobPipelineData.data;
  const dispatch = useDispatch();
  const handleExport = () => {
    const obj = {
      candidate_status: formData.candidate_status,
      job_id: formData.jobId,
      startDate: formData.dateRange.startDate.toISOString(), // Convert Date objects to strings
      endDate: formData.dateRange.endDate.toISOString(),
    };

    dispatch(downloadPipelineCsvRequest(obj));
  };

  if (!jobData || Object.keys(jobData).length === 0) {
    return (
      <div className="mt-3">
        <NoDataFound />
      </div>
    );
  }

  return (
    <Fragment>
      <Row className="d-flex justify-content-start mb-3 mt-3">
        <Col xs="auto">
          <Button color="primary" onClick={handleExport}>
            Export
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="box-col-12 xl-100">
          <Table striped responsive>
            <thead>
              <tr>
                <th>#</th>
                {/* <th>Username</th> */}
                <th>Job Name</th>
                <th>Application Received</th>

                <th>Interviewed</th>
                <th>Shortlisted</th>

                <th>On Hold</th>

                <th>Hired</th>

                <th>Rejected</th>
                {/* <th>Added By</th> */}
              </tr>
            </thead>
            <tbody>
              {Object.keys(jobData).map((jobKey, index) => {
                const jobDetails = jobData[jobKey];
                const {
                  // user_name,
                  job_name,
                  application_received,

                  interviewed,
                  shortlisted,
                  on_hold,
                  hired,
                  rejected,
                  // added_by_recruiter,
                } = jobDetails || {};

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{capitalize(job_name) || "-"}</td>
                    <td>{application_received || "0"}</td>

                    <td>{interviewed || "0"}</td>
                    <td>{shortlisted || "0"}</td>
                    <td>{on_hold || "0"}</td>
                    <td>{hired || "0"}</td>
                    <td>{rejected || "0"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Fragment>
  );
};

export default JobPipelineDatatable;
