// actions.js
import {
  FETCH_JOBSEEKER_REFERRAL_REQUEST,
  FETCH_JOBSEEKER_REFERRAL_SUCCESS,
  FETCH_JOBSEEKER_REFERRAL_FAILURE,
  } from "../../actionTypes";
  
  export const fetchJobseekerReferralRequest = () => ({
    type: FETCH_JOBSEEKER_REFERRAL_REQUEST,
  });
  
  export const fetchJobseekerReferralSuccess = (request) => ({
    type: FETCH_JOBSEEKER_REFERRAL_SUCCESS,
    payload: request,
  });
  
  export const fetchJobseekerReferralFailure = (error) => ({
    type: FETCH_JOBSEEKER_REFERRAL_FAILURE,
    payload: error,
  });
  