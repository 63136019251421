import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_CANDIDATE_REQUEST,
  FETCH_ADD_CANDIDATE_REQUEST,
} from "../../actionTypes";

import {
  addCandidateSuccess,
  addCandidateFailure,
  fetchCandidateSuccess,
  fetchCandidateFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* addCandidate(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/add_candidate", action.payload)
    );
    if (response.data.status) {
      yield put(addCandidateSuccess(response.data));
    } else {
      yield put(addCandidateFailure(response.data));
    }
  } catch (error) {
    yield put(addCandidateFailure({ status: false, message: error.message }));
  }
}

function* fetchCandidate(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/fetch_candidate", action.payload)
    );
    if (response.data.status) {
      yield put(fetchCandidateSuccess(response.data));
    } else {
      yield put(fetchCandidateFailure(response.data));
    }
  } catch (error) {
    yield put(fetchCandidateFailure({ status: false, message: error.message }));
  }
}

// Watcher saga
function* addCandidateSaga() {
  yield takeLatest(ADD_CANDIDATE_REQUEST, addCandidate);
  yield takeLatest(FETCH_ADD_CANDIDATE_REQUEST, fetchCandidate);
}
export default addCandidateSaga;
