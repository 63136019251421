import {
  FETCH_JOB_CONVERSION_REQUEST,
  FETCH_JOB_CONVERSION_SUCCESS,
  FETCH_JOB_CONVERSION_FAILURE,
  DOWNLOAD_JOB_CONVERSION_CSV_REQUEST,
  DOWNLOAD_JOB_CONVERSION_CSV_SUCCESS,
  DOWNLOAD_JOB_CONVERSION_CSV_FAILURE,
} from "../../actionTypes";

export const fetchJobConversionRequest = (data) => {
  return {
    type: FETCH_JOB_CONVERSION_REQUEST,
    payload: data,
  };
};
export const fetchJobConversionSuccess = (jobSources) => {
  return {
    type: FETCH_JOB_CONVERSION_SUCCESS,
    payload: jobSources,
  };
};

export const fetchJobConversionFailure = (error) => {
  return {
    type: FETCH_JOB_CONVERSION_FAILURE,
    payload: error,
  };
};

export const downloadJobConversionCsvRequest = (data) => {
  return {
    type: DOWNLOAD_JOB_CONVERSION_CSV_REQUEST,
    payload: data,
  };
};
export const downloadJobConversionCsvSuccess = (jobSources) => {
  return {
    type: DOWNLOAD_JOB_CONVERSION_CSV_SUCCESS,
    payload: jobSources,
  };
};

export const downloadJobConversionCsvFailure = (error) => {
  return {
    type: DOWNLOAD_JOB_CONVERSION_CSV_FAILURE,
    payload: error,
  };
};
