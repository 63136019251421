import { all } from "redux-saga/effects";

// auth saga
import loginSaga from "../redux/auth/login/loginSaga";
import registerSaga from "../redux/auth/register/registerSaga";
import forgotPasswordSaga from "../redux/auth/forgot_password/forgotPasswordSaga";
import resetPasswordSaga from "../redux/auth/reset_password/resetPasswordSaga";
import verifyAccountSaga from "../redux/auth/verify_account/verifyAccountSaga";

import jobSeekerProfileSaga from "../redux/jobseeker/update_profile/profileSaga";
import appliedJobsSaga from "../redux/jobseeker/applied_jobs/appliedJobsSagas";
import recommendedJobsSaga from "../redux/jobseeker/recommended_jobs/recommendedJobsSaga";
import manageJobsSaga from "../redux/recruiter/manage_jobs/manageJobsSaga";
import recruiterProfileSaga from "../redux/recruiter/recruiter_profile/recruiterProfileSaga";
import companyProfileSaga from "../redux/recruiter/company_profile/companyProfileSaga";
import jobDetailsSaga from "../redux/jobdetails/jobDetailsSaga";
import changePasswordSaga from "../redux/recruiter/change_password/changePasswordSaga";
import jobApplicationsSaga from "../redux/recruiter/job_applications/jobApplicationsSaga";
import addCandidateSaga from "../redux/recruiter/add_candidate/addCandidateSaga";
import generateResumesSaga from "../redux/jobseeker/generate_resumes/generateResumesSaga";
import scanResumeSaga from "../redux/jobseeker/scan_resume/scanResumeSaga";
import locationSaga from "../redux/location/locationSaga";
import educationSaga from "../redux/education/educationSaga";
import countrySaga from "../redux/country/countrySaga";
import mandatorySkillsSaga from "../redux/mandatorySkills/mandatorySkillsSaga";
import functionalAreaSaga from "../redux/functionalArea/functionalAreaSaga";
import industryTypeSaga from "../redux/industryType/industryTypeSaga";
import postJobSaga from "../redux/recruiter/post_job/postJobSaga";
import jobSearchSaga from "../redux/jobseeker/job_search/jobSearchSaga";
// sandeep work
import manageCandidateSaga from "../redux/recruiter/manage_candidates/manageCandidatesSaga";
import socialMediaSaga from "../redux/jobseeker/social_media/socialMediaSaga";
import skillsSaga from "../redux/jobseeker/skills/skillsSaga";
import updateResumeSaga from "../redux/jobseeker/resume_headline/updateResumeSaga";
import addEducationSaga from "../redux/jobseeker/add_education/addEducationSaga";
import addWorkExpSaga from "../redux/jobseeker/work_experience/addWorkExpSaga";
import mainSkillSaga from "../redux/jobseeker/main_skill/mainSkillSaga";
import workSampleSaga from "../redux/jobseeker/work_sample/workSampleSaga";
import desireCareerSaga from "../redux/jobseeker/desired_career/desireCareerSaga";
import jobSourceSaga from "../redux/recruiter/job_source/jobSourceSaga";
import jobPipelineSaga from "../redux/recruiter/job_pipeline/jobPipelineSaga";
import weeklyStatusSaga from "../redux/recruiter/weekly_status/weeklyStatusSaga";
import detailReportSaga from "../redux/recruiter/detail_report/detailReportSaga";
import jobNameSaga from "../redux/recruiter/job_name/jobNameSaga";
import favoritejobSaga from "../redux/jobseeker/favorite_job/favoritejobSaga";

// raj
import referralSaga from "../redux/recruiter/referral/referralRecruiterSaga";
import referralJobseekerSaga from "../redux/jobseeker/referral/referralJobseekerSaga";
import reportJobSaga from "../redux/jobseeker/report_job/reportJobSaga";

import dashboardSaga from "../redux/recruiter/dashboard/dashboardSaga";
import candidateSearchSaga from "../redux/recruiter/candidate_search/candidateSearchSaga";
import jobseekerDashboardSaga from "../redux/jobseeker/dashboard/jobseekerDashboardSaga";
import updateSaga from "../redux/jobseeker/update_resume/updateSaga";
import avatarSaga from "../redux/jobseeker/update_avatar/avatarSaga";
import fetchJobseekerDataSaga from "../redux/jobseeker/fetch_Jobseeker_data/fetchJobseekerDataSaga";
import updateCandidateInfoSaga from "../redux/recruiter/update_candidate_info/updateCandidateInfoSaga";
import updateContactibilitySaga from "../redux/recruiter/update_contactibility/updateContactibilitySaga";
import updateCommunicationSaga from "../redux/recruiter/update_communication/updateCommunicationSaga";
import updateCandidateExperienceSaga from "../redux/recruiter/update_candidate_experience/updateCandidateExperienceSaga";
import updateCandidateTechnicalSkillsSaga from "../redux/recruiter/update_candidate_technical_skills/updateCandidateTechnicalSkillsSaga";
import updateCandidateSoftSkillsSaga from "../redux/recruiter/update_candidate_soft_skills/updateCandidateSoftSkillsSaga";
import updateCandidateRatingSaga from "../redux/recruiter/update_candidate_rating/updateCandidateRatingSaga";
import addNoteSaga from "../redux/recruiter/add_note/addNoteSaga";
import notificationsSaga from "../redux/notifications/notificationsSaga";
import jobFeedbackSaga from "../redux/jobseeker/job_feedback/jobFeedbackSaga";
import companySaga from "../redux/company/companySaga";
import changeJobseekerPasswordSaga from "../redux/jobseeker/change_password/changeJobseekerPasswordSaga";
import goToPassbookSaga from "../redux/goToPassbook/goToPassbookSaga";
import resumeTemplateSaga from "../redux/resumeTemplates/resumeTemplateSaga";
import checkTemplateLockedSaga from "../redux/jobseeker/check_template_locked/checkTemplateLockedSaga";
import shareResumeTemplateSaga from "../redux/jobseeker/share_resume_template/shareResumeTemplateSaga";
import shareSeekerDataSaga from "../redux/jobseeker/share_seeker_data/shareSeekerDataSaga";
import unlockTemplateSaga from "../redux/jobseeker/unlock_template/unlockTemplateSaga";
import addContactedCountSaga from "../redux/jobseeker/add_contacted_count/addContactedCountSaga";
import jobConversionSaga from "../redux/recruiter/job_conversion/jobConversionSaga";
import chatSaga from "../redux/recruiter/chat_message/chat/chatSaga";
import publicApplyJobSaga from "../redux/public/public_apply_job/publicApplyJobSaga";
import candidateBulkUploadSaga from "../redux/recruiter/candidate_bulk_upload/candidateBulkUploadSaga";
import candidateBulkExcelUploadSaga from "../redux/recruiter/candidate_bulk_excel_upload/candidateBulkExcelUploadSaga";
import jobSharingSaga from "../redux/job_sharing/jobSharingSaga";
import changeJobStatusSaga from "../redux/recruiter/change_job_status/changeJobStatusSaga";
import updateCandidatePrimarySkillSaga from "../redux/recruiter/update_candidate_primary_skill/updateCandidatePrimarySkillSaga";
import extendJobExpireDateSaga from "../redux/recruiter/extend_job_expire_date/extendJobExpireDateSaga";
import inviteRecruiterSaga from "../redux/recruiter/invite_recruiter/inviteRecruiterSaga";
import fetchRecruiterInviteSaga from "../redux/recruiter/fetch_recruiter_invite/fetchRecruiterInviteSaga";
import verifyRecruiterInviteTokenSaga from "../redux/public/verify_recruiter_invite_token/verifyRecruiterInviteTokenSaga";
import acceptOrRejectInviteSaga from "../redux/public/accept_or_reject_invite/acceptOrRejectInviteSaga";
import getTotalPointsSaga from "../redux/get_total_points/getTotalPointsSaga";
import fetchRewardsPointsSaga from "../redux/fetch_rewards_points/fetchRewardsPointsSaga";
import generateLinkedinBannerSaga from "../redux/generate_linkedin_banner/generateLinkedinBannerSaga";
import getLinkedinBannerSaga from "../redux/get_linkedin_banner/getLinkedinBannerSaga";
import updateLinkedinBannerSaga from "../redux/update_linkedin_banner/updateLinkedinBannerSaga";
import jobseekerPublicProfileSaga from "../redux/public/jobseeker_public_profile/jobseekerPublicProfileSaga";
import candidateSaveForLaterSaga from "../redux/recruiter/candidate_save_for_later/candidateSaveForLaterSaga";
import fetchSavedCandidatesSaga from "../redux/recruiter/fetch_saved_candidates/fetchSavedCandidatesSaga";
import joinGroupSaga from "../redux/join_group/joinGroupSaga";

export default function* rootSagas() {
  yield all([
    loginSaga(),
    registerSaga(),
    verifyAccountSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
    jobSeekerProfileSaga(),
    appliedJobsSaga(),
    recommendedJobsSaga(),
    manageJobsSaga(),
    recruiterProfileSaga(),
    companyProfileSaga(),
    jobDetailsSaga(),
    changePasswordSaga(),
    jobApplicationsSaga(),
    addCandidateSaga(),
    generateResumesSaga(),
    locationSaga(),
    countrySaga(),
    educationSaga(),
    mandatorySkillsSaga(),
    functionalAreaSaga(),
    industryTypeSaga(),
    postJobSaga(),
    jobSharingSaga(),
    manageCandidateSaga(),
    jobSearchSaga(),
    socialMediaSaga(),
    skillsSaga(),
    updateResumeSaga(),
    scanResumeSaga(),
    addEducationSaga(),
    addWorkExpSaga(),
    mainSkillSaga(),
    workSampleSaga(),
    desireCareerSaga(),
    referralJobseekerSaga(),
    referralSaga(),
    reportJobSaga(),
    dashboardSaga(),
    candidateSearchSaga(),
    jobseekerDashboardSaga(),
    updateSaga(),
    avatarSaga(),
    fetchJobseekerDataSaga(),
    updateCandidateInfoSaga(),
    updateContactibilitySaga(),
    updateCommunicationSaga(),
    updateCandidateExperienceSaga(),
    updateCandidateTechnicalSkillsSaga(),
    updateCandidateSoftSkillsSaga(),
    updateCandidateRatingSaga(),
    addNoteSaga(),
    jobConversionSaga(),
    jobSourceSaga(),
    jobPipelineSaga(),
    weeklyStatusSaga(),
    detailReportSaga(),
    jobNameSaga(),
    favoritejobSaga(),
    notificationsSaga(),
    jobFeedbackSaga(),
    companySaga(),
    changeJobseekerPasswordSaga(),
    goToPassbookSaga(),
    resumeTemplateSaga(),
    checkTemplateLockedSaga(),
    shareResumeTemplateSaga(),
    shareSeekerDataSaga(),
    unlockTemplateSaga(),
    addContactedCountSaga(),
    chatSaga(),
    publicApplyJobSaga(),
    candidateBulkUploadSaga(),
    candidateBulkExcelUploadSaga(),
    changeJobStatusSaga(),
    updateCandidatePrimarySkillSaga(),
    extendJobExpireDateSaga(),
    inviteRecruiterSaga(),
    fetchRecruiterInviteSaga(),
    verifyRecruiterInviteTokenSaga(),
    acceptOrRejectInviteSaga(),
    getTotalPointsSaga(),
    fetchRewardsPointsSaga(),
    generateLinkedinBannerSaga(),
    getLinkedinBannerSaga(),
    updateLinkedinBannerSaga(),
    jobseekerPublicProfileSaga(),
    candidateSaveForLaterSaga(),
    fetchSavedCandidatesSaga(),
    joinGroupSaga(),
  ]);
}
