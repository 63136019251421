import {
  FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST,
  FETCH_JOBSEEKER_DASHBOARD_DATA_SUCCESS,
  FETCH_JOBSEEKER_DASHBOARD_DATA_FAILURE,
  RESET_DAILY,
} from "../../actionTypes";

export const fetchDashboardDataRequest = () => ({
  type: FETCH_JOBSEEKER_DASHBOARD_DATA_REQUEST,
});

export const fetchDashboardDataSuccess = (data) => ({
  type: FETCH_JOBSEEKER_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const fetchDashboardDataFailure = (error) => ({
  type: FETCH_JOBSEEKER_DASHBOARD_DATA_FAILURE,
  payload: error,
});

export const resetDailyVisit = () => ({
  type: RESET_DAILY,
});
