import React, { Fragment, useState, useContext } from "react";
import { Range, getTrackBackground } from "react-range";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Form,
  Input,
  Label,
} from "reactstrap";
import { CandidateSearchContext } from "../../../../CandidateSearch/CandidateSearchContext";
const Ctc = () => {
  const [isFilter, setIsFilter] = useState(true);
  const { filters, updateFilters } = useContext(CandidateSearchContext);

  const STEP = 1;
  const MIN = 0;
  const MAX = 100000000;

  const handleRangeChange = (newValues) => {
    //setValues(newValues);
    updateFilters({
      minctc: newValues[0],
      maxctc: newValues[1],
    });
  };

  const handleInputChange = (key, event) => {
    const { value } = event.target;
    updateFilters({
      ...filters,
      [key]: parseInt(value),
    });
  };

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">CTC (LPA)</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="filter-cards-view animate-chk">
              <Form className="theme-form form-label-align-right range-slider">
                <Row className="mb-3 align-items-center">
                  <Col md="5">
                    <Label>Min</Label>
                    <Input
                      type="number"
                      value={filters.minctc}
                      onChange={(e) => handleInputChange("minctc", e)}
                    />
                  </Col>
                  <Col md="2" className="pt-5">
                    <span className="d-block">To</span>
                  </Col>
                  <Col md="5">
                    <Label>Max</Label>
                    <Input
                      type="number"
                      value={filters.maxctc}
                      onChange={(e) => handleInputChange("maxctc", e)}
                    />
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col md="12">
                    <Range
                      values={[filters.minctc, filters.maxctc]}
                      step={STEP}
                      min={MIN}
                      max={MAX}
                      onChange={handleRangeChange}
                      renderTrack={({ props, children }) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: "36px",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <output style={{ marginTop: "12px" }}>
                            {/* {values[0]} */}
                          </output>
                          <div
                            ref={props.ref}
                            style={{
                              height: "5px",
                              width: "100%",
                              borderRadius: "4px",
                              background: getTrackBackground({
                                values: [filters.minctc, filters.maxctc],
                                colors: ["#ccc", "#7366ff", "#ccc"],
                                min: MIN,
                                max: MAX,
                              }),
                              alignSelf: "center",
                            }}
                          >
                            {children}
                          </div>
                          <output style={{ marginTop: "12px" }}>
                            {/* {values[1]} */}
                          </output>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "35px",
                            width: "35px",
                            borderRadius: "30px",
                            backgroundColor: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 2px 6px #AAA",
                          }}
                        >
                          <div
                            style={{
                              height: "16px",
                              width: "5px",
                              backgroundColor: isDragged ? "#7366ff" : "#CCC",
                            }}
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Ctc;
