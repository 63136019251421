import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_CANDIDATE_SOFT_SKILLS_REQUEST } from "../../actionTypes";

import {
  updateCandidateSoftSkillsSuccess,
  updateCandidateSoftSkillsError,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updateCandidateSoftSkills(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/soft_skills", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(updateCandidateSoftSkillsSuccess(response.data));
    } else {
      yield put(updateCandidateSoftSkillsError(response.data));
    }
  } catch (error) {
    yield put(
      updateCandidateSoftSkillsError({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* updateCandidateSoftSkillsSaga() {
  yield takeLatest(
    UPDATE_CANDIDATE_SOFT_SKILLS_REQUEST,
    updateCandidateSoftSkills
  );
}
export default updateCandidateSoftSkillsSaga;
