import { takeLatest, put, call } from "redux-saga/effects";
import {
  FETCH_DETAIL_REPORT_REQUEST,
  DOWNLOAD_RECRUITER_DETAILS_CSV_REQUEST,
} from "../../actionTypes";
import { fetchDetailReportSuccess, fetchDetailReportFailure } from "./actions";

import AxiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

function* fetchDetailReport(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/recruiter_detail_report`,
      action.payload
    );

    if (response.data.status) {
      yield put(fetchDetailReportSuccess(response.data));
    } else {
      yield put(fetchDetailReportFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchDetailReportFailure({ status: false, message: error.message })
    );
  }
}

function* downloadRecruiterDetailsCSV(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/recruiter_details_table_csv_download`,
      action.payload
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const downloadLink = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);

    downloadLink.download = "exported_data.xls";
    downloadLink.href = blobUrl;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error:", error);
    // Handle errors here
  }
}

export default function* detailReportSaga() {
  yield takeLatest(FETCH_DETAIL_REPORT_REQUEST, fetchDetailReport);
  yield takeLatest(
    DOWNLOAD_RECRUITER_DETAILS_CSV_REQUEST,
    downloadRecruiterDetailsCSV
  );
}
