import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { FaPhoneAlt, FaGlobe } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { fetchCompanyProfile } from "../../../redux/recruiter/company_profile/actions";
import { fetchSkillsRequest } from "../../../redux/mandatorySkills/actions";
import {
  AVATAR,
  SYSTEM_PREFIX,
  roleOptions,
  customOptionsStyle,
} from "../../../Constant";
import Spinner from "../../Common/Spinner";
import { useParams } from "react-router";
import { FaGraduationCap } from "react-icons/fa6";
import { TbBuilding } from "react-icons/tb";
import { capitalize, formatWebsite } from "../../Common/CommonFunction";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const BannerModal = (props) => {
  const dispatch = useDispatch();
  const savedState = localStorage.getItem("bannerProfile");
  const parsedState = JSON.parse(savedState);
  const [errors, setErrors] = useState({});
  const [currentIndex, setCurrentIndex] = useState(props.index);
  const componentRef = useRef(null);

  const [profile, setProfile] = useState({
    photo: AVATAR,
    name: "",
    email: "",
    phone: "",
    role: null,

    includeEmail: false,
    includePhone: false,
    includeWebsite: false,
    quotes: parsedState && parsedState.quotes ? parsedState.quotes : "",
    jobs: parsedState && parsedState.jobs ? parsedState.jobs : "",

    company_name: "",
    company_logo: null,
    company_website: null,
  });

  const getLinkedinBannerReducerData = useSelector(
    (state) => state.getLinkedinBannerReducer
  );
  const allBanners = getLinkedinBannerReducerData.data;

  const recruiterProfileReducerData = useSelector(
    (state) => state.recruiterProfileReducer
  );

  useEffect(() => {
    if (
      !recruiterProfileReducerData.isLoading &&
      recruiterProfileReducerData.data &&
      recruiterProfileReducerData.status === true
    ) {
      const data = recruiterProfileReducerData.data;

      if (savedState) {
        const updatedProfile = {
          photo: data.profile_image ? data.profile_image : AVATAR,
          name: data.recruiter_full_name,
          email: data.recruiter_email,
          phone: data.recruiter_mobile_number,
          role: roleOptions.find((option) => option.value === data.role)?.label,

          includeEmail: parsedState.includeEmail,
          includePhone: parsedState.includePhone,
          includeWebsite: parsedState.includeWebsite,
        };
        setProfile((prevProfile) => ({
          ...prevProfile,
          ...updatedProfile,
        }));
      } else {
        const updatedProfile = {
          photo: data.profile_image ? data.profile_image : AVATAR,
          name: data.recruiter_full_name,
          email: data.recruiter_email,
          phone: data.recruiter_mobile_number,
          role: roleOptions.find((option) => option.value === data.role)?.label,

          includeEmail: !!data.recruiter_email,
          includePhone: !!data.recruiter_mobile_number,
        };
        setProfile((prevProfile) => ({
          ...prevProfile,
          ...updatedProfile,
        }));
      }
    }
  }, [
    recruiterProfileReducerData.isLoading,
    recruiterProfileReducerData.data,
    recruiterProfileReducerData.status,
  ]);

  const companyProfileReducerData = useSelector(
    (state) => state.companyProfileReducer
  );

  useEffect(() => {
    dispatch(fetchCompanyProfile());
  }, [dispatch]);

  //const companyProfile = companyProfileReducerData.data;

  useEffect(() => {
    if (
      companyProfileReducerData &&
      companyProfileReducerData.data &&
      !companyProfileReducerData.isLoading
    ) {
      const data = companyProfileReducerData.data;

      if (savedState) {
        const updatedProfile = {
          includeWebsite: parsedState.includeWebsite,

          company_name: (data.company_name && data.company_name.label) || "",
          company_logo: data.company_logo || "",
          company_website: data.company_website || "",
        };
        setProfile((prevProfile) => ({
          ...prevProfile,
          ...updatedProfile,
        }));
      } else {
        const updatedProfile = {
          includeWebsite: !!data.company_website,

          company_name: (data.company_name && data.company_name.label) || "",
          company_logo: data.company_logo || "",
          company_website: data.company_website || "",
        };
        setProfile((prevProfile) => ({
          ...prevProfile,
          ...updatedProfile,
        }));
      }
    }
  }, [
    companyProfileReducerData.data,
    companyProfileReducerData.isLoading,
    currentIndex,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    setErrors({ ...errors, [name]: error });

    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: checked,
    }));
  };

  const handleNext = (event) => {
    event.preventDefault(); // Prevents the default form or link behavior
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allBanners.length);
  };

  const handlePrevious = (event) => {
    event.preventDefault(); // Prevents the default form or link behavior
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + allBanners.length) % allBanners.length
    );
  };

  useEffect(() => {
    // Update the quotes when the slide (currentIndex) changes
    if (!profile.quotes) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        quotes: allBanners[currentIndex].default_quotes || "", // Set the quotes from the banner or an empty string if not available
      }));
    }
  }, [currentIndex, allBanners]);

  const validate = () => {
    const errors = {};
    let isValid = true;

    if (allBanners[currentIndex].job_allowed != 0) {
      const jobTitles = profile.jobs.split(",").map((job) => job.trim());
      if (jobTitles.length > allBanners[currentIndex].job_allowed) {
        errors.jobs = `You can only enter up to ${allBanners[currentIndex].job_allowed} jobs.`;
        isValid = false;
      }
    }
    setErrors(errors);
    return isValid;
  };

  const handleDownloadClick = async () => {
    console.log("errors", errors);
    if (validate()) {
      // Save the current profile state to local storage
      localStorage.setItem(
        "bannerProfile",
        JSON.stringify({
          includeName: profile.includeName,
          includeEmail: profile.includeEmail,
          includePhone: profile.includePhone,
          includeWebsite: profile.includeWebsite,

          jobs: profile.jobs,
          quotes: profile.quotes,
        })
      );

      const sliderControls = document.querySelector(".slider-controls");
      try {
        // Temporarily hide the .slider-controls div
        if (sliderControls) {
          sliderControls.style.display = "none";
        }

        const canvas = await html2canvas(componentRef.current, {
          allowTaint: true,
          useCORS: true,
          scale: 2,
        });
        const imgData = canvas.toDataURL("image/png");
        const blob = dataURItoBlob(imgData);

        const file_name = profile.name
          ? SYSTEM_PREFIX + profile.name + ".png"
          : SYSTEM_PREFIX + "_linked_banner.png";
        saveAs(blob, file_name);
      } catch (error) {
        console.error("Error capturing element:", error);
      } finally {
        // Show the .slider-controls div again
        if (sliderControls) {
          sliderControls.style.display = "";
        }
      }
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: "image/png" });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-xl"
      backdrop="static"
      fade={false}
    >
      <Form className="theme-form">
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          AI LinkedIn Banner
        </ModalHeader>
        <ModalBody className="p-4">
          {getLinkedinBannerReducerData.isLoading ? (
            <Spinner />
          ) : (
            <div className="linked-banner-main">
              <div className="banner-div">
                <div
                  className={`banner-${allBanners[currentIndex].banner_id}`}
                  ref={componentRef}
                  style={{
                    backgroundImage: `url(${allBanners[currentIndex].banner_template_image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "273px",
                  }}
                >
                  <div className="contact-container">
                    {profile.includePhone &&
                      allBanners[currentIndex].phone_allowed != 0 && (
                        <div className="contact-item phone">
                          <FaPhoneAlt /> {profile.phone}
                        </div>
                      )}
                    {profile.includeEmail &&
                      allBanners[currentIndex].email_allowed != 0 && (
                        <div className="contact-item email">
                          <MdEmail /> {profile.email}
                        </div>
                      )}
                    {profile.includeWebsite &&
                      allBanners[currentIndex].website_allowed != 0 && (
                        <div className="contact-item email">
                          <FaGlobe />{" "}
                          {profile.company_website
                            ? formatWebsite(profile.company_website)
                            : ""}
                        </div>
                      )}
                  </div>
                  <div className="quotes-positions">
                    {profile.quotes &&
                      allBanners[currentIndex].default_quotes &&
                      profile.quotes
                        .split(",")
                        .map((quotes, index) => (
                          <li key={index}>{quotes.trim()}</li>
                        ))}
                  </div>
                  <div className="job-positions">
                    <ul>
                      {profile.jobs &&
                        allBanners[currentIndex].job_allowed != 0 &&
                        profile.jobs
                          .split(",")
                          .map((job, index) => (
                            <li key={index}>{job.trim()}</li>
                          ))}
                    </ul>
                  </div>

                  <div className="send-button">send your cv</div>

                  <div className={`slider-controls`}>
                    <button onClick={handlePrevious} className="prev-btn">
                      <IoIosArrowBack />
                    </button>
                    <button onClick={handleNext} className="next-btn">
                      <IoIosArrowForward />
                    </button>
                  </div>
                </div>
              </div>
              <div className="profile-img bg-white rounded-circle d-flex justify-content-center align-items-center">
                <img
                  src={profile.photo}
                  alt="User profile picture"
                  className="rounded-circle img-fluid"
                />
              </div>
            </div>
          )}

          <Row className=" mt-4">
            <Col md={8}>
              <h4 className="name mt-3 text-uppercase">
                {profile.name && capitalize(profile.name)}
              </h4>
              <div className="about">
                <p className="">
                  {(profile.role || profile.company_name) && (
                    <>
                      {profile.role ? capitalize(profile.role) : ""} at{" "}
                      {profile.company_name || ""}
                    </>
                  )}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="company mt-3">
                {profile.company_name && (
                  <div className="d-flex align-items-center ">
                    {profile.company_logo ? (
                      <img
                        src={profile.company_logo}
                        alt="Company Logo"
                        className="img-fluid me-2"
                        style={{ width: "25px", objectFit: "contain" }}
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center bg-secondary text-white me-2"
                        style={{
                          width: "25px",
                          height: "25px",
                          fontSize: "16px",
                        }}
                      >
                        <TbBuilding />
                      </div>
                    )}
                    <span className="me-2">{profile.company_name}</span>
                  </div>
                )}

                {!profile.company_name && (
                  <div
                    className="d-flex justify-content-center align-items-center bg-white text-black me-2"
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "14px",
                      filter: "blur(3px)",
                    }}
                  >
                    Company Name
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <FormGroup>
                {allBanners[currentIndex].phone_allowed != 0 && (
                  <>
                    <Input
                      className="checkbox_animated ms-2"
                      type="checkbox"
                      id="includePhone"
                      name="includePhone"
                      checked={profile.includePhone}
                      onChange={handleCheckboxChange}
                    />
                    <Label for="includePhone">Add Phone</Label>
                  </>
                )}
                {allBanners[currentIndex].email_allowed != 0 && (
                  <>
                    <Input
                      className="checkbox_animated ms-2"
                      type="checkbox"
                      id="includeEmail"
                      name="includeEmail"
                      checked={profile.includeEmail}
                      onChange={handleCheckboxChange}
                    />
                    <Label for="includeEmail">Add Email</Label>
                  </>
                )}
                {allBanners[currentIndex].website_allowed != 0 && (
                  <>
                    <Input
                      className="checkbox_animated ms-2"
                      type="checkbox"
                      id="includeWebsite"
                      name="includeWebsite"
                      checked={profile.includeWebsite}
                      onChange={handleCheckboxChange}
                    />
                    <Label for="includeWebsite">Add Website</Label>
                  </>
                )}
              </FormGroup>
            </Col>
            {allBanners[currentIndex].default_quotes && (
              <Col md={6}>
                <FormGroup>
                  <Label for="quotes">Quotes</Label>
                  <Input
                    className="form-control form-control-sm"
                    type="text"
                    id="quotes"
                    name="quotes"
                    value={profile.quotes}
                    onChange={handleChange}
                    placeholder="Enter quotes"
                  />
                </FormGroup>
              </Col>
            )}
            {allBanners[currentIndex].job_allowed != 0 && (
              <Col md={6}>
                <FormGroup>
                  <Label for="jobs">Jobs</Label>
                  <Input
                    className="form-control form-control-sm"
                    type="text"
                    id="jobs"
                    name="jobs"
                    value={profile.jobs}
                    onChange={handleChange}
                    placeholder="Enter job title"
                  />
                  <small className="form-text">
                    Enter job titles separated by commas. Example: (Php, Python)
                  </small>

                  {errors.jobs && (
                    <div className="text-danger mt-2">{errors.jobs}</div>
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={handleDownloadClick}>
            Download
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default BannerModal;
