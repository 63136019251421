import React, { useContext } from "react";
import { Grid } from "react-feather";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";

import NfIcon from "../../../assets/images/logo/logo.png";
import CustomizerContext from "../../../_helper/Customizer";

const SidebarLogo = () => {
  const { toggleSidebar, toggleIcon } = useContext(CustomizerContext);

  const openCloseSidebar = () => {
    console.log("clicked");
    console.log(toggleIcon);
    console.log(toggleSidebar);
    toggleSidebar(!toggleIcon);
  };

  return (
    <div className="logo-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/recruiter/dashboard`}>
        <img
          style={{ width: "170px" }}
          className="img-fluid d-inline"
          src={NfIcon}
          alt=""
        />
      </Link>

      <div
        className="back-btn"
        style={{ cursor: "pointer" }}
        onClick={() => openCloseSidebar()}
      >
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="toggle-sidebar" onClick={openCloseSidebar}>
        <Grid className="status_toggle middle sidebar-toggle" />
      </div>
    </div>
  );
};

export default SidebarLogo;
