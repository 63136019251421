// PreloaderContext.js
import React, { createContext, useState } from 'react';

export const PreloaderContext = createContext();

export const PreloaderProvider = ({ children }) => {
  const [showPreloader, setShowPreloader] = useState(true);

  return (
    <PreloaderContext.Provider value={{ showPreloader, setShowPreloader }}>
      {children}
    </PreloaderContext.Provider>
  );
};


export default PreloaderProvider;
