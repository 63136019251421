import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogIn, User } from "react-feather";
import { AVATAR } from "../../../../Constant";
import { AuthContext } from "../../../../Auth/AuthContext";
import { fetchJobseekerDataRequest } from "../../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { COINS } from "../../../../Constant";
import { styleTotalCoins } from "../../../../Components/Common/CommonFunction";
import dollar from "../../../../../src/assets/images/Icons/dollar.png";
const UserHeader = () => {
  const dispatch = useDispatch();
  const { logout, totalPoints } = useContext(AuthContext);
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Your Name");

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  useEffect(() => {
    // Fetch company profile on component mount
    dispatch(fetchJobseekerDataRequest());
  }, []);

  const fetchJobseekerDataReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer
  );
  let profileData = [];

  useEffect(() => {
    if (
      !fetchJobseekerDataReducerData.isLoading &&
      fetchJobseekerDataReducerData.data.data
    ) {
      profileData = fetchJobseekerDataReducerData.data.data;
      setProfile(profileData.jobseeker_photo || AVATAR);
      setName(profileData.jobseeker_full_name || "Your Name");
    }
  }, [fetchJobseekerDataReducerData]);

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media" style={{ alignItems: "center" }}>
        <img
          width="35px"
          height="35px"
          className="b-r-10 m-0"
          src={profile}
          alt=""
        />
        <div className="media-body" style={{ textTransform: "capitalize" }}>
          <span>{name}</span>
          <p
            className="mb-2 font-roboto text-center text-capitalize"
            style={{ fontSize: 15 }}
          >
            {styleTotalCoins(totalPoints)}
          </p>

          {/* <p className="mb-0 font-roboto">
            Admin <i className="middle fa fa-angle-down"></i>
          </p> */}
        </div>
      </div>
      <ul className="simple-list profile-dropdown onhover-show-div">
        <li
          className="d-flex"
          onClick={() =>
            UserMenuRedirect(`${process.env.PUBLIC_URL}/jobseeker/referral`)
          }
        >
          <img
            width="22px"
            height="22px"
            className="b-r-10 m-0"
            src={dollar}
            alt=""
          />

          <span className="ps-2 fs-6 fw-bold text-primary text-capitalize font-roboto">
            {totalPoints}
          </span>
        </li>
        <li
          className="d-flex"
          onClick={() =>
            UserMenuRedirect(
              `${process.env.PUBLIC_URL}/jobseeker/change_password/`
            )
          }
        >
          <User style={{ width: 22 }} />
          <span>Change Password</span>
        </li>

        <li className="d-flex" onClick={logout}>
          <LogIn style={{ width: 22 }} />
          <span>Logout</span>
        </li>
      </ul>
    </li>
  );
};

export default UserHeader;
