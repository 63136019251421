// actions.js
import * as types from "../actionTypes";

export const fetchCountryRequest = (keyword) => ({
  type: types.FETCH_COUNTRY_REQUEST,
  payload: { keyword },
});

export const fetchCountrySuccess = (data) => ({
  type: types.FETCH_COUNTRY_SUCCESS,
  payload: { data },
});

export const fetchCountryFailure = (error) => ({
  type: types.FETCH_COUNTRY_FAILURE,
  payload: { error },
});

