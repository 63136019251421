
import SidebarMenuItems from './SidebarMenuItems';

const SidebarMenu = () => {
  return (
    <nav className="sidebar-main" id="sidebar-main">
     
      <div
        id="sidebar-menu"
        style={{ margin: '0px' }}
      >
        <ul className="sidebar-links custom-scrollbar">
          <li className="back-btn">
            <div className="mobile-back text-end">
              <span>{'Back'}</span>
              <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
            </div>
          </li>
          <SidebarMenuItems />
        </ul>
      </div>
     
    </nav>
  );

};

export default SidebarMenu;