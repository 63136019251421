// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchReferralSuccess, fetchReferralFailure } from "./actions";
import { FETCH_REFERRAL_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* fetchReferralDataSaga(action) {
  console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/referral", action.payload)
    );
    console.log(action.payload);

    const totalRefferPoint = response.data.total_reffer_point;

    yield put(fetchReferralSuccess(response.data)); // Dispatch success action with response data
    console.log(totalRefferPoint);
  } catch (error) {
    yield put(fetchReferralFailure(error.message)); // Dispatch failure action with error message
  }
}

function* referralSaga() {
  yield takeLatest(FETCH_REFERRAL_REQUEST, fetchReferralDataSaga); // Listen for FETCH_REFERRAL_DATA action
}

export default referralSaga;
