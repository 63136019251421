// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";

import { FETCH_REWARDS_POINTS_REQUEST } from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import {
  fetchRewardsPointsSuccess,
  fetchRewardsPointsFailure,
} from "./actions";

function* fetchRewardsPoints(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(
      () => AxiosInstance().post(`/fetch_rewards_points`, action.payload)

      //recruiter/manage_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}
    );
    if (response.data.status) {
      yield put(fetchRewardsPointsSuccess(response.data));
    } else {
      yield put(fetchRewardsPointsFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(
      fetchRewardsPointsFailure({ status: false, message: error.message })
    );
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* fetchRewardsPointsSaga() {
  yield takeLatest(FETCH_REWARDS_POINTS_REQUEST, fetchRewardsPoints);
}

export default fetchRewardsPointsSaga;
