// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import { fetchCountrySuccess,fetchCountryFailure } from "./actions";

function* fetchCountrySaga(action) {
  try {
    
    // Call your API function here with action.payload.keyword
    const response = yield call(() =>
      AxiosInstance().post("/fetch_country", action.payload)
    );
    if(response.data.status){

      yield put(fetchCountrySuccess(response.data));
    }else{
      yield put(fetchCountryFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchCountryFailure({status:false,message:error.message}));
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* countrySaga() {
  yield takeLatest(types.FETCH_COUNTRY_REQUEST, fetchCountrySaga);
}

export default countrySaga;
