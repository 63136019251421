import React, { Fragment, useContext, useEffect, useRef } from "react";
import ChatAppContext from "../../../Chat";
import avatar from "../../../../src/assets/images/avatar.png";
import { format, isToday, isYesterday } from "date-fns";
import { formatTimeAMPM, convertHtmlToJsx } from "../../Common/CommonFunction";
import Spinner from "../../Common/Spinner";

const ChatMessage = () => {
  const chatContainerRef = useRef();
  const {
    messages = {},
    chatLoading,
    selectedUser,
    currentUser,
  } = useContext(ChatAppContext) || {};

  const renderDateSeparator = (date) => {
    if (isToday(new Date(date))) {
      return "Today";
    } else if (isYesterday(new Date(date))) {
      return "Yesterday";
    } else {
      return format(new Date(date), "yyyy-MM-dd");
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Fragment>
      {chatLoading ? (
        <Spinner />
      ) : (
        <div
          className="chat-history chat-msg-box custom-scrollbar"
          ref={chatContainerRef}
        >
          <ul className="simple-list">
            <li className="clearfix border-0 mb-4">
              <div
                style={{ width: "90%", margin: "auto" }}
                className="message other-message"
              >
                <h6>Chat Community Guidelines</h6>
                <p>
                  Welcome to our chat community! To maintain a positive
                  environment for all members, please adhere to the following
                  guidelines:
                </p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>Be respectful and courteous in all interactions.</li>
                  <li>
                    Keep discussions relevant to the chat's purpose or topic.
                  </li>
                  <li>
                    Avoid spamming, chain messages, or irrelevant forwards.
                  </li>
                  <li>
                    Respect everyone's privacy and refrain from sharing personal
                    information without consent.
                  </li>
                  <li>
                    Use appropriate language and avoid offensive or
                    inappropriate content.
                  </li>
                  <li>
                    Report any violations of these guidelines to the chat
                    moderators.
                  </li>
                  <li>
                    Remember that all chat members are subject to the chat
                    platform's Terms of Service.
                  </li>
                </ul>
                <p>
                  Thank you for contributing to a positive and enjoyable chat
                  experience!
                </p>
              </div>
            </li>
            {Object.keys(messages).length > 0 ? (
              Object.keys(messages).map((date) => (
                <Fragment key={date}>
                  <li className="date-separator mb-4 text-center">
                    <span className="badge bg-primary">
                      {renderDateSeparator(date)}
                    </span>
                  </li>
                  {messages[date].map((item, index) => (
                    <li className="clearfix border-0" key={item.message_id}>
                      <div
                        className={`message ${
                          item.sender_type !== "JOBSEEKER"
                            ? "my-message"
                            : "other-message float-end"
                        }`}
                      >
                        <img
                          className={`rounded-circle ${
                            item.sender_type !== "JOBSEEKER"
                              ? "float-start"
                              : "float-end"
                          } chat-user-img img-30`}
                          //  src={selectedUser?.profile_image || avatar}
                          src={
                            item.sender_type !== "JOBSEEKER"
                              ? selectedUser?.profile_image || avatar
                              : currentUser?.jobseeker_photo || avatar
                          }
                          alt=""
                        />
                        <div className="message-data text-end">
                          <span className="message-data-time">
                            {formatTimeAMPM(item.created_at)}
                          </span>
                        </div>
                        {convertHtmlToJsx(item.message_text)}
                      </div>
                    </li>
                  ))}
                </Fragment>
              ))
            ) : (
              <div>
                <img
                  className="img-fluid"
                  src="/path/to/your/image.png"
                  alt="start conversation"
                />
              </div>
            )}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default ChatMessage;
