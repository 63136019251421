import {
  SUBMIT_REPORT_REQUEST,
  SUBMIT_REPORT_SUCCESS,
  SUBMIT_REPORT_FAILURE,
  FETCH_REPORT_JOB_REQUEST,
  RESET_DATA,
} from "../../actionTypes";

// Action Creators
export const submitReportRequest = (reportJobData) => ({
  type: SUBMIT_REPORT_REQUEST,
  payload: reportJobData,
});

export const submitReportSuccess = (response) => ({
  type: SUBMIT_REPORT_SUCCESS,
  payload: response,
});

export const submitReportFailure = (error) => ({
  type: SUBMIT_REPORT_FAILURE,
  payload: error,
});

export const fetchReportJobRequest = (jobId) => ({
  type: FETCH_REPORT_JOB_REQUEST,
  payload: jobId,
});

export const resetReportJobData = () => ({
  type: RESET_DATA,
});
