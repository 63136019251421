// reducers.js
import {
  FETCH_JOB_DETAILS_REQUEST,
  FETCH_JOB_DETAILS_SUCCESS,
  FETCH_JOB_DETAILS_FAILURE,
  APPLY_JOB_REQUEST,
  APPLY_JOB_SUCCESS,
  APPLY_JOB_FAILURE,
  JOB_SHARING_REQUEST,
  JOB_SHARING_SUCCESS,
  JOB_SHARING_FAILURE,
  RESET_MESSAGE,
} from "../actionTypes";

const initialState = {
  data: null, // Initialize data to null
  message: "",
  isLoading: false,
  status: false,
};

const jobDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        data: action.payload.data,
      };
    case FETCH_JOB_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case APPLY_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case APPLY_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case APPLY_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    case RESET_MESSAGE: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };

    default:
      return state;
  }
};

export default jobDetailsReducer;
