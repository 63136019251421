import * as types from '../../actionTypes';

export const addDesireCareerRequest = (data) => ({
  type: types.UPDATE_DESIRED_CAREER_PROFILE_REQUEST,
  payload:data
});

export const addDesireCareerSuccess = (data) => ({
  type: types.UPDATE_DESIRED_CAREER_PROFILE_SUCCESS,
  payload: data,
});

export const addDesireCareerFailure = (error) => ({
  type: types.UPDATE_DESIRED_CAREER_PROFILE_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA
});








