import { call, put, takeEvery } from "redux-saga/effects";
import AxiosInstance from "../../../../services/AxiosInstance";
import {
  FETCH_CHAT_MESSAGES_REQUEST,
  CHECK_CHAT_EXIST_REQUEST,
  SEND_CHAT_MESSAGE_REQUEST,
  FETCH_CHAT_MEMBERS_REQUEST,
} from "../../../actionTypes";

import {
  fetchChatMessagesSuccess,
  fetchChatMessagesFailure,
  checkChatExistSuccess,
  checkChatExistFailure,
  sendChatMessageSuccess,
  sendChatMessageFailure,
  fetchChatMessagesRequest,
  fetchChatMembersSuccess,
  fetchChatMembersFailure,
  sendChatInviteSuccess,
} from "./actions";

function* checkChatExist(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/create_chat", action.payload)
    );

    if (response.data.status) {
      yield put(checkChatExistSuccess(response.data));
    } else {
      yield put(checkChatExistFailure(response.data));
    }
  } catch (error) {
    yield put(checkChatExistFailure({ status: false, message: error.message }));
  }
}

function* fetchChatMessages(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/get_messages", action.payload)
    );
    if (response.data.status) {
      yield put(fetchChatMessagesSuccess(response.data));
    } else {
      yield put(fetchChatMessagesFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchChatMessagesFailure({ status: false, message: error.message })
    );
  }
}

function* sendChatMessage(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/send_message", action.payload)
    );

    if (response.data.status) {
      if (response.data.chat_id) {
        yield put(sendChatInviteSuccess(response.data));
      } else {
        yield put(sendChatMessageSuccess(response.data));
      }
    } else {
      yield put(sendChatMessageFailure(response.data));
    }
  } catch (error) {
    yield put(
      sendChatMessageFailure({ status: false, message: error.message })
    );
  }
}

function* fetchChatMembers() {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/get_chat_members")
    );

    if (response.data.status) {
      yield put(fetchChatMembersSuccess(response.data));
    } else {
      yield put(fetchChatMembersFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchChatMembersFailure({ status: false, message: error.message })
    );
  }
}

function* chatSaga() {
  yield takeEvery(CHECK_CHAT_EXIST_REQUEST, checkChatExist);
  yield takeEvery(FETCH_CHAT_MESSAGES_REQUEST, fetchChatMessages);
  yield takeEvery(SEND_CHAT_MESSAGE_REQUEST, sendChatMessage);
  yield takeEvery(FETCH_CHAT_MEMBERS_REQUEST, fetchChatMembers);
}

export default chatSaga;
