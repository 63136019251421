import {
  CANDIDATE_BULK_EXCEL_UPLOAD_REQUEST,
  CANDIDATE_BULK_EXCEL_UPLOAD_SUCCESS,
  CANDIDATE_BULK_EXCEL_UPLOAD_FAILURE,
  CANDIDATE_BULK_EXCEL_UPLOAD_RESET,
} from "../../actionTypes";

export const candidateBulkExcelUploadRequest = (type) => ({
  type: CANDIDATE_BULK_EXCEL_UPLOAD_REQUEST,
  payload: type,
});

export const candidateBulkExcelUploadSuccess = (data) => ({
  type: CANDIDATE_BULK_EXCEL_UPLOAD_SUCCESS,
  payload: data,
});

export const candidateBulkExcelUploadFailure = (error) => ({
  type: CANDIDATE_BULK_EXCEL_UPLOAD_FAILURE,
  payload: error,
});

export const candidateBulkExcelUploadReset = () => ({
  type: CANDIDATE_BULK_EXCEL_UPLOAD_RESET,
});
