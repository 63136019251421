import * as types from "../../actionTypes";

const initialState = {
  data: {},
  isLoading: false,
  message: "",
  status: "",
};

const avatarReducer = (state = initialState, action) => {
  console.log("prd", action);
  switch (action.type) {
    case types.UPDATE_JOBSEEKER_AVATAR:
      return {
        ...state,
        isLoading: true,
      };
    case types.UPDATE_JOBSEEKER_AVATAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case types.UPDATE_JOBSEEKER_AVATAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case types.RESET_JOOBSEEKER_AVATAR_DATA:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: false,
        message: "",
      };
    default:
      return state;
  }
};

export default avatarReducer;
