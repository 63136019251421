import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppliedJobsRequest } from "../../../redux/jobseeker/applied_jobs/actions";
import { showToast } from "../../Common/Toast";
import {
  capitalize,
  formatDate,
  formatExperienceOutput,
  generateSlug,
  unslugAndCapitalize,
} from "../../Common/CommonFunction";
import {
  Badge,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  Input,
} from "reactstrap";
import { Edit2, Trash2, Eye, Copy } from "react-feather";
import { Link } from "react-router-dom";
import NoDataFound from "../../Common/NoDataFound";
import Spinner from "../../Common/Spinner";
import { useParams } from "react-router-dom";
import { customStyles, getCandidateStatusColor } from "../../../Constant";
import {
  DefinedRange,
  DateRangePicker,
  defaultStaticRanges,
} from "react-date-range";
import { addDays, startOfYear, endOfToday } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";
const CustomHeader = ({ cell }) => (
  <th style={{ fontWeight: "bold" }}>{cell.label}</th>
);

const DataTableComponent = () => {
  const dispatch = useDispatch();
  const { jobstatus } = useParams();
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [formData, setFormData] = useState({
    dateRange: {
      key: "selection",
      startDate: startOfYear(new Date()), // Start of current year
      endDate: endOfToday(), // Today
      type: "ALL",
    },
  });
  const locale = {
    localize: {
      month: (monthIndex) => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return months[monthIndex];
      },
      day: (dayIndex) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[dayIndex];
      },
    },
    formatLong: {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  };

  const customStaticRanges = [
    {
      label: "All",
      range: () => ({
        startDate: startOfYear(new Date()), // Start of current year
        endDate: endOfToday(), // Today
        type: "ALL",
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },

    ...defaultStaticRanges.map((range) => ({
      ...range,
      range: () => ({
        ...range.range(),
        type: range.label.toUpperCase().replace(/ /g, "_"), // Generate a type from the label
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    })),
  ];

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    let selectedType = "CUSTOM"; // Default to CUSTOM

    // Check if the selected range matches any predefined ranges
    for (let range of customStaticRanges) {
      const definedRange = range.range();
      if (
        selection.startDate.getTime() === definedRange.startDate.getTime() &&
        selection.endDate.getTime() === definedRange.endDate.getTime() &&
        selection.type === definedRange.type
      ) {
        selectedType = definedRange.type;
        break;
      }
    }

    setFormData({
      ...formData,
      dateRange: {
        ...selection,
        type: selectedType, // Set the type based on the selected range
      },
    });
    setDatePickerOpen(false); // Close the date picker after selecting a valid date range
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      status: jobstatus ? jobstatus.toUpperCase() : "ALL",
      type: formData.dateRange.type,

      startDate: formData.dateRange.startDate
        ? formData.dateRange.startDate.toISOString()
        : null, // Convert Date objects to strings
      endDate: formData.dateRange.endDate
        ? formData.dateRange.endDate.toISOString()
        : null,
      keyword: searchQuery,
    };
    dispatch(fetchAppliedJobsRequest(currentPage, perPage, data));
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = async (column, direction) => {
    console.log("column", column);

    try {
      const page = currentPage;
      const per_page = perPage;
      const short_by = column.short_column;
      const column_direction = direction;
      const data = {
        status: jobstatus ? jobstatus.toUpperCase() : "ALL",
        type: formData.dateRange.type,

        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : null, // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : null,
        keyword: searchQuery,
      };
      dispatch(
        fetchAppliedJobsRequest(
          page,
          per_page,
          data,
          short_by,
          column_direction
        )
      );
    } catch (error) {
      console.error("Error fetching sorted data:", error);
    }
  };

  const tableColumns = [
    {
      name: "#",
      short_column: "job_id",
      cell: (row, index) => index + 1,
      width: "5%",
      sortable: true,
      center: false,
    },
    {
      name: "Job Title",
      short_column: "job_name",
      cell: (row) =>
        row.job_name ? (
          <Link
            to={`${process.env.PUBLIC_URL}/jobseeker/job_details/${generateSlug(
              row.job_name,
              row.job_location,
              formatExperienceOutput(
                row.work_experience_min,
                row.work_experience_max
              ).replace("-", "-to-"),
              row.job_id
            )}`}
            title={"View Job"}
          >
            {capitalize(row.job_name)}
          </Link>
        ) : (
          "-"
        ),
      sortable: true,
      center: false,
    },
    {
      short_column: "company_name",
      name: "Company Name",
      cell: (row) => (row.company_name ? capitalize(row.company_name) : "_"),
      sortable: true,
      center: false,
    },

    {
      name: "Experience",

      cell: (row) => {
        const experienceOutput = formatExperienceOutput(
          row.work_experience_min,
          row.work_experience_max
        );
        return experienceOutput !== "N/A" ? experienceOutput : "-";
      },

      sortable: false,
      center: true,
    },
    {
      name: "Location",
      short_column: "job_location",
      cell: (row) => (row.job_location ? capitalize(row.job_location) : "_"),
      sortable: true,
      center: false,
    },
    {
      name: "Applied On",
      short_column: "created_at",
      cell: (row) => (row.created_at ? formatDate(row.created_at) : "_"),
      sortable: true,
      center: false,
    },
    {
      name: "Status",

      sortable: false,
      center: false,
      cell: (row) => (
        <span
          className={`badge ${getCandidateStatusColor(row.candidate_status)}`}
        >
          {unslugAndCapitalize(row.candidate_status)}
        </span>
      ),
    },
    {
      name: "Actions",

      cell: (row) => (
        <div>
          <span>
            <Link
              to={`${
                process.env.PUBLIC_URL
              }/jobseeker/job_details/${generateSlug(
                row.job_name,
                row.job_location,
                formatExperienceOutput(
                  row.work_experience_min,
                  row.work_experience_max
                ).replace("-", "-to-"),
                row.job_id
              )}`}
              title="View Job"
            >
              <Eye
                size={18}
                className="eye-icon"
                style={{ marginRight: "10px" }}
              />
            </Link>
            {/* <Edit2 size={18} color="blue" style={{ marginRight: "10px" }} />
            <Copy size={18} color="orange" /> */}
          </span>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    if (jobstatus) {
      const data = {
        status: jobstatus ? jobstatus.toUpperCase() : "ALL",
        type: formData.dateRange.type,

        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : null, // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : null,
        keyword: searchQuery,
      };
      dispatch(fetchAppliedJobsRequest(currentPage, perPage, data));
    } else {
      const data = {
        status: "ALL",
        type: formData.dateRange.type,

        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : null, // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : null,
        keyword: searchQuery,
      };
      dispatch(fetchAppliedJobsRequest(currentPage, perPage, data));
    }
  }, [jobstatus, currentPage, perPage, formData]);

  const appliedJobsReducerData = useSelector(
    (state) => state.appliedJobsReducer
  );
  const appliedJobsData = appliedJobsReducerData.data.data;

  if (appliedJobsReducerData.error) {
    showToast("error", appliedJobsReducerData.error);
  }

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader className="py-3 ">
              {/* Search Bar */}
              <div className="d-flex flex-wrap justify-content-between align-items-end">
                <Form
                  className="theme-form me-2 flex-grow-1"
                  onSubmit={handleSubmit}
                >
                  <div
                    className="input-group flex-nowrap "
                    style={{ height: 35, maxWidth: "350px" }}
                  >
                    <Input
                      className="form-control"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="search"
                      placeholder="Search.."
                    />
                    <Button
                      color="primary"
                      type="submit"
                      className="btn-primary input-group-text pe-3 ps-3"
                    >
                      Search
                    </Button>
                  </div>
                </Form>

                {/* Date Picker Button */}
                <div className="d-flex align-items-center ms-auto mt-3 mt-md-0">
                  <Button
                    color="primary"
                    id="shownCalendar"
                    onClick={() => setDatePickerOpen(!datePickerOpen)}
                    className="btn btn-primary d-flex align-items-center ps-3 pe-3"
                  >
                    <FaCalendarAlt className="" />
                    <span className="ms-2">
                      {formData &&
                        (formData.dateRange.type === "CUSTOM"
                          ? `${formatDate(
                              formData.dateRange.startDate,
                              "dd-MM-yyyy"
                            )} - ${formatDate(
                              formData.dateRange.endDate,
                              "dd-MM-yyyy"
                            )}`
                          : unslugAndCapitalize(formData.dateRange.type))}
                    </span>
                  </Button>
                  {datePickerOpen && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50px", // Adjust as needed based on your layout
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      {/* <DefinedRange
                          onChange={handleDateRangeChange}
                          ranges={[formData.dateRange]}
                          open={datePickerOpen}
                        /> */}
                      <DateRangePicker
                        editableDateInputs
                        onChange={handleDateRangeChange}
                        ranges={[formData.dateRange]}
                        locale={locale}
                        // months={2}
                        direction="horizontal"
                        open={datePickerOpen}
                        onToggle={(open) => setDatePickerOpen(open)}
                        staticRanges={customStaticRanges}
                      />
                    </div>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardBody className="px-0 py-3">
              <DataTable
                customStyles={customStyles}
                data={appliedJobsData}
                columns={tableColumns}
                keyField="id"
                striped={true}
                center={true}
                pagination
                paginationServer
                paginationTotalRows={appliedJobsReducerData.data.total}
                paginationPerPage={perPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                headerComponent={CustomHeader}
                progressPending={appliedJobsReducerData.isLoading}
                progressComponent={<Spinner />}
                noDataComponent={
                  <NoDataFound message={"No applied jobs found"} />
                }
                onSort={(column, direction) => handleSort(column, direction)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DataTableComponent;
