import * as types from "../../actionTypes";

export const addSocialMediaRequest = (data) => ({
  type: types.ADD_SOCIAL_MEDIA_REQUEST,
  payload: data,
});

export const addSocialMediaSuccess = (data) => ({
  type: types.ADD_SOCIAL_MEDIA_SUCCESS,
  payload: data,
});

export const addSocialMediaFailure = (error) => ({
  type: types.ADD_SOCIAL_MEDIA_FAILURE,
  payload: error,
});

export const deleteSocialMediaRequest = (data) => ({
  type: types.DELETE_SOCIAL_MEDIA_REQUEST,
  payload: data,
});

export const deleteSocialMediaSuccess = (data) => ({
  type: types.DELETE_SOCIAL_MEDIA_SUCCESS,
  payload: data,
});

export const deleteSocialMediaFailure = (error) => ({
  type: types.DELETE_SOCIAL_MEDIA_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA,
});
