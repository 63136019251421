import React, { Fragment, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Taptop from "../TapTop";
import Header from "./Header";
// import Sidebar from "./Sidebar";
import Footer from "../Footer";
import Loader from "../Loader";
import CustomizerContext from "../../_helper/Customizer";
const PublicLayout = () => {
  const { toggleIcon } = useContext(CustomizerContext);
  const location = useLocation();
  const pathsWithoutHeader = ["/public/career"]; // Add more paths as needed

  const showHeader = !pathsWithoutHeader.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div
        className={`page-wrapper compact-wrapper`}
        sidebar-layout="stroke-svg"
        id="pageWrapper"
      >
        {showHeader && <Header />}
        <div className="page-body-wrapper">
          {/* <Sidebar /> */}
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={100}
              classNames="fadeIn"
              unmountOnExit
            >
              <div className="page-body" style={{ marginLeft: "0px" }}>
                <div>
                  <div>
                    <Outlet />
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default PublicLayout;
