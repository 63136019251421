// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { bulkUploadSuccess, bulkUploadFailure } from "./actions";
import { CANDIDATE_BULK_UPLOAD_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";
function* handleBulkUpload(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/add_bulk_resume_files", action.payload)
    );
    console.log(response);
    if (response.data.status) {
      yield put(bulkUploadSuccess(response.data));
    } else {
      yield put(bulkUploadFailure(response.data));
    }
  } catch (error) {
    yield put(bulkUploadFailure({ status: false, message: error.message }));
  }
}

function* candidateBulkUploadSaga() {
  yield takeLatest(CANDIDATE_BULK_UPLOAD_REQUEST, handleBulkUpload);
}

export default candidateBulkUploadSaga;
