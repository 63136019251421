import {
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  RESET_FORGET_PASSWORD_FAILURE,
} from "../../actionTypes";

export const forgetPasswordRequest = (jobData) => ({
  type: FORGET_PASSWORD_REQUEST,
  payload: jobData,
});

export const forgetPasswordSuccess = (response) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: response,
});

export const forgetPasswordFailure = (error) => ({
  type: FORGET_PASSWORD_FAILURE,
  payload: error,
});

export const resetForgetPassword = () => ({
  type: RESET_FORGET_PASSWORD_FAILURE,
});
