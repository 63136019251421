// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";

import { GET_TOTAL_POINTS_REQUEST } from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";
import { getTotalPointsSuccess, getTotalPointsFailure } from "./actions";

function* getTotalPoints(action) {
  try {
    // Call your API function here with action.payload.keyword
    const response = yield call(
      () => AxiosInstance().post(`/fetch_total_point`, action.payload)

      //recruiter/manage_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}
    );
    if (response.data.status) {
      yield put(getTotalPointsSuccess(response.data));
    } else {
      yield put(getTotalPointsFailure(response.data));
    }
  } catch (error) {
    // Dispatch failure action with the error
    yield put(getTotalPointsFailure({ status: false, message: error.message }));
  }
}

// Watch for the latest FETCH_LOCATIONS_REQUEST action and call the saga
function* getTotalPointsSaga() {
  yield takeLatest(GET_TOTAL_POINTS_REQUEST, getTotalPoints);
}

export default getTotalPointsSaga;
