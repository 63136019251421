import React, { Fragment, useState, useContext } from "react";
import {
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Collapse,
  Input,
} from "reactstrap";
import { YourCandidateSearchContext } from "../../../../YourCandidateSearch/YourCandidateSearchContext";
import { genderOptions } from "../../../../Constant";
const Gender = () => {
  const [isFilter, setIsFilter] = useState(true);

  const { filters, updateFilters } = useContext(YourCandidateSearchContext);

  const handleRadioChange = (option) => {
    // Update the corresponding filter in the context

    updateFilters({ gender: option.value });
  };

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Gender</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="filter-cards-view animate-chk">
              <div className="mt-3">
                {genderOptions.map((option, index) => (
                  <label
                    key={index}
                    className="d-flex align-items-center"
                    htmlFor={`gender-rad-${index}`}
                  >
                    <Input
                      className="radio_animated mr-2"
                      id={`gender-rad-${index}`}
                      type="radio"
                      checked={filters.gender === option.value}
                      onChange={() => handleRadioChange(option)}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Gender;
