import { takeLatest, put, call } from "redux-saga/effects";
import {
  FETCH_JOB_PIPELINE_REQUEST,
  DOWNLOAD_PIPELINE_CSV_REQUEST,
} from "../../actionTypes";
import {
  fetchJobPipelineSuccess,
  fetchJobPipelineFailure,
  downloadPipelineCsvSuccess,
  downloadPipelineCsvFailure,
} from "./actions";

import AxiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

function* fetchJobPipeline(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/recruiter_job_pipeline`,
      action.payload
    );
    console.log("response", response);
    if (response.data.status) {
      yield put(fetchJobPipelineSuccess(response.data));
    } else {
      yield put(fetchJobPipelineFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchJobPipelineFailure({ status: false, message: error.message })
    );
  }
}

function* downloadPipelineCsv(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/recruiter_job_pipeline_csv_download`,
      action.payload
    );

    console.log(response);
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const downloadLink = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);

    downloadLink.download = "exported_data.xls";
    downloadLink.href = blobUrl;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error:", error);
    // Handle errors here
  }
}

export default function* jobPipelineSaga() {
  yield takeLatest(FETCH_JOB_PIPELINE_REQUEST, fetchJobPipeline);
  yield takeLatest(DOWNLOAD_PIPELINE_CSV_REQUEST, downloadPipelineCsv);
}
