// reducers.js
import {
  UPDATE_COMPANY_PROFILE_REQUEST,
  UPDATE_COMPANY_PROFILE_SUCCESS,
  UPDATE_COMPANY_PROFILE_FAILURE,
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAILURE,
  UPDATE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_LOGO_FAILURE,
  REMOVE_COMPANY_LOGO_REQUEST,
  REMOVE_COMPANY_LOGO_SUCCESS,
  REMOVE_COMPANY_LOGO_FAILURE,
  RESET_MESSAGE,
} from "../../actionTypes";

const initialState = {
  data: null, // For company profile data
  avatarData: {}, // For avatar-related data
  isLoading: false,
  status: false,
  message: "",
};

const companyProfileReducer = (state = initialState, action) => {
  console.log("reducer data", action);
  switch (action.type) {
    case UPDATE_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        data: action.payload.data,
        message: action.payload.message,
      };
    case UPDATE_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case FETCH_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case UPDATE_COMPANY_LOGO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };

    case UPDATE_COMPANY_LOGO_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case REMOVE_COMPANY_LOGO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };

    case REMOVE_COMPANY_LOGO_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case RESET_MESSAGE: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };
    default:
      return state;
  }
};

export default companyProfileReducer;
