// actions.js
import {
  SHARE_RESUME_TEMPLATE_REQUEST,
  SHARE_RESUME_TEMPLATE_SUCCESS,
  SHARE_RESUME_TEMPLATE_FAILURE,
} from "../../actionTypes";

export const shareResumeTemplateRequest = (data) => ({
  type: SHARE_RESUME_TEMPLATE_REQUEST,
  payload: data,
});

export const shareResumeTemplateSuccess = (request) => ({
  type: SHARE_RESUME_TEMPLATE_SUCCESS,
  payload: request,
});

export const shareResumeTemplateFailure = (error) => ({
  type: SHARE_RESUME_TEMPLATE_FAILURE,
  payload: error,
});
