import React, { useContext } from "react";
import { Grid } from "react-feather";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

import NfIcon from "../../../assets/images/logo/logo.png";
import CustomizerContext from "../../../_helper/Customizer";

const SidebarLogo = () => {
  const { toggleSidebar, toggleIcon } = useContext(CustomizerContext);

  const openCloseSidebar = () => {
    toggleSidebar(!toggleIcon);
  };

  return (
    <div className="logo-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/jobseeker/dashboard`}>
        <img className="img-fluid d-inline" src={NfIcon} alt="" />
      </Link>

      <div
        className="back-btn"
        style={{ cursor: "pointer" }}
        onClick={() => openCloseSidebar()}
      >
        <IoIosArrowBack />
      </div>
      <div className="toggle-sidebar" onClick={openCloseSidebar}>
        <Grid className="status_toggle middle sidebar-toggle" />
      </div>
    </div>
  );
};

export default SidebarLogo;
