import {
  FETCH_UNREAD_NOTIFICATIONS_REQUEST,
  FETCH_ALL_NOTIFICATIONS_REQUEST,
  FETCH_UNREAD_NOTIFICATIONS_SUCCESS,
  FETCH_ALL_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  MARK_ALL_AS_READ_REQUEST,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILURE,
  RESET_MARK_ALL_AS_READ_MESSAGE,
} from "../actionTypes";

// Action Creators
export const fetchUnreadNotificationsRequest = (data, page, perPage) => ({
  type: FETCH_UNREAD_NOTIFICATIONS_REQUEST,
  payload: { data, page, perPage },
});

export const fetchAllNotificationsRequest = (data, page, perPage) => ({
  type: FETCH_ALL_NOTIFICATIONS_REQUEST,
  payload: { data, page, perPage },
});

export const fetchUnreadNotificationsSuccess = (data) => ({
  type: FETCH_UNREAD_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const fetchAllNotificationsSuccess = (data) => ({
  type: FETCH_ALL_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const fetchNotificationsFailure = (error) => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const markAllAsReadRequest = (data) => ({
  type: MARK_ALL_AS_READ_REQUEST,
  payload: data,
});

export const markAllAsReadSuccess = (message) => ({
  type: MARK_ALL_AS_READ_SUCCESS,
  payload: message,
});

export const markAllAsReadFailure = (error) => ({
  type: MARK_ALL_AS_READ_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetMarkAllAsReadMessage = () => ({
  type: RESET_MARK_ALL_AS_READ_MESSAGE,
});
