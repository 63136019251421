import {
  PUBLIC_APPLY_JOB_REQUEST,
  PUBLIC_APPLY_JOB_SUCCESS,
  PUBLIC_APPLY_JOB_FAILURE,
  PUBLIC_APPLY_JOB_RESET,
} from "../../actionTypes";

export const publicApplyJobRequest = (data) => ({
  type: PUBLIC_APPLY_JOB_REQUEST,
  payload: data,
});

export const publicApplyJobSuccess = (message) => ({
  type: PUBLIC_APPLY_JOB_SUCCESS,
  payload: message,
});

export const publicApplyJobFailure = (error) => ({
  type: PUBLIC_APPLY_JOB_FAILURE,
  payload: error,
});

export const publicApplyJobReset = () => ({
  type: PUBLIC_APPLY_JOB_RESET,
});
