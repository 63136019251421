import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import InviteRecruiter from "../../Components/Recruiter/InviteRecruiter/InviteRecruiter";
const InvitePage = () => {
  return (
    <Fragment>
      <Breadcrumbs title="Add User" mainTitle="Add User" />
      <Container fluid={true}>
        <InviteRecruiter />
      </Container>
    </Fragment>
  );
};

export default InvitePage;
