import Sidebar from "./Sidebar";
import ListCard from "./ListCard";

import { Container, Row } from "reactstrap";
import CandidateSearchProvider from "../../../CandidateSearch/CandidateSearchContext";
const CandidateSearch = () => {
  return (
    <Container fluid={true}>
      <Row className="mb-5">
        <CandidateSearchProvider>
          <Sidebar />
          <ListCard />
        </CandidateSearchProvider>
      </Row>
    </Container>
  );
};

export default CandidateSearch;
