import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  ListGroup,
  ListGroupItem,
  Card,
  Badge,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { showSweetAlert } from "../../Common/SweetAlert";
import Workexperiencemodal from "./Workexperiencemodal";

import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { socialMediaOptions } from "../../../Constant";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { showToast } from "../../Common/Toast";
import { educationLevelsOptions } from "../../../Constant";
import { formatDate } from "../../Common/CommonFunction";
import { useParams } from "react-router";
import {
  deleteWorkExpRequest,
  resetData,
} from "../../../redux/jobseeker/work_experience/actions";
import { capitalize } from "../../Common/CommonFunction";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";
const Workexperience = ({ profileData, validationMessages }) => {
  const dispatch = useDispatch();
  console.log(profileData);
  const { cand_id, jobcad_id } = useParams();
  const [showWorkExperience, setWorkExperience] = useState(false);

  const [showSocialMedia, setShowSocialMedia] = useState(false);

  const [updateWorkExperience, setUpdateWorkExperience] = useState({
    id: null,
    seeker_id: null,
    designation: "",
    started_working_from: "",
    worked_till: "",
    job_description: "",
  });

  const [existingEducationLevelOptions, setExistingEducationLevelOptions] =
    useState(null);
  useEffect(() => {
    const matchedOptions = educationLevelsOptions.filter(
      (option) =>
        profileData.education &&
        profileData.education.some(
          (education) =>
            education.education_level === option.value &&
            (education.education_level == "SECONDARY" ||
              education.education_level == "SENIOR_SECONDARY")
        )
    );
    // console.log("mat",matchedOptions);
    setExistingEducationLevelOptions(matchedOptions);
  }, []);

  const emptyField = (str) => {
    if (str === "" || null) {
      return "-";
    } else {
      return str;
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onEditSubmit = (data) => {
    alert(data);
  };

  const toggleWorkExperienceModal = () => {
    setWorkExperience(false);
  };

  const addworkExperience = () => {
    setWorkExperience(true);
    setUpdateWorkExperience({
      id: "",
      designation: "",
      organization: "",
      job_description: "",
      current_company: "YES",
      notice_period: "",
      started_working_from: "",
      worked_till: new Date(),
      current_salary: "",
    });
  };

  const editworkExperience = (data) => {
    setWorkExperience(true);

    setUpdateWorkExperience(data);
  };

  const handleWorkExperienceDeleteClick = (job_id) => {
    console.log("ji", job_id);
    showSweetAlert("confirm", {
      message: "You want to delete the job",
      fun: handleWorkExperience,
      id: job_id,
    });
  };

  const handleWorkExperience = (id) => {
    dispatch(deleteWorkExpRequest(id)); // Dispatch the action with the ID of the work experience to be deleted
  };

  const addWorkExpReducerData = useSelector((state) => state.addWorkExpReducer);
  useEffect(() => {
    console.log("srd", addWorkExpReducerData);
    if (addWorkExpReducerData.status && addWorkExpReducerData.message != "") {
      showToast("success", addWorkExpReducerData.message);
      dispatch(fetchJobseekerDataRequest());

      dispatch(resetData());
    } else if (
      addWorkExpReducerData.status == false &&
      addWorkExpReducerData.message != ""
    ) {
      showToast("error", addWorkExpReducerData.message);

      dispatch(resetData());
    }
  }, [addWorkExpReducerData]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
              Work Experience
            </h4>{" "}
            {cand_id ? null : (
              <FiPlusSquare
                className="add-icon "
                size={22}
                onClick={addworkExperience}
                title="Add"
              />
            )}
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ps-2">
            {profileData.work_experience &&
            profileData.work_experience.length > 0 ? (
              // Render work experience data if available
              profileData.work_experience.map((work) => (
                <div
                  className="d-flex justify-content-between setup mb-3"
                  key={work.id}
                >
                  <div>
                    <h5>
                      {capitalize(work.designation)}&nbsp;at&nbsp;
                      {work.organization.label}
                    </h5>
                    <p>
                      <span>
                        {formatDate(work.started_working_from)} -{" "}
                        {formatDate(work.worked_till)}
                      </span>
                      <br />
                      <span>{capitalize(work.job_description)}</span>
                    </p>
                  </div>
                  <div>
                    {!cand_id && (
                      <div className="ms-2">
                        <div className="d-flex">
                          <FiEdit
                            className="edit-icon me-2"
                            size={22}
                            onClick={() => {
                              editworkExperience(work);
                            }}
                            title="Edit"
                          />

                          <RiDeleteBinLine
                            className="delete-icon"
                            size={22}
                            onClick={() =>
                              handleWorkExperienceDeleteClick(work)
                            }
                            title="Delete"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              // Render "No data found" message if no work experience data
              <div className="text-center">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>

      {showWorkExperience && (
        <Workexperiencemodal
          isOpen={showWorkExperience}
          toggleModal={toggleWorkExperienceModal}
          data={updateWorkExperience}
        />
      )}
    </Fragment>
  );
};
export default Workexperience;
