import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Card,
  Badge,
} from "reactstrap";

import {
  performanceScaleOptions,
  educationLevelsOptions,
  schoolMediumsOptions,
} from "../../../Constant";

const Educationbackground = ({ profileData }) => {
  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between  ">
            <h4 className="card-title mb-0 " style={{ fontWeight: 550 }}>
              Education Background
            </h4>
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ps-2">
            {profileData.education && profileData.education.length > 0 ? (
              // Render education data if available
              profileData.education.map((education) => (
                <div
                  className="d-flex justify-content-between setup mb-3"
                  key={education.id}
                >
                  {education.education_level != "SECONDARY" &&
                  education.education_level != "SENIOR_SECONDARY" ? (
                    <div>
                      <h5>
                        {education.jobseeker_degree &&
                          education.jobseeker_degree.label}{" "}
                        - {education.jobseeker_specialization},{" "}
                        {education.jobseeker_passing_year}
                      </h5>
                      <p className="text-capitalize">
                        {education.jobseeker_college_name}
                        <br />
                        <span style={{ fontWeight: "lighter" }}>
                          {
                            performanceScaleOptions.find(
                              (option) =>
                                option.value == education.performance_scale
                            )?.label
                          }{" "}
                          : {education.performance}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h5>
                        {
                          educationLevelsOptions.find(
                            (option) =>
                              option.value == education.education_level
                          )?.label
                        }{" "}
                        - {education.passing_year}{" "}
                      </h5>
                      <p className="text-capitalize">
                        {education.board} -{" "}
                        {
                          schoolMediumsOptions.find(
                            (option) => option.value == education.school_medium
                          )?.label
                        }
                        <br />
                        <span style={{ fontWeight: "lighter" }}>
                          {
                            performanceScaleOptions.find(
                              (option) =>
                                option.value == education.performance_scale
                            )?.label
                          }{" "}
                          : {education.performance}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              ))
            ) : (
              // Render "No data found" message if no education data
              <div className="text-center">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default Educationbackground;
