// actions.js
import {
  SHARE_SEEKER_DATA_REQUEST,
  SHARE_SEEKER_DATA_SUCCESS,
  SHARE_SEEKER_DATA_FAILURE,
} from "../../actionTypes";

export const shareSeekerDataRequest = (data) => ({
  type: SHARE_SEEKER_DATA_REQUEST,
  payload: data,
});

export const shareSeekerDataSuccess = (request) => ({
  type: SHARE_SEEKER_DATA_SUCCESS,
  payload: request,
});

export const shareSeekerDataFailure = (error) => ({
  type: SHARE_SEEKER_DATA_FAILURE,
  payload: error,
});
