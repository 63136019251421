import React, { useState } from "react";
import { Card, CardImg, Button, Col } from "reactstrap";
import { FaCrown } from "react-icons/fa";
import BannerModal from "./BannerModal";

const BannerCard = ({ index, template }) => {
  const [showPreviewResume, setShowPreviewResume] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);

  const togglePreviewResumeModal = () => {
    setShowPreviewResume(!showPreviewResume);
  };

  const handlePreviewClick = () => {
    setSelectedBanner(template);
    setShowPreviewResume(true);
  };

  return (
    <Col lg="4" md="6" sm="6" xs="12" className="mb-5">
      <Card
        className="img-wrapper mb-3"
        style={{ height: "auto", width: "100%" }}
      >
        {template.type === "PREMIUM" && (
          <div
            className="position-absolute"
            style={{ bottom: "0", right: "0" }}
          >
            <FaCrown
              size={22}
              color="gold"
              title="As a Naukrify Pro member, enjoy this for free!"
            />
            {/* <span className="badge bg-warning text-dark">Premium</span> */}
          </div>
        )}
        <CardImg
          id="preview_img"
          src={template.banner_template_image}
          alt="Resume Template"
        />
      </Card>
      <div className="res-prv text-center">
        <Button
          id="preview_btn"
          value={template.id}
          style={{
            fontSize: "13px",
            borderRadius: "61px",
            border: "none",
            padding: "7px 20px",
          }}
          color="primary"
          onClick={handlePreviewClick}
        >
          Preview
        </Button>
      </div>
      {showPreviewResume && (
        <BannerModal
          isOpen={showPreviewResume}
          toggleModal={togglePreviewResumeModal}
          selectedBanner={selectedBanner}
          index={index}
        />
      )}
    </Col>
  );
};

export default BannerCard;
