import {
  UPDATE_LINKEDIN_BANNER_REQUEST,
  UPDATE_LINKEDIN_BANNER_SUCCESS,
  UPDATE_LINKEDIN_BANNER_FAILURE,
  RESET_LINKEDIN_BANNER_STATE,
} from "../actionTypes";

// Action creators
export const updateLinkedInBannerRequest = (data) => ({
  type: UPDATE_LINKEDIN_BANNER_REQUEST,
  payload: data,
});

export const updateLinkedInBannerSuccess = (message) => ({
  type: UPDATE_LINKEDIN_BANNER_SUCCESS,
  payload: message,
});

export const updateLinkedInBannerFailure = (error) => ({
  type: UPDATE_LINKEDIN_BANNER_FAILURE,
  payload: error,
});

export const resetLinkedInBannerState = () => ({
  type: RESET_LINKEDIN_BANNER_STATE,
});
