import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchJobNamesRequest } from "../../../redux/recruiter/job_name/actions";
import {
  sendChatMessageRequest,
  resetChatState,
} from "../../../redux/recruiter/chat_message/chat/actions";
import ChatMessage from "./ChatMessage";
import ChatAppContext from "../../../Chat";
import ChatHeader from "./ChatHeader";
import SendChat from "./SendChat";
import ChatMenu from "./ChatMenu";

const ChatModal = (props) => {
  const { handalChatMessageRequest, selectedUser } =
    useContext(ChatAppContext) || {};
  const { cand_id, jobcad_id } = useParams();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [jobNameOptions, setJobNameOptions] = useState([]);
  const jobNameReducerData = useSelector((state) => state.jobNameReducer);
  const chatReducerData = useSelector(
    (state) => state.chatReducer.sendChatMessage
  );

  const [chatId, setChatId] = useState(null);

  useEffect(() => {
    if (jobNameReducerData.data?.data?.length > 0) {
      const options = jobNameReducerData.data.data.map((job) => ({
        value: job.value,
        label: job.label,
      }));
      setJobNameOptions(options);
    } else {
      setJobNameOptions([]);
    }
  }, [jobNameReducerData.data]);

  const handleJobNameFocus = () => {
    dispatch(fetchJobNamesRequest(""));
  };

  const handleJobNameInputChange = (inputValue) => {
    if (inputValue) {
      dispatch(fetchJobNamesRequest(inputValue));
    }
  };

  const handleJobNameChange = (selectedOption) => {
    setErrors((prevErrors) => ({ ...prevErrors, jobId: "" }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      jobId: selectedOption ? selectedOption.value : null,
      jobName: selectedOption ? selectedOption.label : null,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.jobId) {
      setErrors({ jobId: "Job name is required" });
      return;
    }

    const messageData = {
      //type: "RECRUITER",
      job_name: formData.jobName,
      job_id: formData.jobId,
      seeker_id: props.seekerId,
    };

    dispatch(sendChatMessageRequest(messageData));
    //  props.toggleModal();
  };

  useEffect(() => {
    if (!chatReducerData?.isLoading && chatReducerData?.status) {
      setChatId(chatReducerData.chatId);

      if (handalChatMessageRequest) {
        // handalChatMessageRequest(chatId, chatReducerData.is_read);
        handalChatMessageRequest(chatId);
      }
    }
  }, [chatReducerData, handalChatMessageRequest, chatId]);

  // Reset state when modal is closed
  useEffect(() => {
    if (props.isOpen) {
      setFormData({});
      setErrors({});
      setChatId(null);
      dispatch(resetChatState("sendChatMessage")); // Dispatch resetChatState action here
    }
  }, [props.isOpen]);

  const customOptionsStyle = {
    control: (base) => ({
      ...base,
      minHeight: 35,
    }),
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered={false} // Remove centered to apply custom positioning
      className="modal-lg"
      style={{
        position: "fixed", // Fixes the position relative to the viewport
        bottom: "10px", // Distance from the bottom of the viewport
        right: "10px", // Distance from the right of the viewport
        width: "400px", // Width of the modal

        margin: 0, // Ensures no margin
      }}
    >
      <ModalHeader toggle={props.toggleModal}>Send Message</ModalHeader>
      <ModalBody className="pt-2">
        {!chatId && (
          <>
            <Form className="theme-form" onSubmit={handleSubmit}>
              <FormGroup>
                <Label>
                  Select Job
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  isLoading={jobNameReducerData.isLoading}
                  options={jobNameOptions}
                  value={jobNameOptions.find(
                    (opt) => opt.value === formData.jobId
                  )}
                  onChange={handleJobNameChange}
                  styles={customOptionsStyle}
                  onFocus={handleJobNameFocus}
                  onInputChange={handleJobNameInputChange}
                  placeholder="Select job name..."
                  menuPlacement="top"
                />
                {errors.jobId && (
                  <div className="text-danger">{errors.jobId}</div>
                )}
              </FormGroup>
              <ModalFooter>
                <Button color="secondary" onClick={props.toggleModal}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  Invite
                </Button>
              </ModalFooter>
            </Form>
          </>
        )}

        {chatId && (
          <Row className="chat-box ">
            {chatId ? (
              <>
                <Col className="pe-0 chat-right-aside ">
                  <div className="chat">
                    <ChatHeader />
                    <ChatMessage width={"85%"} />
                    <SendChat />
                  </div>
                </Col>
                {/* {menuToggle && (
                    <Col className="ps-0 chat-menu show">
                      <ChatMenu />
                    </Col>
                  )} */}
              </>
            ) : (
              <Col className="d-flex justify-content-center align-items-center vh-100">
                <div className="card p-4 text-center">
                  <div className="card-body">
                    <h3>Start a Conversation</h3>
                    <p>Select a chat to start messaging.</p>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ChatModal;
