import { takeLatest, put, call } from "redux-saga/effects";
import { FETCH_WEEKLY_STATUS_REQUEST } from "../../actionTypes";
import { fetchWeeklyStatusSuccess, fetchWeeklyStatusFailure } from "./actions";

import AxiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

function* fetchWeeklyStatus(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/manage_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}&sortBy=${action.payload.column}&direction=${action.payload.direction}`
    );

    if (response.data.status) {
      yield put(fetchWeeklyStatusSuccess(response.data));
    } else {
      yield put(fetchWeeklyStatusFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchWeeklyStatusFailure({ status: false, message: error.message })
    );
  }
}

export default function* weeklyStatusSaga() {
  yield takeLatest(FETCH_WEEKLY_STATUS_REQUEST, fetchWeeklyStatus);
}
