import React, { useContext } from "react";
import { Card, CardBody, Col, Media, Button } from "reactstrap";
import { useSelector } from "react-redux";
import CarToon from "../../../assets/images/dashboard/cartoon.svg";
import trophy from "../../../assets/images/trophy.png";
import { Navigate, useNavigate } from "react-router";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import d1 from "../../../assets/images/d1.png";
import d2 from "../../../assets/images/d2.gif";
import { showToast } from "../../Common/Toast";
import { resetDailyVisit } from "../../../redux/jobseeker/dashboard/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { AuthContext } from "../../../Auth/AuthContext";
import { COINS } from "../../../Constant";

const Congratulations = () => {
  const dispatch = useDispatch();
  const WelcomeToNaukrify = "Welcome to Naukrify";
  const WelcomeMessage = "Here whats happing in your account today  ";
  const WhatsNew = "What's new";
  const navigate = useNavigate();
  const { totalPoints, triggerTotalPoints } = useContext(AuthContext);

  const dashboardReducerData = useSelector(
    (state) => state.jobseekerDashboardReducer
  );

  if (
    !dashboardReducerData.isLoading &&
    dashboardReducerData.data &&
    dashboardReducerData.is_daily_visit
  ) {
    if (dashboardReducerData.is_daily_visit) {
      triggerTotalPoints("daily_visit", true);

      dispatch(resetDailyVisit());
    }
  }

  const handleReferClick = () => {
    navigate(`${process.env.PUBLIC_URL}/jobseeker/referral`);
  };

  return (
    <Card className="o-hidden mb-3">
      <CardBody
        className="balance-widget"
        style={{
          backgroundImage: "linear-gradient(to bottom right, #5f3deb, #ad79ff)",
        }}
      >
        {/* <span className="f-w-500 f-light " style={{ color: "white" }}>
          {" "}
          {rewards ? "Congratulations" : "Refer & Earn Rewards"}
        </span> */}
        <h4 className="f-w-500" style={{ color: "white" }}>
          {totalPoints ? "Congratulations" : "Refer & Earn Rewards"}
        </h4>
        <div
          style={{ color: "white", display: "flex", alignItems: "baseline" }}
        >
          <h3
            attrH4={{ className: "mb-3 mt-3 f-w-500 mb-0 f-22" }}
            style={{ margin: 0, display: "inline" }}
          >
            <CountUp
              duration={5}
              start={0}
              separator=","
              end={totalPoints ? totalPoints : "0"}
            />
          </h3>
          <span
            className="f-light f-14 f-w-400 ms-1 text-capitalize"
            style={{
              color: "white",
              textTransform: "capitalize",
              marginLeft: "4px",
            }}
          >
            {COINS}
          </span>
        </div>

        <div className="whatsnew-btn">
          <button
            className="btn btn-outline-white btn btn-outline-transparent f-w-500 mt-3 "
            //color="primary"
            // outline
            onClick={handleReferClick}
          >
            Refer & Earn
          </button>
        </div>

        <div className="mobile-right-img">
          <img
            src={d1}
            className="left-mobile-img"
            // attrImage={{
            //   className: "left-mobile-img",
            //   src: d1,
            //   alt: "",
            // }}
          />
          <img
            src={d2}
            className="mobile-img"
            // attrImage={{
            //   className: "mobile-img",
            //   src: d2,
            //   alt: "mobile with coin",
            // }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Congratulations;
