import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CardHeader,
  CardBody,
  Button,
  Table,
  Card,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { showSweetAlert } from "../../Common/SweetAlert";
import TechnicalskillsModal from "./TechnicalskillsModal";
import Select from "react-select";
import { LOADER_TIMEOUT } from "../../../Constant";
import {
  deleteMainSkillRequest,
  resetData,
} from "../../../redux/jobseeker/main_skill/actions";
import { useSelector, useDispatch } from "react-redux";
import { showToast } from "../../Common/Toast";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import {
  extractAfterLastHyphen,
  convertMonthsToYearsAndMonths,
} from "../../Common/CommonFunction";
import {
  updateCandidatePrimarySkillsRequest,
  resetCandidateUpdateState,
} from "../../../redux/recruiter/update_candidate_primary_skill/actions";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";

const Mainskills = ({ profileData }) => {
  const dispatch = useDispatch();
  const [showTechnicalSkills, setTechnicalSkills] = useState(false);
  const { type, cand_id, jobcad_id } = useParams();
  const [errors, setErrors] = useState({});
  const emptyField = (str) => {
    if (str === "" || str === null) {
      return "-";
    } else {
      return str;
    }
  };

  const toggleTechnicalSkillsModal = () => {
    setTechnicalSkills(!showTechnicalSkills);
  };

  const [editTechnicalSkill, setEditTechnicalSkill] = useState({
    id: null,
    seeker_id: null,
    it_skill: "",
    skill_version: "",
    skill_last_used: "",
    skill_experienced: "",
    skill_rating: "",
  });

  const addTechnicalSkill = () => {
    setTechnicalSkills(true);
    setEditTechnicalSkill({
      id: null,
      seeker_id: extractAfterLastHyphen(cand_id),
      it_skill: "",
      skill_version: "",
      skill_last_used: "",
      skill_experienced: "",
      skill_rating: "",
    });
  };

  const updateTechnicalSkill = (data) => {
    setTechnicalSkills(true);
    setEditTechnicalSkill(data);
  };

  const handleDeleteTechnicalSkillClick = (skill) => {
    showSweetAlert("confirm", {
      message: "You want to delete the job",
      fun: handleDeleteTechnicalSkills,
      id: skill,
    });
  };

  // Function to handle the delete action
  const handleDeleteTechnicalSkills = (skill) => {
    // Dispatch the delete action with the ID of the technical skill to be deleted
    dispatch(
      deleteMainSkillRequest({ id: skill.id, seeker_id: skill.seeker_id })
    );
  };

  const addMainSkillReducerData = useSelector(
    (state) => state.mainSkillReducer
  );

  useEffect(() => {
    if (
      addMainSkillReducerData.status &&
      addMainSkillReducerData.message !== ""
    ) {
      showToast("success", addMainSkillReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );

      dispatch(resetData());
    } else if (
      addMainSkillReducerData.status === false &&
      addMainSkillReducerData.message !== ""
    ) {
      showToast("error", addMainSkillReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      dispatch(resetData());
    }
  }, [dispatch, addMainSkillReducerData]);

  const [formData, setFormData] = useState({
    core_skills: profileData.primary_skill ? profileData.primary_skill : null,
  });

  const handleCoreSkillsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, core_skills: "" };
    setFormData((prevFormData) => ({
      ...prevFormData,
      core_skills: selectedOptions,
    }));
  };

  const handleSkillsFocus = () => {
    // Implement focus logic if necessary
  };

  const handleSkillsInputChange = (inputValue) => {
    // Implement input change logic if necessary
  };
  const validate = () => {
    let errors = {};
    let isValid = true;
    if (!formData.core_skills) {
      errors.core_skills = "Core skills are required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleCoreSkillsSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      const formattedData = {
        primary_skill: formData.core_skills.map((loc) => loc.value).join(","),
        seeker_id: extractAfterLastHyphen(cand_id),
      };
      console.log("formattedData", formattedData);
      dispatch(updateCandidatePrimarySkillsRequest(formattedData));
    }
  };

  const updateCandidatePrimarySkillReducerData = useSelector(
    (state) => state.updateCandidatePrimarySkillReducer
  );
  useEffect(() => {
    if (
      updateCandidatePrimarySkillReducerData.status &&
      updateCandidatePrimarySkillReducerData.message != ""
    ) {
      showToast("success", updateCandidatePrimarySkillReducerData.message);
      dispatch(
        fetchJobseekerDataRequest({
          seeker_id: extractAfterLastHyphen(cand_id),
          job_cand_id: extractAfterLastHyphen(jobcad_id),
        })
      );
      dispatch(resetCandidateUpdateState());
    } else if (
      updateCandidatePrimarySkillReducerData.status == false &&
      updateCandidatePrimarySkillReducerData.message != ""
    ) {
      showToast("error", updateCandidatePrimarySkillReducerData.message);
      dispatch(resetCandidateUpdateState());
    }
  }, [updateCandidatePrimarySkillReducerData]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between ">
            <h4 className="mb-0" style={{ fontWeight: 550 }}>
              Main Skills
            </h4>{" "}
            {type === "view" ? null : (
              <FiPlusSquare
                className={`add-icon ${
                  profileData.it_skills && profileData.it_skills.length > 15
                    ? "text-muted disabled"
                    : ""
                }`}
                size={22}
                onClick={addTechnicalSkill}
                title="Add"
              />
            )}
          </div>
        </CardHeader>
        <CardBody className="">
          <div
            className="ps-2 custom-scrollbar"
            style={{ maxHeight: "600px", overflowY: "auto" }}
          >
            {profileData.it_skills && profileData.it_skills.length > 0 ? (
              <div className="table-responsive pe-3">
                <Table borderless>
                  {/* Table header */}
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Skills</th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Version
                      </th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Last Used
                      </th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Experience
                      </th>
                      <th
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        Rating
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody>
                    {profileData.it_skills.map((skill) => (
                      <tr key={skill.id}>
                        <td>{skill.it_skill.label}</td>
                        <td className="text-center">
                          {emptyField(skill.skill_version)}
                        </td>
                        <td className="text-center">
                          {emptyField(skill.skill_last_used)}
                        </td>
                        <td className="text-center">
                          {emptyField(
                            convertMonthsToYearsAndMonths(
                              skill.skill_experienced
                            )
                          )}
                        </td>
                        <td className="text-center">
                          {emptyField(skill.skill_rating)}
                        </td>
                        <td className="text-center pe-0">
                          {type === "view" ? null : (
                            <div className="d-flex justify-content-end">
                              <FiEdit
                                className="edit-icon"
                                size={22}
                                onClick={() => updateTechnicalSkill(skill)}
                                title="Edit"
                              />
                              <RiDeleteBinLine
                                className="delete-icon ms-2"
                                size={22}
                                onClick={() =>
                                  handleDeleteTechnicalSkillClick(skill)
                                }
                                title="Delete"
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-3">No data found</div>
            )}
          </div>
          {profileData.it_skills && profileData.it_skills.length > 0 && (
            <Form className=" mt-3" onSubmit={handleCoreSkillsSubmit}>
              <Row className="d-flex align-items-center">
                <Col md={type !== "view" ? 10 : 12}>
                  <FormGroup>
                    <Label>
                      Core Skills
                      <span className="text-danger">*</span>
                    </Label>
                    <Select
                      isMulti
                      options={
                        profileData.it_skills
                          ? profileData.it_skills.map((skill) => ({
                              label: skill.it_skill.label,
                              value: skill.it_skill.value,
                            }))
                          : []
                      }
                      value={formData.core_skills}
                      onChange={handleCoreSkillsChange}
                      onFocus={handleSkillsFocus}
                      onInputChange={handleSkillsInputChange}
                      placeholder="Select Skills"
                    />
                    {errors.core_skills && (
                      <small className="text-danger">
                        {errors.core_skills}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                {type !== "view" && (
                  <Col md={2} className="d-flex align-items-end">
                    <Button
                      disabled={
                        !formData.core_skills ||
                        formData.core_skills.length == 0 ||
                        formData.core_skills.length > 3 ||
                        updateCandidatePrimarySkillReducerData.isLoading
                      }
                      type="submit"
                      color="primary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "1rem",
                        paddingLeft: "1rem",
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                )}
              </Row>
              <small>
                <b>Note:</b> Your core skill can be three of your main skills!
                skills!
              </small>
            </Form>
          )}
        </CardBody>
      </Card>

      {showTechnicalSkills && (
        <TechnicalskillsModal
          isOpen={showTechnicalSkills}
          toggleModal={toggleTechnicalSkillsModal}
          data={editTechnicalSkill}
        />
      )}
    </Fragment>
  );
};

export default Mainskills;
