import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Context from "./index";
import {
  fetchChatMessagesRequest,
  sendChatMessageRequest,
  fetchChatMembersRequest,
  resetChatState,
} from "../redux/recruiter/chat_message/chat/actions";
import { NotificationContext } from "../Notifications/NotificationContext";
import { AuthContext } from "../Auth/AuthContext";
const ChatProvider = (props) => {
  const dispatch = useDispatch();
  const { fetchUnreadNotificationsRequest } = useContext(NotificationContext);
  const { triggerTotalPoints } = useContext(AuthContext);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedUserUnreadMsgCount, setSelectedUserUnreadMsgCount] =
    useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const recruiterProfileReducerData = useSelector(
    (state) => state.recruiterProfileReducer
  );

  // const { isLoading: profileLoading, data: currentUser } =
  //   recruiterProfileReducerData;

  const fetchJobseekerDataReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer.data
  );

  // const { isLoading: jobseekerProfileLoading, data: currentUser } =
  //   fetchJobseekerDataReducerData;

  let currentUser;
  let profileLoading = false;

  if (fetchJobseekerDataReducerData.status === true) {
    currentUser = fetchJobseekerDataReducerData.data;
    profileLoading = fetchJobseekerDataReducerData.isLoading; // Assuming jobseekerProfileLoading is defined
  } else if (recruiterProfileReducerData.status === true) {
    currentUser = recruiterProfileReducerData.data;
    profileLoading = recruiterProfileReducerData.isLoading; // Assuming profileLoading is defined
  }

  const changeChat = (member) => {
    setSelectedChatId(member.chat_id);
    setSelectedUserUnreadMsgCount(member.unread_msg_count);
    setSelectedUser(member);
  };

  const membersReducerData = useSelector(
    (state) => state.chatReducer.fetchChatMembers
  );

  let {
    isLoading: memberLoading,
    data: members,
    // totalUnread: totalUnreadMembers,
  } = membersReducerData; // Initialize as let to allow modification

  // Check if members is an array, if not set it to an empty array
  if (!Array.isArray(members)) {
    members = [];
  }

  const handalChatMessageRequest = (chat_id) => {
    setSelectedChatId(chat_id);
    setSelectedUserUnreadMsgCount(1);
  };

  // UseEffect to fetch data when selectedUser changes
  useEffect(() => {
    if (selectedChatId) {
      const isRead = selectedUserUnreadMsgCount > 0 ? true : false;
      dispatch(
        fetchChatMessagesRequest({
          chat_id: selectedChatId,
          is_read: isRead,
        })
      );
    }
  }, [selectedChatId, selectedUserUnreadMsgCount, dispatch]);

  useEffect(() => {
    dispatch(fetchChatMembersRequest());
  }, [dispatch]);

  // Select necessary state values from Redux store
  const chatReducerData = useSelector(
    (state) => state.chatReducer.fetchChatMessages
  );

  // Destructure state values
  const {
    isLoading: chatLoading,
    data: messages,
    profileDetails: selectedUserProfile,
    status: chatStatus,
  } = chatReducerData;

  // Call fetchUnreadNotificationsRequest when chatStatus is true and not loading
  useEffect(() => {
    if (selectedUserUnreadMsgCount > 0 ? true : false) {
      if (!chatLoading && chatStatus) {
        fetchUnreadNotificationsRequest();
      }
    }
  }, [chatReducerData, chatLoading, chatStatus]);

  // Function to send a chat message
  const sendChatMessage = (messageText) => {
    const messageData = {
      chat_id: selectedChatId,
      message_text: messageText,
    };
    dispatch(sendChatMessageRequest(messageData));
  };

  const sendChatMessageData = useSelector(
    (state) => state.chatReducer.sendChatMessage
  );

  useEffect(() => {
    if (
      sendChatMessageData.status &&
      sendChatMessageData.message != "" &&
      sendChatMessageData.is_replying_to_recruiter
    ) {
      if (sendChatMessageData.is_replying_to_recruiter) {
        triggerTotalPoints("replying_to_recruiter", true, true);
        dispatch(resetChatState("sendChatMessage"));
      }
    }
  }, [sendChatMessageData]);

  const [searchKeyword, setSearchKeyword] = useState("");

  const searchMember = (keyword) => {
    setSearchKeyword(keyword);
  };

  const [profileToggle, setProfileToggle] = useState(false); // Add menuToggle state

  const handalProfileToggle = () => {
    setProfileToggle((prevToggle) => !prevToggle);
  };

  return (
    <Context.Provider
      value={{
        ...props,
        currentUser,
        selectedUser,

        messages,
        chatLoading,
        members,
        memberLoading,
        searchMember,
        sendChatMessage,
        changeChat,
        handalProfileToggle,
        searchKeyword,
        profileToggle,
        handalChatMessageRequest,
        // totalUnreadMembers,
        selectedUserProfile,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default ChatProvider;
