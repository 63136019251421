import { takeLatest, put, call } from "redux-saga/effects";
import {
  FETCH_JOB_CONVERSION_REQUEST,
  DOWNLOAD_JOB_CONVERSION_CSV_REQUEST,
} from "../../actionTypes";
import {
  fetchJobConversionSuccess,
  fetchJobConversionFailure,
} from "./actions";

import AxiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

function* fetchJobConversion(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/job_conversion_insights_report`,
      action.payload
    );

    if (response.data.status) {
      yield put(fetchJobConversionSuccess(response.data));
    } else {
      yield put(fetchJobConversionFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchJobConversionFailure({ status: false, message: error.message })
    );
  }
}

function* downloadJobConversionCsv(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/job_conversion_insights_report_download`,
      action.payload
    );
    console.log("response", response);
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const downloadLink = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);

    downloadLink.download = "exported_data.xls";
    downloadLink.href = blobUrl;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error:", error);
    // Handle errors here
  }
}

export default function* jobConversionSaga() {
  yield takeLatest(FETCH_JOB_CONVERSION_REQUEST, fetchJobConversion);
  yield takeLatest(
    DOWNLOAD_JOB_CONVERSION_CSV_REQUEST,
    downloadJobConversionCsv
  );
}
