import {
  UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST,
  UPDATE_CANDIDATE_PRIMARY_SKILLS_SUCCESS,
  UPDATE_CANDIDATE_PRIMARY_SKILLS_FAILURE,
  RESET_CANDIDATE_PRIMARY_SKILLS_STATE,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
};

const updateCandidatePrimarySkillReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CANDIDATE_PRIMARY_SKILLS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CANDIDATE_PRIMARY_SKILLS_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case UPDATE_CANDIDATE_PRIMARY_SKILLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_CANDIDATE_PRIMARY_SKILLS_STATE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default updateCandidatePrimarySkillReducer;
