import React, { Fragment } from "react";
import { Col, Row, Button, Table } from "reactstrap";
import { downloadJobConversionCsvRequest } from "../../../redux/recruiter/job_conversion/actions";
import { useDispatch } from "react-redux";
import OverallJobMetricsDatatable from "./OverallJobMetricsDatatable";
import JobMetricsBreakDownDatatable from "./JobMetricsBreakDownDatatable";
import NoDataFound from "../../Common/NoDataFound";
const JobConversionDatatable = ({ jobConversionData, formData }) => {
  const dispatch = useDispatch();

  const handleExport = () => {
    const obj = {
      //  candidate_status: formData.candidate_status,
      job_id: formData.jobId,
      startDate: formData.dateRange.startDate.toISOString(), // Convert Date objects to strings
      endDate: formData.dateRange.endDate.toISOString(),
    };

    dispatch(downloadJobConversionCsvRequest(obj));
  };

  // Check if jobConversionData exists and has data1
  const jobData =
    jobConversionData && jobConversionData.job_conversion_report
      ? jobConversionData.job_conversion_report
      : {};

  if (Object.keys(jobData).length === 0) {
    return (
      <div className="mt-3">
        <NoDataFound />
      </div>
    );
  }

  return (
    <Fragment>
      <Row className="d-flex justify-content-between mb-3 mt-3">
        <Col xs="auto">
          <h5>Job Conversion Insights</h5>
        </Col>
        <Col xs="auto">
          <Button color="primary" onClick={handleExport}>
            Export
          </Button>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs="12" className="box-col-12 xl-100 ">
          <Table striped responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Job Name</th>
                <th>Application Completion Rate</th>
                <th>Candidates per Hire</th>
                <th>Application to Interview</th>
                <th>Applicants per Open Requisition</th>
                <th>Interviews to Hires</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(jobData).map((jobKey, index) => {
                const jobDetails = jobData[jobKey];
                const {
                  job_name,
                  applicants_per_open_requisition,
                  application_completion_rate,
                  application_to_interview,
                  candidates_per_hire,
                  interviews_to_hires,
                } = jobDetails || {};

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{job_name || "-"}</td>
                    <td>{`${application_completion_rate || 0}%`}</td>
                    <td>{`${candidates_per_hire || "0"}%`}</td>
                    <td>{`${application_to_interview || "0"}%`}</td>
                    <td>{`${applicants_per_open_requisition || "0"}%`}</td>

                    <td>{`${interviews_to_hires || "0"}%`}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <OverallJobMetricsDatatable jobConversionData={jobConversionData} />
      <JobMetricsBreakDownDatatable />
    </Fragment>
  );
};

export default JobConversionDatatable;
