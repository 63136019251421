import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../redux/RootReducer';
import rootSaga from "../sagas/RootSaga";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer, // Your root reducer
  middleware: [sagaMiddleware], // Add Saga middleware
});

sagaMiddleware.run(rootSaga);

export default store;


