// reducer.js
import {
  SHARE_SEEKER_DATA_REQUEST,
  SHARE_SEEKER_DATA_SUCCESS,
  SHARE_SEEKER_DATA_FAILURE,
} from "../../actionTypes";

const initialState = {
  data: {},
  status: false,
  isLoading: false,
  message: "",
};

const shareSeekerDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHARE_SEEKER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SHARE_SEEKER_DATA_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        data: action.payload.data,
      };
    case SHARE_SEEKER_DATA_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default shareSeekerDataReducer;
