// reducer.js

import {
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  error: null,
  status: false,
};

const jobSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status,
      };
    case FETCH_JOBS_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default jobSearchReducer;
