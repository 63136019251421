
import Context from './index';

const AnimationThemeProvider = (props) => {
  

  return (
    <Context.Provider
      value="fadeIn"
    >
      {props.children}
    </Context.Provider>
  );
};

export default AnimationThemeProvider;
