import {
  FETCH_JOB_PIPELINE_REQUEST,
  FETCH_JOB_PIPELINE_SUCCESS,
  FETCH_JOB_PIPELINE_FAILURE,
  DOWNLOAD_PIPELINE_CSV_REQUEST,
  DOWNLOAD_PIPELINE_CSV_SUCCESS,
  DOWNLOAD_PIPELINE_CSV_FAILURE,
} from "../../actionTypes";

export const fetchJobPipelineRequest = (data) => {
  return {
    type: FETCH_JOB_PIPELINE_REQUEST,
    payload: data,
  };
};
export const fetchJobPipelineSuccess = (jobSources) => {
  return {
    type: FETCH_JOB_PIPELINE_SUCCESS,
    payload: jobSources,
  };
};

export const fetchJobPipelineFailure = (error) => {
  return {
    type: FETCH_JOB_PIPELINE_FAILURE,
    payload: error,
  };
};

export const downloadPipelineCsvRequest = (data) => {
  return {
    type: DOWNLOAD_PIPELINE_CSV_REQUEST,
    payload: data,
  };
};
export const downloadPipelineCsvSuccess = (jobSources) => {
  return {
    type: DOWNLOAD_PIPELINE_CSV_SUCCESS,
    payload: jobSources,
  };
};

export const downloadPipelineCsvFailure = (error) => {
  return {
    type: DOWNLOAD_PIPELINE_CSV_FAILURE,
    payload: error,
  };
};
