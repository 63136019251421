import {
  UPDATE_CANDIDATE_TECHNICAL_SKILLS_REQUEST,
  UPDATE_CANDIDATE_TECHNICAL_SKILLS_SUCCESS,
  UPDATE_CANDIDATE_TECHNICAL_SKILLS_ERROR,
  RESET_CANDIDATE_TECHNICAL_SKILLS_MESSAGE,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
};

const updateCandidateTechnicalSkillsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_CANDIDATE_TECHNICAL_SKILLS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CANDIDATE_TECHNICAL_SKILLS_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case UPDATE_CANDIDATE_TECHNICAL_SKILLS_ERROR:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_CANDIDATE_TECHNICAL_SKILLS_MESSAGE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default updateCandidateTechnicalSkillsReducer;
