import { createContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadFiltersFromLocalStorage,
  saveFiltersToLocalStorage,
  removeFiltersFromLocalStorage,
} from "../Components/Common/CommonFunction";
import { fetchCompanyProfile } from "../redux/recruiter/company_profile/actions";

export const CandidateSearchContext = createContext();
const defaultFilters = {
  freshness: "",
  gender: "",
  locations: [],
  keyword: "",
  minctc: 0,
  maxctc: 1000000,
  industry_type: [],
  functional_area: [],
  skills: [],
  experience: "",
};
export default function CandidateSearchProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch company profile on component mount
    dispatch(fetchCompanyProfile());
  }, [dispatch]);

  const companyProfileReducerData = useSelector(
    (state) => state.companyProfileReducer
  );

  const [profileData, setProfileData] = useState(null);

  const [filters, setFilters] = useState(defaultFilters);
  const [pagePerpage, setPagePerpage] = useState({ page: 1, per_page: 6 });
  useEffect(() => {
    if (
      companyProfileReducerData &&
      !companyProfileReducerData.isLoading &&
      companyProfileReducerData.data
    ) {
      setProfileData(companyProfileReducerData.data);
    }
  }, [companyProfileReducerData]);

  useEffect(() => {
    const localFilters = loadFiltersFromLocalStorage(
      "candidateSearchFilters",
      null
    );

    if (localFilters) {
      setFilters(localFilters);
    } else if (profileData) {
      const data = {
        freshness: "", // Default value
        gender: "",
        locations: [],
        keyword: "",
        minctc: 0,
        maxctc: 1000000,
        industry_type: profileData.industry_area
          ? [profileData.industry_area]
          : [],
        functional_area: profileData.functional_area_type
          ? profileData.functional_area_type
          : [],
        skills: [],
        experience: "",
      };
      setFilters(data);
    } else if (localFilters) {
      setFilters(localFilters);
    }
  }, [profileData]);

  // Save filters to localStorage on change
  useEffect(() => {
    if (filters !== defaultFilters) {
      saveFiltersToLocalStorage("candidateSearchFilters", filters);
    }
  }, [filters]);

  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
    removeFiltersFromLocalStorage("candidateSearchFilters");
  };
  const updatePagePerpage = (newFilters) => {
    setPagePerpage((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  return (
    <CandidateSearchContext.Provider
      value={{
        filters,
        updateFilters,
        clearFilters,
        updatePagePerpage,
        pagePerpage,
      }}
    >
      {children}
    </CandidateSearchContext.Provider>
  );
}
