import {
  FETCH_JOB_NAMES_REQUEST,
  FETCH_JOB_NAMES_SUCCESS,
  FETCH_JOB_NAMES_FAILURE,
} from "../../actionTypes";

export const fetchJobNamesRequest = (keyword) => {
  return {
    type: FETCH_JOB_NAMES_REQUEST,
    payload: { keyword },
  };
};
export const fetchJobNamesSuccess = (jobs) => {
  return {
    type: FETCH_JOB_NAMES_SUCCESS,
    payload: jobs,
  };
};

export const fetchJobNamesFailure = (error) => {
  return {
    type: FETCH_JOB_NAMES_FAILURE,
    payload: error,
  };
};
