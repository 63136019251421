import { Container } from "reactstrap";
import ProfileEditContain from "../../Components/Jobseeker/Profile";
import { Fragment } from "react";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { useParams } from "react-router";
const UpdateProfilePage = () => {
  const { cand_id, jobcad_id } = useParams();
  return (
    <Fragment>
      <Breadcrumbs
        title={cand_id ? "Candidates Details" : "Update Profile"}
        mainTitle={cand_id ? "Candidates Details" : "Update Profile"}
      />
      <Container fluid={true}>
        <ProfileEditContain />
      </Container>
    </Fragment>
  );
};

export default UpdateProfilePage;
