import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import { updateResumeFailure, updateResumeSuccess } from "./actions";

function* updateResume(action) {
  console.log("ad", action);
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/add_resume_headline", action.payload)
    );
    console.log("sr", response);
    if (response.data.status) {
      yield put(updateResumeSuccess(response.data));
    } else {
      yield put(updateResumeFailure(response.data));
    }
  } catch (error) {
    yield put(updateResumeSuccess({ status: false, message: error.message }));
  }
}

function* updateResumeSaga() {
  yield takeLatest(types.UPDATE_RESUME_HEADLINE_REQUEST, updateResume);
}

export default updateResumeSaga;
