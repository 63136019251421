import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import excelFormat from "../../assets/files/exceluploadformate.csv"; // Adjust the path if necessary
import {
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  CardHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { showToast } from "../../Components/Common/Toast";
import {
  bulkUploadRequest,
  bulkUploadReset,
} from "../../redux/recruiter/candidate_bulk_upload/actions";
import {
  candidateBulkExcelUploadRequest,
  candidateBulkExcelUploadReset,
} from "../../redux/recruiter/candidate_bulk_excel_upload/actions";
import Select from "react-select";
import { fetchJobNamesRequest } from "../../redux/recruiter/job_name/actions";
import { FiUpload } from "react-icons/fi";
import { AuthContext } from "../../Auth/AuthContext";
const BulkUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { triggerTotalPoints } = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  const [jobNameOptions, setJobNameOptions] = useState([]);
  const jobNameReducerData = useSelector((state) => state.jobNameReducer);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("RESUME_UPLOAD");
  const candidateBulkExcelUploadReducerData = useSelector(
    (state) => state.candidateBulkExcelUploadReducer
  );

  const candidateBulkUploadReducerData = useSelector(
    (state) => state.candidateBulkUploadReducer
  );
  const customOptionsStyle = {
    control: (base) => ({
      ...base,
      minHeight: 35,
    }),
  };

  useEffect(() => {
    if (jobNameReducerData.data?.data?.length > 0) {
      const options = jobNameReducerData.data.data.map((job) => ({
        value: job.value,
        label: job.label,
      }));
      setJobNameOptions(options);
    } else {
      setJobNameOptions([]);
    }
  }, [jobNameReducerData.data]);

  const handleJobNameFocus = () => {
    dispatch(fetchJobNamesRequest(""));
  };

  const handleJobNameInputChange = (inputValue) => {
    if (inputValue) {
      dispatch(fetchJobNamesRequest(inputValue));
    }
  };

  const handleJobNameChange = (selectedOption) => {
    setFormData({
      jobId: selectedOption ? selectedOption.value : 0,
      jobName: selectedOption ? selectedOption.label : null,
    });
  };

  const getUploadParams = () => ({ url: "https://httpbin.org/post" });

  const handleChangeStatus = ({ meta, file }, status) => {
    const updatedErrors = { ...errors, uploadedFiles: "" };
    setErrors(updatedErrors);
    if (status === "done") {
      // Handle file uploaded
      setUploadedFiles((prevFiles) => [...prevFiles, { file, meta }]);
    } else if (status === "removed") {
      // Handle file removed/cancelled
      setUploadedFiles((prevFiles) =>
        prevFiles.filter((f) => f.meta.id !== meta.id)
      );
    }
  };

  const validate = () => {
    const errors = {};
    let isValid = true;
    if (uploadedFiles.length === 0) {
      errors.uploadedFiles = "Please upload at least one file";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const tabOptions = [
    {
      value: "RESUME_UPLOAD",
      label: "RESUME UPLOAD",
    },
    {
      value: "EXCEL_UPLOAD",
      label: "EXCEL UPLOAD (CSV)",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (uploadedFiles.length > 0) {
        const submissionData = new FormData();

        if (activeTab === "RESUME_UPLOAD") {
          uploadedFiles.forEach(({ file }, index) => {
            submissionData.append(`jobseeker_resume_file${index}`, file);
          });
          submissionData.append("job_id", formData.jobId); // Correctly appending job_id from state
          dispatch(bulkUploadRequest(submissionData));
        } else if (activeTab === "EXCEL_UPLOAD") {
          uploadedFiles.forEach(({ file }, index) => {
            submissionData.append("jobseeker_resume_file", file);
          });
          submissionData.append("job_id", formData.jobId); // Correctly appending job_id from state
          dispatch(candidateBulkExcelUploadRequest(submissionData));
        }
        showToast("warning", "AI is parsing the resume in the background");
        // navigate(${process.env.PUBLIC_URL}/jobseeker/resume_scan_result);
      } else {
        showToast("error", "Please upload at least one file before scanning");
      }
    }
  };

  useEffect(() => {
    if (
      candidateBulkUploadReducerData.status == true &&
      candidateBulkUploadReducerData.message != ""
    ) {
      // showToast("warning", candidateBulkUploadReducerData.message);
      triggerTotalPoints(
        "bulk_upload",
        true,
        false,
        candidateBulkUploadReducerData.parsed_resumes
      );
      setUploadedFiles([]);
      // navigate(`${process.env.PUBLIC_URL}/recruiter/manage_candidate`);
      // Dispatch action to reset the message after showing the toast
      dispatch(bulkUploadReset());
    } else if (
      candidateBulkUploadReducerData.status == false &&
      candidateBulkUploadReducerData.message != ""
    ) {
      //showToast("error", candidateBulkUploadReducerData.message);
      setUploadedFiles([]);
      // Dispatch action to reset the message after showing the toast
      dispatch(bulkUploadReset());
    }
  }, [candidateBulkUploadReducerData]);

  useEffect(() => {
    if (
      candidateBulkExcelUploadReducerData.status == true &&
      candidateBulkExcelUploadReducerData.message != ""
    ) {
      //showToast("warning", candidateBulkExcelUploadReducerData.message);
      triggerTotalPoints(
        "bulk_upload",
        true,
        false,
        candidateBulkExcelUploadReducerData.parsed_resumes
      );
      setUploadedFiles([]);
      //   navigate(`${process.env.PUBLIC_URL}/recruiter/manage_candidate`);
      // Dispatch action to reset the message after showing the toast
      dispatch(candidateBulkExcelUploadReset());
    } else if (
      candidateBulkExcelUploadReducerData.status == false &&
      candidateBulkExcelUploadReducerData.message != ""
    ) {
      //showToast("error", candidateBulkExcelUploadReducerData.message);
      setUploadedFiles([]);
      // Dispatch action to reset the message after showing the toast
      dispatch(bulkUploadReset());
    }
  }, [candidateBulkExcelUploadReducerData]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Bulk Upload" title="Bulk Upload" />
      <Container fluid>
        <Card className="bg-white">
          <CardHeader className="py-3">
            <Nav
              className="nav-pills nav-primary justify-content-start mb-3"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {tabOptions.map((item, i) => (
                <NavItem key={i}>
                  <NavLink
                    className={`font-weight-bold ${
                      activeTab === item.value ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(item.value)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </CardHeader>
          <CardBody>
            <Form className="theme-form">
              <FormGroup>
                <Label>Select Job</Label>
                <Select
                  isLoading={jobNameReducerData.isLoading}
                  options={jobNameOptions}
                  value={jobNameOptions.find(
                    (opt) => opt.value === formData.jobId
                  )}
                  onChange={handleJobNameChange}
                  styles={customOptionsStyle}
                  onFocus={handleJobNameFocus}
                  onInputChange={handleJobNameInputChange}
                  placeholder="Select job name..."
                />
              </FormGroup>
              {activeTab === "RESUME_UPLOAD" && (
                <FormGroup>
                  <Label>
                    Resume Upload
                    <span className="text-danger">*</span>
                  </Label>
                  <div className="dropzone-wrapper">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      maxFiles={10}
                      multiple={true}
                      canCancel={true}
                      accept=".pdf,.docx,.doc"
                      inputContent={
                        <div style={{ textAlign: "center", cursor: "pointer" }}>
                          <FiUpload size={40} />
                          <h5 className="mt-2">
                            Drag and drop files to upload candidates
                            (.pdf,.docx,.doc)
                          </h5>
                        </div>
                      }
                      styles={{
                        dropzone: {
                          minHeight: 180,
                          borderColor: "#007bff",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                          display: "inline-flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "10px",
                        },
                        preview: {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "calc(33.33% - 10px)", // Adjust width to fit three items per row
                          boxSizing: "border-box",
                          margin: "10px 0",
                        },
                        previewImage: {
                          display: "none",
                        },
                        previewName: {
                          display: "block",
                          padding: "10px",
                          textAlign: "center",
                        },
                        inputLabelWithFiles: {
                          // Align content with files uploaded
                          marginTop: "4rem",
                          justifyContent: "center",
                        },
                      }}
                    />
                  </div>
                  {errors.uploadedFiles && (
                    <small className="text-danger">
                      {errors.uploadedFiles}
                    </small>
                  )}
                </FormGroup>
              )}
              {activeTab === "EXCEL_UPLOAD" && (
                <FormGroup>
                  <Label>
                    Excel Upload
                    <span className="text-danger">*</span>
                  </Label>
                  <div className="dropzone-wrapper">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      maxFiles={1}
                      multiple={false}
                      canCancel={true}
                      accept=".csv"
                      inputContent={
                        <div style={{ textAlign: "center", cursor: "pointer" }}>
                          <FiUpload size={40} />
                          <h5 className="mt-2">
                            Drag and drop files to upload candidates (.csv)
                          </h5>
                        </div>
                      }
                      styles={{
                        dropzone: {
                          minHeight: 180,
                          borderColor: "#007bff",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                          display: "inline-flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "10px",
                        },
                        preview: {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "calc(33.33% - 10px)", // Adjust width to fit three items per row
                          boxSizing: "border-box",
                          margin: "10px 0",
                        },
                        previewImage: {
                          display: "none",
                        },
                        previewName: {
                          display: "block",
                          padding: "10px",
                          textAlign: "center",
                        },
                        inputLabelWithFiles: {
                          // Align content with files uploaded
                          marginTop: "4rem",
                          justifyContent: "center",
                        },
                      }}
                    />
                  </div>
                  {errors.uploadedFiles && (
                    <small className="text-danger">
                      {errors.uploadedFiles}
                    </small>
                  )}{" "}
                  <br />
                  <div className="d-flex  justify-content-center">
                    <small className="text-muted text-center">
                      <strong>NOTE:</strong> Upload your CSV file with a header
                      row. Make sure the CSV file has columns for all the
                      required fields.
                      <br />
                      <a
                        href={excelFormat}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to download Naukrify Excel Format.
                      </a>
                    </small>
                  </div>
                </FormGroup>
              )}
              <Button
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  candidateBulkExcelUploadReducerData.isLoading ||
                  candidateBulkUploadReducerData.isLoading
                }
              >
                Submit
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default BulkUpload;
