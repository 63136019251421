import {
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,
  RESET_NOTE_MESSAGE,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
} from "../../actionTypes";

// action creators
export const addNoteRequest = (data) => ({
  type: ADD_NOTE_REQUEST,
  payload: data,
});

export const addNoteSuccess = (message) => ({
  type: ADD_NOTE_SUCCESS,
  payload: message,
});

export const addNoteFailure = (error) => ({
  type: ADD_NOTE_FAILURE,
  payload: error,
});

export const deleteNoteRequest = (data) => ({
  type: DELETE_NOTE_REQUEST,
  payload: data,
});

export const deleteNoteSuccess = (message) => ({
  type: DELETE_NOTE_SUCCESS,
  payload: message,
});

export const deleteNoteFailure = (error) => ({
  type: DELETE_NOTE_FAILURE,
  payload: error,
});

export const resetNoteMessage = () => ({
  type: RESET_NOTE_MESSAGE,
});
