import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux"; // Import useDispatch hook
import { Row, Container, Input, Col } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import GreetingCard from "../../Components/Jobseeker/Dashboard/GreetingCard";
import WidgetsWrapper from "../../Components/Jobseeker/Dashboard/WidgetsWraper";
import TopActiveJobs from "../../Components/Jobseeker/Dashboard/TopActiveJobs";
import Acquisitions from "../../Components/Jobseeker/Dashboard/Acquisitions";
import AppliedJobDataTable from "../../Components/Jobseeker/Dashboard/AppliedJobDataTable";
import NewApplicants from "../../Components/Jobseeker/Dashboard/NewApplicants";
import Carousels from "../../Components/Jobseeker/Dashboard/Carousels";
import { fetchDashboardDataRequest } from "../../redux/jobseeker/dashboard/actions";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import Congratulations from "../../Components/Jobseeker/Dashboard/Congratulations";
import { DateRange } from "react-date-range";
import { getTotalPointsRequest } from "../../redux/get_total_points/actions";

const DashboardJobseekerPage = () => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const dateRangePickerRef = useRef(null);
  // Call dispatch inside any event handler or useEffect hook
  useEffect(() => {
    dispatch(fetchDashboardDataRequest());
  }, []); // Make sure to add dispatch to the dependency array if you are using it inside useEffect

  const locale = {
    localize: {
      month: (monthIndex) => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return months[monthIndex];
      },
      day: (dayIndex) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[dayIndex];
      },
    },
    formatLong: {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  };

  const handleToggleDateRangePicker = () => {
    setIsDateRangePickerOpen(!isDateRangePickerOpen);
  };

  const handleDateRangePickerClose = () => {
    setIsDateRangePickerOpen(false);
  };

  return (
    <Fragment>
      <Breadcrumbs title="Dashboard" mainTitle="Dashboard" />
      <Container fluid={true}>
        <Row className="widget-grid position-relative">
          {/* <Col
            xxl="12"
            lg="12"
            className="box-col-12 d-flex justify-content-end"
          >
            <Input
              className="mb-3 form-control custom-input-width"
              type="text"
              placeholder="Select date range"
              onFocus={handleToggleDateRangePicker}
              readOnly // Prevent typing in the input field
              style={{ width: "150px" }} // Adjust the width as needed
            />
            {isDateRangePickerOpen && (
              <div
                ref={dateRangePickerRef}
                className="position-absolute"
                style={{ zIndex: "9999", top: "38px", right: "0" }}
              >
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => {
                    if (item.selection) {
                      setSelectedDateRange([item.selection]);
                    }
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={selectedDateRange}
                  locale={{
                    ...locale, // Spread the locale object here
                    format: "MM/dd/yyyy",
                    separator: " - ",
                    applyLabel: "Apply",
                    cancelLabel: "Cancel",
                    weekStartsOn: 0,
                  }}
                  onClose={handleDateRangePickerClose}
                />
              </div>
            )}
          </Col> */}
          <Col xl="4" md="12" lg="12">
            <Row>
              <Col xl="12" md="6" lg="6">
                <Congratulations />
              </Col>
              <Col xl="12" md="6" lg="6">
                <GreetingCard />
              </Col>
            </Row>
          </Col>
          <Col xl="8" lg="12" md="12" sm="12" className="">
            <WidgetsWrapper />
          </Col>
          <Col sm="12">
            <Carousels />
          </Col>
          <Col xl="12" lg="12" md="12" sm="12" className="">
            <AppliedJobDataTable />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DashboardJobseekerPage;
