import React, { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getTotalPointsRequest } from "../redux/get_total_points/actions";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../Components/Common/Toast";
import { fetchRewardsPointsRequest } from "../redux/fetch_rewards_points/actions";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ type: "", isLoggedin: false });
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("_token");
    if (token) {
      console.log("auth context");
      const userData = JSON.parse(localStorage.getItem("user_data"));
      setUser({ type: userData.type, isLoggedin: true });
    }
  }, [localStorage.getItem("_token")]);

  const logout = () => {
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    localStorage.removeItem("user_data");
    localStorage.removeItem("_token");
    setUser({ type: "", isLoggedin: false });
    <Navigate
      to={`${process.env.PUBLIC_URL}/${user_data.type.toLowerCase()}`}
      replace
    />;
  };
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    dispatch(getTotalPointsRequest());
  }, []);

  const getTotalPointsReducerData = useSelector(
    (state) => state.getTotalPointsReducer
  );

  useEffect(() => {
    if (
      getTotalPointsReducerData &&
      !getTotalPointsReducerData.isLoading &&
      getTotalPointsReducerData.total_points
    ) {
      setTotalPoints(getTotalPointsReducerData.total_points);
    }
  }, [getTotalPointsReducerData]);

  const fetchRewardsPointsReducerData = useSelector(
    (state) => state.fetchRewardsPointsReducer
  );
  useEffect(() => {
    if (
      !fetchRewardsPointsReducerData ||
      !fetchRewardsPointsReducerData.data ||
      !Array.isArray(fetchRewardsPointsReducerData.data) ||
      fetchRewardsPointsReducerData.data.length <= 0
    ) {
      dispatch(fetchRewardsPointsRequest());
    }
  }, []);

  const triggerTotalPoints = (
    actionType,
    increment = true,
    showToastMessage = true,
    numberOfUploads = 1 // Default to 1 if not provided
  ) => {
    const actionItem = fetchRewardsPointsReducerData.data.find(
      (item) => item.type === actionType
    );

    if (actionItem) {
      let actionPoints = parseInt(actionItem.points, 10);

      // If the action type is bulkUpload, multiply the points by the number of uploads
      if (actionType === "bulk_upload") {
        actionPoints *= numberOfUploads;
      }

      const newTotalPoints = increment
        ? +totalPoints + +actionPoints
        : +totalPoints - +actionPoints;
      setTotalPoints(newTotalPoints);
      console.log("newTotalPoints", totalPoints, actionPoints, newTotalPoints);
      if (showToastMessage) {
        showToast(
          "success",
          `${actionPoints} points ${
            increment ? "added to" : "deducted from"
          } your account for ${actionType.replace(/_/g, " ")}!`
        );
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logout,
        totalPoints,
        triggerTotalPoints,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
