import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Collapse,
  Input,
  Form,
} from "reactstrap";
import { YourCandidateSearchContext } from "../../../../YourCandidateSearch/YourCandidateSearchContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchIndustryRequest } from "../../../../redux/industryType/actions";

const Industry = () => {
  const dispatch = useDispatch();
  const { filters, updateFilters } = useContext(YourCandidateSearchContext); // Update to use IndustryContext
  const industryReducerData =
    useSelector((state) => state.industryTypeReducer) || [];
  let industryData = [];
  if (industryReducerData.data.data && !industryReducerData.isLoading) {
    industryData = industryReducerData.data.data;
  }
  // console.log("ird", industryData);

  const [isFilter, setIsFilter] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    // Fetch industries when component mounts
    dispatch(fetchIndustryRequest());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
    // Dispatch fetchIndustryRequest with the search keyword
    dispatch(fetchIndustryRequest(keyword));
  };

  const handleCheckboxChange = (industry) => {
    // Check if the option's value already exists in job_type array
    const existingOption = filters.industry_type.find(
      (item) => item.value === industry.value
    );

    // Toggle the selected state for the clicked job type
    let updatedIndustryType = [];
    if (existingOption) {
      updatedIndustryType = filters.industry_type.filter(
        (item) => item.value !== industry.value
      );
    } else {
      updatedIndustryType = [...filters.industry_type, industry];
    }

    // Update the job types filter in the context with updated array
    updateFilters({ industry_type: updatedIndustryType });
  };

  // Merge and sort the industries to show selected ones first
  const mergedIndustryData = [...industryData];
  filters.industry_type.forEach((filterIndustry) => {
    if (
      !mergedIndustryData.some(
        (industry) => industry.value === filterIndustry.value
      )
    ) {
      mergedIndustryData.push(filterIndustry);
    }
  });

  const sortedIndustryData = mergedIndustryData.sort((a, b) => {
    const isSelectedA = filters.industry_type.some(
      (item) => item.value === a.value
    );
    const isSelectedB = filters.industry_type.some(
      (item) => item.value === b.value
    );
    return isSelectedA === isSelectedB ? 0 : isSelectedA ? -1 : 1;
  });

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Industry Type</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="animate-chk border-0 ">
              <Form className="theme-form">
                <div className="job-filter mb-3">
                  <div className="faq-form">
                    <Input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Search industry type.."
                      value={searchKeyword}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Form>
              {industryReducerData.isLoading ? (
                <div>Loading...</div>
              ) : (
                <div
                  className="industry-checkbox custom-scrollbar"
                  style={{
                    maxHeight: "200px", // Adjust the maximum height as needed
                    overflowY: "auto",
                  }}
                >
                  {sortedIndustryData.length > 0 ? (
                    sortedIndustryData.map((industry, index) => (
                      <label
                        key={index}
                        className="d-flex"
                        // htmlFor={`chk-ani${index}`}
                      >
                        <Input
                          className="checkbox_animated mr-2"
                          id={`chk-ani${index}`}
                          type="checkbox"
                          checked={filters.industry_type.some(
                            (item) => item.value === industry.value
                          )}
                          onChange={() => handleCheckboxChange(industry)}
                        />{" "}
                        {industry.label}
                      </label>
                    ))
                  ) : (
                    <p>No data found</p>
                  )}
                  {/* {industryData.map((industry, index) => (
                    <label
                      key={index}
                      className="d-flex"
                      htmlFor={`chk-ani${index}`}
                    >
                      <Input
                        className="checkbox_animated mr-2"
                        id={`chk-ani${index}`}
                        type="checkbox"
                        checked={filters.industry_type.includes(industry.value)}
                        onChange={() => handleCheckboxChange(industry)}
                      />{" "}
                      {industry.label}
                    </label>
                  ))} */}
                </div>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Industry;
