import {
  FETCH_DETAIL_REPORT_REQUEST,
  FETCH_DETAIL_REPORT_SUCCESS,
  FETCH_DETAIL_REPORT_FAILURE,
  DOWNLOAD_RECRUITER_DETAILS_CSV_REQUEST,
  DOWNLOAD_RECRUITER_DETAILS_CSV_SUCCESS,
  DOWNLOAD_RECRUITER_DETAILS_CSV_FAILURE,
} from "../../actionTypes";

export const fetchDetailReportRequest = (data) => {
  return {
    type: FETCH_DETAIL_REPORT_REQUEST,
    payload: data,
  };
};
export const fetchDetailReportSuccess = (jobSources) => {
  return {
    type: FETCH_DETAIL_REPORT_SUCCESS,
    payload: jobSources,
  };
};

export const fetchDetailReportFailure = (error) => {
  return {
    type: FETCH_DETAIL_REPORT_FAILURE,
    payload: error,
  };
};

export const downloadRecruiterDetailsCSVRequest = (data) => {
  return {
    type: DOWNLOAD_RECRUITER_DETAILS_CSV_REQUEST,
    payload: data,
  };
};
export const downloadRecruiterDetailsCSVSuccess = (jobSources) => {
  return {
    type: DOWNLOAD_RECRUITER_DETAILS_CSV_SUCCESS,
    payload: jobSources,
  };
};

export const downloadRecruiterDetailsCSVFailure = (error) => {
  return {
    type: DOWNLOAD_RECRUITER_DETAILS_CSV_FAILURE,
    payload: error,
  };
};
