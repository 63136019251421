// reducer.js
import {
  SCAN_RESUME_REQUEST,
  SCAN_RESUME_SUCCESS,
  SCAN_RESUME_FAILURE,
  SCAN_RESUME_RESET,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  message: "",
  data: [],
  status: false,
};

const scanResumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCAN_RESUME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SCAN_RESUME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        message: "",
        status: action.payload.status,
        resume_result: action.payload.resume_result,
      };
    case SCAN_RESUME_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case SCAN_RESUME_RESET:
      return {
        ...state,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default scanResumeReducer;
