import * as types from "../../actionTypes";

export const addWorkExpRequest = (data) => ({
  type: types.ADD_WORK_EXPERIENCE_REQUEST,
  payload: data,
});

export const addWorkExpSuccess = (data) => ({
  type: types.ADD_WORK_EXPERIENCE_SUCCESS,
  payload: data,
});

export const addWorkExpFailure = (error) => ({
  type: types.ADD_WORK_EXPERIENCE_FAILURE,
  payload: error,
});

export const deleteWorkExpRequest = (data) => ({
  type: types.DELETE_WORK_EXPERIENCE_REQUEST,
  payload: data,
});

export const deleteWorkExpSuccess = (message) => ({
  type: types.DELETE_WORK_EXPERIENCE_SUCCESS,
  payload: message,
});

export const deleteWorkExpFailure = (error) => ({
  type: types.DELETE_WORK_EXPERIENCE_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA,
});
