import {
  UPDATE_COMMUNICATION_REQUEST,
  UPDATE_COMMUNICATION_SUCCESS,
  UPDATE_COMMUNICATION_FAILURE,
  RESET_COMMUNICATION_MESSAGE,
} from "../../actionTypes";

// action creators
export const updateCommunicationRequest = (data) => ({
  type: UPDATE_COMMUNICATION_REQUEST,
  payload: data,
});

export const updateCommunicationSuccess = (message) => ({
  type: UPDATE_COMMUNICATION_SUCCESS,
  payload: message,
});

export const updateCommunicationFailure = (error) => ({
  type: UPDATE_COMMUNICATION_FAILURE,
  payload: error,
});

export const resetCommunicationMessage = () => ({
  type: RESET_COMMUNICATION_MESSAGE,
});
