import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Badge,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import ReactApexChart from "react-apexcharts";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone-uploader";
import { showToast } from "../../Components/Common/Toast";
import {
  scanResumeRequest,
  scanResumeReset,
} from "../../redux/jobseeker/scan_resume/actions";
import { useRef } from "react";
import { useNavigate } from "react-router";
const ResumeScanResult = () => {
  const [percentage, setPercentage] = useState(0);
  const [scanning, setScanning] = useState(false);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.scanResumeReducer.isLoading); // Get loading state from Redux

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("resume", file);
      console.log("formDataww", formData);
      handleRescan(formData);
    }
  };

  const handleRescan = (formData) => {
    setScanning(true);
    dispatch(scanResumeRequest(formData)); // Dispatch the action to scan resume
  };

  const chartOptions = {
    chart: {
      type: "radialBar",
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "40%",
        },
        track: {
          background: "#f2f2f2",
          strokeWidth: "100%",
          margin: 15,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: "30px",
            offsetY: 10,
            color: "#333",
          },
        },
      },
    },
    colors: ["#6280FF"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#87D4F9"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.8,
        stops: [0, 100],
      },
    },
  };

  const chartSeries = useMemo(() => [percentage], [percentage]);

  // Static data from scanResumeReducer
  const scanResumeData = useSelector((state) => state.scanResumeReducer);

  useEffect(() => {
    if (scanResumeData.status == true) {
      //console.log("Percentage", scanResumeData.resume_result); // Add this line
      // showToast("success", scanResumeData.message);
      setPercentage(scanResumeData.resume_result);
      //  dispatch(scanResumeReset());
    } else if (scanResumeData.status == false && scanResumeData.message != "") {
      showToast("error", scanResumeData.message);
      dispatch(scanResumeReset());
    }
  }, [scanResumeData]);

  // Destructuring the data
  const {
    dates_data = { error: "", message: "", data: [] },
    education_details = { error: "", message: "", data: [] },
    email_id = { error: "", message: "", data: [] },
    image = { error: "", message: "", image_count: 0 },
    job_title_match_according_to_skills = { error: "", message: "", data: [] },
    job_titles_that_are_present_in_resume = {
      error: "",
      message: "",
      data: [],
    },
    name = { error: "", message: "", data: "" },
    page_format_details = {
      font_details: {
        error: "",
        message: "",
        font_count: 0,
        font_name: [],
        font_style_details: {},
      },
      page_count: { error: "", message: "", data: "" },
      page_size: { error: "", message: "", data: "" },
    },
    phone_no = { error: "", message: "", data: [] },
    required_job_titles_according_to_job_description = {
      error: "",
      message: "",
      data: [],
    },
    resume_score = 0,
    section_headings = {
      error: "",
      message: "",
      data: { abstract_section_headings: [], main_section_headings: [] },
    },
    skills = {
      hard_skills_found_in_resume: {},
      soft_skills_found_in_resume: {},
    },
    words_present = { error: "", message: "", word_count: "" },
  } = scanResumeData?.data?.analysis_details || {};

  const [validationMessages, setValidationMessages] = useState([]);

  const handlePowerEditClick = () => {
    // Loop through the analysis details to find errors
    const validationMessages = [];
    Object.keys(scanResumeData.data.analysis_details).forEach((key) => {
      const section = scanResumeData.data.analysis_details[key];
      if (section.error === "Yes") {
        validationMessages.push({
          field: key, // Add the field name
          message: section.message, // Add the message
        });
      }
    });

    // Set the validation messages in state
    setValidationMessages(validationMessages);

    // Navigate to the edit page, passing the validationMessages as a state
    navigate("/job/public/edit_resume/MQ==", { state: { validationMessages } });
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Resume Scan Result" title="ResumeScanResult" />
      <Container fluid={true}>
        <Row className="mb-5">
          <Col xs="12" md="3">
            <Card className="bg-white h-70">
              <CardBody className="text-center ">
                <h5 className="card-title">RESUME RESULT</h5>
                <div style={{ width: "200px", margin: "0 auto" }}>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="radialBar"
                    height={250}
                  />
                </div>
                <br />
                <div className="">
                  <Button
                    className="mb-2 w-80"
                    color="primary"
                    type="submit"
                    onClick={() => fileInputRef.current.click()}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          size="sm"
                          color="light"
                          style={{ marginRight: "8px" }}
                        />{" "}
                        {/* Adjusting right margin */}
                        Scanning...
                      </>
                    ) : (
                      "Upload & Rescan"
                    )}
                  </Button>{" "}
                  <Button
                    color="secondary"
                    className="w-80"
                    onClick={handlePowerEditClick}
                  >
                    Power Edit
                  </Button>
                </div>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".pdf,.docx,.doc"
                />
              </CardBody>
            </Card>
          </Col>

          <Col xs="12" md="9">
            <Card className="bg-white h-100">
              <CardBody>
                <h3 className="card-title">Searchability</h3>
                <hr />
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>NAME</strong>
                  {name.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>{name.message}</p>
                  <span style={{ width: "40%" }}>{name.data}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>EMAIL ID</strong>
                  {email_id.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>{email_id.message}</p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {email_id.data.map((item, index) => (
                      <li key={index} style={{ padding: 0, margin: 0 }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>PHONE NO</strong>
                  {phone_no.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>{phone_no.message}</p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {phone_no.data.map((item, index) => (
                      <li key={index} style={{ padding: 0, margin: 0 }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>IMAGE</strong>
                  {image.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>{image.message}</p>
                  <span style={{ width: "40%" }}>
                    Count: {image.image_count}
                  </span>
                </div>

                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>PAGE FORMAT DETAILS</strong>
                  {page_format_details.font_details.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {page_format_details.font_details.message}
                  </p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <li>
                      <strong>Font Count:</strong>{" "}
                      {page_format_details.font_details.font_count}
                    </li>
                    <li>
                      <strong>Font Names:</strong>{" "}
                      {page_format_details.font_details.font_name.join(", ")}
                    </li>
                    <li>
                      <strong>Bold Style:</strong>{" "}
                      {
                        page_format_details.font_details.font_style_details
                          .bold_style_percentage
                      }
                    </li>
                    <li>
                      <strong>Italic Style:</strong>{" "}
                      {
                        page_format_details.font_details.font_style_details
                          .italic_style_percentage
                      }
                    </li>
                    <li>
                      <strong>Underline Style:</strong>{" "}
                      {
                        page_format_details.font_details.font_style_details
                          .underline_style_percentage
                      }
                    </li>
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>PAGE SIZE</strong>
                  {page_format_details.page_size.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {page_format_details.page_size.message}
                  </p>
                  <span style={{ width: "40%" }}>
                    {page_format_details.page_size.data}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>PAGE COUNT</strong>
                  {page_format_details.page_count.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {page_format_details.page_count.message}
                  </p>
                  <span style={{ width: "40%" }}>
                    {page_format_details.page_count.data}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>DATES DATA</strong>
                  {dates_data.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {dates_data.message}
                  </p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {dates_data.data.map((item, index) => (
                      <li key={index} style={{ padding: 0, margin: 0 }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>EDUCATION DETAILS</strong>
                  {education_details.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {education_details.message}
                  </p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {education_details.data.map((item, index) => (
                      <li key={index} style={{ padding: 0, margin: 0 }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>REQUIRED JOB TITLES</strong>
                  {required_job_titles_according_to_job_description.error ===
                  "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {required_job_titles_according_to_job_description.message}
                  </p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {required_job_titles_according_to_job_description.data.map(
                      (item, index) => (
                        <li key={index} style={{ padding: 0, margin: 0 }}>
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>JOB TITLE MATCH</strong>
                  {job_title_match_according_to_skills.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {job_title_match_according_to_skills.message}
                  </p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {job_title_match_according_to_skills.data.map(
                      (item, index) => (
                        <li key={index} style={{ padding: 0, margin: 0 }}>
                          <div>{item.title}</div>
                          {/* <div>
                          <strong>Required Exp:</strong> {item.required_exp}
                        </div>
                        <div>
                          <strong>Score:</strong> {item.score}
                        </div>
                        <div>
                          <strong>Skills:</strong> {item.skills}
                        </div> */}
                        </li>
                      )
                    )}
                  </ul>
                </div>

                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>JOB TITLES IN RESUME</strong>
                  {job_titles_that_are_present_in_resume.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {job_titles_that_are_present_in_resume.message}
                  </p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {job_titles_that_are_present_in_resume.data.map(
                      (item, index) => (
                        <li key={index} style={{ padding: 0, margin: 0 }}>
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                </div>

                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>RESUME SCORE</strong>
                  <FaCheck color="green" />
                  <p style={{ width: "30%", margin: 0 }}>
                    Resume score is {resume_score}
                  </p>
                  <span style={{ width: "40%" }}>{resume_score}</span>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                <strong style={{ width: "20%" }}>SECTION HEADINGS</strong>
                {section_headings.error === "Yes" ? (
                  <ImCross color="red" />
                ) : (
                  <FaCheck color="green" />
                )}
                <p style={{ width: "30%", margin: 0 }}>
                  {section_headings.message}
                </p>
                <ul
                  style={{
                    width: "40%",
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <li>
                    <strong>Abstract Headings:</strong>{" "}
                    {section_headings.data.abstract_section_headings.join(", ")}
                  </li>
                  <li>
                    <strong>Main Headings:</strong>{" "}
                    {section_headings.data.main_section_headings.join(", ")}
                  </li>
                </ul>
              </div> */}

                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>SKILLS</strong>
                  <FaCheck color="green" />
                  <p style={{ width: "30%", margin: 0 }}>
                    Skills data is available
                  </p>
                  <ul
                    style={{
                      width: "40%",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <li>
                      <strong style={{ minWidth: 90 }}>Hard Skills:</strong>{" "}
                      {Object.keys(skills.hard_skills_found_in_resume).map(
                        (skill, index) => (
                          <Badge
                            key={index}
                            className="me-2 mt-2 border rounded text-black"
                            color="gray"
                            pill
                          >
                            {skill}
                          </Badge>
                        )
                      )}
                    </li>
                    <li>
                      <strong>Soft Skills:</strong>{" "}
                      {Object.keys(skills.soft_skills_found_in_resume).map(
                        (skill, index) => (
                          <Badge
                            key={index}
                            className="me-2 mt-2 border rounded text-black"
                            color="gray"
                            pill
                          >
                            {skill}
                          </Badge>
                        )
                      )}
                    </li>
                  </ul>
                </div>

                <div className="d-flex align-items-center justify-content-between py-2 border-bottom">
                  <strong style={{ width: "20%" }}>WORD COUNT</strong>
                  {words_present.error === "Yes" ? (
                    <ImCross color="red" />
                  ) : (
                    <FaCheck color="green" />
                  )}
                  <p style={{ width: "30%", margin: 0 }}>
                    {words_present.message}
                  </p>
                  <span style={{ width: "40%" }}>
                    {words_present.word_count}
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ResumeScanResult;
