import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import logoWhite from "../../assets/images/logo/logo.png";
import { USER_TYPES } from "../../Constant";
import {
  resetPasswordRequest,
  reset,
} from "../../redux/auth/reset_password/actions";
import { showToast } from "../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
const ResetPwd = () => {
  const dispatch = useDispatch();
  const { type, token } = useParams();
  const navigate = useNavigate();

  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleRetypePassword, setToggleRetypePassword] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const isValidLoginType = USER_TYPES.includes(type.toUpperCase());

  useEffect(() => {
    if (!isValidLoginType) {
      navigate("/404");
    }
  }, [isValidLoginType, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formattedData = {
        password: formData.password,
        confirm_password: formData.confirmPassword,
        type: type.toUpperCase(),
        token,
      };
      dispatch(resetPasswordRequest(formattedData));
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!/[a-z]/.test(formData.password)) {
      errors.password = "Password must contain at least one lowercase letter";
      isValid = false;
    } else if (!/[A-Z]/.test(formData.password)) {
      errors.password = "Password must contain at least one uppercase letter";
      isValid = false;
    } else if (!/\d/.test(formData.password)) {
      errors.password = "Password must contain at least one digit";
      isValid = false;
    } else if (!/[^a-zA-Z\d]/.test(formData.password)) {
      errors.password = "Password must contain at least one special character";
      isValid = false;
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const resetPasswordReducerData = useSelector(
    (state) => state.resetPasswordReducer
  );
  console.log("resetPasswordReducerData", resetPasswordReducerData);
  useEffect(() => {
    if (
      resetPasswordReducerData.status &&
      resetPasswordReducerData.message !== ""
    ) {
      showToast("success", resetPasswordReducerData.message);
      // dispatch(reset());
      // Optional delay before redirection
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/${type}/auth/login/`);
      }, 2000); // Adjust the delay time as needed
    } else if (
      resetPasswordReducerData.status === false &&
      resetPasswordReducerData.message !== ""
    ) {
      showToast("error", resetPasswordReducerData.message);
      dispatch(reset());
    }
  }, [resetPasswordReducerData, type]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Container fluid className="p-0 login-page">
        <Row className="m-0">
          <Col xl="12 p-0">
            <div className="login-card">
              <div>
                <Link className="logo" to={process.env.PUBLIC_URL}>
                  <img
                    className="img-fluid for-light"
                    src={logoWhite}
                    alt="loginpage"
                  />
                </Link>
                <div className="login-main">
                  <Form
                    className="theme-form login-form"
                    onSubmit={handleSubmit}
                  >
                    <h4>Create Your Password</h4>
                    <FormGroup className="position-relative">
                      <Label className="m-0 col-form-label">New Password</Label>
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          placeholder="********"
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <span className={togglePassword ? "" : "show"}></span>
                        </div>
                      </div>
                      {errors.password && (
                        <small className="text-danger">{errors.password}</small>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="m-0 col-form-label">
                        Retype Password
                      </Label>
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          type={toggleRetypePassword ? "text" : "password"}
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          placeholder="********"
                        />{" "}
                        <div
                          className="show-hide"
                          onClick={() =>
                            setToggleRetypePassword(!toggleRetypePassword)
                          }
                        >
                          <span
                            className={toggleRetypePassword ? "" : "show"}
                          ></span>
                        </div>
                      </div>
                      {errors.confirmPassword && (
                        <small className="text-danger">
                          {errors.confirmPassword}
                        </small>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Button
                        className="d-block w-100"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </FormGroup>
                    <p className="mb-0">
                      Don't have an account?
                      <Link
                        className="ps-2"
                        to={`${process.env.PUBLIC_URL}/auth/register`}
                      >
                        Create Account
                      </Link>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default ResetPwd;
