import React from "react";
import "./Template2.css"; // Assuming you have a CSS file for styling
import {
  capitalize,
  capitalizeEachWord,
  formatDate,
  capitalizeFirstLetter,
} from "../../../Common/CommonFunction";
import { educationLevelsOptions } from "../../../../Constant";

const Template2 = ({ jobseekerData = {} }) => {
  const emptyField = (str) => (str === "" || str === null ? "-" : str);
  const calculateProgressValue = (rating) => (rating / 10) * 100;

  const {
    jobseeker_full_name = "",
    jobseeker_preferred_job_title = "",
    resume_headline = "",
    work_experience = [],
    education = [],
    it_skills = [],
    skill = [],
    work_sample = [],
  } = jobseekerData;

  return (
    <div className="resume2" id="resume2">
      <div className="resume-container">
        <div className="border-top"></div>
        <header className="header">
          <h1>{emptyField(capitalizeFirstLetter(jobseeker_full_name))}</h1>
          <p>{emptyField(capitalizeEachWord(jobseeker_preferred_job_title))}</p>
        </header>

        <div className="content">
          <aside className="left-section">
            {resume_headline && (
              <section className="profile">
                <h2>PROFILE</h2>
                <p>{resume_headline}</p>
              </section>
            )}

            {skill.length > 0 && (
              <section className="skills">
                <h2>SKILLS</h2>
                <ul>
                  {skill.map((skill, index) => (
                    <li key={index}>
                      <span>{capitalize(skill.label)}</span>
                    </li>
                  ))}
                </ul>
              </section>
            )}

            {it_skills.length > 0 && (
              <section className="skills">
                <h2>MAIN SKILLS</h2>
                <ul>
                  {it_skills
                    .sort((a, b) => b.skill_rating - a.skill_rating)
                    .map((skill, index) => (
                      <li key={index}>
                        <span>{capitalize(skill.it_skill.label)}</span>
                        <div className="progress-bar">
                          <div
                            className="progress"
                            style={{
                              width: `${calculateProgressValue(
                                skill.skill_rating
                              )}%`,
                            }}
                          ></div>
                        </div>
                      </li>
                    ))}
                </ul>
              </section>
            )}
          </aside>

          <section className="right-section">
            {work_experience.length > 0 && (
              <section className="work-experience">
                <h2>WORK EXPERIENCE</h2>
                {work_experience.map((job, index) => (
                  <div className="job" key={index}>
                    <div>{`${capitalizeEachWord(
                      job.designation
                    )} At ${capitalizeEachWord(
                      job.organization.label
                    )} (${formatDate(job.started_working_from)} - ${formatDate(
                      job.worked_till
                    )})`}</div>
                    <p>{capitalize(job.job_description)}</p>
                  </div>
                ))}
              </section>
            )}

            {education.length > 0 && (
              <section className="education">
                <h2>EDUCATION</h2>
                {education.map((edu, index) => (
                  <div key={index} className="job">
                    <div>
                      {
                        educationLevelsOptions.find(
                          (option) => option.value === edu.education_level
                        )?.label
                      }{" "}
                      - {edu.passing_year}
                    </div>
                    <p>
                      {capitalize(edu.board)}
                      <br />
                      Percentage: {edu.performance}%
                    </p>
                  </div>
                ))}
              </section>
            )}

            {work_sample.length > 0 && (
              <section className="achievements">
                <h2>WORK SAMPLE</h2>
                {work_sample.map((work, index) => (
                  <div className="job" key={index}>
                    <div>{`${capitalizeEachWord(work.work_title)} (${formatDate(
                      work.work_start
                    )} - ${formatDate(work.work_end)})`}</div>
                    <p>
                      <a href={work.work_url}>{work.work_url}</a>
                    </p>
                    <p>{capitalize(work.work_description)}</p>
                  </div>
                ))}
              </section>
            )}
          </section>
        </div>

        <div className="border-bottom"></div>
      </div>
    </div>
  );
};

export default Template2;
