import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Collapse,
  Input,
  Form,
} from "reactstrap";
import { fetchLocationsRequest } from "../../../../redux/location/actions";
import { JobSearchContext } from "../../../../JobSearch/JobSearchContext";

const Location = () => {
  const dispatch = useDispatch();
  const { filters, updateFilters } = useContext(JobSearchContext);

  const locationReducerData =
    useSelector((state) => state.locationReducer) || [];
  let locationData = [];
  if (!locationReducerData.isLoading && locationReducerData.data.data) {
    locationData = locationReducerData.data.data;
  }

  const [isFilter, setIsFilter] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    // Fetch locations when component mounts
    dispatch(fetchLocationsRequest());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
    // Dispatch fetchLocations with the search keyword
    dispatch(fetchLocationsRequest(keyword));
  };

  const handleCheckboxChange = (area) => {
    // Check if the option's value already exists in job_type array
    const existingOption = filters.locations.find(
      (item) => item.value === area.value
    );

    // Toggle the selected state for the clicked job type
    let updatedLocations = [];
    if (existingOption) {
      updatedLocations = filters.locations.filter(
        (item) => item.value !== area.value
      );
    } else {
      updatedLocations = [...filters.locations, area];
    }

    // Update the job types filter in the context with updated array
    updateFilters({ locations: updatedLocations });
  };

  // Merge and sort the locations to show selected ones first
  const mergedLocationData = [...locationData];
  filters.locations.forEach((filterLocation) => {
    if (
      !mergedLocationData.some(
        (location) => location.value === filterLocation.value
      )
    ) {
      mergedLocationData.push(filterLocation);
    }
  });

  const sortedLocationData = mergedLocationData.sort((a, b) => {
    const isSelectedA = filters.locations.some(
      (item) => item.value === a.value
    );
    const isSelectedB = filters.locations.some(
      (item) => item.value === b.value
    );
    return isSelectedA === isSelectedB ? 0 : isSelectedA ? -1 : 1;
  });

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Location</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="animate-chk border-0">
              <Form className="theme-form">
                <div className="job-filter mb-3">
                  <div className="faq-form">
                    <Input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Search location.."
                      value={searchKeyword}
                      onChange={handleInputChange}
                    />
                    <i className="search-icon" data-feather="map-pin"></i>
                  </div>
                </div>
              </Form>

              {locationReducerData.isLoading ? (
                <div>Loading...</div>
              ) : (
                <div
                  className="location-checkbox custom-scrollbar"
                  style={{
                    maxHeight: "200px", // Adjust the maximum height as needed
                    overflowY: "auto",
                  }}
                >
                  {sortedLocationData.length > 0 ? (
                    sortedLocationData.map((location, index) => (
                      <label
                        key={index}
                        className="d-flex"
                        //  htmlFor={`chk-ani${index}`}
                      >
                        <Input
                          className="checkbox_animated"
                          id={`chk-ani${index}`}
                          type="checkbox"
                          checked={filters.locations.some(
                            (item) => item.value === location.value
                          )}
                          onChange={() => handleCheckboxChange(location)}
                        />{" "}
                        {location.label}
                      </label>
                    ))
                  ) : (
                    <p>No data found</p>
                  )}
                </div>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Location;
