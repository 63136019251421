import { React, useCallback, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Badge,
  FormGroup,
  Input,
  Label,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { publicJobsourceOptions } from "../../../Constant";
import {
  publicApplyJobRequest,
  publicApplyJobReset,
} from "../../../redux/public/public_apply_job/actions";
import { fetchLocationsRequest } from "../../../redux/location/actions";
import { showToast } from "../../../Components/Common/Toast";
import { useParams, useNavigate } from "react-router-dom";
import {
  customOptionsStyle,
  monthsOptions,
  experienceOptions,
} from "../../../Constant";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { extractAfterLastHyphen } from "../../Common/CommonFunction";
import { jobsourceOptions } from "../../../Constant";
import validator from "validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import styles directly from react-phone-input-2
import { convertNumberToWords } from "../../Common/CommonFunction";
import { fetchEducationRequest } from "../../../redux/education/actions";
const ApplyNowModal = (props) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const [showReferralInput, setShowReferralInput] = useState(false);

  const navigate = useNavigate();
  const [locationOptions, setLocationOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [formData, setFormData] = useState({
    full_name: "",
    email1: "",
    email2: "",
    phone1: "",
    phone2: "",
    country_code1: "91",
    country_code2: "91",
    jobseeker_location: null,
    preferred_location: null,
    relevant_experience_years: null,
    relevant_experience_months: null,
    jobseeker_notice_period: "",
    jobseeker_preferred_salary: "",
    jobseeker_current_salary: "",
    resume: null,
    job_source: {
      value: "CANDIDATE APPLYING DIRECT",
      label: "Candidate Applying Direct",
    },
    referral_email: "",
    education: [],
  });

  const [errors, setErrors] = useState({});
  const publicApplyJobReducerData = useSelector(
    (state) => state.publicApplyJobReducer
  );

  // Fetching and updating location data
  let locationReducerData = useSelector((state) => state.locationReducer);
  let locationData = locationReducerData.data.data;

  useEffect(() => {
    locationData = locationData || [];
    setLocationOptions(locationData);
  }, [locationData]);

  const handleLocationFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleLocationInputChange = useCallback((inputValue) => {
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  let educationReducerData = useSelector((state) => state.educationReducer);
  let educationData = educationReducerData.data.data;

  useEffect(() => {
    educationData = educationData || [];
    setEducationOptions(educationData);
  }, [educationData]);

  const handleEducationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchEducationRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleEducationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchEducationRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleEducationChange = (selectedOptions) => {
    const updatedErrors = { ...errors, education: "" };
    setErrors(updatedErrors);

    // Update the form state with selected values
    setFormData({ ...formData, education: selectedOptions });
  };

  const handleLocationsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, jobseeker_location: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, jobseeker_location: selectedOptions });
  };
  const handlePreferredLocationsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, preferred_location: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, preferred_location: selectedOptions });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedErrors = { ...errors, [name]: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, [name]: value });
  };

  const handleCodeChange = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code1: country.dialCode,
    });
  };
  const handleCodeChange2 = (value, country) => {
    // Extract only the phone number without the country code
    const phoneNumberWithoutCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();
    // Removing non-digit characters and ensuring country code is included
    const formattedPhoneNumber = `+${country.dialCode}${value
      .replace(/\D/g, "")
      .slice(country.dialCode.length)}`;
    setFormData({
      ...formData,

      country_code2: country.dialCode,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleJobSourceChange = (selectedOption) => {
    setFormData({
      ...formData,
      job_source: selectedOption, // Store only the value
    });

    setErrors({
      ...errors,
      job_source: "",
    });
    // Check if the selected option is "EMPLOYEE REFERRAL"
    if (selectedOption && selectedOption.value === "EMPLOYEE REFERRAL") {
      setShowReferralInput(true);
    } else {
      setShowReferralInput(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const formDataObject = new FormData();

      formDataObject.append("jobseeker_full_name", formData.full_name);
      formDataObject.append("jobseeker_email1", formData.email1);
      formDataObject.append("jobseeker_email2", formData.email2);
      formDataObject.append("jobseeker_phone1", formData.phone1);
      formDataObject.append("jobseeker_phone2", formData.phone2);
      formDataObject.append("country_code1", formData.country_code1);
      formDataObject.append("country_code2", formData.country_code2);
      formDataObject.append("referral_email", formData.referral_email);
      formDataObject.append(
        "qualification",
        formData.education
          ? formData.education.map((edu) => edu.value).join(",")
          : ""
      );
      formDataObject.append(
        "jobseeker_location",
        formData.jobseeker_location ? formData.jobseeker_location.value : ""
      );
      formDataObject.append(
        "month_exp",
        formData.relevant_experience_months
          ? formData.relevant_experience_months.value
          : ""
      );
      formDataObject.append(
        "year_exp",
        formData.relevant_experience_years
          ? formData.relevant_experience_years.value
          : ""
      );
      formDataObject.append(
        "preferred_location",
        formData.preferred_location ? formData.preferred_location.value : ""
      );
      formDataObject.append(
        "jobseeker_notice_period",
        formData.jobseeker_notice_period
      );
      formDataObject.append(
        "jobseeker_preferred_salary",
        formData.jobseeker_preferred_salary
      );
      formDataObject.append(
        "jobseeker_current_salary",
        formData.jobseeker_current_salary
      );
      formDataObject.append("resume", formData.resume);
      formDataObject.append(
        "job_source",
        formData.job_source ? formData.job_source.value : ""
      );
      formDataObject.append("job_id", extractAfterLastHyphen(id)); // Replace with the actual job ID if dynamic

      dispatch(publicApplyJobRequest(formDataObject));
    } else {
      console.error("Form submission error:", errors);
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.full_name) {
      errors.full_name = "Full name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email1) {
      errors.email1 = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.email1)) {
      errors.email1 = "Please enter valid email";
      isValid = false;
    }

    if (formData.email2 && !emailRegex.test(formData.email2)) {
      errors.email2 = "Please enter valid email";
      isValid = false;
    }

    const trimmedPhoneNumber = formData.phone1.trim();
    if (!trimmedPhoneNumber) {
      errors.phone1 = "Phone number is required";
      isValid = false;
    } else if (
      formData.country_code1 === "91" &&
      trimmedPhoneNumber.length !== 10
    ) {
      errors.phone1 = "Phone number must be 10 digits for india";
      isValid = false;
    } else if (isNaN(trimmedPhoneNumber)) {
      errors.phone1 = "Phone number must be a valid number";
      isValid = false;
    } else if (
      !validator.isMobilePhone(trimmedPhoneNumber, "any", { strictMode: false })
    ) {
      errors.phone1 = "Invalid phone number format";
      isValid = false;
    }

    const trimmedPhoneNumber2 = formData.phone2.trim();
    if (trimmedPhoneNumber2) {
      // Check if phone2 is provided
      if (
        formData.country_code2 === "91" &&
        trimmedPhoneNumber2.length !== 10
      ) {
        errors.phone2 = "Phone number must be 10 digits for India";
        isValid = false;
      } else if (isNaN(trimmedPhoneNumber2)) {
        errors.phone2 = "Phone number must be a valid number";
        isValid = false;
      } else if (
        !validator.isMobilePhone(trimmedPhoneNumber2, "any", {
          strictMode: false,
        })
      ) {
        errors.phone2 = "Invalid phone number format";
        isValid = false;
      }
    }

    if (!formData.jobseeker_location) {
      errors.jobseeker_location = "Current location is required";
      isValid = false;
    }

    if (!formData.preferred_location) {
      errors.preferred_location = "Preferred location is required";
      isValid = false;
    }

    if (
      !formData.relevant_experience_years &&
      !formData.relevant_experience_months
    ) {
      errors.relevant_experience_years =
        "Relevant Experience (either in years or months) is required";
      isValid = false;
    }

    // Validate Jobseeker Preferred Salary
    if (!formData.jobseeker_preferred_salary) {
      errors.jobseeker_preferred_salary = "Expected salary is required";
      isValid = false;
    } else if (isNaN(formData.jobseeker_preferred_salary)) {
      errors.jobseeker_preferred_salary = "Expected salary must be a number";
      isValid = false;
    }

    // Validate Jobseeker Current Salary
    if (!formData.jobseeker_current_salary) {
      errors.jobseeker_current_salary = "Current salary is required";
      isValid = false;
    } else if (isNaN(formData.jobseeker_current_salary)) {
      errors.jobseeker_current_salary = "Current salary must be a number";
      isValid = false;
    }

    // Validate notice period
    if (!formData.jobseeker_notice_period) {
      errors.jobseeker_notice_period = "Notice period is required";
      isValid = false;
    } else if (isNaN(formData.jobseeker_notice_period)) {
      errors.jobseeker_notice_period = "Notice period must be a number";
      isValid = false;
    }

    if (!formData.resume) {
      errors.resume = "Resume is required";
      isValid = false;
    }

    if (!formData.job_source) {
      errors.job_source = "Job source is required";
      isValid = false;
    }

    if (formData.job_source.value === "EMPLOYEE REFERRAL") {
      if (!formData.referral_email) {
        errors.referral_email = "Referral Email is required";
        isValid = false;
      }
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    console.log("publicApplyJobReducerData:", publicApplyJobReducerData);
    if (
      publicApplyJobReducerData.status === true &&
      publicApplyJobReducerData.message !== ""
    ) {
      showToast("success", publicApplyJobReducerData.message);
      props.toggleModal();
      dispatch(publicApplyJobReset());
    } else if (
      publicApplyJobReducerData.status === false &&
      publicApplyJobReducerData.message !== ""
    ) {
      showToast("error", publicApplyJobReducerData.message);
      props.toggleModal();
      dispatch(publicApplyJobReset());
    }
  }, [publicApplyJobReducerData, navigate, dispatch]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form onSubmit={handleSubmit} className="theme-form">
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4> Apply For This Job</h4>
        </ModalHeader>
        <ModalBody>
          <h5 className="mb-4 text-purple">Personal Information</h5>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="full_name">
                  Full Name
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="full_name"
                  id="full_name"
                  placeholder="Enter full name"
                  value={formData.full_name}
                  onChange={handleChange}
                />
                {errors.full_name && (
                  <small className="text-danger">{errors.full_name}</small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>
                  Current Location
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  name="jobseeker_location"
                  options={locationOptions}
                  isLoading={locationReducerData.isLoading}
                  placeholder="Select locations..."
                  value={formData.jobseeker_location}
                  onChange={handleLocationsChange}
                  onInputChange={handleLocationInputChange}
                  styles={customOptionsStyle}
                  onFocus={handleLocationFocus}
                />
                {errors.jobseeker_location && (
                  <small className="text-danger">
                    {errors.jobseeker_location}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Primary Phone
                  <span className="text-danger">*</span>
                </Label>
                <div className="d-flex">
                  <PhoneInput
                    style={{
                      width: "10px",
                      marginRight: "10px",
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      disabled: true,
                      // placeholder: "Enter country code",
                    }}
                    country={"in"} // Default country
                    value={formData.country_code1}
                    onChange={handleCodeChange}
                    inputStyle={{
                      width: "100px", // Adjust width to fit country code
                      height: "38px",
                      //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                      paddingLeft: "3rem",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      color: "#495057",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }}
                    enableAreaCodes={false}
                  />
                  <Input
                    className="form-control form-control-sm"
                    placeholder="Enter phone number"
                    id="phone1"
                    name="phone1"
                    value={formData.phone1}
                    onChange={handleChange}
                    style={{ marginLeft: "95px" }} // Add margin to separate the fields
                    maxLength={15} // Adjust the maximum length as per your requirement
                  />
                </div>

                {errors.phone1 && (
                  <small className="text-danger">{errors.phone1}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="email">
                  Primary Email<span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="email1"
                  id="email1"
                  placeholder="Enter email"
                  value={formData.email1}
                  onChange={handleChange}
                />
                {errors.email1 && (
                  <small className="text-danger">{errors.email1}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Secondary Phone</Label>
                <div className="d-flex">
                  <PhoneInput
                    style={{
                      width: "10px",
                      marginRight: "10px",
                    }}
                    inputProps={{
                      name: "phone",
                      autoFocus: true,
                      disabled: true,
                      // placeholder: "Enter country code",
                    }}
                    country={"in"} // Default country
                    value={formData.country_code2}
                    onChange={handleCodeChange2}
                    inputStyle={{
                      width: "100px", // Adjust width to fit country code
                      height: "38px",
                      //padding: "0.375rem 2.2rem", // Adjust padding to fit correctly
                      paddingLeft: "3rem",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      color: "#495057",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }}
                    enableAreaCodes={false}
                  />
                  <Input
                    className="form-control form-control-sm"
                    placeholder="Enter phone number"
                    id="phone2"
                    name="phone2"
                    value={formData.phone2}
                    onChange={handleChange}
                    style={{ marginLeft: "95px" }} // Add margin to separate the fields
                    maxLength={15} // Adjust the maximum length as per your requirement
                  />
                </div>

                {errors.phone2 && (
                  <small className="text-danger">{errors.phone2}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="email2">Secondary Email </Label>
                <Input
                  className="form-control form-control-sm"
                  type="text"
                  name="email2"
                  id="email2"
                  placeholder="Enter second email"
                  value={formData.email2}
                  onChange={handleChange}
                />
                {errors.email2 && (
                  <small className="text-danger">{errors.email2}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <h5 className="mb-4 text-purple">Job Preferences</h5>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label>
                  Preferred Location
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  name="preferred_location"
                  options={locationOptions}
                  isLoading={locationReducerData.isLoading}
                  placeholder="Select locations..."
                  value={formData.preferred_location}
                  onChange={handlePreferredLocationsChange}
                  onInputChange={handleLocationInputChange}
                  styles={customOptionsStyle}
                  onFocus={handleLocationFocus}
                />
                {errors.preferred_location && (
                  <small className="text-danger">
                    {errors.preferred_location}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_notice_period">
                  Notice Period
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_notice_period"
                  className="form-control form-control-sm"
                  placeholder="Notice Period"
                  onChange={handleChange}
                  value={formData.jobseeker_notice_period}
                />
                {errors.jobseeker_notice_period && (
                  <small className="text-danger">
                    {errors.jobseeker_notice_period}
                  </small>
                )}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_current_salary">
                  Current CTC <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_current_salary"
                  className="form-control form-control-sm"
                  placeholder="Current ctc"
                  onChange={handleChange}
                  value={formData.jobseeker_current_salary}
                />
                {formData.jobseeker_current_salary &&
                  !isNaN(formData.jobseeker_current_salary) && (
                    <small className="text-muted text-capitalize">
                      {convertNumberToWords(
                        parseFloat(formData.jobseeker_current_salary)
                      )}
                    </small>
                  )}
                {errors.jobseeker_current_salary && (
                  <small className="text-danger">
                    {errors.jobseeker_current_salary}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="jobseeker_preferred_salary">
                  Expected CTC <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="jobseeker_preferred_salary"
                  className="form-control form-control-sm"
                  placeholder="Expected ctc"
                  onChange={handleChange}
                  value={formData.jobseeker_preferred_salary}
                />
                {formData.jobseeker_preferred_salary &&
                  !isNaN(formData.jobseeker_preferred_salary) && (
                    <small className="text-muted text-capitalize">
                      (
                      {convertNumberToWords(
                        parseFloat(formData.jobseeker_preferred_salary)
                      )}
                      )
                    </small>
                  )}
                {errors.jobseeker_preferred_salary && (
                  <small className="text-danger">
                    {errors.jobseeker_preferred_salary}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={12}>
              <Label>
                Relevant Experience <span className="font-danger">*</span>
              </Label>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Select
                  options={experienceOptions}
                  value={formData.relevant_experience_years}
                  onChange={(selectedOption) => {
                    setFormData({
                      ...formData,
                      relevant_experience_years: selectedOption,
                    });
                    setErrors({ ...errors, relevant_experience_years: "" });
                  }}
                />{" "}
                {errors.relevant_experience_years && (
                  <small className="text-danger">
                    {errors.relevant_experience_years}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Select
                  options={monthsOptions}
                  value={formData.relevant_experience_months}
                  onChange={(selectedOption) => {
                    setFormData({
                      ...formData,
                      relevant_experience_months: selectedOption,
                    });
                    setErrors({ ...errors, relevant_experience_years: "" });
                  }}
                />
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label>Job Source</Label>
                <span className="text-danger">*</span>
                <Select
                  options={publicJobsourceOptions}
                  value={formData.job_source}
                  onChange={handleJobSourceChange}
                  placeholder="Select job source..."
                />
                {errors.job_source && (
                  <div className="invalid-feedback d-block">
                    {errors.job_source}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>
                  Education (Minimun Qualifications)
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  isMulti
                  options={educationOptions}
                  isLoading={educationReducerData.loading}
                  placeholder="Select education..."
                  value={formData.education}
                  onChange={handleEducationChange}
                  styles={customOptionsStyle}
                  onInputChange={handleEducationInputChange}
                  onFocus={handleEducationFocus}
                />

                {errors.education && (
                  <small className="text-danger">{errors.education}</small>
                )}
              </FormGroup>
            </Col>
            {showReferralInput && (
              <Col md="6">
                <FormGroup>
                  <Label>Referral Email</Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    name="referral_email"
                    className="form-control form-control-sm"
                    placeholder="Enter referral email..."
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        referral_email: e.target.value,
                      })
                    }
                    value={formData.referral_email}
                  />

                  {errors.referral_email && (
                    <div className="invalid-feedback d-block">
                      {errors.referral_email}
                    </div>
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>
          <h5 className="mb-4 text-purple">Document Upload</h5>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="relevant_experience_months">
                  CV
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="file"
                  id="resume"
                  name="resume"
                  onChange={handleFileChange}
                  invalid={!!errors.resume}
                />
                {errors.resume && (
                  <div className="invalid-feedback d-block">
                    {errors.resume}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="text-end">
          <Button
            color="secondary"
            type="submit"
            name="submit"
            className="me-1"
            disabled={publicApplyJobReducerData.isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ApplyNowModal;
