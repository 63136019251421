import {
  SCAN_RESUME_REQUEST,
  SCAN_RESUME_SUCCESS,
  SCAN_RESUME_FAILURE,
  SCAN_RESUME_RESET,
} from "../../actionTypes";

export const scanResumeRequest = (type) => ({
  type: SCAN_RESUME_REQUEST,
  payload: type,
});

export const scanResumeSuccess = (data) => ({
  type: SCAN_RESUME_SUCCESS,
  payload: data,
});

export const scanResumeFailure = (error) => ({
  type: SCAN_RESUME_FAILURE,
  payload: error,
});

export const scanResumeReset = () => ({
  type: SCAN_RESUME_RESET,
});
