// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchJobDetailsSuccess,
  fetchJobDetailsFailure,
  applyJobSuccess,
  applyJobFailure,
  jobSharingSuccess,
  jobSharingFailure,
} from "./actions";
import {
  FETCH_JOB_DETAILS_REQUEST,
  APPLY_JOB_REQUEST,
  JOB_SHARING_REQUEST,
} from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";

function* fetchJobDetails(action) {
  console.log(action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/job_details", action.payload)
    );
    yield put(fetchJobDetailsSuccess(response.data));
  } catch (error) {
    yield put(fetchJobDetailsFailure(error));
  }
}

function* applyJob(action) {
  console.log("applyJob", action.payload);
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/apply_job", action.payload)
    );
    yield put(applyJobSuccess(response.data));
  } catch (error) {
    yield put(applyJobFailure(error));
  }
}

function* jobDetailsSaga() {
  yield takeLatest(FETCH_JOB_DETAILS_REQUEST, fetchJobDetails);
  yield takeLatest(APPLY_JOB_REQUEST, applyJob);
}

export default jobDetailsSaga;
