import { React, Fragment, useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/update_profile/actions";
import {
  addWorkExpRequest,
  resetData,
} from "../../../redux/jobseeker/work_experience/actions";
import DatePicker from "react-datepicker";
import { showToast } from "../../Common/Toast";
import { fetchCompanyRequest } from "../../../redux/company/actions";
import { convertNumberToWords } from "../../Common/CommonFunction";
const Workexperiencemodal = (props) => {
  console.log("pro", props);
  const dispatch = useDispatch();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [errors, setErrors] = useState({});
  /**
   * set default form field values
   */
  const [formData, setFormData] = useState({
    seeker_id: props.data.seeker_id || "",
    work_exp_id: props.data.id || "",
    designation: props.data.designation || "",
    organization: props.data.organization || "",
    job_profile: props.data.job_description || "",
    current_company: props.data.current_company || "",
    notice_period: props.data.notice_period || "",
    started_working_from: props.data.started_working_from
      ? new Date(props.data.started_working_from)
      : null,

    worked_till: props.data.worked_till
      ? new Date(props.data.worked_till)
      : null,
    current_salary: props.data.current_salary || "",
  });

  // const handleFormData = (data) => {
  //   if (data.id === "") {
  //     dispatch(addWorkExperience(data));
  //     setTimeout(() => {
  //       dispatch(fetchJobseekerDataRequest());
  //     }, 900);
  //     setTimeout(() => {
  //       props.toggleModal();
  //     }, 1100);
  //   } else {
  //     dispatch(updateWorkExperience(data));
  //     setTimeout(() => {
  //       dispatch(fetchJobseekerDataRequest());
  //     }, 900);
  //     setTimeout(() => {
  //       props.toggleModal();
  //     }, 1100);
  //   }
  // };

  /**
   * handle form submit when all data is valid
   */

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      // Format the data before logging or dispatching

      const formattedData = {
        seeker_id: formData.seeker_id || "",
        work_exp_id: formData.work_exp_id || "",
        designation: formData.designation || "",
        organization_id: formData.organization.value || "",
        organization: formData.organization.label || "",
        is_new: formData.organization.__isNew__ || false,
        job_description: formData.job_profile || "",
        current_company: formData.current_company || "",
        notice_period:
          (formData.current_company == "YES" && formData.notice_period) || "",
        started_working_from: formData.started_working_from || "",
        worked_till: formData.worked_till || "",
        current_salary: formData.current_salary || "",
      };

      dispatch(addWorkExpRequest(formattedData));
    }
  };

  // validate form data

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.designation) {
      errors.designation = "Designation is required";
      isValid = false;
    }

    if (!formData.organization) {
      errors.organization = "Organization is required";
      isValid = false;
    }
    if (!formData.job_profile) {
      errors.job_profile = "Job profile is required";
      isValid = false;
    }
    // Check if current_company is YES
    if (formData.current_company === "YES") {
      // Validate Notice Period
      if (!formData.notice_period) {
        errors.notice_period = "Notice period is required";
        isValid = false;
      } else if (isNaN(formData.notice_period)) {
        errors.notice_period = "Notice period must be a number";
        isValid = false;
      }
    }
    if (!formData.started_working_from) {
      errors.started_working_from = "Work from is required";
      isValid = false;
    }
    if (!formData.worked_till) {
      errors.worked_till = "Work till is required";
      isValid = false;
    }
    if (!formData.current_salary) {
      errors.current_salary = "Current salary is required";
      isValid = false;
    } else if (isNaN(formData.current_salary)) {
      errors.current_salary = "Current salary must be a number";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  //handle onchange on form fields to remove errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    // Clear the error message when input changes
    let error = "";
    setErrors({ ...errors, [name]: error });

    if (name == "current_company" && value == "YES") {
      setFormData({
        ...formData,
        worked_till: new Date(),
        current_company: "YES",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // handle date chage
  const handleWorkFromDateChange = (date) => {
    const updatedErrors = { ...errors, started_working_from: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, started_working_from: date });
  };
  const handleWorkTillDateChange = (date) => {
    const updatedErrors = { ...errors, worked_till: "" };
    setErrors(updatedErrors);
    setFormData({ ...formData, worked_till: date });
  };

  const addWorkExpReducerData = useSelector((state) => state.addWorkExpReducer);
  useEffect(() => {
    console.log("srd", addWorkExpReducerData);
    if (addWorkExpReducerData.status && addWorkExpReducerData.message != "") {
      props.toggleModal();
    } else if (
      addWorkExpReducerData.status == false &&
      addWorkExpReducerData.message != ""
    ) {
      props.toggleModal();
    }
  }, [addWorkExpReducerData]);

  let companyReducerData = useSelector((state) => state.companyReducer);
  let companyData = companyReducerData.data.data;

  useEffect(() => {
    // Check if companyData is valid and not empty
    if (companyData && companyData.length > 0) {
      setCompanyOptions(companyData);
    }
  }, [companyData]); // Update state when companyData changes

  const handleCompanyFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = "";
        dispatch(fetchCompanyRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCompanyInputChange = useCallback((inputValue) => {
    const fetchData = async () => {
      try {
        dispatch(fetchCompanyRequest(inputValue));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleCompanyChange = (selectedOption) => {
    const updatedErrors = { ...errors, organization: "" };
    setErrors(updatedErrors);

    setFormData({ ...formData, organization: selectedOption });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          <h4>
            {props.data.id ? "Edit Work Experience" : "Add Work Experience"}
          </h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Designation
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="designation"
                  placeholder="Enter your designation"
                  className="form-control form-control-sm"
                  value={formData.designation}
                  onChange={handleChange}
                />
                {errors.designation && (
                  <small className="text-danger">{errors.designation}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Organization
                  <span className="text-danger">*</span>
                </Label>
                <CreatableSelect
                  isClearable
                  className=""
                  options={companyOptions}
                  isLoading={companyReducerData.isLoading}
                  placeholder="Select company..."
                  value={formData.organization}
                  onChange={handleCompanyChange}
                  onInputChange={handleCompanyInputChange}
                  // styles={{
                  //   control: (provided, state) => ({
                  //     ...provided,
                  //     backgroundColor: "#f3f3ff",
                  //     border: "1px solid #ccc",
                  //     borderRadius: "4px",
                  //     height: "46px", // Adjust height as needed
                  //     padding: "0px", // Adjust padding as needed
                  //     boxShadow: state.isFocused
                  //       ? "0 0 0 2px rgba(0, 123, 255, 0.2)"
                  //       : "none",
                  //     "&:hover": {
                  //       borderColor: "#999",
                  //     },
                  //   }),
                  // }}
                  onFocus={handleCompanyFocus}
                />
                {errors.organization && (
                  <small className="text-danger">{errors.organization}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Is this your current company?
                  <span className="text-danger">*</span>
                </Label>
                <FormGroup>
                  <Label check>
                    <Input
                      checked={formData.current_company == "YES"}
                      type="radio"
                      name="current_company"
                      value="YES"
                      onChange={handleChange}
                    />{" "}
                    Yes
                  </Label>
                  <Label check className="ms-3">
                    <Input
                      type="radio"
                      name="current_company"
                      value="NO"
                      onChange={handleChange}
                      checked={formData.current_company == "NO"}
                    />{" "}
                    No
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Started Working From
                  <span className="text-danger">*</span>
                </Label>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  className="form-control form-control-sm mb-0"
                  selected={formData.started_working_from}
                  onChange={handleWorkFromDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Enter work from"
                  dropdownMode="select"
                />
                {errors.started_working_from && (
                  <small className="text-danger">
                    {errors.started_working_from}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Worked Till
                  <span className="text-danger">*</span>
                </Label>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  className="form-control form-control-sm mb-0"
                  selected={formData.worked_till}
                  onChange={handleWorkTillDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Enter work till"
                  disabled={formData.current_company == "YES"}
                  dropdownMode="select"
                />
                {errors.worked_till && (
                  <small className="text-danger">{errors.worked_till}</small>
                )}
              </FormGroup>
            </Col>
            <Col
              md={6}
              className={formData.current_company != "YES" ? "d-none" : ""}
            >
              <FormGroup>
                <Label>
                  Notice Period (In Days) <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="notice_period"
                  className="form-control form-control-sm"
                  placeholder="Enter notice period"
                  value={formData.notice_period}
                  onChange={handleChange}
                />
                {errors.notice_period && (
                  <small className="text-danger">{errors.notice_period}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={formData.current_company != "YES" ? 6 : 12}>
              <FormGroup>
                <Label>
                  Current CTC <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="current_salary"
                  className="form-control form-control-sm"
                  placeholder="Enter current Salary"
                  value={formData.current_salary}
                  onChange={handleChange}
                />
                {formData.current_salary && !isNaN(formData.current_salary) && (
                  <small className="text-muted text-capitalize">
                    {convertNumberToWords(formData.current_salary)}
                  </small>
                )}
                {errors.current_salary && (
                  <small className="text-danger">{errors.current_salary}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>
                  Describe Your Job Profile
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="textarea"
                  name="job_profile"
                  rows="5"
                  placeholder="Enter job profile description"
                  value={formData.job_profile}
                  onChange={handleChange}
                />
                {errors.job_profile && (
                  <small className="text-danger">{errors.job_profile}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            disabled={addWorkExpReducerData.isLoading}
            color="primary"
            type="submit"
          >
            {props.data.id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default Workexperiencemodal;
