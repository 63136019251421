import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

export const registerRequest = (jobId) => ({
  type: REGISTER_REQUEST,
  payload: jobId,
});

export const registerSuccess = (jobData) => ({
  type: REGISTER_SUCCESS,
  payload: jobData,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

export const reset = () => ({
  type: RESET_JOB,
});
