import React, { Fragment } from "react";
import { Col, Row, Button, Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { downloadJobSourceCsvRequest } from "../../../redux/recruiter/job_source/actions";
import { capitalize } from "../../Common/CommonFunction";
import { jobsourceOptions } from "../../../Constant";
import NoDataFound from "../../Common/NoDataFound";

const JobSourceDatatable = ({ jobSourceData, formData }) => {
  const dispatch = useDispatch();

  // Check if jobSourceData exists and has data
  const jobData = jobSourceData?.data || {};

  if (Object.keys(jobData).length === 0) {
    return (
      <div className="mt-3">
        <NoDataFound />
      </div>
    );
  }

  const handleExport = () => {
    const obj = {
      source: formData.jobSource,
      job_id: formData.jobId,
      startDate: formData.dateRange.startDate.toISOString(),
      endDate: formData.dateRange.endDate.toISOString(),
    };

    dispatch(downloadJobSourceCsvRequest(obj));
  };

  return (
    <>
      <Row className="d-flex justify-content-start mb-3 mt-3">
        <Col xs="auto">
          <Button color="primary" onClick={handleExport}>
            Export
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="box-col-12 xl-100">
          <Table striped responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Job Name</th>
                <th>Source</th>
                <th>Candidate Counts</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(jobData).map((jobKey, index) => {
                const jobDetails = jobData[jobKey];
                const { job_name, counts, source } = jobDetails || {};

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{capitalize(job_name) || "-"}</td>
                    <td>
                      {jobsourceOptions.find(
                        (option) => option.value === source
                      )?.label || ""}
                    </td>
                    <td>{counts || "0"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default JobSourceDatatable;
