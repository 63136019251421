import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import {
  addWorkSampleFailure,
  addWorkSampleSuccess,
  deleteWorkSampleSuccess,
  deleteWorkSampleFailure,
} from "./actions";

function* addWorkSample(action) {
  console.log("ad", action);
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/add_work_sample", action.payload)
    );
    console.log("sr", response);
    if (response.data.status) {
      yield put(addWorkSampleSuccess(response.data));
    } else {
      yield put(addWorkSampleFailure(response.data));
    }
  } catch (error) {
    yield put(addWorkSampleFailure({ status: false, message: error.message }));
  }
}

function* deleteWorkSample(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/delete_work_sample", action.payload)
    );
    if (response.data.status) {
      yield put(deleteWorkSampleSuccess(response.data));
    } else {
      yield put(deleteWorkSampleFailure(response.data));
    }
  } catch (error) {
    yield put(
      deleteWorkSampleFailure({ status: false, message: error.message })
    );
  }
}

function* workSampleSaga() {
  yield takeLatest(types.ADD_WORK_SAMPLE_REQUEST, addWorkSample);
  yield takeLatest(types.DELETE_WORK_SAMPLE_REQUEST, deleteWorkSample);
}

export default workSampleSaga;
