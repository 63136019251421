import React from "react";
import "./Template4.css";
import {
  capitalizeEachWord,
  capitalize,
  formatDate,
} from "../../../Common/CommonFunction";
import { FaStar } from "react-icons/fa";
import { educationLevelsOptions } from "../../../../Constant";
import github from "../../../../assets/images/resumeIcons/githubwhite.png";
import linkedin from "../../../../assets/images/resumeIcons/linkedinwhite.png";
import email from "../../../../assets/images/resumeIcons/emailwhite.png";
import call from "../../../../assets/images/resumeIcons/callwhite.png";
import location from "../../../../assets/images/resumeIcons/locationwhite.png";

const Template4 = ({ jobseekerData = {} }) => {
  // Check if jobseekerData has any meaningful content
  const hasData = jobseekerData && Object.keys(jobseekerData).length > 0;

  // Return a fallback message if there's no data
  if (!hasData) {
    return <div>No data available to display</div>; // You can replace with a loading spinner if needed
  }

  const emptyField = (str) => (str === "" || str === null ? "-" : str);
  const calculateProgressValue = (rating) => (rating / 10) * 100;

  const {
    jobseeker_full_name = "",
    jobseeker_date_of_birth = "",
    jobseeker_gender = "",
    jobseeker_marital_status = "",
    jobseeker_preferred_job_title = "",
    jobseeker_email1 = "",
    jobseeker_phone1 = "",
    jobseeker_email2 = "",
    jobseeker_phone2 = "",
    jobseeker_location = {},
    jobseeker_photo = "",
    resume_headline = "",
    work_experience = [],
    work_sample = [],
    education = [],
    it_skills = [],
    skill = [],
    online_detail_result = [],
  } = jobseekerData;

  const extractDisplayableValue = (value) => {
    if (typeof value === "object") {
      return value.label || value.value || "";
    }
    return value || "";
  };

  const renderSocialIcon = (socialProfileName) => {
    switch (socialProfileName.toUpperCase()) {
      case "LINKEDIN":
        return (
          <img
            style={{ height: 20, paddingRight: 10 }}
            src={linkedin}
            alt="LinkedIn Icon"
          />
        );
      case "GITHUB":
        return (
          <img
            style={{ height: 20, paddingRight: 10 }}
            src={github}
            alt="GitHub Icon"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="resume-4" id="resume4">
      <div className="container">
        <div className="left">
          <div className="profile-square"></div>
          <div className="img-container">
            <img
              src={jobseeker_photo}
              alt="Profile Picture"
              className="profile-pic"
            />
          </div>
          <div className="name-prf">
            <h2>{capitalizeEachWord(jobseeker_full_name || "")}</h2>
            <h4>{capitalizeEachWord(jobseeker_preferred_job_title) || ""}</h4>
          </div>
          <div className="basic-info">
            <div className="contact">
              <div className="section-title">CONTACT</div>
              <ul>
                <li>
                  <img src={email} className="contact-icon" alt="Email Icon" />
                  <a
                    href={`mailto:${extractDisplayableValue(jobseeker_email1)}`}
                  >
                    {extractDisplayableValue(jobseeker_email1)}
                  </a>
                </li>
                <li>
                  <img src={call} className="contact-icon" alt="Phone Icon" />
                  <a href={`tel:${extractDisplayableValue(jobseeker_phone1)}`}>
                    {extractDisplayableValue(jobseeker_phone1)}
                  </a>
                </li>
                <li>
                  <img
                    src={location}
                    className="contact-icon"
                    alt="Location Icon"
                  />
                  {extractDisplayableValue(jobseeker_location)}
                </li>
                <li>
                  {online_detail_result.length > 0 &&
                    online_detail_result.map(
                      (profile, index) =>
                        (profile.social_profile_name.toUpperCase() ===
                          "LINKEDIN" ||
                          profile.social_profile_name.toUpperCase() ===
                            "GITHUB") && (
                          <div key={index}>
                            {renderSocialIcon(profile.social_profile_name)}
                            <a href={profile.url}>{profile.url}</a>
                          </div>
                        )
                    )}
                </li>
              </ul>
            </div>
            <div className="awards">
              <div className="section-title">Skills</div>
              <ul>
                {skill.map((skill, index) => (
                  <li key={index}>
                    <span className="skill-name">
                      {capitalize(skill.label)}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="skills">
              <div className="section-title">Main SKILLS</div>
              <ul>
                {it_skills
                  .sort((a, b) => b.skill_rating - a.skill_rating)
                  .map((skill, index) => (
                    <li key={index}>
                      <span className="skill-name">
                        {capitalize(skill.it_skill.label)}
                      </span>
                      <div className="progress-bar">
                        <div
                          className="progress"
                          style={{
                            width: `${calculateProgressValue(
                              skill.skill_rating
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="top">
            <div className="about">
              <h6>ABOUT ME</h6>
              <p>{emptyField(resume_headline)}</p>
            </div>
          </div>
          <div className="lower">
            <div className="experience">
              <div className="section-title">
                <img
                  src="https://us.123rf.com/450wm/jovanas/jovanas1610/jovanas161000568/64116111-education-icon-vector-hat-glasses.jpg?ver=6"
                  alt="Education Icon"
                />
                <h1>EDUCATION</h1>
              </div>
              {education.map((edu, index) => (
                <div className="section-items" key={index}>
                  <div className="years">{edu.passing_year}</div>
                  <div className="company-details">
                    <h2>
                      {
                        educationLevelsOptions.find(
                          (option) => option.value === edu.education_level
                        )?.label
                      }
                    </h2>
                    {edu.board}
                    <br />
                    Percentage: {edu.performance}%
                  </div>
                </div>
              ))}
            </div>
            <div className="reference">
              <div className="section-title">
                <img
                  src="https://cdn-icons-png.freepik.com/512/8455/8455233.png"
                  alt="Work Experience Icon"
                />
                <h1>WORK EXPERIENCE</h1>
              </div>
              {work_experience.map((work, index) => (
                <div className="section-items" key={index}>
                  <div className="years">
                    {formatDate(work.started_working_from)} -{" "}
                    {formatDate(work.worked_till)}
                  </div>
                  <div className="company-details">
                    <h2>
                      {capitalize(work.designation)}@{work.organization.label}
                    </h2>
                    {capitalize(work.job_description)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template4;
