import {
  GO_TO_PASSBOOK_REQUEST,
  GO_TO_PASSBOOK_SUCCESS,
  GO_TO_PASSBOOK_FAILURE,
  RESET_MESSAGE,
} from "../actionTypes";

export const goToPassbookRequest = (
  page,
  per_page,
  data,
  short_by,
  column_direction
) => ({
  type: GO_TO_PASSBOOK_REQUEST,
  payload: { page, per_page, data, short_by, column_direction },
});

export const goToPassbookSuccess = (data) => ({
  type: GO_TO_PASSBOOK_SUCCESS,
  payload: data,
});

export const goToPassbookFailure = (error) => ({
  type: GO_TO_PASSBOOK_FAILURE,
  payload: error,
});

// Action creator for resetting the message
export const resetFavoriteMessage = () => ({
  type: RESET_MESSAGE,
});
