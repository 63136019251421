import React, { useState } from "react";
import { Bell } from "react-feather";
import { useNotification } from "../../../../Notifications/NotificationContext";
import {
  formatDate,
  timeAgo,
} from "../../../../Components/Common/CommonFunction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  markAllAsReadRequest,
  resetMarkAllAsReadMessage,
} from "../../../../redux/notifications/actions";
import { NotificationContext } from "../../../../Notifications/NotificationContext";
import { useContext } from "react";
const Notificationbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const { unreadNotifications, unreadNotificationsCount } =
    useContext(NotificationContext);
  // Get only the first four notifications
  const displayedNotifications = unreadNotifications.slice(0, 4);
  const getRandomClassName = (index) => {
    const classNames = [
      "b-l-primary",
      "b-l-warning",
      "b-l-success",
      "b-l-info",
    ];
    return classNames[index % classNames.length];
  };

  const handleNotificationClick = (notification) => {
    //dispatch(markAllAsReadRequest({ id: notification.id }));
    console.log("notification", notification.isread);
    if (notification.isread == 1) {
      navigate(`${process.env.PUBLIC_URL}/${notification.link}`);
    } else {
      console.log("notification", notification);
      dispatch(markAllAsReadRequest({ id: notification.id }));
      navigate(`${process.env.PUBLIC_URL}/${notification.link}`);
    }
  };

  return (
    <li className="onhover-dropdown">
      <div
        className={`notification-box ${
          unreadNotificationsCount > 0 ? "animated" : ""
        }`}
        onClick={() => setNotificationDropDown(!notificationDropDown)}
      >
        <Bell />
        <span className="badge rounded-pill badge-secondary">
          {unreadNotificationsCount}
        </span>
      </div>
      <div
        className={`notification-dropdown onhover-show-div ${
          notificationDropDown ? "active" : ""
        }`}
      >
        <h6 className="f-18 mb-0 dropdown-title">Notification</h6>
        <ul>
          {displayedNotifications && displayedNotifications.length > 0 ? (
            displayedNotifications.map((notification, index) => (
              <li
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  handleNotificationClick(notification);
                }}
                className={`${getRandomClassName(index)} border-4`}
                key={notification.id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <p
                  style={{
                    margin: "0",
                    flex: "1",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {notification.description}
                </p>

                <span
                  className="font-danger"
                  style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
                >
                  {timeAgo(notification.date, false)}
                </span>
              </li>
            ))
          ) : (
            <li>No notifications available</li>
          )}
          <li>
            <a
              className="f-w-700"
              href={`${process.env.PUBLIC_URL}/recruiter/notifications`}
            >
              CHECK ALL
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Notificationbar;
