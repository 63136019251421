import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux"; // Import useSelector from react-redux

const TopActiveJobs = () => {
  // Retrieve data from the Redux store using useSelector hook
  const dashboardReducerdData = useSelector((state) => state.dashboardReducer);

  // Initialize state variables to manage chart data and options
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "area",
      height: 180,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
      },
    },
    grid: {
      show: true,
      borderColor: "#f1f1f1",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: true,
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#f1f1f1",
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#f1f1f1",
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
    states: {
      normal: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      fontSize: "14px",
      offsetX: 20,
      labels: {
        colors: "#9aa0ac",
      },
    },
    colors: ["#7367F0", "#28C76F", "#EA5455"],
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 300,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "9px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          legend: {
            fontSize: "12px",
            offsetX: 10,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 250,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "9px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "9px",
              },
            },
          },
          legend: {
            fontSize: "10px",
            offsetX: 0,
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
          legend: {
            fontSize: "8px",
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
          },
        },
      },
    ],
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Applications",
      data: [30, 40, 35, 50, 49, 60, 70],
    },
    {
      name: "Shortlisted",
      data: [35, 45, 50, 60, 55, 70, 80],
    },
    {
      name: "Rejected",
      data: [45, 50, 55, 60, 65, 80, 90],
    },
  ]);

  useEffect(() => {
    // Check if dashboardReducerdData is not loading and contains the necessary data
    if (
      !dashboardReducerdData.isLoading &&
      dashboardReducerdData.data &&
      dashboardReducerdData.data.data
    ) {
      const data = dashboardReducerdData.data.data;

      // Extract relevant data for chart series and options from the retrieved data
      const application_status = data.application_status;
      const shortlisted_status = data.shortlisted_status;
      const rejected_status = data.rejected_status;

      // Update chart series
      setChartSeries([
        {
          name: "Applications",
          data: application_status,
        },
        {
          name: "Shortlisted",
          data: shortlisted_status,
        },
        {
          name: "Rejected",
          data: rejected_status,
        },
      ]);

      // Update chart options if needed
      // This depends on how your chart options are affected by the data
      // For example, updating x-axis categories based on new data
      // setChartOptions((prevOptions) => ({
      //  ...prevOptions,
      // Your updated chart options here
      //   }));
    }
  }, [dashboardReducerdData]);

  return (
    <Card className="shadow-sm rounded">
      <CardHeader className="card-no-border">
        <h5 className="mb-0">Top Active Jobs</h5>
      </CardHeader>
      <CardBody className="px-3 py-3 pt-0">
        <div id="basic-apex">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            height="350"
            type="area"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default TopActiveJobs;
