import React, { Fragment, useContext } from "react";
import { Nav } from "reactstrap";
import ChatAppContext from "../../../Chat";
import avatar from "../../../../src/assets/images/avatar.png";

const ChatMenu = () => {
  const { selectedUser, selectedUserProfile } =
    useContext(ChatAppContext) || {};

  const renderProfileInfo = (label, value, isLink = false) => {
    if (!value) return null;

    return (
      <div className="mb-2">
        <span className="font-weight-bold" style={{ fontWeight: "bold" }}>
          {label}:
        </span>{" "}
        {isLink ? (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ) : (
          value
        )}
      </div>
    );
  };

  return (
    <Fragment>
      {/* <Nav tabs className="border-tab nav-primary"> */}
      {/* Uncomment and implement these NavItems if needed */}
      {/* <NavItem id="myTab" role="tablist">
          <NavLink
            tag="a"
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            {" "}
            CALL
          </NavLink>
        </NavItem>
        <NavItem id="myTab" role="tablist">
          <NavLink
            tag="a"
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            STATUS
          </NavLink>
        </NavItem>
        <NavItem id="myTab" role="tablist">
          <NavLink
            tag="a"
            className={activeTab === "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            PROFILE
          </NavLink>
        </NavItem> */}
      {/* </Nav> */}

      <div
        className="user-profile custom-scrollbar"
        style={{ overflowY: "auto", height: "560px" }}
      >
        <div className="image text-center mb-3">
          <img
            className="rounded-circle"
            src={selectedUser?.jobseeker_photo || avatar}
            alt="User Avatar"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
        <div className="user-content text-center">
          <h5 className="text-center text-uppercase mb-2">
            {selectedUser ? selectedUser.jobseeker_full_name : ""}
          </h5>
          {selectedUserProfile?.recruiter_email && (
            <p className="mb-2">{selectedUserProfile.jobseeker_email1}</p>
          )}
          {selectedUserProfile?.status !== undefined && (
            <p className="mb-2">
              {selectedUserProfile.status ? "Active" : "Inactive"}
            </p>
          )}
          <hr />
          <div
            className="text-left profile-details"
            style={{ margin: "20px", textAlign: "left" }}
          >
            {selectedUserProfile && (
              <Fragment>
                {renderProfileInfo(
                  "Company Name",
                  selectedUserProfile.company_name
                )}
                {renderProfileInfo(
                  "Company Email",
                  selectedUserProfile.company_email
                )}
                {renderProfileInfo(
                  "Company Phone",
                  selectedUserProfile.company_phone
                )}
                {renderProfileInfo("Address", selectedUserProfile.address)}
                {renderProfileInfo("Zip Code", selectedUserProfile.zip_code)}
                {renderProfileInfo(
                  "Company Website",
                  selectedUserProfile.company_website,
                  true
                )}
                {renderProfileInfo(
                  "Jobseeker Email 1",
                  selectedUserProfile.jobseeker_email1
                )}
                {renderProfileInfo(
                  "Jobseeker Email 2",
                  selectedUserProfile.jobseeker_email2
                )}
                {renderProfileInfo(
                  "Jobseeker Phone 1",
                  selectedUserProfile.jobseeker_phone1
                )}
                {renderProfileInfo(
                  "Jobseeker Phone 2",
                  selectedUserProfile.jobseeker_phone2
                )}
                {renderProfileInfo(
                  "Preferred Department",
                  selectedUserProfile.jobseeker_preferred_department
                )}
                {renderProfileInfo(
                  "Preferred Employment Type",
                  selectedUserProfile.jobseeker_preferred_employment_type
                )}
                {renderProfileInfo(
                  "Preferred Industry",
                  selectedUserProfile.jobseeker_preferred_industry
                )}
                {renderProfileInfo(
                  "Preferred Job Type",
                  selectedUserProfile.jobseeker_preferred_job_type
                )}
                {renderProfileInfo(
                  "Preferred Location",
                  selectedUserProfile.jobseeker_preferred_location
                )}
                {renderProfileInfo(
                  "Preferred Salary",
                  selectedUserProfile.jobseeker_preferred_salary
                )}
                {renderProfileInfo(
                  "Preferred Shift",
                  selectedUserProfile.jobseeker_preferred_shift
                )}
                {selectedUserProfile.social_links &&
                  selectedUserProfile.social_links.map((link, index) =>
                    renderProfileInfo(link.social_profile_name, link.url, true)
                  )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatMenu;
