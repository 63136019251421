import React, { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import Template1 from "../../Components/Jobseeker/ResumeTemplates/Template1/Template1";
import Template2 from "../../Components/Jobseeker/ResumeTemplates/Template2/Template2";
import Template3 from "../../Components/Jobseeker/ResumeTemplates/Template3/Template3";
import Template4 from "../../Components/Jobseeker/ResumeTemplates/Template4/Template4";
import Template5 from "../../Components/Jobseeker/ResumeTemplates/Template5/Template5";

import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { FiEdit } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { fetchTemplatesRequest } from "../../redux/resumeTemplates/actions";
import { useDispatch, useSelector } from "react-redux";
import { FiShare2 } from "react-icons/fi";
import ShareModal from "../../Components/Jobseeker/ResumeTemplates/ShareModel";
import { checkTemplateLockRequest } from "../../redux/jobseeker/check_template_locked/actions";
import { shareResumeTemplateRequest } from "../../redux/jobseeker/share_resume_template/actions";
import { showToast } from "../../Components/Common/Toast";
import { PreloaderContext } from "../../_helper/Preloader/PreloaderContext";
import { LOADER_TIMEOUT } from "../../Constant";
import { useContext } from "react";
import whatsapp from "../../assets/images/Icons/whatsapp.png";
import facebook from "../../assets/images/Icons/facebook.png";
import twitter from "../../assets/images/Icons/twitter.png";
import linkedin from "../../assets/images/Icons/linkedin.png";
import {
  unlockTemplateRequest,
  resetUnlockTemplateMessage,
} from "../../redux/jobseeker/unlock_template/actions";
import { fetchJobseekerReferralRequest } from "../../redux/jobseeker/referral/actions";
import {
  idToBase64,
  base64ToId,
  showStyledCoins,
} from "../../Components/Common/CommonFunction";
import { AuthContext } from "../../Auth/AuthContext";
import { fetchRewardsPointsRequest } from "../../redux/fetch_rewards_points/actions";

const TemplatePreviewPage = () => {
  const { templateId } = useParams();
  const { triggerTotalPoints } = useContext(AuthContext);
  //  const [referralLink, setReferralLink] = useState("");
  const { showPreloader } = useContext(PreloaderContext);
  //const referralLink = `${window.location.origin}/public/preview_resume/${templateId}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(
    base64ToId(templateId) || "1"
  );

  console.log("base64ToId", selectedTemplate);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isPurchased, setIsPurchased] = useState(true);
  const [resumeShareLink, setResumeShareLink] = useState("");

  useEffect(() => {
    dispatch(fetchJobseekerReferralRequest()); // Fetch total referral coins when component mounts
  }, []);
  const jobseekerRefferalData = useSelector(
    (state) => state.referralJobseekerReducer
  );
  const referalCode = jobseekerRefferalData.data.referal_code;
  const referralLink = `${window.location.origin}${process.env.PUBLIC_URL}/jobseeker/auth/register/${referalCode}`;

  const shareOnSocialMedia = (platform) => {
    // Logic to share referral link on selected social media platform
    let shareUrl = "";
    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=Referral | Naukrify –${referralLink}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer.php?u=${referralLink}`;
        break;
      case "twitter":
        shareUrl = `http://twitter.com/share?text=${referralLink}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${referralLink}`;
        break;
      default:
        break;
    }
    window.open(shareUrl, "_blank");
  };

  const handleEditClick = () => {
    navigate(
      `${process.env.PUBLIC_URL}/public/edit_resume/${idToBase64(
        selectedTemplate
      )}`
    );
  };

  let resumeTemplateReducerData = useSelector(
    (state) => state.resumeTemplateReducer
  );

  let templateData = resumeTemplateReducerData.data.data;

  useEffect(() => {
    templateData = templateData || [];
    // setTemplateOptions(templateData);

    const options = templateData.map((template) => ({
      value: template.value,
      label: template.label,
      type: template.type,
    }));

    setTemplateOptions(options);
  }, [templateData]);

  const handleTemplateFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchTemplatesRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  useEffect(() => {
    handleTemplateFocus();
  }, []);

  const handleTemplateChange = (selectedOptions) => {
    setSelectedTemplate(selectedOptions.value);
    setSelectedType(selectedOptions.type);
  };

  useEffect(() => {
    // Set initial selected type based on the initial templateId value
    const initialSelectedType =
      templateOptions.find((option) => option.value == base64ToId(templateId))
        ?.type || "";

    setSelectedType(initialSelectedType);
  }, [templateOptions]);

  useEffect(() => {
    if (selectedType === "PREMIUM") {
      dispatch(
        checkTemplateLockRequest({
          resume_template_id: idToBase64(selectedTemplate),
        })
      );
    } else {
      setIsPurchased(true);
    }
  }, [selectedType, selectedTemplate]);

  const handleShareClick = () => {
    setIsShareModalOpen(!isShareModalOpen);
  };

  useEffect(() => {
    // Dispatch share action if isPurchased is true

    // Dispatch action to share resume template
    dispatch(
      shareResumeTemplateRequest({
        template_id: idToBase64(selectedTemplate),
      })
    );
  }, [selectedTemplate]);

  let shareResumeTemplateReducerData = useSelector(
    (state) => state.shareResumeTemplateReducer
  );

  useEffect(() => {
    if (
      shareResumeTemplateReducerData.status &&
      shareResumeTemplateReducerData.data
    ) {
      setResumeShareLink(
        `${window.location.origin}${process.env.PUBLIC_URL}/public/preview_resume/${shareResumeTemplateReducerData.data}/${templateId}`
      );
    }
  }, [shareResumeTemplateReducerData]);

  const fetchJobseekerDataReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer
  );
  let profileData = [];
  if (
    !fetchJobseekerDataReducerData.isLoading &&
    fetchJobseekerDataReducerData.data.data
  ) {
    profileData = fetchJobseekerDataReducerData.data.data;
  }

  useEffect(() => {
    if (
      fetchJobseekerDataReducerData.status &&
      fetchJobseekerDataReducerData.message != ""
    ) {
      showToast("success", fetchJobseekerDataReducerData.message);
      // dispatch(resetJob());
    } else if (
      fetchJobseekerDataReducerData.status == false &&
      fetchJobseekerDataReducerData.message != ""
    ) {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", fetchJobseekerDataReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", fetchJobseekerDataReducerData.message);
      }
      // dispatch(resetJob());
    }
  }, [fetchJobseekerDataReducerData]);

  const checkTemplateLockedReducerData = useSelector(
    (state) => state.checkTemplateLockedReducer
  );

  useEffect(() => {
    if (
      !checkTemplateLockedReducerData.isLoading &&
      checkTemplateLockedReducerData.status
    ) {
      setIsPurchased(checkTemplateLockedReducerData.isPurchased);
      // dispatch(resetJob());
    }
  }, [checkTemplateLockedReducerData]);

  const handleUnlockClick = () => {
    dispatch(
      unlockTemplateRequest({ template_id: idToBase64(selectedTemplate) })
    );
  };

  const unlockTemplateReducerData = useSelector(
    (state) => state.unlockTemplateReducer
  );

  useEffect(() => {
    if (
      unlockTemplateReducerData.status == true &&
      unlockTemplateReducerData.message != ""
    ) {
      showToast("success", unlockTemplateReducerData.message);
      triggerTotalPoints("buy_resume", false, false);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetUnlockTemplateMessage());
      setIsPurchased(true);
      setSelectedType("FREE");
    } else if (
      unlockTemplateReducerData.status == false &&
      unlockTemplateReducerData.message != ""
    ) {
      setIsPurchased(false);
      showToast("error", unlockTemplateReducerData.message);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetUnlockTemplateMessage());
    }
  }, [unlockTemplateReducerData]);
  const fetchRewardsPointsReducerData = useSelector(
    (state) => state.fetchRewardsPointsReducer
  );

  useEffect(() => {
    if (
      !fetchRewardsPointsReducerData ||
      !fetchRewardsPointsReducerData.data ||
      !Array.isArray(fetchRewardsPointsReducerData.data) ||
      fetchRewardsPointsReducerData.data.length <= 0
    ) {
      dispatch(fetchRewardsPointsRequest());
    }
  }, []);

  const buyResumePoints =
    fetchRewardsPointsReducerData?.data?.find(
      (item) => item.type === "buy_resume"
    )?.points ?? 0;

  const convertHtmlToPdf = async (file) => {
    const apiUrl = "http://195.35.23.76:5000/convert_html_to_pdf";

    try {
      // Create a FormData object to hold the file
      const formData = new FormData();
      formData.append("file", file); // Add the HTML file

      // Send the file to the server using FormData
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData, // Send FormData instead of JSON
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "converted.pdf"; // Specify the download filename
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to convert HTML to PDF:", error);
    }
  };

  const handleDownloadClick = async () => {
    try {
      let templateName = "";
      let resumeName = "";

      switch (selectedTemplate) {
        case 1:
          templateName = "Template1";
          resumeName = "resume1";
          break;
        case 2:
          templateName = "Template2";
          resumeName = "resume2";
          break;
        case 3:
          templateName = "Template3";
          resumeName = "resume3";
          break;
        case 4:
          templateName = "Template4";
          resumeName = "resume4";
          break;
        case 5:
          templateName = "Template5";
          resumeName = "resume5";
          break;
        default:
          templateName = ""; // Default case if needed
          resumeName = "";
          break;
      }
      // Load CSS content dynamically (if stored as a file)
      const cssPath = `../../Components/Jobseeker/ResumeTemplates/${templateName}/${templateName}.css`;
      const cssResponse = await fetch(cssPath);
      const cssContent = await cssResponse.text();
      console.log("cssResponse", cssResponse);
      console.log("cssContent", cssContent);
      console.log("resumeName", resumeName);
      // Get the HTML content from the main tag
      const mainContent = document.getElementById(resumeName).innerHTML;
      console.log("mainContent", mainContent);

      // Combine CSS and HTML by wrapping CSS in a <style> tag
      const htmlCode = `
        <html>
          <head>
            <style>${cssContent}</style>
          </head>
          <body>
            <main>${mainContent}</main>
          </body>
        </html>
      `;
      console.log("htmlWithCSS", htmlCode);

      // Create a Blob for the HTML content (HTML file)
      const blob = new Blob([htmlCode], { type: "text/html" });

      // Create a new file object with the HTML content
      const file = new File([blob], "resume.html", { type: "text/html" });

      // Pass the file object to the convertHtmlToPdf function
      await convertHtmlToPdf(file);
    } catch (error) {
      console.error("Error generating PDF:", error);
      showToast("error", "Failed to download the resume. Please try again.");
    }
  };

  return (
    <Fragment>
      <Breadcrumbs title={"Preview Resume"} mainTitle={"Preview Resume"} />

      <Container fluid>
        <Row className="justify-content-center p-0 mb-4">
          <Col xl="auto" lg="auto" md="auto" className="p-0">
            {/* Row for button */}
            <Row className="mb-3">
              <div className="d-flex justify-content-between align-items-center p-0">
                <div className="d-flex align-items-center">
                  <FiEdit
                    className="edit-icon"
                    style={{ height: "25px", width: "25px" }}
                    onClick={handleEditClick}
                    title="edit"
                  />
                  {isPurchased && (
                    <>
                      <FiShare2
                        title="share"
                        style={{
                          height: "25px",
                          width: "25px",
                          marginLeft: "10px",
                        }}
                        className="share-icon"
                        onClick={handleShareClick}
                      />
                      <FiDownload
                        className="download-icon"
                        style={{
                          height: "25px",
                          width: "25px",
                          marginLeft: "10px",
                        }}
                        onClick={handleDownloadClick}
                        title="download"
                      />
                    </>
                  )}
                </div>
                <Select
                  options={templateOptions}
                  value={templateOptions.find(
                    (option) => option.value === selectedTemplate
                  )}
                  onChange={handleTemplateChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "0px",
                      height: "30px",
                      width: "250px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                    }),
                  }}
                />
              </div>
            </Row>

            {/* Row for template */}
            <Row className="justify-content-center p-0">
              <Col className="d-flex justify-content-end p-0  rounded">
                <div
                  //className="p-3"
                  style={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {selectedTemplate == "1" && (
                    <Template1 jobseekerData={profileData} />
                  )}
                  {selectedTemplate == "2" && (
                    <Template2 jobseekerData={profileData} />
                  )}
                  {selectedTemplate == "3" && (
                    <Template3 jobseekerData={profileData} />
                  )}
                  {selectedTemplate == "4" && (
                    <Template4 jobseekerData={profileData} />
                  )}
                  {selectedTemplate == "5" && (
                    <Template5 jobseekerData={profileData} />
                  )}

                  {selectedType === "PREMIUM" && !isPurchased && (
                    <div
                      style={{
                        alignContent: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust transparency as needed (0.7 is 70% transparent)
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card className="mt-2" style={{ margin: "0 40px" }}>
                        <CardBody className="d-flex flex-column align-items-center">
                          <div className="mb-3 mb-md-0 text-center">
                            <h5>
                              Unlock this premium resume template by using{" "}
                              {showStyledCoins(buyResumePoints)} !
                            </h5>
                            <p>
                              Refer your friends to earn more points and unlock
                              other premium features!
                            </p>
                            <div className="refferal-description d-flex flex-column align-items-center">
                              <div className="share-links">
                                <span className="share-link">
                                  <strong style={{ margin: "0 5px" }}>
                                    OR
                                  </strong>
                                </span>
                                <br />
                                <br />
                                <img
                                  src={whatsapp}
                                  className="share-link share_refferal me-2"
                                  onClick={() => shareOnSocialMedia("whatsapp")}
                                  aria-label="Whatsapp"
                                  alt="whatsapp"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                  }}
                                />
                                <img
                                  src={facebook}
                                  className="share-link share_refferal me-2"
                                  onClick={() => shareOnSocialMedia("facebook")}
                                  aria-label="Facebook"
                                  alt="facebook"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                  }}
                                />
                                <img
                                  src={twitter}
                                  className="share-link share_refferal me-2"
                                  onClick={() => shareOnSocialMedia("twitter")}
                                  aria-label="Twitter"
                                  alt="twitter"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                  }}
                                />
                                <img
                                  src={linkedin}
                                  className="share-link share_refferal me-2"
                                  onClick={() => shareOnSocialMedia("linkedin")}
                                  aria-label="Linkedin"
                                  alt="linkedin"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <Button color="warning" onClick={handleUnlockClick}>
                              UNLOCK THIS RESUME
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ShareModal
        isOpen={isShareModalOpen}
        toggle={() => setIsShareModalOpen(!isShareModalOpen)}
        resumeShareLink={resumeShareLink}
      />
    </Fragment>
  );
};

export default TemplatePreviewPage;
