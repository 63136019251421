import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import { Navigate } from "react-router-dom";

import AuthProvider from "./Auth/AuthContext";
import { AuthContext } from "./Auth/AuthContext";
import ProtectedRoute from "./Auth/ProtectedRoute";

import PreloaderProvider from "./_helper/Preloader/PreloaderContext";

// layout
import JobseekerLayout from "./Layout/Jobseeker/JobseekerLayout";
import RecruiterLayout from "./Layout/Recruiter/RecruiterLayout";

// auth pages
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgetPwd from "./pages/Auth/ForgetPwd";
import ResetPwd from "./pages/Auth/ResetPwd";

import Test from "./pages/Test";
import Test2 from "./pages/Test2";

// jobseeker pages
import DashboardJobseekerPage from "./pages/jobseeker/DashboardJobseekerPage";
import UpdateProfilePage from "./pages/jobseeker/UpdateProfilePage";

import AppliedJobsPage from "./pages/jobseeker/AppliedJobsPage";
import RecommendedJobsPage from "./pages/jobseeker/RecommendedJobsPage";
import RefferalPage from "./pages/jobseeker/RefferalPage";
import GenerateResumesPage from "./pages/jobseeker/GenerateResumesPage";
import ChangePasswordPage from "./pages/jobseeker/ChangePasswordPage";
import JobseekerJobDetailPage from "./pages/jobseeker/JobDetailPage";
import JobSearchPage from "./pages/jobseeker/JobSearchPage";
import JobSearchCareerPage from "./pages/Public/JobSearchCareerPage";
import ReportJobPage from "./pages/jobseeker/ReportJobPage";

import NotificationsPage from "./pages/NotificationsPage";
// recruiter pages
import DashboardRecruiter from "./pages/recruiter/DashboardRecruiter";
import PostJobPage from "./pages/recruiter/PostJobPage";
import CareerPage from "./pages/recruiter/CareerPage";
import ManageCandidatePage from "./pages/recruiter/ManageCandidatePage";
import ManageJobPage from "./pages/recruiter/ManageJobsPage";
import RecruiterChangePasswordPage from "./pages/recruiter/RecruiterChangePasswordPage";
import RecruiterProfilePage from "./pages/recruiter/RecruiterProfilePage";
import CompanyProfilePage from "./pages/recruiter/CompanyProfilePage";
import RecruiterReferralPage from "./pages/recruiter/RecruiterReferralPage";
import JobApplicationsPage from "./pages/recruiter/JobApplicationsPage";
import RecruiterJobDetailPage from "./pages/recruiter/JobDetailPage";
import JobCandidateDetailsPage from "./pages/recruiter/JobCandidateDetailsPage";
import AddCandidatePage from "./pages/recruiter/AddCandidatePage";
import GoToPassbook from "./pages/jobseeker/GoToPassbook";
import RecruiterGoToPassbook from "./pages/recruiter/GoToPassbook";
import JobSourcePage from "./pages/recruiter/JobSourcePage";
import JobConversionPage from "./pages/recruiter/JobConversionPage";
import JobPipelinePage from "./pages/recruiter/JobPipelinePage";
import PublicProfilePage from "./pages/Public/PublicProfilePage";
import CandidateSearchPage from "./pages/recruiter/CandidateSearchPage";
import TemplatePreviewPage from "./pages/jobseeker/TemplatePreviewPage";
import ResumeScan from "./pages/jobseeker/ResumeScan";
import ResumeScanResult from "./pages/jobseeker/ResumeScanResult";
import Error404 from "./pages/errors/ErrorPage404";
import PublicLayout from "./Layout/Public/PublicLayout";
import JobFeedbackPage from "./pages/jobseeker/JobFeedbackPage";
import NotificationProvider from "./Notifications/NotificationContext";
import SavedJobsPage from "./pages/jobseeker/SavedJobsPage";
import TemplateEditPage from "./pages/jobseeker/TemplateEditPage";
import TemplatePreviewPublicPage from "./pages/jobseeker/TemplatePreviewPublicPage";
import ChatPage from "./pages/recruiter/ChatPage";
import JobseekerChatPage from "./pages/jobseeker/ChatPage";
import BulkUpload from "./pages/recruiter/BulkUpload";

import ChatProvider from "./Chat/ChatProvider";

import Maintenance from "./pages/Maintenance";
import RootLayout from "./Layout/RootLayout";
import VerifyAccount from "./pages/Auth/VerifyAccount";
import PublicJobDetailPage from "./pages/Public/PublicJobDetailPage";

import YourCandidateSearchPage from "./pages/recruiter/YourCandidateSearchPage";
import InvitePage from "./pages/recruiter/InvitePage";
import AcceptInvite from "./pages/Public/AcceptInvite";
import AiLinkedinBanner from "./pages/jobseeker/AiLinkedinBanner";
import AiLinkedinBannerRecruiter from "./pages/recruiter/AiLinkedinBanner";
import SavedCandidatesPage from "./pages/recruiter/SavedCandidatesPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/job",
      element: <RootLayout />,
      children: [
        {
          path: "",
          element: (
            // <Navigate to={`${process.env.PUBLIC_URL}/jobseeker/auth/login`} />
            <Maintenance />
          ),
        },
        {
          path: "auth/login",
          element: (
            <Navigate to={`${process.env.PUBLIC_URL}/jobseeker/auth/login`} />
          ),
        },
        {
          path: "invite/:token",
          element: <AcceptInvite />,
        },
        {
          path: "public/",
          element: <PublicLayout />,
          children: [
            {
              path: "job_details/:id",
              element: <PublicJobDetailPage />,
            },
            {
              path: "report_job/:jobId",
              element: <ReportJobPage />,
            },
            {
              path: "public_profile/:cand_id",
              element: <PublicProfilePage />,
            },
            {
              path: "career/:companyId",
              element: <JobSearchCareerPage />,
            },
            {
              path: "preview_resume/:hashCode/:templateId",
              element: <TemplatePreviewPublicPage />,
            },
            {
              path: "edit_resume/:templateId",
              element: <TemplateEditPage />,
            },
          ],
        },
        {
          path: ":type/auth",
          children: [
            {
              path: "login",
              element: <Login />,
            },
            {
              path: "register/:id?",
              element: <Register />,
            },
            {
              path: "register/invite/:token?",
              element: <Register />,
            },
            {
              path: "forgot_password",
              element: <ForgetPwd />,
            },
            {
              path: "reset_password/:token",
              element: <ResetPwd />,
            },
            {
              path: "verify_account/:token",
              element: <VerifyAccount />,
            },
          ],
        },

        {
          path: "jobseeker/",
          element: (
            <AuthContext.Consumer>
              {(auth) => (
                <ProtectedRoute userType="JOBSEEKER" auth={auth}>
                  <JobseekerLayout />
                </ProtectedRoute>
              )}
            </AuthContext.Consumer>
          ),
          children: [
            {
              path: "",
              element: (
                <Navigate
                  to={`${process.env.PUBLIC_URL}/jobseeker/dashboard`}
                />
              ),
            },
            {
              path: "dashboard",
              element: <DashboardJobseekerPage />,
            },
            {
              path: "update_profile",
              element: <UpdateProfilePage />,
            },
            {
              path: "applied_jobs/:jobstatus?",
              element: <AppliedJobsPage />,
            },
            {
              path: "recommended_jobs",
              element: <RecommendedJobsPage />,
            },
            {
              path: "referral",
              element: <RefferalPage />,
            },
            {
              path: "go_to_passbook",
              element: <GoToPassbook />,
            },
            {
              path: "generate_resume",
              element: <GenerateResumesPage />,
            },
            {
              path: "ai_linkedin_banner",
              element: <AiLinkedinBanner />,
            },
            {
              path: "change_password",
              element: <ChangePasswordPage />,
            },
            {
              path: "job_details/:id",
              element: <JobseekerJobDetailPage />,
            },

            {
              path: "job_search",
              element: <JobSearchPage />,
            },
            {
              path: "resume_scan",
              element: <ResumeScan />,
            },
            {
              path: "resume_scan_result",
              element: <ResumeScanResult />,
            },
            {
              path: "job_feedback/:jobId",
              element: <JobFeedbackPage />,
            },
            {
              path: "notifications",
              element: (
                <NotificationProvider>
                  <NotificationsPage />,
                </NotificationProvider>
              ),
            },
            {
              path: "saved_jobs",
              element: <SavedJobsPage />,
            },
            {
              path: "chat",
              element: (
                <NotificationProvider>
                  <ChatProvider>
                    <JobseekerChatPage />
                  </ChatProvider>
                </NotificationProvider>
              ),
            },
            {
              path: "preview_resume/:templateId",
              element: <TemplatePreviewPage />,
            },
          ],
        },

        {
          path: "recruiter/",
          element: (
            <AuthContext.Consumer>
              {(auth) => (
                <ProtectedRoute userType="RECRUITER" auth={auth}>
                  <RecruiterLayout />
                </ProtectedRoute>
              )}
            </AuthContext.Consumer>
          ),
          children: [
            {
              path: "",
              element: (
                <Navigate
                  to={`${process.env.PUBLIC_URL}/recruiter/dashboard`}
                />
              ),
            },
            {
              path: "dashboard",
              element: <DashboardRecruiter />,
            },
            // {
            //   path: "post_job/:id?/:duplicate?",
            //   element: <PostJobPage />,
            // },
            {
              path: "post_job/:type/:id?/:duplicate?",
              element: <PostJobPage type="job" />,
            },
            {
              path: "post_job/:type/:id?/:duplicate?",
              element: <PostJobPage type="internship" />,
            },
            {
              path: "manage_jobs/:tab?",
              element: <ManageJobPage />,
            },
            {
              path: "bulk_upload",
              element: <BulkUpload />,
            },
            {
              path: "manage_candidate/:candidateStatus?",
              element: <ManageCandidatePage />,
            },
            {
              path: "saved_candidates",
              element: <SavedCandidatesPage />,
            },
            {
              path: "career",
              element: <CareerPage />,
            },
            {
              path: "chat",
              element: (
                <NotificationProvider>
                  <ChatProvider>
                    <ChatPage />
                  </ChatProvider>
                </NotificationProvider>
              ),
            },
            {
              path: "change_password",
              element: <RecruiterChangePasswordPage />,
            },
            {
              path: "profile",
              element: <RecruiterProfilePage />,
            },
            {
              path: "company",
              element: <CompanyProfilePage />,
            },
            {
              path: "referral",
              element: <RecruiterReferralPage />,
            },
            {
              path: "go_to_passbook",
              element: <RecruiterGoToPassbook />,
            },
            {
              path: "job_details/:id",
              element: <RecruiterJobDetailPage />,
            },
            {
              path: "job_applications/:id",
              element: <JobApplicationsPage />,
            },

            {
              path: "ai_linkedin_banner",
              element: <AiLinkedinBannerRecruiter />,
            },
            // {
            //   path: "job_candidate_detail/:cand_id/:jobcad_id?",
            //   element: <JobCandidateDetailsPage />,
            // },
            {
              path: "job_candidate_detail/:type/:cand_id/:jobcad_id?",
              element: <JobCandidateDetailsPage />,
            },

            {
              path: "add_candidate/:job_id/:cand_id?",
              element: <AddCandidatePage />,
            },
            {
              path: "candidate_search",
              element: (
                <NotificationProvider>
                  <ChatProvider>
                    <CandidateSearchPage />
                  </ChatProvider>
                </NotificationProvider>
              ),
            },
            {
              path: "your_candidate_search",
              element: (
                <NotificationProvider>
                  <ChatProvider>
                    <YourCandidateSearchPage />
                  </ChatProvider>
                </NotificationProvider>
              ),
            },

            {
              path: "job_source",
              element: <JobSourcePage />,
            },

            {
              path: "job_conversion",
              element: <JobConversionPage />,
            },

            {
              path: "job_pipeline",
              element: <JobPipelinePage />,
            },
            // {
            //   path: "weekly_status",
            //   element: <WeeklyStatusPage />,
            // },
            // {
            //   path: "detail_report",
            //   element: <DetailReportPage />,
            // },
            {
              path: "notifications",
              element: (
                <NotificationProvider>
                  <NotificationsPage />
                </NotificationProvider>
              ),
            },
            {
              path: "invite",
              element: <InvitePage />,
            },
          ],
        },
      ],
    },
    {
      path: "",
      element: (
        // <Navigate to={`${process.env.PUBLIC_URL}/jobseeker/auth/login`} />
        <Maintenance />
      ),
    },
    {
      path: "/test",
      element: <Test />,
    },
    {
      path: "/test2",
      element: <Test2 />,
    },
    {
      path: "*",
      element: <Error404 />,
    },
  ]);

  return (
    <div className="App">
      <AuthProvider>
        <CustomizerProvider>
          <AnimationThemeProvider>
            <PreloaderProvider>
              <RouterProvider router={router} />
            </PreloaderProvider>
          </AnimationThemeProvider>
        </CustomizerProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
