// actions.js
import {
  GET_TOTAL_POINTS_REQUEST,
  GET_TOTAL_POINTS_SUCCESS,
  GET_TOTAL_POINTS_FAILURE,
  RESET_TOTAL_POINTS,
} from "../actionTypes";

export const getTotalPointsRequest = () => ({
  type: GET_TOTAL_POINTS_REQUEST,
  //payload: { keyword },
});

export const getTotalPointsSuccess = (data) => ({
  type: GET_TOTAL_POINTS_SUCCESS,
  payload: data,
});

export const getTotalPointsFailure = (error) => ({
  type: GET_TOTAL_POINTS_FAILURE,
  payload: error,
});

export const resetTotalPoints = () => ({
  type: RESET_TOTAL_POINTS,
});
