import {
  GET_LINKEDIN_BANNER_REQUEST,
  GET_LINKEDIN_BANNER_SUCCESS,
  GET_LINKEDIN_BANNER_FAILURE,
} from "../actionTypes";

export const getLinkedInBannerRequest = (type) => ({
  type: GET_LINKEDIN_BANNER_REQUEST,
  payload: type,
});

export const getLinkedInBannerSuccess = (data) => ({
  type: GET_LINKEDIN_BANNER_SUCCESS,
  payload: data,
});

export const getLinkedInBannerFailure = (error) => ({
  type: GET_LINKEDIN_BANNER_FAILURE,
  payload: error,
});
