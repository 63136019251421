import {
  UPDATE_COMMUNICATION_REQUEST,
  UPDATE_COMMUNICATION_SUCCESS,
  UPDATE_COMMUNICATION_FAILURE,
  RESET_COMMUNICATION_MESSAGE,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
};

const updateCommunicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMMUNICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_COMMUNICATION_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case UPDATE_COMMUNICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_COMMUNICATION_MESSAGE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default updateCommunicationReducer;
