import {
  UPDATE_CANDIDATE_RATING_REQUEST,
  UPDATE_CANDIDATE_RATING_SUCCESS,
  UPDATE_CANDIDATE_RATING_FAILURE,
  RESET_CANDIDATE_RATING_MESSAGE,
} from "../../actionTypes";

// action creators
export const updateCandidateRatingRequest = (data) => ({
  type: UPDATE_CANDIDATE_RATING_REQUEST,
  payload: data,
});

export const updateCandidateRatingSuccess = (message) => ({
  type: UPDATE_CANDIDATE_RATING_SUCCESS,
  payload: message,
});

export const updateCandidateRatingFailure = (error) => ({
  type: UPDATE_CANDIDATE_RATING_FAILURE,
  payload: error,
});

export const resetCandidateRatingMessage = () => ({
  type: RESET_CANDIDATE_RATING_MESSAGE,
});
