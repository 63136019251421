import React, { useEffect, Fragment, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Button,
  Row,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyRecruiterInviteTokenRequest,
  resetVerifyRecruiterInviteToken,
} from "../../redux/public/verify_recruiter_invite_token/actions";
import { showToast } from "../../Components/Common/Toast";
import {
  acceptOrRejectInviteRequest,
  resetAcceptOrRejectInvite,
} from "../../redux/public/accept_or_reject_invite/actions";

import { COMPANY_LOGO } from "../../Constant";
import { AuthContext } from "../../Auth/AuthContext";
const AcceptInvite = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const verifyRecruiterInviteTokenReducerData = useSelector(
    (state) => state.verifyRecruiterInviteTokenReducer
  );
  const acceptOrRejectInviteReducerData = useSelector(
    (state) => state.acceptOrRejectInviteReducer
  );

  useEffect(() => {
    if (token) {
      dispatch(verifyRecruiterInviteTokenRequest({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (acceptOrRejectInviteReducerData.message) {
      if (acceptOrRejectInviteReducerData.status) {
        showToast("success", acceptOrRejectInviteReducerData.message);

        if (acceptOrRejectInviteReducerData.invite_status) {
          if (user && user.isLoggedin === true) {
            navigate(`${process.env.PUBLIC_URL}/recruiter/invite`);
          } else {
            navigate(
              `${process.env.PUBLIC_URL}/recruiter/auth/register/invite/${token}`
            );
          }
        } else {
          if (user && user.isLoggedin === true) {
            navigate(`${process.env.PUBLIC_URL}/recruiter/invite`);
          }
        }

        dispatch(resetAcceptOrRejectInvite());
      } else {
        showToast("error", acceptOrRejectInviteReducerData.message);
      }
      dispatch(resetAcceptOrRejectInvite());
    }
  }, [acceptOrRejectInviteReducerData, dispatch, navigate, token]);

  const handleAccept = () => {
    dispatch(
      acceptOrRejectInviteRequest({
        status: "ACCEPTED",
        token,
      })
    );
  };

  const handleReject = () => {
    dispatch(
      acceptOrRejectInviteRequest({
        status: "REJECTED",
        token,
      })
    );
  };

  return (
    <Fragment>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col xl="8" lg="10" md="12">
            <Card className="shadow-sm border-0">
              <CardBody className="p-4 text-center">
                <img
                  src={COMPANY_LOGO}
                  alt="Company Logo"
                  className="mb-4"
                  style={{ width: "150px" }}
                />
                <CardTitle tag="h4" className="mb-4">
                  Verify Your Invitation
                </CardTitle>
                <CardText className="mb-4">
                  You have been invited to join our recruitment platform. Please
                  verify your invitation by clicking the button below.
                </CardText>
                <Form>
                  {verifyRecruiterInviteTokenReducerData.isLoading ? (
                    <div className="text-center">
                      <Spinner color="primary" />
                      <p className="mt-2">Verifying...</p>
                    </div>
                  ) : (
                    <>
                      {!verifyRecruiterInviteTokenReducerData.status && (
                        <CardText className="text-danger text-center">
                          Invalid Token
                        </CardText>
                      )}
                      {verifyRecruiterInviteTokenReducerData.status && (
                        <FormGroup className="d-flex justify-content-center">
                          <Button
                            color="primary"
                            className="w-48 me-2"
                            onClick={handleAccept}
                          >
                            Accept Invitation
                          </Button>
                          <Button
                            color="danger"
                            className="w-48"
                            onClick={handleReject}
                          >
                            Reject Invitation
                          </Button>
                        </FormGroup>
                      )}
                    </>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AcceptInvite;
