import * as types from "../../actionTypes";

const initialState = {
  data: {},
  isLoading: false,
  message: "",
  status: "",
};

const fetchJobseekerDataReducer = (state = initialState, action) => {
  console.log("prd", action);
  switch (action.type) {
    case types.FETCH_JOBSEEKER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_JOBSEEKER_DATA_SUCCESS:
      
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status,
      };
    case types.FETCH_JOBSEEKER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: action.payload.status,
        message: action.payload.message,
      };

    case types.RESET_DATA:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: false,
        message: "",
      };
    default:
      return state;
  }
};

export default fetchJobseekerDataReducer;
