import {
  GET_TOTAL_POINTS_REQUEST,
  GET_TOTAL_POINTS_SUCCESS,
  GET_TOTAL_POINTS_FAILURE,
  RESET_TOTAL_POINTS,
} from "../actionTypes";

const initialState = {
  total_points: "",
  isLoading: false,
  message: "",
  status: false,
};

const getTotalPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_POINTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TOTAL_POINTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total_points: action.payload.total_points,
        status: action.payload.status,
      };
    case GET_TOTAL_POINTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: "",
        message: action.payload.message,
        status: action.payload.status,
      };
    case RESET_TOTAL_POINTS: // Reset message action
      return {
        isLoading: false,
        message: "",
        status: false,
      };
    default:
      return state;
  }
};

export default getTotalPointsReducer;
