import {
  FETCH_WEEKLY_STATUS_REQUEST,
  FETCH_WEEKLY_STATUS_SUCCESS,
  FETCH_WEEKLY_STATUS_FAILURE,
} from "../../actionTypes";

export const fetchWeeklyStatusRequest = (data) => {
  return {
    type: FETCH_WEEKLY_STATUS_REQUEST,
    payload: data,
  };
};
export const fetchWeeklyStatusSuccess = (jobSources) => {
  return {
    type: FETCH_WEEKLY_STATUS_SUCCESS,
    payload: jobSources,
  };
};

export const fetchWeeklyStatusFailure = (error) => {
  return {
    type: FETCH_WEEKLY_STATUS_FAILURE,
    payload: error,
  };
};
