import React, { Fragment, useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Card,
  Badge,
} from "reactstrap";

import { IoMdAddCircleOutline } from "react-icons/io";

import AddskillModal from "./AddskillModal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { FiEdit } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";
const ResumeSkills = ({ profileData }) => {
  const dispatch = useDispatch();

  const { type, cand_id, jobcad_id } = useParams();

  const [showSkillModal, setShowSkillModal] = useState(false);

  const addSkillModal = (data) => {
    setShowSkillModal(true);
  };

  const toggleAddSkillModal = () => {
    setShowSkillModal(false);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <h4 className="card-title mb-0" style={{ fontWeight: 550 }}>
            Resume Skills
          </h4>
        </CardHeader>
        <CardBody className="">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 me-2">Skills</h5>
            {/* <RiAddBoxLine
              size={15}
              className="text-primary profile_icons"
              onClick={addSkillModal}
            /> */}
            {type == "view" ? null : (
              <FiPlusSquare
                className="add-icon "
                size={22}
                onClick={addSkillModal}
                title="Add"
              />
            )}
          </div>

          <div className="mt-3">
            {profileData.skill &&
              profileData.skill.map((skill) => (
                <Badge
                  className="me-2 mb-2 border rounded text-black"
                  color=""
                  pill
                >
                  {skill.label}
                </Badge>
              ))}
          </div>
        </CardBody>
      </Card>

      {showSkillModal && (
        <AddskillModal
          isOpen={showSkillModal}
          toggleModal={toggleAddSkillModal}
          data={profileData}
        />
      )}
    </Fragment>
  );
};
export default ResumeSkills;
