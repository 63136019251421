// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  saveFavoriteJobSuccess,
  saveFavoriteJobFailure,
  fetchFavoriteJobsSuccess,
  fetchFavoriteJobsFailure,
  deleteFavoriteJobSuccess,
  deleteFavoriteJobFailure,
} from "./actions";
import {
  SAVE_FAVORITE_JOB_REQUEST,
  FETCH_FAVORITE_JOBS_REQUEST,
  DELETE_FAVORITE_JOB_REQUEST,
} from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* saveFavoriteJob(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/save_job", action.payload)
    );
    if (response.data.status == true) {
      yield put(saveFavoriteJobSuccess(response.data));
    } else {
      yield put(saveFavoriteJobFailure(response.data));
    }
  } catch (error) {
    yield put(
      saveFavoriteJobFailure({ status: false, message: error.message })
    );
  }
}

function* fetchFavoriteJobs(action) {
  const { page, perPage } = action.payload;
  let url = `/jobseeker/show_all_saved_jobs?page=${action.payload.page}&per_page=${action.payload.per_page}`;

  if (action.payload.column) {
    url += `&sortBy=${action.payload.column}`;
  }

  if (action.payload.direction) {
    url += `&direction=${action.payload.direction}`;
  }

  try {
    const response = yield call(() =>
      AxiosInstance().post(url, action.payload.data)
    );
    if (response.data.status == true) {
      yield put(fetchFavoriteJobsSuccess(response.data));
    } else {
      yield put(fetchFavoriteJobsFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchFavoriteJobsFailure({ status: false, message: error.message })
    );
  }
}

function* deleteFavoriteJob(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/delete_saved_jobs", action.payload)
    );
    if (response.data.status == true) {
      yield put(deleteFavoriteJobSuccess(response.data));
    } else {
      yield put(deleteFavoriteJobFailure(response.data));
    }
  } catch (error) {
    yield put(
      deleteFavoriteJobFailure({ status: false, message: error.message })
    );
  }
}

function* favoritejobSaga() {
  yield takeLatest(SAVE_FAVORITE_JOB_REQUEST, saveFavoriteJob);
  yield takeLatest(FETCH_FAVORITE_JOBS_REQUEST, fetchFavoriteJobs);
  yield takeLatest(DELETE_FAVORITE_JOB_REQUEST, deleteFavoriteJob);
}

export default favoritejobSaga;
