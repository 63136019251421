import * as types from "../../actionTypes";

export const addSkillsRequest = (data) => ({
  type: types.ADD_SKILLS_REQUEST,
  payload: data,
});

export const addSkillsSuccess = (data) => ({
  type: types.ADD_SKILLS_SUCCESS,
  payload: data,
});

export const addSkillsFailure = (error) => ({
  type: types.ADD_SKILLS_FAILURE,
  payload: error,
});

export const resetData = () => ({
  type: types.RESET_DATA,
});
