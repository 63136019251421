import React, { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import Template1 from "../../Components/Jobseeker/ResumeTemplates/Template1/Template1";
import Template2 from "../../Components/Jobseeker/ResumeTemplates/Template2/Template2";
import Template3 from "../../Components/Jobseeker/ResumeTemplates/Template3/Template3";
import Template4 from "../../Components/Jobseeker/ResumeTemplates/Template4/Template4";
import Template5 from "../../Components/Jobseeker/ResumeTemplates/Template5/Template5";
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { FiEdit } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { fetchTemplatesRequest } from "../../redux/resumeTemplates/actions";
import { useDispatch, useSelector } from "react-redux";
import { shareSeekerDataRequest } from "../../redux/jobseeker/share_seeker_data/actions";
import { base64ToId } from "../../Components/Common/CommonFunction";
const TemplatePreviewPublicPage = () => {
  const { hashCode } = useParams();
  const { templateId } = useParams();
  const [selectedTemplate, setSelectedTemplate] = useState(
    base64ToId(templateId)
  );
  const dispatch = useDispatch();

  const handleDownloadClick = () => {
    console.log("download");
  };

  useEffect(() => {
    dispatch(
      shareSeekerDataRequest({
        hashcode: hashCode,
      })
    );
  }, []);

  let shareSeekerDataReducerData = useSelector(
    (state) => state.shareSeekerDataReducer
  );

  let jobseekerData = [];
  if (
    !shareSeekerDataReducerData.isLoading &&
    shareSeekerDataReducerData.data
  ) {
    jobseekerData = shareSeekerDataReducerData.data;
  }

  return (
    <Fragment>
      <Breadcrumbs title={"Preview Resume"} mainTitle={"Preview Resume"} />

      <Container fluid>
        <Row className="justify-content-center mb-4">
          <Col xl="auto" lg="auto" md="auto" className="p-0">
            {/* Row for button */}
            <Row className="mb-3">
              <div className="d-flex justify-content-end p-0">
                <Button
                  color="danger"
                  onClick={handleDownloadClick}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 20px",
                  }}
                >
                  Download
                  <FiDownload
                    style={{
                      marginLeft: "5px",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </Button>
              </div>
            </Row>

            <Row className="justify-content-center p-0">
              <Col
                className="d-flex justify-content-end p-0  rounded"
                xl="auto"
                lg="auto"
                md="auto"
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {selectedTemplate == "1" && (
                    <Template1 jobseekerData={jobseekerData} />
                  )}
                  {selectedTemplate == "2" && (
                    <Template2 jobseekerData={jobseekerData} />
                  )}
                  {selectedTemplate == "3" && (
                    <Template3 jobseekerData={jobseekerData} />
                  )}
                  {selectedTemplate == "4" && (
                    <Template4 jobseekerData={jobseekerData} />
                  )}
                  {selectedTemplate == "5" && (
                    <Template5 jobseekerData={jobseekerData} />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TemplatePreviewPublicPage;
