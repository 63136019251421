// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { jobSharingSuccess, jobSharingFailure } from "./actions";
import { JOB_SHARING_REQUEST } from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";

function* shareJob(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/job_sharing", action.payload)
    );
    if (response.data.status == true) {
      yield put(jobSharingSuccess(response.data));
    } else {
      yield put(jobSharingFailure(response.data));
    }
  } catch (error) {
    yield put(jobSharingFailure({ status: false, message: error.message }));
  }
}

function* jobSharingSaga() {
  yield takeLatest(JOB_SHARING_REQUEST, shareJob);
}

export default jobSharingSaga;
