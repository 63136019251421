import * as types from "../../actionTypes";

export const updateResumeAvatar = (data) => {
  return { type: types.UPDATE_RESUME, payload: data };
};

// Action creators for successful delete resume response
export const updateResumeSuccess = (data) => ({
  type: types.UPDATE_RESUME_SUCCESS,
  payload: data,
});

// Action creators for failed delete resume response
export const updateResumeFailure = (error) => ({
  type: types.UPDATE_RESUME_FAILURE,
  payload: error,
});

export const deleteResumeRequest = (data) => ({
  type: types.DELETE_RESUME_REQUEST,
  payload: data,
});

// Action creators for successful delete resume response
export const deleteResumeSuccess = (data) => ({
  type: types.DELETE_RESUME_SUCCESS,
  payload: data,
});

// Action creators for failed delete resume response
export const deleteResumeFailure = (error) => ({
  type: types.DELETE_RESUME_FAILURE,
  payload: error,
});
export const resetResumeData = () => ({
  type: types.RESET_DATA,
});
