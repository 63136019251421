import React, { useContext, useState } from "react";
import { Col, Input, InputGroup, Row } from "reactstrap";

import ChatAppContext from "../../../../src/Chat/index";

const SendChat = () => {
  const [messageInput, setMessageInput] = useState("");

  const context = useContext(ChatAppContext);

  if (!context) {
    return <div>Loading...</div>;
  }

  const { sendChatMessage } = context;

  const handleMessageChange = (message) => {
    setMessageInput(message);
  };

  const handleMessagePress = (e) => {
    if (e.key === "Enter" || e === "send") {
      if (messageInput.length > 0) {
        sendChatMessage(messageInput);
        setMessageInput("");
      }
    }
  };

  return (
    <div className="chat-message clearfix">
      <Row>
        {/* <div>
          {showEmojiPicker ? (
            <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
          ) : null}
        </div> */}
        <Col xl="12" className="d-flex">
          {/* <div className="smiley-box bg-primary">
            <div className="picker" onClick={() => toggleEmojiPicker()}>
              <img src="path_to_smiley_image" alt="" />
            </div>
          </div> */}
          <InputGroup className="text-box">
            <Input
              type="text"
              className="form-control input-txt-bx"
              placeholder="Type a message......"
              value={messageInput}
              onKeyPress={(e) => handleMessagePress(e)}
              onChange={(e) => handleMessageChange(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={() => handleMessagePress("send")}
            >
              Send
            </button>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

export default SendChat;
