import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerRequest, reset } from "../../redux/auth/register/actions";
import { showToast } from "../../Components/Common/Toast";
import { customOptionsStyle } from "../../Constant";
import { fetchCompanyRequest } from "../../redux/company/actions";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
const RegisterFrom = () => {
  const dispatch = useDispatch();
  const [togglePassword, setTogglePassword] = useState(false);
  const { type, token } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [companyOptions, setCompanyOptions] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    company: "",

    type: type.toUpperCase(),
  });

  useEffect(() => {
    setFormData({
      name: "",
      email: "",
      password: "",
      company: "",
      type: type ? type.toUpperCase() : "",
    });
    setErrors({});
  }, [type]);

  const handleSubmit = (e, status) => {
    e.preventDefault();

    if (validate()) {
      const formattedData = {
        name: formData.name || "",
        email: formData.email || "",
        password: formData.password || "",
        company_id: formData.company?.__isNew__ ? "" : formData.company?.value,
        company_name: formData.company.label || "",
        is_new: formData.company.__isNew__ || false,
        type: formData.type || "",
        token: token,
      };

      dispatch(registerRequest(formattedData));
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (token == null) {
      if (type.toUpperCase() === "RECRUITER") {
        if (!formData.company) {
          errors.company = "Company Name is required";
          isValid = false;
        }
      }
    }

    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (token == null) {
      if (!formData.email) {
        errors.email = "Email is required";
        isValid = false;
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
          errors.email = "Please enter a valid email";
          isValid = false;
        }
      }
    }

    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!/[a-z]/.test(formData.password)) {
      errors.password = "Password must contain at least one lowercase letter";
      isValid = false;
    } else if (!/[A-Z]/.test(formData.password)) {
      errors.password = "Password must contain at least one uppercase letter";
      isValid = false;
    } else if (!/\d/.test(formData.password)) {
      errors.password = "Password must contain at least one digit";
      isValid = false;
    } else if (!/[^a-zA-Z\d]/.test(formData.password)) {
      errors.password = "Password must contain at least one special character";
      isValid = false;
    }

    setErrors(errors);

    return isValid;
  };

  let companyReducerData = useSelector((state) => state.companyReducer);
  let companyData = companyReducerData.data.data;

  useEffect(() => {
    // Check if companyData is valid and not empty
    if (companyData && companyData.length > 0) {
      setCompanyOptions(companyData);
    }
  }, [companyData]); // Update state when companyData changes

  const handleCompanyFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = "";
        dispatch(fetchCompanyRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCompanyInputChange = useCallback((inputValue) => {
    const fetchData = async () => {
      try {
        dispatch(fetchCompanyRequest(inputValue));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleCompanyChange = (selectedOption) => {
    console.log("archana", selectedOption);
    const updatedErrors = { ...errors, company: "" };
    setErrors(updatedErrors);

    setFormData({ ...formData, company: selectedOption });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    setErrors({ ...errors, [name]: error });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let registerReducerData = useSelector((state) => state.registerReducer);
  console.log("registerReducerData", registerReducerData);
  let redirectTimer;
  useEffect(() => {
    if (registerReducerData.status && registerReducerData.message !== "") {
      showToast("success", registerReducerData.message);
      dispatch(reset());
      // Optional delay before redirection
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/${type}/auth/login/`);
      }, 2000); // Adjust the delay time as needed
    } else if (
      registerReducerData.status === false &&
      registerReducerData.message !== ""
    ) {
      showToast("error", registerReducerData.message);
      dispatch(reset());
    }
  }, [registerReducerData]);

  return (
    <Fragment>
      <div className="login-card">
        <div className="login-main">
          <Form className="theme-form login-form" onSubmit={handleSubmit}>
            <h4>Create {type} account</h4>
            <p>Enter your personal details to create account</p>
            {type.toUpperCase() === "RECRUITER" && token == null && (
              <FormGroup>
                <Label className="col-form-label m-0 pt-0">
                  Your Company<span className="text-danger">*</span>
                </Label>
                <CreatableSelect
                  isClearable
                  className=" mt-2"
                  options={companyOptions}
                  isLoading={companyReducerData.isLoading}
                  placeholder="Select Company..."
                  value={formData.company}
                  onChange={handleCompanyChange}
                  onInputChange={handleCompanyInputChange}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#f3f3ff",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      height: "46px", // Adjust height as needed
                      padding: "0px", // Adjust padding as needed
                      boxShadow: state.isFocused
                        ? "0 0 0 2px rgba(0, 123, 255, 0.2)"
                        : "none",
                      "&:hover": {
                        borderColor: "#999",
                      },
                    }),
                  }}
                  onFocus={handleCompanyFocus}
                />
                {errors.company && (
                  <small className="text-danger">{errors.company}</small>
                )}
              </FormGroup>
            )}

            <FormGroup>
              <Label className="col-form-label m-0 pt-0">
                Your Name<span className="text-danger">*</span>
              </Label>
              <Input
                className="form-control"
                type="text"
                name="name"
                placeholder="Full Name"
                onChange={handleChange}
              />
              {errors.name && (
                <small className="text-danger">{errors.name}</small>
              )}
            </FormGroup>
            {token == null && (
              <FormGroup>
                <Label className="col-form-label m-0 pt-0">
                  Email Address<span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder="abc@gmail.com"
                  onChange={handleChange}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </FormGroup>
            )}

            <FormGroup className="position-relative">
              <Label className="col-form-label m-0 pt-0">
                Password<span className="text-danger">*</span>
              </Label>
              <div className="position-relative">
                <Input
                  className="form-control"
                  type={togglePassword ? "text" : "password"}
                  name="password"
                  placeholder="********"
                  onChange={handleChange}
                />
                <div
                  className="show-hide"
                  onClick={() => setTogglePassword(!togglePassword)}
                >
                  <span className={togglePassword ? "" : "show"}></span>
                </div>
              </div>
              {errors.password && (
                <small className="text-danger">{errors.password}</small>
              )}
            </FormGroup>
            <FormGroup>
              <Button
                disabled={registerReducerData.isLoading}
                className="d-block w-100"
                color="primary"
                type="submit"
              >
                Create Account
              </Button>
            </FormGroup>
            <p className="mb-0 text-start">
              Already have an account?
              <Link
                className="ms-2"
                to={`${
                  process.env.PUBLIC_URL
                }/${type.toLowerCase()}/auth/login`}
              >
                Sign in
              </Link>
            </p>
            <p className="mb-0 text-start">
              Create{" "}
              {type.toUpperCase() === "RECRUITER" ? "jobseeker" : "recruiter"}{" "}
              account
              <Link
                className="ms-2"
                to={`${process.env.PUBLIC_URL}/${
                  type.toUpperCase() === "RECRUITER" ? "jobseeker" : "recruiter"
                }/auth/register`}
              >
                Create Account
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterFrom;
