// actions.js
import * as types from "../actionTypes";

export const fetchEducationRequest = (keyword) => ({
  type: types.FETCH_EDUCATION_REQUEST,
  payload: { keyword },
});

export const fetchEducationSuccess = (data) => ({
  type: types.FETCH_EDUCATION_SUCCESS,
  payload: { data },
});

export const fetchEducationFailure = (error) => ({
  type: types.FETCH_EDUCATION_FAILURE,
  payload: { error },
});
