import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobseekerDataRequest } from "../../../redux/jobseeker/update_profile/actions";
import {
  addMainSkillRequest,
  resetData,
} from "../../../redux/jobseeker/main_skill/actions";
import { fetchSkillsRequest } from "../../../redux/mandatorySkills/actions";
import { ratingOptions, passingyearsOptions } from "../../../Constant";
import { showToast } from "../../Common/Toast";

const TechnicalskillsModal = (props) => {
  const dispatch = useDispatch();
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [errors, setErrors] = useState({});

  const initialFormData = {
    seeker_id: props.data?.seeker_id || "",
    id: props.data?.id || "",
    it_skill: props.data?.it_skill || null,
    skill_last_used:
      props.data?.skill_last_used != null
        ? passingyearsOptions.find(
            (option) => option.value === props.data.skill_last_used
          )
        : null,
    skill_version: props.data?.skill_version || "",
    skill_rating:
      props.data?.skill_rating != null
        ? ratingOptions.find(
            (option) => option.value == props.data.skill_rating
          )
        : null,
    skill_experienced: props.data?.skill_experienced || "",
  };

  const [formData, setFormData] = useState(initialFormData);

  let skillsReducerData = useSelector((state) => state.mandatorySkillsReducer);
  let skillsData = skillsReducerData.data?.data || [];

  const handleSkillsFocus = () => {
    const fetchData = async () => {
      try {
        const keyword = "";
        dispatch(fetchSkillsRequest(keyword, "MANDATORY"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    fetchData();
  };

  const handleSkillsInputChange = useCallback(
    (inputValue) => {
      const fetchData = async () => {
        try {
          dispatch(fetchSkillsRequest(inputValue, "MANDATORY"));
        } catch (error) {
          // Handle any errors if necessary
        }
      };

      fetchData();
    },
    [dispatch]
  );

  const handleSkillsChange = (selectedOptions) => {
    setErrors((prevErrors) => ({ ...prevErrors, it_skill: "" }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      it_skill: selectedOptions,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const formattedData = {
        id: formData.id,
        seeker_id: formData.seeker_id,
        it_skill: formData.it_skill?.value || "", // Optional chaining to avoid undefined errors
        skill_last_used: formData.skill_last_used?.value || "",
        skill_version: formData.skill_version,
        skill_rating: formData.skill_rating?.value || "",
        skill_experienced: formData.skill_experienced,
      };

      dispatch(addMainSkillRequest(formattedData));
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!formData.it_skill) {
      errors.it_skill = "Skill is required";
      isValid = false;
    }

    if (!formData.skill_experienced) {
      errors.skill_experienced = "Skill experienced is required";
      isValid = false;
    } else if (isNaN(formData.skill_experienced)) {
      errors.skill_experienced = "Skill experienced must be a number";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSkillRatingChange = (selectedOption) => {
    setErrors((prevErrors) => ({ ...prevErrors, skill_rating: "" }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      skill_rating: selectedOption,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSkillLastUsedChange = (selectedOption) => {
    setErrors((prevErrors) => ({ ...prevErrors, skill_last_used: "" }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      skill_last_used: selectedOption,
    }));
  };

  const addMainSkillReducerData = useSelector(
    (state) => state.mainSkillReducer
  );

  useEffect(() => {
    if (
      addMainSkillReducerData.status &&
      addMainSkillReducerData.message !== ""
    ) {
      props.toggleModal();
    } else if (
      addMainSkillReducerData.status === false &&
      addMainSkillReducerData.message !== ""
    ) {
      props.toggleModal();
    }
  }, [addMainSkillReducerData, props]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-lg"
    >
      <Form className="theme-form" onSubmit={handleSubmit}>
        <ModalHeader toggle={props.toggleModal} className="border-0">
          <h4>{props.data.id ? "Edit Main Skill" : "Add Main Skill"}</h4>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>
                  Skill
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  options={skillsData}
                  value={formData.it_skill}
                  onChange={handleSkillsChange}
                  onFocus={handleSkillsFocus}
                  onInputChange={handleSkillsInputChange}
                  placeholder="Select Skill"
                  isLoading={skillsReducerData.isLoading}
                />
                {errors.it_skill && (
                  <small className="text-danger">{errors.it_skill}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Experience (In Month)
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="skill_experienced"
                  className="form-control form-control-sm"
                  placeholder="Enter skill experience"
                  onChange={handleChange}
                  value={formData.skill_experienced}
                />
                {errors.skill_experienced && (
                  <small className="text-danger">
                    {errors.skill_experienced}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Rating (0-10)</Label>
                <Select
                  options={ratingOptions}
                  onChange={handleSkillRatingChange}
                  value={formData.skill_rating}
                  placeholder="Select rating"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Version</Label>
                <Input
                  type="text"
                  name="skill_version"
                  className="form-control form-control-sm"
                  placeholder="Enter skill version"
                  onChange={handleChange}
                  value={formData.skill_version}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Last Used</Label>
                <Select
                  options={passingyearsOptions}
                  value={formData.skill_last_used}
                  onChange={handleSkillLastUsedChange}
                  placeholder="Select year"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggleModal}>
            Close
          </Button>
          <Button
            disabled={addMainSkillReducerData.isLoading}
            color="primary"
            type="submit"
          >
            {props.data.id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default TechnicalskillsModal;
