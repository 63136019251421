import {
  INVITE_RECRUITER_REQUEST,
  INVITE_RECRUITER_SUCCESS,
  INVITE_RECRUITER_FAILURE,
  RESET_INVITE_RECRUITER,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
};

const inviteRecruiterReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITE_RECRUITER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case INVITE_RECRUITER_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case INVITE_RECRUITER_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_INVITE_RECRUITER:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default inviteRecruiterReducer;
