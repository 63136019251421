import {
  ACCEPT_OR_REJECT_INVITE_REQUEST,
  ACCEPT_OR_REJECT_INVITE_SUCCESS,
  ACCEPT_OR_REJECT_INVITE_FAILURE,
  RESET_ACCEPT_OR_REJECT_INVITE,
} from "../../actionTypes";

export const acceptOrRejectInviteRequest = (message) => ({
  type: ACCEPT_OR_REJECT_INVITE_REQUEST,
  payload: message,
});

export const acceptOrRejectInviteSuccess = (data) => ({
  type: ACCEPT_OR_REJECT_INVITE_SUCCESS,
  payload: data,
});

export const acceptOrRejectInviteFailure = (data) => ({
  type: ACCEPT_OR_REJECT_INVITE_FAILURE,
  payload: data,
});

export const resetAcceptOrRejectInvite = () => ({
  type: RESET_ACCEPT_OR_REJECT_INVITE,
});
