import React, { Fragment } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Input,
  Form,
} from "reactstrap";
import Spinner from "../../Common/Spinner";
import { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchManageCandidates,
  downloadCandidates,
  deleteCandidateRequest,
  resetCandidate,
} from "../../../redux/recruiter/manage_candidates/actions";
import { Edit2, Trash2, Eye, Phone, Mail, Copy } from "react-feather";
import { Link } from "react-router-dom";
import { IoMdCopy } from "react-icons/io";
import { RiMailSendLine } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import {
  capitalize,
  formatDate,
  unslugAndCapitalize,
  handlePhoneCall,
  handleCopyToClipboard,
  handleSendEmail,
  generateSlug,
  formatToInteger,
} from "../../../Components/Common/CommonFunction";
import { PreloaderContext } from "../../../_helper/Preloader/PreloaderContext";
import { showToast } from "../../Common/Toast";
import { LOADER_TIMEOUT } from "../../../Constant";
import { showSweetAlert } from "../../Common/SweetAlert";
import { useNavigate } from "react-router-dom";
import { AVATAR } from "../../../Constant";
import NoDataFound from "../../Common/NoDataFound";
import { useParams } from "react-router-dom";
import { customStyles } from "../../../Constant";
import { emptyField } from "../../../Constant";
import PreviewResumeModal from "../Profile/PreviewResumeModal";
import { FiFileText } from "react-icons/fi";
import { formatExperienceOutput } from "../../../Components/Common/CommonFunction";
import { getCandidateStatusColor } from "../../../Constant";
import {
  DefinedRange,
  DateRangePicker,
  defaultStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addContactedCountRequest } from "../../../redux/jobseeker/add_contacted_count/actions";
import { addDays, startOfYear, endOfToday } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa"; // FontAwesome calendar icon

const ManageCandidate = () => {
  const navigate = useNavigate();
  const { candidateStatus } = useParams();
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [selectedIds, setSelectedIds] = useState([]);
  const dispatch = useDispatch();
  const { showPreloader } = useContext(PreloaderContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showPreviewResume, setShowPreviewResume] = useState(false);
  const [resumeData, setResumeData] = useState();
  const [datePickerOpen, setDatePickerOpen] = useState(false); // State to manage date picker visibility

  const locale = {
    localize: {
      month: (monthIndex) => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return months[monthIndex];
      },
      day: (dayIndex) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[dayIndex];
      },
    },
    formatLong: {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  };

  const customStaticRanges = [
    {
      label: "All",
      range: () => ({
        startDate: startOfYear(new Date()), // Start of current year
        endDate: endOfToday(), // Today
        type: "ALL",
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Your Candidate",
      range: () => ({
        startDate: startOfYear(new Date()), // Start of current year
        endDate: endOfToday(), // Today
        type: "YOUR_CANDIDATE",
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Bulk Upload",
      range: () => ({
        startDate: startOfYear(new Date()), // Start of current year
        endDate: endOfToday(), // Today
        type: "BULK_UPLOAD",
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    ...defaultStaticRanges.map((range) => ({
      ...range,
      range: () => ({
        ...range.range(),
        type: range.label.toUpperCase().replace(/ /g, "_"), // Generate a type from the label
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.type === definedRange.type &&
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    })),
  ];

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    let selectedType = "CUSTOM"; // Default to CUSTOM

    // Check if the selected range matches any predefined ranges
    for (let range of customStaticRanges) {
      const definedRange = range.range();
      if (
        selection.startDate.getTime() === definedRange.startDate.getTime() &&
        selection.endDate.getTime() === definedRange.endDate.getTime() &&
        selection.type === definedRange.type
      ) {
        selectedType = definedRange.type;
        break;
      }
    }

    setFormData({
      ...formData,
      dateRange: {
        ...selection,
        type: selectedType, // Set the type based on the selected range
      },
    });
    setDatePickerOpen(false); // Close the date picker after selecting a valid date range
  };

  const [formData, setFormData] = useState({
    dateRange: {
      key: "selection",
      startDate: startOfYear(new Date()), // Start of current year
      endDate: endOfToday(), // Today
      type: "ALL",
    },
  });

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      type: formData.dateRange.type,
      keyword: searchQuery,
      startDate: formData.dateRange.startDate
        ? formData.dateRange.startDate.toISOString()
        : "", // Convert Date objects to strings
      endDate: formData.dateRange.endDate
        ? formData.dateRange.endDate.toISOString()
        : "",
      ...(candidateStatus && { status: candidateStatus.toUpperCase() }),
    };
    dispatch(fetchManageCandidates(currentPage, perPage, data));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleDelete = (seeker_id) => {
    showSweetAlert("confirm", {
      message: "You want to delete the candidate",
      fun: deleteJob,
      id: seeker_id,
    });
  };

  const deleteJob = (seeker_id) => {
    dispatch(deleteCandidateRequest({ seeker_id: seeker_id }));
  };

  const handleRowSelected = (selectedRows) => {
    const selectedIds = selectedRows.selectedRows.map((row) => row.seeker_id);
    setSelectedIds(selectedIds);
  };

  const handleMailClick = (email, seekerId) => {
    handleSendEmail(email);
    dispatch(addContactedCountRequest({ seeker_id: seekerId })); // Dispatch your action with the necessary payload
  };

  const handlePhoneClick = (phone, seekerId) => {
    handlePhoneCall(phone);
    dispatch(addContactedCountRequest({ seeker_id: seekerId })); // Dispatch your action with the necessary payload
  };

  const handleCopyClick = (data, seekerId) => {
    handleCopyToClipboard(data);
    dispatch(addContactedCountRequest({ seeker_id: seekerId }));
  };

  const tableColumns = [
    // {
    //   name: "#",
    //   cell: (row, index) => index + 1,
    //   width: "60px",
    //   sortable: true,
    //   center: false,
    // },
    {
      //  width: "10%",
      short_column: "candidate_name",
      name: "Name",
      cell: (row) => (
        <div className="d-flex align-items-center gap-1">
          <img
            className="img-fluid img-30 rounded-circle"
            src={row.jobseeker_photo || AVATAR}
            alt="user"
          />
          <Link
            to={`${
              process.env.PUBLIC_URL
            }/recruiter/job_candidate_detail/view/${generateSlug(
              row.jobseeker_full_name,
              row.jobseeker_location,
              row.seeker_id
            )}/${generateSlug(row.jobseeker_full_name, row.job_name, row.id)}`}
            title="View Candidate"
          >
            {capitalize(row.jobseeker_full_name) || "-"}
          </Link>
        </div>
      ),
      sortable: true,
      center: false,
    },
    {
      name: "Email",
      short_column: "candidate_email",
      // selector: (row) => (
      //   <span
      //     style={{ cursor: "pointer" }}
      //     onClick={() => handleSendEmail(row.jobseeker_email)}
      //   >
      //     {row.jobseeker_email || "-"}
      //   </span>
      // ),
      center: false,
      sortable: true,
      width: "11%",
      cell: (row) => (
        <>
          {row.jobseeker_email1 ? (
            <span style={{ cursor: "pointer" }}>
              {row.jobseeker_email1 && (
                <>
                  <div className="mb-2">
                    <span
                      className="email-tooltip"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={emptyField(row.jobseeker_email1.trim())}
                    >
                      {emptyField(row.jobseeker_email1.trim()).length > 6
                        ? emptyField(row.jobseeker_email1.trim()).substring(
                            0,
                            6
                          ) + "..."
                        : emptyField(row.jobseeker_email1.trim())}
                    </span>
                    <RiMailSendLine
                      className="email-icon ms-1"
                      title="Email"
                      size={17}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleMailClick(row.jobseeker_email1, row.seeker_id)
                      }
                    />{" "}
                    <IoMdCopy
                      className="copy-icon me-0"
                      size={17}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleCopyClick(row.jobseeker_email1, row.seeker_id)
                      }
                      title="Copy"
                    />{" "}
                  </div>
                </>
              )}
              {/* {row.jobseeker_email2 && (
                <>
                  {emptyField(row.jobseeker_email2.trim()).length > 17
                    ? emptyField(row.jobseeker_email2.trim()).substring(0, 17) +
                      "..."
                    : emptyField(row.jobseeker_email2.trim())}
                  <Mail
                    size={18}
                    color="gray"
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                      verticalAlign: "middle",
                    }}
                    onClick={() => handleSendEmail(row.jobseeker_email2)}
                  />
                  <Copy
                    size={18}
                    color="gray"
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      verticalAlign: "middle",
                    }}
                    onClick={() => handleCopyToClipboard(row.jobseeker_email2)}
                  />
                </>
              )} */}
            </span>
          ) : (
            "-" // Show hyphen if email data does not exist
          )}
          <br />
        </>
      ),
    },
    {
      name: "Mobile No",
      short_column: "candidate_mobile",
      center: false,
      sortable: true, // Ensure this is set to true
      width: "12%",
      cell: (row) => (
        <>
          {row.jobseeker_phone1 ? (
            <span style={{ cursor: "pointer" }}>
              {row.jobseeker_phone1 && (
                <>
                  <div className="mb-2">
                    <span
                      className="email-tooltip"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={emptyField(row.jobseeker_phone1.trim())}
                    >
                      {row.jobseeker_phone1.length > 6
                        ? `${row.jobseeker_phone1.trim().substring(0, 6)}...`
                        : row.jobseeker_phone1.trim()}
                    </span>
                    <IoCallOutline
                      className="phone-icon ms-1"
                      size={17}
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() =>
                        handlePhoneClick(row.jobseeker_phone1, row.seeker_id)
                      }
                      title="Call"
                    />{" "}
                    <IoMdCopy
                      className="copy-icon me-0"
                      size={17}
                      style={{ verticalAlign: "middle" }}
                      onClick={() =>
                        handleCopyClick(row.jobseeker_phone1, row.seeker_id)
                      }
                      title="Copy"
                    />{" "}
                  </div>
                </>
              )}
              {/* {row.jobseeker_phone2 && row.country_code2 && (
                <>
                  {`${row.country_code2}${row.jobseeker_phone2.trim()}`}

                  <Phone
                    size={18}
                    color="gray"
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    onClick={() => handlePhoneCall(row.jobseeker_phone2)}
                  />
                  <Copy
                    size={18}
                    color="gray"
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    onClick={() => handleCopyToClipboard(row.jobseeker_phone2)}
                  />
                </>
              )} */}
            </span>
          ) : (
            "-" // Show hyphen if phone data does not exist
          )}
          <br />
        </>
      ),
    },
    {
      name: "Location",
      short_column: "jobseeker_location",
      cell: (row) => capitalize(row.jobseeker_location) || "-",
      sortable: true,
      center: false,
      width: "11%",
    },
    {
      name: "Total Exp (Years)",
      center: true,
      width: "8%",
      cell: (row) => {
        const experienceOutput = row.total_exp;
        return experienceOutput ? experienceOutput : "-";
        // return 4;
      },
    },
    {
      name: "Current CTC (LPA)",
      center: true,
      width: "8%",
      cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {(row.current_salary && formatToInteger(row.current_salary)) || "-"}
        </div>
      ),
    },
    {
      name: "EXP CTC (LPA)",
      center: true,
      width: "7%",
      cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {(row.jobseeker_preferred_salary &&
            formatToInteger(row.jobseeker_preferred_salary)) ||
            "-"}
        </div>
      ),
    },
    {
      name: "Notice Period (Days)",
      center: true,
      width: "8%",
      cell: (row) =>
        row.jobseeker_notice_period ? `${row.jobseeker_notice_period}` : "-",
    },
    {
      name: "Status",
      center: true,
      width: "8%",
      cell: (row) => (
        <span
          className={`badge ${getCandidateStatusColor(row.candidate_status)}`}
        >
          {unslugAndCapitalize(row.candidate_status)}
        </span>
      ),
    },
    // {
    //   name: "Applied On",
    //   center: true,
    //   width: "120px",
    //   cell: (row) => formatDate(row.created_at) || "-",
    // },
    {
      width: "12%",
      name: "Actions",
      center: true,
      cell: (row) => (
        <div>
          <span>
            <Link
              to={`${
                process.env.PUBLIC_URL
              }/recruiter/job_candidate_detail/view/${generateSlug(
                row.jobseeker_full_name,
                row.jobseeker_location,
                row.seeker_id
              )}/${generateSlug(
                row.jobseeker_full_name,
                row.job_name,
                row.id
              )}`}
              title="View Candidate"
            >
              <Eye
                size={18}
                className="eye-icon"
                style={{ marginRight: "7px" }}
              />
            </Link>
            <Link
              to={`${
                process.env.PUBLIC_URL
              }/recruiter/job_candidate_detail/edit/${generateSlug(
                row.jobseeker_full_name,
                row.jobseeker_location,
                row.seeker_id
              )}/${generateSlug(
                row.jobseeker_full_name,
                row.job_name,
                row.id
              )}`}
              title="Edit Candidate"
            >
              <Edit2
                size={18}
                className="edit-icon"
                style={{ marginRight: "7px" }}
              />
            </Link>
            {row.is_uploaded_resume && (
              <FiFileText
                size={18}
                className="file-icon"
                style={{ marginRight: "7px", cursor: "pointer" }}
                onClick={() => handlePreviewClick(row)}
                title="View Resume"
              />
            )}
            <span title="Delete Candidate">
              <Trash2
                size={18}
                onClick={() => handleDelete(row.id)}
                className="delete-icon"
                style={{ cursor: "pointer" }}
              />
            </span>
          </span>
        </div>
      ),
    },
  ];

  const togglePreviewResumeModal = () => {
    setShowPreviewResume(false);
  };

  const handlePreviewClick = (row) => {
    if (row.is_uploaded_resume) {
      setShowPreviewResume(true);
      setResumeData(row);
    }
  };

  const downloadBtn = () => {
    dispatch(downloadCandidates({ seeker_ids: selectedIds }));
  };

  const manageCandidateReducerData = useSelector(
    (state) => state.manageCandidateReducer
  );

  useEffect(() => {
    const page = currentPage;
    const per_page = perPage;
    const data = {
      type: formData.dateRange.type,
      keyword: searchQuery,
      startDate: formData.dateRange.startDate
        ? formData.dateRange.startDate.toISOString()
        : "", // Convert Date objects to strings
      endDate: formData.dateRange.endDate
        ? formData.dateRange.endDate.toISOString()
        : "",
      ...(candidateStatus && { status: candidateStatus.toUpperCase() }),
    };
    dispatch(fetchManageCandidates(page, per_page, data));
  }, [perPage, currentPage, candidateStatus, formData]);

  // handle server side sorting
  const handleSort = async (column, direction) => {
    try {
      const page = currentPage;
      const per_page = perPage;
      const short_by = column.short_column;
      const column_direction = direction;
      const data = {
        type: formData.dateRange.type,
        keyword: searchQuery,
        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : "", // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : "",
        ...(candidateStatus && { status: candidateStatus.toUpperCase() }),
      };
      dispatch(
        fetchManageCandidates(page, per_page, data, short_by, column_direction)
      );
    } catch (error) {
      console.error("Error fetching sorted data:", error);
    }
  };

  console.log("rd", manageCandidateReducerData);

  const candidateData = manageCandidateReducerData.data.data;

  useEffect(() => {
    if (
      manageCandidateReducerData.status &&
      manageCandidateReducerData.message !== ""
    ) {
      showToast("success", manageCandidateReducerData.message);
      dispatch(resetCandidate());
      const data = {
        type: formData.dateRange.type,
        keyword: searchQuery,
        startDate: formData.dateRange.startDate
          ? formData.dateRange.startDate.toISOString()
          : "", // Convert Date objects to strings
        endDate: formData.dateRange.endDate
          ? formData.dateRange.endDate.toISOString()
          : "",
        ...(candidateStatus && { status: candidateStatus.toUpperCase() }),
      };
      const page = currentPage;
      const per_page = perPage;
      dispatch(fetchManageCandidates(page, per_page, data));
    } else if (
      manageCandidateReducerData.status === false &&
      manageCandidateReducerData.message !== ""
    ) {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", manageCandidateReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", manageCandidateReducerData.message);
      }
      dispatch(resetCandidate());
    }
  }, [manageCandidateReducerData]);

  return (
    <Fragment>
      <Row>
        <Col xs="12" className="box-col-12 xl-100">
          <Card>
            <CardHeader className="py-3">
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                {/* Search Bar */}

                <Form
                  className="theme-form me-2 flex-grow-1"
                  onSubmit={handleSubmit}
                >
                  <div
                    className="input-group flex-nowrap "
                    style={{ height: 35, maxWidth: "350px" }}
                  >
                    <Input
                      className="form-control"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="search"
                      placeholder="Search.."
                    />
                    <Button
                      color="primary"
                      type="submit"
                      className="btn-primary input-group-text pe-3 ps-3"
                    >
                      Search
                    </Button>
                  </div>
                </Form>
                {candidateData && (
                  <Button
                    disabled={selectedIds.length > 0 ? false : true}
                    color="primary"
                    className=" me-2 ps-3 pe-3 mt-3 mt-md-0"
                    onClick={downloadBtn}
                  >
                    XLS Export
                  </Button>
                )}
                <div className="d-flex align-items-center ms-auto mt-3 mt-md-0">
                  <Button
                    color="primary"
                    id="shownCalendar"
                    onClick={() => setDatePickerOpen(!datePickerOpen)}
                    className="btn btn-primary d-flex align-items-center ps-3 pe-3"
                  >
                    <FaCalendarAlt className="" />
                    <span className="ms-2">
                      {formData &&
                        (formData.dateRange.type === "CUSTOM"
                          ? `${formatDate(
                              formData.dateRange.startDate,
                              "dd-MM-yyyy"
                            )} - ${formatDate(
                              formData.dateRange.endDate,
                              "dd-MM-yyyy"
                            )}`
                          : unslugAndCapitalize(formData.dateRange.type))}
                    </span>
                  </Button>
                  {datePickerOpen && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50px", // Adjust as needed based on your layout
                        right: "0",
                        zIndex: 1000,
                      }}
                    >
                      {/* <DefinedRange
                          onChange={handleDateRangeChange}
                          ranges={[formData.dateRange]}
                          open={datePickerOpen}
                        /> */}
                      <DateRangePicker
                        editableDateInputs
                        onChange={handleDateRangeChange}
                        ranges={[formData.dateRange]}
                        locale={locale}
                        direction="horizontal"
                        open={datePickerOpen}
                        onToggle={(open) => setDatePickerOpen(open)}
                        staticRanges={customStaticRanges}
                      />
                    </div>
                  )}
                </div>
              </div>
            </CardHeader>

            <CardBody className="px-0 py-3">
              <DataTable
                customStyles={customStyles}
                data={manageCandidateReducerData.data.data}
                columns={tableColumns}
                striped={true}
                center={true}
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                pagination
                paginationServer
                paginationTotalRows={manageCandidateReducerData.data.total}
                paginationPerPage={perPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={manageCandidateReducerData.isLoading}
                progressComponent={<Spinner />}
                noDataComponent={
                  <NoDataFound message={"No candidates found"} />
                }
                onSort={(column, direction) => handleSort(column, direction)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showPreviewResume && (
        <PreviewResumeModal
          isOpen={showPreviewResume}
          toggleModal={togglePreviewResumeModal}
          data={resumeData}
        />
      )}
    </Fragment>
  );
};

export default ManageCandidate;
