// actions.js
import * as types from "../actionTypes";

export const fetchTemplatesRequest = (keyword) => ({
  type: types.FETCH_TEMPLATES_REQUEST,
  payload: { keyword },
});

export const fetchTemplatesSuccess = (data) => ({
  type: types.FETCH_TEMPLATES_SUCCESS,
  payload: { data },
});

export const fetchTemplatesFailure = (error) => ({
  type: types.FETCH_TEMPLATES_FAILURE,
  payload: { error },
});
