// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchUnreadNotificationsSuccess,
  fetchAllNotificationsSuccess,
  fetchNotificationsFailure,
  markAllAsReadSuccess,
  markAllAsReadFailure,
} from "./actions";
import {
  FETCH_UNREAD_NOTIFICATIONS_REQUEST,
  FETCH_ALL_NOTIFICATIONS_REQUEST,
  MARK_ALL_AS_READ_REQUEST,
} from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";

function* fetchUnreadNotifications(action) {
  const { data, page, perPage } = action.payload;
  try {
    const response = yield call(() =>
      AxiosInstance().post(
        `/notifications?page=${page}&per_page=${perPage}`,
        data
      )
    );

    if (response.data.status) {
      yield put(fetchUnreadNotificationsSuccess(response.data));
    } else {
      yield put(fetchUnreadNotificationsSuccess(response.data));
    }
  } catch (error) {
    yield put(
      fetchNotificationsFailure({ status: false, message: error.message })
    );
  }
}

function* fetchAllNotifications(action) {
  const { data, page, perPage } = action.payload;
  try {
    const response = yield call(() =>
      AxiosInstance().post(
        `/notifications?page=${page}&per_page=${perPage}`,
        data
      )
    );

    if (response.data.status) {
      yield put(fetchAllNotificationsSuccess(response.data));
    } else {
      yield put(fetchAllNotificationsSuccess(response.data));
    }
  } catch (error) {
    yield put(
      fetchNotificationsFailure({ status: false, message: error.message })
    );
  }
}

function* markAllAsRead(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/change_notification_status", action.payload)
    );
    if (response.data.status) {
      yield put(markAllAsReadSuccess(response.data));
    } else {
      yield put(markAllAsReadFailure(response.data));
    }
  } catch (error) {
    yield put(markAllAsReadFailure({ status: false, message: error.message }));
  }
}

function* notificationsSaga() {
  yield takeLatest(
    FETCH_UNREAD_NOTIFICATIONS_REQUEST,
    fetchUnreadNotifications
  );
  yield takeLatest(FETCH_ALL_NOTIFICATIONS_REQUEST, fetchAllNotifications);
  yield takeLatest(MARK_ALL_AS_READ_REQUEST, markAllAsRead);
}

export default notificationsSaga;
