import {
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
} from "../../actionTypes";

export const fetchJobsRequest = (page, per_page, data) => ({
  type: FETCH_JOBS_REQUEST,
  payload: { page, per_page, data },
});

export const fetchJobsSuccess = (data) => ({
  type: FETCH_JOBS_SUCCESS,
  payload: data,
});

export const fetchJobsFailure = (error) => ({
  type: FETCH_JOBS_FAILURE,
  payload: error,
});
