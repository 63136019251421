import React, {
  useEffect,
  Fragment,
  useContext,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobsRequest } from "../../../redux/jobseeker/recommended_jobs/actions";
import { Card, CardBody, Col, Row, Badge } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import { MdCurrencyRupee } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import { FiStar } from "react-icons/fi";
import {
  timeAgo,
  capitalize,
  removeHTMLTags,
  formatSalaryOutput,
  formatExperienceOutput,
  generateSlug,
} from "../../Common/CommonFunction";
import Spinner from "../../Common/Spinner";
import NoDataFound from "../../Common/NoDataFound";
import { AuthContext } from "../../../Auth/AuthContext";
import Pagination from "../../Common/Pagination";
import {
  saveFavoriteJobRequest,
  resetFavoriteMessage,
} from "../../../redux/jobseeker/favorite_job/actions";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Common/Toast";
import { PiSuitcaseSimple } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";

const RecommendedJobs = () => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(6);
  const navigate = useNavigate();
  const [maxHeight, setMaxHeight] = useState(0);
  const cardRefs = useRef([]);
  const [savedJobs, setSavedJobs] = useState({});
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const recommendedJobsReducer = useSelector(
    (state) => state.recommendedJobsReducer
  );

  let recommendedData = [];
  if (!recommendedJobsReducer.isLoading && recommendedJobsReducer.data) {
    recommendedData = recommendedJobsReducer.data;
  }

  const totalPages = recommendedData
    ? Math.ceil(recommendedData.total / perPage)
    : 0;

  useEffect(() => {
    dispatch(fetchJobsRequest(currentPage, perPage));
  }, [currentPage, perPage]);

  const handleFavouriteJobClick = (id) => {
    if (user.isLoggedin) {
      dispatch(saveFavoriteJobRequest({ job_id: id }));
      setSavedJobs((prev) => ({ ...prev, [id]: true }));
    } else {
      navigate(`${process.env.PUBLIC_URL}/auth/login`);
    }
  };

  useEffect(() => {
    if (
      recommendedData &&
      recommendedData.data &&
      recommendedData.data.length > 0
    ) {
      const initialSavedJobs = recommendedData.data.reduce((acc, job) => {
        acc[job.job_id] = job.is_saved;
        return acc;
      }, {});
      setSavedJobs(initialSavedJobs);
    }
  }, [recommendedData.data]);

  const favoriteJobReducerData = useSelector(
    (state) => state.favoriteJobReducer
  );
  useEffect(() => {
    if (
      favoriteJobReducerData.status &&
      favoriteJobReducerData.message !== ""
    ) {
      showToast("success", favoriteJobReducerData.message);
      dispatch(resetFavoriteMessage());
      //  navigate(`${process.env.PUBLIC_URL}/jobseeker/saved_jobs`);
    } else if (
      favoriteJobReducerData.status === false &&
      favoriteJobReducerData.message !== ""
    ) {
      showToast("error", favoriteJobReducerData.message);
      dispatch(resetFavoriteMessage());
    }
  }, [favoriteJobReducerData]);
  const handleCardClick = (event, job) => {
    event.preventDefault(); // Prevent the default behavior of the link

    // Custom logic using the job object
    const targetUrl =
      user.isLoggedin && user.type === "JOBSEEKER"
        ? `${process.env.PUBLIC_URL}/jobseeker/job_details/${generateSlug(
            job.job_name,
            job.job_location,
            formatExperienceOutput(
              job.work_experience_min,
              job.work_experience_max
            ).replace("-", "-to-"),
            job.job_id
          )}`
        : `${process.env.PUBLIC_URL}/public/job_details/${generateSlug(
            job.job_name,
            job.job_location,
            formatExperienceOutput(
              job.work_experience_min,
              job.work_experience_max
            ).replace("-", "-to-"),
            job.job_id
          )}`;

    // Perform the redirection
    navigate(targetUrl);
  };
  return (
    <Fragment>
      {recommendedJobsReducer.isLoading ? (
        <Spinner color="primary" />
      ) : recommendedData &&
        recommendedData.status &&
        recommendedData.data &&
        recommendedData.data.length > 0 ? (
        <Fragment>
          <Row xs="1" sm="2">
            {recommendedData.data.map((job, index) => (
              <Col key={index} xs="12" sm="6" md="6" lg="6" xl="6">
                <Card style={{ minHeight: "285px" }}>
                  <div className="job-search">
                    <CardBody>
                      <div className="media">
                        <div className="media-body">
                          <h5 className="mb-0">
                            <Link onClick={(e) => handleCardClick(e, job)}>
                              {capitalize(job.job_name)}
                            </Link>
                          </h5>
                          <span
                            className="me-2 d-flex align-items-center  f-w-500 "
                            style={{ minHeight: "1em" }}
                          >
                            {/* <FaRegBuilding
                              size={16}
                              className="me-1"
                              style={{ color: "green" }}
                            /> */}
                            <span className="me-1">
                              {job.company_name && capitalize(job.company_name)}
                            </span>

                            {job.average_rating && (
                              <div className="stars me-2 icon-container ">
                                {[...Array(5)].map((_, i) => {
                                  const starValue = i + 1;
                                  const averageRating =
                                    job.average_rating ?? "";
                                  return (
                                    <span
                                      key={starValue}
                                      className=""
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          starValue <= averageRating
                                            ? "orange"
                                            : "gray",
                                        fontSize: "calc(0.8em + 0.5vw)",
                                      }}
                                    >
                                      &#9733;
                                    </span>
                                  );
                                })}
                              </div>
                            )}
                          </span>
                          <p
                            className=" d-flex align-items-center flex-wrap  f-w-500"
                            style={{ color: "#121224", minHeight: "3em" }} // Adjust minHeight as needed
                          >
                            <span className=" d-flex align-items-center">
                              <PiSuitcaseSimple
                                size={19}
                                className="me-1 suit-case-icon"
                              />
                              {job.work_experience_min ||
                              job.work_experience_max ? (
                                <div className="mb-0 d-flex align-items-center">
                                  <span>
                                    {formatExperienceOutput(
                                      job.work_experience_min,
                                      job.work_experience_max
                                    )}
                                  </span>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </span>
                            <span className="mx-2">|</span>{" "}
                            {job.is_disclose_salary == 1 ? (
                              // Salary is disclosed
                              <span className="me-2 mb-0 d-flex align-items-center">
                                <MdCurrencyRupee
                                  className="me-1 rupee-icon"
                                  size={18}
                                />
                                {job.salary_min || job.salary_max
                                  ? formatSalaryOutput(
                                      job.salary_min,
                                      job.salary_max
                                    )
                                  : "N/A"}
                              </span>
                            ) : (
                              // Salary is not disclosed
                              <span className="me-2 mb-0 d-flex align-items-center">
                                <MdCurrencyRupee
                                  className="me-1 rupee-icon"
                                  size={18}
                                />
                                Not disclosed
                              </span>
                            )}
                            <span className="mx-2">|</span>{" "}
                            <span className="d-flex align-items-center">
                              <GrLocation
                                size={19}
                                className="me-1 location-icon"
                              />
                              {job.job_location
                                ? capitalize(job.job_location)
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                        {job.company_logo ? (
                          <img
                            src={job.company_logo}
                            alt="Badge Placeholder"
                            className="badge-image"
                            width={60}
                            height={60}
                          />
                        ) : job.company_name ? (
                          <div className="badge-image" style={logoBoxStyle}>
                            {job.company_name
                              ? job.company_name.charAt(0).toUpperCase()
                              : ""}
                          </div>
                        ) : (
                          <div className="badge-image" style={logoBoxStyle}>
                            <FaRegBuilding />
                          </div>
                        )}
                      </div>
                      {job.job_description && (
                        <div
                          className="pt-2"
                          style={{
                            maxHeight: "54px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2, // Maximum number of lines to display
                            whiteSpace: "normal",
                            //  fontSize: "14px", // Set the font size to 15px
                            // color: "#999999", // Adjust the color as needed
                          }}
                        >
                          {removeHTMLTags(job.job_description)}
                        </div>
                      )}
                      {job.mandatory_skills && (
                        <div className="mb-2 mt-2">
                          <div>
                            {job.mandatory_skills && (
                              <div className="mb-3">
                                <div>
                                  {job.mandatory_skills
                                    .split(",")
                                    .map((skill) => skill.trim())
                                    .sort((a, b) =>
                                      a === job.primary_skill
                                        ? -1
                                        : b === job.primary_skill
                                        ? 1
                                        : 0
                                    ) // Sort to ensure primary skill is first
                                    .slice(0, 3)
                                    .map((skill, index) => {
                                      const isPrimary =
                                        skill === job.primary_skill; // Check if current skill is primary
                                      return (
                                        <Badge
                                          key={index}
                                          className={`me-2 mt-2 border rounded ${
                                            isPrimary
                                              ? "fw-bolder text-primary"
                                              : "text-black"
                                          }`}
                                          // style={{
                                          //   padding: "8px 10px",
                                          //   color: "black",
                                          //   whiteSpace: "normal", // Ensure text wraps within badge
                                          //   maxWidth: "100%", // Allow badges to grow to a maximum width
                                          // }}
                                          color="gray"
                                          pill
                                        >
                                          {skill}
                                          {isPrimary && (
                                            <FiStar
                                              style={{
                                                marginLeft: "5px",
                                                color: "blue", // Example: Change star color for primary skill
                                              }}
                                            />
                                          )}
                                        </Badge>
                                      );
                                    })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div>
                        <p className="mt-2 d-flex justify-content-between">
                          {job.created_at && (
                            <span className="d-flex align-items-center text-muted ">
                              <LuClock3
                                size={16}
                                style={{
                                  marginRight: "5px",
                                }}
                              />{" "}
                              {timeAgo(job.created_at)}
                            </span>
                          )}

                          {user.isLoggedin && user.type == "JOBSEEKER" && (
                            <span
                              onClick={() => {
                                if (!savedJobs[job.job_id])
                                  handleFavouriteJobClick(job.job_id);
                              }}
                              className={`sidebar-link sidebar-title link-nav d-flex align-items-center  ${
                                savedJobs[job.job_id] ? "disabled" : ""
                              }`}
                              style={{ cursor: "pointer" }} // Inline style as fallback
                            >
                              <FaRegHeart className="me-2" size={16} />
                              {savedJobs[job.job_id] ? "Saved" : "Save"}
                            </span>
                          )}
                        </p>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Fragment>
      ) : (
        <Card className="pt-3 pb-3">
          <NoDataFound message={"No recommended jobs found"} />
        </Card>
      )}
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </Fragment>
  );
};

const logoBoxStyle = {
  width: "60px",
  height: "50px",
  backgroundColor: "#7366ff",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "25px",
  fontWeight: 500,
  color: "white",
};

export default RecommendedJobs;
