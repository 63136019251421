import React, { Fragment, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Taptop from "../TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "../Footer";
import Loader from "../Loader";
import CustomizerContext from "../../_helper/Customizer";
const JobseekerLayout = () => {
  const { toggleIcon } = useContext(CustomizerContext);
  const location = useLocation();
   return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className={`page-wrapper compact-wrapper`} sidebar-layout="stroke-svg" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={100} classNames="fadeIn" unmountOnExit>
              <div className="page-body" style={{ marginLeft: (toggleIcon)?'90px':'265px' }}>
                <div>
                  <div>
                    <Outlet />
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default JobseekerLayout;
