// actions.js
import * as types from '../actionTypes';


export const fetchIndustryRequest = (keyword) => ({
    type: types.FETCH_INDUSTRY_REQUEST,
    payload: { keyword },
  });
  
  export const fetchIndustrySuccess = (data) => ({
    type: types.FETCH_INDUSTRY_SUCCESS,
    payload: { data },
  });
  
  export const fetchIndustryFailure = (error) => ({
    type: types.FETCH_INDUSTRY_FAILURE,
    payload: { error },
  });