import { takeLatest, put, call } from "redux-saga/effects";
import {
  DOWNLOAD_CANDIDATES,
  DELETE_CANDIDATE_REQUEST,
  FETCH_MANAGE_CANDIDATES_REQUEST,
  FETCH_MANAGE_CANDIDATES_FAILURE,
  FETCH_MANAGE_CANDIDATES_SUCCESS,
} from "../../actionTypes";
import {
  fetchManageCandidatesFailure,
  fetchManageCandidatesSuccess,
  deleteCandidateSuccess,
  deleteCandidateFailure,
} from "./actions";

import AxiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

function* fetchManageCandidatesSaga(action) {
  console.log("action.payload.status", action.payload.status);

  let url = `/recruiter/manage_candidates?page=${action.payload.page}&per_page=${action.payload.per_page}`;

  if (action.payload.column) {
    url += `&sortBy=${action.payload.column}`;
  }

  if (action.payload.direction) {
    url += `&direction=${action.payload.direction}`;
  }

  try {
    const response = yield AxiosInstance().post(url, action.payload.data);

    if (response.data.status) {
      yield put(fetchManageCandidatesSuccess(response.data));
    } else {
      yield put(fetchManageCandidatesFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchManageCandidatesFailure({ status: false, message: error.message })
    );
  }
}

function* downloadCandidate(action) {
  try {
    console.log(action.payload);

    // const ids = {
    //   seeker_ids: action.payload,
    // };

    const response = yield AxiosInstance().post(
      `/recruiter/csv_download`,
      action.payload
    );

    console.log(response);
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const downloadLink = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);

    downloadLink.download = "exported_data.xls";
    downloadLink.href = blobUrl;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error:", error);
    // Handle errors here
  }
  // try {
  //     const ids = {
  //     job_id : 217
  //   }
  //     const response = yield AxiosInstance().post(
  //       'recruiter/csv_download',ids
  //       );
  //     console.log("api data: ",response);
  //   } catch (error) {
  //   }
}

function* deleteCandidate(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post(`/recruiter/delete_candidate`, action.payload)
    );
    console.log("delres", response);

    yield put(deleteCandidateSuccess(response.data));
  } catch (error) {
    yield put(
      deleteCandidateFailure({ status: false, message: error.message })
    );
  }
}

export default function* manageCandidateSaga() {
  yield takeLatest(FETCH_MANAGE_CANDIDATES_REQUEST, fetchManageCandidatesSaga);

  yield takeLatest(DOWNLOAD_CANDIDATES, downloadCandidate);
  yield takeLatest(DELETE_CANDIDATE_REQUEST, deleteCandidate);
}
