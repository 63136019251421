import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";
import { showToast } from "../../../Components/Common/Toast";
import { deleteResumeFailure, deleteResumeSuccess } from "./actions";
function* fetchJobseekerData(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/jobseeker_data", action.payload)
    );
    if (response.data.status) {
      yield put({
        type: types.FETCH_JOBSEEKER_DATA_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: types.FETCH_JOBSEEKER_DATA_FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.FETCH_JOBSEEKER_DATA_FAILURE,
      payload: { status: false, message: error.message },
    });
  }
}

function* fetchJobseekerDataSaga() {
  yield takeLatest(types.FETCH_JOBSEEKER_DATA_REQUEST, fetchJobseekerData);
}

export default fetchJobseekerDataSaga;
