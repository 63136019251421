import React from "react";
import "./Template5.css";
import github from "../../../../assets/images/resumeIcons/github.png";
import linkedin from "../../../../assets/images/resumeIcons/linkedin.png";
import email from "../../../../assets/images/resumeIcons/email.png";
import call from "../../../../assets/images/resumeIcons/call.png";
import location from "../../../../assets/images/resumeIcons/location.png";
import {
  capitalize,
  capitalizeEachWord,
  formatDate,
  capitalizeFirstLetter,
} from "../../../Common/CommonFunction";
import { educationLevelsOptions } from "../../../../Constant";

const Template5 = ({ jobseekerData = {} }) => {
  console.log("jobseekerData443", jobseekerData);

  const emptyField = (str) => {
    return str === "" || str === null ? "-" : str;
  };

  const calculateProgressValue = (rating) => {
    // Convert rating out of 10 to a percentage
    return (rating / 10) * 100;
  };

  const {
    jobseeker_full_name = "",
    jobseeker_date_of_birth = "",
    jobseeker_gender = "",
    jobseeker_marital_status = "",
    jobseeker_preferred_job_title = "",
    jobseeker_email1 = "",
    jobseeker_phone1 = "",
    jobseeker_email2 = "",
    jobseeker_phone2 = "",
    jobseeker_location = {},
    jobseeker_photo = "",
    resume_headline = "",
    work_experience = [],
    work_sample = [],
    education = [],
    it_skills = [],
    skill = [],
    online_detail_result = [],
  } = jobseekerData;

  const renderSocialIcon = (socialProfileName) => {
    switch (socialProfileName.toUpperCase()) {
      case "LINKEDIN":
        return (
          <img
            style={{ height: "20px", marginRight: "5px" }}
            src={linkedin}
            alt="LinkedIn Icon"
          />
        );
      case "GITHUB":
        return (
          <img
            style={{ height: "20px", marginRight: "5px" }}
            src={github}
            alt="GitHub Icon"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="resume5" id="resume5">
      <div className="container">
        <div className="left">
          <div className="profile-container">
            <div className="profile-img">
              {jobseeker_photo && (
                <img src={jobseeker_photo} alt="Profile Picture" />
              )}
            </div>
            <div className="name">
              <h1>{capitalizeFirstLetter(jobseeker_full_name)}</h1>
              <p>{capitalizeEachWord(jobseeker_preferred_job_title)}</p>
            </div>
          </div>

          {resume_headline && (
            <div className="about">
              <div className="section-title">
                <h2>ABOUT ME</h2>
              </div>
              <div className="section-title">
                <p>{resume_headline}</p>
              </div>
            </div>
          )}

          {education.length > 0 && (
            <div className="work-history">
              <div className="section-title">
                <h2>EDUCATION</h2>
              </div>
              {education.map((edu, index) => (
                <div className="experience" key={index}>
                  <div className="year">
                    <h3>
                      {
                        educationLevelsOptions.find(
                          (option) => option.value === edu.education_level
                        )?.label
                      }{" "}
                      - {edu.passing_year}
                    </h3>
                  </div>
                  <div className="work-details">
                    <p>{edu.board}</p>
                    <p>Percentage: {edu.performance}%</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {work_experience.length > 0 && (
            <div className="work-history">
              <div className="section-title">
                <h2>WORK EXPERIENCE</h2>
              </div>
              {work_experience.map((work, index) => (
                <div className="experience" key={index}>
                  <div className="year">
                    <h3>
                      {formatDate(work.started_working_from)} -{" "}
                      {formatDate(work.worked_till)}
                    </h3>
                  </div>
                  <div className="work-details">
                    <p>
                      {capitalize(work.designation)}@{work.organization.label}
                    </p>
                    <p>{capitalize(work.job_description)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="right">
          <div className="contact">
            <ul>
              {jobseeker_email1 && (
                <li>
                  <img
                    src={email}
                    alt="Email Icon"
                    style={{ height: 20, marginRight: 10 }}
                  />
                  <a href={`mailto:${jobseeker_email1}`}>{jobseeker_email1}</a>
                </li>
              )}
              {jobseeker_phone1 && (
                <li>
                  <img
                    src={call}
                    alt="Phone Icon"
                    style={{ height: 20, marginRight: 10 }}
                  />
                  <a href={`tel:+${jobseeker_phone1}`}>{jobseeker_phone1}</a>
                </li>
              )}
              {jobseeker_location.label && (
                <li>
                  <img
                    src={location}
                    alt="Location Icon"
                    style={{ height: 20, marginRight: 10 }}
                  />
                  {jobseeker_location.label}
                </li>
              )}
              {online_detail_result.length > 0 &&
                online_detail_result.map(
                  (profile, index) =>
                    (profile.social_profile_name.toUpperCase() === "LINKEDIN" ||
                      profile.social_profile_name.toUpperCase() ===
                        "GITHUB") && (
                      <li
                        key={index}
                        className={profile.social_profile_name.toLowerCase()}
                      >
                        {renderSocialIcon(profile.social_profile_name)}
                        <a href={profile.url}>{profile.url}</a>
                      </li>
                    )
                )}
            </ul>
          </div>

          {work_sample.length > 0 && (
            <div className="projects">
              <h5>WORK SAMPLE</h5>
              <ul>
                {work_sample.map((work, index) => (
                  <li key={index}>
                    <p>
                      {capitalize(work.work_title)} (
                      {formatDate(work.work_start)} to{" "}
                      {formatDate(work.work_end)})
                    </p>
                    <p className="mb-1">
                      <a href={work.work_url}>{work.work_url}</a>
                    </p>
                    <p className="mb-1">{capitalize(work.work_description)}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {it_skills.length > 0 && (
            <div className="tools">
              <h5>MAIN SKILLS</h5>
              {it_skills
                .filter((skill) => skill.skill_rating > 0)
                .map((skill, index) => (
                  <div className="skill" key={index}>
                    <label>{skill.it_skill.label}</label>
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{
                          width: `${calculateProgressValue(
                            skill.skill_rating
                          )}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          <div className="achievements">
            <h5>Skills</h5>
            <ul>
              {skill
                // .slice(0, 5) // Take the top 5 skills
                .map((skill, index) => (
                  <li key={index}>
                    <span className="skill-name">
                      {capitalize(skill.label)}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template5;
