import { takeLatest, call, put } from "redux-saga/effects";
import { UPDATE_COMMUNICATION_REQUEST } from "../../actionTypes";

import {
  updateCommunicationSuccess,
  updateCommunicationFailure,
} from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
// Worker saga
function* updateCommunication(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/recruiter/communication", action.payload)
    );
    console.log("res", response.data);
    if (response.data.status == true) {
      yield put(updateCommunicationSuccess(response.data));
    } else {
      yield put(updateCommunicationFailure(response.data));
    }
  } catch (error) {
    yield put(
      updateCommunicationFailure({ status: false, message: error.message })
    );
  }
}

// Watcher saga
function* updateCommunicationSaga() {
  yield takeLatest(UPDATE_COMMUNICATION_REQUEST, updateCommunication);
}
export default updateCommunicationSaga;
