import {
  CHANGE_JOB_STATUS_REQUEST,
  CHANGE_JOB_STATUS_SUCCESS,
  CHANGE_JOB_STATUS_FAILURE,
  RESET_CHANGE_JOB_STATUS,
} from "../../actionTypes";

export const changeJobStatusRequest = (message) => ({
  type: CHANGE_JOB_STATUS_REQUEST,
  payload: message,
});

export const changeJobStatusSuccess = (data) => ({
  type: CHANGE_JOB_STATUS_SUCCESS,
  payload: data,
});

export const changeJobStatusFailure = (data) => ({
  type: CHANGE_JOB_STATUS_FAILURE,
  payload: data,
});

export const resetJobStatusMessage = () => ({
  type: RESET_CHANGE_JOB_STATUS,
});
