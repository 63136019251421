import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Widgets1 from "../../Common/CommonWidgets/Widgets1";
import { useSelector } from "react-redux";
import {
  FaBriefcase,
  FaBoxOpen,
  FaBuilding,
  FaClipboardCheck,
  FaPhoneAlt,
} from "react-icons/fa";
import { VscLayersActive } from "react-icons/vsc";

const WidgetsWrapper = () => {
  // Retrieve data from the Redux store using useSelector hook
  const dashboardReducerdData = useSelector((state) => state.dashboardReducer);

  let countData = [];
  if (
    !dashboardReducerdData.isLoading &&
    dashboardReducerdData.data &&
    dashboardReducerdData.data.data
  ) {
    countData = dashboardReducerdData.data.data;
  }

  // Destructuring data from countData
  const {
    new_jobs_count,
    total_applications_count,
    total_jobs_count,
    active_jobs_count,
    short_listed_count,
    hired_count,
  } = countData;

  // Widget data array
  const widgetData = [
    {
      title: "New Jobs",
      count: new_jobs_count,
      color: "secondary",
      icon: <FaBriefcase className="svg-fill" />,
      link: "/recruiter/manage_jobs/all",
    },
    {
      title: "Total Jobs",
      count: total_jobs_count,
      color: "success",
      icon: <FaBuilding className="svg-fill" />,
      link: "/recruiter/manage_jobs/all",
    },
    {
      title: "Active Jobs",
      count: active_jobs_count,
      color: "success",
      icon: <VscLayersActive className="svg-fill" />,
      link: "/recruiter/manage_jobs/active",
    },
    {
      title: "Applications",
      count: total_applications_count,
      color: "warning",
      icon: <FaBoxOpen className="svg-fill" />,
      link: "/recruiter/manage_candidate",
    },

    {
      title: "Shortlisted",
      count: short_listed_count,
      color: "primary",
      icon: <FaClipboardCheck className="svg-fill" />,
      link: "/recruiter/manage_candidate/shortlisted",
    },

    {
      title: "Hired",
      count: hired_count,
      color: "success",
      icon: <FaPhoneAlt className="svg-fill" />,
      link: "/recruiter/manage_candidate/hired",
    },
  ];

  return (
    <>
      <Row>
        {widgetData.map((widget, index) => (
          <Col xl="4" lg="6" md="6" sm="12" className="mb-2" key={index}>
            <Link to={`${process.env.PUBLIC_URL}${widget.link}`}>
              <Widgets1
                data={{
                  title: widget.title,
                  gros: widget.count,
                  total: widget.count,
                  color: widget.color,
                  icon: widget.icon,
                }}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default WidgetsWrapper;
