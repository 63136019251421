import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import ManageJobs from "../../Components/Recruiter/ManageJobs/ManageJobs";

const ManageJobPage = () => {
  
  return (
    <Fragment>
      <Breadcrumbs title="Manage Jobs" mainTitle="Manage Jobs" />
      <Container fluid={true}>
        <ManageJobs/>
      </Container>
    </Fragment>
  );
};

export default ManageJobPage;
