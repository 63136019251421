import {
  GENERATE_LINKEDIN_BANNER_REQUEST,
  GENERATE_LINKEDIN_BANNER_SUCCESS,
  GENERATE_LINKEDIN_BANNER_FAILURE,
} from "../actionTypes";

const initialState = {
  data: "",
  isLoading: false,
  message: "",
  status: false,
};

const generateLinkedinBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_LINKEDIN_BANNER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GENERATE_LINKEDIN_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        status: action.payload.status,
      };
    case GENERATE_LINKEDIN_BANNER_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: "",
        message: action.payload.message,
        status: action.payload.status,
      };
    // case RESET_TOTAL_POINTS: // Reset message action
    //   return {
    //     isLoading: false,
    //     message: "",
    //     status: false,
    //   };
    default:
      return state;
  }
};

export default generateLinkedinBannerReducer;
