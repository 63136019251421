// reducer.js
import * as types from "../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  mandatoryLoading: false,
  goodToHaveLoading: false,
  status: false,
  message:""
};
let mandatoryStatus = false;
let goodToHaveStatus = false;

const mandatorySkillsReducer = (state = initialState, action) => {
  console.log(action.payload);
  if(action.payload && action.payload.skillType == "MANDATORY") {
    mandatoryStatus = true;
    goodToHaveStatus = false;
  }else if (action.payload && action.payload.skillType == "GOOD_TO_HAVE") {
    goodToHaveStatus = true;
    mandatoryStatus=false;
  }
  switch (action.type) {
    case types.FETCH_SKILLS_REQUEST:
      return {
        ...state,
        isLoading: true,
        mandatoryLoading: mandatoryStatus,
        goodToHaveLoading: goodToHaveStatus,
      };
    case types.FETCH_SKILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mandatoryLoading: false,
        goodToHaveLoading: false,
        data: action.payload.data,
        status:action.payload.status,
      };
    case types.FETCH_SKILLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        mandatoryLoading: false,
        goodToHaveLoading: false,
        data: [],
        message: action.payload.message,
        status:action.payload.status,
      };
    default:
      return state;
  }
};

export default mandatorySkillsReducer;
