import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap";
import Select from "react-select";
import Slider from "react-slick";
import { fetchSkillsRequest } from "../../../redux/mandatorySkills/actions";

import { showToast } from "../../Common/Toast";
import { AVATAR, SYSTEM_PREFIX, customOptionsStyle } from "../../../Constant";
import Spinner from "../../Common/Spinner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchCompanyProfile } from "../../../redux/recruiter/company_profile/actions";
import { roleOptions } from "../../../Constant";
import { capitalize, formatWebsite } from "../../Common/CommonFunction";
import { TbBuilding } from "react-icons/tb";
import { FaGraduationCap } from "react-icons/fa6";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";
import { useParams } from "react-router";
import { fetchLocationsRequest } from "../../../redux/location/actions";
import { TiLocation } from "react-icons/ti";

const BannerModal = (props) => {
  const dispatch = useDispatch();
  const savedState = localStorage.getItem("jobseekerBannerProfile");
  const parsedState = JSON.parse(savedState);
  const componentRef = useRef(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [profile, setProfile] = useState({
    photo: AVATAR,
    name: "",
    email: "",
    phone: "",
    resume_headline: "",
    education: [],

    organization: [],
    skills: [],
    quotes: parsedState && parsedState.quotes ? parsedState.quotes : "",
    locations: [],
    includeEmail: false,
    includePhone: false,
  });

  const [error, setError] = useState("");
  const [currentIndex, setCurrentIndex] = useState(props.index);
  const getLinkedinBannerReducerData = useSelector(
    (state) => state.getLinkedinBannerReducer
  );
  const allBanners = getLinkedinBannerReducerData.data;

  const fetchJobseekerDataReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer
  );

  useEffect(() => {
    if (
      !fetchJobseekerDataReducerData.isLoading &&
      fetchJobseekerDataReducerData.data &&
      fetchJobseekerDataReducerData.status === true
    ) {
      if (savedState) {
        const updatedProfile = {
          photo:
            fetchJobseekerDataReducerData.data.data.jobseeker_photo || AVATAR,
          name:
            fetchJobseekerDataReducerData.data.data.jobseeker_full_name || "",
          email: fetchJobseekerDataReducerData.data.data.jobseeker_email1 || "",
          phone: fetchJobseekerDataReducerData.data.data.jobseeker_phone1 || "",
          resume_headline:
            fetchJobseekerDataReducerData.data.data.resume_headline || "",
          // designation:
          //   fetchJobseekerDataReducerData.data.data.work_experience.map(
          //     (work) => work.designation
          //   ) || "",
          organization: Array.isArray(
            fetchJobseekerDataReducerData.data.data.work_experience
          )
            ? fetchJobseekerDataReducerData.data.data.work_experience.map(
                (work) => work.organization
              )
            : [],

          education: Array.isArray(
            fetchJobseekerDataReducerData.data.data.education
          )
            ? fetchJobseekerDataReducerData.data.data.education.filter(
                (education) => education.type === "college"
              )
            : [],
          skills: parsedState.skills || [],

          includeEmail: parsedState.includeEmail,
          includePhone: parsedState.includePhone,
          locations: parsedState.locations,
        };
        setProfile((prevProfile) => ({
          ...prevProfile,
          ...updatedProfile,
        }));
      } else {
        // const itSkills =
        //   fetchJobseekerDataReducerData?.data?.data?.it_skills || [];
        // const itSkillsArray = itSkills.map((skill) => ({
        //   // Adjust the structure based on the actual properties of the IT skills objects
        //   label: skill.it_skill.label,
        //   value: skill.it_skill.value,
        //   // Add other properties as needed
        // }));
        const updatedProfile = {
          photo:
            fetchJobseekerDataReducerData.data.data.jobseeker_photo || AVATAR,
          name:
            fetchJobseekerDataReducerData.data.data.jobseeker_full_name || "",
          email: fetchJobseekerDataReducerData.data.data.jobseeker_email1 || "",
          phone: fetchJobseekerDataReducerData.data.data.jobseeker_phone1 || "",
          resume_headline:
            fetchJobseekerDataReducerData.data.data.resume_headline || "",
          // designation:
          //   fetchJobseekerDataReducerData.data.data.work_experience.map(
          //     (work) => work.designation
          //   ) || "",
          organization: Array.isArray(
            fetchJobseekerDataReducerData.data.data.work_experience
          )
            ? fetchJobseekerDataReducerData.data.data.work_experience.map(
                (work) => work.organization
              )
            : [],

          education: Array.isArray(
            fetchJobseekerDataReducerData.data.data.education
          )
            ? fetchJobseekerDataReducerData.data.data.education.filter(
                (education) => education.type === "college"
              )
            : [],
          skills:
            fetchJobseekerDataReducerData.data.data.primary_skill.slice(
              0,
              allBanners[currentIndex].skills_allowed
            ) || "",
          locations:
            fetchJobseekerDataReducerData.data.data.jobseeker_preferred_location.slice(
              0,
              allBanners[currentIndex].locations_allowed
            ) || "",
        };
        setProfile((prevProfile) => ({
          ...prevProfile,
          ...updatedProfile,
        }));
      }
    }
  }, [
    fetchJobseekerDataReducerData.isLoading,
    fetchJobseekerDataReducerData.data,
    fetchJobseekerDataReducerData.status,
    currentIndex,
  ]);

  useEffect(() => {
    // Update the quotes when the slide (currentIndex) changes
    if (!profile.quotes) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        quotes: allBanners[currentIndex].default_quotes || "", // Set the quotes from the banner or an empty string if not available
      }));
    }
  }, [currentIndex, allBanners]);

  const [mandatorySkillsOptions, setMandatorySkillsOptions] = useState([]);

  //read industry type data from store
  let mandatorySkillsReducerData = useSelector(
    (state) => state.mandatorySkillsReducer
  );
  // console.log(industryTypeReducerData.data);
  let mandatorySkillsData = mandatorySkillsReducerData.data.data;

  useEffect(() => {
    mandatorySkillsData = mandatorySkillsData || [];
    setMandatorySkillsOptions(mandatorySkillsData);
  }, [mandatorySkillsData]);

  const handlemandatorySkillsFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchSkillsRequest(keyword, "MANDATORY"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleMandatorySkillsInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchSkillsRequest(inputValue, "MANDATORY"));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  const handleMandatorySkillsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, skills: "" };
    setErrors(updatedErrors);
    setProfile((prevProfile) => ({
      ...prevProfile,
      skills: selectedOptions,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: checked,
    }));
  };

  let locationReducerData = useSelector((state) => state.locationReducer);
  let locationData = locationReducerData.data.data;

  useEffect(() => {
    locationData = locationData || [];
    setLocationOptions(locationData);
  }, [locationData]);

  const handleLocationFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchLocationsRequest(keyword));
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  // Callback to handle location input change
  const handleLocationInputChange = useCallback((inputValue) => {
    // Fetch data based on the inputValue (keyword)
    const fetchData = async () => {
      try {
        dispatch(fetchLocationsRequest(inputValue));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location input changes
    fetchData();
  }, []);

  // Callback to handle location select change
  const handleLocationsChange = (selectedOptions) => {
    const updatedErrors = { ...errors, locations: "" };
    setErrors(updatedErrors);
    setProfile((prevProfile) => ({
      ...prevProfile,
      locations: selectedOptions,
    }));
  };

  const validate = () => {
    const errors = {};
    let isValid = true;
    if (allBanners[currentIndex].skills_allowed != 0) {
      if (profile.skills.length > allBanners[currentIndex].skills_allowed) {
        errors.skills = `You can only select up to ${allBanners[currentIndex].skills_allowed} skills.`;
        isValid = false;
      }
    }

    if (allBanners[currentIndex].locations_allowed != 0) {
      if (
        profile.locations.length > allBanners[currentIndex].locations_allowed
      ) {
        errors.locations = `You can only select up to ${allBanners[currentIndex].locations_allowed} locations.`;
        isValid = false;
      }
    }

    setErrors(errors);
    return isValid;
  };

  const handleDownloadClick = async () => {
    // if (!isImageLoaded) return; // Wait for image to load
    if (validate()) {
      // Save the current profile state to local storage
      localStorage.setItem(
        "jobseekerBannerProfile",
        JSON.stringify({
          includeName: profile.includeName,
          includeEmail: profile.includeEmail,
          includePhone: profile.includePhone,
          includeWebsite: profile.includeWebsite,
          skills: profile.skills,
          locations: profile.locations,
          quotes: profile.quotes,
        })
      );

      try {
        const canvas = await html2canvas(componentRef.current, {
          // Add options as needed:
          allowTaint: true, // If necessary for cross-origin images
          useCORS: true, // For cross-origin images
          scale: 2, // Adjust scale if needed
        });
        const imgData = canvas.toDataURL("image/png");
        const blob = dataURItoBlob(imgData);

        const file_name = profile.name
          ? SYSTEM_PREFIX + profile.name + ".png"
          : SYSTEM_PREFIX + "_linked_banner.png";
        saveAs(blob, file_name);
      } catch (error) {
        console.error("Error capturing element:", error);
      }
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: "image/png" });
  };

  const handleNext = (event) => {
    event.preventDefault(); // Prevents the default form or link behavior
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allBanners.length);
  };

  const handlePrevious = (event) => {
    event.preventDefault(); // Prevents the default form or link behavior
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + allBanners.length) % allBanners.length
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      centered
      className="modal-xl"
      backdrop="static"
      fade={false}
      // style={{ maxWidth: 968 }}
    >
      {console.log(profile)}
      <Form className="theme-form">
        <ModalHeader className="border-0" toggle={props.toggleModal}>
          AI LinkedIn Banner
        </ModalHeader>
        <ModalBody className="p-4">
          {getLinkedinBannerReducerData.isLoading ? (
            <Spinner />
          ) : (
            <div className="linked-jobseeker-banner-main">
              <div className="banner-div">
                <div
                  className={`banner-${allBanners[currentIndex].banner_id}`}
                  ref={componentRef}
                  style={{
                    backgroundImage: `url(${allBanners[currentIndex].banner_template_image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "273px",
                  }}
                >
                  <div className="contact-container">
                    {profile.includePhone &&
                      allBanners[currentIndex].phone_allowed != 0 && (
                        <div className="contact-item phone">
                          <FaPhoneAlt /> {profile.phone}
                        </div>
                      )}
                    {profile.includeEmail &&
                      allBanners[currentIndex].email_allowed != 0 && (
                        <div className="contact-item email">
                          <MdEmail /> {profile.email}
                        </div>
                      )}
                    {profile.locations &&
                      allBanners[currentIndex].locations_allowed !== 0 && (
                        <div className="contact-item email">
                          <TiLocation />
                          <span>
                            {profile.locations
                              .slice(0, 2) // Limit to the allowed number of locations
                              .map((location, index, array) => (
                                <React.Fragment key={index}>
                                  {location.label}
                                  {index < array.length - 1 && ", "}{" "}
                                  {/* Add comma except after the last item */}
                                </React.Fragment>
                              ))}
                          </span>
                        </div>
                      )}
                  </div>

                  <div className="quotes-positions">
                    {profile.quotes &&
                      allBanners[currentIndex].default_quotes &&
                      profile.quotes
                        .split(",")
                        .map((quotes, index) => (
                          <li key={index}>{quotes.trim()}</li>
                        ))}
                  </div>

                  <div className="skills-positions">
                    <ul>
                      {profile.skills &&
                        allBanners[currentIndex].skills_allowed !== 0 &&
                        profile.skills
                          .slice(0, allBanners[currentIndex].skills_allowed) // Limit to the allowed number of skills
                          .map((skill, index) => (
                            <li key={index}>{skill.label}</li> // Use skill.label to display the skill name
                          ))}
                    </ul>
                  </div>

                  <div className={`slider-controls`}>
                    <button onClick={handlePrevious} className="prev-btn">
                      <IoIosArrowBack />
                    </button>
                    <button onClick={handleNext} className="next-btn">
                      <IoIosArrowForward />
                    </button>
                  </div>
                </div>
              </div>
              <div className="profile-img bg-white rounded-circle d-flex justify-content-center align-items-center">
                <img
                  src={profile.photo}
                  alt="User profile picture"
                  className="rounded-circle img-fluid"
                />
              </div>
            </div>
          )}
          <Row className=" mt-4">
            <Col md={8}>
              <h4 className="name mt-3 text-uppercase">
                {profile.name && capitalize(profile.name)}
              </h4>
              <div className="about">
                <p className="resume_headline">
                  {profile.resume_headline && (
                    <>
                      {profile.resume_headline
                        ? capitalize(profile.resume_headline)
                        : ""}
                    </>
                  )}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="company mt-3">
                {profile.organization && profile.organization.length > 0 && (
                  <div className="d-flex align-items-center mb-2">
                    <div
                      className="d-flex justify-content-center align-items-center bg-secondary text-white me-2"
                      style={{
                        width: "25px",
                        height: "25px",
                        fontSize: "16px",
                      }}
                    >
                      <TbBuilding />
                    </div>

                    <span>{profile.organization[0].label}</span>
                  </div>
                )}

                {profile.education && profile.education.length > 0 && (
                  <div className="d-flex align-items-center">
                    <div
                      className="d-flex justify-content-center align-items-center bg-secondary text-white me-2"
                      style={{
                        width: "25px",
                        height: "25px",
                        fontSize: "16px",
                      }}
                    >
                      <FaGraduationCap />
                    </div>

                    <span>
                      {
                        profile.education[profile.education.length - 1]
                          .jobseeker_college_name
                      }
                    </span>
                  </div>
                )}
                {(!profile.organization || profile.organization.length === 0) &&
                  (!profile.education || profile.education.length === 0) && (
                    <div
                      className="d-flex justify-content-center align-items-center bg-white text-black me-2"
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "14px",
                        filter: "blur(3px)",
                      }}
                    >
                      Company Name
                    </div>
                  )}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <FormGroup>
                {allBanners[currentIndex].phone_allowed != 0 && (
                  <>
                    <Input
                      className="checkbox_animated ms-2"
                      type="checkbox"
                      id="includePhone"
                      name="includePhone"
                      checked={profile.includePhone}
                      onChange={handleCheckboxChange}
                    />
                    <Label for="includePhone">Add Phone</Label>
                  </>
                )}
                {allBanners[currentIndex].email_allowed != 0 && (
                  <>
                    <Input
                      className="checkbox_animated ms-2"
                      type="checkbox"
                      id="includeEmail"
                      name="includeEmail"
                      checked={profile.includeEmail}
                      onChange={handleCheckboxChange}
                    />
                    <Label for="includeEmail">Add Email</Label>
                  </>
                )}
                {allBanners[currentIndex].website_allowed != 0 && (
                  <>
                    <Input
                      className="checkbox_animated ms-2"
                      type="checkbox"
                      id="includeWebsite"
                      name="includeWebsite"
                      checked={profile.includeWebsite}
                      onChange={handleCheckboxChange}
                    />
                    <Label for="includeWebsite">Add Website</Label>
                  </>
                )}
              </FormGroup>
            </Col>
            {allBanners[currentIndex].default_quotes && (
              <Col md={6}>
                <FormGroup>
                  <Label for="quotes">Quotes</Label>
                  <Input
                    className="form-control form-control-sm"
                    type="text"
                    id="quotes"
                    name="quotes"
                    value={profile.quotes}
                    onChange={handleChange}
                    placeholder="Enter quotes"
                  />
                </FormGroup>
              </Col>
            )}

            {allBanners[currentIndex].skills_allowed != 0 && (
              <Col md={6}>
                <FormGroup>
                  <Label for="skills">Skills</Label>
                  <Select
                    isMulti
                    options={mandatorySkillsOptions}
                    isLoading={mandatorySkillsReducerData.mandatoryLoading}
                    value={profile.skills} // Use formData.skills directly
                    onChange={handleMandatorySkillsChange} // Handle change event
                    styles={customOptionsStyle}
                    placeholder={"Select Technology..."}
                    onFocus={handlemandatorySkillsFocus} // Handle focus event
                    onInputChange={handleMandatorySkillsInputChange}
                  />
                  {errors && (
                    <div className="text-danger mt-2">{errors.skills}</div>
                  )}
                </FormGroup>
              </Col>
            )}
            {allBanners[currentIndex].location_allowed != 0 && (
              <Col md="6">
                <FormGroup>
                  <Label>Job Location(s)</Label>
                  <Select
                    isMulti
                    options={locationOptions}
                    isLoading={locationReducerData.isLoading}
                    placeholder="Select locations..."
                    value={profile.locations}
                    onChange={handleLocationsChange}
                    onInputChange={handleLocationInputChange}
                    styles={customOptionsStyle}
                    onFocus={handleLocationFocus}
                  />
                  {errors && (
                    <div className="text-danger mt-2">{errors.locations}</div>
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={handleDownloadClick}>
            Download
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default BannerModal;
