import {
  FETCH_WEEKLY_STATUS_REQUEST,
  FETCH_WEEKLY_STATUS_SUCCESS,
  FETCH_WEEKLY_STATUS_FAILURE,
} from "../../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  message: "",
  status: false,
};

const weeklyStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEEKLY_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_WEEKLY_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: action.payload.status,
        isLoading: false,
      };
    case FETCH_WEEKLY_STATUS_FAILURE:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default weeklyStatusReducer;
