import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

import {
  addWorkExpFailure,
  addWorkExpSuccess,
  deleteWorkExpFailure,
  deleteWorkExpSuccess,
} from "./actions";

function* addWorkExp(action) {
  console.log("ad", action);
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/add_work_experience", action.payload)
    );
    console.log("sr", response);
    if (response.data.status) {
      yield put(addWorkExpSuccess(response.data));
    } else {
      yield put(addWorkExpFailure(response.data));
    }
  } catch (error) {
    yield put(addWorkExpFailure({ status: false, message: error.message }));
  }
}

function* deleteWorkExp(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("jobseeker/delete_work_experience", action.payload)
    );
    if (response.data.status) {
      yield put(deleteWorkExpSuccess(response.data));
    } else {
      yield put(deleteWorkExpFailure(response.data));
    }
  } catch (error) {
    yield put(deleteWorkExpFailure({ status: false, message: error.message }));
  }
}

function* addWorkExpSaga() {
  yield takeLatest(types.ADD_WORK_EXPERIENCE_REQUEST, addWorkExp);
  yield takeLatest(types.DELETE_WORK_EXPERIENCE_REQUEST, deleteWorkExp);
}

export default addWorkExpSaga;
