import {
  SUBMIT_REPORT_REQUEST,
  SUBMIT_REPORT_SUCCESS,
  SUBMIT_REPORT_FAILURE,
  RESET_DATA,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
  data: null,
};

const reportJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_REPORT_REQUEST:
      console.log("reportjob reducer line no. 20", action.payload);
      return {
        ...state,
        isLoading: true,
      };
    case SUBMIT_REPORT_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };
    case SUBMIT_REPORT_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
        message: action.payload.message,
      };

    case RESET_DATA:
      return {
        ...state,
        isLoading: false,
        data: {},
        status: false,
        message: "",
      };
    default:
      return state;
  }
};

export default reportJobReducer;
