// reducer.js
import * as types from "../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  message: "",
  status: false,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        status: action.payload.status,
      };
    case types.FETCH_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        message: action.payload.message,
        status: action.payload.status,
      };
    default:
      return state;
  }
};

export default companyReducer;
