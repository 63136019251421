import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";
import { showToast } from "../../../Components/Common/Toast";
import {
  deleteResumeFailure,
  deleteResumeSuccess,
  updateResumeSuccess,
  updateResumeFailure,
} from "./actions";

function* updateResumeAvatar(action) {
  try {
    // const data = {
    //   id : action.payload.id,
    // it_skill : action.payload.it_skill,
    // skill_version : action.payload.skill_version,
    // skill_last_used : action.payload.skill_last_used,
    // skill_experienced : action.payload.skill_experienced,
    // skill_rating : action.payload.skill_rating,
    // }
    console.log(action.payload);
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/add_update_resume_file", action.payload)
    );
    if (response.data.status) {
      yield put(updateResumeSuccess(response.data));
    } else {
      yield put(updateResumeFailure(response.data));
    }
  } catch (error) {
    yield put({ type: types.UPDATE_RESUME_FAILURE, payload: error });
  }
}

function* deleteResume(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/remove_jobseeker_resume", action.payload)
    );
    if (response.data.status) {
      yield put(deleteResumeSuccess(response.data));
    } else {
      yield put(deleteResumeFailure(response.data));
    }
  } catch (error) {
    yield put(deleteResumeFailure({ status: false, message: error.message }));
  }
}

function* updateSaga() {
  yield takeLatest(types.UPDATE_RESUME, updateResumeAvatar);
  yield takeLatest(types.DELETE_RESUME_REQUEST, deleteResume);
}

export default updateSaga;
