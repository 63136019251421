import {
  FETCH_JOB_APPLICATIONS_REQUEST,
  FETCH_JOB_APPLICATIONS_SUCCESS,
  FETCH_JOB_APPLICATIONS_FAILURE,
} from "../../actionTypes";

export const fetchJobApplications = (
  page,
  per_page,
  data,
  column,
  direction
) => ({
  type: FETCH_JOB_APPLICATIONS_REQUEST,
  payload: { page, per_page, data, column, direction },
});

export const fetchJobApplicationsSuccess = (data) => ({
  type: FETCH_JOB_APPLICATIONS_SUCCESS,
  payload: data,
});

export const fetchJobApplicationsFailure = (error) => ({
  type: FETCH_JOB_APPLICATIONS_FAILURE,
  payload: error,
});
