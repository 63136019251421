import {
  FETCH_JOB_NAMES_REQUEST,
  FETCH_JOB_NAMES_SUCCESS,
  FETCH_JOB_NAMES_FAILURE,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  status: false,
  message: "",
};

const jobNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_NAMES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOB_NAMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status,
        message: "",
      };
    case FETCH_JOB_NAMES_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
};

export default jobNameReducer;
