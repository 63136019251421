// reducers.js
import {
  GO_TO_PASSBOOK_REQUEST,
  GO_TO_PASSBOOK_SUCCESS,
  GO_TO_PASSBOOK_FAILURE,
  RESET_FAVORITE_MESSAGE,
} from "../actionTypes";

const initialState = {
  data: [], // Initialize data to null
  message: "",
  isLoading: false,
  status: false,
};

const goToPassbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case GO_TO_PASSBOOK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GO_TO_PASSBOOK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status, // Set status from payload if needed
      };
    case GO_TO_PASSBOOK_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        status: action.payload.status,
        message: action.payload.message,
      };

    case RESET_FAVORITE_MESSAGE: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };
    default:
      return state;
  }
};

export default goToPassbookReducer;
