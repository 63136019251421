import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Form, Row } from "reactstrap";
import { X } from "react-feather";
import { Link } from "react-router-dom";
import CustomContext from "../../../_helper/Customizer";
import Leftbar from "./Leftbar/index";
import RightHeader from "./RightHeader/index";

const Header = () => {
  const { toggleIcon } = useContext(CustomContext);

  const [searchValue, setsearchValue] = useState("");
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector(".empty-menu").classList.add("is-open");
    } else {
      setSearchResultEmpty(false);
      document.querySelector(".empty-menu").classList.remove("is-open");
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document.querySelector(".Typeahead-menu").classList.add("is-open");
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue("");
    document.querySelector(".Typeahead-menu").classList.remove("is-open");
    document.body.classList.remove("offcanvas");
  };

  return (
    <Fragment>
      <div
        className={`page-header ${toggleIcon ? "close_icon" : ""}`}
        style={{ marginLeft: 0, width: "100%" }}
      >
        <Row className="header-wrapper m-0">
          <Form className="form-inline search-full col" action="#" method="get">
            <div className="form-group w-100">
              <div className="Typeahead Typeahead--twitterUsers">
                <div className="u-posRelative">
                  <input
                    className="Typeahead-input form-control-plaintext w-100"
                    id="demo-input"
                    type="search"
                    placeholder="Search..."
                    defaultValue={searchValue}
                    onChange={(e) => handleSearchKeyword(e.target.value)}
                  />
                  <div
                    className="spinner-border Typeahead-spinner"
                    role="status"
                  >
                    <span className="sr-only">Loading</span>
                  </div>
                  <X
                    className="close-search"
                    onClick={() =>
                      document
                        .querySelector(".search-full")
                        .classList.remove("open")
                    }
                  />
                </div>
                <div className="Typeahead-menu" id="search-outer">
                  <div className="header-search-suggestion custom-scrollbar">
                    {searchValue
                      ? searchValue.map((data, index) => {
                          return (
                            <div className="ProfileCard u-cf" key={index}>
                              <div className="ProfileCard-details">
                                <div className="ProfileCard-realName">
                                  <Link
                                    to={data.path + "/"}
                                    className="realname  w-100 d-flex justify-content-start gap-2"
                                    onClick={removeFix}
                                  >
                                    {data.title}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div className="Typeahead-menu empty-menu">
                  <div className="tt-dataset tt-dataset-0">
                    <div className="EmptyMessage">
                      {"Opps!! There are no result found."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          {/* <Leftbar /> */}
          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;
