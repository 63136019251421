import {
  FETCH_JOB_APPLICATIONS_REQUEST,
  FETCH_JOB_APPLICATIONS_SUCCESS,
  FETCH_JOB_APPLICATIONS_FAILURE,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  message: "",
  status:false,
  data: [],
};

const jobApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_APPLICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOB_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status,
      };
    case FETCH_JOB_APPLICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    default:
      return state;
  }
};

export default jobApplicationReducer;
