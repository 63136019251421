import React, { Fragment, useState, useContext } from "react";

import {
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Collapse,
  Input,
} from "reactstrap";
import { CandidateSearchContext } from "../../../../CandidateSearch/CandidateSearchContext";
import { freshnessOptions } from "../../../../Constant";
const Freshness = () => {
  const [isFilter, setIsFilter] = useState(true);

  const { filters, updateFilters } = useContext(CandidateSearchContext);
  // Dynamic freshness options

  const handleRadioChange = (option) => {
    // Update the freshness filter in the context
    updateFilters({ freshness: option.value });
  };

  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader className="py-3" onClick={() => setIsFilter(!isFilter)}>
            <h5 className="mb-0 p-0">Freshness</h5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="filter-cards-view animate-chk">
              {/* <div className="checkbox-animated"> */}
              {freshnessOptions.map((option, index) => (
                <label
                  key={index}
                  className="d-flex align-items-center"
                  htmlFor={`rad-ani-${index}`}
                >
                  <Input
                    className="radio_animated mr-2"
                    id={`rad-ani-${index}`}
                    type="radio"
                    checked={filters.freshness === option.value}
                    onChange={() => handleRadioChange(option)}
                  />
                  <span>{option.label}</span>
                </label>
              ))}
              {/* </div> */}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Freshness;
