import {
  FETCH_JOB_PIPELINE_REQUEST,
  FETCH_JOB_PIPELINE_SUCCESS,
  FETCH_JOB_PIPELINE_FAILURE,
  DOWNLOAD_PIPELINE_CSV_REQUEST,
  DOWNLOAD_PIPELINE_CSV_SUCCESS,
  DOWNLOAD_PIPELINE_CSV_FAILURE,
} from "../../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  message: "",
  status: false,
};

const jobPipelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_PIPELINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOB_PIPELINE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: action.payload.status,
        isLoading: false,
      };
    case FETCH_JOB_PIPELINE_FAILURE:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default jobPipelineReducer;
