// reducer.js
import {
  FETCH_RESUME_TEMPLATES_REQUEST,
  FETCH_RESUME_TEMPLATES_SUCCESS,
  FETCH_RESUME_TEMPLATES_FAILURE,
} from "../../actionTypes";

const initialState = {
  isLoading: false,
  error: null,
  data: [],
};

const generateResumesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESUME_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_RESUME_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case FETCH_RESUME_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default generateResumesReducer;
