// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { goToPassbookSuccess, goToPassbookFailure } from "./actions";
import { GO_TO_PASSBOOK_REQUEST } from "../actionTypes";
import AxiosInstance from "../../services/AxiosInstance";

function* navigateToPassbook(action) {
  let url = `/passbook?page=${action.payload.page}&per_page=${action.payload.per_page}`;

  if (action.payload.column) {
    url += `&sortBy=${action.payload.column}`;
  }

  if (action.payload.direction) {
    url += `&direction=${action.payload.direction}`;
  }
  try {
    const response = yield call(() =>
      AxiosInstance().post(url, action.payload.data)
    );
    if (response.data.status == true) {
      yield put(goToPassbookSuccess(response.data));
    } else {
      yield put(goToPassbookFailure(response.data));
    }
  } catch (error) {
    yield put(goToPassbookFailure({ status: false, message: error.message }));
  }
}

function* goToPassbookSaga() {
  yield takeLatest(GO_TO_PASSBOOK_REQUEST, navigateToPassbook);
}

export default goToPassbookSaga;
