import { Container } from "reactstrap";
import ProfileEditContain from "../../Components/Recruiter/Profile";
import { Fragment } from "react";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { useParams } from "react-router";
const JobCandidateDetailsPage = () => {
  const { cand_id, jobcad_id } = useParams();
  return (
    <Fragment>
      <Breadcrumbs
        title={"Candidates Details"}
        mainTitle={ "Candidates Details" }
      />
      <Container fluid={true}>
        <ProfileEditContain />
      </Container>
    </Fragment>
  );
};

export default JobCandidateDetailsPage;
