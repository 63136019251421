import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogIn, User } from "react-feather";
import { FiUsers } from "react-icons/fi";
import { AuthContext } from "../../../../Auth/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruiterProfile } from "../../../../redux/recruiter/recruiter_profile/actions";
import { AVATAR } from "../../../../Constant";
import { FiEdit } from "react-icons/fi";
import { COINS } from "../../../../Constant";
import { FaCoins } from "react-icons/fa";
import { styleTotalCoins } from "../../../../Components/Common/CommonFunction";
import dollar from "../../../../../src/assets/images/Icons/dollar.png";
const UserHeader = () => {
  const dispatch = useDispatch();
  const { logout, totalPoints } = useContext(AuthContext);
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Your Name");
  const recruiterProfileReducerData = useSelector(
    (state) => state.recruiterProfileReducer
  );
  const recruiterProfile = recruiterProfileReducerData.data;

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  useEffect(() => {
    // Fetch company profile on component mount
    dispatch(fetchRecruiterProfile());
  }, []);

  useEffect(() => {
    // Populate form data with fetched company profile data
    if (recruiterProfile && recruiterProfileReducerData.isLoading != true) {
      setProfile(recruiterProfile.profile_image || AVATAR);
      setName(recruiterProfile.recruiter_full_name || "Your Name");
    }
  }, [recruiterProfileReducerData]);

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media" style={{ alignItems: "center" }}>
        <img
          className="b-r-10 m-0"
          width="35px"
          height="35px"
          src={profile}
          alt=""
        />
        <div
          className="media-body align-item-center"
          style={{ textTransform: "capitalize" }}
        >
          <span>{name}</span>
          <p
            className="mb-2 font-roboto text-center text-capitalize"
            style={{ fontSize: 15 }}
          >
            {styleTotalCoins(totalPoints)}
          </p>
          {/* <p className="mb-0 font-roboto">
            Admin <i className="middle fa fa-angle-down"></i>
          </p> */}
        </div>
      </div>
      <ul className="simple-list profile-dropdown onhover-show-div">
        <li
          className="d-flex"
          onClick={() =>
            UserMenuRedirect(`${process.env.PUBLIC_URL}/recruiter/referral`)
          }
        >
          <img
            width="22px"
            height="22px"
            className="b-r-10 m-0"
            src={dollar}
            alt=""
          />
          <span className="ms-2 fs-6 fw-bold text-primary text-capitalize">
            {totalPoints}
          </span>
        </li>
        <li
          className="d-flex"
          onClick={() =>
            UserMenuRedirect(`${process.env.PUBLIC_URL}/recruiter/profile`)
          }
        >
          <User style={{ width: 22 }} />
          <span>Edit Profile</span>
        </li>
        <li
          className="d-flex"
          onClick={() =>
            UserMenuRedirect(
              `${process.env.PUBLIC_URL}/recruiter/change_password/`
            )
          }
        >
          <FiEdit style={{ width: 22 }} />
          <span>Change Password</span>
        </li>

        <li className="d-flex" onClick={logout}>
          <LogIn style={{ width: 22 }} />
          <span>Logout</span>
        </li>
      </ul>
    </li>
  );
};

export default UserHeader;
