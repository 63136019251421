import * as types from "../../actionTypes";

export const fetchAppliedJobsRequest = (
  page,
  per_page,
  data,
  column,
  direction
) => ({
  type: types.FETCH_APPLIED_JOBS_REQUEST,
  payload: { page, per_page, data, column, direction },
});

export const fetchAppliedJobsSuccess = (data) => ({
  type: types.FETCH_APPLIED_JOBS_SUCCESS,
  payload: data,
});

export const fetchAppliedJobsFailure = (error) => ({
  type: types.FETCH_APPLIED_JOBS_FAILURE,
  payload: error,
});
