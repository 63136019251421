import React, { Fragment, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Home,
  Edit,
  CheckSquare,
  File,
  Users,
  Columns,
  LogOut,
  ChevronDown,
  ChevronUp,
  Report,
} from "react-feather";
import { AuthContext } from "../../../Auth/AuthContext";
import { FiAlertOctagon } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { LuClipboardEdit } from "react-icons/lu";
import { GoCrossReference } from "react-icons/go";
import { FiBriefcase } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";
import { FiExternalLink } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { FiUpload } from "react-icons/fi";
import { LuFile } from "react-icons/lu";
import { FaRegHeart } from "react-icons/fa";
import { TbBuilding } from "react-icons/tb";
import { LuMailPlus } from "react-icons/lu";
import { FiFileText } from "react-icons/fi";
import { FaRegBuilding } from "react-icons/fa";
const SidebarMenuItems = () => {
  const { logout } = useContext(AuthContext);

  // State to manage submenu visibility and active submenu item
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState("");

  // Toggle submenu visibility and set active submenu item
  const toggleSubmenu = (submenuName) => {
    if (submenuOpen && activeSubmenu === submenuName) {
      setSubmenuOpen(false);
      setActiveSubmenu("");
    } else {
      setSubmenuOpen(true);
      setActiveSubmenu(submenuName);
    }
  };

  const handleReportClick = (e) => {
    e.preventDefault();
    toggleSubmenu("reportJob");
  };
  return (
    <>
      <Fragment>
        <li className="sidebar-list">
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/dashboard`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <Home />
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/post_job/job`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <Edit />
            <span>Post New Job</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/post_job/internship`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiPlusSquare />
            <span>Post New Internship</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/manage_jobs`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <CheckSquare />
            <span>Manage Jobs</span>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/manage_candidate`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <Columns />
            <span>Manage Candidates</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/saved_candidates`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FaRegHeart />
            <span>Saved Candidates</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/your_candidate_search`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiSearch />
            <span>Search Your Candidate</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/candidate_search`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiSearch />
            <span>Search Candidate </span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/bulk_upload`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiUpload />
            <span>Bulk Upload</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/job_source`}
            onClick={handleReportClick}
            className={`sidebar-link sidebar-title link-nav ${
              submenuOpen && activeSubmenu === "reportJob" ? "active" : ""
            }`}
          >
            <LuFile />
            <span>Report</span>
            {submenuOpen && activeSubmenu === "reportJob" ? (
              <ChevronUp style={{ float: "right" }} />
            ) : (
              <ChevronDown style={{ float: "right" }} />
            )}
          </NavLink>
          {submenuOpen && activeSubmenu === "reportJob" && (
            <ul className="sidebar-submenu">
              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/recruiter/job_source`}
                  className={`sidebar-sublink ${
                    window.location.pathname.includes("/recruiter/job_source")
                      ? "active"
                      : ""
                  }`}
                >
                  Job Source
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/recruiter/job_pipeline`}
                  className={`sidebar-sublink ${
                    window.location.pathname.includes("/recruiter/job_pipeline")
                      ? "active"
                      : ""
                  }`}
                >
                  Job Pipeline
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/recruiter/job_conversion`}
                  className={`sidebar-sublink ${
                    window.location.pathname.includes(
                      "/recruiter/job_conversion"
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  Job Conversion Insights
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/recruiter/weekly_status`}
                  className={`sidebar-sublink ${
                    window.location.pathname.includes(
                      "/recruiter/weekly_status"
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  Weekly Status
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/recruiter/detail_report`}
                  className={`sidebar-sublink ${
                    window.location.pathname.includes(
                      "/recruiter/detail_report"
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  Recruiter Detail Report
                </NavLink>
              </li> */}
            </ul>
          )}

          {/* <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/profile`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiUsers />
            <span>Edit Profile</span>
          </NavLink> */}
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/company`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <TbBuilding />
            <span>Edit Company</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/invite`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <LuMailPlus />
            <span>Invite</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/referral`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiExternalLink />
            <span>Referral</span>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/career`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiBriefcase />
            <span>Career Page Integration</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/recruiter/ai_linkedin_banner`}
            className={`sidebar-link sidebar-title link-nav`}
          >
            <FiFileText />
            <span>AI Linkedin Banner</span>
          </NavLink>
        </li>

        <li className="sidebar-main-title p-0"></li>
        <li className="sidebar-list">
          <a
            href="#"
            className={`sidebar-link sidebar-title link-nav`}
            onClick={logout}
          >
            <LogOut />
            <span>Logout</span>
          </a>
        </li>
      </Fragment>
    </>
  );
};

export default SidebarMenuItems;
