import {
  CHANGE_JOB_STATUS_REQUEST,
  CHANGE_JOB_STATUS_SUCCESS,
  CHANGE_JOB_STATUS_FAILURE,
  RESET_CHANGE_JOB_STATUS,
} from "../../actionTypes";

const initialState = {
  status: false,
  isLoading: false,
  message: "",
};

const changeJobStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_JOB_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_JOB_STATUS_SUCCESS:
      console.log("us", action.payload);
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case CHANGE_JOB_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_CHANGE_JOB_STATUS:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: "",
      };
    default:
      return state;
  }
};

export default changeJobStatusReducer;
