// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import { scanResumeSuccess, scanResumeFailure } from "./actions";
import { SCAN_RESUME_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";
function* scanResume(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/resume_analyzer", action.payload)
    );
    console.log(response);
    if (response.data.status) {
      yield put(scanResumeSuccess(response.data));
    } else {
      yield put(scanResumeFailure(response.data));
    }
  } catch (error) {
    yield put(scanResumeFailure({ status: false, message: error.message }));
  }
}

function* scanResumeSaga() {
  yield takeLatest(SCAN_RESUME_REQUEST, scanResume);
}

export default scanResumeSaga;
