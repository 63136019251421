import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Card,
  Badge,
} from "reactstrap";

import { formatDate } from "../../Common/CommonFunction";

import { capitalize } from "../../Common/CommonFunction";

const Worksample = ({ profileData }) => {
  return (
    <Fragment>
      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center justify-content-between ">
            <h4 className="card-title mb-0 " style={{ fontWeight: 550 }}>
              Work Sample
            </h4>
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ps-2">
            {profileData.work_sample && profileData.work_sample.length > 0 ? (
              // Render work sample data if available
              profileData.work_sample.map((work) => (
                <div
                  className="d-flex justify-content-between setup"
                  key={work.id}
                >
                  <div>
                    <h5>
                      {capitalize(work.work_title)} (
                      {formatDate(work.work_start)} to{" "}
                      {formatDate(work.work_end)})
                    </h5>

                    <p className="mb-1">
                      <a href={work.work_url}>{work.work_url}</a>
                    </p>

                    <p className="mb-1">{capitalize(work.work_description)}</p>
                  </div>
                </div>
              ))
            ) : (
              // Render "No data found" message if no work sample data
              <div className="text-center">No data found</div>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default Worksample;
