import { Container, Col, Row, CardBody, Card, Button } from "reactstrap";
import ProfileEditContain from "../../Components/Jobseeker/Profile";
import { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { useParams } from "react-router";
import Template1 from "../../Components/Jobseeker/ResumeTemplates/Template1/Template1";
import Template2 from "../../Components/Jobseeker/ResumeTemplates/Template2/Template2";
import Template3 from "../../Components/Jobseeker/ResumeTemplates/Template3/Template3";
import Template4 from "../../Components/Jobseeker/ResumeTemplates/Template4/Template4";
import Template5 from "../../Components/Jobseeker/ResumeTemplates/Template5/Template5";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { PreloaderContext } from "../../_helper/Preloader/PreloaderContext";
import { fetchJobseekerDataRequest } from "../../redux/jobseeker/fetch_Jobseeker_data/actions";
import { showToast } from "../../Components/Common/Toast";
import { LOADER_TIMEOUT } from "../../Constant";
import Spinner from "../../Components/Common/Spinner";
import MyProfileEdit from "../../Components/Jobseeker/Profile/MyProfileEdit";
import EditMyProfile from "../../Components/Jobseeker/Profile/EditmyProfile";
import Educationbackground from "../../Components/Jobseeker/Profile/Educationbackground";
import Workexperience from "../../Components/Jobseeker/Profile/Workexperience";
import Mainskills from "../../Components/Jobseeker/Profile/Mainskills";
import Worksample from "../../Components/Jobseeker/Profile/Worksample";
import Select from "react-select";
import { fetchTemplatesRequest } from "../../redux/resumeTemplates/actions";
import { FiDownload } from "react-icons/fi";
import { checkTemplateLockRequest } from "../../redux/jobseeker/check_template_locked/actions";
import { FiShare2 } from "react-icons/fi";
import { shareResumeTemplateRequest } from "../../redux/jobseeker/share_resume_template/actions";
import ShareModal from "../../Components/Jobseeker/ResumeTemplates/ShareModel";
import {
  unlockTemplateRequest,
  resetUnlockTemplateMessage,
} from "../../redux/jobseeker/unlock_template/actions";
import whatsapp from "../../assets/images/Icons/whatsapp.png";
import facebook from "../../assets/images/Icons/facebook.png";
import twitter from "../../assets/images/Icons/twitter.png";
import linkedin from "../../assets/images/Icons/linkedin.png";
import { fetchJobseekerReferralRequest } from "../../redux/jobseeker/referral/actions";
import {
  idToBase64,
  base64ToId,
  showStyledCoins,
} from "../../Components/Common/CommonFunction";
import { AuthContext } from "../../Auth/AuthContext";
import { fetchRewardsPointsRequest } from "../../redux/fetch_rewards_points/actions";
import { useLocation } from "react-router-dom";

const TemplateEditPage = () => {
  const location = useLocation();
  const { validationMessages } = location.state || {}; // Retrieve validation messages from state
  console.log("analysisData222", validationMessages); // Logs the passed resume analysis data

  const { cand_id, jobcad_id, templateId } = useParams();
  const { triggerTotalPoints } = useContext(AuthContext);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { showPreloader } = useContext(PreloaderContext);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(
    base64ToId(templateId)
  );
  const [selectedType, setSelectedType] = useState("");
  const [isPurchased, setIsPurchased] = useState(true);
  const [resumeShareLink, setResumeShareLink] = useState("");

  useEffect(() => {
    dispatch(fetchJobseekerReferralRequest()); // Fetch total referral coins when component mounts
  }, []);
  const jobseekerRefferalData = useSelector(
    (state) => state.referralJobseekerReducer
  );
  const referalCode = jobseekerRefferalData.data.referal_code;
  const referralLink = `${window.location.origin}${process.env.PUBLIC_URL}/jobseeker/auth/register/${referalCode}`;
  const shareOnSocialMedia = (platform) => {
    // Logic to share referral link on selected social media platform
    let shareUrl = "";
    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=Referral | Naukrify –${referralLink}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer.php?u=${referralLink}`;
        break;
      case "twitter":
        shareUrl = `http://twitter.com/share?text=${referralLink}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${referralLink}`;
        break;
      default:
        break;
    }
    window.open(shareUrl, "_blank");
  };

  const handleTemplateFocus = () => {
    // This function will be called when the location dropdown is focused
    // You can trigger your API call here
    const fetchData = async () => {
      try {
        const keyword = ""; // Set your keyword value here
        dispatch(fetchTemplatesRequest(keyword));
      } catch (error) {
        // Handle any errors if necessary
      }
    };

    // Call the fetchData function when the location dropdown is focused
    fetchData();
  };

  useEffect(() => {
    handleTemplateFocus();
  }, []);

  const handleTemplateChange = (selectedOptions) => {
    setSelectedTemplate(selectedOptions.value);
    setSelectedType(selectedOptions.type);
  };

  const handleShareClick = () => {
    setIsShareModalOpen(!isShareModalOpen);
  };

  useEffect(() => {
    // Dispatch share action if isPurchased is true

    // Dispatch action to share resume template
    dispatch(
      shareResumeTemplateRequest({
        template_id: idToBase64(selectedTemplate),
      })
    );
  }, [selectedTemplate]);

  useEffect(() => {
    // Set initial selected type based on the initial templateId value
    const initialSelectedType =
      templateOptions.find((option) => option.value == base64ToId(templateId))
        ?.type || "";

    setSelectedType(initialSelectedType);
  }, [templateOptions]);

  useEffect(() => {
    if (selectedType === "PREMIUM") {
      dispatch(
        checkTemplateLockRequest({
          resume_template_id: idToBase64(selectedTemplate),
        })
      );
    } else {
      setIsPurchased(true);
    }
  }, [selectedType, selectedTemplate]);

  let shareResumeTemplateReducerData = useSelector(
    (state) => state.shareResumeTemplateReducer
  );

  useEffect(() => {
    if (
      shareResumeTemplateReducerData.status &&
      shareResumeTemplateReducerData.data
    ) {
      setResumeShareLink(
        `${window.location.origin}${process.env.PUBLIC_URL}/public/preview_resume/${shareResumeTemplateReducerData.data}/${templateId}`
      );
    }
  }, [shareResumeTemplateReducerData]);

  let resumeTemplateReducerData = useSelector(
    (state) => state.resumeTemplateReducer
  );

  let templateData = resumeTemplateReducerData.data.data;

  useEffect(() => {
    templateData = templateData || [];
    // setTemplateOptions(templateData);

    const options = templateData.map((template) => ({
      value: template.value,
      label: template.label,
      type: template.type,
    }));

    setTemplateOptions(options);
  }, [templateData]);
  useEffect(() => {
    const data = {
      seeker_id: cand_id,
    };
    dispatch(fetchJobseekerDataRequest(data));
  }, []);

  const fetchJobseekerDataReducerData = useSelector(
    (state) => state.fetchJobseekerDataReducer
  );
  let profileData = [];
  if (
    !fetchJobseekerDataReducerData.isLoading &&
    fetchJobseekerDataReducerData.data.data
  ) {
    profileData = fetchJobseekerDataReducerData.data.data;
  }

  useEffect(() => {
    if (
      fetchJobseekerDataReducerData.status &&
      fetchJobseekerDataReducerData.message != ""
    ) {
      showToast("success", fetchJobseekerDataReducerData.message);
      // dispatch(resetJob());
    } else if (
      fetchJobseekerDataReducerData.status == false &&
      fetchJobseekerDataReducerData.message != ""
    ) {
      if (showPreloader) {
        setTimeout(() => {
          showToast("error", fetchJobseekerDataReducerData.message);
        }, LOADER_TIMEOUT);
      } else {
        showToast("error", fetchJobseekerDataReducerData.message);
      }
      // dispatch(resetJob());
    }
  }, [fetchJobseekerDataReducerData]);

  const checkTemplateLockedReducerData = useSelector(
    (state) => state.checkTemplateLockedReducer
  );

  useEffect(() => {
    if (
      !checkTemplateLockedReducerData.isLoading &&
      checkTemplateLockedReducerData.status
    ) {
      setIsPurchased(checkTemplateLockedReducerData.isPurchased);
      // dispatch(resetJob());
    }
  }, [checkTemplateLockedReducerData]);

  const unlockTemplateReducerData = useSelector(
    (state) => state.unlockTemplateReducer
  );

  const handleUnlockClick = () => {
    dispatch(
      unlockTemplateRequest({ template_id: idToBase64(selectedTemplate) })
    );
  };

  useEffect(() => {
    if (
      unlockTemplateReducerData.status == true &&
      unlockTemplateReducerData.message != ""
    ) {
      showToast("success", unlockTemplateReducerData.message);
      triggerTotalPoints("buy_resume", false, false);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetUnlockTemplateMessage());
      setIsPurchased(true);
      setSelectedType("FREE");
    } else if (
      unlockTemplateReducerData.status == false &&
      unlockTemplateReducerData.message != ""
    ) {
      showToast("error", unlockTemplateReducerData.message);
      // Dispatch action to reset the message after showing the toast
      dispatch(resetUnlockTemplateMessage());
    }
  }, [unlockTemplateReducerData]);
  const fetchRewardsPointsReducerData = useSelector(
    (state) => state.fetchRewardsPointsReducer
  );

  useEffect(() => {
    if (
      !fetchRewardsPointsReducerData ||
      !fetchRewardsPointsReducerData.data ||
      !Array.isArray(fetchRewardsPointsReducerData.data) ||
      fetchRewardsPointsReducerData.data.length <= 0
    ) {
      dispatch(fetchRewardsPointsRequest());
    }
  }, []);

  const buyResumePoints =
    fetchRewardsPointsReducerData?.data?.find(
      (item) => item.type === "buy_resume"
    )?.points ?? 0;
  return (
    <Fragment>
      <Breadcrumbs title={"Edit Resume"} mainTitle={"Edit Resume"} />

      <Container fluid>
        <Row className="mb-4">
          <Col xs="12" className="d-flex justify-content-end mb-2">
            {isPurchased && (
              <>
                <FiShare2
                  className="share-icon"
                  title="share"
                  style={{
                    height: "25px",
                    width: "25px",
                    marginRight: "10px",
                  }}
                  onClick={handleShareClick}
                />
                <FiDownload
                  className="download-icon"
                  style={{
                    height: "25px",
                    width: "25px",
                    marginRight: "10px",
                  }}
                  title="download"
                />
              </>
            )}
            <Select
              options={templateOptions}
              value={templateOptions.find(
                (option) => option.value == selectedTemplate
              )}
              onChange={handleTemplateChange}
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: "0px",
                  height: "30px",
                  width: "250px",
                }),
                menu: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Col>
          <Col className="d-flex flex-column p-0 border border-2 border-primary rounded">
            <div
              className="flex-fill p-2 custom-scrollbar"
              style={{
                height: "calc(100vh - 50px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {fetchJobseekerDataReducerData.isLoading ? (
                <Spinner /> // Show loader when isLoading is true
              ) : (
                <div className="custom-scrollbar">
                  <Row>
                    <Col xl="12" className="mb-3">
                      <MyProfileEdit
                        profileData={profileData}
                        validationMessages={validationMessages}
                      />
                    </Col>
                    <Col xl="12" className="mb-3">
                      <EditMyProfile
                        profileData={profileData}
                        validationMessages={validationMessages}
                      />
                    </Col>
                    <Col xl="12" className="mb-3">
                      <Educationbackground
                        profileData={profileData}
                        validationMessages={validationMessages}
                      />
                    </Col>
                    <Col xl="12" className="mb-3">
                      <Workexperience
                        profileData={profileData}
                        validationMessages={validationMessages}
                      />
                    </Col>
                    <Col xl="12" className="mb-3">
                      <Mainskills
                        profileData={profileData}
                        validationMessages={validationMessages}
                      />
                    </Col>
                    <Col xl="12" className="mb-3">
                      <Worksample
                        profileData={profileData}
                        validationMessages={validationMessages}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>

          <Col className="d-flex justify-content-end p-0  rounded">
            <div
              //className="p-3"
              style={{
                position: "relative",
                width: "100%",
                overflow: "hidden",
              }}
            >
              {selectedTemplate == "1" && (
                <Template1 jobseekerData={profileData} />
              )}
              {selectedTemplate == "2" && (
                <Template2 jobseekerData={profileData} />
              )}
              {selectedTemplate == "3" && (
                <Template3 jobseekerData={profileData} />
              )}
              {selectedTemplate == "4" && (
                <Template4 jobseekerData={profileData} />
              )}
              {selectedTemplate == "5" && (
                <Template5 jobseekerData={profileData} />
              )}
              {selectedType === "PREMIUM" && !isPurchased && (
                <div
                  style={{
                    alignContent: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust transparency as needed (0.7 is 70% transparent)
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Card className="mt-2" style={{ margin: "0 40px" }}>
                    <CardBody className="d-flex flex-column align-items-center">
                      <div className="mb-3 mb-md-0 text-center">
                        <h5>
                          Unlock this premium resume template by using{" "}
                          {showStyledCoins(buyResumePoints)} !
                        </h5>
                        <p>
                          Refer your friends to earn more points and unlock
                          other premium features!
                        </p>
                        <div className="refferal-description d-flex flex-column align-items-center">
                          <div className="share-links">
                            <span className="share-link">
                              <strong style={{ margin: "0 5px" }}>OR</strong>
                            </span>
                            <br />
                            <br />
                            <img
                              src={whatsapp}
                              className="share-link share_refferal me-2"
                              onClick={() => shareOnSocialMedia("whatsapp")}
                              aria-label="Whatsapp"
                              alt="whatsapp"
                              style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                              }}
                            />
                            <img
                              src={facebook}
                              className="share-link share_refferal me-2"
                              onClick={() => shareOnSocialMedia("facebook")}
                              aria-label="Facebook"
                              alt="facebook"
                              style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                              }}
                            />
                            <img
                              src={twitter}
                              className="share-link share_refferal me-2"
                              onClick={() => shareOnSocialMedia("twitter")}
                              aria-label="Twitter"
                              alt="twitter"
                              style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                              }}
                            />
                            <img
                              src={linkedin}
                              className="share-link share_refferal me-2"
                              onClick={() => shareOnSocialMedia("linkedin")}
                              aria-label="Linkedin"
                              alt="linkedin"
                              style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <Button color="warning" onClick={handleUnlockClick}>
                          UNLOCK THIS RESUME
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <ShareModal
        isOpen={isShareModalOpen}
        toggle={() => setIsShareModalOpen(!isShareModalOpen)}
        resumeShareLink={resumeShareLink}
      />
    </Fragment>
  );
};

export default TemplateEditPage;
