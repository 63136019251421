import { put, takeLatest, call } from "redux-saga/effects";
import * as types from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";
import { showToast } from "../../../Components/Common/Toast";
import { deleteResumeFailure, deleteResumeSuccess } from "./actions";

function* updatePersonalInfo(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/jobseeker/add_personal_details", action.payload)
    );
    console.log("updatePersonalInfo", response);
    if (response.data.status) {
      yield put({
        type: types.UPDATE_PERSONAL_INFO_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: types.UPDATE_PERSONAL_INFO_FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_PERSONAL_INFO_FAILURE,
      payload: { status: false, message: error.message },
    });
  }
}

function* jobSeekerProfileSaga() {
  yield takeLatest(types.UPDATE_PERSONAL_INFO_REQUEST, updatePersonalInfo);
}

export default jobSeekerProfileSaga;
