import {
  FETCH_MANAGE_CANDIDATES_REQUEST,
  FETCH_MANAGE_CANDIDATES_FAILURE,
  FETCH_MANAGE_CANDIDATES_SUCCESS,
  DELETE_CANDIDATE_REQUEST,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAILURE,
  RESET_DATA,
} from "../../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  message: "",
  status: false,
};

const manageCandidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MANAGE_CANDIDATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_MANAGE_CANDIDATES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: action.payload.status,
        isLoading: false,
      };
    case FETCH_MANAGE_CANDIDATES_FAILURE:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        isLoading: false,
      };
    case DELETE_CANDIDATE_REQUEST:
      return { ...state, isLoading: true };
    case DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case DELETE_CANDIDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_DATA:
      return {
        ...state,
        isLoading: false,
        status: false,
        message: "",
      };
    default:
      return state;
  }
};
export default manageCandidateReducer;
