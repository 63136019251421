// sagas.jsx
import { call, put, takeLatest } from "redux-saga/effects";
import {
  shareResumeTemplateSuccess,
  shareResumeTemplateFailure,
} from "./actions";
import { SHARE_RESUME_TEMPLATE_REQUEST } from "../../actionTypes";
import AxiosInstance from "../../../services/AxiosInstance";

function* shareResumeTemplate(action) {
  try {
    const response = yield call(() =>
      AxiosInstance().post("/share_resume_template", action.payload)
    );

    if (response.data.status) {
      yield put(shareResumeTemplateSuccess(response.data));
    } else {
      yield put(shareResumeTemplateFailure(response.message));
    }
  } catch (error) {
    yield put(shareResumeTemplateFailure(error.message)); // Dispatch failure action with error message
  }
}

function* shareResumeTemplateSaga() {
  yield takeLatest(SHARE_RESUME_TEMPLATE_REQUEST, shareResumeTemplate);
}

export default shareResumeTemplateSaga;
