// reducers.js
import {
  UPDATE_RECRUITER_PROFILE_REQUEST,
  UPDATE_RECRUITER_PROFILE_SUCCESS,
  UPDATE_RECRUITER_PROFILE_FAILURE,
  FETCH_RECRUITER_PROFILE_REQUEST,
  FETCH_RECRUITER_PROFILE_SUCCESS,
  FETCH_RECRUITER_PROFILE_FAILURE,
  UPDATE_RECRUITER_AVATAR_REQUEST,
  UPDATE_RECRUITER_AVATAR_SUCCESS,
  UPDATE_RECRUITER_AVATAR_FAILURE,
  REMOVE_RECRUITER_AVATAR_REQUEST,
  REMOVE_RECRUITER_AVATAR_SUCCESS,
  REMOVE_RECRUITER_AVATAR_FAILURE,
  RESET_MESSAGE,
} from "../../actionTypes";

const initialState = {
  data: {}, // For company profile data
  avatarData: {}, // For avatar-related data
  isLoading: false,
  status: false,
  message: "",
};

const recruiterProfileReducer = (state = initialState, action) => {
  console.log("reducer data", action);
  switch (action.type) {
    case UPDATE_RECRUITER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_RECRUITER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        data: action.payload.data,
        message: action.payload.message,
      };
    case UPDATE_RECRUITER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case FETCH_RECRUITER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RECRUITER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        status: action.payload.status,
      };
    case FETCH_RECRUITER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case UPDATE_RECRUITER_AVATAR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_RECRUITER_AVATAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };

    case UPDATE_RECRUITER_AVATAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case REMOVE_RECRUITER_AVATAR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_RECRUITER_AVATAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };

    case REMOVE_RECRUITER_AVATAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case RESET_MESSAGE: // Reset message action
      return {
        ...state,
        message: "",
        isLoading: false,
        status: false,
      };
    default:
      return state;
  }
};

export default recruiterProfileReducer;
