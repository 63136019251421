import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_JOB,
} from "../../actionTypes";

export const loginRequest = (jobData) => ({
  type: LOGIN_REQUEST,
  payload: jobData,
});

export const loginSuccess = (response) => ({
  type: LOGIN_SUCCESS,
  payload: response,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const reset = () => ({
  type: RESET_JOB,
});
