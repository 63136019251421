import React, { useState } from 'react';
import Context from './index';

const CustomizerProvider = (props) => {
  // const customizer = ConfigDB.data;
  const [toggleIcon, setToggleIcon] = useState(false);
  const [sidebarResponsive, setSidebarResponsive] = useState(false);
  const [IsOpen, setIsClose] = useState(false);
  

  //Toggle sidebar
  const toggleSidebar = (toggle) => {
    setToggleIcon(toggle);
  };



  const toggleSidebarResponsive = (toggle) => {
    setSidebarResponsive(toggle);
  };

  return (
    <Context.Provider
      value={{
        ...props,
        toggleIcon,
        sidebarResponsive,
        IsOpen,
        setIsClose,
        setToggleIcon,
        toggleSidebarResponsive,
        toggleSidebar: toggleSidebar,
      }}>
      {props.children}
    </Context.Provider>
  );
};

export default CustomizerProvider;
