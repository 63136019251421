import {
  FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST,
  FETCH_JOBSEEKER_PUBLIC_PROFILE_SUCCESS,
  FETCH_JOBSEEKER_PUBLIC_PROFILE_FAILURE,
} from "../../actionTypes";

export const fetchJobseekerPublicProfileRequest = (data) => ({
  type: FETCH_JOBSEEKER_PUBLIC_PROFILE_REQUEST,
  payload: data,
});

export const fetchJobseekerPublicProfileSuccess = (data) => ({
  type: FETCH_JOBSEEKER_PUBLIC_PROFILE_SUCCESS,
  payload: data,
});

export const fetchJobseekerPublicProfileFailure = (error) => ({
  type: FETCH_JOBSEEKER_PUBLIC_PROFILE_FAILURE,
  payload: error,
});
