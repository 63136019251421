import {
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_FAILURE,
  RESET_DATA,
} from "../../actionTypes";

const initialState = {
  status: false,
  message: "",
  error: null,
  isLoading: false, // Add isLoading state to manage loading status
};

const verifyAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: true,
        isLoading: false,
        message: action.payload.message,
        is_redirect: action.payload.is_redirect,
        error: null,
      };
    case VERIFY_ACCOUNT_FAILURE:
      return {
        ...state,
        status: false,
        isLoading: false,
        message: action.payload.message,
        error: action.payload,
      };
    case RESET_DATA:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default verifyAccountReducer;
