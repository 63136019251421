import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardImg, Button, Col } from "reactstrap";
import { FaCrown } from "react-icons/fa";
import { idToBase64 } from "../../Common/CommonFunction";
const ResumeCard = ({ template }) => {
  const navigate = useNavigate();
  console.log("idToBase64", idToBase64(1));
  const handlePreviewClick = () => {
    // Redirect to another page, replace '/target-page' with your desired URL
    navigate(
      `${process.env.PUBLIC_URL}/jobseeker/preview_resume/${idToBase64(
        template.id
      )}`
    );
  };

  return (
    <Col lg="3" md="6" sm="6" xs="12" className="mb-5">
      <Card
        className="img-wrapper mb-3"
        style={{ height: "90%", width: "100%" }}
      >
        {template.resume_category === "PREMIUM" && (
          <div
            className="position-absolute top-0 end-0 d-flex align-items-center mt-2"
            style={{ marginRight: "5px" }} // Adjust margin as needed
          >
            <FaCrown size={20} color="gold" style={{ marginRight: "4px" }} />
            <span className="badge bg-warning text-dark">Premium</span>
          </div>
        )}
        <CardImg
          id="preview_img"
          src={template.resume_template_image}
          alt="Resume Template"
        />
      </Card>
      <div className="res-prv text-center">
        <Button
          id="preview_btn"
          value={template.id}
          style={{
            fontSize: "13px",
            borderRadius: "61px",
            border: "none",
            padding: "7px 20px",
          }}
          color="primary"
          onClick={handlePreviewClick}
        >
          Preview
        </Button>
      </div>
    </Col>
  );
};

export default ResumeCard;
