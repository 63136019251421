import { takeLatest, put, call } from "redux-saga/effects";
import {
  FETCH_JOB_SOURCES_REQUEST,
  DOWNLOAD_JOB_SOURCE_CSV_REQUEST,
} from "../../actionTypes";
import { fetchJobSourcesSuccess, fetchJobSourcesFailure } from "./actions";

import AxiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

function* fetchJobSources(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/recruiter_job_source_report`,
      action.payload
    );

    if (response.data.status) {
      yield put(fetchJobSourcesSuccess(response.data));
    } else {
      yield put(fetchJobSourcesFailure(response.data));
    }
  } catch (error) {
    yield put(
      fetchJobSourcesFailure({ status: false, message: error.message })
    );
  }
}

function* downloadJobSourceCsv(action) {
  try {
    const response = yield AxiosInstance().post(
      `/recruiter/recruiter_job_source_report_csv_download`,
      action.payload
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const downloadLink = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);

    downloadLink.download = "exported_data.xls";
    downloadLink.href = blobUrl;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error:", error);
    // Handle errors here
  }
}

export default function* jobSourceSaga() {
  yield takeLatest(FETCH_JOB_SOURCES_REQUEST, fetchJobSources);
  yield takeLatest(DOWNLOAD_JOB_SOURCE_CSV_REQUEST, downloadJobSourceCsv);
}
